import React from 'react';
import PropTypes from 'prop-types';

const PhoneNumbers = ({ value }) => {
  return (
    <span>
      {value.length === 0 
        ? <span />
        : <span>{value.map((phone) => phone.number).join(', ')}</span>
      }
    </span>
  );
};

PhoneNumbers.propTypes = {
  value: PropTypes.any
};

export default PhoneNumbers;

import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

const Gallery = ({ images, ...props }) => {
  const items = images.map((img) => {
    return {
      original: `${process.env.REACT_APP_CLOUDINARY_PREFIX_URL}/b_rgb:ffffff,c_lpad,w_560,h_420/${img}`,
      thumbnail: `${process.env.REACT_APP_CLOUDINARY_PREFIX_URL}/b_rgb:ffffff,c_lpad,w_72,h_72/${img}`,
      originalClass: props.originalClass,
      thumbnailClass: props.thumbnailClass,
    };
  });

  return (
    <ImageGallery
      additionalClass={props.additionalClass}
      items={items}
      showBullets={images.length > 1}
      showThumbnails={images.length > 1}
      showPlayButton={false}
      showIndex={false}
      showNav={true}
      showFullscreenButton={props.showFullscreenButton || false}
      useBrowserFullscreen={false} />
  );
};

Gallery.propTypes = {
  additionalClass: PropTypes.string,
  images: PropTypes.array.isRequired,
  originalClass: PropTypes.string,
  showFullscreenButton: PropTypes.bool,
  thumbnailClass: PropTypes.string
};

export default Gallery;

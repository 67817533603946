import grey from '@material-ui/core/colors/grey';
import { COLLIERS_TEAL } from '../../../styles/theme';

export default (theme) => ({
  ['@global']: {
    '.leaflet-popup-content-wrapper': {
      width: '100%',
      padding: '0 !important',
    },
    '.leaflet-popup-content': {
      margin: '0 !important',
      width: '100% !important',
      ['& p']: {
        margin: '0 !important',
      },
    },
  },

  accordionSummary: {
    color: 'white', 
    boxShadow: '0 3px 14px rgba(0, 0, 0, 0.4)', 
    height: '48px !important',
    minHeight: '48px !important'
  },

  closeButton: {
    height: '14px',
    lineHeight: '14px',
    width: '18px',
    fontSize: '16px',
    color: 'white',
    position: 'absolute',
    top: '16px',
    right: '-6px',
    border: 'none',
    backgroundColor: 'transparent',
    ['&:hover']: {
      backgroundColor: 'transparent',
    },
  },

  closeIcon: {
    color: 'white',
    fontSize: '18px',
    fontWeight: '700',
  },

  datatypeMenuNav: {
    padding: '11.5px 0px 0px 0px !important',
    left: '-134px',
    width: '172%',
    scale: '0.60',
    overflow: 'hidden',
    position: 'relative'
  },
  datatypeMenuBox: {
    color: 'white'
  },

  datatypeMenuTabs: {
    backgroundColor: '#6E6E70',
    height: '73px !important',
    width: '100%',
    ['& .MuiTab-wrapper']: {
      opacity: 'unset',
      textWrap: 'nowrap',
      ['& .MuiAvatar-root']: {
        display: 'none'
      }
    },
    ['& .MuiTabs-scroller']: {
      position: 'unset',
      overflow: 'hidden',
      color: 'white'
    },
    ['& > div > span']: {
      display: 'none'
    }
  },
  datatypeMenuTab: {
    fontWeight: '600',
    fontSize: '16px',
    height: '100%',
    opacity: 'unset',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(1800)]: {
      minWidth: '25%',
    },
  },
  datatypeMenuTabInactive: {
    fontWeight: '600',
    fontSize: theme.spacing(2),
    opacity: 'unset !important',
    padding: '6px 8px',
    ['& .MuiTab-wrapper']: {
      opacity: '0.36',
    },
    [theme.breakpoints.down(1800)]: {
      minWidth: '25%',
    },
    [theme.breakpoints.down(1500)]: {
      padding: '16px 24px'
    }
  },
  datatypeMenuTabIndicatorProps: {
    bottom: 'unset',
    left: '0',
    top: '0',
    height: '12px',
    transition: 'unset'
  },
  datatypesMenuTabAvatar: {
    height: 28,
    width: 28,
    margin: '0 !important',
    padding: '4px',
    [theme.breakpoints.down(1500)]: {
      height: 32,
      width: 32
    }
  },
  datatypesMenuTabIcon: {
    height: 28,
    width: 28,
    padding: '4px',
    [theme.breakpoints.down(1500)]: {
      height: 32,
      width: 32
    }
  },
  parcelLabel: {
    marginRight: '5px',
    flexShrink: 0,
    width: '25%'
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  detailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.4rem',
    width: '100%'
  },
  label: {
    fontSize: '12px',
    marginRight: '5px',
    flexShrink: 0
  },

  labelContainer: {
    display: 'flex'
  },
  value: {
    fontSize: '12px',
    overflow: 'hidden',
    textAlign: 'right',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '100%'
  },
  popupContainer: {
    width: '440px',
  },
  recordedSalesSummary: {
    backgroundColor: COLLIERS_TEAL,
    color: 'white',
    fontWeight: 'bold',
  },
  loadingPopup: {
    height: '50px',
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ourDealsLogo: {
    width: '35px',
    position: 'absolute',
    top: '12px',
    right: '8px'
  },
  ownerValue: {
    marginLeft: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '50%'
  },
  parcelPopup: {
    width: '440px',
    maxHeight: '650px',
    backgroundColor: 'white',
    ['& header']: {
      padding: theme.spacing(),
      height: '56px',
    },
    ['& h4']: {
      color: 'white !important',
      textDecoration: 'underline !important',
      fontSize: '120% ',
      fontWeight: 'bold !important',
      margin: 0,
    },
    ['& h5']: {
      color: 'white',
      textDecoration: 'underline',
      fontWeight: 'normal',
      margin: 0,
    },
    ['& article']: {
      fontSize: '50%',
      maxHeight: '180px',
      overflowY: 'scroll'
    },
    ['& .MuiAccordion-root.Mui-expanded']: {
      margin: 0
    }
  },
  parcelPopupHeader: {
    'cursor': 'pointer',
    'color': 'white'
  },
  popup: {
    'background': 'white',
    'top': -320,
    'right': -220,
    'min-width': 261,
    ['& header']: {
      padding: theme.spacing(),
    },
    ['& h4']: {
      color: grey[800],
      fontSize: '120%',
      fontWeight: 'normal',
      margin: 0,
    },
    ['& h5']: {
      color: grey[500],
      fontWeight: 'normal',
      margin: 0,
    },
    ['& article']: {
      fontSize: '50%',
      maxHeight: '180px',
      overflowY: 'scroll'
    },
  },
  popupContent: {
    padding: theme.spacing(),
    ['.MuiAccordionDetails-root']: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
      maxHeight: '400px',
    }
  },
  popupContentCloseButton: {
    height: '14px',
    lineHeight: '14px',
    width: '18px',
    fontSize: '16px',
    color: '#c3c3c3',
    position: 'absolute',
    top: '10px',
    right: '-6px',
    border: 'none',
    backgroundColor: 'transparent',
    ['&:hover']: {
      backgroundColor: 'transparent',
    },
  },

  poupContentCloseIcon: {
    color: '#c3c3c3',
    fontSize: '18px',
    fontWeight: '700',
  },

  popupContentRow: {
    paddingBottom: '0px',
  },
  popupContentRowItem: {
    display: 'flex',
    alignItems: 'center'
  },
  popupHeader: {
    cursor: 'pointer',
  },
  popupPanelRow: {
    width: 'inherit',
    paddingLeft: '8px',
    paddingBottom: '12px',
  },
  propertyColumnContainer: {
    flex: '1'
  },
  propertyContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    lineHeight: '1.4rem'
  },
  propertyLabel: {
    marginRight: '16px',
  },
  heading: {
    fontSize: '.5rem'
  },
  recordedSalesTableHeader: {
    fontSize: '12px', fontFamily: 'Helvetica Neue, Arial, Helvetica, sans-serif',
    fontWeight: '400',
    lineHeight: '1.4px',
    paddingBottom: '8px',
    borderBottom: '0'
  },
  recordedSalesTableCell: {
    fontSize: '12px', fontFamily: 'Helvetica Neue, Arial, Helvetica, sans-serif',
    lineHeight: '1.4px',
    borderBottom: '0',
    fontWeight: 'bold'
  },
  situsAddress: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '65%'
  },
  parcelValue: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '65%'
  }
});

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FlagIcon from '@material-ui/icons/Flag';
import FieldNote from '../../common/stats/FieldNote';
import styles from './module.css';

const Header = ({ classes, clearTim, location, router, showProblemReporter, tim }) => {
  const close = () => {
    clearTim();
    router.push({ ...location, pathname: '/research/map' });
  };

  const reportTim = () => {
    showProblemReporter(tim);
  };

  const getHeaderStatFieldNote = (fieldName, extraStyles) => {
    if (tim.fieldNotes && tim.fieldNotes[fieldName]) {
      return (
        <FieldNote color="#FFFFFF" content={tim.fieldNotes[fieldName]} style={extraStyles} />
      );
    }
    return null;
  };

  return (
    <div className={classes.header}>
      <header>
        <div className={classes.metaNav}>
          <IconButton className={classes.rightButton} size="small" onClick={close}><CloseIcon /></IconButton>
          <IconButton className={classes.rightButton} size="small" onClick={reportTim}><FlagIcon /></IconButton>
        </div>
        <div className={classes.headerTitle}>
          <Typography className={classes.headerBanner} variant="h5">
            {tim.tenant.name || 'Unknown Tenant'}
            {getHeaderStatFieldNote('tenant', { top: 2 })}
          </Typography>
          <div className={classes.headerInfo}>
            <Typography className={classes.headerSubbanner} variant="h6">
              {(tim.tenant.industries || []).join(', ')}
            </Typography>
            <div className={classes.headerStats}>
              {getHeaderStatFieldNote('submarkets')}
              {tim.submarkets && tim.submarkets.map((submarket, i) => (
                <span className={classes.headerStat} key={i}>{submarket}</span>
              ))}
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.object,
  clearTim: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  showProblemReporter: PropTypes.func.isRequired,
  tim: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);

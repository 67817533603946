import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { SQ_FT_PER_SQ_M, SQ_FT_PER_ACRE } from '../../research/constants.js';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css';

const formatDate = (date) => {
  return date ? moment(date).format('MM/DD/YYYY') : '\u2013';
};

const formatDollarNumber = (number) => {
  return number ? `$${number.toLocaleString('en-US')}` : '\u2013';
};

function formatString(str) {
  const formattedStr = str ? str : '\u2013';
  const words = formattedStr
    .toLowerCase()
    .split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}

const PublicSalesRecordsDetailsLastSale = ({ classes,  propertySize, sortedLastMarketSale }) => {
  const { 
    buyer, 
    dateFiling, 
    dateTransfer, 
    document,
    isConstructionLoan,
    lender, 
    loan, 
    multipleApnFlag,
    salePrice, 
    seller,
    titleCompanyName
  } = sortedLastMarketSale;

  const constructionLoanValue = isConstructionLoan ? 'Yes' : '\u2013';
  const acres = propertySize ? propertySize * SQ_FT_PER_SQ_M / SQ_FT_PER_ACRE : null;

  const calculatedPricePerSF = salePrice.value && propertySize
    ? `$${(salePrice.value / propertySize).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : '\u2013';

  const calculatedPricePerAcre = salePrice.value && propertySize
    ? `$${(salePrice.value / acres).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : '\u2013';

  const lastMarketSaleInfo = [
    [
      { 
        key: 'recordingDate', 
        value: formatDate(dateFiling), 
        label: 'Recording Date', 
        format: 'string' 
      },
      {
        key: 'saleDate', 
        value: formatDate(dateTransfer), 
        label: 'Sale Date', 
        format: 'string' 
      },
      { 
        key: 'salePrice', 
        value: formatDollarNumber(salePrice.value), 
        label: 'Sale Price', 
        format: 'number' 
      },
      { 
        key: 'pricePerSF', 
        value: calculatedPricePerSF, 
        label: 'Price Per SF', 
        format: 'number' 
      },
      { 
        key: 'pricePerAcre', 
        value: calculatedPricePerAcre, 
        label: 'Price Per Acre', 
        format: 'number' 
      },
      { 
        key: 'saleType', 
        value: formatString(salePrice.description),
        label: 'Sale Type', 
        format: 'string' 
      }
    ],
    [
      { 
        key: 'titleCompany', 
        value: formatString(titleCompanyName), 
        label: 'Title Company', 
        format: 'string' 
      },
      { 
        key: 'constructionLoan',
        value: constructionLoanValue, 
        label: 'Construction Loan', 
        format: 'string' 
      },
      { 
        key: '1stMtgAmt', 
        value: formatDollarNumber(loan.value.first),
        label: '1st Mtg Amount', 
        format: 'number' 
      },
      { 
        key: '1stMtgType', 
        value: formatString(loan.type.description),
        label: '1st Mtg Type', 
        format: 'string' 
      },
      { 
        key: 'deedType', 
        value: formatString(document.typeDescription),
        label: 'Deed Type', 
        format: 'string' 
      }
    ],
    [
      { 
        key: 'saleDocNo', 
        value: formatString(loan.currentSale.documentNumber),
        label: 'Sale Doc. No.', 
        format: 'string' 
      },
      { 
        key: 'transferDocNo', 
        value: formatString(document.number),
        label: 'Transfer Doc. No.', 
        format: 'string' 
      },
      { 
        key: 'transferDocDesc', 
        value: formatString(document.typeDescription),
        label: 'Transfer Doc. Desc.', 
        format: 'string' 
      },
      { 
        key: 'sellerName', 
        value: formatString(seller.name),  
        label: 'Seller Name', 
        format: 'string' 
      },
      { 
        key: 'buyer', 
        value: formatString(buyer.name), 
        label: 'Buyer', 
        format: 'string' 
      },
      { 
        key: 'lender', 
        value: formatString(lender.name), 
        label: 'Lender', 
        format: 'string' 
      },
      { 
        key: 'multipleAPNFlag', 
        value: formatString(multipleApnFlag.description), 
        label: 'Multiple APN Flag', 
        format: 'string' 
      }
    ]
  ];

  return (
    <section>
      <Paper elevation={1} className={classes.sectionPaper}>
        <header className={classes.sectionHeaderWrapper}>
          <Typography className={classes.sectionHeader} variant="subtitle1">Last Market Sale Information</Typography>
        </header>
        <div className={classes.tripleColumn}>
          {lastMarketSaleInfo.map((info, i) => (
            <div className={classes.columnContainer} key={i} >
              {info.map((detail) => (
                <div key={detail.label} className={classes.labelContainer}>
                  <Typography className={classes.label}>{detail.label}:</Typography>
                  <Typography className={classes.value}>{detail.value}</Typography>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Paper>
    </section>
  );
};

PublicSalesRecordsDetailsLastSale.propTypes = {
  classes: PropTypes.object,
  propertySize: PropTypes.number,
  sortedLastMarketSale: PropTypes.object.isRequired,
};

export default withStyles(styles)(PublicSalesRecordsDetailsLastSale);

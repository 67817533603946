import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Confirm from '../../common/Confirm';

const MAX_PROPERTIES = 7;

const SiteAdvisorMenuItem = ({ createSiteAdvisorSession, currentUser, selected }) => {
  const [confirm, setConfirm] = useState(false);

  const triggerAnalysis = (selected) => {
    const industrialProperties = selected
      .map((p) => p._source)
      .filter((p) => p.propertyTypes.includes('Industrial'));
    const finalProperties = industrialProperties.slice(0, MAX_PROPERTIES);
    createSiteAdvisorSession({ properties: finalProperties, userid: currentUser.email });
  };

  const handleClick = () => {
    if (selected.length > MAX_PROPERTIES) {
      setConfirm(true);
    } else {
      triggerAnalysis(selected);
    }
  };

  const handleConfirm = () => {
    setConfirm(false);
    triggerAnalysis(selected);
  };

  const handleDeny = () => {
    setConfirm(false);
  };

  return (
    <MenuItem onClick={handleClick}>
      <ListItemIcon><AssessmentIcon /></ListItemIcon>
      <ListItemText primary="Send to SiteAdvisor" />
      <Confirm
        confirmButtonText="I Understand"
        denyButtonText="Go Back"
        onConfirm={handleConfirm}
        onDeny={handleDeny}
        open={confirm}
        text={`Note: Only the first ${MAX_PROPERTIES} Industrial properties will be sent to be analyzed.`} />
    </MenuItem>
  );
};

SiteAdvisorMenuItem.propTypes = {
  createSiteAdvisorSession: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  selected: PropTypes.array.isRequired
};

export default SiteAdvisorMenuItem;

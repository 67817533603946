import { ability } from '../auth/ability';
import { getCurateAbilities } from '../auth/CurateUser';
import { registerCuratePermissions } from '../auth/CurateUserAbility';
import {
  CLEAR_SELECTED_RESULTS,
  CLEAR_SELECTED_RESULTS_ALL_DATATYPES,
  CURATE_USER_FETCH_ERROR,
  CURATE_USER_FETCH_REQUEST,
  CURATE_USER_FETCH_SUCCESS,
  DESELECT_SEARCH_RESULT,
  DISABLE_LIST_MODE,
  DISABLE_LIST_MODE_ALL_DATATYPES,
  DISABLE_SELECT_MODE,
  ENABLE_LIST_MODE,
  ENABLE_SELECT_MODE,
  NOTIFICATIONS_CLOSE,
  MY_LISTS_FETCH_ERROR,
  MY_LISTS_FETCH_REQUEST,
  MY_LISTS_FETCH_TRANSLATE_SUCCESS,
  MY_LISTS_UPDATE_ERROR,
  MY_LISTS_UPDATE_REQUEST,
  SELECT_ALL_SEARCH_RESULTS,
  SELECT_SEARCH_RESULT,
  SET_SELECTION_ANCHOR,
  SET_SOURCE_FILTER_SCHEMA,
  TOGGLE_SHARE_SEARCH_DIALOG,
  TOGGLE_SHOW_SELECTED,
  UPDATE_MAP_BOUNDS,
} from '../constants/actionTypes';
import api from '../services/api';

export function setSourceFilterSchema(schema) {
  return {
    type: SET_SOURCE_FILTER_SCHEMA,
    schema
  };
}

export function selectAllResults(results, dataType) {
  return {
    type: SELECT_ALL_SEARCH_RESULTS,
    results,
    dataType
  };
}

export function clearSelectedResults(dataType) {
  return {
    type: CLEAR_SELECTED_RESULTS,
    dataType
  };
}

export function clearSelectedResultsAllDatatypes() {
  return {
    type: CLEAR_SELECTED_RESULTS_ALL_DATATYPES
  };
}

export function updateMapBounds(bounds) {
  return {
    type: UPDATE_MAP_BOUNDS,
    bounds
  };
}

export function enableSelectMode() {
  return {
    type: ENABLE_SELECT_MODE
  };
}

export function disableSelectMode() {
  return {
    type: DISABLE_SELECT_MODE
  };
}

export function setSelectionAnchor(item) {
  return {
    type: SET_SELECTION_ANCHOR,
    item
  };
}

export function toggleShowSelected() {
  return {
    type: TOGGLE_SHOW_SELECTED
  };
}

export function toggleSelectedItem(item, isSelected, dataType) {
  return {
    type: isSelected ? SELECT_SEARCH_RESULT : DESELECT_SEARCH_RESULT,
    dataType,
    item
  };
}

export function toggleShareDialog(open) {
  return {
    type: TOGGLE_SHARE_SEARCH_DIALOG,
    open
  };
}

export function notificationsClose() {
  return {
    type: NOTIFICATIONS_CLOSE
  };
}

export function fetchCurateUser() {
  return (dispatch) => {
    dispatch({ type: CURATE_USER_FETCH_REQUEST });
    return getCurateAbilities()
      .then((data) => {
        registerCuratePermissions(data);
        dispatch({ type: CURATE_USER_FETCH_SUCCESS, data });
      })
      .catch((error) => {
        dispatch({
          type: CURATE_USER_FETCH_ERROR,
          error: { error }
        });
      });
  };
}

export function createListsByEntity(data) {
  const mappedEntityLists = new Map();

  for (const list of data) {
    const { items } = list;
    for (const item of items) {
      if (mappedEntityLists.has(item)) {
        const currentListsForEntity = mappedEntityLists.get(item);
        // create new array ref to trigger renders
        mappedEntityLists.set(item, [...currentListsForEntity, list]);
      } else {
        mappedEntityLists.set(item, [list]);
      }
    }
  }

  return mappedEntityLists;
}

export async function getSavedLists() {
  if (ability.cannot('use', '_app')) return new Promise((resolve) => resolve([]));
  const results = await api.read('myLists', null, { sort: '-updatedAt' });
  return results;
}

export function createListsWithEntities(value, currentListView, selectedEntities) {
  const newList = {
    title: value.title,
    description: value.description,
    datatype: currentListView,
    items: selectedEntities
  };
  return (dispatch) => {
    return api.create('myLists', newList).then(() => {
      dispatch({ type: MY_LISTS_FETCH_REQUEST });
      getSavedLists().then((data) => {
        const myListsByEntity = createListsByEntity(data);
        dispatch({ type: MY_LISTS_FETCH_TRANSLATE_SUCCESS, myLists: { raw: data, myListsByEntity } });
      })
        .catch((error) => {
          dispatch({
            type: MY_LISTS_FETCH_ERROR,
            err: { ...error },
          });
        });
    });
  };
}

export function updateMyList(listId, newItems) {
  return (dispatch) => {
    return api.update(`myLists/${listId}`, { 'items': newItems }).then(() => {
      dispatch({ type: MY_LISTS_UPDATE_REQUEST });
      getSavedLists().then((data) => {
        const myListsByEntity = createListsByEntity(data);
        dispatch({ type: MY_LISTS_FETCH_TRANSLATE_SUCCESS, myLists: { raw: data, myListsByEntity } });
      })
        .catch((error) => {
          dispatch({
            type: MY_LISTS_UPDATE_ERROR,
            err: { ...error },
          });
        });
    });
  };
}

export function fetchSavedLists() {
  return (dispatch) => {
    dispatch({ type: MY_LISTS_FETCH_REQUEST });
    return getSavedLists()
      .then((data) => {
        const myListsByEntity = createListsByEntity(data);
        dispatch({ type: MY_LISTS_FETCH_TRANSLATE_SUCCESS, myLists: { raw: data, myListsByEntity } });
      })
      .catch((error) => {
        dispatch({
          type: MY_LISTS_FETCH_ERROR,
          err: { ...error },
        });
      });
  };
}

export function enableListMode(datatype) {
  clearSelectedResults(datatype);

  return {
    type: ENABLE_LIST_MODE,
    datatype
  };
}

export function disableListMode(datatype) {
  return (dispatch) => { // To-Do: Reset Filters and Maybe send off Request for fresh data for bounds of the map.
    dispatch({ type: DISABLE_LIST_MODE, datatype });
  };
}

export function disableListModeAllDatatypes() {
  return { // To-Do: Figure out where to place (Whenever a user refreshes the browser or logs back in);
    type: DISABLE_LIST_MODE_ALL_DATATYPES 
  };
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

const VirtualSelect = ({
  labelKey,
  onChange,
  onSearch,
  noOptionsText,
  renderOption,
  value,
  ...textFieldProps
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const getOptionLabel = (option) => (labelKey ? option[labelKey] : option) || '';

  const getOptionSelected = (option, value) => {
    return labelKey ? option[labelKey] === value[labelKey] : option === value;
  };

  const handleInputChange = (event, newInputValue) => {
    setLoading(true);
    onSearch(newInputValue).then(({ options }) => {
      setOptions(options);
      setLoading(false);
    });
  };

  const handleSelectionChange = (event, selectedValue) => {
    onChange(selectedValue);
  };

  const toggleOpen = (which) => () => {
    setOpen(which);
  };

  const renderInput = (params) => {
    return (
      <TextField
        {...textFieldProps}
        {...params}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <React.Fragment>
              {loading && <CircularProgress color="inherit" size={20} />}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }} />
    );
  };

  const filterOptions = (options) => {
    return options;
  };

  return (
    <Autocomplete
      autoComplete
      debug
      open={open}
      onOpen={toggleOpen(true)}
      onClose={toggleOpen(false)}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      loading={loading}
      onChange={handleSelectionChange}
      onInputChange={handleInputChange}
      options={options}
      noOptionsText={noOptionsText || undefined}
      renderInput={renderInput}
      renderOption={renderOption || undefined}
      value={value || undefined} />
  );
};

VirtualSelect.propTypes = {
  labelKey: PropTypes.string,
  noOptionsText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  renderOption: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
};

export default VirtualSelect;

import api from '../../../services/api';
import {
  RESEARCH_GRID_COLUMNS_REORDER,
  RESEARCH_GRID_COLUMN_TOGGLE,
  RESEARCH_GRID_HIDE_ALL_COLUMNS_TOGGLE,
  RESEARCH_GRID_SHOW_ALL_COLUMNS_TOGGLE,
  RESEARCH_GRID_GRID_VIEW_REQUEST,
  RESEARCH_GRID_GRID_VIEW_SUCCESS,
  RESEARCH_GRID_GRID_VIEW_ERROR,
  RESEARCH_GRID_GRID_VIEW_SELECT,
  RESEARCH_GRID_GRID_VIEW_DELETE_REQUEST,
  RESEARCH_GRID_GRID_VIEW_DELETE_SUCCESS,
  RESEARCH_GRID_GRID_VIEW_DELETE_ERROR,
  RESEARCH_GRID_GRID_VIEW_SAVE_REQUEST,
  RESEARCH_GRID_GRID_VIEW_SAVE_SUCCESS,
  RESEARCH_GRID_GRID_VIEW_SAVE_ERROR,
  RESEARCH_GRID_GRID_VIEW_UPDATE_REQUEST,
  RESEARCH_GRID_GRID_VIEW_UPDATE_SUCCESS,
  RESEARCH_GRID_GRID_VIEW_UPDATE_ERROR,
  UNAUTHORIZED_REQUEST
} from '../../../constants/actionTypes';
import datatypes from '../../../common/datatypes';

export function reorderColumns(datatype, columns) {
  return {
    type: RESEARCH_GRID_COLUMNS_REORDER,
    columns,
    datatype,
  };
}

export function toggleColumn(datatype, column, isEnabled) {
  return {
    type: RESEARCH_GRID_COLUMN_TOGGLE,
    datatype,
    column,
    isEnabled
  };
}

function requestGridView() {
  return {
    type: RESEARCH_GRID_GRID_VIEW_REQUEST
  };
}

function receiveGridView(items) {
  return {
    type: RESEARCH_GRID_GRID_VIEW_SUCCESS,
    receivedAt: Date.now(),
    items
  };
}

function handleError(type, error) {
  return {
    type,
    error
  };
}

function unauthorizedRequest() {
  return {
    type: UNAUTHORIZED_REQUEST
  };
}

export function fetchGridViews(datatype) {
  return (dispatch) => {
    dispatch(requestGridView());
    return api.read('grid-views', null, { type: datatype })
      .then((json) => dispatch(receiveGridView(json)))
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return dispatch(handleError(RESEARCH_GRID_GRID_VIEW_ERROR, response));
      });
  };
}

export function selectGridView({ id, title, columns, createdBy, datatype }) {
  return {
    type: RESEARCH_GRID_GRID_VIEW_SELECT,
    id,
    title,
    columns,
    createdBy,
    datatype,
  };
}

function saveGridViewRequest(item) {
  return {
    type: RESEARCH_GRID_GRID_VIEW_SAVE_REQUEST,
    item
  };
}

function saveGridViewSuccess(response) {
  return {
    type: RESEARCH_GRID_GRID_VIEW_SAVE_SUCCESS,
    response
  };
}

export function saveGridView(datatype, { title }) {
  return (dispatch, getState) => {
    const { research: { table: { columns } } } = getState();

    const data = {
      title,
      columns: columns[datatype],
      type: datatype
    };

    dispatch(saveGridViewRequest(data));
    return api.create('/grid-views', data)
      .then((json) => {
        dispatch(saveGridViewSuccess(json));
        dispatch(selectGridView({ datatype, ...json }));
        return json;
      })
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return dispatch(handleError(RESEARCH_GRID_GRID_VIEW_SAVE_ERROR, response));
      });
  };
}

function updateGridViewRequest(item) {
  return {
    type: RESEARCH_GRID_GRID_VIEW_UPDATE_REQUEST,
    item
  };
}

function updateGridViewSuccess(response) {
  return {
    type: RESEARCH_GRID_GRID_VIEW_UPDATE_SUCCESS,
    response
  };
}

export function updateGridView(datatype, gridView) {
  return (dispatch, getState) => {
    const { columns } = getState().research.table;

    const data = {
      ...gridView,
      columns: columns[datatype],
      type: datatype
    };
    dispatch(updateGridViewRequest(data));

    return api.update(`/grid-views/${data.id}`, data, true)
      .then((json) => {
        dispatch(updateGridViewSuccess(json));
        dispatch(selectGridView({ datatype: datatypes[datatype], ...json }));
        return json;
      })
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return dispatch(handleError(RESEARCH_GRID_GRID_VIEW_UPDATE_ERROR, response));
      });
  };
}

function deleteGridViewRequest(id, datatype) {
  return {
    type: RESEARCH_GRID_GRID_VIEW_DELETE_REQUEST,
    id,
    datatype
  };
}

function deleteGridViewSuccess(id, datatype) {
  return {
    type: RESEARCH_GRID_GRID_VIEW_DELETE_SUCCESS,
    id,
    datatype
  };
}

export function deleteGridView(datatype, id) {
  return (dispatch, getState) => {
    const { selectedGridViews } = getState().research.table.columnsOrganizer;
    dispatch(deleteGridViewRequest(id, datatype));
    return api.destroy(`/grid-views/${id}`)
      .then((json) => {
        dispatch(deleteGridViewSuccess(id, datatype));
        // if deleting current view, select "Custom"
        if (selectedGridViews[datatype].id === id) {
          dispatch(selectGridView({
            columns: datatypes[datatype].columns,
            datatype,
            id: 'Default',
            title: 'Default'
          }));
        }
        return json;
      })
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(RESEARCH_GRID_GRID_VIEW_DELETE_ERROR, response));
      });
  };
}

export function hideAllColumnsToggle(columns, datatype) {
  return {
    type: RESEARCH_GRID_HIDE_ALL_COLUMNS_TOGGLE,
    columns,
    datatype,
  };
}

export function showAllColumnsToggle(columns, datatype) {
  return {
    type: RESEARCH_GRID_SHOW_ALL_COLUMNS_TOGGLE,
    columns,
    datatype,
  };
}

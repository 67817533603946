import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from '../propertyDetail/module.css';
import { Paper } from '@material-ui/core';
import ColumnFieldStat from '../propertyDetail/common/ColumnFieldStat';
import CircularProgress from '@material-ui/core/CircularProgress';

const convertSquareMetersToSquareFeet = (sqm) => {
  return sqm ? Math.round(sqm * 10.7639) : '\u2013';
};

const formatDate = (date) => {
  return date ? moment(date).format('MM/DD/YYYY') : '\u2013';
};

const formatDollarNumber = (number) => {
  return number ? `$${number.toLocaleString('en-US')}` : '\u2013';
};

const formatNumber = (number) => {
  return number ? numeral(number).format('0,0') : '\u2013';
};

export const ParcelTax = ({ 
  isFetchingParcelDetail, 
  assessments, 
  parcel, 
  transactions 
}) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  useEffect(() => {
    if (!isFetchingParcelDetail) {
      setHasLoaded(true);
    }
  }, [parcel, assessments, transactions]);

  const ownerInformation = (input) => {
    if (!input.owner) {
      return null;
    }

    const {
      ownershipStatus: { description } = {},
      streetAddress,
      locality,
      names = [],
      regionCode,
      postalCode,
    } = input.owner;

    const ownerFullName = names.length ? names[0].fullName : undefined;

    const mailingAddress = [
      streetAddress,
      locality,
      regionCode,
      postalCode
    ].filter((x) => !!x).join(', ');

    return {
      key: 'Owner Information',
      label: 'Owner Information',
      data: [
        [
          {
            key: 'OwnerName1',
            label: 'Owner Name 1',
            value: ownerFullName,
            format: 'string',
          }
        ],
        [
          {
            key: 'Owner Type',
            label: 'Owner Type',
            value: description,
            format: 'string',
          },
          {
            key: 'Mailing Address',
            label: 'Mailing Address',
            value: mailingAddress || '-',
            format: 'string',
          }
        ],
      ]
    };
  };

  const locationInformation = (input) => {
    if (!input.assessment) {
      return null;
    }

    const {
      assessment: {
        book,
        lot: {
          lotNumber,
          blockNumber
        } = {},
        page
      } = {},
      census: {
        blockGroup,
        tract
      } = {},
      county,
      fips,
      legalDescription,
      subdivision,
      usplss: {
        townshipRangeSection
      } = {}
    } = input;

    let mapReference = '';
    if (transactions && transactions[0] && transactions[0].recorder) {
      mapReference = transactions[0].recorder.mapReference;
    }

    return {
      key: 'Location Information',
      label: 'Location Information',
      data: [
        [
          {
            key: 'County',
            label: 'County',
            value: county,
            format: 'string',
          },
          {
            key: 'FIPS Code',
            label: 'FIPS Code',
            value: fips,
            format: 'string',
          },
          {
            key: 'Census Trct/Bllk',
            label: 'Census Trct/Bllk',
            value: `${tract ? tract : '-'} / ${blockGroup ? blockGroup : '-'}`,
            format: 'string',
          },
          {
            key: 'Legal Description',
            label: 'Legal Description',
            value: legalDescription,
            format: 'string',
          }
        ],
        [
          {
            key: 'Twnshp-Rnge-Sect',
            label: 'Twnshp-Rnge-Sect',
            value: townshipRangeSection,
            format: 'string',
          },
          {
            key: 'Legal Land Lot',
            label: 'Legal Land Lot',
            value: lotNumber,
            format: 'string',
          },
          {
            key: 'Legal Block',
            label: 'Legal Block',
            value: blockNumber,
            format: 'string',
          },
          {
            key: 'Legal Book/Page',
            label: 'Legal Book/Page',
            value: `${book ? book : '-'} / ${page ? page : '-'}`,
            format: 'string',
          }
        ],
        [
          {
            key: 'Map Reference',
            label: 'Map Reference',
            value: mapReference,
            format: 'string',
          },
          {
            key: 'Subdivision',
            label: 'Subdivision',
            value: subdivision,
            format: 'string',
          },
        ],
      ]
    };
  };

  const sitePropertyInformation = (input) => {
    if (!input.assessments || input.assessments.length === 0) {
      return null;
    }

    const {
      assessments: [{
        primaryStructure: {
          airConditioning: {
            description: ACdescription = '',
          } = {},
          buildingArea = '',
          construction: {
            description: constructionDescription = '',
          } = {},
          heating: {
            description: heatingDescription = '',
          } = {},
          parkingSpaces = '',
          roof: {
            description: roofDescription = '',
          } = {},
          stories: {
            count = '',
          } = {},
          units = '',
          yearBuilt = '',
        } = {}
      } = {}],
      assessment: {
        lot: {
          depth = '',
          size = '',
          sizeAcreage = '',
          width = '',
        } = {},
        zoning: {
          assessment: assessmentZoning = '',
        },
      } = {},
      derived: {
        calculatedLotArea = '',
        calculatedLotAcreage = '',
      } = {},
      landUse: {
        category = '', 
        description: landUseDescription = '',
      } = {},
      topography = '',
    } = input;

    return {
      key: 'Site & Property Information',
      label: 'Site & Property Information',
      data: [
        [
          {
            key: 'Zoning',
            label: 'Zoning',
            value: assessmentZoning,
            format: 'string',
          },
          {
            key: 'Land Use Desc.',
            label: 'Land Use Desc.',
            value: landUseDescription,
            format: 'string',
          },
          {
            key: 'Land Use Category',
            label: 'Land Use Category',
            value: category,
            format: 'string',
          },
          {
            key: 'Assessor Acreage',
            label: 'Assessor Acreage',
            value: formatNumber(sizeAcreage),
            format: 'number',
          },
          {
            key: 'Calculated Acreage',
            label: 'Calculated Acreage',
            value: formatNumber(calculatedLotAcreage),
            format: 'number',
          },
          {
            key: 'Assessed Lot SF',
            label: 'Assessed Lot SF',
            value: convertSquareMetersToSquareFeet(size),
            format: 'string',
          },
          {
            key: 'Calculated Lot SF',
            label: 'Calculated Lot SF',
            value: convertSquareMetersToSquareFeet(calculatedLotArea),
            format: 'string',
          },
          {
            key: 'Assessor Lot W/D',
            label: 'Assessor Lot W/D',
            value: `${width ? width : '-'} / ${depth ? depth : '-'}`,
            format: 'string',
          }
        ],
        [
          {
            key: 'Building Area',
            label: 'Building Area SF',
            value: convertSquareMetersToSquareFeet(buildingArea),
            format: 'string',
          },
          {
            key: 'No. of Units',
            label: 'No. of Units',
            value: units,
            format: 'string',
          },
          {
            key: 'No. of Stories',
            label: 'No. of Stories',
            value: count,
            format: 'string',
          },
          {
            key: 'Year Built',
            label: 'Year Built',
            value: yearBuilt,
            format: 'string',
          },
          {
            key: 'Parking Spaces',
            label: 'Parking Spaces',
            value: parkingSpaces,
            format: 'string',
          },
          {
            key: 'Construction',
            label: 'Construction',
            value: constructionDescription,
            format: 'string',
          },
          {
            key: 'Roof Type',
            label: 'Roof Type',
            value: roofDescription,
            format: 'string',
          },
          {
            key: 'Heat Type',
            label: 'Heat Type',
            value: heatingDescription,
            format: 'string',
          },
          {
            key: 'Air Cond. Type',
            label: 'Air Cond. Type',
            value: ACdescription,
            format: 'string',
          }
        ],
        [
          {
            key: 'Topography',
            label: 'Topography',
            value: topography,
            format: 'string',
          }
        ]
      ]
    };
  };

  const taxValueInformation = (
    {
      assessment: {
        assessedValue: {
          land: assessedLand,
          year: assessedValueYear,
          total: assessedValueTotal,
          improvements: assessedValueImprovements,
        },
        improvementPercent,
        marketValue: {
          improvements: marketImprovements,
          land: marketValueLand,
          total: marketValueTotal,
          year: marketValueYear,
        },
      },
      tax: {
        amount: taxAmount,
        exemption,
        taxRateAreaCode,
        year: taxYear,
      },
    }) => (
    {
      key:  'Tax and Value Information',
      label: 'Tax and Value Information',
      data: [[
        {
          key: 'Tax Year',
          label: 'Tax Year',
          value: taxYear,
          format: 'string',
        },
        {
          key: 'Property Tax',
          label: 'Property Tax',
          value: formatDollarNumber(taxAmount),
          format: 'string',
        },
        {
          key: 'Tax Rate Code',
          label: 'Tax Rate Code',
          value: taxRateAreaCode,
          format: 'string',
        },
        {
          key: 'Tax Exemption',
          label: 'Tax Exemption',
          value: exemption,
          format: 'string',
        },
      ],
      [
        {
          key: 'Assessed Year',
          label: 'Assessed Year',
          value: assessedValueYear,
          format: 'string',
        },
        {
          key: 'Assessed Value',
          label: 'Assessed Value',
          value: formatDollarNumber(assessedValueTotal),
          format: 'string',
        },
        {
          key: 'Land Value',
          label: 'Land Value',
          value: formatDollarNumber(assessedLand),
          format: 'string',
        },
        {
          key: 'Improvement Value',
          label: 'Improvement Value',
          value: formatDollarNumber(assessedValueImprovements),
          format: 'string',
        },
        {
          key: 'Improvement %',
          label: 'Improvement %',
          value: improvementPercent,
          fromat: 'percentage'
        },
      ],
      [
        {
          key: 'Market Value Year',
          label: 'Market Value Year',
          value: marketValueYear,
          format: 'string',
        },
        {
          key: 'Total Market Value',
          label: 'Total Market Value',
          value: formatDollarNumber(marketValueTotal),
          format: 'string',
        },
        {
          key: 'Land Market Value',
          label: 'Land Market Value',
          value: formatDollarNumber(marketValueLand),
          format: 'string',
        },
        {
          key: 'Market Improvement Value',
          label: 'Market Improvement Value',
          value: formatDollarNumber(marketImprovements),
          format: 'string',
        },
      ],
      ]
    }
  );

  const lastTransferOfOwnershipInfo = (input) => {
    if (!input.assessments || input.assessments.length === 0 || !input.assessments[0].transaction) {
      return null; 
    }

    const {
      assessments: [{
        transaction: {
          lastMarketSale: {
            documentNumnber,
            documentTypeDescription,
            transferDate
          } = {},
          lastNoValueTransfer: {
            book: transferBook,
            page: transferPage
          } = {} 
        }
      }]  
    } = input;

    return {
      key: 'Last Transfer of Ownership',
      label: 'Last Transfer of Ownership',
      data: [
        [{
          key: 'Recording Date',
          label: 'Recording Date',
          value: formatDate(transferDate),
          format: 'string',
        },
        {
          key: 'Doc. Number',
          label: 'Doc. Number',
          value: documentNumnber,
          format: 'string',
        },
        {
          key: 'Doc. Type',
          label: 'Doc. Type',
          value: documentTypeDescription,
          format: 'string',
        }],
        [{
          key: 'Book Number',
          label: 'Book Number',
          value: transferBook,
          format: 'string',
        },
        {
          key: 'Page Number',
          label: 'Page Number',
          value: transferPage,
          format: 'string'
        }],
      ]
    };
  };

  const sections = [
    parcel && Array.isArray(parcel.parcels) && parcel.parcels[0] && parcel.parcels[0].owner
      ? ownerInformation(parcel.parcels[0])
      : { data: [] },

    parcel && Array.isArray(parcel.parcels) 
    && parcel.parcels[0] && parcel.parcels[0].assessment && transactions && Array.isArray(transactions)
      ? locationInformation({ ...parcel.parcels[0], transactions })
      : { data: [] },

    parcel && Array.isArray(parcel.parcels) && parcel.parcels[0] 
    && parcel.parcels[0].assessment && assessments && Array.isArray(assessments)
      ? sitePropertyInformation({ assessments, ...parcel.parcels[0] })
      : { data: [] },

    parcel && Array.isArray(parcel.parcels) && parcel.parcels[0] && parcel.parcels[0].assessment
      ? taxValueInformation(parcel.parcels[0])
      : { data: [] },

    assessments && Array.isArray(assessments)
      ? lastTransferOfOwnershipInfo({ assessments })
      : { data: [] },
  ];

  const validSections = sections.filter((sec) => sec && sec.key);

  return (
    <React.Fragment>
      {hasLoaded ?
        validSections.map((section) => (
          <section key={section.key} className={classes.taxSection}>
            <Paper elevation={2} className={classes.sectionPaper}>
              <div className={classes.sectionHeaderWrapper}>
                <Typography 
                  className={classes.sectionHeader}
                  variant="subtitle1">
                  {section.label}
                </Typography>
              </div>
              <ColumnFieldStat
                sectionArray={section.data ? section.data : '-'}
                fieldNotes={{}}
                specialColumn={null} />
            </Paper>
          </section>
        )) :
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      }
    </React.Fragment>
  );
};

ParcelTax.propTypes = {
  assessments: PropTypes.arrayOf(PropTypes.object),
  isFetchingAssessments: PropTypes.bool,
  isFetchingParcelDetail: PropTypes.bool,
  isFetchingTransactions: PropTypes.bool,
  parcel: PropTypes.object,
  transactions: PropTypes.array,
};

ParcelTax.defaultProps = {
  assessments: [],
  transactions: [],
};

export default withStyles(styles)(ParcelTax);

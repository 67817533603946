import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import { FacetFilter, renderComponent } from 'searchkit-react16';
import cx from 'clsx';
import RelatedAndCustomIdsAccessor from './accessors/RelatedAndCustomIdsAccessor';
import styles from './module.css';

class RelatedAndCustomIdsArrayFilter extends FacetFilter {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    ids: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props) {
    super(props);
    this.state = {
      text: '',
    };
  }

  defineAccessor = () => {
    return new RelatedAndCustomIdsAccessor(this.props.field, this.getAccessorOptions());
  };

  getValues = () => {
    return this.accessor.state.value || [];
  };

  handleChange = (ev) => {
    this.setState({ text: ev.target.value });
  };

  handleEnter = () => {
    const value = this.state.text;
    let newValues = this.getValues();
    if (value && !this.getValues().includes(value)) {
      newValues = newValues.concat(value);
      this.accessor.setQueryString(newValues);
      this.searchkit.reloadSearch();
    }
    this.setState({ text: '', values: newValues });
  };

  handleKeypress = (ev) => {
    if (ev.key === 'Enter') {
      this.handleEnter(ev);
    }
  };

  handleDelete = (v) => {
    return async () => {
      const newValues = this.getValues().filter((val) => v !== val);
      await this.setState({ values: newValues });
      this.accessor.setQueryString(newValues);
      this.searchkit.reloadSearch();
    };
  };

  render() {
    const { classes, containerComponent, title, id } = this.props;
    return renderComponent(
      containerComponent,
      {
        className: id ? `filter--${id}` : undefined,
      },
      <div className={id ? `filter--${id}` : undefined}>
        <Tooltip
          title="Search for IDs related to external sources"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [70, -10],
                },
              },
            ],
          }}>
          <div style={{ marginBottom: '5px', fontWeight: 700, fontSize: '14px', color: '#444' }}>
            {title}
          </div>
        </Tooltip>
        <React.Fragment>
          <TextField
            className={classes.idArrayInputField}
            inputProps={{ className: cx('sk-input-filter__text', classes.idArrayInputElement) }}
            InputProps={{
              classes: { adornedStart: classes.idArrayInputWrapper, focused: classes.idArrayInputFocused },
              startAdornment: <div className={cx(classes.idArrayInputIcon, 'sk-input-filter__icon')} />
            }}
            name="ids"
            onBlur={this.handleEnter}
            onChange={this.handleChange}
            onKeyPress={this.handleKeypress}
            placeholder="Search"
            size="small"
            value={this.state.text}
            variant="outlined" />
          <ul className={classes.idArrayChips}>
            {this.getValues().map((v) => (
              <li className={classes.idArrayChip} key={`id-chip-${v}`}>
                <Chip label={v} onDelete={this.handleDelete(v)} />
              </li>
            ))}
          </ul>
        </React.Fragment>
      </div>
    );
  }
}

export default withStyles(styles)(RelatedAndCustomIdsArrayFilter);

import {
  DETAIL_COMMENT_SAVE_REQUEST,
  DETAIL_COMMENT_SAVE_SUCCESS,
  DETAIL_COMMENT_SAVE_ERROR,
  DETAIL_COMMENT_DELETE_REQUEST,
  DETAIL_COMMENT_DELETE_SUCCESS,
  DETAIL_COMMENT_DELETE_ERROR,
  DETAIL_COMMENTS_FETCH_REQUEST,
  DETAIL_COMMENTS_FETCH_SUCCESS,
  DETAIL_COMMENTS_FETCH_ERROR,
} from '../../../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest,
  receiveData,
  requestData,
} from '../../../common/apiActions';
import api from '../../../services/api';

export function saveComment(body, propertyId) {
  const data = { body, propertyId };
  return (dispatch) => {
    dispatch(requestData(DETAIL_COMMENT_SAVE_REQUEST));
    return api.create('/comments', data)
      .then((response) => dispatch(receiveData(DETAIL_COMMENT_SAVE_SUCCESS, response)))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(DETAIL_COMMENT_SAVE_ERROR, response));
      });
  };
}

export function fetchComments(propertyId) {
  return (dispatch) => {
    dispatch(requestData(DETAIL_COMMENTS_FETCH_REQUEST));
    return api.read(`/comments?propertyId=${propertyId}`)
      .then((data) => dispatch(receiveData(DETAIL_COMMENTS_FETCH_SUCCESS, data)))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(DETAIL_COMMENTS_FETCH_ERROR, response));
      });
  };
}

export function deleteComment(id) {
  return (dispatch) => {
    dispatch(requestData(DETAIL_COMMENT_DELETE_REQUEST));
    return api.destroy('/comments', id)
      .then(() => dispatch(receiveData(DETAIL_COMMENT_DELETE_SUCCESS, { id })))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(DETAIL_COMMENT_DELETE_ERROR, response));
      });
  };
}

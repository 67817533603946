import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { FullStoryAPI as fullStoryAPI } from 'react-fullstory';
import NotificationMessage from '../../common/NotificationMessage';
import DatatypeIcons from '../common/DatatypeIcons';
import { EVENTS as eventNames } from '../../constants/fullstory.js';
import { saveEvent } from '../../common/userEvents/actions';
import styles from './module.css.js';

const MyListRow = ({
  classes,
  list: {
    title,
    timestamp,
    value,
    description
  },
  enableListMode,
  onDeleteList,
  onUpdateList,
  listDeleted,
  listUpdated,
  selectList
}) => {
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [listTitle, setListTitle] = useState(title);
  const [listDescription, setListDescription] = useState(description);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const toggleDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setNotificationMessage('');
    setNotificationOpen(false);
    setIsDeleting(false);
  };

  const handleDelete = () => {
    setIsDeleting(true);
    return listDeleted(value.id)
      .then(() => {
        setIsDeleting(false);
        setDeleteDialogOpen(false);
        onDeleteList(value);
      })
      .catch((error) => {
        setIsDeleting(false);
        setDeleteDialogOpen(false);
        setNotificationOpen(false);
        setNotificationMessage(<NotificationMessage text={`Error deleting list [${error.message}]`} type={'error'} />);
        setNotificationOpen(true);
      });
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationOpen(false);
    setNotificationMessage('');
  };

  const toggleEditDialog = () => {
    setEditDialogOpen(true);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
    setNotificationMessage('');
    setNotificationOpen(false);
    setEditDialogOpen(false);
    setListTitle(title);
    setListDescription(description);
  };

  const handleEdit = () => {
    const data = {
      title: listTitle,
      description: listDescription,
      items: value.items,
      datatype: value.datatype
    };
    Object.assign(data, { title: listTitle, description: listDescription });
    setIsEditing(true);
    return listUpdated(data, value.id)
      .then(() => {
        onUpdateList(listTitle, listDescription, value);
        setIsEditing(false);
        setEditDialogOpen(false);
        setNotificationMessage(<NotificationMessage
          secondaryText={'List updated'}
          text={listTitle}
          type={'success'} />);
        setNotificationOpen(true);
        setListTitle(listTitle);
        setListDescription(listDescription);
      })
      .catch((error) => {
        setIsEditing(false);
        setNotificationOpen(false);
        setNotificationMessage(<NotificationMessage text={`Error saving list [${error.message}]`} type={'error'} />);
        setNotificationOpen(true);
        console.error('handleSave error: ', error);
      });
  };

  const handleTitleChange = (event) => {
    setListTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setListDescription(event.target.value);
  };

  const loadList = () => {
    const event = {
      attributes: value,
      name: eventNames.MYLISTLOADED,
    };
    fullStoryAPI(eventNames.EVENT, eventNames.MYLISTLOADED, { 'myLists_str': eventNames.MYLISTLOADED_CLICKED });
    saveEvent(event);

    selectList(value);
    enableListMode(value.datatype);
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.tableRow}>
        <TableCell className={classes.listTableCell}>
          <p className={classes.listTitle} onClick={loadList}>{title}</p>
        </TableCell>
        <TableCell
          className={classes.listTableCellDescription}>{description}</TableCell>
        <TableCell
          className={classes.listTableCell}>{value.items.length}</TableCell>
        <TableCell className={classes.listTableCell}>
          <div className={classes.listColumnModules}>
            <DatatypeIcons types={[value.datatype]} />
          </div>
        </TableCell>
        <TableCell className={classes.listTableCell}>{moment(timestamp).format('M/D/YYYY h:mm A')}</TableCell>
        <TableCell className={classes.listTableCell}>
          <div className={classes.listColumnActions}>
            <Tooltip title="Edit Name or Description" placement="right-start">
              <IconButton
                className={classes.listTableActionButton}
                onClick={toggleEditDialog}
                size="small">
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete List" placement="top">
              <IconButton
                className={classes.listTableActionButton}
                onClick={toggleDeleteDialog}
                size="small">
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}>
        <DialogTitle>Delete List</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Are you sure you want to delete this list?'}
            <span className={classes.dialogContentText}>{title}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isDeleting}
            onClick={handleDeleteCancel}>Cancel</Button>
          <Button
            color="primary"
            disabled={isDeleting}
            onClick={handleDelete}
            variant="contained">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={editDialogOpen}
        onClose={handleEditCancel}>
        <DialogTitle>Update List</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label={'Title'}
            defaultValue={title} 
            onChange={handleTitleChange} />
          <TextField
            fullWidth
            label={'Description'}
            defaultValue={description}
            onChange={handleDescriptionChange} />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isEditing}
            onClick={handleEditCancel}>Cancel</Button>
          <Button
            color="primary"
            disabled={isEditing}
            onClick={handleEdit}
            variant="contained">Update</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notificationOpen}
        autoHideDuration={5000}
        onClose={handleNotificationClose}>
        <SnackbarContent
          className={classes.snackbackContent}
          message={notificationMessage} />
      </Snackbar>
    </React.Fragment>
  );
};

MyListRow.propTypes = {
  classes: PropTypes.object.isRequired,
  enableListMode: PropTypes.func,
  list: PropTypes.object.isRequired,
  listDeleted: PropTypes.func,
  listUpdated: PropTypes.func.isRequired,
  onDeleteList: PropTypes.func.isRequired,
  onUpdateList:PropTypes.func.isRequired,
  selectList: PropTypes.func
};

export default withStyles(styles)(MyListRow);

import React from 'react';
import PropTypes from 'prop-types';
import Leaflet from 'leaflet';
import difference from 'lodash/difference';
import { renderToString } from 'react-dom/server';
import intersection from 'lodash/intersection';
import trim from 'lodash/trim';
import { useTracker } from '@colliers-international/usage-tracker';
import grey from '@material-ui/core/colors/grey';
import ComboMarkerIcon from '@material-ui/icons/MoreHoriz';
import { get } from 'lodash';
import datatypes from '../../common/datatypes';
import { createMapIcon } from '../../common/datatypes/createDatatype';
import Popup from './Popup';
import listItemContent from './listItemContent';

const shouldShowPopup = (marker) => {
  if (marker && ['news', 'company'].includes(marker.options.type)) {
    return false;
  }

  return true;
};

const comboMarkerColor = grey[800];

class Pins extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    highlightPinId: PropTypes.string,
    map: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onPinsUpdated: PropTypes.func,
    onPopupClose: PropTypes.func.isRequired,
    pins: PropTypes.object.isRequired,
    popup: PropTypes.string,
    router: PropTypes.object,
    trackEvent: PropTypes.func.isRequired
  };

  componentDidMount() {
    const { pins, map } = this.props;
    map.on('load', () => Object.keys(pins).map((id) => this.addPin(pins[id], map, this.props.className)));
  }
  shouldComponentUpdate(nextProps) {
    return this.props.popup !== nextProps.popup
    || this.props.pins !== nextProps.pins
    || this.props.className !== nextProps.className
    || this.props.highlightPinId !== nextProps.highlightPinId;
  }
  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.popup !== nextProps.popup) return;
    const oldPins = Object.keys(this.props.pins);
    const newPins = Object.keys(nextProps.pins);
    if (this.props.className === nextProps.className) {
      difference(oldPins, newPins)
        .map((id) => this.removePin(id, nextProps.map));
      difference(newPins, oldPins)
        .map((id) => this.addPin(nextProps.pins[id], nextProps.map, nextProps.className, nextProps.highlightPinId));
      intersection(newPins, oldPins)
        .map((id) => this.updatePin(nextProps.pins[id], nextProps.map, nextProps.className, nextProps.highlightPinId));
    } else {
      oldPins
        .map((id) => this.removePin(id, nextProps.map));
      newPins
        .map((id) => this.addPin(nextProps.pins[id], nextProps.map, nextProps.className, nextProps.highlightPinId));
    }
  }
  componentDidUpdate() {
    if (this.props.onPinsUpdated) {
      this.props.onPinsUpdated();
    }
  }
  static COMBO_MARKER = {
    color: comboMarkerColor,
    mapIcon: createMapIcon(ComboMarkerIcon),
  };

  onClick = (event, datatype) => {
    const datatypeName = datatype.charAt(0).toUpperCase() + datatype.slice(1);
    this.props.trackEvent({ category: 'Discover Map', action: 'Clicked', name: `${datatypeName} Icon` });
    this.props.router.push({ ...location, query: { currentListView: datatype } });
    this.props.onClick(event.target.options.pindex);
  };

  getClassName = (defaultClass, highlight) => {
    return trim(`${defaultClass} ${highlight ? 'hovered' : ''}`);
  };

  addPin = (pin, map, className, highlightPinId) => {
    const { location, type, pindex } = pin;
    const { color, mapIcon } = datatypes[type] || Pins.COMBO_MARKER;
    const latLng = Array.from(location || []).reverse();
    let tooltipContent;
    const icon = Leaflet.divIcon({
      className: this.getClassName(className, pindex === highlightPinId),
      html: `<div style="background-color: ${color}; color: white;">${mapIcon}</div>`
    });
    const markerText = listItemContent[pin.type];
    if (markerText) {
      const { PrimaryText, SecondaryText } = markerText;
      tooltipContent = [`
      <h4>${renderToString(<PrimaryText {...pin} />)}</h4>
      <h5>${renderToString(<SecondaryText {...pin} />)}</h5>
      `, { direction: 'top', className: 'resultPin' }];
    } else {
      tooltipContent = [`<h4>${pin.name || pin.canonicalName || (pin.address && pin.address.split(',')[0])}</h4>
      <h5>${pin.address || get(pin, 'property.address', '')}</h5>`, { direction: 'top', className: 'resultPin' }];
    }
    const marker = Leaflet.marker(latLng, { icon, pindex, ...pin });
    marker.bindTooltip(...tooltipContent);
    map.on('popupclose', () => {
      marker.bindTooltip(...tooltipContent);
    });

    marker.on('click', (e) => {
      if (shouldShowPopup(marker)) {
        marker.unbindTooltip();
      }
      this.onClick(e, type);
    });
    marker.on('mouseover', () => {
      marker.openTooltip();
    });
    marker.addTo(map);
    return marker;
  };

  updatePin({ type, pindex }, map, className, highlightPinId) {
    const { color, mapIcon } = datatypes[type] || Pins.COMBO_MARKER;
    const leafletId = Object.keys(map._layers).find((id) => map._layers[id].options.pindex === pindex);
    if (leafletId) {
      const highlight = pindex === highlightPinId;
      const icon = Leaflet.divIcon({
        className: this.getClassName(className, highlight),
        html: `<div style="background-color: ${color}; color: white;">${mapIcon}</div>`
      });
      if (map._layers[leafletId].setZIndexOffset) {
        map._layers[leafletId].setZIndexOffset(highlight ? 1000 : 0);
      }
      if (map._layers[leafletId].setIcon) {
        map._layers[leafletId].setIcon(icon);
      }
    }
  }

  removePin(pindex, map) {
    const leafletId = Object.keys(map._layers).find((id) => map._layers[id].options.pindex === pindex);
    const pinLayer = map._layers[leafletId];
    if (pinLayer) {
      pinLayer.remove();
    }
  }

  render() {
    let marker;
    const { popup, map, pins } = this.props;
    if (popup) {
      const leafletId = Object.keys(map._layers).find((leafletId) => map._layers[leafletId].options.pindex === popup);
      marker = map._layers[leafletId];
    }

    if (!shouldShowPopup(marker)) {
      return null;
    }

    return (
      <div>
        <Popup 
          marker={marker} 
          onClose={this.props.onPopupClose} 
          pins={pins}
          trackEvent={this.props.trackEvent} />
      </div>
    );
  }
}

const TrackedPins = (props) => {
  const { trackEvent } = useTracker();

  return (
    <Pins trackEvent={trackEvent} {...props} />
  );
};

export default TrackedPins;

import pick from 'lodash/pick';
import { FullStoryAPI as fullStoryAPI } from 'react-fullstory';
import {
  FAVORITES_LIST_REQUEST,
  FAVORITES_LIST_SUCCESS,
  FAVORITES_LIST_ERROR,
  FEED_LIST_REQUEST,
  FEED_LIST_SUCCESS,
  FEED_LIST_ERROR,
  FEED_LIST_UPDATE_OFFSET,
  FEED_ITEM_EXPAND_TOGGLE,
  FEED_ITEM_HYDRATE_REQUEST,
  FEED_ITEM_HYDRATE_SUCCESS,
  FEED_ITEM_HYDRATE_ERROR,
  FEED_ADD_LOCATION_REQUEST,
  FEED_ADD_LOCATION_SUCCESS,
  FEED_ADD_LOCATION_ERROR,
  FEED_REMOVE_LOCATION_REQUEST,
  FEED_REMOVE_LOCATION_SUCCESS,
  FEED_REMOVE_LOCATION_ERROR,
  FEED_ITEM_SHARE_SHOW,
  FEED_ITEM_SHARE_HIDE,
  FEED_ITEM_SHARE_SEND_REQUEST,
  FEED_ITEM_SHARE_SEND_SUCCESS,
  FEED_ITEM_SHARE_SEND_ERROR,
  FEED_ITEM_FAVORITE_CREATE_REQUEST,
  FEED_ITEM_FAVORITE_CREATE_SUCCESS,
  FEED_ITEM_FAVORITE_CREATE_ERROR,
  FEED_ITEM_FAVORITE_REMOVE_REQUEST,
  FEED_ITEM_FAVORITE_REMOVE_SUCCESS,
  FEED_ITEM_FAVORITE_REMOVE_ERROR,
  FEED_SUMMARY_LIST_REQUEST,
  FEED_SUMMARY_LIST_SUCCESS,
  FEED_SUMMARY_LIST_ERROR,
} from '../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest,
  requestData,
  receiveData
} from '../common/apiActions';
import api, { serializeParams, getHeaders } from '../services/api';
import { EVENTS as eventNames } from './../constants/fullstory.js';

export function fetchFeedList(params, locationId = null, refresh = true) {
  return (dispatch) => {
    dispatch(requestData(FEED_LIST_REQUEST, { refresh }));
    return api.read(`/user/feed${locationId ? `/${locationId}` : ''}?${serializeParams(params)}`)
      .then((data) => dispatch(receiveData(FEED_LIST_SUCCESS, data)))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }
        return dispatch(handleError(FEED_LIST_ERROR, response));
      });
  };
}

export function toggleFeedItem(item, isExpanded) {
  return {
    type: FEED_ITEM_EXPAND_TOGGLE,
    item: { ...item, isExpanded }
  };
}

export function hydrateFeedItem(resource, id) {
  return (dispatch) => {
    dispatch(requestData(FEED_ITEM_HYDRATE_REQUEST));
    return api.read(`/${resource}/${id}`)
      .then((data) => dispatch(receiveData(FEED_ITEM_HYDRATE_SUCCESS, data)))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(FEED_ITEM_HYDRATE_ERROR, response));
      });
  };
}

function addLocationRequest(location) {
  return {
    type: FEED_ADD_LOCATION_REQUEST,
    location
  };
}

function addLocationSuccess(currentUser) {
  return {
    type: FEED_ADD_LOCATION_SUCCESS,
    currentUser
  };
}

function removeLocationRequest(location) {
  return {
    type: FEED_REMOVE_LOCATION_REQUEST,
    location
  };
}

function removeLocationSuccess(currentUser) {
  return {
    type: FEED_REMOVE_LOCATION_SUCCESS,
    currentUser
  };
}

export function removeLocation(location) {
  return (dispatch, getState) => {
    dispatch(removeLocationRequest(location));
    const { currentUser } = getState().auth;
    let result;
    currentUser.follows = currentUser.follows.filter((item) => item.id !== location.id);
    return fetch(`${process.env.REACT_APP_API_URL}/user`, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(currentUser)
    })
      .then((res) => res.json())
      .then((json) => {
        result = json;
        return fetchFeedList({ type: getState().visibleDatatypes })(dispatch);
      })
      .then(() => dispatch(removeLocationSuccess(result)))
      .catch((res) => {
        if (res.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(FEED_REMOVE_LOCATION_ERROR, res));
      });
  };
}

export function addLocation(location) {
  return (dispatch, getState) => {
    dispatch(addLocationRequest(location));
    const { currentUser } = getState().auth;
    currentUser.follows = currentUser.follows || [];

    const follow = pick(location, ['location', 'title', 'street', 'address']);
    follow.propertyId = location.id;
    currentUser.follows.push(follow);
    return fetch(`${process.env.REACT_APP_API_URL}/user`, {
      method: 'PATCH',
      headers: getHeaders(),
      body: JSON.stringify(currentUser)
    })
      .then((res) => res.json())
      .then((json) => {
        dispatch(addLocationSuccess(json));
        fullStoryAPI(eventNames.EVENT, eventNames.LOCATION, { 'Location_str': eventNames.LOCATION_CLICKED });
        return fetchFeedList({ type: getState().visibleDatatypes })(dispatch);
      })
      .catch((res) => {
        if (res.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(FEED_ADD_LOCATION_ERROR, res));
      });
  };
}

export function updateFeedListOffset() {
  return (dispatch, getState) => {
    dispatch({
      type: FEED_LIST_UPDATE_OFFSET,
      offset: getState().feed.offset + 10,
    });
  };
}

export function showShareItem(item) {
  return {
    type: FEED_ITEM_SHARE_SHOW,
    item,
  };
}

export function hideShareItem() {
  return {
    type: FEED_ITEM_SHARE_HIDE,
  };
}

export function sendShareItem(data) {
  return (dispatch) => {
    dispatch(requestData(FEED_ITEM_SHARE_SEND_REQUEST));
    return api.create('/share', data)
      .then((data) => {
        dispatch(receiveData(FEED_ITEM_SHARE_SEND_SUCCESS, data));
      })
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(FEED_ITEM_SHARE_SEND_ERROR, response));
      });
  };
}

export function fetchSummaryList() {
  return (dispatch) => {
    dispatch(requestData(FEED_SUMMARY_LIST_REQUEST));
    return api.read('/user/feed/summary')
      .then((data) => dispatch(receiveData(FEED_SUMMARY_LIST_SUCCESS, data)))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(FEED_SUMMARY_LIST_ERROR, response));
      });
  };
}

export function fetchFavoritesList() {
  return (dispatch) => {
    dispatch(requestData(FAVORITES_LIST_REQUEST));
    return api.read('/user/favorites')
      .then((data) => dispatch(receiveData(FAVORITES_LIST_SUCCESS, data)))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(FAVORITES_LIST_ERROR, response));
      });
  };
}

export function createFavorite(item) {
  return (dispatch) => {
    dispatch(requestData(FEED_ITEM_FAVORITE_CREATE_REQUEST));
    return api.create('user/favorites', { resourceId: item.id })
      .then((createdItem) => dispatch(receiveData(FEED_ITEM_FAVORITE_CREATE_SUCCESS, createdItem)))
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return dispatch(handleError(FEED_ITEM_FAVORITE_CREATE_ERROR, response));
      });
  };
}

export function removeFavorite(favorite) {
  return (dispatch) => {
    dispatch(requestData(FEED_ITEM_FAVORITE_REMOVE_REQUEST));
    return api.destroy('user/favorites', favorite.id)
      .then(() => dispatch(receiveData(FEED_ITEM_FAVORITE_REMOVE_SUCCESS, favorite.id)))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(FEED_ITEM_FAVORITE_REMOVE_ERROR, response));
      });
  };
}

import { green, grey } from '@material-ui/core/colors';

export default (theme) => ({
  addLocationContainer: {
    margin: theme.spacing(),
    padding: theme.spacing(),
  },
  addLocationInput: {
    '& input::placeholder': {
      fontSize: '78%',
    },
  },
  addLocationTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  appbarSpacer: theme.mixins.toolbar,
  avatar: {
    color: 'white',
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  card: {
    margin: 5,
  },
  cardActions: {
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  cardHeader: {
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(20),
    [theme.breakpoints.down(600)]: {
      paddingRight: theme.spacing(2),
    }
  },
  cardSubtitle: {
    lineHeight: 1.5
  },
  cardTitle: {
    fontSize: '130%',
    fontWeight: 800,
    lineHeight: 1.5,
  },
  drawerPaper: {
    maxWidth: '100vw',
    width: 400,
  },
  emptyQuickView: {
    flex: '1',
    padding: `${theme.spacing(6)}px ${theme.spacing(2)}px`,
    textAlign: 'center',
    width: '100%',
  },
  list: {
    height: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  loading: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  location: {
    borderBottom: `1px solid ${grey[300]}`,
  },
  locationOptionIcon: {
    minWidth: 44
  },
  quickViewsColumn: {
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    position: 'relative'
  },
  quickViewsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxWidth: '100%',
    padding: theme.spacing(),
    position: 'relative',
    width: 400
  },
  quickViewsFakeToolbar: {
    backgroundColor: '#e8e8e8',
    position: 'absolute',
    width: '100%',
    zIndex: 0,
    top: 0,
    left: 0,
    right: 0
  },
  quickViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  quickViewListContainer: {
    flex: '1 1 auto',
    height: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
    width: '100%'
  },
  quickViewNotLoading: {
    background: 'white',
    borderBottom: '1px solid #e0e0e0',
    height: 3
  },
  quickViewTitle: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
  },
  selectedLocation: {
    color: green[500],
  },
  shareCardContent: {
    [theme.breakpoints.down(600)]: {
      display: 'none'
    }
  },
  unselectedLocation: {
    color: grey[400],
  },
});
import get from 'lodash/get';
import {
  DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_REQUEST,
  DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_SUCCESS,
  DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_ERROR,
  DETAIL_ASSESSMENT_TRANSACTIONS_CLEAR,
  DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_ERROR,
  DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_REQUEST,
  DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_SUCCESS,
  DETAIL_DEMOGRAPHIC_REPORT_DISABLE_VIEW,
  DETAIL_DEMOGRAPHIC_REPORT_ENABLE_VIEW,
  DETAIL_PARCEL_FETCH_REQUEST,
  DETAIL_PARCEL_FETCH_ERROR,
  DETAIL_PARCEL_FETCH_SUCCESS,
  DETAIL_PARCEL_ASSESSMENTS_FETCH_REQUEST,
  DETAIL_PARCEL_ASSESSMENTS_FETCH_ERROR,
  DETAIL_PARCEL_ASSESSMENTS_FETCH_SUCCESS,
  DETAIL_PARCEL_PROPERTIES_FETCH_REQUEST,
  DETAIL_PARCEL_PROPERTIES_FETCH_ERROR,
  DETAIL_PARCEL_PROPERTIES_FETCH_SUCCESS,
  SET_PARCEL_PROPERTIES_SUCCESS,
} from '../../constants/actionTypes';

const initialState = {
  assessments: [],
  isFetching: false,
  isFetchingAssessments: false,
  isFetchingProperties: false,
  isFetchingTransactions: false,
  isViewReportDisabled: true,
  parcel: [],
  transactions: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DETAIL_PARCEL_FETCH_REQUEST:
      return {
        ...state,
        assessments: [],
        parcel: undefined,
        transactions: [],
        isFetching: true,
      };
    case DETAIL_PARCEL_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        assessments: action.data.assessment,
        parcel: action.data.parcel,
        transactions: action.data.sortedTransactions,
        dataUri: null
      };

    case DETAIL_PARCEL_FETCH_ERROR:
      return {
        ...state,
        isFetching: false
      };

    case DETAIL_PARCEL_ASSESSMENTS_FETCH_REQUEST:
      return {
        ...state,
        assessments: [],
        isFetchingAssessments: true
      };
    case DETAIL_PARCEL_ASSESSMENTS_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingAssessments: false,
        assessments: get(action.data, 'assessments', [])
      };

    case DETAIL_PARCEL_ASSESSMENTS_FETCH_ERROR:
      return {
        ...state,
        isFetchingAssessments: false
      };

    case DETAIL_PARCEL_PROPERTIES_FETCH_REQUEST:
      return {
        ...state,
        isFetchingProperties: true,
        properties: []
      };
    case DETAIL_PARCEL_PROPERTIES_FETCH_SUCCESS:
      return {
        ...state,
        isFetchingProperties: false,
        properties: action.data
      };

    case DETAIL_PARCEL_PROPERTIES_FETCH_ERROR:
      return {
        ...state,
        isFetchingProperties: false
      };

    case DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_REQUEST:
      return {
        ...state,
        isFetchingTransactions: true
      };

    case DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_SUCCESS:
      const currentTransactions = get(state, ['transactions', action.data.id], []);
      return {
        ...state,
        isFetchingTransactions: false,
        transactions: {
          ...state.transactions,
          [action.data.id]: [
            ...currentTransactions,
            ...get(action, 'data.data.transactions', []).filter((t) => {
              return !currentTransactions.find((st) => st.id === t.id);
            })
          ]
        }
      };

    case DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_ERROR:
      return {
        ...state,
        isFetchingTransactions: false
      };

    case DETAIL_ASSESSMENT_TRANSACTIONS_CLEAR:
      return {
        ...state,
        transactions: []
      };

    case DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_REQUEST:
      return {
        ...state,
        isViewReportDisabled: true,
      };

    case DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_SUCCESS:
      return {
        ...state,
        dataUri: action.data.dataUri,
        isViewReportDisabled: true,
      };

    case DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_ERROR:
      return {
        ...state,
        isViewReportDisabled: false,
      };

    case DETAIL_DEMOGRAPHIC_REPORT_ENABLE_VIEW:
      return {
        ...state,
        isViewReportDisabled: false
      };

    case DETAIL_DEMOGRAPHIC_REPORT_DISABLE_VIEW:
      return {
        ...state,
        isViewReportDisabled: true
      };

    case SET_PARCEL_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: action.data
      };
    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import _isArray from 'lodash/isArray';
import { indigo } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AreaIcon from '@material-ui/icons/BorderOuter';
import DateIcon from '@material-ui/icons/Today';
import SpaceUseIcon from '@material-ui/icons/PictureInPicture';
import StaticMap from '../../common/StaticMap';
import FieldNote from '../../common/stats/FieldNote';
import Stat from '../../common/stats/Stat';
import formatStat from '../../common/stats/formatStat';
import { HIDDEN_GUEST_COLUMNS } from '../../common/datatypes/constants';
import { tim as datatype } from '../../common/datatypes/datatypes';
import FullContact from '../propertyDetail/listItem/Lease/FullContact';
import styles from './module.css';

const Info = ({ classes, currentUser, tim }) => {
  const tenantStats = [
    'transactionType',
    'inMarketAt',
    'status',
    'markets',
    'submarkets',
    'micromarkets',
    'spaceUses',
    'sourcedBy',
    'rescourId',
    'tags'
  ]
    .filter((key) => !(currentUser.isGuest && HIDDEN_GUEST_COLUMNS.includes(key)))
    .map((key) => datatype.stats.find((stat) => stat.key === key));
  
  const leaseStats = [
    'currentProperty',
    'currentArea',
    'currentLeaseEndDate',
  ]
    .filter((key) => !(currentUser.isGuest && HIDDEN_GUEST_COLUMNS.includes(key)))
    .map((key) => datatype.stats.find((stat) => stat.key === key));

  const fieldNotes = tim.fieldNotes || {};

  const spaceUseTypes = (tim.spaceUses || []).map((use) => use.type).filter((t) => !!t);
  const tenantRepresentatives = tim.tenantRepresentatives || [];

  // if no info in a section, don't show the section
  const showTenantNeeds = tenantStats.reduce((acc, { key }) => {
    return acc || !!(tim[key] && !(_isArray(tim[key]) && tim[key].length === 0)) || fieldNotes[key];
  }, false);
  const showCurrentLease = leaseStats.reduce((acc, { key }) => {
    return acc || !!(tim[key] && !(_isArray(tim[key]) && tim[key].length === 0)) || fieldNotes[key];
  }, false);

  const getInfoStatFieldNote = (fieldName, extraStyles) => {
    if (fieldNotes[fieldName]) {
      return (
        <FieldNote content={fieldNotes[fieldName]} style={extraStyles} />
      );
    }
    return null;
  };
  const fieldNoteStatStyle = { top: -4 };

  return (
    <div className={classes.infoContainer}>
      <div className={classes.headerGallery}>
        <div className={classes.mapContainer}>
          <StaticMap
            shapes={tim.searchShapes.coordinates}
            shapesColor={indigo[500].replace(/^#/, '')}
            size="600x375"
            zoom={9} />
        </div>
        <div className={classes.statBigContainer}>
          <div className={classes.statBigRow}>
            <div className={classes.statBig}>
              <div>
                <AreaIcon />
                <span>Area Range {getInfoStatFieldNote('targetArea', fieldNoteStatStyle)}</span>
              </div>
              {tim.targetArea && (tim.targetArea.min || tim.targetArea.max) ? (
                <span>
                  {tim.targetArea.min ? tim.targetArea.min.toLocaleString() : ''}
                  {tim.targetArea.min && tim.targetArea.max ? ' - ' : ''}
                  {tim.targetArea.max ? tim.targetArea.max.toLocaleString() : ''}
                  SF
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
          </div>

          <div className={classes.statBigRow}>
            <div className={classes.statBig}>
              <div>
                <DateIcon />
                <span>Move In Date {getInfoStatFieldNote('occupancyDate', fieldNoteStatStyle)}</span>
              </div>
              <span>{tim.occupancyDate || '-'}</span>
            </div>
            <div className={classes.statDivider} />
            <div className={cx([classes.statBig, classes.multirow])}>
              <div>
                <SpaceUseIcon />
                <span>Space Uses {getInfoStatFieldNote('spaceUses', fieldNoteStatStyle)}</span>
              </div>
              <span>{spaceUseTypes.length > 0 ? spaceUseTypes.join(', ') : '-'}</span>
            </div>
          </div>
        </div>
      </div>
      <section>
        {showTenantNeeds && (
          <div className={classes.section}>
            <Typography variant="subtitle1">Tenant Needs</Typography>
            <div className={classes.stats}>
              {tenantStats.map(({ label, key, ...stat }) => {
                const nullValue = fieldNotes[key] ? '-' : null;
                const formattedValue = (!tim[key] || (_isArray(tim[key]) && tim[key].length === 0))
                  ? nullValue
                  : formatStat(tim[key], stat);
                return (
                  <Stat
                    className={classes.stat}
                    fieldNote={fieldNotes[key]}
                    key={key}
                    label={label}
                    showEmpty={false}
                    value={formattedValue} />
                );
              })}
            </div>
          </div>
        )}

        {showCurrentLease && (
          <div className={classes.section}>
            <Typography variant="subtitle1">Current Lease</Typography>
            <div className={classes.stats}>
              {leaseStats.map(({ label, key, ...stat }) => {
                const nullValue = fieldNotes[key] ? '-' : null;
                const formattedValue = (!tim[key] || (_isArray(tim[key]) && tim[key].length === 0))
                  ? nullValue
                  : formatStat(tim[key], stat);
                return (
                  <Stat
                    className={classes.stat}
                    fieldNote={fieldNotes[key]}
                    key={key}
                    label={label}
                    showEmpty={false}
                    value={formattedValue} />
                );
              })}
            </div>
          </div>
        )}

        {(tim.note || fieldNotes.note) && (
          <div className={classes.section}>
            <Typography variant="subtitle1">Notes</Typography>
            <div className={classes.stats}>
              {tim.note}
              {fieldNotes.note && <FieldNote content={fieldNotes.note} />}
            </div>
          </div>
        )}

        {(tenantRepresentatives.length > 0 || fieldNotes.tenantRepresentatives) && (
          <Paper className={classes.contactsWrapper}>
            <FullContact
              fieldNote={fieldNotes.tenantRepresentatives}
              headerPrefix={'Tenant Rep'}
              signatories={tim.tenantRepresentatives}
              expandFirst={true} />
          </Paper>
        )}
      </section>
    </div>
  );
};

Info.propTypes = {
  classes: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  tim: PropTypes.object.isRequired,
};

Info.defaultProps = {
  classes: {},
  currentUser: {},
  tim: {},
};

export default withStyles(styles)(Info);

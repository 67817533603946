import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { ThemeProvider as LegacyThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import legacyTheme from './styles/legacyTheme';
import theme from './styles/theme';
import routes from './routes';

const defaultLegacyTheme = createMuiTheme(legacyTheme);

const defaultTheme = createTheme(theme);

class Root extends React.Component {
  render() {
    return (
      <div className="root-inner">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Provider store={this.props.store}>
            <ThemeProvider theme={defaultTheme}>
              <LegacyThemeProvider theme={defaultLegacyTheme}>
                <CssBaseline />
                <Router history={this.props.history}>
                  {routes(this.props.store)}
                </Router>
              </LegacyThemeProvider>
            </ThemeProvider>
          </Provider>
        </LocalizationProvider>
      </div>
    );
  }
}

Root.propTypes = {
  history: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default Root;

import React from 'react';
import Chip from '@material-ui/core/Chip';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const ListAddButton = ({ 
  isBulk,
  itemId,
  listId,
  listItems,
  updateMyList
}) => {
  const itemExistsInList = listItems.includes(itemId);
  let bulkItemsExists = false;

  if (isBulk) {
    const bulkItemsBooleanArray = itemId.map((item) => listItems.some((el) => el === item));
    bulkItemsExists = bulkItemsBooleanArray.some((el) => el === true);
  }

  const clickButton = (event) => {
    event.stopPropagation();
    if (itemExistsInList && !isBulk) {
      const found = listItems.indexOf(itemId);
      listItems.splice(found, 1);
      updateMyList(listId, listItems);
    } else if (isBulk === true) {
      itemId.map((item) => listItems.includes(item)
        ? null
        : listItems.push(item));
      updateMyList(listId, listItems);
    } else {
      listItems.push(itemId);
      updateMyList(listId, listItems);
    }
  };

  return  (
    <React.Fragment>
      {!isBulk &&
        <Chip
          label={itemExistsInList ? 'Remove' : 'Add To'}
          color={itemExistsInList ? 'secondary' : 'primary'}
          variant="outlined"
          onClick={clickButton} />}
      {isBulk &&
        <Chip
          style={bulkItemsExists ? { border: 'unset', opacity: '1' } : null}
          avatar={bulkItemsExists ? <CheckCircleIcon style={{ fill: 'green' }} /> : null}
          label={bulkItemsExists ? null : 'Add To'}
          color={bulkItemsExists ? 'default' : 'primary'}
          variant="outlined"
          disabled={bulkItemsExists}
          onClick={clickButton} />}
    </React.Fragment>
  );
};

ListAddButton.propTypes = {
  isBulk: PropTypes.bool.isRequired,
  itemId: PropTypes.any.isRequired, // bulkAdd is an Array, singleAdd is string
  listId: PropTypes.string.isRequired,
  listItems: PropTypes.array.isRequired,
  updateMyList: PropTypes.func.isRequired
};

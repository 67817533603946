import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import useSize from '@react-hook/size';
import { MultiGrid, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import SortIcon from '@material-ui/icons/Sort';
import AscIcon from '@material-ui/icons/ArrowDropUp';
import DescIcon from '@material-ui/icons/ArrowDropDown';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import get from 'lodash/get';
import cx from 'clsx';
import { TABLE_ROW_HEIGHT, TABLE_COL_WIDTH_DEFAULT, MAX_REPORT_SIZE } from '../constants';
import Header from './Header';
import Cell from './Cell';
import styles from './module.css';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';

export const notClickablePropertyDetail = (currentListView) => {
  const map = {
    news: 'news',
    tim: 'tim'
  };
  return map[currentListView];
};

const Table = ({ 
  classes, 
  currentListView, 
  createListsWithEntities,
  myLists,
  results,
  columns,
  selectAllResults,
  updateMyList,
  ...props
}) => {
  const headerRef = useRef(null);
  const popoverRef = React.createRef();
  const [, headerHeight] = useSize(headerRef);
  const minColWidth = 75;

  // prepend the results with a header row
  results = [map(columns, 'title')].concat(results);

  const getColumnWidth = ({ index }) => {
    return (columns[index] && columns[index].width) || TABLE_COL_WIDTH_DEFAULT;
  };

  const getRowHeight = ({ index }) => TABLE_ROW_HEIGHT * (index === 0 ? 1.5 : 1);

  const selectAll = () => {
    // Strip the header row
    return selectAllResults(results.slice(1, MAX_REPORT_SIZE + 1), currentListView);
  };

  const clearSelectedResults = () => props.clearSelectedResults(currentListView);

  const getCellContent = (columnIndex, rowIndex) => {
    if (rowIndex === 0) return results[rowIndex][columnIndex];

    const column = columns[columnIndex];
    const { aggregation, key } = column;

    switch (key) {
      case 'select':
        return findIndex(props.selectedResults, { _id: results[rowIndex]._id }) >= 0;
      default:
        if (aggregation) {
          const [arrKey, objAttr] = key.split('.', 2);
          return get(results, `${rowIndex}._source.${arrKey}`, []).map((val) => val[objAttr]);
        }
        return get(results, `${rowIndex}._source.${key}`);
    }
  };

  const sortColumn = (key) => () => {
    return props.orderColumn(currentListView, key);
  };

  const renderOrderIndicator = (column, { key = null, direction = null }) => {
    let icon = <SortIcon />;
    const classNames = [classes.indicator];

    if (column.sortKey === key) {
      icon = direction === 'desc' ? <DescIcon /> : <AscIcon />;
      classNames.push(classes.activeIndicator);
    }

    return (
      <div className="hint--left" aria-label="Sort">
        <IconButton className={cx(classNames)} onClick={sortColumn(column.sortKey)}>
          {icon}
        </IconButton>
      </div>
    );
  };

  const cache = new CellMeasurerCache({
    defaultWidth: TABLE_COL_WIDTH_DEFAULT,
    minWidth: minColWidth,
    fixedHeight: true
  });

  const onSelect = (item, select, isShiftKey) => {
    const { selectedResults, selectionAnchor, setSelectionAnchor, toggleSelectedItem } = props;

    // if selecting a range (and has an anchor of the same type)
    if (isShiftKey && selectionAnchor && selectionAnchor._type === item._type) {
      // and range is non-zero in length
      if (selectionAnchor._id !== item._id) {
        // select all unselected in the range...
        let inRange = false;
        const alreadySelectedIds = (selectedResults || []).map((x) => x._id);
        results.forEach((result) => {
          const isEdgeItem = (result._id === selectionAnchor._id || result._id === item._id);
          if (isEdgeItem) {
            inRange = !inRange;
          }
          if (inRange || isEdgeItem) {
            if (!alreadySelectedIds.includes(result._id)) {
              toggleSelectedItem(result, true, result._type);
            }
          }
        });
        return;
      }
    }

    // update state
    toggleSelectedItem(item, select, item._type);

    // if selecting and not holding shift w/ existing anchor, set anchor
    if (select && !(selectionAnchor && isShiftKey)) {
      setSelectionAnchor(item);

    // if unselecting the selection anchor, unset anchor
    } else if (!select && item._id === selectionAnchor._id) {
      setSelectionAnchor(null);
    }
  };

  // Disabling this lint line because this is not actually a stateless component, it just resembles one.
  // eslint-disable-next-line react/prop-types
  const getCellInner = (key, style, columnIndex, rowIndex) => {
    const { selectedResults } = props;

    const toggleSelect = (e, isSelected) => {
      onSelect(results[rowIndex], isSelected, e.nativeEvent.shiftKey);
    };

    const onDrag = (event, { deltaX }) => {
      props.onColumnResize(columnIndex, Math.max(minColWidth, getColumnWidth({ index: columnIndex }) + deltaX));

      // if dragging last column, make sure scrolling with resize as we go
      if (columnIndex === columns.length - 1 && deltaX > 0) {
        document.querySelector(`.${classes.scrollIdentifier}`).scrollLeft += deltaX + 20;
      }
    };

    const dragHandle = (
      <Draggable
        axis="x"
        defaultClassName={classes.dragHandle}
        onDrag={onDrag}
        position={{ x: 0 }}>
        <span />{/* requires children */}
      </Draggable>
    );

    if (rowIndex === 0) {
      const column = columns[columnIndex];
      if (columnIndex === columns.length || column.hideHeader) {
        return <div key={key} style={style} className={classes.headerCell} />;
      }
      
      return (
        <div key={key} style={style} className={classes.headerCell}>
          {columnIndex === 0 && selectedResults.length === 0 &&
          <Tooltip title="Select All">
            <IconButton className={classes.selectAllButton} onClick={selectAll}>
              <SelectAllIcon />
            </IconButton>
          </Tooltip>
          }
          {columnIndex === 0 && selectedResults.length > 0 &&
          <Tooltip title="Clear Selection">
            <IconButton className={classes.selectAllButton} onClick={clearSelectedResults}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
          }
          <p className={classes.headerCellLabel}>{getCellContent(columnIndex, rowIndex)}</p>
          {column.sortable && renderOrderIndicator(column, props.orderBy)}
          {columnIndex !== 0 && dragHandle}
        </div>
      );
    }
    
    if (columnIndex === 1 && rowIndex !== 0 && !(notClickablePropertyDetail(currentListView))) {
      return (
        <div key={key} style={style} className={classes.titleCell}>
          <Cell
            classes={classes}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            column={columns[columnIndex]}
            isTitleCell={columnIndex === 1 && rowIndex !== 0}
            content={getCellContent(columnIndex, rowIndex)}
            currentListView={currentListView}
            row={results[rowIndex]._source}
            toggleSelectedItem={toggleSelect} />
          {columnIndex !== 0 && dragHandle}
        </div>
      );
    }

    return (
      <div key={key} style={style} className={classes.cell}>
        <Cell
          classes={classes}
          columnIndex={columnIndex}
          rowIndex={rowIndex}
          column={columns[columnIndex]}
          isTitleCell={columnIndex === 1 && rowIndex !== 0}
          content={getCellContent(columnIndex, rowIndex)}
          currentListView={currentListView}
          row={results[rowIndex]._source}
          toggleSelectedItem={toggleSelect} />
        {columnIndex !== 0 && dragHandle}
      </div>
    );
  };

  const cellRenderer = ({ key, style, columnIndex, rowIndex, parent }) => {
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex} >
        {getCellInner(key, style, columnIndex, rowIndex)}
      </CellMeasurer>
    );
  };
  cellRenderer.propTypes = {
    columnIndex: PropTypes.number,
    key: PropTypes.string,
    parent: PropTypes.node,
    rowIndex: PropTypes.number,
    style: PropTypes.string,
  };

  // TODO: Remove this style once React-Virtualized > 9.9.0 is released.
  return (
    <div ref={popoverRef} className={classes.container}>
      <Header
        myLists={myLists}
        columns={columns}
        currentListView={currentListView}
        createListsWithEntities={createListsWithEntities}
        popoverRef={popoverRef}
        ref={headerRef}
        results={results}
        updateMyList={updateMyList}
        {...props} />
      {props.isSearching && !props.searches[currentListView].listMode && <LinearProgress />}
      <MultiGrid
        cellRenderer={cellRenderer}
        classNameBottomLeftGrid={classes.grid}
        classNameBottomRightGrid={cx(classes.grid, classes.scrollIdentifier)}
        columnCount={columns.length}
        columnWidth={getColumnWidth}
        fixedColumnCount={Math.min(columns.length, 2)}
        fixedRowCount={1}
        height={props.containerHeight - headerHeight}
        rowHeight={getRowHeight}
        rowCount={results.length}
        style={{ backgroundColor: 'white' }}
        width={props.containerWidth} />
    </div>
  );
};

Table.propTypes = {
  classes: PropTypes.object,
  clearSelectedResults: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  containerHeight: PropTypes.number.isRequired,
  containerWidth: PropTypes.number.isRequired,
  createListsWithEntities: PropTypes.func.isRequired,
  currentListView: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  isSearching: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  myLists: PropTypes.object.isRequired,
  onColumnResize: PropTypes.func.isRequired,
  orderBy: PropTypes.object,
  orderColumn: PropTypes.func.isRequired,
  results: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired,
  searches: PropTypes.object.isRequired,
  selectAllResults: PropTypes.func.isRequired,
  selectedResults: PropTypes.array.isRequired,
  selectionAnchor: PropTypes.shape({
    _id: PropTypes.string,
    _type: PropTypes.string,
  }),
  setSelectionAnchor: PropTypes.func.isRequired,
  showSelected: PropTypes.bool,
  toggleSelectedItem: PropTypes.func.isRequired,
  updateMyList: PropTypes.func.isRequired,
};

export default withStyles(styles)(Table);

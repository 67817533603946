import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@mui/material/Tooltip';
import FieldNote from '../../../common/stats/FieldNote';
import formatStat from '../../../common/stats/formatStat';
import { withStyles } from '@material-ui/core';
import styles from '../module.css';

const FieldStat = ({ classes, fieldNote, isBoldLabel, item, stat }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const statRef = useRef(null);

  useEffect(() => {
    if (statRef.current) {
      const isTextTruncated = statRef.current.scrollWidth > statRef.current.clientWidth;
      setShowTooltip(isTextTruncated);
    }
  }, [stat.value]);

  const getInfoStatFieldNote = (fieldName, extraStyles) => {
    if (fieldNote) {
      return (
        <FieldNote content={fieldNote} style={extraStyles} />
      );
    }
    return null;
  };
  const fieldNoteStatStyle = { top: -4 };

  return (
    <div key={stat.key} className={classes.stat}>
      <Typography className={isBoldLabel ? classes.boldStatLabel : classes.statLabel}>{stat.label}</Typography>
      {showTooltip ? (
        <Tooltip
          title={stat.value}
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -10],
                },
              },
            ],
          }}>
          <Typography
            ref={statRef}
            component={'span'}
            className={stat.customClass ? stat.customClass : classes.statValue}>
            {formatStat(stat.value, { format: stat.format }, item) || '-'}
            {getInfoStatFieldNote(stat.key, fieldNoteStatStyle)}
          </Typography>
        </Tooltip>
      ) : (
        <Typography
          ref={statRef}
          component={'span'}
          className={stat.customClass ? stat.customClass : classes.statValue}>
          {formatStat(stat.value, { format: stat.format }, item) || '-'}
          {getInfoStatFieldNote(stat.key, fieldNoteStatStyle)}
        </Typography>
      )}
    </div>
  );
};

FieldStat.propTypes = {
  classes: PropTypes.object,
  fieldNote: PropTypes.string,
  isBoldLabel: PropTypes.bool,
  item: PropTypes.object,
  stat: PropTypes.object
};

export default withStyles(styles)(FieldStat);

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SortIcon from '@material-ui/icons/Sort';
import styles from './module.css';

const WidgetTable = ({ cellRenderer, classes, columns, defaultSortColumn, emptyMessage, onRemove, rows }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState({ id: defaultSortColumn, order: 'desc' });
  const [showPagination, setShowPagination] = useState(true);

  useEffect(() => {
    setShowPagination(rows.length > 0);
  }, [rows]);

  const sortedRows = useMemo(() => {
    const sortAbleRows = [...rows].sort((a, b) => {
      const column = (columns).find((col) => col.id === sortColumn.id);
      const valueA = a[column.id];
      const valueB = b[column.id];

      if (column.id === 'items') {
        const lengthA = Array.isArray(valueA) ? valueA.length : 0;
        const lengthB = Array.isArray(valueB) ? valueB.length : 0;
        return sortColumn.order === 'asc' ? lengthA - lengthB : lengthB - lengthA;
      }
  
      const compareResult = Math.sign(valueA < valueB ? -1 : 1);
      return sortColumn.order === 'asc' ? compareResult : -compareResult;
    });

    return sortAbleRows;
  }, [rows, sortColumn]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortClick = (columnId) => {
    if (columnId === 'enabledDataTypes' || columnId === 'datatype') {
      return;
    }

    if (sortColumn.id === columnId) {
      const order = sortColumn.order === 'asc' ? 'desc' : 'asc';
      setSortColumn({ id: columnId, order });
    } else {
      setSortColumn({ id: columnId, order: 'asc' });
    }
  };
    
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table size="small" stickyHeader className={classes.tableHeadRoot}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.sortType === 'array' ? 'right' : 'left'}
                  style={{ minWidth: 200, borderBottom: '2px solid #25408F' }}>
                  {column.label}
                  {(column.id !== 'enabledDataTypes' && column.id !== 'datatype') && 
                  (<IconButton onClick={() => { handleSortClick(column.id); }}>
                    <SortIcon 
                      style={{ transform: 
                        column.id === sortColumn.id  && sortColumn.order === 'asc' ? 'scaleY(-1)' : 'scaleY(1)' }} />
                  </IconButton>)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} style={{ textAlign: 'center', padding: '20px' }}>
                  <Typography variant="body1">
                    {emptyMessage}
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              sortedRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow 
                      key={row.code || row.id}>
                      {columns.map((column) => {
                        return (
                          <TableCell 
                            key={column.id} 
                            align={column.sortType === 'array' ? 'right' : 'left'} 
                            style={column.sortType === 'array' ?  { paddingRight: '64px' } : null}>
                            {cellRenderer(row, column)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={`${showPagination ? classes.tableFooter : classes.hidePagination}`}>
        {showPagination && (
          <TablePagination
            className={classes.tablePagination}
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows.length}
            labelRowsPerPage={'Rows per Page:'}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage} />
        )}
        <Tooltip title="Remove Widget">
          <IconButton className={classes.removeButton} onClick={onRemove}> 
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    </Paper>
  );
};

WidgetTable.propTypes = {
  cellRenderer: PropTypes.func,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array,
  defaultSortColumn: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  rows: PropTypes.array,
};

export default withStyles(styles)(WidgetTable);

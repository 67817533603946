import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import SourceIcon from '@material-ui/icons/Link';
import styles from './module.css';

const useStyles = makeStyles(styles);

const Actions = ({ expand, expanded, item, showProblemReporter, isExpandable }) => {
  const classes = useStyles();
  const showSource = () => window.open(item.sourceUrl);

  // use this for item-specific report
  const showProblemReporterForItem = () => {
    showProblemReporter(item);
  };

  return (
    <div className={classes.actions}>
      {item.sourceUrl && (
        <Button
          onClick={showSource}
          startIcon={<SourceIcon />}>
          Source
        </Button>
      )}
      <div onClick={expand} style={{ flex: 1 }} />
      {(expanded || !isExpandable) && (
        <Button
          className={classes.reportProblemButton}
          onClick={showProblemReporterForItem}>
          Report a Problem
        </Button>
      )}
      {isExpandable && (
        <IconButton onClick={expand}>
          {expanded ? <CollapseIcon /> : <ExpandIcon />}
        </IconButton>
      )}
    </div>
  );
};

Actions.propTypes = {
  expand: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  isExpandable: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  showProblemReporter: PropTypes.func.isRequired
};

export default withRouter(Actions);

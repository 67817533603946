import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import formatStat from '../../../common/stats/formatStat';
import { withStyles } from '@material-ui/core';
import styles from '../module.css';

/**
 * @param {boolean} property
 *    - Property Stats have different padding, boolean to determine class object for wrapper div
 */
const SummaryFieldStats = ({ classes, property, stats }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoverElId, setHoverElId] = useState(null);

  const onMouseEnter = (id) => (event) => {
    setAnchorEl(event.currentTarget);
    setHoverElId(id);
  };
  const onMouseLeave = () => {
    setAnchorEl(null);
    setHoverElId(null);
  };
  return (
    <div className={property ? classes.summmaryPropertyStatsWrapper : classes.summaryFieldStatsWrapper}>
      {stats.map((stat) => {
        return (
          <div key={stat.label} className={classes.summaryFieldStatsStat}>
            {stat.customClass && (
              <React.Fragment>
                <Typography
                  className={stat.customClass}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={onMouseEnter(stat.label)}
                  onMouseLeave={onMouseLeave}>
                  {formatStat(stat.value, { format: stat.format }) || '-'}
                </Typography>
                <Typography className={classes.summaryFieldStatsLabel}>{stat.label}</Typography>
                <Popover
                  key={stat.label}
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  className={classes.sourceSummaryEllipsisPopover}
                  classes={{ paper: classes.sourceSummaryEllipsisContent }}
                  disableRestoreFocus
                  open={hoverElId === stat.label}
                  style={{ pointerEvents: 'none' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                  {formatStat(stat.value, { format: stat.format }) || '-'}
                </Popover>
              </React.Fragment>
            )}
            {!stat.customClass && (
              <React.Fragment>
                <Typography
                  className={classes.summaryFieldStatsData}>
                  {formatStat(stat.value, { format: stat.format }) || '-'}
                </Typography>
                <Typography className={classes.summaryFieldStatsLabel}>{stat.label}</Typography>
              </React.Fragment>
            )}
          </div>
        );
      })}
    </div>
  );
};

SummaryFieldStats.propTypes = {
  classes: PropTypes.object,
  property: PropTypes.any,
  stats: PropTypes.array.isRequired
};

export default withStyles(styles)(SummaryFieldStats);

import grey from '@material-ui/core/colors/grey';

export default (theme) => ({
  fieldNotes: {
    lineHeight: 'normal',
    marginTop: -1,
    position: 'absolute !important',
    left: 0,
  },
  fieldNotesButton: {
    width: '1.4em !important',
    height: '1.4em !important',
    padding: '0 !important',
    opacity: 0.3,
    ['&:hover']: {
      opacity: 0.9,
    }
  },
  fieldNotesContainer: {
    display: 'inline-block',
    marginLeft: '0.3em',
    position: 'relative',
    height: '1em',
    width: '1.4em',
  },
  fieldNotesContent: {
    fontSize: 14,
    maxWidth: 400,
    padding: `${theme.spacing()}px ${theme.spacing(2)}px`,
  },
  fieldNotesIcon: {
    width: '1.4em !important',
    height: '1.4em !important',
  },

  showAll: {
    verticalAlign: 'middle',
  
    ['& a']: {
      color: '0d47a1',
      cursor: 'pointer',
      display: 'inline-block',
    },
  
    ['& button']: {
      verticalAlign: 'middle',
    },
  },

  stat: {
    display: 'flex',
    margin: 0,
    padding: '0 5px',
  
    ['&:nth-child(even)']: {
      backgroundColor: grey[200],
    },
  
    ['&:last-child']: {
      border: 0
    },
  
    ['& strong']: {
      flex: '1 1 0%',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '35px',
      textTransform: 'uppercase',
    },
  
    ['& span']: {
      color: grey[800],
      flex: '3 1 0%',
      fontSize: '14px',
      lineHeight: '35px',
      textAlign: 'right',
    },
  },

  statLabel: {
    fontWeight: 'normal'
  },

  statIcon: {
    [theme.breakpoints.down(600)]: {
      display: 'none',
    }
  },

  statTableSpaceUses: {
    marginTop: theme.spacing(0),
    width: '45%'
  },

  statTableUnitMixes: {
    marginTop: theme.spacing(0),
    width: '30%'
  },

  statTableRow: {
    height: '36px',
  },

  statTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    ['& strong']: {
      flex: '0 1 auto',
      paddingLeft: 3,
    },
    ['& span']: {
      flex: '0 1 auto'
    }
  },

  statValue: {
    fontSize: '160%',
    fontWeight: 700,
    [theme.breakpoints.down(600)]: {
      fontSize: '100%',
      fontWeight: 400
    }
  },

  statValueTruncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  summaryStat: {
    borderRight: `1px solid ${grey[300]}`,
    display: 'flex',
    flex: '1 1 0%',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 70,
    textAlign: 'center',
    ['&:last-child']: {
      border: 0
    },
  },
  summaryStats: {
    display: 'flex',
  },

  ['@media (max-width: 600px)']: {
    statValue: {
      fontSize: '100% !important',
      fontWeight: 'normal !important',
    },
    statTitle: {
      ['& svg']: {
        display: 'none !important',
      }
    },
  },
  signatoryStat: {
    display: 'flex',
  }
});

import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

const AreaChange = ({ value }) => {
  return (
    <div>
      {value.type && <div>Type: {value.type}</div>}
      {value.value && <div>Amount: {numeral(value.value).format('0,0.00[0]')}</div>}
    </div>
  );
};

AreaChange.propTypes = {
  value: PropTypes.any
};

export default AreaChange;

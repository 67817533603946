import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Can from '../../auth/ability';
import { useTracker } from '@colliers-international/usage-tracker';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';
import CommentIcon from '@material-ui/icons/Comment';
import ShowOnMapIcon from '@material-ui/icons/Place';
import { getParcelPath } from '../../common/getParcelPath';
import getReportMenuItems from '../../common/reports';
import { detailReports } from '../common/detailReportIds';
import ReportsMenu from '../propertyDetail/Reports';
import datatypes from '../../common/datatypes';
import listItemContent from './listItemContent';
import { MyListsMenu } from './MyListsMenu';
import { DocumentsMenu } from './DocumentsMenu';
import RightPanelDetails from './listItemContent/RightPanelDetails';

const MapListItem = ({
  classes,
  color,
  createListsWithEntities,
  currentUser,
  currentListView,
  enabledSearches,
  hideAllFilters,
  isSearching,
  isSelected,
  myLists,
  onSelect,
  popup,
  result,
  router,
  selectedProperty,
  selectPopup,
  setListMouseover,
  style = {},
  updateMyList,
  ...props
}) => {
  const excludedTypes = ['tim', 'news', 'company'];
  const {
    _source,
    _type: type,
    _source: { address, hash, id, properties = [], rescourId, documents },
  } = result;
  const { PrimaryText, SecondaryText } = listItemContent[type];
  const property = _source.property || properties[0] || {};
  const _rescourId = (type === 'property' ? rescourId : property && property.rescourId) || '';
  const propertyAddress = property.address || address;
  const propertyHash = property.hash || hash || '';
  const propertyId = property.id || id || '';
  const slug = type === 'tim' ? `market/${id}` : `${propertyHash}|${_rescourId}`;
  const { trackEvent } = useTracker();

  const [hoverHighlight, setHoverHighlight] = useState(propertyId === selectedProperty);
  const includedReportTypes = ['availability', 'listing', 'lease', 'sale'];

  const reportMenuItems = getReportMenuItems({
    currentListView,
    currentUser
  }).filter((report) => detailReports.includes(report.key));

  const hasReports = reportMenuItems.length > 0;

  const showDetails = async (event) => {
    trackEvent({ category: 'Parcel Pop-Up', action: 'Clicked', name: 'Right Panel Property Item' });
    event.stopPropagation();
    const { hasOnlyDefaultFilters } = datatypes[type];
    const enabledSearchesFilter = enabledSearches[type].filters;
    const hasFiltersActive = !hasOnlyDefaultFilters(enabledSearchesFilter);
    hideAllFilters();
    if (type === 'tim') {
      router.push({ ...location, pathname: `/research/map/tim/${id}` });
    } if (hasFiltersActive) {
      const pathname = await getParcelPath({ 
        id: 'filter', address: propertyAddress, hash: propertyHash, slug, type 
      });
      router.push({
        ...location,
        pathname,
      });
    } else {
      const pathname = await getParcelPath({ id, address: propertyAddress, hash: propertyHash, slug, type });
      router.push({
        ...location,
        pathname,
      });
    }
  };

  const showComments = (event) => {
    event.stopPropagation();
    if (type !== 'tim') {
      router.push({ ...location, pathname: `/research/map/${slug}/comments` });
    }
  };

  const showOnMap = (event) => {
    event.stopPropagation();
    if (type !== 'tim') {
      selectPopup(property.hash || hash);
    }
  };

  const toggleSelect = (e, isSelected) => {
    onSelect(result, isSelected, e.nativeEvent.shiftKey);
  };

  const stopPropagation = (e) => e.stopPropagation();

  const handleMouseEnter = () => () => {
    // if has property, use property hash; else (if is property). use hash; else, use id
    setListMouseover(
      result._source.property
        ? result._source.property.hash
        : result._source.hash || result._id
    );
  };

  const handleMouseLeave = () => () => {
    setListMouseover(null);
  };

  useEffect(() => {
    if (selectedProperty === propertyId) {
      return setHoverHighlight(true);
    }
    setHoverHighlight(false);
  }, [selectedProperty]);

  useEffect(() => {
    if (popup === propertyHash) {
      return setHoverHighlight(true);
    }
    setHoverHighlight(false);
  }, [popup]);

  const hexToRgbA = (hex) => {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = `0x${c.join('')}`;
      return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},.1)`;
    }
    throw new Error('Bad Hex');
  };

  const onHover = hoverHighlight
    ? { background: hexToRgbA(color), border: `2px solid ${color}` }
    : { borderBottom: '1px solid rgb(224, 224, 224)' };

  return (
    <ListItem
      id={`list-item-${propertyHash}`}
      button
      onClick={showDetails}
      onMouseEnter={handleMouseEnter()}
      onMouseLeave={handleMouseLeave()}
      style={{
        ...style,
        fontSize: '11px',
        paddingRight: '8px',
        paddingLeft: '8px',
        borderBottom: '1px solid #e0e0e0',
        alignItems: 'center',
        ...onHover,
      }}>
      <div className={classes.listItemWrapper}>
        <ListItemIcon
          className={classes.checkBox}
          style={{ alignSelf: 'flex-start', marginTop: 1 }}>
          <Checkbox
            edge="start"
            checked={isSelected(result)}
            color="default"
            onChange={toggleSelect}
            onClick={stopPropagation} />
        </ListItemIcon>
        <div className={classes.listItemTextContainer}>
          <ListItemText
            style={{ marginBottom: 0 }}
            primary={<PrimaryText {..._source} customClass={classes.ourDealsLogo} />}
            secondary={<SecondaryText {..._source} />}
            secondaryTypographyProps={{
              component: 'span',
              style: { color: '#444', fontSize: '1rem' },
            }} />
          {!excludedTypes.includes(type) && (
            <RightPanelDetails
              classes={classes}
              type={type}
              _source={_source} />
          )}
          <div className={classes.listItemIconsContainer}>
            <Can do="read" on="reports">
              {includedReportTypes.includes(type) && hasReports && (
                <ReportsMenu
                  {...props}
                  currentListView={currentListView}
                  currentUser={currentUser}
                  classes={classes}
                  property={property}
                  record={result}
                  singleReportsList={true} />
              )}
            </Can>
            {documents && documents.length > 0 ? <DocumentsMenu classes={classes} documents={documents}  /> : null}
            <Tooltip title="Show on Map" placement="bottom">
              <IconButton className={classes.listItemIcons} onClick={showOnMap}>
                <ShowOnMapIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Comments" placement="bottom">
              <IconButton
                className={classes.listItemIcons}
                onClick={showComments}>
                <CommentIcon />
              </IconButton>
            </Tooltip>
            {type !== 'news' && (
              <div className={classes.listItemMenuAction}>
                {(currentUser.isGuest === false) && (<MyListsMenu
                  currentUser={currentUser}
                  itemId={id}
                  currentListView={currentListView}
                  createListsWithEntities={createListsWithEntities}
                  classes={classes}
                  isBulk={false}
                  isSearching={isSearching}
                  myLists={myLists}
                  updateMyList={updateMyList} />)}
              </div>)}
          </div>
        </div>
      </div>
    </ListItem>
  );
};

MapListItem.propTypes = {
  arrowRef: PropTypes.node,
  classes: PropTypes.object,
  color: PropTypes.string,
  createListsWithEntities: PropTypes.func.isRequired,
  currentListView: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  enabledSearches: PropTypes.object.isRequired,
  hideAllFilters: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  isSelected: PropTypes.func.isRequired,
  myLists: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  popup: PropTypes.string,
  property: PropTypes.object,
  result: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  selectPopup: PropTypes.func.isRequired,
  selectedEntities: PropTypes.array,
  selectedProperty: PropTypes.string,
  setListMouseover: PropTypes.func.isRequired,
  style: PropTypes.object,
  updateMyList: PropTypes.func.isRequired,
};

export default MapListItem;

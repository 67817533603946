import { useState } from 'react';

export const useCollapsable = (defaultCollapsedState = false) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsedState);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return [collapsed, toggleCollapsed];
};

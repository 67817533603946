import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import FieldNoteIcon from '../../assets/FieldNotes';
import styles from './module.css';

const FieldNote = ({ classes, color, content, style }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const onMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onMouseLeave = () => {
    setAnchorEl(null);
  };

  return [
    <div key={0} className={classes.fieldNotesContainer}>
      <span className={classes.fieldNotes} style={style || {}}>
        <span
          className={classes.fieldNotesButton}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}>
          <FieldNoteIcon color={color} style={{ height: '1.4rem', width: '1.4rem' }} />
        </span>
      </span>
    </div>,
    
    <Popover
      key={1}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      className={classes.fieldNotes}
      classes={{ paper: classes.fieldNotesContent }}
      disableRestoreFocus
      open={Boolean(anchorEl)}
      style={{ pointerEvents: 'none' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
      {content}
    </Popover>
  ];
};

FieldNote.propTypes = {
  classes: PropTypes.object,
  color: PropTypes.string,
  content: PropTypes.string.isRequired,
  style: PropTypes.object
};

export default withStyles(styles)(FieldNote);

import {
  CURRENT_USER_UPDATE_REQUEST,
  CURRENT_USER_UPDATE_SUCCESS,
  CURRENT_USER_UPDATE_ERROR,
  GUEST_LOGIN_REQUEST,
  GUEST_LOGIN_ERROR,
  SELECT_SAVEDSEARCH,
  CURRENT_USER_FETCH_REQUEST,
  CURRENT_USER_FETCH_SUCCESS,
  CURRENT_USER_FETCH_ERROR,
  RESET_APP_STATE,
  SET_AUTH_TOKEN
} from '../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest,
} from '../common/apiActions';
import api from '../services/api';
import { registerPermissions } from './ability';

export function setToken(idToken) {
  return (dispatch) => {
    if (idToken) {
      window.localStorage.setItem('token', idToken);
    } else {
      window.localStorage.removeItem('token');
    }
    dispatch({ type: SET_AUTH_TOKEN, data: idToken });
  };
}

export function fetchUser() {
  return (dispatch) => {
    dispatch({ type: CURRENT_USER_FETCH_REQUEST });
    return api.read('user', null, { sc: true })
      .then((data) => {
        registerPermissions(data.permissions, data.defaultTenantId);
        dispatch({ type: CURRENT_USER_FETCH_SUCCESS, data });
      })
      .catch((error) => dispatch({
        type: CURRENT_USER_FETCH_ERROR,
        error: { 'error_description': (error.statusText || error) }
      }));
  };
}

export function guestLogin(guestLinkId) {
  return (dispatch) => {
    dispatch({ type: GUEST_LOGIN_REQUEST });
    return api.read(`il/${guestLinkId}/auth`)
      .then((data) => {
        window.localStorage.setItem('token', data.token);
        window.localStorage.setItem('isGuest', true);
        return dispatch({ type: SET_AUTH_TOKEN, data: data.token });
      })
      .catch((error) => dispatch({
        type: GUEST_LOGIN_ERROR,
        error: { 'error_description': (error.statusText || error) }
      }));
  };
}

export function softLogout() {
  return (dispatch) => {
    window.localStorage.clear();
    dispatch({ type: RESET_APP_STATE });
  };
}

export function processGuestLink(savedSearch) {
  return (dispatch, getState) => {
    const { research: { searches } } = getState();
    dispatch({ type: SELECT_SAVEDSEARCH, savedSearch, searches });
  };
}

function updateCurrentUserRequest(data) {
  return { type: CURRENT_USER_UPDATE_REQUEST, data };
}

function updateCurrentUserSuccess(data) {
  return { type: CURRENT_USER_UPDATE_SUCCESS, data };
}

export function updateCurrentUser(data) {
  return (dispatch) => {
    dispatch(updateCurrentUserRequest(data));
    return api.update('/user', data)
      .then((updatedUser) => dispatch(updateCurrentUserSuccess(updatedUser)))
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return dispatch(handleError(CURRENT_USER_UPDATE_ERROR, response));
      });
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stat from '../../../common/stats/Stat';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const PrimaryText = ({ canonicalName }) => {
  const classes = useStyles();
  return (
    <div className={classes.primaryText}>
      {canonicalName || '\u2013'}
    </div>
  );
};

PrimaryText.propTypes = {
  canonicalName: PropTypes.string,
};

export const SecondaryText = ({ industry, totalEmployees, locationEmployees }) => {
  const classes = useStyles();
  let employees = `${locationEmployees || 'N/A'} employees`;
  if (locationEmployees && totalEmployees) {
    employees = `${locationEmployees} of ${totalEmployees} total employees`;
  } else if (totalEmployees) {
    employees = `${totalEmployees} total employees`;
  }

  return (
    <React.Fragment>
      <div className={classes.secondaryText}>
        <p>{employees}</p>
      </div>
      <div>
        <Stat 
          className={classes.stat} 
          label="Industry" 
          value={industry || '\u2013'}
          marginValue="5px" />
      </div>
    </React.Fragment>
  );
};

SecondaryText.propTypes = {
  industry: PropTypes.string,
  locationEmployees: PropTypes.number,
  totalEmployees: PropTypes.number,
};

import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory as router } from 'react-router';
import { getParcelPath } from '../../../common/getParcelPath';
import LinearProgress from '@material-ui/core/LinearProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { COLLIERS_TEAL } from '../../../styles/theme';
import styles from './module.css';

const RecordedSalesPopupCard = ({ 
  classes,
  isFetching,
  parcel,
  trackEvent
}) => {
  if (isFetching) {
    return (
      <div className={classes.loadingPopup}>
        <LinearProgress style={{ width: '100%' }} />
      </div>
    );
  }

  const lastMarketSale = get(parcel, 'transaction.lastMarketSale', {});

  const formatDate = (date) => {
    return date ? moment(date).format('MM/DD/YYYY') : '\u2013';
  };

  const formatPrice = (price) => {
    return price ? `$${numeral(price).format('0,0')}` : '\u2013';
  };

  const formatPricePerArea = (price) => {
    return price ? `$${numeral(price).format('0,0.00')}` : '\u2013';
  };

  const columns = ['filingDate', 'value', 'pricePerArea'];

  const columnTitles = {
    'filingDate': 'Recording Date',
    'value': 'Sale Price',
    'pricePerArea': 'Price per SF'
  };

  const navigate = async () => {
    const pathname = await getParcelPath({
      apn: get(parcel, 'assessment.apn', 'parcelApn'),
      fips: get(parcel, 'fips', ''),
      slug: 'info/public',
      type: 'sales'
    });

    router.push({
      ...location,
      pathname
    });
  };
  
  const recordedSalesTableCellClick = () => {
    trackEvent({ category: 'Parcel Pop-Up', action: 'Clicked', name: 'Recorded Sales Recording Date' });
    return navigate();
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        aria-controls={'panel-sales-a-content'}
        id={'panel-sales-a-header'}
        className={classes.accordionSummary}
        style={{ backgroundColor: COLLIERS_TEAL }}>
        <Typography style={{ fontWeight: 'bold' }}>{'RECORDED SALES'}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.popup} style={{ width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow className={classes.popupTableRow}>
                {columns.map((column, i) => (
                  <TableCell 
                    key={i} 
                    className={classes.recordedSalesTableHeader} 
                    align={column === 'filingDate' ? 'left' : 'center'}>{columnTitles[column]}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                {columns.map((column, columnIndex) => (
                  <TableCell 
                    key={columnIndex} 
                    className={classes.recordedSalesTableCell}
                    align={column === 'filingDate' ? 'left' : 'right'}
                    style={{ paddingRight: column === 'filingDate' ? 'inherit' : '35px' }}>
                    {(() => {
                      if (column === 'filingDate') {
                        return (<strong 
                          onClick={recordedSalesTableCellClick}
                          className={classes.parcelValue}
                          style={{ 
                            color: 'rgb(37, 64, 143)', 
                            textDecoration: 'underline', 
                            cursor: 'pointer', 
                            fontSize: '12px', 
                            fontFamily: 'Helvetica Neue, Arial, Helvetica, sans-serif' }}>
                          {formatDate(lastMarketSale[column])}
                        </strong>);
                      } else if (column === 'value') {
                        return formatPrice(lastMarketSale[column]);
                      } else if (column === 'pricePerArea') {
                        return formatPricePerArea(lastMarketSale[column]);
                      } return lastMarketSale[column];
                    })()}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

RecordedSalesPopupCard.propTypes = {
  classes: PropTypes.object,
  isFetching: PropTypes.bool.isRequired,
  parcel: PropTypes.object.isRequired,
  trackEvent: PropTypes.func.isRequired
};

export default withStyles(styles)(RecordedSalesPopupCard);

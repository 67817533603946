import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import GridOnIcon from '@material-ui/icons/GridOn';
import MapIcon from '@material-ui/icons/Map';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import SortIcon from '@material-ui/icons/Sort';
import { getParcelPath } from '../common/getParcelPath';
import { read } from '../services/api';
import store from '../store';
import { enableSales } from '../research/searches/actions';
import OurDealsFilter from './common/OurDealsFilter';
import styles from './module.css';

const columns = [
  { 
    id: 'buyer', 
    label: 'Buyer', 
    align: 'left',
    sortType: 'string'
  },
  { 
    id: 'soldDate', 
    label: 'Date Sold', 
    minWidth: 100,
    align: 'left',
    sortType: 'date'
  },
  {
    id: 'transactionValue',
    label: 'Sale Price',
    minWidth: 170,
    align: 'left',
    sortType: 'number'
  },
  {
    id: 'pricePerSquareFoot',
    label: 'Price / SF',
    minWidth: 170,
    align: 'left',
    sortType: 'number'
  },
  {
    id: 'capRate',
    label: 'Cap Rate',
    minWidth: 170,
    align: 'left',
    sortType: 'number'
  },
  {
    id: 'propertyType',
    label: 'Sale Type',
    minWidth: 170,
    align: 'left',
    sortType: 'string'
  },
  {
    id: 'created',
    label: 'Created',
    minWidth: 170,
    align: 'left',
    sortType: 'date'
  }
];
    
function createData(
  buyer = '', 
  soldDate = '', 
  transactionValue = '', 
  pricePerSquareFoot = '',
  capRate = '', 
  propertyType = '', 
  created = '',
  id = '',
  properties = []
)   {
  const formatDate = (date) => moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : '-';
  const formatDollar = (value) => value ? `$${value.toLocaleString()}` : '';
  const formatpricePerSquareFoot = (value) => value ? `$${value.toLocaleString()}` : '';
  const formatPercentage = (value) => {
    if (typeof value !== 'number') {
      return '';
    }
    
    const formattedValue = (value * 100).toFixed(2);
    return `${formattedValue}%`;
  };  

  return { 
    buyer, 
    soldDate: formatDate(soldDate),
    transactionValue: formatDollar(transactionValue) || '-',
    pricePerSquareFoot: formatpricePerSquareFoot(pricePerSquareFoot) || '-', 
    capRate: formatPercentage(capRate) || '-',
    propertyType: propertyType.toString() || '-',
    created: formatDate(created),
    id,
    properties
  };
}

const SaleOurDealsWidget = ({ 
  classes,
  datatype, 
  enableDatatype,
  onRemove, 
  onMapViewClick, 
  onTableViewClick, 
  router,
  title,
  widgetId
}) => {
  const [records, setRecords] = useState(parseInt(localStorage.getItem(`records_${widgetId}`)) || 10);
  const [rows, setRows] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [tabValue, setTabValue] = useState(() => {
    const storedTabValue = parseInt(localStorage.getItem(`tabValue_${widgetId}`), 10);
    return isNaN(storedTabValue) ? 2 : storedTabValue;
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState({ id: 'created', order: 'desc' });
  const [showPagination, setShowPagination] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      setHasLoaded(false);
      let results;

      if (tabValue === 0) {
        results = await read(`/sales?createdBy=${process.env.REACT_APP_OURDEALS_USER_ID}&filterDate=${records}`);
      } else if (tabValue === 1) {
        results = await read(`/sales?createdBy=null&filterDate=${records}`);
      } else {
        const ourDealsResults = await read(
          `/sales?createdBy=${process.env.REACT_APP_OURDEALS_USER_ID}&filterDate=${records}`
        );
        const nonOurDealsResults = await read(`/sales?createdBy=null&filterDate=${records}`);
        results = [...ourDealsResults, ...nonOurDealsResults];
      }

      const transformedResults = results.map((row) =>
        createData(
          row.buyers ? row.buyers[0].name : '-',
          row.soldDate ? row.soldDate : '',
          row.transactionValue ? row.transactionValue : '',
          row.pricePerSquareFoot ? row.pricePerSquareFoot : '',
          row.capRate ? row.capRate : '',
          row.saleType ? row.saleType : '-',
          row.createdAt ? row.createdAt : '',
          row.id ? row.id : '',
          row.properties ? row.properties : []
        )
      );

      setRows(transformedResults);
      setHasLoaded(true);
      setShowPagination(results.length > 0);
    };

    fetchData();
  }, [tabValue, records]);

  useEffect(() => {
    const tabValueFromStorage = localStorage.getItem(`tabValue_${widgetId}`);
    if (tabValueFromStorage) {
      setTabValue(parseInt(tabValueFromStorage));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(`tabValue_${widgetId}`, tabValue.toString());
  }, [tabValue]);
    
  const sortedRows = useMemo(() => {
    const sortAbleRows = [...rows].sort((a, b) => {
      const column = columns.find((col) => col.id === sortColumn.id);
      let valueA = a[column.id];
      let valueB = b[column.id];

      const extractNumber = (value) => {
        return parseFloat(value.replace(/[^\d.-]/g, '')) || 0;
      };
  
      if (
        ['transactionValue', 'pricePerSquareFoot', 'capRate']
          .includes(column.id)) {
        valueA = extractNumber(valueA);
        valueB = extractNumber(valueB);
      }

      let compareResult;
  
      switch (column.sortType) {
        case 'number':
          compareResult = sortColumn.order === 'asc' ? valueA - valueB : valueB - valueA;
          break;
        case 'date':
          compareResult = sortColumn.order === 'asc'
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
          break;
        case 'string':
        default:
          compareResult = sortColumn.order === 'asc'
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
          break;
      }
  
      return compareResult;
    });
  
    return sortAbleRows;
  }, [rows, sortColumn]);

  const handleRecordChange = (event) => {
    setRecords(event.target.value);
    localStorage.setItem(`records_${widgetId}`, event.target.value.toString());
  };

  useEffect(() => {
    localStorage.setItem(`records_${widgetId}`, records.toString());
  }, [records]);
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const handleSortClick = (columnId) => {
    if (sortColumn.id === columnId) {
      const order = sortColumn.order === 'asc' ? 'desc' : 'asc';
      setSortColumn({ id: columnId, order });
    } else {
      setSortColumn({ id: columnId, order: 'asc' });
    }
  };

  const handleTabChange = (newTabValue) => {
    setTabValue(newTabValue);
    localStorage.setItem(`tabValue_${widgetId}`, newTabValue.toString());
  };

  const handleTableViewClick = () => {
    store.dispatch(enableSales(datatype, rows));
    onTableViewClick({ value: title, key: datatype });
  };
  
  const handleMapViewClick = () => {
    store.dispatch(enableSales(datatype, rows));
    onMapViewClick({ value: title, key: datatype });
  };

  const loadValue = async ({ value }) => {
    try {
      let record;

      if (rows && rows.length > 0) {
        record = rows.find((row) => row.buyer === value);
      } 
    
      const key = datatype;
      const pathname = await getParcelPath({
        address: record.properties[0].address,
        slug: `${record.properties[0].hash}|${record.properties[0].rescourId}`,
        type: key
      });

      router.push({
        pathname,
        search: `?currentListView=${key}`,
        hash: `#18/${record.properties[0].location[1]}/${record.properties[0].location[0]}`,
        query: { currentListView: key } 
      });
      store.dispatch(enableDatatype(key));
    } catch (error) {
      console.error(error);
    }
  };

  const renderTableCellContent = (column, value) => {
    if (column.id === 'buyer') {
      return (
        <Typography
          onClick={() => { loadValue({ value }); }}
          className={classes.loadValue}
          style={{ overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', height: '24px', width: '100%' }}>
          {value}
        </Typography>
      );
    }

    return column.format && typeof value === 'number'
      ? column.format(value)
      : value;
  };

  const dragHandleButton = 
    (
      <IconButton className={classes.dragHandle}>
        <DragHandleIcon className={'dashboard-widget-drag-handle'} />
      </IconButton>
    );

  const headerDropDown = (
    <div>
      <FormControl className={classes.dropDown}> 
        <Select
          name="records"
          value={records}
          onChange={handleRecordChange}>
          <MenuItem value={10}>Last 10 Days</MenuItem> 
          <MenuItem value={20}>Last 20 Days</MenuItem>
          <MenuItem value={30}>Last 30 Days</MenuItem>
        </Select> 
      </FormControl>
    </div>
  );

  const headerButtons = (
    <div className={classes.headerIcons}>
      <Tooltip title="GRID VIEW">
        <IconButton onClick={handleTableViewClick}>
          <GridOnIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="MAP VIEW">
        <IconButton onClick={handleMapViewClick}>
          <MapIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <Card className={classes.card} elevation={5}>
      <div className={classes.tableHeader}>
        <CardHeader 
          action={dragHandleButton}
          title={title} 
          className={classes.title} />

        <OurDealsFilter
          onTabChange={handleTabChange}
          tabValue={tabValue} />

        {headerDropDown}
      
        {headerButtons}
      </div>

      {hasLoaded ? (<CardContent>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader className={classes.tableHeadRoot}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.sortType === 'number' ? 'right' : 'left'}
                      style={{ minWidth: 200, borderBottom: '2px solid #25408F' }}>
                      {column.label}
                      {column.id !== 'source' && (<IconButton onClick={() => { handleSortClick(column.id); }}>
                        <SortIcon 
                          style={{ transform: 
                        column.id === sortColumn.id  && sortColumn.order === 'asc' ? 'scaleY(-1)' : 'scaleY(1)' }} />
                      </IconButton>)}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center', padding: '20px' }}>
                      <Typography variant="body1">
                        No data available for the selected time range.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow 
                          key={index}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell 
                                key={column.id} 
                                align={column.sortType === 'number' ? 'right' : 'left'} 
                                style={column.sortType === 'number' ?  { paddingRight: '64px' } : null}>
                                {renderTableCellContent(column, value)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={`${showPagination ? classes.tableFooter : classes.hidePagination}`}>
            {showPagination && (
              <TablePagination
                className={classes.tablePagination}
                component="div"
                rowsPerPageOptions={[5, 10, 20]}
                count={sortedRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
            )}
            <Tooltip title="Remove Widget">
              <IconButton className={classes.removeButton} onClick={onRemove}> 
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
      </CardContent>) : (<div className={classes.spinner}><CircularProgress  /></div>)}
    </Card>
  );
};

SaleOurDealsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  datatype: PropTypes.string.isRequired,
  enableDatatype: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool,
  onMapViewClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTableViewClick: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  widgetId: PropTypes.number.isRequired
};

export default withStyles(styles)(SaleOurDealsWidget);

import React, { useCallback, useState } from 'react';
import { useTracker } from '@colliers-international/usage-tracker';
import PropTypes from 'prop-types';
import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import moment from 'moment';
import CreateListsMenu from './CreateListsMenu';
import { ListAddButton } from './ListAddButton';
import Tooltip from '@material-ui/core/Tooltip';

export const MyListsMenu = ({
  classes,
  createListsWithEntities,
  currentListView,
  currentUser,
  grid,
  isBulk,
  myLists,
  isSearching,
  itemId,
  updateMyList
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [createListView, setCreateListView] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const validateNewList = useCallback((list) => {
    return !myLists.raw.find((l) => l.title.trim() === list.title.trim());
  }, [myLists]);

  const { trackEvent } = useTracker();

  const onOpenMenu = (event) => {
    event.stopPropagation();
    const trackedEvent = grid ? 
      { category: 'Discover Grid Interface', action: 'Clicked', name:'Add to List' }
      : { category: 'Discover Map Interface', action: 'Clicked', name:'Add to List' };
    trackEvent(trackedEvent);
    setOpenMenu(!openMenu);
    setAnchorEl(event.currentTarget);
    setOpenTooltip(false);
  };

  const onButtonMouseEnter = () => {
    setOpenTooltip(true);
  };

  const onButtonMouseLeave = () => {
    setOpenTooltip(false);
  };

  const setListView = (event) => {
    event.stopPropagation();
    setCreateListView(!createListView);
  };

  const onCloseMenu = (event) => {
    event.stopPropagation();
    setOpenMenu(false);
    setAnchorEl(null);
  };

  const clickAway = (event) => {
    event.stopPropagation();
    onCloseMenu(event);
  };

  const formattedDate = (date) => {
    const newDate = moment(date).format('MM/DD/YYYY');
    return newDate;
  };
  const filteredListsByDatatype = myLists
    && myLists.raw
    && myLists.raw.filter((el) => el.datatype === currentListView);

  const color = isBulk ? 'white' : 'rgb(0, 0, 0, 0.54)';

  const myListsCount = myLists.myListsByEntity !== undefined
    && myLists.myListsByEntity.has(itemId) ? myLists.myListsByEntity.get(itemId).length : 0;

  const guestUser = currentUser && currentUser.isGuest;

  return (
    guestUser ? [] : (<Tooltip 
      open={openTooltip && !openMenu}
      title={isBulk ? 'Add selected items to a list' : 'Add to list'}
      placement="bottom">
      <IconButton 
        className={classes.listItemIcons} 
        style={{ color: isSearching ? 'lightgrey' : `${color}` }}
        onClick={onOpenMenu}
        onMouseEnter={onButtonMouseEnter}
        onMouseLeave={onButtonMouseLeave}>
        {myListsCount ? (
          <Badge badgeContent={myListsCount} color="error">
            <PlaylistAddCheckIcon style={{ color: 'green' }} />
          </Badge>
        ) : (
          <PlaylistAddIcon style={{ color: `${color}` }} />
        )}
        {openMenu && (
          <ClickAwayListener onClickAway={clickAway}>
            <Popper
              open={openMenu}
              anchorEl={anchorEl}
              placement="bottom-end"
              leftoffset={50}
              className={classes.myListsToolTipContainer}
              transition
              modifiers={{
                flip: {
                  enabled: true,
                },
                preventOverflow: {
                  enabled: true,
                  boundariesElement: 'viewPort',
                },
                arrow: {
                  enabled: true,
                  element: arrowRef,
                },
              }}>
              <span ref={setArrowRef} className={classes.arrow} />
              <Grid
                container
                direction="row"
                justifycontent="space-between"
                alignItems="center"
                style={{ marginTop: '8px' }}>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Typography className={classes.myListsTitle}>MY LISTS</Typography>
                </Grid>
                <Grid item xs={4}>
                  <IconButton className={classes.closeButton} onClick={onCloseMenu}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              {createListView ? (
                <CreateListsMenu
                  classes={classes}
                  createListsWithEntities={createListsWithEntities}
                  currentListView={currentListView}
                  selectedEntities={itemId}
                  setListView={setListView}
                  validateNewList={validateNewList} />
              ) : (
                <React.Fragment>
                  <div
                    onClick={setListView}
                    className={classes.createListTitle}>
                    <IconButton className={classes.createListButton} disableRipple>
                      <AddIcon />
                    </IconButton>
                    <h6 className={classes.createListText}>CREATE LIST</h6>
                  </div>
                  <div className={classes.filteredListsContainer}>
                    {filteredListsByDatatype.map((item) => (
                      <div className={classes.myListItemContainer} key={item.id}>
                        <div className={classes.myListItemInfo}>
                          <h6 className={classes.myListItemTitle}>{item.title}</h6>
                          <p className={classes.myListItemDate}>
                            {formattedDate(item.updatedAt)}
                          </p>
                        </div>
                        <ListAddButton
                          isBulk={isBulk}
                          itemId={itemId}
                          listId={item.id}
                          listItems={item.items}
                          updateMyList={updateMyList} />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              )}
            </Popper>
          </ClickAwayListener>
        )}
      </IconButton>
    </Tooltip>)
  );
};

MyListsMenu.propTypes = {
  classes: PropTypes.object,
  createListsWithEntities: PropTypes.func.isRequired,
  currentListView: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  grid: PropTypes.bool,
  isBulk: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool,
  itemId: PropTypes.any.isRequired,
  myLists: PropTypes.object.isRequired,
  selectedEntities: PropTypes.array,
  selectedResults: PropTypes.array,
  updateMyList: PropTypes.func.isRequired,
};

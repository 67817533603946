import React from 'react';
import PropTypes from 'prop-types';
import AuthenticatedImage from './AuthenticatedImage';

const StaticMap = ({ location, size, zoom, shapes, shapesColor }) => {
  const params = { size };
  if (shapes) {
    const hex = shapesColor || '000000';
    params._path = JSON.stringify(shapes.map((shape) => {
      return `color:0x${hex}BB|weight:2|fillcolor:0x${hex}55|`
              + `${shape[0].map((coords) => `${coords[1]},${coords[0]}`).join('|')}`;
    }));
  } else {
    const latLng = [location[1], location[0]].join(',');  // location is [lng, lat]
    params.zoom = zoom;
    params.maptype = 'roadmap';
    params.center = latLng;
    params.markers = `anchor:center|icon:https://s3.amazonaws.com/colliers-public-assets/dot.png|${latLng}`;
  }

  const serializedParams = Object
    .keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  const dimensions = size.split('x');
  return (
    <AuthenticatedImage
      height={dimensions && dimensions[1] && parseInt(dimensions[1])}
      url={`${process.env.REACT_APP_API_URL}/maps/api/staticmap?${serializedParams}`}
      width={dimensions && dimensions[0] && parseInt(dimensions[0])} />
  );
};

StaticMap.propTypes = {
  location: PropTypes.array,
  shapes: PropTypes.array,
  shapesColor: PropTypes.string,
  size: PropTypes.string,
  zoom: PropTypes.number
};

StaticMap.defaultProps = {
  size: '100x100',
  zoom: 13
};

export default StaticMap;

import get from 'lodash/get';
import {
  DETAIL_PARCEL_FETCH_REQUEST,
  DETAIL_PARCEL_FETCH_ERROR,
  DETAIL_PARCEL_FETCH_SUCCESS,
  DETAIL_PARCEL_ASSESSMENTS_FETCH_REQUEST,
  DETAIL_PARCEL_ASSESSMENTS_FETCH_ERROR,
  DETAIL_PARCEL_ASSESSMENTS_FETCH_SUCCESS,
  DETAIL_PARCEL_PROPERTIES_FETCH_REQUEST,
  DETAIL_PARCEL_PROPERTIES_FETCH_ERROR,
  DETAIL_PARCEL_PROPERTIES_FETCH_SUCCESS,
  DETAIL_PARCEL_PROPERTIES_SELECT,
  DETAIL_PARCEL_PROPERTIES_CLEAR,
  DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_REQUEST,
  DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_SUCCESS,
  DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_ERROR,
  DETAIL_ASSESSMENT_TRANSACTIONS_CLEAR,
  SET_PARCEL_PROPERTIES_SUCCESS
} from '../../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest,
  requestData,
  receiveData
} from '../../common/apiActions';
import { hideAllFilters } from '../searches/actions';
import api from '../../services/api';

export function fetchParcel({ apn, fips }) {
  return async (dispatch) => {
    dispatch(requestData(DETAIL_PARCEL_FETCH_REQUEST, { fips, apn }));
    return api.read(`/parcels/fips/${fips}/apn/${apn}`)
      .then((data) => {
        const assessments = get(data, 'assessment.assessments', []);
        const transactions = get(data, 'transactions.transactions', []);
        const sortedTransactions = {};

        assessments.forEach((assessment) => {
          const assessmentId = assessment.id;
          sortedTransactions[assessmentId] = transactions.filter((t) => t.assessmentId === assessmentId);
        });

        const enhancedData = {
          ...data,
          sortedTransactions
        };

        return dispatch(receiveData(DETAIL_PARCEL_FETCH_SUCCESS, enhancedData));
      })
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        if (response.status === 404) {
          return dispatch(handleError(DETAIL_PARCEL_FETCH_ERROR, 'Parcel not found'));
        }

        if (response.json) {
          return response.json()
            .then((error) => {
              return dispatch(handleError(DETAIL_PARCEL_FETCH_ERROR, error));
            });
        }
        return dispatch(handleError(DETAIL_PARCEL_FETCH_ERROR, response));
      });
  };
}

export function fetchParcelByAddress({ address }) {
  return async (dispatch) => {
    dispatch(requestData(DETAIL_PARCEL_FETCH_REQUEST, { address }));
    return api.read(`/parcels?address=${encodeURIComponent(address)}`, null, null, { timeout: 5000 })
      .then((data) => {
        return dispatch(receiveData(DETAIL_PARCEL_FETCH_SUCCESS, get(data, 'parcels.0')));
      })
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        if (response.status === 404) {
          return dispatch(handleError(DETAIL_PARCEL_FETCH_ERROR, 'Parcel not found'));
        }

        if (response.json) {
          return response.json()
            .then((error) => {
              return dispatch(handleError(DETAIL_PARCEL_FETCH_ERROR, error));
            });
        }
        return dispatch(handleError(DETAIL_PARCEL_FETCH_ERROR, response));
      });
  };
}

export function fetchParcelAssessments({ id }) {
  return async (dispatch) => {
    dispatch(requestData(DETAIL_PARCEL_ASSESSMENTS_FETCH_REQUEST, { id }));
    return api.read(`/parcels/${id}/assessments`)
      .then((data) => {
        return dispatch(receiveData(DETAIL_PARCEL_ASSESSMENTS_FETCH_SUCCESS, data));
      })
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        if (response.status === 404) {
          return dispatch(handleError(DETAIL_PARCEL_ASSESSMENTS_FETCH_ERROR, 'Assessments not found'));
        }

        if (response.json) {
          return response.json()
            .then((error) => {
              return dispatch(handleError(DETAIL_PARCEL_ASSESSMENTS_FETCH_ERROR, error));
            });
        }
        return dispatch(handleError(DETAIL_PARCEL_ASSESSMENTS_FETCH_ERROR, response));
      });
  };
}

export function fetchParcelProperties({ geometry }) {
  return async (dispatch) => {
    dispatch(requestData(DETAIL_PARCEL_PROPERTIES_FETCH_REQUEST, { geometry }));
    dispatch(hideAllFilters());
    return api.read(`/properties?geometry=${geometry}`)
      .then((data) => dispatch(receiveData(DETAIL_PARCEL_PROPERTIES_FETCH_SUCCESS, data)))
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        if (response.status === 404) {
          return dispatch(handleError(DETAIL_PARCEL_PROPERTIES_FETCH_ERROR, 'Properties not found'));
        }

        if (response.json) {
          return response.json()
            .then((error) => {
              return dispatch(handleError(DETAIL_PARCEL_PROPERTIES_FETCH_ERROR, error));
            });
        }
        return dispatch(handleError(DETAIL_PARCEL_PROPERTIES_FETCH_ERROR, response));
      });
  };
}

export function fetchParcelAssessmentTransactions({ id }) {
  return async (dispatch) => {
    dispatch(requestData(DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_REQUEST, { id }));
    return api.read(`/parcels/assessments/${id}/transactions`)
      .then((data) => {
        return dispatch(receiveData(DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_SUCCESS, { data, id }));
      })
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        if (response.status === 404) {
          return dispatch(handleError(DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_ERROR, 'Transactions not found'));
        }

        if (response.json) {
          return response.json()
            .then((error) => {
              return dispatch(handleError(DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_ERROR, error));
            });
        }
        return dispatch(handleError(DETAIL_ASSESSMENT_TRANSACTIONS_FETCH_ERROR, response));
      });
  };
}

export function clearParcelAssessmentTransactions() {
  return {
    type: DETAIL_ASSESSMENT_TRANSACTIONS_CLEAR
  };
}

export function setCurrentProperty(id) {
  return {
    type: DETAIL_PARCEL_PROPERTIES_SELECT,
    id
  };
}

export function setParcelProperties(properties) {
  return {
    type: SET_PARCEL_PROPERTIES_SUCCESS,
    data: properties
  };
}

export function clearCurrentProperty() {
  return {
    type: DETAIL_PARCEL_PROPERTIES_CLEAR
  };
}

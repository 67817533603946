import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import MyBookmarksWidgetTable from './MyBookmarksWidgetTable';
import styles from './module.css';

const MyBookmarksWidget = memo(({ classes, onRemove }) => {
  const dragHandleButton = 
    (
      <IconButton className={classes.dragHandle}>
        <DragHandleIcon className={'dashboard-widget-drag-handle'} />
      </IconButton>
    );

  return (
    <Card className={classes.card} elevation={5}>
      <div className={classes.myBookmarksTableHead}>
        <CardHeader
          action={
            <div className={classes.myBookmarksCardHeader}>
              {dragHandleButton}
            </div>
          }
          title={
            <div style={{ display: 'flex', alignItems: 'center' }} >
              MY BOOKMARKED APPS
              <Tooltip title="Displaying your bookmarked apps from MyColliers" placement="right">
                <InfoOutlinedIcon style={{ color: 'grey', marginLeft: '8px' }} />
              </Tooltip>
            </div>
          }
          className={classes.title} />
      </div>

      <CardContent>
        <MyBookmarksWidgetTable onRemove={onRemove} />
      </CardContent>
    </Card>
  );
});

MyBookmarksWidget.displayName = 'MyBookmarksWidget';

MyBookmarksWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  onManage: PropTypes.func.isRequired,
  onMapViewClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTableViewClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(MyBookmarksWidget);

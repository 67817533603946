import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import { withStyles } from '@material-ui/core';
import styles from '../module.css';

const Documents = ({ classes, documents }) => {
  return (
    <section>
      <Paper elevation={1} className={classes.sectionPaper}>
        <Typography className={classes.sectionHeader} variant="subtitle1">Documents</Typography>
        <div className={classes.documents}>
          <Table size="small" className={classes.documentsTable}>
            <TableHead>
              <TableRow>
                <TableCell>File Name</TableCell>
                <TableCell>Attachment Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((doc, key) => {
                return (
                  <TableRow key={key} className={classes.documentsTableRow}>
                    <TableCell>
                      <ListItem
                        button
                        component="a"
                        href={doc.url}
                        target={'_blank'}>
                        <ListItemIcon><FileIcon /></ListItemIcon>
                        <ListItemText>{doc.title || doc.fileName}</ListItemText>
                      </ListItem>
                    </TableCell>
                    <TableCell>{doc.attachmentType}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    </section>
  );
};

Documents.propTypes = {
  classes: PropTypes.object,
  documents: PropTypes.array
};

export default withStyles(styles)(Documents);

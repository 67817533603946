import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Can from './auth/ability';
import NotFound from './NotFound';

const Styleguide = () => (
  <div style={{ height: 'calc(100vh - 64px)', overflowY: 'scroll', padding: 24, width: '100vw' }}>
    <Can do="read" on="styleguide">
      <Typography style={{ borderRadius: '4px, 0, 0 0', marginBottom: 18 }} variant="h2">
        Discover Styleguide
      </Typography>

      <Paper elevation={3} style={{ marginBottom: 24 }}>
        <Box borderRadius={4} style={{ backgroundColor: 'gray', padding: 16 }}>
          <Typography variant="h3">Typography</Typography>
        </Box>
        <Container style={{ padding: 8 }}>
          <Typography variant="h4">Variants</Typography>
          <Divider variant="middle" />
          <Typography paragraph variant="h1">This is the `h1` variant</Typography>
          <Typography paragraph variant="h2">This is the `h2` variant</Typography>
          <Typography paragraph variant="h3">This is the `h3` variant</Typography>
          <Typography paragraph variant="h4">This is the `h4` variant</Typography>
          <Typography paragraph variant="h5">This is the `h5` variant</Typography>
          <Typography paragraph variant="h6">This is the `h6` variant</Typography>
          <Typography paragraph variant="subtitle1">This is the `subtitle1` variant</Typography>
          <Typography paragraph variant="subtitle2">This is the `subtitle2` variant</Typography>
          <Typography paragraph variant="body1">This is the `body1` variant</Typography>
          <Typography paragraph variant="body2">This is the `body2` variant</Typography>
          <Typography paragraph variant="caption">This is the `caption` variant</Typography>
          <Typography paragraph variant="button">This is the `button` variant</Typography>
          <Typography paragraph variant="overline">This is the `overline` variant</Typography>
          <Typography paragraph variant="inherit">This is the `inherit` variant</Typography>
        </Container>
        <Container style={{ padding: 8 }}>
          <Typography variant="h4">Colors</Typography>
          <Divider variant="middle" />
          <Typography color="primary" paragraph variant="h5">This is the `primary` color</Typography>
          <Typography color="secondary" paragraph variant="h5">This is the `secondary` color</Typography>
          <Typography color="textPrimary" paragraph variant="h5">This is the `textPrimary` color</Typography>
          <Typography color="textSecondary" paragraph variant="h5">This is the `textSecondary` color</Typography>
          <Typography color="error" paragraph variant="h5">This is the `error` color</Typography>
        </Container>
      </Paper>
      <Paper elevation={3} style={{ marginBottom: 24 }}>
        <Box borderRadius={4} style={{ backgroundColor: 'gray', padding: 16 }}>
          <Typography variant="h3">Buttons</Typography>
        </Box>
        <Container style={{ padding: 8 }}>
          <Typography variant="h4">Variants</Typography>
          <Divider variant="middle" style={{ marginBottom: 16 }} />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button color="primary" variant="contained">`Contained` Variant</Button> 
            <Button color="primary" variant="outlined">`Outlined` Variant</Button> 
            <Button color="primary" variant="text">`Text` Variant</Button> 
          </div>
        </Container>
        <Container style={{ padding: 8 }}>
          <Typography variant="h4">Colors</Typography>
          <Divider variant="middle" style={{ marginBottom: 16 }} />
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
            <Button color="default" variant="contained">`Contained` Variant, `Default` Color</Button> 
            <Button color="primary" variant="contained">`Contained` Variant, `Primary` Color</Button> 
            <Button color="secondary" variant="contained">`Contained` Variant, `Secondary Color</Button> 
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 16 }}>
            <Button color="default" variant="outlined">`Outlined` Variant, `Default` Color</Button> 
            <Button color="primary" variant="outlined">`Outlined` Variant, `Primary` Color</Button> 
            <Button color="secondary" variant="outlined">`Outlined` Variant, `Secondary` Color</Button> 
          </div>
        </Container>
        <Container style={{ padding: 8 }}>
          <Typography variant="h4">Sizes</Typography>
          <Divider variant="middle" style={{ marginBottom: 16 }} />
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button color="primary" size="large" variant="contained">`Large` Size</Button> 
            <Button color="primary" size="medium" variant="contained">`Medium` Size</Button> 
            <Button color="primary" size="small" variant="contained">`Small` Size</Button> 
          </div>
        </Container>
      </Paper>
    </Can>
    <Can not do="read" on="styleguide">
      <NotFound />
    </Can>
  </div>
);

export default Styleguide;

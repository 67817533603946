import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DistanceIcon from '@material-ui/icons/CompareArrows';
import PlaceIcon from '@material-ui/icons/Place';
import plur from 'plur';
import Can from '../../../auth/ability';
import CurateCan from '../../../auth/CurateUserAbility';
import OurDealsLogo, { renderOurDeals } from '../../../common/OurDealsLogo';
import formatStat from '../../../common/stats/formatStat';
import getReportMenuItems from '../../../common/reports';
import { detailReports } from '../../common/detailReportIds';
import ReportsMenu from '../Reports';
import NearbyLink from './NearbyLink';
import * as listContent from './content';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Create from '@material-ui/icons/Create';
import styles from './module.css';

const useStyles = makeStyles(styles);

export default function getTitles(
  { nearbyDistance, ...item }, 
  property, 
  currentUser, 
  currentListView,
  result,
  props
) {
  const classes = useStyles();
  const { _type: resource, __tenant: tenant, rescourId } = item;
  const isOurDeals = renderOurDeals({ datatype: resource, item });
  const includedReportTypes = ['availability', 'listing', 'lease', 'sale'];

  const reportMenuItems = getReportMenuItems({
    currentListView,
    currentUser
  }).filter((report) => detailReports.includes(report.key));

  const hasReports = reportMenuItems.length > 0;

  const handleCurateClick = (event) => {
    event.stopPropagation();
  };
  const commonSummaryAddress = (datatype) => {
    const datatypes = ['sale', 'listing', 'development'];
    return datatypes.includes(datatype);
  };
  const subheader = (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {nearbyDistance && (
        <div style={{ marginRight: 16 }}>
          <PlaceIcon
            style={{
              height: '18px',
              marginRight: '3px',
              position: 'relative',
              top: '4px',
              width: '18px'
            }} />
          {property.name || (property.address && property.address.split(',')[0])}
          <DistanceIcon
            style={{
              height: '18px',
              margin: '0 4px',
              position: 'relative',
              top: '4px',
              width: '18px'
            }} />
          {nearbyDistance} miles away
        </div>
      )}
      {listContent[item._type].Subtitle && listContent[item._type].Subtitle(item)}
    </div>
  );

  let specialTitle = '';
  if (item._type === 'availability') {
    if (item.area && item.property && item.property.address) {
      const { area, property: { address } } = item;
      specialTitle = `${formatStat(area, { format: 'number' })} SF @ ${address}`;
    } else if (item.area) {
      specialTitle = `${item.area} SF`;
    } else if (item.property.address) {
      specialTitle = `${item.property.address}`;
    }
  } else if (item.tenant && item.tenant.name) {
    specialTitle = item.tenant.name;
  }

  const nearbyProperty = item.property || (item.properties && item.properties[0]) || {};

  const title = (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <NearbyLink className={classes.title} {...item} property={nearbyProperty}>
          {specialTitle || item.canonicalName}
          {item._type === 'lease' && <span>
            {item.floors &&
            <span className={classes.specialTitleWrapper}>
              <span className={classes.specialTitleLabel}> Floors </span>
              <span className={classes.specialTitleValue}>
                {item.floors && item.floors.length > 1 ? item.floors.join(', ') : item.floors}</span>
            </span>}
            {item.suite &&
            <span className={classes.specialTitleWrapper}>
              <span className={classes.specialTitleLabel}> Suite </span>
              <span className={classes.specialTitleValue}>{item.suite}</span>
            </span>}
          </span>}
          {commonSummaryAddress(item._type) && (
            <span className={classes.specialTitleWrapper}>
              {item._type !== 'development' && item.properties && 
                <span className={classes.specialTitleValue}>  {item.properties[0].address}</span>}
              {item._type === 'development' && item.property.address &&
                <span className={classes.specialTitleValue}>  {item.property.address}</span>}
            </span>
          )}
        </NearbyLink>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '12px' }}>
          <Can do="use" on="_app">
            {isOurDeals && (
              <OurDealsLogo customClass={classes.ourDealsLogo} />
            )}
          </Can>
          <Can do="read" on="reports">
            {includedReportTypes.includes(currentListView) && hasReports && (
              <ReportsMenu
                {...props}
                classes={classes}
                currentListView={currentListView}
                currentUser={currentUser}
                property={property}
                record={result}
                singleReportsList={true} />
            )}
          </Can>
          <CurateCan
            do="read"
            on={plur(resource)}
            tenant={tenant}>
            <Tooltip title="Edit this Record" placement="right">
              <IconButton
                className={classes.curateEditIcon}
                target="_blank"
                onClick={handleCurateClick}
                href={`${process.env.REACT_APP_CURATE_APP_DOMAIN}/manager/${resource}/edit/${resource}/${rescourId}`}>
                <Create />
              </IconButton>
            </Tooltip>
          </CurateCan>
        </div>
      </div>
    </React.Fragment>
  );

  return {
    title,
    subheader,
  };
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { previousPath } from '../../../common/getLocationTitles';
import { getParcelPath } from '../../../common/getParcelPath';

const NearbyLink = ({ _type, children, className, id, property }) => {
  const [pathname, setPathname] = useState();
  const view = previousPath();

  useEffect(() => {
    (async () => {
      const path = await getParcelPath({
        address: property && property.address,
        id,
        slug: property && `${property.hash}|${property.rescourId}`,
        type: _type,
        view: previousPath(),
      });
      setPathname(path);
    })();
  }, [_type, id, property, view]);

  return (
    <Link
      className={className}
      to={{ ...location, pathname }}>
      {children}
    </Link>
  );
};

NearbyLink.propTypes = {
  _type: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  property: PropTypes.object.isRequired,
};

export default NearbyLink;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { ACRES_PER_SQ_M, SQ_FT_PER_SQ_M } from '../../constants';
import formatStat from '../../../common/stats/formatStat';
import styles from './module.css';

const formatSize = (size) => {
  return size ? `${numeral(size).format('0,0')} SF` : '\u2013';
};

const formatNumber = (number) => {
  return number ? numeral(number).format('0,0') : '\u2013';
};

const formatDecimalNumber = (number) => {
  if (number || number === 0) {
    if (number % 1 === 0) {
      return `${numeral(number).format('0,0')}.00`;
    }
    return numeral(number).format('0,0.00');
  }
  return '\u2013';
};

const formatLeaseTerm = (term) => {
  if (term) {
    return `${term} mos.`;
  }
  return '\u2013';
};

const formatCapRate = (rate) => {
  if (rate !== null && rate !== undefined) {
    if (rate < 1 && rate > 0) {
      return `${(rate * 100).toFixed(2)}%`;
    }
  }
  return '\u2013';
};

const formatDate = (date) => {
  return date ? moment(date).format('MM/DD/YYYY') : '\u2013';
};

const formatString = (str) => {
  return str ? str : '\u2013';
};

const formatPrice = (price) => {
  return price ? `$${numeral(price).format('0,0')}` : '\u2013';
};

const propertySize = (buildingArea) => {
  if (buildingArea) {
    return `${formatStat(buildingArea * SQ_FT_PER_SQ_M, { format: 'wholeNumber' })} (SF)`;
  }
  return '\u2013';
};

const formatLotSize = (lotSize) => {
  if (lotSize) {
    const acres = lotSize * ACRES_PER_SQ_M;
    const formattedAcres = numeral(acres).format('0.000');
    return `${formattedAcres} (Acres)`;
  }
  return '\u2013';
};

function RightPanelDetails({ assessment, type, _source }) {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  let propertyDetailsLeft;
  let propertyDetailsRight;
  let widthLeft;
  let widthRight;
  
  switch (type) {
    case 'availability':
      widthLeft = '65px';
      widthRight = '75px';
      propertyDetailsLeft = [
        { label: 'Suite(s)', value: formatString(_source.suite || _source.property.suite) },
        { label: 'Available SF', value: formatSize(_source.area) }, 
        { label: 'Min. Div.', value: formatNumber(_source.minDivisibleArea) }, 
        { 
          label: 'Prop. Type', 
          value: formatString((_source.property.propertyTypes && _source.property.propertyTypes.join('/'))) 
        },
      ];
      propertyDetailsRight = [
        {
          label: 'Asking Rate',
          value: (
            _source.askingRate && (_source.askingRate.min || _source.askingRate.max)
          )
            ? `$${_source.askingRate.min ? formatDecimalNumber(_source.askingRate.min) : '\u2013'} - ${
              _source.askingRate.max ? `$${formatDecimalNumber(_source.askingRate.max)}` : '\u2013'
            }`
            : '\u2013'          
        },
        { label: 'Rate Type', value: formatString(_source.rateType) },
        { label: 'Listing Status', value: formatString(_source.dealStatus) },
        { label: 'Date Avail', value: formatDate(_source.availableDate) }, 
      ];
      break;
    case 'lease':
      widthLeft = '65px';
      widthRight = '60px';
      propertyDetailsLeft = [
        { label: 'Area', value: formatSize(_source && _source.area) },
        { label: 'Term', value: formatLeaseTerm(_source && _source.leaseTerm) }, 
        { label: 'Lease Type', value: formatString(_source && _source.leaseType) }, 
        {
          label: 'Prop. Type',
          value: formatString(
            (_source.property.propertyTypes && _source.property.propertyTypes.join('/'))
          ) || '\u2013'
        },  
      ];
      propertyDetailsRight = [
        { 
          label: 'Start Rate', 
          value: (
            _source.rentRate || _source.rateType
          )
            ? `$${formatDecimalNumber(_source.rentRate)} ${
              _source.rateType ? `${formatString(_source.rateType)}` : '\u2013'
            }`
            : '\u2013'          
        },
        { label: 'Start', value: formatDate(_source.commencementDate) },
        { label: 'End', value: formatDate(_source.expirationDate) },
        { label: 'Suite(s)', value: formatString(_source.suite) },
      ];
      break;
    case 'sale':
      widthLeft = '61px';
      widthRight = '60px';
      propertyDetailsLeft = [
        { label: 'Size', value: formatSize(_source.area || _source.properties[0].area) }, 
        { label: 'Sale Price', value: formatPrice(_source.transactionValue) }, 
        { label: 'Price / SF', value: formatPrice(_source.pricePerSquareFoot) }, 
        {
          label: 'Prop. Type',
          value: formatString(
            (_source.properties[0].propertyTypes && _source.properties[0].propertyTypes.join('/'))
          )
        },          
      ];
      propertyDetailsRight = [
        { label: 'Sale Date', value: formatDate(_source.soldDate) }, 
        { label: 'Sale Type', value: formatString(_source.saleType) }, 
        { label: 'Cap Rate', value: formatCapRate(_source.capRate) },
      ];
      break;
    case 'listing':
      const saleType = _source.saleType || [];
      const saleTypeDisplay = saleType.length > 1 ? `${saleType[0]}...` : saleType[0] || '\u2013';
      widthLeft = '61px';
      widthRight = '60px';
      propertyDetailsLeft = [
        {
          label: 'Size',
          value: formatSize(
            _source.rentableBuildingArea || _source.properties[0].rentableBuildingArea
          )
        },        
        { label: 'Sale Price', value: formatPrice(_source.price) },
        { label: 'Price / SF', value: formatPrice(_source.pricePerSquareFoot) },
        {
          label: 'Prop. Type',
          value: formatString(
            (_source.properties[0].propertyTypes && _source.properties[0].propertyTypes.join('/'))
          )
        },          
      ];
      propertyDetailsRight = [
        { label: 'Status', value: formatString(_source.status) },
        { label: 'Sale Type', value: formatString(saleTypeDisplay) },
        { label: 'Cap Rate', value: formatCapRate(_source.capRate) },
      ];
      break;
    case 'development':
      widthLeft = '60px';
      widthRight = '75px';
      propertyDetailsLeft = [
        {
          label: 'Prop. Type',
          value: formatString(
            (_source.property.propertyTypes && _source.property.propertyTypes.join('/'))
          )
        },
        {
          label: 'Subtype',
          value: formatString(
            _source.property.propertyUses && _source.property.propertyUses.join('/')
          )
        },        
        { label: 'Size', value: formatSize(_source.area || _source.property.area) }, 
        { label: 'Status', value: formatString(_source.developmentStatus) },
      ];
      propertyDetailsRight = [
        { label: 'Market', value: formatString(_source.property.market) },
        { label: 'Submarket', value: formatString(_source.property.submarket) },
        { label: 'Dev. Type', value: formatString(_source.developmentType) },
        { label: 'Est. Complete', value: formatDate(_source.deliveryDate) },
      ];
      break;
    case 'parcel':
      widthLeft = '105px';
      widthRight = '105px';
      propertyDetailsLeft = [  
        { 
          label: 'Property Size', 
          value: propertySize(assessment && assessment.primaryStructure && assessment.primaryStructure.buildingArea)
        },
        { 
          label: 'Lot Size', 
          value: formatLotSize(assessment && assessment.lot && assessment.lot.lotSize) 
        },
      ];
      propertyDetailsRight = [
        { 
          label: 'Assessed Value',
          value: formatPrice(_source && _source.assessment && _source.assessment.assessedValue.total)
        },
        { 
          label: 'Last Sold Date', 
          value: formatDate(_source && _source.transaction && _source.transaction.lastMarketSale.filingDate) 
        },
      ];
      break;
    default:
      widthLeft = '55px';
      widthRight = '60px';
      propertyDetailsLeft = [
        {
          label: 'Type',
          value: formatString((_source.propertyTypes && _source.propertyTypes.join(', '))),
        },
        {
          label: 'Subtype',
          value: formatString((_source.propertyUses && _source.propertyUses.join(', '))),
        },
        { label: 'Size', value: formatSize(_source.area || _source.rentableBuildingArea) }, 
        { label: 'Year Built', value: formatString(_source.yearBuilt) },
      ];
      propertyDetailsRight = [
        { label: 'Market', value: formatString(_source.market) },
        { label: 'Submarket', value: formatString(_source.submarket) },
        { label: 'Bldg. Class', value: formatString(_source.buildingClass) },
        { label: 'Renovated', value: formatString(_source.yearRenovated) },
      ];
  }
  
  return (
    <div className={classes.detailContainer}>
      <div className={classes.columnContainer}>
        {propertyDetailsLeft.map((detail) => (
          <div key={detail.label} className={classes.labelContainer}>
            <span className={classes.label} style={{ width: widthLeft }}>{detail.label}:</span>
            <strong className={classes.value}>
              {detail.value}
            </strong>
          </div>
        ))}
      </div>
      <div className={classes.columnContainer}>
        {propertyDetailsRight.map((detail) => (
          <div key={detail.label} className={classes.labelContainer}>
            <span className={classes.label} style={{ width: widthRight }}>{detail.label}:</span>
            <strong className={classes.value}>
              {detail.value}
            </strong>
          </div>
        ))}
      </div>
    </div>
  );
}
  
RightPanelDetails.propTypes = {
  _source: PropTypes.object.isRequired,
  assessment: PropTypes.object,
  type: PropTypes.string.isRequired,
};
  
export default RightPanelDetails;

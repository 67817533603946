import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pickBy from 'lodash/pickBy';
import { connect } from 'react-redux';
import calculateDistance from '../../common/calculateDistance';
import { getParcelPath } from '../../common/getParcelPath';
import { showProblemReporter, hideProblemReporter } from '../problemReporter/actions';
import { previousPath } from '../../common/getLocationTitles';
import * as reportActions from '../reports/actions';
import * as researchActions from '../../research/actions';
import * as actions from './actions';
import PropertyDetail from './PropertyDetail';

class PropertyDetailContainer extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static propTypes = {
    clearProperty: PropTypes.func.isRequired,
    fetchAllDatatypeWithFilters: PropTypes.func.isRequired,
    fetchAllNearby: PropTypes.func.isRequired,
    fetchProperty: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    property: PropTypes.object,
    propertyId: PropTypes.string.isRequired,
    requestFilteredQueryWithIds: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    searches: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidMount() {
    this.props.fetchProperty(this.props.propertyId);
    this.fetchAllNearby(this.props.propertyId);
    this.fetchAllDatatypeWithFilters();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.propertyId !== this.props.propertyId) {
      this.props.fetchProperty(this.props.propertyId);
      this.fetchAllNearby(this.props.propertyId);
    }
    if (prevProps.property !== this.props.property && Object.keys(this.props.property).length > 0) {
      this.fetchAllDatatypeWithFilters();
    }
  }
  
  componentWillUnmount() {
    this.props.clearProperty();
  }

  fetchAllNearby = () => {
    const { params: { propertyId } } = this.props;
    this.props.fetchAllNearby(propertyId);
  };

  navigate = async (datatype, id = null) => {
    const hasFilteredQueryItems = this.props[`hasFilteredQueryItems${datatype[0].toUpperCase()}${datatype.slice(1)}`];
    if (hasFilteredQueryItems) id = 'filter';
    const pathname = await getParcelPath({
      apn: get(this.props, 'params.apn'),
      fips: get(this.props, 'params.fips'),
      id,
      slug: this.props.propertyId,
      type: datatype,
      view: previousPath(),
    });

    this.props.router.push({ ...this.props.location, pathname });
  };

  navigateFetchError = () => {
    this.setState({ hasError: false });
  };

  getDistanceFromProperty = (nearbyLocation, multipleLocations) => {
    let locations;
    if (multipleLocations) {
      locations = Array.isArray(nearbyLocation[0]) ? nearbyLocation : [nearbyLocation];
    } else {
      locations = [nearbyLocation];
    }
  
    const distance = locations.reduce((acc, nearby) => {
      const lat = Array.isArray(nearby) ? nearby[1] : nearby.lat;
      const lng = Array.isArray(nearby) ? nearby[0] : nearby.lng;
      const currentDistance = calculateDistance(this.props.property.location, { lat, lng }); 

      return Math.min(acc, currentDistance);
    }, Infinity).toFixed(1);
  
    return distance;
  };
  
  fetchAllDatatypeWithFilters = () => {
    const { searches, property } = this.props;
    if (Object.keys(property).length > 0) {
      this.props.fetchAllDatatypeWithFilters(property, searches);
    }
  };

  render() {
    if (this.state.hasError) {
      return this.navigateFetchError();
    }
    return (
      <PropertyDetail
        fetchAllDatatypeWithFilters={this.fetchAllDatatypeWithFilters}
        getDistanceFromProperty={this.getDistanceFromProperty}
        navigate={this.navigate}
        navigateFetchError={this.navigateFetchError}
        {...this.props} />
    );
  }
}

function mapStateToProps({ auth, research, visibleDatatypes }, { params }) {
  const { selectedResults, searches, selectionAnchor } = research;
  const { propertyId, datatype } = params;
  const {
    allNearbyItems,
    filteredQueryItemsLease,
    filteredQueryItemsAvailability,
    filteredQueryItemsSale,
    filteredQueryItemsListing,
    filteredQueryItemsDevelopment,
    filteredQueryItemsCompany,
    filteredQueryItemsNews,
    hasFilteredQueryItemsLease,
    hasFilteredQueryItemsAvailability,
    hasFilteredQueryItemsSale,
    hasFilteredQueryItemsListing,
    hasFilteredQueryItemsDevelopment,
    hasFilteredQueryItemsCompany,
    hasFilteredQueryItemsNews,
    isFetching,
    isFetchingContent,
    isFetchingFilteredQueryItems,
    isFetchingNearby,
    isFetchingPdf,
    property,
    reportPdfError
  } = research.propertyDetail;
  const {
    isProblemReporterVisible,
    problemItem,
  } = research.problemReporter;
  const { currentUser } = auth;
  const currentListView = datatype;
  const enabledSearches = pickBy(searches, (s) => s.isEnabled && s.filters);
  return {
    allNearbyItems,
    currentUser,
    filteredQueryItemsLease,
    filteredQueryItemsAvailability,
    filteredQueryItemsSale,
    filteredQueryItemsListing,
    filteredQueryItemsDevelopment,
    filteredQueryItemsCompany,
    filteredQueryItemsNews,
    hasFilteredQueryItemsLease,
    hasFilteredQueryItemsAvailability,
    hasFilteredQueryItemsSale,
    hasFilteredQueryItemsListing,
    hasFilteredQueryItemsDevelopment,
    hasFilteredQueryItemsCompany,
    hasFilteredQueryItemsNews,
    currentListView,
    enabledSearches,
    propertyId,
    property,
    isFetching,
    isFetchingContent,
    isFetchingFilteredQueryItems,
    isFetchingNearby,
    isFetchingPdf,
    isProblemReporterVisible,
    problemItem,
    reportPdfError,
    searches,
    selectedResults,
    selectionAnchor,
    visibleDatatypes,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, ...researchActions, hideProblemReporter, ...reportActions, showProblemReporter }
)(PropertyDetailContainer);

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { AllHtmlEntities as entities } from 'html-entities';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PlaceIcon from '@material-ui/icons/Place';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import TitleIcon from '@material-ui/icons/WorkOutline';
import FieldNote from '../../../../common/stats/FieldNote';
import twitterLogo from '../../../../assets/twitter.png';
import linkedInLogo from '../../../../assets/linkedin.png';
import { fcContact } from '../../../../services/api';
import styles from './module.css';

const clean = (data) => entities.decode(data);

class FullContact extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    expandFirst: PropTypes.bool,
    fieldNote: PropTypes.string,
    headerPrefix: PropTypes.string,
    signatories: PropTypes.array
  };

  constructor(props) {
    super(props);
    const cards = {};
    (this.props.signatories || []).forEach((signatory) => {
      cards[signatory.name] = {
        _loaded: null,
        expanded: false,
        name: signatory.name,
        displayName: signatory.name,
        company: null,
        email: signatory.email,
        photo: null,
        icon: (signatory.type === 'Contact') ? <PersonIcon /> : <BusinessIcon />,
        city: null,
        state: null,
        type: signatory.type
      };
    });
    this.state = { cards };
  }

  componentDidMount() {
    if (this.props.expandFirst && Object.keys(this.state.cards).length > 0) {
      this.handleExpand(this.state.cards[Object.keys(this.state.cards)[0]])();
    }
  }

  handleExpand = (card) => () => {
    const expanded = !this.state.cards[card.name].expanded;
    this.setState({
      cards: Object.assign(this.state.cards, {
        [card.name]: Object.assign(card, { expanded })
      })
    });

    // load fc data if not collapsed, company type, email-less, or already loaded
    if (!expanded) return;
    if (card.type === 'Company' || !card.email || card._loaded === true) return;
    fcContact(card.email)
      .then((result) => {
        if (result && result.details) {
          let faceBookProfile;
          let linkedInProfile;
          let twitterProfile;
          if (result.details.profiles.linkedin) {
            linkedInProfile = result.details.profiles.linkedin;
          }
          if (result.details.profiles.facebook) {
            faceBookProfile = result.details.profiles.facebook;
          }
          if (result.details.profiles.twitter) {
            twitterProfile = result.details.profiles.twitter;
          }
          const data = {
            _loaded: true,
            city: get(result, 'demographics.locationDeduced.city.name'),
            company: get(result, 'organizations[0].name'),
            expanded,
            email: card.email,
            faceBookProfile,
            displayName: get(result, 'contactInfo.fullName') || card.name,
            icon: card.icon,
            linkedInProfile,
            name: card.name,
            photo: get(result, 'photos[0].url'),
            state: get(result, 'demographics.locationDeduced.state.name'),
            title: get(result, 'organizations[0].title'),
            twitterProfile,
            type: card.type
          };
          this.setState({ cards: Object.assign(this.state.cards, { [card.name]: data }) });
        }
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        {Object.keys(this.state.cards).length === 0 && (
          <Accordion className={classes.card} elevation={0}>
            <AccordionSummary className={classes.cardHeader}>
              <Typography variant="body2" color="textSecondary">
                {this.props.headerPrefix}
                {this.props.fieldNote && <FieldNote content={this.props.fieldNote} />}
              </Typography>
            </AccordionSummary>
          </Accordion>
        )}

        {Object.keys(this.state.cards).map((key, index) => {
          const card = this.state.cards[key];
          return (
            <Accordion
              key={key}
              className={classes.card}
              elevation={0}
              expanded={card.expanded}
              onChange={this.handleExpand(card)}>
              <AccordionSummary className={classes.cardHeader} expandIcon={<ExpandMoreIcon />}>
                <Typography variant="body2" color="textSecondary">
                  {this.props.headerPrefix}: {card.displayName}
                  {index === 0 && this.props.fieldNote && <FieldNote content={this.props.fieldNote} />}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.cardBody}>
                <div className={classes.contactHeader}>
                  {!(card.type === 'Contact' && card.photo) && (
                    <Avatar className={classes.cardAvatar}>{card.icon || null}</Avatar>
                  )}
                  {card.type === 'Contact' && card.photo && (
                    <Avatar className={classes.cardAvatar} src={card.photo} />
                  )}
                  {card.displayName && (
                    <Typography className={classes.contactTitle}>{card.displayName}</Typography>
                  )}
                  <div className={classes.social}>
                    {card.linkedInProfile && (
                      <Link href={card.linkedInProfile.url} target={'_blank'}>
                        <img src={linkedInLogo} />
                      </Link>
                    )}
                    {card.twitterProfile && (
                      <Link href={card.twitterProfile.url} target={'_blank'}>
                        <img src={twitterLogo} />
                      </Link>
                    )}
                  </div>
                </div>
                <div className={classes.contactDetails}>
                  {card.title && (
                    <div className={classes.stat}>
                      <TitleIcon className={classes.statIcon} />
                      <Typography className={classes.contactDetail}>
                        {clean(`${card.title}, ${card.company}`)}
                      </Typography>
                    </div>
                  )}
                  {card.email && (
                    <div className={classes.stat}>
                      <EmailIcon className={classes.statIcon} />
                      <Typography className={classes.contactDetail}>{card.email}</Typography>
                    </div>
                  )}
                  {card.city && card.state && (
                    <div className={classes.stat}>
                      <PlaceIcon className={classes.statIcon} />
                      <Typography className={classes.contactDetail}>{card.city}, {card.state}</Typography>
                    </div>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  }
}

FullContact.defaultProps = {
  expandFirst: false
};

export default withStyles(styles)(FullContact);

import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import getLocationTitles from '../common/getLocationTitles';
import locationApi from '../services/location';
import styles from './module.css';

export class Spotlight extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    location: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    value: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      mounted: false,
      suggestions: []
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  getSuggestions = (event, text) => {
    this.props.onChange(text);

    if (!text) return this.setState({ suggestions: [] });
    this._getSuggestions(text);
  };

  _getSuggestions = throttle((text) => {
    this.setState({ loading: true });
    Promise.all([
      locationApi(text, this.props.location),
    ])
      .then(([locations]) => {
        if (!Array.isArray(locations)) {
          locations = [];
        }
        this.setState({
          loading: false,
          suggestions: locations.map((result) => {
            return {
              text: result.address || result.title || '',
              value: result
            };
          })
            .slice(0, 5)
        });
      });
  }, 1000);

  onNewRequest = (event, value) => this.props.onSelect((value || {}).value);

  renderInput = (params) => {
    return (
      <TextField
        {...params}
        InputProps={{
          ...params.InputProps,
          className: this.props.classes.input,
          endAdornment: null
        }}
        placeholder="Search for locations"
        variant="outlined" />
    );
  };

  renderOption = (option) => {
    if (!option) return null;

    const { value } = option;
    const { primary, secondary } = getLocationTitles(value);

    const { classes } = this.props;
    return (
      <React.Fragment>
        <span className={classes.optPrimary}>{primary}</span>
        <span className={classes.optSep}> - </span>
        <span className={classes.optSecondary}>({secondary})</span>
      </React.Fragment>
    );
  };

  render() {
    const { loading, suggestions } = this.state;
    const { value } = this.props;

    // NOTE: filterOptions seems redundant, but fixes a bug where noOptionsText was being shown
    //  even when there were options (i.e. when searching "birmingham alabama")
    return (
      <React.Fragment>
        <div style={{ position: 'relative' }}>
          <Autocomplete
            autoHighlight
            filterOptions={(o) => { return o; }}
            fullWidth
            getOptionLabel={({ text }) => { return text; }}
            getOptionSelected={(o, v) => { return o.text === v.text; }}
            openOnFocus
            loading={loading}
            onChange={this.onNewRequest}
            onInputChange={this.getSuggestions}
            options={suggestions.length > 0 ? suggestions : []}
            noOptionsText="No results found."
            renderInput={this.renderInput}
            renderOption={this.renderOption}
            size="small"
            inputValue={value || ''} />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Spotlight);

import isEmpty from 'lodash/isEmpty';
import { FALLBACK_LOCATION } from './constants';

export default function geolocate({ geoIP }) {
  let fallbackLocation = FALLBACK_LOCATION;
  if (!isEmpty(geoIP)) fallbackLocation = `#11/${geoIP.latitude}/${geoIP.longitude}`;
  return new Promise((resolve) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        const { latitude, longitude } = coords;
        resolve(`#11/${latitude}/${longitude}`);
      }, () => resolve(fallbackLocation), { timeout: 10000, maximumAge: 3600000 });
    } else {
      resolve(fallbackLocation);
    }
  });
}

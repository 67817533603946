import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';

const ExpirationsChart = (props) => {
  const {
    classes,
    items,
    legend,
    colors,
    chartImg,
    animationDuration,
    onAnimationComplete,
  } = props;

  // analyze the items (selectedResults from the map view)
  const expirations = {};
  let minYear = null;
  let maxYear = null;
  if (items && items.length > 0) {
    for (const item of items) {
      if (item._source) {
        if (item._source.expirationDate) {
          const expYear = item._source.expirationDate.split('-').shift();
          if (expYear && parseInt(expYear) > 0) {
            if (Object.keys(expirations).indexOf(expYear) === -1) {
              expirations[expYear] = { tally: 0, area: 0 };
            }
            expirations[expYear].tally++;
            expirations[expYear].area += item._source.area / 1000;

            if (minYear == null || expYear < minYear) {
              minYear = expYear;
            }
            if (maxYear || expYear > maxYear) {
              maxYear = expYear;
            }
          }
        }
      }
    }
  }

  // build a sequential list of years from min to max so chart doesn't skip years
  // (fill in zeroed-data if there is none for years skipped)
  // while in this loop, also catch maxes in each scale to help set the y-axis
  let maxArea = 0;
  let maxTally = 0;
  for (let yr = minYear; yr <= maxYear; yr++) {
    const stringYr = String(yr);
    if (Object.keys(expirations).indexOf(stringYr) === -1) {
      expirations[stringYr] = { tally: 0, area: 0 };
    } else {
      maxArea = (expirations[stringYr].area > maxArea) ? expirations[stringYr].area : maxArea;
      maxTally = (expirations[stringYr].tally > maxTally) ? expirations[stringYr].tally : maxTally;
    }
  }

  // init chartRef
  let chartRef = null;

  // return the element
  return (
    <div>
      <Bar
        ref={(chart) => { chartRef = chart; }}
        data={{
          labels: Object.keys(expirations),
          datasets: [{
            label: 'Expirations',
            yAxisID: 'expTally',
            data: Object.values(expirations).map((expiration) => expiration.tally),
            backgroundColor: colors[0],
            borderWidth: 0
          }, {
            label: 'Area Expiring',
            yAxisID: 'expArea',
            data: Object.values(expirations).map((expiration) => expiration.area),
            borderWidth: 0,
            backgroundColor: colors[1]
          }]
        }}
        options={{
          scales: {
            yAxes: [{
              id: 'expTally',
              scaleLabel: { display: true, labelString: 'Leases', fontStyle: 'bold' },
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                suggestedMax: Math.ceil(1.05 * maxTally)  // 5% more than the highest value in data
              }
            },{
              id: 'expArea',
              scaleLabel: { display: true, labelString: '(k) SF', fontStyle: 'bold' },
              type: 'linear',
              position: 'right',
              ticks: {
                beginAtZero: true,
                suggestedMax: Math.ceil(1.05 * maxArea)  // 5% more than the highest value in data
              },
              gridLines: {
                display: false
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // tally
                if (tooltipItem.datasetIndex === 0) {
                  return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.yLabel.toLocaleString()}`;
                }

                // area
                return `${data.datasets[tooltipItem.datasetIndex].label}: `
                        + `${(Math.round(100 * tooltipItem.yLabel) * 10).toLocaleString()} SF`;
              }
            }
          },
          animation: {
            duration: animationDuration || 1000,
            onComplete: () => {
              if (onAnimationComplete) {
                onAnimationComplete(chartRef.chartInstance);
              }
            }
          },
          legend: legend ? legend : {}
        }} />
      {chartImg && <img src={chartImg} className={classes.canvasImg} data-canvas />}
    </div>
  );
};

ExpirationsChart.propTypes = {
  animationDuration: PropTypes.number,
  chartImg: PropTypes.string,
  classes: PropTypes.object,
  colors: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  legend: PropTypes.object,
  onAnimationComplete: PropTypes.func
};
  
export default ExpirationsChart;
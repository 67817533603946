import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import DatatypeIcons from '../../../../src/account/common/DatatypeIcons';
import styles from './module.css';
import Close from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

class SavedSearchDialog extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    handleChange: PropTypes.func,
    handleSave: PropTypes.func,
    handleSaveCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    isSaving: PropTypes.bool,
    onChange: PropTypes.func,
    onFrequencyChange: PropTypes.func,
    onSaveOptionChange: PropTypes.func,
    saveDisabled: PropTypes.bool,
    saveMode: PropTypes.string,
    savedSearch: PropTypes.object,
    savedSearchDescription: PropTypes.string,
    savedSearchFrequency: PropTypes.string,
    savedSearchTitle: PropTypes.string,
    searches: PropTypes.array
  };
  constructor(props) {
    super(props);
    this.state = { frequency: this.props.savedSearchFrequency || 'never' };
  }

  render() {
    const {
      handleSave,
      handleSaveCancel,
      isOpen,
      isSaving,
      onChange,
      onFrequencyChange,
      onSaveOptionChange,
      saveDisabled,
      saveMode,
      savedSearch,
      savedSearchTitle,
      searches
    } = this.props;
      
    const { classes } = this.props;
    const enabledDataTypes = Object.keys(searches).filter((key) => searches[key].isEnabled);

    const handleChange = (e, value) => {
      e.preventDefault();
      this.setState({ frequency: value }, () => onFrequencyChange(value || 'never'));
    };

    return (
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        className={classes.saveSearchDialog}
        open={isOpen}
        onClose={this.handleDeleteCancel}>
        <DialogTitle>Save Search</DialogTitle>
        {(isEmpty(savedSearch) && saveMode === 'create') && (
          <div className={classes.savedSearchColumnModules}>
            <DatatypeIcons types={enabledDataTypes} />
          </div>
        )}
        <Close className={classes.close} onClick={handleSaveCancel} />
        <DialogContent>
          {!isEmpty(savedSearch) && (
            <RadioGroup name="saveOptions" value={saveMode} onChange={onSaveOptionChange}>
              <FormControlLabel
                control={<Radio color="primary" />}
                disabled={isEmpty(savedSearch)}
                value="update" 
                label={<span>Update <strong>{savedSearchTitle}</strong></span>} />
              <FormControlLabel value="create" control={<Radio color="primary" />} label="Create New" />
            </RadioGroup>
          )}
          {saveMode === 'create' && (
            <React.Fragment>
              <div>
                <TextField
                  fullWidth
                  label={'Title'}
                  name="savedSearchTitle"
                  onChange={onChange} />
              </div>
              <div>
                <TextField
                  fullWidth
                  label={'Description'}
                  name="savedSearchDescription"
                  onChange={onChange} />
              </div>
              <div>
                <Typography className={classes.notifyText}>
                  Notify me of new changes
                </Typography>
                <Typography className={classes.notifySubText}>
                  Receive email alerts when new items match this saved search.
                </Typography>
              </div>
              <div>
                <ToggleButtonGroup
                  value={this.state.frequency}
                  exclusive
                  onChange={handleChange}
                  name="savedSearchFrequency"
                  aria-label="frequency"
                  className={classes.toggleButtons}>
                  <ToggleButton value="never">NEVER</ToggleButton>
                  <ToggleButton value="daily">DAILY</ToggleButton>
                  <ToggleButton value="weekly">WEEKLY</ToggleButton>
                </ToggleButtonGroup>
              </div>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isSaving}
            variant="outlined"
            className={classes.cancelButton}
            onClick={handleSaveCancel}>Cancel</Button>
          <Button
            color="primary"
            disabled={saveDisabled}
            onClick={handleSave}
            className={classes.saveButton}
            variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SavedSearchDialog);
import React from 'react';
import PropTypes from 'prop-types';
import { ControlContainer } from '@colliers-international/react-leaflet-components';
import MapList from '../List';

export const ResultList = (props) => {
  return props.anySearchesEnabled && props.currentListView &&
    <ControlContainer style={{ width:'425px' }}>
      <MapList {...props} />
    </ControlContainer>;
};

ResultList.propTypes = {
  anySearchesEnabled: PropTypes.bool.isRequired,
  currentListView: PropTypes.string.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import _isArray from 'lodash/isArray';
import { withStyles } from '@material-ui/core/styles';
import Stat from './Stat';
import formatStat from './formatStat';
import styles from './module.css';

const Stats = ({ classes, item, schema, statClassName }) => {
  const fieldNotes = item.fieldNotes || {};
  return (
    <div className={classes.stats}>
      {schema.map(({ key, label, ...stat }) => {
        const formattedValue = (!item[key] || (_isArray(item[key]) && item[key].length === 0))
          ? null
          : formatStat(item[key], stat, item);
        return (
          <Stat
            className={statClassName || classes.stat}
            fieldNote={fieldNotes[key]}
            key={key}
            label={label}
            showEmpty={false}
            value={formattedValue} />
        );
      })}
    </div>
  );
};

Stats.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object.isRequired,
  schema: PropTypes.array.isRequired,
  statClassName: PropTypes.string
};

Stats.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Stats);

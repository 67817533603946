import indigo from '@material-ui/core/colors/indigo';

export default (theme) => ({
  accountLoadingCircle: {
    alignSelf: 'center',
    width: '50px',
    height: '50px'
  },
  accountLoadingDescription: {
    marginTop: theme.spacing(0.5),
  },
  accountLoadingWrapper: {
    color: theme.palette.colliersBlue,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    marginTop: '24vh'
  },
  noAccountBody: {
    margin: theme.spacing(0.5, 0, 2, 0),
  },
  noAccountButton: {
    color: 'white',
    backgroundColor: indigo[800],
    borderRadius: '16px',
    border: '1px solid #f5f5f5',
    width: 'fit-content',
    margin: 'auto',
    ['&:hover']: {
      border: '1px solid',
      borderColor: indigo[800],
      backgroundColor: '#f5f5f5',
      color: indigo[800]
    }
  },
  noAccountIcon: {
    color: indigo[800],
    alignSelf: 'center'
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

const GaugeChart = (props) => {
  const {
    classes,
    items,
    analyzeKey,
    title,
    unit,
    color,
    chartImg,
    animationDuration,
    onAnimationComplete
  } = props;

  // if no format provided, then format should just return the value as-is
  let { format } = props;
  if (typeof(format) == 'undefined') {
    format = (v) => v;
  }

  // analyze the items on the specified key
  // the "items" are selectedResults from the map view... we assume the analyzeKey is in the _source property of each item
  let min = null;
  let max = null;
  let avg = 0;
  let sum = 0;
  let tally = 0;
  let percentage = 0;
  if (items && items.length > 0) {
    for (const item of items) {
      if (item._source) {
        if (item._source[analyzeKey] > 0) {
          // sum and tally (to calculate average)
          sum += item._source[analyzeKey];
          tally++;

          // min
          if (min === null || item._source[analyzeKey] < min) {
            min = item._source[analyzeKey];
          }

          // max
          if (max === null || item._source[analyzeKey] > max) {
            max = item._source[analyzeKey];
          }
        }
      }
    }

    // avg
    if (tally > 0) {
      avg = sum / tally;

      // calculate percentage from avg, min and max
      percentage = (max === min) ? 0 : 100 * (avg - min) / (max - min);
    }
  }
  if (min === null) {
    min = 0;
  }
  if (max === null) {
    max = 0;
  }

  // init chartRef
  let chartRef = null;
  
  // return the element
  return (
    <div>
      <Doughnut
        ref={(chart) => { chartRef = chart; }}
        data={{
          datasets: [{
            data: [percentage, (100 - percentage)],
            backgroundColor: [color,'#e7e7e7']
          }]
        }}
        options={{
          circumference: Math.PI,   // half-circle
          rotation: Math.PI,        // starting from W
          cutoutPercentage: 88,
          tooltips: false,
          animation: {
            duration: animationDuration || 1000,
            onComplete: () => {
              if (onAnimationComplete) {
                onAnimationComplete(chartRef.chartInstance);
              }
            }
          }
        }} />
      {chartImg && <img src={chartImg} className={classes.canvasImg} data-canvas />}
      <div className={classes.gaugeLabels}>
        <div className={classes.gaugeHeader} style={{ color }}>
          {format(avg)}{unit}
        </div>
        <div className={classes.gaugeSubheader}>
          {title}
        </div>
        <div className={classes.gaugeEnds}>
          <div className={classes.gaugeEndLeft}>{format(min)}</div>
          <div className={classes.gaugeEndRight}>{format(max)}{unit}</div>
        </div>
      </div>
    </div>
  );
};

GaugeChart.propTypes = {
  analyzeKey: PropTypes.string.isRequired,
  animationDuration: PropTypes.number,
  chartImg: PropTypes.string,
  classes: PropTypes.object,
  color: PropTypes.string.isRequired,
  format: PropTypes.func,
  items: PropTypes.array.isRequired,
  onAnimationComplete: PropTypes.func,
  title: PropTypes.string.isRequired,
  unit: PropTypes.string
};
  
export default GaugeChart;
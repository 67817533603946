export default (theme) => ({
  ['@keyframes fadein']: {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  authImage: {
    animation: 'fadein .2s linear forwards',
  },
  authImageLoading: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing()
  },
  ourDealsLogoDefault: {
    width: '35px',
    position: 'absolute',
    top: '2px',
    right: '2px',
  },
  notificationMessage: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    ['& span']: {
      marginLeft: theme.spacing()
    }
  },
  notificationMessageSecondaryText: {
    textTransform: 'capitalize',
  }
});
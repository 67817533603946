import React from 'react';
import { Route, IndexRoute } from 'react-router';
import PropertyDetailContainer from './Container';
import Info from './Info';
import Demographics from './demographics/Container';
import Comments from './comments/Container';
import Content from './Content';

export default (
  <Route path=":propertyId" component={PropertyDetailContainer}>
    <IndexRoute component={Info} />
    <Route path="demographics" component={Demographics} />
    <Route path="comments" component={Comments} />
    <Route path="property(/:itemId)" component={Info} />
    <Route path=":datatype(/:itemId)" component={Content} />
  </Route>
);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SummaryFieldStats from '../common/SummaryFieldStats';
import styles from './module.css';

const PropertyStats = ({ classes, property }) => {
  const { 
    availability,
    buildingClass,
    landArea,
    market,
    propertyTypes,
    rentableBuildingArea,
    submarket,
    yearBuilt
  } = property;
  let formattedAvailabilityArea;
  if (availability && availability[0].area) formattedAvailabilityArea = availability[0].area;

  const propertyStats = [
    { label: 'Property Type', value: propertyTypes, format: 'propertyTypesHeader' },
    { label: 'Year Built', value: yearBuilt, format: 'string' },
    { label: 'Property Size (SF)', value: rentableBuildingArea, format: 'number' },
    { label: 'Land Area (Acres)', value: landArea, format: 'number' },
    { label: 'Market', value: market, format: 'string', customClass: classes.headerPropertStatDataMarkets },
    { label: 'Submarket', value: submarket, format: 'string', customClass: classes.headerPropertStatDataMarkets },
    { label: 'Class', value: buildingClass, format: 'string', },
    { label: 'Available (SF)', value: formattedAvailabilityArea, format: 'number' }
  ];

  return (
    <React.Fragment>
      <SummaryFieldStats
        property={true} 
        stats={propertyStats} />
    </React.Fragment>
  );
};

PropertyStats.propTypes = {
  classes: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyStats);

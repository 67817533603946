export default () => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  }
});

import {
  ONBOARDING_NEXT_STEP,
  ONBOARDING_PREVIOUS_STEP,
  ONBOARDING_COMPLETE,
} from '../constants/actionTypes';

export function previousStep() {
  return {
    type: ONBOARDING_PREVIOUS_STEP
  };
}

export function nextStep() {
  return {
    type: ONBOARDING_NEXT_STEP
  };
}

export function completeOnboarding() {
  return {
    type: ONBOARDING_COMPLETE
  };
}

export default (theme = {}) => {
  return {
    actions: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      width: '100%',
      ['& > button']: {
        marginRight: 16
      }
    },
    actionButtonLabel: {},
    formField: {
      marginTop: theme.spacing(4),
    },
    gallery: {
      margin: '0 auto',
      maxWidth: 500,
      ['& img']: {
        maxHeight: 500
      }
    },
    newsStats: {
      display: 'flex',
      flex: '2 1 0%',
      flexDirection: 'column',
    },
    newsStat: {
      fontSize: '15px',
      flex: '1 1 0%',
      paddingLeft: theme.spacing(4),
      ['& > span']: {
        lineHeight: `${theme.spacing(4.5)}px`,
      }
    },
    newsStatIcon: {
      height: '18px',
      lineHeight: '35px',
      marginRight: '3px',
      position: 'relative',
      top: '4px',
      width: '18px',
    },
    newsThumbnail: {
      flex: '1 1 0%',
      justifyContent: 'center',
      position: 'relative',
      ['& img']: {
        height: 120,
        right: 10,
        position: 'absolute',
        top: -60,
        width: 120,
      },
    },
    shareDialogPaper: {
      [theme.breakpoints.down(600)]: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
      }
    },
    [theme.breakpoints.down(600)]: {
      hideOnSmall: {
        display: 'none',
      }
    }
  };
};

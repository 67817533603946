import { RangeAccessor, RangeQuery } from 'searchkit-react16';
import moment from 'moment';

export default class DateRangeAccessor extends RangeAccessor {
  buildSharedQuery(query) {
    const _this = this;
    if (this.state.hasValue()) {
      const val = this.state.getValue();
      const minRangeValue = val.min ? moment(val.min).format('yyyy-MM-DD') : null;
      const maxRangeValue = val.max ? moment(val.max).format('yyyy-MM-DD') : null;
      const rangeFilter = this.fieldContext.wrapFilter(
        new RangeQuery(this.options.field, 
          { gte: minRangeValue,
            lte: maxRangeValue,
            format: 'yyyy-MM-dd' })
      );
      const selectedFilter = {
        name: this.translate(this.options.title),
        value: `${val.min} - ${val.max}`,
        id: this.options.id,
        remove: () => {
          _this.state = _this.state.clear();
        },
      };
      return query
        .addFilter(this.key, rangeFilter)
        .addSelectedFilter(selectedFilter);
    }
    return query;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import FieldStat from './FieldStat';
import styles from '../module.css';
import { withStyles } from '@material-ui/core';

const ColumnFieldStat = ({
  classes,
  item,
  fieldNotes,
  sectionArray,
  specialColumn,
}) => {
  // Add format(s) to array in below function if needing Item for formatStat.js case
  const formatNeedsItemObject = (format) => {
    const formats = ['escalationRate', 'propertyLinks', 'spaceUses'];
    return formats.includes(format);
  };

  return (
    <div className={classes.tripleColumn}>
      {sectionArray.map((column, index) => (
        <div key={index}>
          {column.map((stat) => (
            <FieldStat
              key={stat.key}
              item={formatNeedsItemObject(stat.format) ? item : null}
              isBoldLabel={stat.label === 'Related IDs' || stat.label === 'Custom IDs'}
              fieldNote={fieldNotes[stat.key] ? fieldNotes[stat.key] : null}
              stat={stat} />))}
        </div>))}
      {specialColumn && specialColumn()}
    </div>
  );
};

ColumnFieldStat.propTypes = {
  classes: PropTypes.object,
  fieldNotes: PropTypes.object.isRequired,
  item: PropTypes.object,
  sectionArray: PropTypes.array.isRequired,
  specialColumn: PropTypes.any,
};

export default withStyles(styles)(ColumnFieldStat);

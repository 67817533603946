/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import get from 'lodash/get';
import * as L from 'leaflet';
import chroma from 'chroma-js';
import { LegendControl } from './LegendControl';

function getClassName(scales, zoomLevel, area) {
  if (area > scales[4] && zoomLevel > 8) {
    return 'show-labels';
  } else if (area > scales[3] && zoomLevel > 9) {
    return 'show-labels';
  } else if (area > scales[2] && zoomLevel > 10) {
    return 'show-labels';
  } else if (area > scales[1] && zoomLevel > 11) {
    return 'show-labels';
  } else if (area < scales[0] && zoomLevel > 12) {
    return 'show-labels';
  }
}

export class FeatureShape {
  constructor({ featureContent, map }) {
    if (featureContent) {
      this.geoJson = featureContent.data;
      this.featureType = featureContent.type;
      this.id = featureContent.id;
    }
    this.map = map;
  }

  addData = (data) => {
    if (data && data.features.length) {
      const features = data.features.filter((feat) =>
        !this.geoJson.features.some((existing) => {
          return existing.properties.id === feat.properties.id;
        }));
      this.geoJson.features = [...this.geoJson.features, ...features];
      this.feature.addData(features);
    }
  };

  clearData = () => {
    this.feature.remove();
  };

  fetchData = (bounds, fetchData) => {
    (async () => {
      let waitFetch = null;
      if (waitFetch) {
        clearTimeout(waitFetch);
      }

      const boundary = {
        xleft: bounds.bottom_left[0],
        xright: bounds.top_right[0],
        ybottom: bounds.bottom_left[1],
        ytop: bounds.top_right[1]
      };
      const params = new URLSearchParams({ ...boundary });
      waitFetch = setTimeout(async () => {
        await fetchData(`${this.id}?${params}`, this.id, this.featureType);
      }, 400);
    })();
  };
}

export class FeaturePoint extends FeatureShape {
  featureType = 'POINT';

  constructor(props) {
    super(props);
    this._build(this.geoJson);
  }

  _build(geoJson) {
    this.feature = L.geoJSON(geoJson, {
      id: this.id,
      pointToLayer: this._pointToLayer
    }).bindTooltip(this._toolTipLabel).addTo(this.map);
  }

  _toolTipLabel = (layer) => {
    const { fac_name, name, port_name, displayname } = get(layer, 'feature.properties.data', {});
    const title = (fac_name || name || port_name || displayname);
    return title[0].toUpperCase() + title.slice(1);
  };

  _pointToLayer(feature, latlng) {
    const myIcon = L.icon({
      iconUrl: get(feature, 'properties.data.icon_url', ''),
      iconSize: [25, 50]
    });

    return L.marker(latlng, {
      icon: myIcon
    });
  }
}

export class FeaturePolygon extends FeatureShape {
  featureType = 'POLYGON';
  clrs = [
    '#53697A',
    '#B8BDB4',
    '#88B27E',
    '#C28B9A',
    '#6BADC9',
    '#C9AC68',
    '#7CB0A9',
    '#8B74A6',
    '#D8D13E',
    '#689676'
  ];

  constructor(props) {
    super(props);
    this.viewScales = chroma.limits(this.geoJson.features.map((feat) => get(feat, 'properties.data.area')), 'q', 5);
    this._build(this.geoJson);
  }

  _build(geoJson) {
    this.feature = L.geoJSON(geoJson, {
      id: 'feature-polygon',
      onEachFeature: this._onEachFeature,
      style: this._featureStyle
    });
    this.feature.addTo(this.map);
  }

  _toolTipFeature = (feature) => {
    const className = getClassName(this.viewScales, this.map.getZoom(), get(feature, 'properties.data.area'));
    return {
      content: get(feature, 'properties.data.name', ''), options: {
        permanent: className === 'show-labels',
        direction: 'center',
        className: `sub-market-label ${className}`
      }
    };
  };

  _onEachFeature = (feature, layer) => {
    const { content, options } = this._toolTipFeature(feature, this.map);
    layer.bindTooltip(content, options);
  };

  _neighbors = (feature) => {
    const neighbors = get(feature, 'properties.data.neighbors');
    const neighboringColors = [];
    if (neighbors) {
      JSON.parse(neighbors).forEach((neighborId) => {
        const found = this.geoJson.features.find((f) => f.properties.id === neighborId);
        if (found && found.properties.color) {
          neighboringColors.push(found.properties.color);
        }
      });
    }
    const color = this.clrs.filter((c) => !neighboringColors.some((n) => n === c));
    return color[Math.floor(Math.random() * color.length)];
  };

  _featureStyle = (feature) => {
    const color = this._neighbors(feature);
    return {
      'fillColor': color,
      color,
      'weight': 2,
      'opacity': 1,
      'fillOpacity': .5
    };
  };
}

export class DemographicFeature extends React.Component {
  // eslint-disable-next-line react/sort-comp
  clrs = chroma.scale(['#f9f7cb', '#e9102f']).mode('lch').colors(7);
  waitFetch = null;
  limits = [];
  constructor(props) {
    const { fetchDemographic, demographic, bounds } = props;
    super(props);
    this.map = props.map;
    this.demographic = demographic;
    this.fetchData(bounds, fetchDemographic);
    this.geoJson = { type: 'FeatureCollection', features: [] };
    this._build(this.geoJson);

    this.state = {
      limits: []
    };
  }
  _build(geoJson) {
    this.feature = L.geoJSON(geoJson, {
      id: 'feature-polygon',
      onEachFeature: this._onEachFeature,
      style: this._featureStyle
    }).addTo(this.map);
  }

  _getDemoByZoom(zoom) {
    if (zoom > 10) {
      return { id: process.env.REACT_APP_CENSUS_DATA, name: 'Census Tract' };
    } else if (zoom > 8) {
      return { id: process.env.REACT_APP_SUBDIVISION, name: 'Subdivision' };
    }
    return { id: process.env.REACT_APP_COUNTY, name: 'County' };
  }

  addData = (data) => {
    this.feature.clearLayers();
    if (data && data.features.length) {
      this.geoJson.features = [...data.features];
      this._calcLimits();
      this.feature.addData(data.features);
    }
  };

  _calcLimits = () => {
    const range = this.geoJson.features.map((item) => item.properties.data[this.demographic.key]);
    const limits = chroma.limits(range, 'q', 6);
    this.setState({ limits });
    this.limits = limits;
  };

  updateDemographic = (demographic) => {
    if (!demographic.name) {
      this.feature.remove();
      return;
    }
    this.demographic = demographic;
    this._calcLimits();

    this.feature.eachLayer((layer) => {
      const tt = this._toolTipFeature(layer.feature);
      layer.setTooltipContent(...Object.values(tt));
      const featureStyle = this._featureStyle(layer.feature);

      layer.setStyle(featureStyle);
    });
  };

  clearData = () => {
    this.map.removeLayer(this.feature);
    this.feature.remove();
  };

  _toolTipFeature = (feature) => {
    const { name, format, key } = this.demographic;
    return {
      content: `<div style='display:flex;
      width:auto;
      flex-direction:column;>
      <label'>
      <b>${this.layer.name}</b>
      </label>
      <span>${get(feature, 'properties.data.name', '')}</span>
      <label style='margin-top:5px'>
        <b>${name}</b>
        </label>
        <span>${format(get(feature, ['properties', 'data', key], ''))}</span>
      </div>`
    };
  };

  _onEachFeature = (feature, layer) => {
    const { content, options } = this._toolTipFeature(feature, this.map);
    layer.on({
      mouseover: this._highlightFeature,
      mouseout: this._resetHighlight,
    });
    layer.bindTooltip(content, options);
  };

  _inBetweenRange = (feature) => {
    const val = get(feature, ['properties', 'data', this.demographic.key]);

    const selected = this.limits.findIndex((limit) => limit >= val);
    if (!selected && selected !== 0) {
      return this.clrs[this.clrs.length - 1];
    }
    return this.clrs[selected];
  };

  _featureStyle = (feature) => {
    const color = this._inBetweenRange(feature);
    return {
      'fillColor': color,
      'color': 'white',
      'weight': 1,
      'opacity': 1,
      'dashArray': '3',
      'fillOpacity': .5
    };
  };
  _resetHighlight = (e) => {
    this.feature.resetStyle(e.target);
  };
  _highlightFeature(e) {
    const layer = e.target;

    layer.setStyle({
      weight: 2,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
    });

    layer.bringToFront();
  }

  fetchData = async (bounds, fetchData) => {
    const zoom = this.map.getZoom();
    const layer = this._getDemoByZoom(zoom);
    this.layer = layer;
    let boundary = {
      xleft: bounds.bottom_left[0],
      xright: bounds.top_right[0],
      ybottom: bounds.bottom_left[1],
      ytop: bounds.top_right[1]
    };
    if (zoom < 8) {
      boundary = {
        ...boundary, skip: 0, limit: 3000
      };
    }
    const params = new URLSearchParams({ ...boundary });
    fetchData(`${layer.id}?${params}`);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.featureContent !== this.props.featureContent) {
      this.addData(this.props.featureContent);
    }
    if (prevProps.demographic.name !== this.props.demographic.name) {
      this.updateDemographic(this.props.demographic);
    }
    if (prevProps.bounds !== this.props.bounds) {
      if (this.waitFetch) {
        clearTimeout(this.waitFetch);
      }
      this.waitFetch = setTimeout(() => {
        this.fetchData(this.props.bounds, this.props.fetchDemographic);
      }, 600);
    }
  }

  componentWillUnmount() {
    this.clearData();
  }

  render() {
    if (this.feature) {
      return <LegendControl featureLayer={this} limits={this.state.limits} classes={this.props.classes} />;
    }
    return null;
  }
}

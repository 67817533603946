import { grey } from '@material-ui/core/colors';

export default (theme) => ({
  grid: {
    border: `1px solid ${grey[300]}`,
    borderLeft: 0,
    outline: 'none',
    overflow: 'auto',
    ['-webkit-overflow-scrolling']: 'touch',
    position: 'relative',
    width: '100%',
    willChange: 'transform',
  },

  arrow: {
    'position': 'absolute',
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: '8px solid #fff',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      position: 'absolute',
      zIndex: 100,
      top: '-12px',
      left: '50%',
      transform: 'translate(-50%, 50%)'
    },
  },

  boomLink: {
    'color': grey[800],
    '&:hover': {
      textDecoration: 'none !important',
    },
  },

  cell: {
    alignContent: 'center',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${grey[300]}`,
    borderRight: `1px solid ${grey[300]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowX: 'hidden',
    overflowY: 'hidden',
    padding: theme.spacing(),
    pointerEvents: 'auto',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  createListButton: {
    color: theme.palette.colliersBlue,
    ['&:hover']: {
      borderRadius: '50%',
    }
  },

  createListText: {
    color: theme.palette.colliersBlue,
    fontSize: '16px',
    fontWeight: '525',
    marginLeft: '-2px'
  },

  createListTitle: {
    display: 'flex',
    alignItems: 'center',
    ['& h6']: {
      margin: 0
    }
  },

  closeButton: {
    'display': 'flex',
    'margin': '0 auto'
  },

  filteredListsContainer: {
    height: 200,
    overflowY: 'scroll',
  },

  menuItem: {
    width: 'auto',
    height: 'auto',
  },

  titleCell: {
    alignContent: 'center',
    alignItems: 'flex-start',
    borderBottom: `1px solid ${grey[300]}`,
    borderRight: `1px solid ${grey[300]}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowX: 'hidden',
    overflowY: 'hidden',
    padding: theme.spacing(),
    pointerEvents: 'auto',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    ['&:hover']: {
      background: '#fafafa',
    },
  },

  headerButton: {
    color: 'white',
  },

  selectAllButton: {
    backgroundColor: grey[200]
  },

  headerRow: {
    overflow: 'hidden !important',
  },
  
  headerCell: {
    alignItems: 'center',
    backgroundColor: grey[200],
    borderRight: `1px solid ${grey[300]}`,
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 'bold',
    justifyContent: 'center',
    overflow: 'hidden !important',
    padding: theme.spacing()
  },

  headerColumnContainer: {
    display: 'flex',
    flexDirection: 'column'
  },

  headerDataFilterContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  headerDataRightContainer: {
    display: 'flex',
    alignItems: 'center'
  },

  headerCellLabel: {
    flex: 1
  },
  
  noResults: {
    fontSize: '200%',
    padding: theme.spacing(6),
    textAlign: 'center',
  },
  
  dragHandle: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 5,
    cursor: 'ew-resize',
  },

  headerDropdown: {
    color: 'white',
    fontWeight: 'bold',
    marginRight: theme.spacing(4.5),
  },
  headerItemCount: {
    color: 'white',
    height: 'auto',
    fontWeight: 'bold',
    alignContent:'left',
    textTransform: 'uppercase'
  },

  myListItemContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },

  myListItemDate: {
    fontSize: 10, 
    marginTop: '2px'
  },

  myListItemInfo: {
    width: 150,
    textAlign: 'left',
    marginBottom: '10px',
  },

  myListItemTitle: {
    margin: 'auto', 
    fontSize: '1rem',
    fontWeight: 500,
    color: 'rgba(0,0,0,0.74)'
  },

  myListsToolTipContainer: {
    'width': '300px',
    'overflow': 'visible',
    'backgroundColor': theme.palette.background.paper,
    'zIndex': 100,
    'borderRadius': '5px',
    'filter': 'drop-shadow(0 2px 2px rgba(0, 0, 0, .3))',
    '&[x-placement*="bottom"] $arrow': {
      'top': 0,
      'left': 0,
      'marginTop': '-0.375em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      'bottom': 0,
      'left': 0,
      'marginBottom': '-0.375em',
      '&::before': {
        top: '-12px',
        left: '-6px',
        borderBottom: 'unset',
        borderTop: '8px solid #fff',
      }
    },
  },

  myListsTitle: {
    'fontSize': '1.5rem',
    'color': 'rgba(0,0,0,0.54)'
  },

  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },

  titleCellNoContent: {
    width: '100%',
    height: '100%'
  },
  titleCellContentWrapper: {
    width: '100%',
    height: '100%'
  },
  documentsTableCellWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  documentsTableCellContent: {
    paddingBottom: '2px'
  },
  
  scrollIdentifier: {
    /* don't delete this (used as id for multigrid autoscroll-on-resize) */
  }
});

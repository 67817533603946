import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Can from '../../../auth/ability';
import OurDealsLogo, { renderOurDeals } from '../../../common/OurDealsLogo';
import Stat from '../../../common/stats/Stat';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const PrimaryText = ({ properties = [{}], createdBy = {}, customClass }) => {
  const isOurDeals = renderOurDeals({
    datatype: 'sale',
    item: { createdBy }
  });
  const classes = useStyles();
  const propertyAddress = get(properties, '0.address', '\u2013').split(',')[0];
  const propertyDisplay = get(properties, '0.name', propertyAddress);

  return (
    <div
      className={isOurDeals ? classes.primaryTextOurDeals : classes.primaryText}>
      {propertyDisplay}
      <Can do="use" on="_app">
        {isOurDeals && (
          <OurDealsLogo customClass={customClass} />
        )}
      </Can>
    </div>
  );
};

PrimaryText.propTypes = {
  createdBy: PropTypes.object,
  customClass: PropTypes.string,
  properties: PropTypes.array,
};

export const SecondaryText = ({ buyers, properties }) => {
  const classes = useStyles();
  const address = get(properties, '0.address', '\u2013');
  const buyer = get(buyers, '0.name', '\u2013');
  return (
    <React.Fragment>
      <div className={classes.secondaryText}>
        <p>
          {address}
        </p>
      </div>
      <div>
        <Stat
          className={classes.stat}
          label="Buyer"
          paddingValue="29px"
          value={buyer} />
      </div>
    </React.Fragment>
  );
};

SecondaryText.propTypes = {
  buyers: PropTypes.array,
  properties: PropTypes.array,
};

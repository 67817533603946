import grey from '@material-ui/core/colors/grey';
import { COLLIERS_BLUE } from '../../styles/theme';

const freedrawColor = COLLIERS_BLUE;

const datatypeWidth = 60;
export default (theme) => ({
  ['@global']: {
    '[data-circling="1"] .leaflet-interactive': {
      pointerEvents: 'none !important',
    },

    '.mode-create': {
      cursor: 'crosshair',
    },

    '.leaflet-edge': {
      backgroundColor: freedrawColor,
      boxShadow: '0 0 0 1px white, 0 0 1px rgba(0, 0, 0, .35)',
      borderRadius: '50%',
      cursor: 'move',
      outline: 'none',
      transition: 'background-color .25s'
    },

    '.leaflet-polygon': {
      fill: freedrawColor,
      stroke: freedrawColor
    },

    '.leaflet-control-layers-toggle': {
      height: '30px !important',
      width: '30px !important',
    },
    '.leaflet-control-draw': {
      left: '50%',
      transform: 'translate(-50%, 0%)',
    },
  },
  ['@keyframes growIn']: {
    from: { transform: 'scale(0, 0)' },
    to: { transform: 'scale(1, 1)' }
  },

  appBarIconButton: {
    color: 'white',
    padding: 0,
  },

  boomLink: {
    'color': grey[800],
    '&:hover': {
      textDecoration: 'none !important',
    },
  },

  caret: {
    backgroundColor: 'grey !important',
    position: 'absolute',
    minWidth: '1rem',
    minHeight: '1rem',
    transform: 'rotate(45deg)',
    zIndex: '-2',
    top: '3rem',
    left: '1rem',
    borderRadius: 0
  },

  circularProgressContainer: {
    color: 'white',
    marginRight: 2,
    marginLeft: 5
  },

  closeButton: {
    'display': 'flex',
    'margin': '0 auto'
  },

  collapseToggleButton: {
    backgroundColor: 'transparent !important',
    color: 'white !important',
    margin: '14px 0 !important',
  },

  checkBox: {
    alignItems: 'flex-start',
    minWidth: 36,
    marginLeft: '.5rem'
  },

  createListButton: {
    color: theme.palette.colliersBlue,
    ['&:hover']: {
      borderRadius: '50%',
    }
  },

  createListMenu: {
    display: 'flex',
    flexDirection: 'column',
    margin: '8px 32px 0px 32px'
  },

  createListMenuChip: {
    marginRight: 20
  },

  createListMenuChipDiv: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 30,
    marginRight: 10
  },

  createListMenuTextField: {
    marginBottom: 30
  },

  createListTitle: {
    display: 'flex',
    alignItems: 'center',
    ['& h6']: {
      margin: 0
    }
  },

  createListText: {
    color: theme.palette.colliersBlue,
    fontSize: '16px',
    fontWeight: '525',
    marginLeft: '-2px'
  },

  datatype: {
    display: 'inline-block',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: datatypeWidth,
    zIndex: 0,
    ['& aside']: {
      display: 'inline-block',
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateX(20px)',
      transition: 'opacity .2s ease-in, transform .2s ease-in',
      zIndex: -1,
    },
    ['& $enabledDatatypeTooltip']: {
      backgroundColor: 'white',
      borderRadius: theme.spacing(0.5),
      color: 'black',
      display: 'none',
      fontSize: '16px',
      left: datatypeWidth,
      lineHeight: '30px',
      margin: `${theme.spacing(0.5)}px 0`,
      marginLeft: theme.spacing(13),
      opacity: 0.8,
      padding: `0 ${theme.spacing()}px`,
      position: 'absolute',
      top: 0,
    },
    ['& $disabledDatatypeTooltip']: {
      backgroundColor: 'white',
      borderRadius: theme.spacing(0.5),
      color: 'black',
      display: 'none',
      fontSize: '16px',
      left: datatypeWidth,
      lineHeight: '30px',
      margin: `${theme.spacing(0.5)}px 0`,
      opacity: 0.8,
      padding: `0 ${theme.spacing()}px`,
      position: 'absolute',
      top: 0,
    },
    ['& $filterIconTooltip']: {
      backgroundColor: 'white',
      borderRadius: theme.spacing(0.5),
      color: 'black',
      display: 'none',
      fontSize: '16px',
      left: '50px',
      top: '40px',
      lineHeight: '30px',
      margin: `${theme.spacing(0.5)}px 0`,
      opacity: 0.8,
      padding: `0 ${theme.spacing()}px`,
      position: 'absolute',
    },
    ['& $turnOffIconTooltip']: {
      backgroundColor: 'white',
      borderRadius: theme.spacing(0.5),
      color: 'black',
      display: 'none',
      fontSize: '16px',
      left: '95px',
      top: '40px',
      lineHeight: '30px',
      margin: `${theme.spacing(0.5)}px 0`,
      opacity: 0.8,
      padding: `0 ${theme.spacing()}px`,
      position: 'absolute',
    },
    ['&:hover']: {
      overflowX: 'visible',
      zIndex: 3,
      ['& aside']: {
        opacity: 1,
        transform: 'translateX(96px)',
      },
      ['& $enabledDatatypeTooltip']: {
        display: 'block'
      },
      ['& $disabledDatatypeTooltip']: {
        display: 'block'
      },
      ['& $filterIconTooltip']: {
        display: 'block'
      },
      ['& $turnOffIconTooltip']: {
        display: 'block'
      },
    },
  },

  datatypeButton: {
    height: theme.spacing(5),
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing()}px ${theme.spacing()}px !important`,
    width: theme.spacing(5),
    ['& button']: {
      borderRadius: '50%',
      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px',
      ['&:hover']: {
        boxShadow: 'inset 0 0 0 99999px rgba(255,255,255,0.4)',
      },
    },
    ['&:not(:first-child)']: {
      opacity: 1,
      transform: 'translateX(0)',
    },
    ['&:hover']: {
      overflowX: 'visible',
      zIndex: 3,
      ['& aside']: {
        opacity: 1,
        transform: 'translateX(96px)',
      },
      ['& $disabledDatatypeTooltip']: {
        display: 'block'
      },
    },
  },

  documentsListWrapper: {
    borderColor: theme.palette.grey[300],
    maxHeight: 300,
    padding: 0,
  },

  documentsListItemContainer:{
    width: '100%',
    margin: '10 10',
  },

  documentsListItemRoot:{
    padding: '20px 20px',
  },

  documentsFileIcon: {
    width: 40,
  },

  documentLink: {
    textDecoration: 'none',
    color: 'inherit',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
  },

  documentDataWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 200,
  },

  documentDetailLabel: {
    marginRight: 5,
  },

  documentDetailValue: {
    margin: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  ellipsisText: {
    maxWidth: '160px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
  },

  enabledDatatypeTooltip: {},
  
  disabledDatatypeTooltip: {},

  filterIconTooltip: {},

  turnOffIconTooltip: {},

  customBadge: {
    left: '12px',
    bottom: '26px',
    border: '1px solid white',
    minWidth: '15.4px',
    height: '15.4px',
    padding: '0 0px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    fontSize: '0.6rem',
    fontWeight: 'bold',
  },

  detailsContainer: {
    alignItems: 'stretch',
    display: 'flex',
    justifyContent: 'stretch',
    position: 'absolute',
    zIndex: 5
  },

  filteredListsContainer: {
    height: 200,
    overflowY: 'scroll',
  },

  headerButton: {
    color: 'white',
  },

  headerTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 18,
    ['& h3']: {
      margin: 0
    }
  },

  headerWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    letterSpacing: '1px',
    width: '90%',
    left: 24,
    color: 'white',
    position: 'absolute'
  },
  headerAppBar: {
    borderRadius: '4px 4px 0 0',
  },

  headerCount: {
    color: 'white',
    fontWeight: 'bold',
    marginLeft: 0,
    marginRight: theme.spacing(0.5)
  },

  headerToolbar: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
  },

  loadingPopup: {
    height: '50px',
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  listCollapse: {
    display: 'none',
  },

  listContainer: {
    marginLeft: '10px',
    zIndex: 2
  },

  listContainerCollapsed: {
    height: 64,
    zIndex: 2
  },

  listExpand: {
    display: 'none',
  },

  listHeaderActions: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    marginRight: -16,
  },

  listItemWrapper: {
    width: '100%',
    display: 'flex', 
    alignItems: 'center'
  },

  listItemIcons: {
    padding: '6px 2px',
    opacity: .66
  },

  listItemIconsContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    justifyContent: 'flex-end',
    marginRight: 20,
  },

  listItemMenuAction: {
    marginRight: -theme.spacing(1.5)
  },

  listItemMenuWrapper: {
    display: 'flex',
    flexDirection: 'column' 
  },

  listItemTextContainer: {
    width: '90%', 
    marginTop: 6
  },

  listModeNotice: {
    alignItems: 'center',
    backgroundColor: '#f2d68c',
    display: 'flex',
    justifyContent: 'center'
  },

  listModeNoticeText: {
    fontSize: '0.7rem',
    fontWeight: 600
  },

  listModeRow: {
    alignItems: 'center',
    backgroundColor: '#f2d68c',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing()}px ${theme.spacing()}px`
  },

  listModeWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },

  myListItemContainer: {
    display: 'flex',
    justifyContent: 'space-around',
  },

  myListItemDate: {
    fontSize: 10, 
    marginTop: '2px'
  },

  myListItemInfo: {
    width: 150,
    textAlign: 'left',
    marginBottom: '10px',
  },

  myListItemTitle: {
    margin: 'auto', 
    fontSize: '1rem',
    fontWeight: 500,
    color: 'rgba(0,0,0,0.74)'
  },

  menuContainer: {
    flexDirection: 'column',
    left: 0,
    marginTop: theme.spacing(),
    position: 'absolute',
    top: 0,
    width: datatypeWidth,
    zIndex: 4,
  },

  overlay: {
    opacity: 0.5,
    ['&:hover']: {
      opacity: 0.8,
    }
  },

  overlayLegend: {
    bottom: 32,
    height: 180,
    left: 102,
    padding: 10,
    position: 'fixed',
    zIndex: 999,
    ['& h3']: {
      margin: 0
    }
  },

  overlayLegendList: {
    margin: 0,
    padding: 0,
    ['& > li']: {
      alignItems: 'center',
      display: 'flex',
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
  },

  overlayLegendItemColor: {
    height: 20,
    marginRight: 5,
    width: 20
  },

  overlayTooltip: {
    '& h5': {
      margin: 0
    }
  },

  pin: {
    outline: 'none',
    ['& > div']: {
      backgroundColor: grey[600],
      border: '1px solid white',
      borderRadius: '50%',
      boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, .3)',
      height: 20,
      outline: 'none',
      transform: 'scale(1)',
      transition: 'transform .1s ease-in',
      width: 20,
      ['&:hover']: {
        border: '1px solid black',
        transform: 'scale(1.75)'
      },
      ['& svg']: {
        height: 15,
        left: 2,
        top: 1,
        width: 15
      },
    },
    ['&.hovered > div']: {
      transform: 'scale(1.75)'
    },
    ['&$zoomedInPin']: {
      ['& > div']: {
        animation: '$growIn',
        ['& svg']: {
          margin: 1
        }
      }
    },
    ['&$zoomedOutPin']: {
      ['& > div']: {
        height: 13,
        width: 13,
        ['& svg']: {
          display: 'none !important',
        }
      }
    }
  },
  zoomedInPin: {},
  zoomedOutPin: {},

  ['@media screen and (max-width: 600px)']: {
    ['@global']: {
      '.leaflet-control-zoom': {
        marginTop: '75px !important',
      },
      '.leaflet-control-layers': {
        display: 'none'
      },
      '.leaflet-control-draw': {
        display: 'none'
      }
    },

    detailsContainer: {
      bottom: '0 !important',
      left: '0 !important',
      right: '0 !important',
      top: '0 !important',
      zIndex: '6 !important',
    },

    list: {
      width: 'auto !important'
    },

    listContainer: {
      height: '100% !important',
      maxHeight: 'none !important',
      right: '0 !important',
      top: '0 !important',
      width: '100% !important',
      zIndex: '5 !important',
      ['& $listCollapse']: {
        display: 'block !important',
      }
    },

    listContainerCollapsed: {
      right: '0 !important',
      top: '0 !important',
      width: '100% !important',
      zIndex: '5 !important',
      ['& $listExpand']: {
        display: 'block !important'
      }
    },

    listHeaderActions: {
      display: 'none !important'
    },

    menuContainer: {
      marginTop: 75
    },
  },

  toolTipContainer: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 100,
    borderRadius: '5px',
    filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, .3))'
  },
  myListsToolTipContainer: {
    'width': '300px',
    'overflow': 'visible',
    'backgroundColor': theme.palette.background.paper,
    'zIndex': 100,
    'borderRadius': '5px',
    'filter': 'drop-shadow(0 2px 2px rgba(0, 0, 0, .3))',
    '&[x-placement*="bottom"] $rightPanelArrow': {
      'top': 0,
      'left': 0,
      'marginTop': '-0.375em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $rightPanelArrow': {
      'bottom': 0,
      'left': 0,
      'marginBottom': '-0.375em',
      '&::before': {
        top: '-12px',
        left: '-6px',
        borderBottom: 'unset',
        borderTop: '8px solid #fff',
      }
    },
  },

  myListsTitle: {
    'fontSize': '1.5rem',
    'color': 'rgba(0,0,0,0.54)'
  },

  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },

  arrow: {
    'position': 'absolute',
    // 'width': '100%',
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: '8px solid #fff',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      position: 'absolute',
      zIndex: 100,
      top: '-12px',
      left: '50%',
      transform: 'translate(-50%, 50%)'
    },
  },
  
  collapse: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },

  lightButton: {
    backgroundColor: theme.palette.common.white,
    height: '40px',
    width: '40px',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0
  },
  ourDealsLogo: {
    width: '45px',
    position: 'absolute',
    top: '8px',
    right: '6px',
  },

  popup: {
    '& .leaflet-popup-tip-container': {
      'display': 'none'
    },
    '& .leaflet-popup-close-button': {
      'color': 'white !important',
      'right': '16px !important',
      'top': '16px !important',
      'position': 'absolute',
      '&:hover': {
        'color': 'white'
      }
    },
    ['& header']: {
      padding: theme.spacing(),
    },
    ['& h4']: {
      color: grey[800],
      fontSize: '120%',
      fontWeight: 'normal',
      margin: 0,
    },
    ['& h5']: {
      color: grey[500],
      fontWeight: 'normal',
      margin: 0,
    },
    ['& article']: {
      fontSize: '50%',
      maxHeight: '180px',
      overflowY: 'scroll'
    }
  },

  popupHeader: {
    cursor: 'pointer',
  },

  reportButtonToolTipContainer: {
    'width': '300px',
    'overflow': 'visible',
    'backgroundColor': theme.palette.background.paper,
    'zIndex': 100,
    'borderRadius': '5px',
    'filter': 'drop-shadow(0 2px 2px rgba(0, 0, 0, .3))',
    '&[x-placement*="bottom"] $printArrow': {
      'top': -8,
      'left': 288,
      'marginTop': '-0.375em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $printArrow': {
      'bottom': 0,
      'left': 0,
      'marginBottom': '-0.375em',
      '&::before': {
        top: '-12px',
        left: '-6px',
        borderBottom: 'unset',
        borderTop: '8px solid #fff',
      }
    },
  },

  reportMenuTitles: {
    '& div': {
      'marginLeft': '-4.5% !important',
    }, 
    '& div:nth-child(3)': {
      boxShadow: 
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 0px 2px rgba(0,0,0,0.12)',
      marginTop: '3.25em !important',
    }, 
  },

  resetDiv: {
    height: theme.spacing(5),
    margin: `0 ${theme.spacing(0.5)}px ${theme.spacing()}px ${theme.spacing()}px`,
    width: theme.spacing(5),
    ['& div']: {
      backgroundColor: '#C64444',
      fontSize: '.6rem',
      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px',
      padding: theme.spacing(.5),
      cursor: 'pointer',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      ['&:hover']: {
        boxShadow: 'inset 0 0 0 99999px rgba(255,255,255,0.4)',
      },
    },
    ['&:not(:first-child)']: {
      opacity: 1,
      transform: 'translateX(0)',
    }
  },

  resetMenu: {
    display: 'inline-block',
    position: 'relative',
    width: datatypeWidth,
    zIndex: 0,
    ['& aside']: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      opacity: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      transform: 'translateX(20px)',
      transition: 'opacity .2s ease-in, transform .2s ease-in',
      zIndex: -1,
    },
    ['&:hover']: {
      overflowX: 'visible',
      zIndex: 3,
      ['& aside']: {
        opacity: 1,
        transform: 'translateX(96px)',
      },
    },
  },

  resetMenuBox: {
    borderRadius: '5px',
    minWidth: theme.spacing(5.4),
    height: theme.spacing(5.4),
    textAlign: 'center'
  }, 

  resetTextBox: {
    backgroundColor: 'grey !important',
    width: '150px',
    marginTop: '5px',
    borderRadius: '5px',
    textAlign: 'center'
  },

  printArrow: {
    'position': 'absolute',
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: '8px solid #fff',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      position: 'absolute',
      zIndex: 100,
      left: '50%',
      transform: 'translate(-50%, 50%)'
    },
  },

  quickPrint: {
    padding: '6px 2px',
    opacity: .66
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import datatypes from '../common/datatypes';
import * as feedContent from './feedItem/content';
import Actions from './feedItem/Actions';
import getTitles from './feedItem/getTitles';
import ShareItem from './feedItem/ShareItem';
import styles from './module.css';

const NoContentYet = () => <h1>NO CONTENT YET</h1>;
const noContentYet = {
  CardContent: NoContentYet,
  ExpandedCardContent: NoContentYet,
};

const FeedItem = ({
  classes,
  createFavorite,
  favorites,
  hideShareItem,
  item,
  onToggle,
  removeFavorite,
  sendShareItem,
  shareItem,
  showShareItem,
}) => {
  const { Icon, color, key, stats } = datatypes[item._type];
  const Info = feedContent[key] || noContentYet;
  const onExpandChange = () => onToggle(item, !item.isExpanded);
  item.favorite = find(favorites, { resourceId: item.id });

  return (
    <Card className={classes.card}>
      <CardHeader
        {...getTitles(item)}
        avatar={(
          <Avatar className={classes.avatar} style={{ backgroundColor: color }}>
            <Icon color="inherit" />
          </Avatar>
        )}
        className={classes.cardHeader}
        classes={{
          title: classes.cardTitle,
          subheader: classes.cardSubtitle,
        }}
        onClick={onExpandChange} />
      
      <CardContent>
        <div onClick={onExpandChange}>
          <Info.CardContent stats={stats} item={item} />
        </div>
        {item.isExpanded && (
          <div style={{ marginTop: 16 }}>
            {!item.isHydrated && <LinearProgress />}
            <Collapse in={item.isHydrated}>
              <Info.ExpandedCardContent stats={stats} item={item} />
            </Collapse>
          </div>
        )}
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Actions
          expand={onExpandChange}
          favorite={createFavorite}
          share={showShareItem}
          item={item}
          unfavorite={removeFavorite} />
      </CardActions>

      <ShareItem
        send={sendShareItem}
        isVisible={shareItem === item.id}
        item={item}
        hide={hideShareItem}>
        <Card>
          <CardHeader
            {...getTitles(item)}
            avatar={(
              <Avatar size={50} style={{ backgroundColor: color, color: 'white' }}>
                <Icon color="inherit" />
              </Avatar>
            )}
            className={classes.cardHeader}
            classes={{
              title: classes.cardTitle,
              subheader: classes.cardSubtitle,
            }} />
          <CardContent className={classes.shareCardContent}>
            <Info.CardContent stats={stats} item={item} />
          </CardContent>
        </Card>
      </ShareItem>

    </Card>
  );
};

FeedItem.propTypes = {
  classes: PropTypes.object,
  createFavorite: PropTypes.func.isRequired,
  favorites: PropTypes.array.isRequired,
  hideShareItem: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  onToggle: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  sendShareItem: PropTypes.func.isRequired,
  shareItem: PropTypes.string,
  showShareItem: PropTypes.func.isRequired,
};

export default withStyles(styles)(FeedItem);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import SaveIcon from '@material-ui/icons/Save';
import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import Confirm from '../../../common/Confirm';
import GridViewCreator from './GridViewCreator';
import styles from './module.css';

export const GridViews = ({
  classes,
  columns,
  customColumns,
  datatype,
  deleteGridView,
  options,
  onSelect,
  saveGridView,
  selectedGridView,
  updateGridView
}) => {
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = React.useState(false);

  const handleCreateClose = () => { return setShowCreateModal(false); };

  const optionsWithCustom = [{ id: 'Default', columns: customColumns, title: 'Default' }].concat(options);
  const canEditGridView = selectedGridView && selectedGridView.id !== 'Default';
  const canSaveGridView = !selectedGridView || selectedGridView.id === 'Default';
  const allColumnsToggledOff = columns.filter((c) => c.isEnabled).length === 0;

  const onChange = ({ target: { value } }) => {
    return onSelect(optionsWithCustom.find((option) => option.id === value));
  };

  const onDeleteGridView = () => {
    setShowConfirmDeleteModal(true);
  };

  const handleDeleteConfirm = () => {
    setShowConfirmDeleteModal(false);
    return deleteGridView(datatype, selectedGridView.id);
  };

  const handleDeleteDeny = () => {
    return setShowConfirmDeleteModal(false);
  };

  const onSaveGridView = () => {
    if (allColumnsToggledOff) {
      return;
    }
    return updateGridView(datatype, selectedGridView);
  };

  const handleSaveClick = () => {
    setShowCreateModal(true);
  };

  const handleSaveGridView = (data) => {
    if (allColumnsToggledOff) return;
    saveGridView(datatype, data);
    setShowCreateModal(false);
  };

  return (
    <div>
      <div className={classes.subHeader}>
        <FormControl>
          <Select
            value={selectedGridView ? selectedGridView.id : 'Default'}
            onChange={onChange}
            className={classes.layoutList}>
            {optionsWithCustom.map((option, i) => <MenuItem key={i} value={option.id}>{option.title}</MenuItem>)}
          </Select>
        </FormControl>
        {canEditGridView &&
          <div>
            <Tooltip title="Save Grid View">
              <IconButton disabled={allColumnsToggledOff} onClick={onSaveGridView}>
                <SaveIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Grid View">
              <IconButton onClick={onDeleteGridView}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        }
        {canSaveGridView &&
          <Tooltip title="Save Grid View">
            <IconButton disabled={allColumnsToggledOff} onClick={handleSaveClick}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        }
        <GridViewCreator
          onClose={handleCreateClose}
          onSave={handleSaveGridView}
          open={showCreateModal} />
        <Confirm
          confirmButtonText="Yes"
          denyButtonText="No"
          onConfirm={handleDeleteConfirm}
          onDeny={handleDeleteDeny}
          open={showConfirmDeleteModal}
          text={`Delete "${(selectedGridView || {}).title}"?`} />
      </div>
      <div>
        {allColumnsToggledOff && 
        <div className={classes.warningAlert}>
          Please have (1) or more columns active.
        </div>}
      </div>
    </div>
  );
};

GridViews.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  currentColumnsOrganizer: PropTypes.array,
  currentColumnsOrganizerGridViews: PropTypes.array,
  currentListView: PropTypes.string,
  customColumns: PropTypes.array.isRequired,
  datatype: PropTypes.string.isRequired,
  deleteGridView: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  saveGridView: PropTypes.func.isRequired,
  selectedGridLayoutProps: PropTypes.object.isRequired,
  selectedGridView: PropTypes.object,
  updateGridView: PropTypes.func.isRequired
};

function mapStateToProps({ research, routing: { locationBeforeTransitions: { query: { currentListView } } } }) {
  return {
    currentColumnsOrganizerGridViews: research.table.columnsOrganizer.gridViews,
    selectedGridLayoutProps: research.table.columnsOrganizer.selectedGridViews || {},
    currentListView
  };
}

const ConnectedGridViews = connect(mapStateToProps)(GridViews);
const StyledGridViews = withStyles(styles)(ConnectedGridViews);
export default StyledGridViews;

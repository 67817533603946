import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './actions';
import Comments from './Comments';

export class CommentsContainer extends React.Component {
  static propTypes = {
    fetchComments: PropTypes.func.isRequired,
    property: PropTypes.object.isRequired,
    saveComment: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.props.fetchComments(this.props.property.id);
  }

  handleCommentSave = ({ body }) => {
    return this.props.saveComment(body, this.props.property.id);
  };

  render() {
    return <Comments {...this.props} onCommentSave={this.handleCommentSave} />;
  }
}

function mapStateToProps({ research, auth: { currentUser } }) {
  const {
    property,
    comments,
    isSavingComment,
    isFetchingComments,
  } = research.propertyDetail;

  return {
    currentUser,
    property,
    comments,
    isSavingComment,
    isFetchingComments,
  };
}

export default connect(mapStateToProps, actions)(CommentsContainer);

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function inKilometers(_start, _end) {
  const start = {
    lat: Array.isArray(_start) ? _start[1] : _start.lat,
    lng: Array.isArray(_start) ? _start[0] : _start.lng
  };
  const end = {
    lat: Array.isArray(_end) ? _end[1] : _end.lat,
    lng: Array.isArray(_end) ? _end[0] : _end.lng
  };

  const R = 6371;
  const dLat = deg2rad(end.lat - start.lat);
  const dLon = deg2rad(end.lng - start.lng);
  const a = Math.sin(dLat / 2)
    * Math.sin(dLat / 2)
    + Math.cos(deg2rad(start.lat))
    * Math.cos(deg2rad(end.lat))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

export default function inMiles(start, end) {
  return inKilometers(start, end) * 0.621371;
}

import { COLLIERS_CYAN } from '../../styles/theme';

export default (theme) => ({
  buttonContainer: {
    marginTop: '16px',
    textAlign: 'right',
  },
  card: {
    overflow: 'auto',
    marginBottom: 16,
  },
  cellWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  dragHandle: {
    color: theme.palette.colliersBlue,
    cursor: 'grab',
    justifySelf: 'flex-start',
    alignSelf: 'flex-end',
  },
  dropDown: {
    'width': '160px',
    '& > div > div': {
      textAlign: 'center'
    }
  },
  headerIcons: {
    paddingRight: '8px',
  },
  hidePagination: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'flexDirection': 'row-reverse', 
    [theme.breakpoints.down(1570)]: {
      'flexDirection': 'column',
      'flexWrap': 'wrap',
    },
  },
  manageButton: {
    'border': 'none',
    'background': 'none',
    'cursor': 'pointer',
    'paddingRight': '16px',
    'color': theme.palette.colliersBlue,
    'fontWeight': 'bold',
    '&:hover': {
      color: COLLIERS_CYAN,
      textDecoration: 'underline'
    }
  },
  ourDealsFilter: {
    marginBottom: theme.spacing(3),
    paddingRight: theme.spacing(5),
    height: '10px'
  },
  ourDealsTabs: {
    'display': 'flex',
    'marginBottom': theme.spacing(0),
    'border': '1px solid #9d9d9d', 
    'width': 'fit-content',
    'height': '2px',
    '& > div > div > button > .MuiTouchRipple-root': {
      border: '1px solid #9d9d9d',
    },
  }, 
  removeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  rowTotal: {
    fontWeight: 'bold',
    paddingLeft: '8px',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '16px'
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableCellButton: {
    width: '100%',
    height: '100%',
    textAlign: 'left',
    padding: '6px 16px',
  },  
  tableFooter: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    [theme.breakpoints.down(1570)]: {
      'flexDirection': 'column',
      'flexWrap': 'wrap',
    },
  },
  tableHead: {
    '& > tr > th': {
      fontWeight: 'bold',
    },
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableHeadRoot: {
    '& > thead > tr > th': {
      backgroundColor: '#FFFFFF'
    }
  },
  tablePagination: {
    'margin': '0px',
    'overflow': 'auto',
    '& > div': {
      padding: '0px',
    },
    '& > div > p:nth-of-type(1)': {
      paddingLeft: '12px',
      fontWeight: 'bold',
    },
    '& > div > p:nth-of-type(2)': {
      paddingLeft: '80px',
      fontWeight: 'bold',
    },
    '& > div > div:nth-child(3)': {
      marginLeft: '0px',
      marginRight: '0px',
    },
    '& > div > div > div': {
      fontWeight: 'bold',
    },
  },
  title: {
    'display': 'flex',
    'flexDirection': 'row-reverse',
    'color': theme.palette.colliersBlue,
    '& > div': {
      paddingRight: '8px',
      marginTop: '-8px'
    },
  },
  widgetWrapper: {
    border: '1px solid #ccc',
    borderRadius: '4px',
    marginBottom: '10px',
    padding: '10px',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'grab',
  },
});

import {
  DETAIL_TIM_FETCH_REQUEST,
  DETAIL_TIM_FETCH_ERROR,
  DETAIL_TIM_FETCH_SUCCESS,
  DETAIL_TIM_CLEAR
} from '../../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest,
  requestData,
  receiveData
} from '../../common/apiActions';
import api from '../../services/api';

export function clearTim() {
  return {
    type: DETAIL_TIM_CLEAR
  };
}

export function fetchTim(id) {
  return (dispatch) => {
    dispatch(requestData(DETAIL_TIM_FETCH_REQUEST, { id }));
    return api.read(`/tims/${id}`)
      .then((data) => dispatch(receiveData(DETAIL_TIM_FETCH_SUCCESS, data)))
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return response.json().then((error) => dispatch(handleError(DETAIL_TIM_FETCH_ERROR, error)));
      });
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AreaIcon from '@material-ui/icons/BorderOuter';
import DateIcon from '@material-ui/icons/Today';
import RentIcon from '@material-ui/icons/AttachMoney';
import TermIcon from '@material-ui/icons/Timer';
import Stats from '../../common/stats/Stats';
import SummaryStats from '../../common/stats/SummaryStats';
import styles from './module.css';

const getClasses = makeStyles(styles);

export const CardContent = ({ item, stats }) => {
  const summaryIcons = {
    area: AreaIcon,
    leaseTerm: TermIcon,
    rentRate: RentIcon,
    signedDate: DateIcon,
  };
  const orderedStats = ['signedDate', 'rentRate', 'area', 'leaseTerm'];
  const filteredStats = stats.filter(({ key }) => {
    return orderedStats.indexOf(key) > -1;
  });

  filteredStats.sort((a, b) => {
    return orderedStats.indexOf(a.key) < orderedStats.indexOf(b.key) ? -1 : 1;
  });

  return <SummaryStats schema={filteredStats} icons={summaryIcons} item={item} />;
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired
};

export const ExpandedCardContent = ({ stats, item }) => {
  const classes = getClasses();
  const fieldNotes = item.fieldNotes || {};
  const filteredStats = stats.filter(({ key }) => {
    return (item[key] || fieldNotes[key]) && [
      '__tenant',
      '_type',
      'id',
      'canonicalName',
      'location',
      'isExpanded',
      'isHydrated',
      'rescourId',
      'createdAt',
      'property',
      'rentSchedule'
    ].indexOf(key) === -1;
  });

  return (
    <section className={classes.stats}>
      <Stats schema={filteredStats} item={item} />
    </section>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

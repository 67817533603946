import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';

const CloseButton = ({ ...props }) => {
  return (
    <Tooltip title="Close" placement="left">
      <IconButton {...props}>
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
};

CloseButton.propTypes = {
  link: PropTypes.string
};

export default CloseButton;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SummaryFieldStats from '../propertyDetail/common/SummaryFieldStats';
import styles from './module.css';

const formatAddress = (streetAddress, city, state, zip) => {
  if (streetAddress && city && state && zip) {
    return `${streetAddress}, ${city}, ${state} ${zip}`;
  }
  return '\u2013';
};

const formatDate = (date) => {
  return date ? moment(date).format('MM/DD/YYYY') : '\u2013';
};

const formatDollarNumber = (number) => {
  return number ? `$${number.toLocaleString('en-US')}` : '\u2013';
};

const ParcelStats = ({
  assessment,
  classes,
  parcel,
}) => {
  const stats = [
    { label: 'APN',
      value: get(parcel, 'parcels.0.assessment.apn'), 
      format: 'string' },
    { label: 'Property Size (SF)', 
      value: get(assessment, 'primaryStructure.buildingArea'), 
      format: 'sq-m-to-sq-f' },
    { label: 'Lot Size (Acres)', 
      value: get(assessment, 'lot.lotSize'), 
      format: 'sq-m-to-acres' },
    { label: 'Assessed Value', 
      value: formatDollarNumber(get(assessment, 'assessedValue.total')), 
      format: 'number' },
    { label: 'Last Sale', 
      value: formatDate(get(assessment, 'transaction.lastMarketSale.transferDate')), 
      format: 'string' },
    { label: 'Last Sale Amount', 
      value: formatDollarNumber(get(assessment, 'transaction.lastMarketSale.value')), 
      format: 'string' },
  ];

  return (
    <div className={classes.header}>
      <Typography className={classes.title} variant="h5">
        {get(parcel, 'parcels.0.location.streetAddress', '\u2013')}
      </Typography>
      <Typography className={classes.address} variant="h5">
        {formatAddress(
          get(parcel, 'parcels.0.location.streetAddress'), 
          get(parcel, 'parcels.0.location.locality'), 
          get(parcel, 'parcels.0.location.regionCode'), 
          get(parcel, 'parcels.0.location.postalCode'))}
      </Typography>
      <SummaryFieldStats
        property={true} 
        stats={stats} />
    </div>
  );
};

ParcelStats.propTypes = {
  assessment: PropTypes.object,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  params: PropTypes.object,
  parcel: PropTypes.object,
  router: PropTypes.object,
  transactions: PropTypes.array
};

export default withStyles(styles)(ParcelStats);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import styles from './module.css';

const UnitMixes = ({ classes, value }) => {
  return (
    <div>
      {value && (
        <Table size="small" className={classes.statTableUnitMixes}>
          <TableHead>
            <TableRow>
              <TableCell>Bed</TableCell>
              <TableCell>Bath</TableCell>
              <TableCell>Area</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Units</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map((unit, key) => {
              return (
                <TableRow key={key} className={classes.statTableRow}>
                  <TableCell>{unit.bed || '-'}</TableCell>
                  <TableCell>{unit.bath || '-'}</TableCell>
                  <TableCell>{unit.area || '-'}</TableCell>
                  <TableCell>{unit.price || '-'}</TableCell>
                  <TableCell>{unit.units || '-'}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>)}
    </div>
  );
};

UnitMixes.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.array
};

export default withStyles(styles)(UnitMixes);

import React from 'react';
import PropTypes from 'prop-types';
import styles from '../module.css';
import Section from './Section';
import { withStyles } from '@material-ui/core';

const SuiteInfo = ({ classes, item, fieldNotes }) => {
  const {
    spaceUses,
    parkingRatio,
    dockDoors,
    driveIns,
    amperage,
    amperageHigh,
    electricalPhase,
    hasRailSpur,
    hasColdStorage,
    unitMixes,
    parkingDetails,
    clearHeightMax,
    clearHeightMin,
    voltage,
    voltageHigh,
    sprinklers,
    hasFencedYard,
    columnSpacingWidthMin,
    columnSpacingWidthMax,
    columnSpacingDepth,
    columnSpacingWidth,
    units,
    hasMezzanine,
    isVented,
  } = item;
  const suiteInfoSpecialTopRow = [
    [
      { key: 'parkingRatio', label: 'Parking Ratio', value: parkingRatio, format: 'parkingRatio' },
    ],
    [
      {
        key: 'parkingDetails',
        label: 'Parking Details',
        value: parkingDetails,
        format: 'parkingDetails',
        customClass: classes.spanTwoColumns
      }
    ]
  ];
  const suiteInfoSpecialRow = [
    [
      { key: 'hasRailSpur', label: 'Rail Spur', value: hasRailSpur, format: 'boolean' },
      { key: 'hasColdStorage', label: 'Cold Storage', value: hasColdStorage, format: 'boolean' },
      {
        key: 'spaceUses',
        label: 'Space Uses',
        value: spaceUses,
        format: 'spaceUses',
        customClass: classes.spanTwoColumns
      }
    ],
    [
      { key: 'hasMezzanine', label: 'Mezzanine', value: hasMezzanine, format: 'boolean' },
      { key: 'hasFencedYard', label: 'Fenced Yard', value: hasFencedYard, format: 'boolean' }
    ],
    [
      { key: 'isVented', label: 'Vented', value: isVented, format: 'boolean' },
      { key: 'units', label: 'Number of Units', value: units, format: 'number' },
      { key: 'unitMixes', label: 'Unit Mixes', value: unitMixes, format: 'unitMixes' }
    ]
  ];

  const suiteInfo = [
    [
      { key: 'dockDoors', label: 'Dock Doors', value: dockDoors, format: 'number' },
      { key: 'driveIns', label: 'Drive-Ins', value: driveIns, format: 'number' },
      { key: 'amperage', label: 'Amperage (Low)', value: amperage, format: 'number' },
      { key: 'amperageHigh', label: 'Amperage (High)', value: amperageHigh, format: 'number' },
      { key: 'electricalPhase', label: 'Electrical Phase', value: electricalPhase, format: 'number' }
    ],
    [
      { key: 'clearHeightMin', label: 'Min Clear Height', value: clearHeightMin, format: 'number' },
      { key: 'clearHeightMax', label: 'Max Clear Height', value: clearHeightMax, format: 'number' },
      { key: 'voltage', label: 'Volts (Low)', value: voltage, format: 'number' },
      { key: 'voltageHigh', label: 'Volts (High)', value: voltageHigh, format: 'number' },
      { key: 'sprinklers', label: 'Sprinklers', value: sprinklers, format: 'string' }
    ],
    [
      { key: 'columnSpacingDepth', label: 'Column Spacing (depth)', value: columnSpacingDepth, format: 'number' },
      { key: 'columnSpacingWidth', label: 'Column Spacing (width)', value: columnSpacingWidth, format: 'number' },
      { key: 'columnSpacingWidthMin', label: 'Min Column Spacing', value: columnSpacingWidthMin, format: 'number' },
      { key: 'columnSpacingWidthMax', label: 'Max Column Spacing', value: columnSpacingWidthMax, format: 'number' },
    ]
  ];

  return (
    <React.Fragment>
      <Section
        title={'Suite Info'}
        sectionArray={suiteInfo}
        fieldNotes={fieldNotes}
        item={item}
        specialRowArray={suiteInfoSpecialRow}
        specialTopRowArray={suiteInfoSpecialTopRow} />
    </React.Fragment>
  );
};

SuiteInfo.propTypes = {
  classes: PropTypes.object,
  fieldNotes: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired
};

export default withStyles(styles)(SuiteInfo);

import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';

const DEFAULT_VERSION_CHECK_DELAY = 300000; // 5 minutes

export default class VersionCheck extends React.Component {
  static propTypes = {
    checkInterval: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      intervalDelay: (props.checkInterval) ? parseInt(props.checkInterval) : DEFAULT_VERSION_CHECK_DELAY,
      isFetching: false,
      localVersion: null,
      remoteVersion: null
    };
  }

  componentDidMount() {
    const intervalId = setInterval(this.checkVersion, this.state.intervalDelay);
    this.setState({ intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this.setState({ isFetching: false });
  }

  checkVersion = () => {
    if (this.state.isFetching || this.updateRequired()) return Promise.resolve();
    const { localVersion } = this.state;

    this.setState({ isFetching: true });
    return fetch(`${window.location.origin}/version`, {
      cache: 'no-cache',
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
      .then((response) => ((response.ok) ? response.json() : Promise.reject(response)))
      .then(({ version }) => {
        const nextState = { isFetching: false };
        if (!localVersion) Object.assign(nextState, { localVersion: version, remoteVersion: version });
        if (localVersion && localVersion !== version) Object.assign(nextState, { remoteVersion: version });
        this.setState(nextState);
      })
      .catch((error) => {
        console.error('Error checking version: ', error.message);
        this.setState({ isFetching: false });
      });
  };

  updateRequired = () => {
    if (!this.state.localVersion) return false;
    return this.state.localVersion !== this.state.remoteVersion;
  };

  handleUpdateRequest = () => {
    location.replace(`${window.location.origin}/?v=${this.state.remoteVersion}`);
  };

  render() {
    return (
      <Snackbar
        open={this.updateRequired()}
        action={(
          <div onClick={this.handleUpdateRequest}>Update</div>
        )}
        message="A newer version of the Broker application is available, update now?" />
    );
  }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';
import DatatypeIcons from '../common/DatatypeIcons';
import styles from './module.css.js';

const MyAlertsRow = ({
  classes,
  alert: {
    title,
    timestamp,
    value,
    description,
  },
  fetchMyAlert
}) => {
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationOpen(false);
    setNotificationMessage('');
  };

  const loadAlert = () => {
    if (value.savedSearch) {
      fetchMyAlert(value.id, value.savedSearch.id);
    }
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.tableRow}>
        <TableCell className={classes.alertTableCell}>
          <p
            className={classes.alertColumnName}
            onClick={loadAlert}>{title}</p>
        </TableCell>
        <TableCell
          className={classes.alertTableCellDescription}>{description}</TableCell>
        <TableCell
          className={classes.alertTableCell}>{value.items.length}</TableCell>
        <TableCell className={classes.alertTableCell}>
          <div className={classes.alertColumnModules}>
            <DatatypeIcons types={value.datatype} />
          </div>
        </TableCell>
        <TableCell className={classes.alertTableCell}>{moment(timestamp).format('M/D/YYYY h:mm A')}</TableCell>
      </TableRow>

      <Snackbar
        open={notificationOpen}
        autoHideDuration={5000}
        onClose={handleNotificationClose}>
        <SnackbarContent
          className={classes.snackbackContent}
          message={notificationMessage} />
      </Snackbar>
    </React.Fragment>
  );
};

MyAlertsRow.propTypes = {
  alert: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  fetchMyAlert: PropTypes.func.isRequired
};

export default withStyles(styles)(MyAlertsRow);

import { DynamicRangeFilter } from 'searchkit-react16';
import isNil from 'lodash/isNil';

export default class CustomDynamicRange extends DynamicRangeFilter {
  getMinMax = function () {
    // in case of fractional min / max, wrap min and max to nearest numeral outside agg range
    // (may need a different solution if need more fractionally-precise range control in the future)
    const filterAggregation = {
      min: Math.floor(this.accessor.getStat('min')),
      max: Math.ceil(this.accessor.getStat('max'))
    };
    const filterValue = this.accessor.state.value;

    let min = 0;
    let max = Number.MAX_SAFE_INTEGER;
    if (filterAggregation && !isNil(filterAggregation.min) && !isNil(filterAggregation.max)) {
      min = filterAggregation.min;
      max = filterAggregation.max;
      if (filterValue && !isNil(filterValue.min)) min = Math.min(filterAggregation.min, filterValue.min);
      if (filterValue && !isNil(filterValue.max)) max = Math.max(filterAggregation.max, filterValue.max);
    }
    return { min, max };
  };
}

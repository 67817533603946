import {
  BoolMust,
  QueryAccessor,
} from 'searchkit-react16';

class IdsAccessor extends QueryAccessor {
  buildSharedQuery(query) {
    const ids = this.state.getValue();
    if (!this.state || !this.state.getValue() || !this.state.getValue().length) return query;
    const filter = new BoolMust({ ids: { values: this.state.getValue() } });

    const selectedFilter = {
      name: this.translate(this.options.title),
      ids,
      id: this.options.id,
      remove: () => { this.state = this.state.clear(); }
    };
    return query
      .addQuery(filter)
      .addSelectedFilter(selectedFilter);
  }
}

export default IdsAccessor;

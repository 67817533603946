import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { selectPopup } from '../map/actions';
import ParcelDetail from './ParcelDetail';
import * as actions from './actions';

const ParcelDetailContainer = memo((props) => {
  return (
    <ParcelDetail {...props} />
  );
}, isEqual);

ParcelDetailContainer.displayName = 'ParcelDetailContainer';

ParcelDetailContainer.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object,
  router: PropTypes.object.isRequired,
};

function mapStateToProps({ research }) {
  const {
    isFetching: isFetchingParcelDetails,
    isFetchingAssessments,
    isFetchingProperties,
    isFetchingTransactions,
    parcel,
    properties,
  } = research.parcelDetail;
  const { assessments } = research.parcelDetail.assessments || {};
  const { transactions } = research.parcelDetail || {};
  const { property: mapProperty } = research.propertyDetail;

  const filteredTransactions = (Array.isArray(assessments) ? assessments : []).reduce((acc, a) => {
    const assessmentTransactions = get(transactions, [a.id], []);
    return [
      ...acc,
      ...assessmentTransactions
    ];
  }, []);
  return {
    assessments,
    isFetchingAssessments,
    isFetchingParcelDetails,
    isFetchingProperties,
    isFetchingTransactions,
    mapProperty,
    parcel,
    properties,
    transactions: filteredTransactions
  };
}

export default connect(
  mapStateToProps,
  { ...actions, selectPopup }
)(ParcelDetailContainer);

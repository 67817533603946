import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { history } from '../store';
import styles from './module.css';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

const useHistoryStack = (location) => {
  const [locationStack, setLocationStack] = useState([]);

  useEffect(() => {
    setLocationStack([location]);

    const unlisten = history.listen((location) => {
      setLocationStack((prevStack) => [...prevStack, location]);
    });

    return () => {
      unlisten();
    };
  }, []);

  return locationStack;
};

const AccountSubheader = ({ classes, location }) => {
  const [tabValue, setTabValue] = useState(0);
  const locationStack = useHistoryStack(location);

  const handleGoBack = debounce(() => {
    history.go(-(locationStack.length));
  }, 100);

  const clickHandler = (value) => {
    history.push(`/account/${value}`);
    if (value === 'savedsearches') {
      setTabValue(0);
    } else if (value === 'mylists') {
      setTabValue(1);
    } else if (value === 'alerts') {
      setTabValue(2);
    }
  };

  useEffect(() => {
    if (location.pathname.endsWith('savedsearches')) {
      setTabValue(0);
    } else if (location.pathname.endsWith('mylists')) {
      setTabValue(1);
    } else if (location.pathname.endsWith('alerts')) {
      setTabValue(2);
    }
  }, []);

  return (
    <div className={classes.accountSubheader}>
      {locationStack.length >= 1 && (
        <Button
          style={{ color: '#2B3F8A', marginBottom: 12, marginTop: -16 }}
          startIcon={<ArrowBackIos />}
          onClick={handleGoBack}>
          BACK
        </Button>)}
      <Tabs
        className={classes.accountSubheaderWrapper} 
        value={tabValue} 
        TabIndicatorProps={{ hidden: true }}>
        <Tab 
          label={<Typography className={classes.accountSubheaderTitle}>My Searches</Typography>}
          onClick={() => clickHandler('savedsearches')}
          color={tabValue === 0 ? 'white' : '#9d9d9d'}
          style={
            tabValue === 0
              ? { backgroundColor: '#2F438C', color:'white' } : { backgroundColor: 'white', color: '#9d9d9d' }} />
        <Tab 
          label={<Typography className={classes.accountSubheaderTitle}>My Lists</Typography>} 
          onClick={() => clickHandler('mylists')}
          color={tabValue === 1 ? 'white' : '#9d9d9d'}
          style={
            tabValue === 1 
              ? { backgroundColor: '#2F438C', color:'white' } : { backgroundColor: 'white', color: '#9d9d9d' }} />
        <Tab 
          label={<Typography className={classes.accountSubheaderTitle}>Alert Results</Typography>} 
          onClick={() => clickHandler('alerts')}
          color={tabValue === 2 ? 'white' : '#9d9d9d'}
          style={
            tabValue === 2 
              ? { backgroundColor: '#2F438C', color:'white' } : { backgroundColor: 'white', color: '#9d9d9d' }} />
      </Tabs>
      <Divider className={classes.accountSubheaderDivider} />
    </div>
  );
};

AccountSubheader.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withStyles(styles)(AccountSubheader);

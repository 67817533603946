import React from 'react';
import PropTypes from 'prop-types';
import Can from '../../../auth/ability';
import { Button, Tooltip } from '@material-ui/core';

export const AuthFeatureButton = ({
  children,
  title,
  ...other
}) => (
  <Can do="read" on="demographics">
    <Tooltip arrow title={title}>
      <Button {...other}>
        {children}
      </Button>
    </Tooltip>
  </Can>
);
  
AuthFeatureButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title:PropTypes.string.isRequired
};
  
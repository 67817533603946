import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

const BuildingClassChart = (props) => {
  const {
    chartImg,
    classes,
    colors,
    items,
    legend,
    animationDuration,
    onAnimationComplete,
  } = props;

  // analyze the items (selectedResults from the map view)
  const buildingClasses = { A: 0, B: 0, C: 0, Trophy: 0 };
  if (items && items.length > 0) {
    for (const item of items) {
      if (item._source) {
        if (item._source.property && item._source.property.buildingClass) {
          buildingClasses[item._source.property.buildingClass]++;
        }
      }
    }
  }

  // init chartRef
  let chartRef = null;

  // return the element
  return (
    <div>
      <Doughnut
        ref={(chart) => { chartRef = chart; }}
        className={classes.printNot}
        data={{
          datasets: [{
            data: [buildingClasses.A, buildingClasses.B, buildingClasses.C, buildingClasses.Trophy],
            backgroundColor: colors
          }],
          labels: ['A', 'B', 'C', 'Trophy']
        }}
        options={{
          cutoutPercentage: 60,
          rotation: Math.PI,        // starting from W
          animation: {
            duration: animationDuration || 1000,
            onComplete: () => {
              if (onAnimationComplete) {
                onAnimationComplete(chartRef.chartInstance);
              }
            }
          },
          legend: legend ? legend : {}
        }} />
      {chartImg && <img src={chartImg} className={classes.canvasImg} data-canvas />}
    </div>
  );
};

BuildingClassChart.propTypes = {
  animationDuration: PropTypes.number,
  chartImg: PropTypes.string,
  classes: PropTypes.object,
  colors: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  legend: PropTypes.object,
  onAnimationComplete: PropTypes.func
};
  
export default BuildingClassChart;
import grey from '@material-ui/core/colors/grey';
import { COLLIERS_RED } from '../../styles/theme';

export default (theme) => ({
  ['.gm-iv-address']: {
    display: 'none !important'
  },

  avatar: {
    color: 'white',
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  card: {
    margin: 5,
  },
  cardContentListItem: {
    padding: '0px 45px'
  },
  cardActions: {
    height: theme.spacing(6),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
  cardHeader: {
    width: '100%',
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down(600)]: {
      paddingRight: theme.spacing(2),
    }
  },
  cardSubtitle: {
    lineHeight: 1.5
  },
  cardTitle: {
    width: '100% !important',
    fontSize: '140%',
    fontWeight: 700,
    lineHeight: 1.5,
  },
  checkBox: {
    alignItems: 'flex-start',
    minWidth: 36,
    marginLeft: '.5rem'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    minHeight: 0,
  },

  contentContainerNested: {
    height: 'calc(100vh - 225px)'
  },

  documents: {
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'flex-start',
    marginTop: -theme.spacing(6),
    marginLeft: theme.spacing(26),
    [theme.breakpoints.down(1500)]: {
      marginLeft: theme.spacing(20),
    }
  },
  documentsTable: {
    marginTop: theme.spacing(2),
    width: '50%',
    [theme.breakpoints.down(1500)]: {
      width: '100%'
    }
  },
  documentsTableRow: {
    height: 36
  },

  featuresWrapper: {
    display: 'grid',
    gridTemplateColumns: '.1666666fr 0.6666664fr .1666666fr',
  },

  features: {
    gridColumnStart: '2',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    columnGap: theme.spacing(0.25),
    rowGap: theme.spacing(0.25),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },

  imageGallery: {
    minWidth: '45vw',
  },

  images: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'fit-content',
    marginTop: -theme.spacing(2),
    marginLeft: theme.spacing(12),
  },

  header: {
    ['& > header']: {
      color: 'white',
      minHeight: 65,
      paddingLeft: 10,
      position: 'relative',
    }
  },

  headerGallery: {
    display: 'flex',
    height: 'calc((100vw - 370px)/3.2)',
    width: '100%',

    ['& > img']: {
      flex: '1 1 auto',
      height: '100% !important',
      width: '50% !important',
    },
  },

  streetview: {
    flex: '1 0 auto',
    height: '100%',
    width: '50%',
  },

  headerTitle: {
    paddingLeft: 10,
    paddingRight: 35,
    width: '100%',
  },
  headerBanner: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 2,
    margin: 0,
    color: '#505050',
    overflow: 'hidden',
    padding: '0 108px 0 0',
    textOverflow: 'ellipsis',
  },
  headerSubbanner: {
    flex: 1,
    fontSize: 16,
    fontWeight: 600,
    color: '#505050',
    lineHeight: 1.25,
    margin: 0,
    marginTop: -theme.spacing(0.75),
    overflow: 'hidden',
    padding: theme.spacing(0, 0, 1.25, 0),
    textOverflow: 'ellipsis',
  },

  infoContainer: {
    height: '100vh',
    lineHeight: 2,
    overflowX: 'scroll',

    ['& section']: {
      boxShadow: 'none !important',
      margin: '0 15px',
      padding: 15,

      ['&:last-child']: {
        border: 0,
        marginBottom: 50,
      },
      ['& thead']: {
        border: '0 !important',
      },
      ['& th']: {
        color: 'black !important',
        fontWeight: 'bold !important',
        height: 'auto !important',
        textTransform: 'uppercase',
      },

      ['& h3']: { // TODO: ...
        color: grey[400],
        margin: 0,
        textTransform: 'uppercase',
      }
    }
  },

  infoContainerNested: {
    height: 'calc(100vh - 225px)'
  },

  listContainer: {
    overflow: 'scroll',
    height: '100vh'
  },

  marketInfo: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    textAlign: 'center',
  },
  marketStat: {
    fontSize: '12px',
    paddingBottom: 10,
    ['& h4']: { // TODO: 
      textTransform: 'uppercase',
    }
  },

  metaNav: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    right: 0,
    top: 0,
    marginTop: 10,

    button: {
      flex: '1 1 auto',
      height: 'auto !important',
      paddingBottom: '0 !important',
      path: {
        fill: `${grey[500]} !important`,
      },
      ['&:hover']: {
        path: {
          fill: 'white !important',
        }
      }
    },
  },

  neighborhoodContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  note: {
    display: 'grid',
    gridTemplateColumns: '.1666666fr 0.6666664fr .1666666fr',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'justify',
    marginTop: -theme.spacing(3),
    marginBottom: theme.spacing(2),
    ['& > div']: {
      gridColumnStart: '2',
    },
    ['& > strong, b']: {
      display: 'none',
    }
  },

  noteLabel: { 
    color: '#707070',
    font: 'Open Sans',
    fontSize: '20px',
    fontWeight: 400,
    placeSelf: 'start',
    marginRight: theme.spacing(1.25),
  },

  noteValue: {
    ['& > strong']: {
      display: 'none',
    }
  },

  propertyCurateEditIcon: {
    marginLeft: theme.spacing(2),
    color: '#919191',
    ['&:hover']: {
      color: '#787878'
    }
  },

  rightButton: {
    color: '#919191',
    margin: theme.spacing(0, 1, 0, 0),
    ['&:hover']: {
      color: '#787878'
    }
  },

  summaryFieldStatsWrapper: {
    display: 'flex',
    justifyContent: 'space-between', 
    padding: '0px 16px',
    [theme.breakpoints.down(1500)]: {
      flexWrap: 'wrap',
      padding: '0px 16px',
      justifyContent: 'space-evenly',
    },
  },
  summmaryPropertyStatsWrapper: {
    display: 'flex',
    justifyContent: 'space-between', 
    padding: '10px 16px',
    [theme.breakpoints.down(1500)]: {
      flexWrap: 'wrap',
      padding: '0px 16px',
      justifyContent: 'space-evenly',
    },
  },

  summaryFieldStatsStat: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down(1500)]: {
      padding: '10px 16px',
    },
  },

  summaryFieldStatsLabel: {
    color: '#505050',
    fontWeight: 700,
    fontSize: theme.spacing(1.5),
    alignSelf: 'center',
    [theme.breakpoints.down(1500)]: {
      fontSize: '12px'
    }
  },

  summaryFieldStatsData: {
    fontWeight: 300,
    fontSize: theme.spacing(2.75),
    color: '#254091',
    alignSelf: 'center',
    [theme.breakpoints.down(1500)]: {
      fontSize: '16px'
    }
  },

  sectionHeader: {
    color: '#253F8E',
    fontSize: '22px',
    FontFace: 'Open Sans Semibold',
    lineHeight: 2,
    margin: 0,
    width: 'fit-content'
  },

  sectionHeaderWrapper: {
    display: 'flex'
  },
  sectionInner: {
    padding: `${theme.spacing(2)}px 0`,
  },
  sectionPaper: {
    padding: theme.spacing(1, 1.5, 3, 1.5)
  },
  spanTwoColumns: {
    width: '300%',
    color: '#000000',
    font: 'Open Sans',
    fontSize: theme.spacing(2),
    fontWeight: 400,
    [theme.breakpoints.down(1500)]: {
      width: '100%'
    }
  },
  spanThreeColumns: {
    width: '450%',
    color: '#000000',
    font: 'Open Sans',
    fontSize: theme.spacing(2),
    fontWeight: 400,
    [theme.breakpoints.down(1500)]: {
      width: '100%'
    }
  },

  stats: {
    display: 'flex',
  },

  stat: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    font: 'Open Sans',
    fontSize: '20px',
    fontWeight: 400,
    marginTop: theme.spacing(1.875),
    width: '100%',
    ['&$wide']: {
      width: '50%',
    },
    ['& strong']: {
      flex: '0 1 auto',
      textTransform: 'uppercase',
      ['&:first-child']: {
        color: '#707070'
      },
    },
    ['& span']: {
      flex: '1 1 auto',
    }
  },
  wide: {},

  statBig: {
    alignItems: 'center',
    display: 'flex',
    flex: '0 1 auto',
    marginTop: 15,
    width: '25%',

    ['& > svg']: {
      flex: '0 1 auto',
      marginRight: 10,
      height: '24px !important',
      width: '24px !important',
    },

    ['& > span']: {
      color: COLLIERS_RED,
      flex: '0 1 auto',
      fontSize: '18px',
    },
  },

  statLabel: {
    color: '#707070',
    font: 'Open Sans',
    fontSize: theme.spacing(2),
    fontWeight: 400,
    justifySelf: 'end',
    alignSelf: 'start',
    marginRight: theme.spacing(1.25),
  },

  boldStatLabel: {
    color: '#707070',
    font: 'Open Sans',
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    justifySelf: 'end',
    alignSelf: 'start',
    marginRight: theme.spacing(1.25),
  },

  statValue: {
    color: '#000000',
    font: 'Open Sans',
    fontSize: theme.spacing(2),
    fontWeight: 400,
    maxWidth: '375px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(1500)]: {
      fontSize: '16px'
    }
  },

  tripleColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down(1500)]: {
      gridTemplateColumns: '1fr',
      width: '100%',
    },
  },

  recordInfoCreatedWrapper: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(15)
  },
  recordInfoUpdatedWrapper: {
    marginLeft: theme.spacing(15)
  },

  swipeable: {
    backgroundColor: grey[100],
    flex: '1 1 auto',
  },

  taxSection:{
    margin: '32px',
  },

  walkscore: {
    flex: '0 1 auto',
  },

  ['@media (max-width: 600px)']: {
    cardHeader: {
      paddingRight: '0 !important',
    },

    doubleColumn: {
      gridTemplateColumns: '1fr',
      justifyContent: 'center',
      margin: theme.spacing(5)
    },

    headerGallery: {
      height: 'calc(100vw / 3.2)',
    },
    headerNav: {
      paddingRight: 50,

      ['& button']: {
        marginLeft: '0 !important',
        minWidth: '0 !important',
        ['& > span:first-child > span']: {
          marginLeft: theme.spacing(0.5),
          marginRight: 0
        },
      },
    },

    headerStats: {
      display: 'none',
    },

    neighborhoodContainer: {
      alignItems: 'center',
      flexDirection: 'column',
    },

    note: {
      margin: theme.spacing(2),
      ['& > div']: {
        width: '100%',
      },
      ['& > p']: {
        display: 'none'
      },
    },

    stat: {
      width: '50% !important',
      ['&$wide']: {
        width: '100% !important',
      }
    },
    statBig: {
      width: '50% !important',
      ['&$wide']: {
        width: '100% !important',
      }
    },
    tripleColumn: {
      gridTemplateColumns: '1fr',
      margin: theme.spacing(5)
    },

  }
});

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import AreaIcon from '@material-ui/icons/BorderOuter';
import DateIcon from '@material-ui/icons/Today';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import PriceIcon from '@material-ui/icons/AttachMoney';
import StatusIcon from '@material-ui/icons/LocalOffer';
import FieldNote from '../../../common/stats/FieldNote';
import SummaryStats from '../../../common/stats/SummaryStats';
import Stats from '../../../common/stats/Stats';
import FullContact from './Lease/FullContact';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const CardContent = ({ item, stats }) => {
  const summaryIcons = {
    targetArea: AreaIcon,
    status: StatusIcon,
    inMarketAt: PriceIcon,
    occupancyDate: DateIcon,
  };
  const orderedStats = ['status', 'targetArea', 'inMarketAt', 'occupancyDate'];
  const filteredStats = stats.filter(({ key }) => orderedStats.indexOf(key) > -1);

  return (
    <div>
      <SummaryStats schema={filteredStats} icons={summaryIcons} item={item} />
    </div>
  );
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

export const ExpandedCardContent = ({ stats, item }) => {
  const classes = useStyles();
  const orderedStats = [
    // TODO: ...
    'tags',
    'note'
  ];
  const fieldNotes = item.fieldNotes || {};
  const filteredStats = stats.filter(({ key }) => {
    return (item[key] || fieldNotes[key]) && orderedStats.indexOf(key) !== -1;
  });
  filteredStats.sort((a, b) => {
    return orderedStats.indexOf(a.key) < orderedStats.indexOf(b.key) ? -1 : 1;
  });

  const documents = item.documents || [];
  const tenantRepresentatives = item.tenantRepresentatives || [];

  return (
    <div className={classes.expandedView}>
      <section className={classes.generalInfo}>
        <h2>General Information</h2>
        <Stats
          schema={filteredStats}
          item={item}
          statClassName={classes.leaseStat} />
      </section>

      <section className={classes.detailInfo}>
        {(tenantRepresentatives.length > 0 || fieldNotes.tenantRepresentatives) && (
          <Paper className={classes.contactsWrapper}>
            <FullContact
              fieldNote={fieldNotes.tenantRepresentatives}
              headerPrefix={'Tenant Rep'}
              signatories={item.tenantRepresentatives}
              expandFirst={true} />
          </Paper>
        )}

        {documents.length > 0 && (
          <Paper className={classes.genericWrapper}>
            <header>
              Documents
              {fieldNotes.documents && <FieldNote content={fieldNotes.documents} />}
            </header>
            {documents.map((doc, index) => (
              <div key={index} className={classes.documentRow}>
                <FileIcon />
                <div className={classes.documentTitle}>
                  {doc.title || doc.fileName}
                </div>
                <Link href={doc.url} target={'_blank'}>Download</Link>
              </div>
            ))}
          </Paper>
        )}
      </section>
    </div>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

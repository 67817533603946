import { combineReducers } from 'redux';
import { compact, without } from 'lodash';
import {
  RESEARCH_GRID_COLUMNS_REORDER,
  RESEARCH_GRID_COLUMN_TOGGLE,
  RESEARCH_GRID_GRID_VIEW_SELECT,
  RESEARCH_GRID_HIDE_ALL_COLUMNS_TOGGLE,
  RESEARCH_GRID_SHOW_ALL_COLUMNS_TOGGLE,
} from '../../constants/actionTypes';
import datatypes from '../../common/datatypes';
import columnsOrganizer from './columnsOrganizer/reducer';

const initialState = Object.keys(datatypes).reduce((acc, datatype) => {
  acc[datatype] = datatypes[datatype].columns.map((column) => ({ ...column, isEnabled: true }));
  return acc;
}, {});

function updateColumn(columns, updatedColumn) {
  const cols = columns.map((column) => {
    if (column.key === updatedColumn.key) {
      return {
        ...column,
        ...updatedColumn
      };
    }
    return column;
  });
  return cols;
}

function columns(state = initialState, action) {
  switch (action.type) {
    case RESEARCH_GRID_COLUMNS_REORDER:
      return {
        ...state,
        [action.datatype]: action.columns
      };

    case RESEARCH_GRID_COLUMN_TOGGLE:
      return {
        ...state,
        [action.datatype]: updateColumn(state[action.datatype], {
          key: action.column,
          isEnabled: action.isEnabled
        })
      };

    case RESEARCH_GRID_GRID_VIEW_SELECT:
      const reorderedColumns = compact(action.columns.map(({ key, isEnabled = true }) => {
        const found = state[action.datatype].find((c) => c.key === key);
        return (found) ? { ...found, isEnabled } : null;
      }));

      const missing = without(state[action.datatype].map((c) => c.key), ...reorderedColumns.map((c) => c.key));
      if (missing.length) {
        missing.forEach((missingItem) => {
          const missingColumn = state[action.datatype].find((c) => missingItem === c.key);
          reorderedColumns.push({ ...missingColumn, isEnabled: false });
        });
      }

      return {
        ...state,
        [action.datatype]: reorderedColumns
      };

    case RESEARCH_GRID_HIDE_ALL_COLUMNS_TOGGLE:
      const updatedColumnsToggledOff = state[action.datatype].map((column) => {
        return {
          ...column,
          isEnabled: false
        };
      });
      return {
        ...state,
        [action.datatype]: updatedColumnsToggledOff
      };
    
    case RESEARCH_GRID_SHOW_ALL_COLUMNS_TOGGLE:
      const updatedColumnsToggledOn = state[action.datatype].map((column) => {
        return {
          ...column,
          isEnabled: true
        };
      });
      return {
        ...state,
        [action.datatype]: updatedColumnsToggledOn
      };

    default:
      return state;
  }
}

export default combineReducers({
  columns,
  columnsOrganizer,
});

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import transform from 'lodash/transform';
import Snackbar from '@material-ui/core/Snackbar';
import { history } from '../../store';
import NotificationMessage from '../../common/NotificationMessage';
import api from '../../services/api';
import CreateShareDialog from './CreateShareDialog';
import SendShareDialog from './SendShareDialog';

class SharedSearch extends React.Component {
  static propTypes = {
    bounds: PropTypes.object,
    isSharing: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    searches: PropTypes.object,
    selectedResults: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      displayShareDialog: false,
      notificationMessage: '',
      notificationOpen: false,
      shareConfirmationSaving: false,
      shareTitle: '',
      shareableLink: null
    };
  }

  close = () => {
    this.setState({
      notificationMessage: '',
      notificationOpen: false,
      shareTitle: '',
      shareableLink: null
    });
    this.props.onClose();
  };

  onShareTitleChange = (event, shareTitle) => this.setState({ shareTitle });

  handleShareCancel = () => {
    this.close();
  };

  handleShareConfirm = () => {
    this.setState({ shareConfirmationSaving: true });

    const { searches, selectedResults } = this.props;
    api.create('/il', {
      savedSearch: {
        enabledDataTypes: transform(selectedResults, (acc, items, key) => {
          if ((items || []).length > 0) acc.push(key);
        }, []),
        urlHash: history.getCurrentLocation().hash,
        filters: transform(searches, (acc, val, key) => { acc[key] = val.filters || {}; }),
        query: transform(searches, (acc, val, key) => { acc[key] = val.query || {}; }),
        selectedItems: transform(selectedResults, (acc, val, key) => (acc[key] = val.map((item) => item._id)), {})
      },
      searchBounds: this.props.bounds.searchBounds,
      title: this.state.shareTitle
    })
      .then((shareableLink) => {
        this.setState({
          notificationMessage: <NotificationMessage text={'Shared Link Created'} type={'success'} />,
          notificationOpen: true,
          shareConfirmationSaving: false,
          shareableLink
        });
      })
      .catch((error) => {
        this.setState({
          notificationMessage: <NotificationMessage
            text={`Error creating shared link [${error.message}]`} type={'error'} />,
          notificationOpen: true,
          shareConfirmationSaving: false,
        });
        console.error('handleShare error: ', error);
      });
  };

  onShareComplete = () => {
    this.setState({
      notificationOpen: true,
      notificationMessage: <NotificationMessage text={'Link Shared'} type={'success'} />,
    });
    this.close();
  };

  onShareCancel = () => {
    this.setState({
      notificationOpen: false,
      notificationMessage: '',
    });
    this.close();
  };

  handleNotificationClose = () => this.setState({ notificationOpen: false, notificationMessage: '' });

  render() {
    const { isSharing, selectedResults } = this.props;
    const { shareableLink, shareConfirmationSaving } = this.state;

    if (!isSharing) return null;

    return (
      <div>
        <CreateShareDialog
          isOpen={!shareableLink}
          isSaving={shareConfirmationSaving}
          onCancel={this.handleShareCancel}
          onConfirm={this.handleShareConfirm}
          onTitleChange={this.onShareTitleChange}
          selectedResults={selectedResults} />

        <SendShareDialog
          isOpen={!!shareableLink}
          onComplete={this.onShareComplete}
          onCancel={this.onShareCancel}
          shareableLink={shareableLink} />

        <Snackbar
          autoHideDuration={10000}
          message={this.state.notificationMessage}
          onClose={this.handleNotificationClose}
          open={this.state.notificationOpen} />
      </div>
    );
  }
}

function mapStateToProps({ research: { map: { bounds }, searches, selectedResults, isSharing } }) {
  return {
    bounds,
    isSharing,
    searches,
    selectedResults
  };
}

export default connect(mapStateToProps)(SharedSearch);

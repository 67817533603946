import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import transform from 'lodash/transform';
import isEmpty from 'lodash/isEmpty';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import moment from 'moment';
import SaveIcon from '@material-ui/icons/Save';
import HeaderButton from '../../table/common/HeaderButton';
import { history } from '../../../store';
import api from '../../../services/api';
import * as actions from '../../../spotlight/actions';
import NotificationMessage from '../../../common/NotificationMessage';
import SavedSearchDialog from './SavedSearchDialog';
import { withStyles } from '@material-ui/core';

const savedSearchDisplayTitle = (savedSearch) => {
  if (isEmpty(savedSearch)) return '';
  return `${savedSearch.title} (${moment(savedSearch.createdAt).format('MM-DD-YY hh:mm')})`;
};

const savedSearchDisplayDescription = (savedSearch) => {
  if (isEmpty(savedSearch)) return '';
  return savedSearch.description;
};

const savedSearchDisplayFrequency = (savedSearch) => {
  if (isEmpty(savedSearch)) return '';
  return savedSearch.frequency;
};

const SaveButton = withStyles((theme) => ({
  root: {
    'padding': '4px 12px',
    'margin': '0',
    'width': 'auto',
    'height': '40px',
    'color': '#2690B9',
    'backgroundColor': theme.palette.common.white,
    'border': '1px solid #9E9E9E',
    'borderRadius': '8px',
    'font': 'roboto',
    'left': '-50%',
    '&:hover': {
      backgroundColor: '#5093E0',
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('sm')]: {
      left: '-10%',
    },
    [theme.breakpoints.down('md')]: {
      left: '-30%',
    },
    [theme.breakpoints.down('lg')]: {
      left: '-5%',
    },
    [theme.breakpoints.down(1490)]: {
      right: 'unset',
      transform: 'unset',
      left: '-2%',
    },
    [theme.breakpoints.up(1490)]: {
      position: 'fixed',
      left: '50%',
      right: 'auto',
      transform: 'translateX(-50%)'
    },
  },
}))(Button);

export class SavedSearchForm extends React.Component {
  static propTypes = {
    bounds: PropTypes.object,
    classes: PropTypes.object.isRequired,
    currentListView: PropTypes.string.isRequired,
    grid: PropTypes.boolean,
    loadRecentSavedSearches: PropTypes.func.isRequired,
    location: PropTypes.object,
    onCreate: PropTypes.func,
    onSave: PropTypes.func,
    savedSearch: PropTypes.object,
    savedSearchCreated: PropTypes.func.isRequired,
    savedSearchFrequency: PropTypes.string,
    savedSearchUpdated: PropTypes.func.isRequired,
    searches: PropTypes.object,
    selectedResults: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSaving: false,
      notificationMessage: '',
      notificationOpen: false,
      saveMode: props.savedSearch && props.savedSearch.id ? 'update' : 'create',
      saveDialogOpen: false,
      savedSearchDescription: null,
      savedSearchFrequency: 'never',
      savedSearchTitle: null,
    };
  }

  toggleSaveDialog = () => this.setState({ saveDialogOpen: true });

  handleSaveCancel = () => {
    this.setState({
      isSaving: false,
      notificationMessage: '',
      notificationOpen: false,
      saveDialogOpen: false,
      savedSearchDescription: null,
      savedSearchTitle: null,
      savedSearchFrequency: null
    });
  };

  handleSave = () => {
    let resource = '/savedSearches';
    const { saveMode } = this.state;
    const {
      onCreate,
      onSave,
      loadRecentSavedSearches,
      savedSearch,
      savedSearchCreated,
      savedSearchUpdated,
      searches,
      selectedResults
    } = this.props;
    const data = {
      enabledDataTypes: transform(searches, (acc, val, key) => { if (val.isEnabled) acc.push(key); }, []),
      urlHash: history.getCurrentLocation().hash,
      filters: transform(searches, (acc, val, key) => { acc[key] = val.filters || {}; }),
      query: transform(searches, (acc, val, key) => { acc[key] = val.query || {}; }),
      selectedItems: transform(selectedResults, (acc, val, key) => (acc[key] = val.map((item) => item._id)), {}),
      title: this.state.savedSearchTitle,
      description: this.state.savedSearchDescription,
      frequency: this.state.savedSearchFrequency
    };

    if (saveMode === 'update') {
      resource += `/${savedSearch.id}`;
      Object.assign(data, { 
        title: savedSearch.title, 
        description: savedSearch.description, 
        frequency: savedSearch.frequency 
      });
    }

    this.setState({ isSaving: true });
    return api[saveMode](resource, data)
      .then((result) => {
        if (saveMode === 'create') {
          if (onCreate) onCreate(result);
          savedSearchCreated(result, searches);
        }
        if (saveMode === 'update') {
          if (onSave) onSave(result);
          savedSearchUpdated(result, searches);
        }
        loadRecentSavedSearches();
        this.setState({
          isSaving: false,
          notificationMessage: <NotificationMessage
            secondaryText={(saveMode === 'update') ? `Search ${saveMode}d` : ''}
            text={(saveMode === 'update') ? savedSearch.title : 'Saved search has been created successfully'}
            type={'success'} />,
          notificationOpen: true,
          saveDialogOpen: false,
          savedSearchDescription: null,
          savedSearchFrequency: this.state.savedSearchFrequency,
          savedSearchTitle: null,
        });
        return result;
      })
      .catch((error) => {
        this.setState({
          isSaving: false,
          notificationMessage: <NotificationMessage text={`Error saving search [${error.message}]`} type={'error'} />,
          notificationOpen: true,
        });
        console.error('handleSave error: ', error);
      });
  };

  handleNotificationClose = () => this.setState({ notificationOpen: false, notificationMessage: '' });

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onFrequencyChange = (value) => {
    this.setState({ savedSearchFrequency: value });
  };

  onSaveOptionChange = (event, saveMode) => this.setState({ saveMode });

  render() {
    const { grid, savedSearch, searches } = this.props;
    const saveDisabled = this.state.isSaving || (this.state.saveMode === 'create' && !this.state.savedSearchTitle);
    const savedSearchTitle = savedSearchDisplayTitle(savedSearch);
    const savedSearchDescription = savedSearchDisplayDescription(savedSearch);
    const savedSearchFrequency = savedSearchDisplayFrequency(savedSearch);

    return (
      <React.Fragment>
        {grid ? (
          <HeaderButton 
            title={'Save Search'} 
            handler={this.toggleSaveDialog} 
            headerIcon={<SaveIcon color="inherit" />} /> 
        ) : (
          <SaveButton
            id="save-search-button"
            name="Save Search"
            onClick={this.toggleSaveDialog}>
            Save Search
          </SaveButton> 
        )}
        
        <SavedSearchDialog
          handleSave={this.handleSave}
          handleSaveCancel={this.handleSaveCancel}
          isOpen={this.state.saveDialogOpen}
          isSaving={this.state.isSaving}
          onChange={this.onChange}
          onFrequencyChange={this.onFrequencyChange}
          onSaveOptionChange={this.onSaveOptionChange}
          saveDisabled={saveDisabled}
          saveMode={this.state.saveMode}
          savedSearch={savedSearch}
          savedSearcDescription={savedSearchDescription}
          savedSearchFrequency={savedSearchFrequency}
          savedSearchTitle={savedSearchTitle}
          searches={searches} />

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={this.handleNotificationClose}
          open={this.state.notificationOpen}>
          <SnackbarContent
            style={{ minWidth: 'fit-content' }}
            message={this.state.notificationMessage} />
        </Snackbar>
      </React.Fragment>
    );
  }
}

function mapStateToProps({ research: {
  map: { bounds }, searches, selectedResults },
spotlight: { selectedSavedSearch }
}) {
  return {
    bounds,
    savedSearch: selectedSavedSearch,
    searches,
    selectedResults
  };
}

export default connect(mapStateToProps, actions)(SavedSearchForm);

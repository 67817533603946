import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import dimensions from 'react-dimensions';
import calculateBounds from './calculateBounds';
import geolocate from './geolocate';
import Research from './Research';
import * as actions from './actions';

export class ResearchContainer extends React.Component {
  static propTypes = {
    bounds: PropTypes.object,
    containerHeight: PropTypes.number,
    containerWidth: PropTypes.number,
    currentLocation: PropTypes.string,
    fetchCurateUser: PropTypes.func.isRequired,
    fetchSavedLists: PropTypes.func,
    location: PropTypes.object.isRequired,
    notifications: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    searchBounds: PropTypes.object,
    updateMapBounds: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      containerHeight,
      containerWidth,
      currentLocation,
      fetchCurateUser,
      updateMapBounds,
    } = this.props;

    if (!currentLocation) this.setCurrentLocation(this.props);
    // 'edit' permissions
    fetchCurateUser();

    if (currentLocation) {
      const bounds = calculateBounds({ containerWidth, containerHeight, currentLocation });
      updateMapBounds(bounds);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { containerWidth, containerHeight, currentLocation } = nextProps;

    if (!currentLocation) {
      this.setCurrentLocation(nextProps);
    }

    if (currentLocation) {
      const bounds = calculateBounds({ containerWidth, containerHeight, currentLocation });
      if (JSON.stringify(this.props.bounds) !== JSON.stringify(bounds)) this.props.updateMapBounds(bounds);
    }
  }

  setCurrentLocation = ({ router, location, currentUser: { defaultLocation, geoIP } }) => {
    // use default location if one is set on the user account
    if (defaultLocation && defaultLocation.location && defaultLocation.location.length === 2) {
      router.push({ ...location, hash: `#11/${defaultLocation.location[1]}/${defaultLocation.location[0]}` });

    // otherwise, generate a default from user's browser (or ip)
    } else {
      geolocate({ geoIP }).then((hash) => router.push({ ...location, hash }));
    }
  };

  render() {
    return <Research {...this.props} />;
  }
}

function mapStateToProps({ research, auth }, { location, containerWidth, containerHeight }) {
  const bounds = calculateBounds({ currentLocation: location.hash, containerWidth, containerHeight });

  return {
    bounds: bounds.mapBounds,
    searchBounds: bounds.searchBounds,
    curateUser: auth.curateUser,
    currentLocation: location.hash,
    currentUser: auth.currentUser,
    notifications: research.notifications,
    polygons: research.map.polygons,
    sourceFilter: research.sourceFilter || 'list',
  };
}

export default withRouter(dimensions()(connect(mapStateToProps, { ...actions })(ResearchContainer)));

import startCase from 'lodash/startCase';
import has from 'lodash/has';
import { MatchQuery, QueryString } from 'searchkit-react16';
import { numeric } from './rangeFormatters';

export default function createFilter(field, type, config = {}) {
  const title = config.title || startCase(field);

  if (type === 'textArray') {
    config.searchOnChange = true;
    config.queryBuilder = (query) => new MatchQuery(field, query);
  }

  if (type === 'text') {
    config.searchOnChange = true;
    config.queryFields = config.queryFields || [field];
    config.queryBuilder = (rawQuery) => {
      if (config.wildcard === false) return new QueryString(rawQuery, { 'default_field': field, 'fuzziness': '0' });

      // by default, searches only at start of text
      let query = `${rawQuery}*`;

      // if middleSearchMinimum is set (and gt 0) and query is at least that long, will search in middle of text also
      if (config.middleSearchMinimum && rawQuery.length >= config.middleSearchMinimum) {
        query = `*${rawQuery}*`;
      }

      return new QueryString(query, {
        'analyze_wildcard': true,
        'minimum_should_match': '2<75%',
        'fields': config.queryFields
      });
    };
  }

  if ((type === 'refinement' || type === 'refinementNews') && !has(config, 'isCollapsed')) {
    config.isCollapsed = false;
  }

  if (type === 'refinementNews') {
    config.isNews = true;
  }

  if ((type === 'staticRange' || type === 'dynamicRange') && !has(config, 'rangeFormatter')) {
    config.rangeFormatter = numeric;
  }

  return {
    field,
    title,
    type,
    id: field,
    operator: 'OR',
    ...config
  };
}

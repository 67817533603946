import { combineReducers } from 'redux';
import {
  CLEAR_SELECTED_MYALERT,
  CLEAR_SELECTED_MYLIST,
  CREATED_MYLIST,
  DELETED_MYLIST,
  SELECT_MYLIST,
  UPDATED_MYALERT,
  UPDATED_MYLIST,
  LOAD_RECENT_MYALERTS_REQUEST,
  LOAD_RECENT_MYALERTS_SUCCESS,
  LOAD_RECENT_MYALERTS_FAILURE,
  LOAD_RECENT_MYLISTS_REQUEST,
  LOAD_RECENT_MYLISTS_SUCCESS,
  LOAD_RECENT_MYLISTS_FAILURE,
  UPDATE_MYALERTS_FAILURE,
} from '../constants/actionTypes';
  
const initialState = {
  selectedMyAlert: {},
  selectedMyList: {},
  recentMyLists: [],
  recentMyAlerts: [],
  myAlertsFailure: {},
  myListFailure: {}
};
  
export function alerts(state = initialState, action) {
  switch (action.type) {
    case UPDATED_MYALERT:
      const { datatype, ...alerts } = action.data;
      return {
        ...state,
        selectedMyAlert: {
          ...state.selectedMyAlert,
          [datatype]: alerts
        }
      };
    case LOAD_RECENT_MYALERTS_REQUEST:
      return {
        ...state,
        recentMyAlerts: []
      };
    case LOAD_RECENT_MYALERTS_SUCCESS: 
      return {
        ...state, 
        recentMyAlerts: action.data
      };
      
    case LOAD_RECENT_MYALERTS_FAILURE:
      return {
        ...state,
        myAlertsFailure: action.error
      };
      
    case CLEAR_SELECTED_MYALERT:
      return {
        ...state,
        selectedMyAlert: {}
      };

    case UPDATE_MYALERTS_FAILURE:
      return {
        ...state,
        myAlertsFailure: action.err
      };
  
    default:
      return state;
  }
}

export function lists(state = initialState, action) {
  switch (action.type) {
    case CREATED_MYLIST:
    case UPDATED_MYLIST:
    case SELECT_MYLIST:
      const { datatype, ...list } = action.myList;
      return {
        ...state,
        selectedMyList: {
          ...state.selectedMyList,
          [datatype]: list
        }
      };
    case LOAD_RECENT_MYLISTS_REQUEST:
      return {
        ...state,
        recentMyLists: []
      };
    case LOAD_RECENT_MYLISTS_SUCCESS: 
      return {
        ...state, 
        recentMyLists: action.data
      };
      
    case LOAD_RECENT_MYLISTS_FAILURE:
      return {
        ...state,
        myListFailure: action.error
      };
      
    case CLEAR_SELECTED_MYLIST:
    case DELETED_MYLIST:
      return {
        ...state,
        selectedMyList: {}
      };
  
    default:
      return state;
  }
}

export default combineReducers({
  alerts,
  lists
});

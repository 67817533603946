import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import styles from './module.css';

const ListMode = ({ count, classes, onDisable, name }) => {
  const handleClick = useCallback(() => onDisable('property'), [onDisable]);

  return (
    <div className={classes.listModeWrapper}>
      <div className={classes.listModeNotice}>
        <Typography className={classes.listModeNoticeText} variant="subtitle2">
          {'- SAVED LIST VIEW ACTIVE-'}
        </Typography>
      </div>
      <div className={classes.listModeRow}>
        <Typography variant="subtitle2">
          {name}
          {(count >= 0) && ` (${count})`}
        </Typography>
        <Tooltip arrow title="EXIT LIST VIEW">
          <IconButton onClick={handleClick} size="small">
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

ListMode.propTypes = {
  classes: PropTypes.object.isRequired,
  count: PropTypes.number,
  name: PropTypes.string.isRequired,
  onDisable: PropTypes.func.isRequired,
};

export default withStyles(styles)(ListMode);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { read } from '../services/api';
import { fetchAlertRecord } from '../research/map/actions';
import { history } from '../store';
import Widget from './common/Widget';
import WidgetTable from './common/WidgetTable';
import styles from './module.css';

const TitleRow = ({ alert, classes, children, fetchAlertRecord, savedSearch }) => {
  const handleClick = () => {
    fetchAlertRecord(alert.id, savedSearch.id);
  };

  return <div className={classes.clickableTitle} onClick={handleClick}>{children}</div>;
};

TitleRow.propTypes = {
  alert: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  fetchAlertRecord: PropTypes.func.isRequired,
  savedSearch: PropTypes.object.isRequired,
};

const ConnectedTitleRow = connect(null, { fetchAlertRecord })(withStyles(styles)(TitleRow));

const cellRenderer = (row, column) => {
  const value = row[column.id];
  const content = column.format && typeof column.format === 'function' ? column.format(value, row) : value;

  switch (column.id) {
    case 'record':
      return <ConnectedTitleRow alert={row.alert} savedSearch={row.savedSearch}>{content}</ConnectedTitleRow>;
    default:
      return <span>{content}</span>;
  }
};

const columns = [
  {
    id: 'record',
    label: 'Record',
    minWidth: 100,
  },
  {
    id: 'searchName',
    label: 'Saved Search',
    minWidth: 100
  },
  {
    format: (d) => moment(d).format('MM/DD/YYYY'),
    id: 'alertDate',
    label: 'Alert Date',
    minWidth: 100
  }
];

const handleManage = () => {
  history.push('/account/alerts');
};

const extractPopulatedItems = (alerts) => {
  return alerts.reduce((acc, alert) => {
    const items = (alert.populatedItems || []).map((item) => {
      return {
        alertDate: alert.createdAt,
        alert,
        id: item.id,
        record: item.canonicalName || item.name || item.title,
        recordId: item.id,
        savedSearch: alert.savedSearch,
        searchName: alert.title,
      };
    });
    return acc.concat(items);
  }, []);
};

const MyAlertsWidget = ({ classes, onRemove }) => {
  const [records, setRecords] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let abortController = new window.AbortController();
    async function fetchAlerts() {
      try {
        setFetching(true);

        const alerts = await read(
          'alerts',
          null,
          { limit: 200, populateItems: 1, sort: '-createdAt' },
          { signal: abortController.signal }
        );
        setRecords(extractPopulatedItems(alerts));
        setFetching(false);
        abortController = null;
      } catch (error) {
        if (!abortController.signal.aborted) {
          setError(error);
          setFetching(false);
        }
      }
    }
    fetchAlerts();
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, []);

  return (
    <Widget
      onManage={handleManage}
      onRemove={onRemove}
      small
      title="MY ALERTS">
      {fetching && (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      )}
      {!fetching && (
        <React.Fragment>
          {error && (
            <div className={classes.errorWrapper}>
              <Typography variant="h6">{'An error occurred while loading your Alerts'}</Typography>
            </div>
          )}
          {!error && (
            <React.Fragment>
              {records.length > 0 && (
                <React.Fragment>
                  <WidgetTable
                    columns={columns}
                    cellRenderer={cellRenderer}
                    defaultSortColumn="alertDate"
                    emptyMessage={'You don’t have any alerts.'}
                    onRemove={onRemove}
                    rows={records} />
                </React.Fragment>
              )}
              {records.length < 1 && (
                <React.Fragment>
                  <WidgetTable
                    columns={columns}
                    cellRenderer={cellRenderer}
                    defaultSortColumn="alertDate"
                    emptyMessage={'You don’t have any alerts.'}
                    onRemove={onRemove}
                    rows={records} />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Widget>
  );
};

MyAlertsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withStyles(styles)(MyAlertsWidget);

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Disclaimer = ({ isVisible, hide }) => {
  return (
    <Dialog
      open={isVisible}
      onClose={hide}>
      <DialogTitle>Disclaimer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The information presented here is deemed to be accurate, but it has not been independently verified.
          Colliers makes no guarantee, warranty or representation.
          It is your responsibility to independently confirm accuracy and completeness.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hide}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

Disclaimer.propTypes = {
  hide: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default Disclaimer;

import geoViewport from 'geo-viewport';
import mediaQuery from '../common/mediaQuery';

const _cache = {};

export default function calculateBounds({ currentLocation, containerWidth, containerHeight }) {
  const key = [currentLocation, containerWidth, containerHeight].join('-');
  if (_cache[key]) {
    return _cache[key];
  }
  const dimensions = [containerWidth, containerHeight];
  const [zoom, lat, lng] = currentLocation
    .replace('#', '')
    .split('/')
    .map(parseFloat);
  const bounds = geoViewport.bounds([lng, lat], zoom, dimensions);
  let searchableWidthRatio = (containerWidth - 380) / containerWidth; // TODO: get list width from CSS
  mediaQuery('(max-width: 600px)', (match) => match && (searchableWidthRatio = 1));
  const rightBound = bounds[0] + ((bounds[2] - bounds[0]) * searchableWidthRatio);
  _cache[key] = {
    mapBounds: {
      'top_right': [bounds[2], bounds[3]],
      'bottom_left': [bounds[0], bounds[1]]
    },
    searchBounds: {
      'top_right': [rightBound, bounds[3]],
      'bottom_left': [bounds[0], bounds[1]]
    }
  };

  return _cache[key];
}

import { BoolMust, BoolShould, TermQuery, QueryAccessor } from 'searchkit-react16';

class RelatedAndCustomIdsAccessor extends QueryAccessor {
  buildSharedQuery(query) {
    const relatedAndCustomIds = this.state.getValue();
    if (!relatedAndCustomIds || relatedAndCustomIds.length === 0) return query;

    const shouldQueries = relatedAndCustomIds.flatMap((id) => [
      new TermQuery('ourDealsId', id),
      new TermQuery('ourListingId', id),
      new TermQuery('coStarId', id),
      new TermQuery('legacyId', id),
      new TermQuery('lightboxId', id),
      new BoolMust({ term: { 'customId.id': id } })
    ]);
    const shouldFilter = new BoolShould(shouldQueries);

    const selectedFilter = {
      name: this.translate(this.options.title),
      relatedAndCustomIds,
      id: this.options.id,
      remove: () => { this.state = this.state.clear(); }
    };
    return query
      .addQuery(shouldFilter)
      .addSelectedFilter(selectedFilter);
  }
}

export default RelatedAndCustomIdsAccessor;

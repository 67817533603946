import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import SwipeableViews from 'react-swipeable-views';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Loader from '../Loader';
import { previousPath } from '../../common/getLocationTitles';
import { getParcelPath } from '../../common/getParcelPath';
import datatypes from '../../common/datatypes';
import MenuTabs from './common/MenuTabs';
import ListItem from './ListItem';
import styles from './module.css';
import PropertySubheader from './propertySubheader/PropertySubheader';

class Content extends React.Component {
  static propTypes = {
    allNearbyItems: PropTypes.object.isRequired,
    classes: PropTypes.object,
    currentListView: PropTypes.string,
    currentUser: PropTypes.object,
    enabledSearches: PropTypes.object.isRequired,
    fetchAllDatatypeWithFilters: PropTypes.func.isRequired,
    fetchAllNearby: PropTypes.func.isRequired,
    getDistanceFromProperty: PropTypes.func,
    isFetchingContent: PropTypes.bool.isRequired,
    isFetchingFilteredQueryItems: PropTypes.bool.isRequired,
    isFetchingNearby: PropTypes.bool.isRequired,
    isNested: PropTypes.bool,
    location: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    property: PropTypes.object,
    router: PropTypes.object.isRequired,
    searches: PropTypes.object.isRequired,
    selectedResults: PropTypes.object,
    selectionAnchor: PropTypes.object,
    setSelectionAnchor: PropTypes.func.isRequired,
    showProblemReporter: PropTypes.func,
    toggleSelectedItem: PropTypes.func,
    visibleDatatypes: PropTypes.array.isRequired,
  };

  handleTabChange = async (_event, value) => {
    const { apn, datatype, fips, propertyId } = this.props.params;
    const hasFilteredQueryItems = this.props[`hasFilteredQueryItems${datatype[0].toUpperCase()}${datatype.slice(1)}`];
    let idValue = '';
    if (hasFilteredQueryItems) {
      if (value === 0) idValue = 'filter';
      if (value === 1) idValue = '';
      if (value === 2) idValue = 'nearby';
    } else {
      if (value === 0) idValue = '';
      if (value === 1) idValue = 'nearby';
    }
    const pathname = await getParcelPath({
      address: this.props.property.address,
      apn,
      fips,
      id: idValue,
      slug: propertyId,
      type: datatype,
      view: previousPath(),
    });
    this.props.router.push({ ...this.props.location, pathname });
  };
  nav = (key, id = null) => () => this.props.navigate(key, id);

  render() {
    const { datatype, itemId } = this.props.params;
    const { 
      allNearbyItems,
      classes,
      currentListView,
      currentUser,
      params,
      property,
      visibleDatatypes
    } = this.props;
    const selectedResults = this.props.selectedResults[datatype] || [];
    const items = property[datatype] || [];
    const { color, nearbyTab, hasNearby } = datatypes[datatype];
    const { enabledSearches } = this.props;
    const currentResults = enabledSearches[datatype] ? enabledSearches[datatype].results : [];
    const hasFilteredQueryItems = this.props[`hasFilteredQueryItems${datatype[0].toUpperCase()}${datatype.slice(1)}`];
    const filteredQueryItems = this.props[`filteredQueryItems${datatype[0].toUpperCase()}${datatype.slice(1)}`];
    const nearbyItemsArray = this.props.allNearbyItems[datatype];

    let tabIndex = 0;
    if (hasFilteredQueryItems) {
      if (itemId === 'nearby') tabIndex = 1;
      if (itemId === 'filter') tabIndex = 2;
    } else {
      tabIndex = itemId === 'nearby' ? 1 : 0;
    }

    return (
      <div className={cx({ [classes.contentContainer]: true, [classes.contentContainerNested]: this.props.isNested })}>
        {this.props.isFetchingContent && <div style={{ marginTop: 40 }}><Loader /></div>}
        {!this.props.isFetchingContent && (
          <div className={classes.listContainer}>
            <PropertySubheader
              allNearbyItems={allNearbyItems}
              fetchAllDatatypeWithFilters={this.props.fetchAllDatatypeWithFilters}
              hasFilteredQueryItems={hasFilteredQueryItems}
              nav={this.nav}
              property={property}
              params={params}
              datatypeInfo={visibleDatatypes} />
            <React.Fragment>
              <MenuTabs
                color={color}
                filteredQueryItems={filteredQueryItems}
                handleTabChange={this.handleTabChange}
                hasFilteredQueryItems={hasFilteredQueryItems}
                hasNearby={hasNearby}
                items={items}
                nearbyItemsTab={nearbyItemsArray && nearbyItemsArray.length ? nearbyItemsArray.length : 0}
                nearbyTab={nearbyTab}
                params={params}
                tabIndex={tabIndex}
                datatypeInfo={visibleDatatypes} />
              <SwipeableViews
                className={classes.swipeable}
                onChangeIndex={this.handleTabChange}
                index={tabIndex}>
                <React.Fragment>
                  {items.map((item) => (
                    <Paper
                      id={`content-item-${item.id}`}
                      key={item.id}
                      elevation={0}
                      style={{ margin: 5 }}>
                      <ListItem
                        {...this.props}
                        active={item.id === itemId}
                        currentListView={currentListView}
                        currentUser={currentUser}
                        item={item}
                        property={property}
                        currentResults={currentResults}
                        selectedResults={selectedResults}
                        selectionAnchor={this.props.selectionAnchor}
                        setSelectionAnchor={this.props.setSelectionAnchor}
                        toggleSelectedItem={this.props.toggleSelectedItem}
                        showProblemReporter={this.props.showProblemReporter} />
                    </Paper>
                  ))}
                </React.Fragment>
                {hasNearby && (
                  <React.Fragment>
                    {!this.props.isFetchingNearby && nearbyItemsArray && nearbyItemsArray.map((item) => (
                      <Paper
                        id={`content-item-${item.id}`}
                        key={item.id}
                        elevation={0}
                        style={{ margin: 5 }}>
                        <ListItem
                          {...this.props}
                          active={item.id === itemId}
                          currentUser={currentUser}
                          item={item}
                          property={property}
                          currentResults={currentResults}
                          selectedResults={selectedResults}
                          selectionAnchor={this.props.selectionAnchor}
                          setSelectionAnchor={this.props.setSelectionAnchor}
                          showProblemReporter={this.props.showProblemReporter}
                          toggleSelectedItem={this.props.toggleSelectedItem}
                          nearbyDistance={this.props.getDistanceFromProperty(item.location, !!item.properties)} />
                      </Paper>
                    ))}
                  </React.Fragment>
                )}
                <React.Fragment>
                  {filteredQueryItems.map((item) => (
                    <Paper
                      id={`content-item-${item.id}`}
                      key={item.id}
                      elevation={0}
                      style={{ margin: 5 }}>
                      <ListItem
                        {...this.props}
                        active={item.id === itemId}
                        currentListView={currentListView}
                        currentUser={currentUser}
                        item={item}
                        property={property}
                        currentResults={currentResults}
                        selectedResults={selectedResults}
                        selectionAnchor={this.props.selectionAnchor}
                        setSelectionAnchor={this.props.setSelectionAnchor}
                        toggleSelectedItem={this.props.toggleSelectedItem}
                        showProblemReporter={this.props.showProblemReporter} />
                    </Paper>
                  ))}
                </React.Fragment>
              </SwipeableViews>
            </React.Fragment>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Content);

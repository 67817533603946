import { RangeFilter, renderComponent } from 'searchkit-react16';
import get from 'lodash/get';
import Accessor from './accessors/DateRangeAccessor.js';

export default class DateRange extends RangeFilter {
  defineAccessor() {
    const { id, title, field, fieldOptions, min, max, interval } = this.props;
    return new Accessor(id, { id, title, field, fieldOptions, min, max, interval });
  }

  renderRangeComponent(component) {
    const { min, max, rangeFormatter, marks } = this.props;
    const state = this.accessor.state.getValue();
    return renderComponent(component, {
      min, max,
      minValue: get(state, 'min', min),
      maxValue: get(state, 'max', max),
      items: this.accessor.getBuckets(),
      onChange: this.sliderUpdate,
      onFinished: this.sliderUpdateAndSearch,
      rangeFormatter, marks
    });
  }
}

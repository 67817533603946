import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DateIcon from '@material-ui/icons/Today';
import StatusIcon from '@material-ui/icons/LocalOffer';
import SummaryStats from '../../common/stats/SummaryStats';
import Stats from '../../common/stats/Stats';
import styles from './module.css';

const getClasses = makeStyles(styles);

export const CardContent = ({ item, stats }) => {
  const summaryIcons = {
    'inMarketAt': DateIcon,
    'occupancyDate': DateIcon,
    'status': StatusIcon
  };
  const orderedStats = ['inMarketAt', 'occupancyDate', 'status'];
  const filteredStats = stats.filter(({ key }) => {
    return orderedStats.indexOf(key) > -1;
  });

  filteredStats.sort((a, b) => {
    return orderedStats.indexOf(a.key) < orderedStats.indexOf(b.key) ? -1 : 1;
  });

  return <SummaryStats schema={filteredStats} icons={summaryIcons} item={item} />;
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

export const ExpandedCardContent = ({ stats, item }) => {
  const classes = getClasses();
  const orderedStats = [
    'transactionType',
    'status',
    'markets',
    'submarkets',
    'micromarkets',
    'sourcedBy',
    'rescourId',
    'tags',
    'currentProperty',
    'currentArea',
    'currentLeaseEndDate',
    'note'
  ];
  const fieldNotes = item.fieldNotes || {};
  const filteredStats = stats.filter(({ key }) => {
    return (item[key] || fieldNotes[key]) && orderedStats.indexOf(key) !== -1;
  });
  filteredStats.sort((a, b) => {
    return orderedStats.indexOf(a.key) < orderedStats.indexOf(b.key) ? -1 : 1;
  });

  return (
    <section className={classes.stats}>
      <Stats schema={filteredStats} item={item} />
    </section>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import numeral from 'numeral';
import { browserHistory as router } from 'react-router';
import { read } from '../../../services/api';
import { getParcelPath } from '../../../common/getParcelPath';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropertyIcon from '@material-ui/icons/LocationOn';
import datatypes from '../../../common/datatypes';
import PropertyTiles from '../../propertyDetail/propertySubheader/PropertyTiles';
import HeaderPanel from '../listItemContent/HeaderPanel';
import RightPanelDetails from '../listItemContent/RightPanelDetails';
import { COLLIERS_BLUE } from '../../../styles/theme';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css';

const widthLeft = 60;
const widthRight = 80;

const formatString = (str) => {
  return str ? str : '\u2013';
};

const formatSize = (size) => {
  return size ? `${numeral(size).format('0,0')} SF` : '\u2013';
};

const Popup = ({ propertyItem, params, classes }) => {
  const datatypeInfo = ['lease', 'availability', 'sale', 'listing'];

  const count = datatypeInfo.reduce((memo, d) => {
    memo[d] = (propertyItem[d] || []).length;
    return memo;
  }, {});

  const getDataType = (params) => {
    if (!datatypeInfo.includes(params.datatype)) {
      const tab = datatypeInfo.find((tab) => count[tab] > 0);
      return tab || 'lease';
    }
    
    return count[params.datatype] > 0 ? params.datatype : 'lease';
  };
  
  const [tab, setTab] = useState(getDataType(params));
  const [data, setData] = useState(propertyItem[tab] || []);

  const contentSections = { 
    property: {},
    lease: {},
    availability: {},
    sale: {},
    listing: {},
    development: {},
  };
  
  const availableDatatypes = Object.keys(contentSections)
    .filter((key) => ((key === 'property') ? true : (propertyItem[key] || []).length > 0))
    .map((key) => datatypes[key].key);
  
  const visibleDatatypes = datatypeInfo.reduce((memo, d) => {
    if (d === 'tim') return memo;

    const { Icon, color, plural } = datatypes[d];
    memo[d] = { Icon, color, label: plural, nearby: true };
    return memo;
  }, {});
  Object.keys(visibleDatatypes).forEach((key) => {
    contentSections[key] = { ...visibleDatatypes[key] };
  });
  
  const [currentDatatype, setCurrentDatatype] = useState(getDataType(params) || window.location.pathname
    .split('/')
    .find((element) => contentSections[element]));
  const [datatypeColor, setDatatypeColor] = useState(contentSections[currentDatatype].color);

  const handleChange = (event, newDatatype) => {
    setCurrentDatatype(newDatatype);
    setDatatypeColor(contentSections[newDatatype].color);
    setTab(newDatatype);
    setData(propertyItem[newDatatype]);
  };

  const shouldRenderPropertyTiles = datatypeInfo.some((tab) => count[tab] > 0);

  const propertyDetailsLeft = [
    {
      label: 'Type', 
      value: get(propertyItem, 'propertyTypes[0]', '\u2013')
    },
    {
      label: 'Subtype',
      value: get(propertyItem, 'propertyUses[0]', '\u2013')
    },
    {
      label: 'Size',
      value: formatSize(propertyItem.area || propertyItem.rentableBuildingArea)
    },
    {
      label: 'Year Built',
      value: get(propertyItem, 'yearBuilt', '\u2013')
    }
  ];
  
  const propertyDetailsRight = [
    {
      label: 'Market',
      value: get(propertyItem, 'market', '\u2013')
    },
    {
      label: 'Submarket',
      value: get(propertyItem, 'submarket', '\u2013')
    },
    {
      label: 'Bldg. Class',
      value: get(propertyItem, 'buildingClass', '\u2013')
    },
    {
      label: 'Renovated',
      value: get(propertyItem, 'yearRenovated', '\u2013')
    }
  ];

  const showDetails = async () => {
    const pathname = await getParcelPath({
      address: get(propertyItem, 'address', ''),
      hash: get(propertyItem, 'hash', ''),
      slug: `${propertyItem.hash}|${propertyItem.rescourId}`,
      type: 'property'
    });

    router.push({
      ...location,
      pathname
    });
  };

  const showMore = async (tab) => {
    const pathname = await getParcelPath({
      address: get(propertyItem, 'address', ''),
      hash: get(propertyItem, 'hash', ''),
      slug: `${propertyItem.hash}|${propertyItem.rescourId}`,
      type: tab
    });
    
    router.push({
      ...location,
      pathname
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={classes.popupContentRow}>
        <div style={{ display: 'flex' }} >
          <PropertyIcon style={{ color: COLLIERS_BLUE, margin: '2px 4px 0 -10px' }} />
          <div>
            <strong 
              className={classes.parcelValue} 
              style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '14px' }} 
              onClick={() => { return showDetails('property'); }}>
              {formatString(get(propertyItem, 'name', '\u2013'))}
            </strong>
            <div className={classes.popupContentRowItem}>
              <strong 
                className={classes.parcelValue} 
                style={{ textDecoration: 'underline', paddingBottom: '8px', cursor: 'pointer', fontSize: '14px' }} 
                onClick={() => { return showDetails('property'); }}>
                {formatString(get(propertyItem, 'address', '\u2013'))}
              </strong>
            </div>
          </div>
        </div>
        <div className={classes.popupContentRowItem}>
          <span className={classes.propertyLabel}>Owner:</span>
          <strong className={classes.ownerValue}>
            {formatString(get(propertyItem, ['owners', 0, 'name'], '\u2013'))}
          </strong>
        </div>
      </div>
      <div className={classes.propertyContainer}>
        <div className={classes.propertyColumnContainer}>
          {propertyDetailsLeft.map((detail) => (
            <div key={detail.label} className={classes.labelContainer}>
              <p className={classes.label} style={{ width: widthLeft }}>{detail.label}:</p>
              <strong className={classes.parcelValue}>
                {detail.value}
              </strong>
            </div>
          ))}
        </div>
        <div className={classes.propertyColumnContainer}>
          {propertyDetailsRight.map((detail) => (
            <div key={detail.label} className={classes.labelContainer}>
              <p className={classes.label} style={{ width: widthRight }} >{detail.label}:</p>
              <strong className={classes.parcelValue}>
                {detail.value}
              </strong>
            </div>
          ))}
        </div>
      </div>
      {shouldRenderPropertyTiles && (
        <div 
          className={classes.popupContentRow}>
          <PropertyTiles 
            classes={classes} 
            contentSections={contentSections} 
            datatypeColor={datatypeColor}
            availableDatatypes={availableDatatypes} 
            currentDatatype={currentDatatype} 
            handleChange={handleChange} 
            count={count} 
            showIcons={false} />
        </div>
      )}
      <div className={classes.popupPanelRow}>
        {data && data.length > 0 && data.slice(0, 3).map((item, index) => (
          <React.Fragment key={index}>
            <HeaderPanel type={tab} source={item} />
            <RightPanelDetails type={tab} _source={item} />
            {data.length > 1 && index < data.length - 1 && (
              <Divider 
                style={{ 
                  width: '106%', marginTop: '8px', marginBottom: '8px', marginLeft: '-8px', overflow: 'hidden' }} />
            )}
          </React.Fragment>
        ))}
        {data && data.length > 3 && (
          <strong 
            onClick={() => { return showMore(tab); }} 
            style={{ cursor: 'pointer', textDecoration: 'underline' }}>Show More</strong>
        )}
      </div>
    </div>
  );
};

const PropertiesPopupCard = ({ classes, params, parcel, setParcelProperties }) => {
  const propertyLength = get(parcel, 'properties.length', 0);
  const propertyLengthDisplay = `PROPERTIES ${propertyLength ? `(${propertyLength})` : ''}`;
  const properties = get(parcel, 'properties', []);
  const [data, setData] = useState([]);
  const [isAccordionExpanded, setAccordionExpanded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (properties.length !== 0) {
          const nestedProperties = await properties.map(async (propertyItem) => {
            const result = await read(`/properties/${propertyItem.hash}|${propertyItem.rescourId}`);
            return result;
          });
          const resolvedProperties = await Promise.all(nestedProperties);
          setData(resolvedProperties);
        }
      } catch (error) {
        setError(error);
      }
    };
    setParcelProperties(properties);
    fetchData();
  }, [properties]);

  if (error) {
    return null;
  }

  return (
    <>
      {data && data.length !== 0 && (
        <Accordion
          expanded={isAccordionExpanded}
          onChange={(e, isExpanded) => { return setAccordionExpanded(isExpanded); }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
            aria-controls="panel1-properties-a-content"
            id="panel1-properties-a-header"
            className={classes.accordionSummary}
            style={{ backgroundColor: '#24408F' }}>
            <Typography 
              style={{ fontWeight: 'bold' }}>
              {propertyLengthDisplay}
            </Typography>
          </AccordionSummary>
          <AccordionDetails 
            style={{ 
              backgroundColor: 'white', 
              flexDirection: 'column', 
              height: 'auto', 
              maxHeight: '400px', 
              overflowY: 'auto', 
              overflowX: 'hidden' }}>
            {data && data.length !== 0 && data.map((propertyItem, propertyItemIndex) => (
              <React.Fragment key={propertyItemIndex}>
                <Popup 
                  key={propertyItemIndex} 
                  propertyItem={propertyItem} 
                  classes={classes} 
                  properties={properties}
                  params={params} />
                {
                  data.length > 1 && propertyItemIndex < data.length - 1 && (
                    <Divider style={{ width: '108%', marginLeft: '-16px', marginBottom: '8px', overflow: 'hidden' }} />
                  )}
              </React.Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

PropertiesPopupCard.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  parcel: PropTypes.object.isRequired,
  setParcelProperties: PropTypes.func.isRequired
};

Popup.propTypes = {
  classes: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  propertyItem: PropTypes.object.isRequired
};

export default withStyles(styles)(PropertiesPopupCard);

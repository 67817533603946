import RangeInput from './RangeInput';

export default class CurrencyRangeInput extends RangeInput {
  constructor(props) {
    super(props);

    this.stepSize = 0.01;
    this.roundToDecimals = 2;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchPopupContent } from '../actions';
import { hideAllFilters } from '../../searches/actions';
import { setParcelProperties } from '../../parcelDetail/actions';
import ParcelPopUp from './ParcelPopUp';

export class PopupContentContainer extends React.Component {
  static propTypes = {
    bounds: PropTypes.object.isRequired, 
    classes: PropTypes.object,
    closePopup: PropTypes.func.isRequired,
    fetchPopupContent: PropTypes.func.isRequired,
    hideAllFilters: PropTypes.func.isRequired,
    pindex: PropTypes.string.isRequired,
    pins: PropTypes.array.isRequired,
    property: PropTypes.object.isRequired,
    searches: PropTypes.array.isRequired,
    setParcelProperties: PropTypes.func.isRequired
  };

  UNSAFE_componentWillMount() {
    this.props.fetchPopupContent(this.props.pindex);
  }

  render() {
    return <ParcelPopUp closePopup={this.props.closePopup} {...this.props} />;
  }
}

function mapStateToProps({ research: { map, searches } } , ownProps) {
  const pin = Object.values(map.pins).find((pin) => pin.pindex === ownProps.pindex);
  return {
    bounds: map.bounds.mapBounds,
    isFetching: map.isPopupFetching,
    pin,
    property: map.popupContent ? map.popupContent : pin,
    parcelPopup:  map.hasPopupError === false,
    searches: Object.keys(searches).filter((key) => searches[key].isEnabled)
  };
}

export default connect(mapStateToProps, { fetchPopupContent, hideAllFilters, setParcelProperties })(
  PopupContentContainer
);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import IconArrowDown from '@material-ui/icons/KeyboardArrowDown';
import IconArrowUp from '@material-ui/icons/KeyboardArrowUp';
import formatStat from './formatStat';
import styles from './module.css';

class ArrayStat extends React.Component {
  static propTypes = {
    arrayFormat: PropTypes.string,
    classes: PropTypes.object,
    displayLimit: PropTypes.number,
    value: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { expanded: false };
  }

  toggleExpand = () => {
    return (this.state.expanded === true) ? this.setState({ expanded: false }) : this.setState({ expanded: true });
  };

  render() {
    const { arrayFormat, classes, displayLimit } = this.props;
    return (
      <span>
        {this.props.value
          .slice(0, displayLimit)
          .map((item) => formatStat(item, { format: arrayFormat }))
          .join(', ')}
        {this.props.value.length > displayLimit && this.state.expanded && (
          <span>
            {', '}
            {this.props.value
              .slice(displayLimit)
              .map((item) => formatStat(item, { format: arrayFormat }))
              .join(', ')}
          </span>
        )}
        {this.props.value.length > displayLimit && (
          <div className={classes.showAll}>
            <a onClick={this.toggleExpand}>
              {this.state.expanded ? 'Hide' : `Show All (${this.props.value.length})`}
              <IconButton>{this.state.expanded ? <IconArrowUp /> : <IconArrowDown />}</IconButton>
            </a>
          </div>
        )}
      </span>
    );
  }
}

ArrayStat.defaultProps = {
  classes: {},
  displayLimit: 10
};

export default withStyles(styles)(ArrayStat);

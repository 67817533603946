import blue from '@material-ui/core/colors/blue';
import indigo from '@material-ui/core/colors/indigo';

export default (theme) => ({
  alertColumnName: {
    margin: theme.spacing(0),
    color: blue[500],
    ['&:hover']: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  alertHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 16px 0',
  },
  alertText: {
    color: indigo[500],
    fontWeight: '500',
    fontSize: '1.5rem',
  },
  clear: {
    textDecoration: 'underline',
    color: blue[500],
    cursor: 'pointer',
    textAlign: 'right',
    position: 'relative',
    top: '24px',
    right: '16px'
  },
  dialogContentText: {
    display: 'block',
    fontWeight: 'bold'
  },
  filterPopper: {
    backgroundColor: 'white',
    position: 'absolute',
  },
  formGroup: {
    padding: '8px'
  },
  alertColumnActions: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  alertTableActionButton: {
    marginRight: theme.spacing(1),
  },
  alertColumnModules: {
    display: 'flex',
  },
  alertTableCell: {
    width: '16%',
    padding: '12px 16px',
  },
  alertTableCellDescription: {
    width: '20%',
    padding: '12px 16px',
  },
  alertTableHeaderActionWrapper: {
    display: 'flex',
  },
  alertTableHeaderCellDescription: {
    width: '20%',
    borderBottom: '2px solid #25408F'
  },
  alertTableHeaderCell: {
    width: '16%',
    borderBottom: '2px solid #25408F'
  },
  alertTableHeaderCellBodyAction: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    margin: theme.spacing(0),
    alignSelf: 'center'
  },
  alertTableHeaderCellBody: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    margin: theme.spacing(0),
  },
  alertTableHeaderCellSort: {
    marginLeft: theme.spacing(0.5),
  },
  snackbarContent: {
    minWidth: 'fit-content',
  },
  sortIcon: {
    transform: 'scaleY(-1)'
  },
  tableRow: {
    '&:last-child td, &:last-child th': {
      border: 0
    }
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Stat from '../../../common/stats/Stat';
import Can from '../../../auth/ability';
import OurDealsLogo, { renderOurDeals } from '../../../common/OurDealsLogo';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const PrimaryText = ({ property, address, createdBy = {}, customClass }) => {
  const isOurDeals = renderOurDeals({
    datatype: 'lease',
    item: { createdBy }
  });

  const classes = useStyles();
  return (
    <div className={isOurDeals ? classes.primaryTextOurDeals : classes.primaryText}>
      {property && property.name || address && address.split(',')[0] || '\u2013'}
      <Can do="use" on="_app">
        {isOurDeals && (
          <OurDealsLogo customClass={customClass} />
        )}
      </Can>
    </div>
  );
};

PrimaryText.propTypes = {
  address: PropTypes.string,
  createdBy: PropTypes.object,
  customClass: PropTypes.string,
  property: PropTypes.object,
};

export const SecondaryText = ({ property, tenant, isQuickView, expirationDate }) => {
  const classes = useStyles();
  const address = get(property, 'address', '\u2013');
  const tenantDisplay = get(tenant, 'name', '\u2013');
  return (
    <React.Fragment>
      <div className={classes.secondaryText}>
        <p>{address}</p>
      </div>
      <div>
        <Stat
          className={classes.stat}
          label="Tenant"
          paddingValue="27px"
          value={tenantDisplay} />
        {isQuickView && (
          <Stat
            className={classes.stat}
            label="Expiration Date"
            value={expirationDate || '\u2013'} />
        )}
      </div>
    </React.Fragment>
  );
};

SecondaryText.propTypes = {
  expirationDate: PropTypes.string,
  isQuickView: PropTypes.bool,
  property: PropTypes.object,
  tenant: PropTypes.object,
};

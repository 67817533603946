import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FieldNote from './FieldNote';
import styles from './module.css';

const getClasses = makeStyles(styles);

const SummaryStat = ({ fieldNote, Icon, label, value, ...props }) => {
  const classes = getClasses(useTheme());
  return (
    <div {...props}>
      <div className={classes.statTitle}>
        {Icon && <Icon className={classes.statIcon} />}
        <strong>
          {label}
          {fieldNote && <FieldNote content={fieldNote} />}
        </strong>
      </div>
      <span className={classes.statValue}>{value || '-'}</span>
    </div>
  );
};

SummaryStat.propTypes = {
  Icon: PropTypes.object,
  fieldNote: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export default SummaryStat;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import styles from '../module.css';

const HeaderButton = ({ classes, title, handler, headerIcon, hide }) => (
  <React.Fragment>
    {!hide &&
      <Tooltip title={title}>
        <IconButton
          className={classes.headerButton}
          onClick={handler}>
          {headerIcon}
        </IconButton>
      </Tooltip>
    }
  </React.Fragment>
);

HeaderButton.propTypes = {
  classes: PropTypes.object.isRequired,
  handler: PropTypes.func,
  headerIcon: PropTypes.object.isRequired,
  hide: PropTypes.boolean,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(HeaderButton);

/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, RadioGroup, Radio, FormControlLabel, CircularProgress } from '@material-ui/core';

export const SubMarketList = (props) => {
  const {
    featureMeta,
    fetchGISFeature,
    featureContent,
    removeFeature,
    leafletMap,
    isFeatureFetching,
    featureType
  } = props;
  const setChecked = () => {
    const polygonLayer = featureContent.find((content) => content.type === 'POLYGON');
    if (!isFeatureFetching && polygonLayer) {
      return featureMeta.find((meta) => meta.id === polygonLayer.id).name;
    }
    return 'None';
  };
  const [value, setValue] = React.useState(setChecked());
  const getBoundary = (value) => {
    if (value !== 'None') {
      const { _northEast, _southWest } = leafletMap.getBounds();
      const boundary = {
        xleft: _southWest.lng,
        xright: _northEast.lng,
        ybottom: _southWest.lat,
        ytop: _northEast.lat
      };
      const params = new URLSearchParams({ ...boundary });
      fetchGISFeature(`${value}?${params}`, value, 'POLYGON');
    }
  };

  const handleChange = (event) => {
    setValue((event.target).value);
    const found = featureMeta.find((meta) => meta.name === (event.target).value);
    featureContent.forEach((feature) => {
      if (feature.type === 'POLYGON') {
        removeFeature(feature.id);
      }
    });
    if (found) {
      getBoundary(found.id);
    }
  };
  return (
    <FormControl>
      <RadioGroup
        value={value}
        onChange={handleChange}>
        <FormControlLabel
          value="None"
          control={<Radio />}
          label="None" />
        {featureMeta &&
          featureMeta.length &&
          featureMeta.filter((feature) => feature.name.includes(featureType)).map(({ id, name }) => {
            return (<FormControlLabel
              key={id}
              value={name}
              control={
                isFeatureFetching && value === name ?
                  <CircularProgress style={{ height: '19px', width: '19px', margin: '10px' }} /> : <Radio />
              }
              label={name.split('Submarkets')[0]} />);
          })}
      </RadioGroup>
    </FormControl>
  );
};

SubMarketList.propTypes = {
  featureContent: PropTypes.array,
  featureMeta: PropTypes.array,
  featureType: PropTypes.string.isRequired,
  fetchGISFeature: PropTypes.func.isRequired,
  isFeatureFetching: PropTypes.bool,
  leafletMap: PropTypes.object.isRequired,
  removeFeature: PropTypes.func.isRequired,
};
import React from 'react';
import { Router, Route, IndexRoute } from 'react-router';
import App from './App';
import Redirector from './Redirector';
import NotFound from './NotFound';
import Styleguide from './Styleguide';
import CrossOrigin from './auth/CrossOrigin';
import InteractiveLinkAuth from './auth/InteractiveLinkAuth';
import Logout from './auth/Logout';
import dashboardRoutes from './dashboard/routes';
import feedRoutes from './feed/routes';
import researchRoutes from './research/routes';
import accountRoutes from './account/routes';
import onboardingRoutes from './onboarding/routes';

export default function routes() {
  return (
    <Router>
      <Route path="/auth/cross-origin" component={CrossOrigin} />
      <Route path="/il/:id" component={InteractiveLinkAuth} />
      <Route path="/" component={App}>
        <IndexRoute components={{ main: Redirector }} />
        {dashboardRoutes}
        {feedRoutes}
        {researchRoutes}
        {accountRoutes}
        {onboardingRoutes}
        <Route path="styleguide" components={{ main: Styleguide }} />
        <Route path="logout" components={{ main: Logout, noHeader: true }} />
        <Route path="404" components={{ main: NotFound }} />
        <Route path="*" components={{ main: NotFound }} />
      </Route>
    </Router>
  );
}

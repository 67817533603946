import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import ErrorIcon from '@material-ui/icons/Error';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NestedItemIcon from '@material-ui/icons/SubdirectoryArrowRight';
import datatypes from '../../common/datatypes';
import NotificationMessage from '../../common/NotificationMessage';

const MAX_SHARE_ITEMS = 25;

const DATATYPE_LIMITS = {
  tim: 100
};

export default class CreateShareDialog extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    isSaving: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    onTitleChange: PropTypes.func,
    selectedResults: PropTypes.object
  };

  render() {
    const {
      isOpen,
      isSaving,
      onCancel,
      onConfirm,
      onTitleChange,
      selectedResults } = this.props;
    const resultsAreSelected = Object.keys(selectedResults).reduce(
      (acc, datatype) => acc || (selectedResults[datatype].length > 0),
      false
    );

    const tooManySelected = Object.keys(selectedResults).reduce(
      (acc, datatype) => (acc || (selectedResults[datatype].length > (DATATYPE_LIMITS[datatype] || MAX_SHARE_ITEMS))),
      false
    );

    if (!resultsAreSelected) {
      return (
        <Dialog open={isOpen}>
          <DialogTitle>
            Zero Items Selected
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Use the list next to the map or the table view to select
              the items you wish to share from this search.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={onCancel}>Ok</Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <React.Fragment>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={isOpen}
          BackdropProps={{ invisible: true }}>
          {isSaving && (
            <DialogContent style={{ padding: '32px', textAlign: 'center' }}>
              <CircularProgress size={60} />
            </DialogContent>
          )}
          {!isSaving && (
            <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                fullWidth
                disabled={isSaving || tooManySelected}
                placeholder="(optional)"
                label="Search Title"
                InputLabelProps={{ shrink: true }}
                onChange={onTitleChange}
                style={{ marginBottom: 16 }} />

              {Object.keys(selectedResults)
                .filter((datatype) => selectedResults[datatype].length > 0)
                .sort()
                .map((datatype) => {
                  const { color, plural, Icon } = datatypes[datatype];
                  const borderLeft = `4px solid ${color}`;
                  return (
                    <Accordion key={datatype} defaultExpanded>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ borderLeft }}>
                        <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                          <ListItemIcon><Icon style={{ color }} /></ListItemIcon>
                          <ListItemText
                            primary={plural}
                            secondary={(
                              <React.Fragment>
                                {selectedResults[datatype].length} selected
                                (max: {(DATATYPE_LIMITS[datatype] || MAX_SHARE_ITEMS)})
                                {selectedResults[datatype].length > (DATATYPE_LIMITS[datatype] || MAX_SHARE_ITEMS) && (
                                  <ErrorIcon
                                    color="error"
                                    style={{
                                      height: '1.2em',
                                      marginLeft: '4px',
                                      width: '1.2em',
                                      verticalAlign: 'bottom'
                                    }} />
                                )}
                              </React.Fragment>
                            )} />
                        </ListItem>
                      </AccordionSummary>
                      <AccordionDetails style={{ borderLeft, paddingTop: 0 }}>
                        <List dense style={{ padding: 0 }}>
                          {selectedResults[datatype].map(({ _source }) => (
                            <ListItem key={_source.id} style={{ fontSize: '85%' }}>
                              <ListItemIcon><NestedItemIcon /></ListItemIcon>
                              <ListItemText>{_source.canonicalName}</ListItemText>
                            </ListItem>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              }
            </DialogContent>
          )}
          <DialogActions>
            <Button
              disabled={isSaving}
              onClick={onCancel}>Cancel</Button>
            <Button
              color="primary"
              disabled={isSaving || tooManySelected}
              onClick={onConfirm}>Continue</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          message={(
            <NotificationMessage
              text={`Only up to ${MAX_SHARE_ITEMS} of each record type may be shared at one time.`}
              type="error" />
          )}
          open={tooManySelected} />
      </React.Fragment>
    );
  }
}

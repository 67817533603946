import { grey } from '@material-ui/core/colors';

export default (theme) => ({
  arrow: {
    'position': 'absolute',
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: '8px solid #fff',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      position: 'absolute',
      zIndex: 100,
      top: '-12px',
      left: '50%',
      transform: 'translate(-50%, 50%)'
    },
  },
  headerBar: {
    backgroundColor: theme.palette.colliersBlue,
    zIndex: theme.zIndex.drawer + 1,
    ['@media print']: {
      display: 'none !important'
    }
  },
  headerButton: {
    color: 'inherit',
    fontWeight: 'bold',
    margin: 5,
    ['&:not($selected):hover']: {
      backgroundColor: 'rgba(153, 153, 153, 0.2)'
    },
    ['&$selected']: {
      backgroundColor: '#757575'
    },
  },
  headerButtonIcon: {},
  headerButtonSquare: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  headerLogoButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  headerPopper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    ['&:tooltip']: {
      top: '80px'
    }
  },
  listItemText: {
    color: grey[700],
  },
  logo: {
    height: 60,
    padding: `${theme.spacing(1.25)}px 0`,
    ['& > img']: {
      height: theme.spacing(5)
    }
  },
  logoImg: {},
  navContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  selected: {},
  spotlightContainer: {
    flex: 1,
    margin: `0 ${theme.spacing(2)}px 0  ${theme.spacing(1)}px`
  },
  toolbar: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },

  [theme.breakpoints.down(600)]: {
    headerButtonIcon: {
      margin: 0,
    },
    headerButtonLabel: {
      display: 'none'
    },
    spotlightContainer: {
      display: 'none'
    },
    toolbar: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(0.5)
    }
  }
});

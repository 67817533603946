import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { enableDatatype } from '../research/searches/actions';
import Dashboard from './Dashboard';

export class Container extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object,
    enableDatatype: PropTypes.func.isRequired,
    router: PropTypes.object,
  };

  render() {
    return (
      <div>
        <Dashboard {...this.props} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.dashboard,
    ...state.auth,
    ...state.feed,
  };
}

export default connect(mapStateToProps, { enableDatatype })(Container);

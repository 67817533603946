import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchGISFeature, removeFeature } from '../actions';
import { CircularProgress, List } from '@material-ui/core';
import { FeatureMetaList } from './POIList';
import { SubMarketList } from './SubMarketList';
import { Demographics } from './Demographics';

export class FeatureContainer extends React.Component {
  static propTypes = {
    featureMeta: PropTypes.array,
    featureType: PropTypes.string.isRequired,
    fetchGISFeature: PropTypes.func.isRequired,
    isFeatureFetching: PropTypes.bool,
    isFeatureMetaFetching: PropTypes.bool,
    removeFeature: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    if (!this.props.featureMeta) {
      this.props.fetchGISFeature();
    }
  }

  selectFeatureType() {
    if (this.props.featureType === 'Submarkets') {
      return <SubMarketList {...this.props} featureType={this.props.featureType} />;
    }
    if (this.props.featureType === 'points-of-interest') {
      return <FeatureMetaList  {...this.props} featureType={this.props.featureType} />;
    }
    return <Demographics {...this.props} featureType={this.props.featureType} />;
  }

  render() {
    return (
      <List style={{ width: '210px', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
        {this.props.isFeatureMetaFetching ? <div style={{ margin: '10px' }}><CircularProgress /> </div> :
          this.selectFeatureType()}
      </List>
    );
  }
}

function mapStateToProps({ research: { map } }) {
  return {
    isFeatureFetching: map.isFeatureFetching,
    isFeatureMetaFetching: map.isFeatureMetaFetching,
    fetchGISFeature: map.fetchGISFeature,
    featureMeta: map.featureMeta,
    featureContent: map.featureContent,
    removeFeature: map.removeFeature
  };
}

export default connect(mapStateToProps, { fetchGISFeature, removeFeature })(FeatureContainer);

export default () => ({
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  mainContainer: {
    height: '100%',
    overflow: 'auto'
  },

  // globals for the app
  ['@global']: {
    '.leaflet-bottom.leaflet-left': {
      marginBottom: 25
    }
  }
});


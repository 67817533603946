import { combineReducers } from 'redux';
import mapValues from 'lodash/mapValues';
import {
  CLEAR_SELECTED_RESULTS,
  CLEAR_SELECTED_RESULTS_ALL_DATATYPES,
  DESELECT_SEARCH_RESULT,
  DETAIL_PROPERTY_FETCH_ERROR,
  NOTIFICATIONS_CLOSE,
  MY_LISTS_FETCH_ERROR,
  MY_LISTS_FETCH_TRANSLATE_SUCCESS,
  MY_LISTS_UPDATE_ERROR,
  SELECT_SAVEDSEARCH,
  SELECT_SEARCH_RESULT,
  SELECT_ALL_SEARCH_RESULTS,
  SET_SELECTION_ANCHOR,
  SET_SOURCE_FILTER_SCHEMA,
  TOGGLE_SHARE_SEARCH_DIALOG,
  TOGGLE_SHOW_SELECTED,
} from '../constants/actionTypes';
import { alerts, lists } from '../account/reducer';
import { MAX_REPORT_SIZE } from './constants';
import map from './map/reducer';
import searches from './searches/reducer';
import table from './table/reducer';
import parcelDetail from './parcelDetail/reducer';
import propertyDetail from './propertyDetail/reducer';
import timDetail from './timDetail/reducer';
import problemReporter from './problemReporter/reducer';
import analytics from './analytics/reducer';
import reports from './reports/reducer';

function sourceFilter(state = 'list', action) {
  switch (action.type) {
    case SET_SOURCE_FILTER_SCHEMA:
      return action.schema;

    default:
      return state;
  }
}

function selectedResults(state = {}, action) {
  switch (action.type) {
    case SELECT_ALL_SEARCH_RESULTS:
      return {
        ...state,
        [action.dataType]: action.results
      };

    case SELECT_SEARCH_RESULT:
      if (!state[action.dataType] || state[action.dataType].length < MAX_REPORT_SIZE) {
        return Object.assign({}, state, { [action.dataType]: [...state[action.dataType] || [], action.item] });
      }
      return state;

    case DESELECT_SEARCH_RESULT:
      return Object.assign(
        {},
        state,
        { [action.dataType]: [...state[action.dataType].filter((result) => action.item._id !== result._id)] }
      );

    case CLEAR_SELECTED_RESULTS:
      return Object.assign({}, state, { [action.dataType]: [] });

    case CLEAR_SELECTED_RESULTS_ALL_DATATYPES:
      return {  };

    case SELECT_SAVEDSEARCH:
      return mapValues(action.savedSearch.selectedItems, (items, key) => {
        return action.searches[key].results.filter((result) => items.includes(result._id));
      });

    default:
      return state;
  }
}

function selectionAnchor(state = null, action) {
  switch (action.type) {
    case SET_SELECTION_ANCHOR:
      return action.item ? Object.assign({}, action.item)  : null;
    default:
      return state;
  }
}

function showSelected(state = false, action) {
  switch (action.type) {
    case TOGGLE_SHOW_SELECTED:
      return !state;

    default:
      return state;
  }
}

function isSharing(state = false, action) {
  switch (action.type) {
    case TOGGLE_SHARE_SEARCH_DIALOG:
      return action.open;

    default:
      return state;
  }
}

function notifications(state = { open: false }, action) {
  switch (action.type) {
    case DETAIL_PROPERTY_FETCH_ERROR:
      return {
        ...state,
        message: action.error.message,
        open: true,
        type: 'error'
      };

    case NOTIFICATIONS_CLOSE:
      return {
        ...state,
        message: undefined,
        open: false,
        type: undefined
      };

    default:
      return state;
  }
}

function myLists(state = {
  myLists: {},
  myListsFetchError: {},
  recentMyLists: [],
}, action) {
  switch (action.type) {
    case MY_LISTS_FETCH_ERROR:
    case MY_LISTS_UPDATE_ERROR: 
      return {
        ...state,
        myListsFetchError: action.err
      };
    case MY_LISTS_FETCH_TRANSLATE_SUCCESS:
      return {
        ...state,
        myLists: action.myLists
      };
    
    default:
      return state;
  }    
}

export default combineReducers({
  analytics,
  alerts,
  isSharing,
  lists,
  map,
  myLists,
  notifications,
  parcelDetail,
  problemReporter,
  propertyDetail,
  reports,
  selectedResults,
  selectionAnchor,
  searches,
  sourceFilter,
  showSelected,
  table,
  timDetail
});

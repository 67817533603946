import React from 'react';
import { render } from 'react-dom';
import WebFont from 'webfontloader';
import { AppContainer } from 'react-hot-loader';
import 'react-hot-loader/patch';
import store, { history } from './store';
import Root from './Root';
import 'hint.css/hint.css';
import 'react-virtualized/styles.css';

// Disable scroll-controlled input on number inputs
// https://stackoverflow.com/a/38589039
document.addEventListener('wheel', () => {
  if (document.activeElement.type === 'number') {
    document.activeElement.blur();
  }
});

const rootNode = document.getElementById('root');
function mountApp() {
  render(
    <AppContainer>
      <Root store={store} history={history} />
    </AppContainer>,
    rootNode
  );
}

WebFont.load({
  google: {
    families: ['Open Sans:100,300,400,500,700,800&display=swap']
  },
  timeout: 2000,
  // Don't mount the app until the webfonts have loaded to prevent FOUT
  // https://helpx.adobe.com/typekit/using/font-events.html
  active: mountApp,
  inactive: mountApp
});

if (module.hot) {
  module.hot.accept('./Root', () => {
    const NextApp = require('./Root').default; // eslint-disable-line global-require

    render(
      <AppContainer>
        <NextApp store={store} history={history} />
      </AppContainer>,
      rootNode
    );
  });
}

import blue from '@material-ui/core/colors/blue';

export default (theme) => ({
  cancelButton: {
    width: '80px',
    fontWeight: '500',
    color:  '#0C9ED9',
    border: '0.5px solid black',
    backgroundColor: 'white',
    borderRadius: '20px'
  },
  close: {
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
  deleteSearch: {
    color: 'red',
    fontWeight: '500',
    position: 'absolute',
    left: '18px',
  },
  notifyText: {
    marginTop: '16px',
    marginBottom: '4px',
    fontWeight: 'bold',
  },
  notifySubText: {
    marginBottom: '16px',
    color: '#707274'
  },
  savedSearchTableHeaderCell: {
    borderBottom: '2px solid #25408F',
    padding: '12px 16px'
  },
  savedSearchTableHeaderActionWrapper: {
    display: 'flex',
  },
  savedSearchTableHeaderCellSort: {
    marginLeft: theme.spacing(0.5),
  },
  savedSearchTableHeaderCellBodyAction: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    margin: theme.spacing(0),
    alignSelf: 'center'
  },
  savedSearchTableHeaderCellBody: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    margin: theme.spacing(0),
  },
  savedSearchTableCell: {
    padding: '12px 16px',
  },
  savedSearchColumnName: {
    margin: theme.spacing(0),
    color: blue[500],
    ['&:hover']: {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  savedSearchColumnModules: {
    display: 'flex',
  },
  savedSearchModules: {
    display: 'flex',
    position: 'absolute',
    top: '16px',
    right: '80px',
  },
  savedSearchColumnActions: {
    display: 'flex',
  },
  toggleButtons: {
    ['& .MuiToggleButton-root']: {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid #707274',
      borderRadius: 0,
      width: '80px'
    },
    ['& .MuiToggleButton-root.Mui-selected']: {
      backgroundColor: '#0C9ED9',
      color: 'white'
    },
  },
  updateButton: {
    width: '80px',
    color: 'white',
    fontWeight: '500',
    backgroundColor: '#304085',
    borderRadius: '20px'
  },
});

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './module.css';

class AuthenticatedImage extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    height: PropTypes.number,
    url: PropTypes.string.isRequired,
    width: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.state = {
      dataUrl: null,
      loaded: false
    };
  }

  componentDidMount() {
    const token = window.localStorage.getItem('token') || null;
    this._isFetching = true;
    fetch(this.props.url, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        if (res.status === 200) return res.blob();
        return Promise.reject(res);
      })
      .then((res) => {
        if (this._isFetching) {
          this._isFetching = false;
          this.setState({
            dataUrl: URL.createObjectURL(res),
            loaded: true
          });
        }
      })
      .catch(() => {
        this.setState({
          loaded: true
        });
      });
  }

  componentWillUnmount() {
    this._isFetching = false;
    if (this.state.dataUrl) {
      URL.revokeObjectURL(this.state.dataUrl);
    }
  }

  render() {
    const { classes } = this.props;
    if (this.props.children) {
      const childrenWithProps = React.Children.map(this.props.children,
        (child) => React.cloneElement(child, { authImage: this.state.dataUrl })
      );

      return <div>{childrenWithProps}</div>;
    }

    if (this.state.dataUrl) {
      return (
        <img
          className={classes.authImage}
          style={{ height: this.props.height, width: this.props.width }}
          src={this.state.dataUrl} />
      );
    }

    if (!this.state.loaded) {
      return (
        <div className={classes.authImageLoading} style={{ height: this.props.height, width: this.props.width }}>
          <CircularProgress />
        </div>
      );
    }

    return null;
  }
}

export default withStyles(styles)(AuthenticatedImage);

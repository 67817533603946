import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dimensions from 'react-dimensions';
import datatypes from '../../common/datatypes';
import * as actions from './actions';
import Reports from './Reports';

class ReportsContainer extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    results: PropTypes.array.isRequired
  };

  render() {
    return (
      <Reports
        {...this.props} />
    );
  }
}
function mapStateToProps({ auth, research: { reports, selectedResults } }, { location }) {
  const { 
    isBuildingFullReport, 
    isFetchingPreviewReport, 
    previewReportDataUri, 
    reportGenerationError
  } = reports;
  const { query: { currentListView,  selectedReport, singlePrint }, state: { record } = {} } = location;
  const reportRecord = record || [];
  return {
    columns: datatypes[currentListView].columns,
    currentUser: auth.currentUser,
    currentListView: currentListView || 'lease',
    isBuildingFullReport,
    isFetchingPreviewReport,
    previewReportDataUri,
    reportGenerationError,
    results:  (singlePrint ? reportRecord : selectedResults[currentListView]) || [],
    selectedReport,
    singlePrint
  };
}

export default dimensions()(connect(mapStateToProps, actions)(ReportsContainer));

/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import ReactStreetview from 'react-streetview';
import PropTypes from 'prop-types';
import moment from 'moment';
import cx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Walkscore from '../../common/Walkscore';
import StaticMap from '../../common/StaticMap';
import styles from './module.css';
import PropertySubheader from './propertySubheader/PropertySubheader';
import Section from './common/Section';
import Features from './common/Features';
import Images from './common/Images';
import Notes from './common/Notes';
import Documents from './common/Documents';

const Info = ({ allNearbyItems, classes, fetchAllDatatypeWithFilters, isNested, params, property, navigate, visibleDatatypes }) => {
  const { 
    market,
    submarket,
    micromarket,
    crossStreets,
    county,
    suite,
    complexName,
    sourcedBy,
    website,
    virtualTourUrl,
    propertyTypes,
    propertyUses,
    buildingClass,
    yearBuilt,
    yearRenovated,
    hasExpansionPotential,
    isFullBlock,
    energyCertification,
    isGreenGlobe,
    buildingRoofType,
    constructionTypes,
    rentableBuildingArea,
    averageFloorArea,
    buildingHeightFloors,
    floorAreaRatio,
    buildings,
    buildingHeightRise,
    occupancyType,
    units,
    averageUnitArea,
    grossUp,
    grossUpYear,
    rentAverage,
    occupancyRate,
    affordablePercentage,
    buildingPassengerElevators,
    buildingFreightElevators,
    loadFactor,
    dockDoors,
    driveIns,
    amperage,
    amperageHigh,
    electricalPhase,
    docks,
    docksAtGrade,
    isVented,
    hasCreativeSpace,
    isEnergyStar,
    hasRailSpur,
    parkingRatio,
    parkingDetails,
    trailerSpaces,
    clearHeightMax,
    clearHeightMin,
    voltage,
    voltageHigh,
    yardArea,
    docksCross,
    docksInside,
    hasClimateControl,
    hasFencedYard,
    hasGreenSpace,
    spaceUses,
    sprinklers,
    columnSpacingDepth,
    columnSpacingWidth,
    columnSpacingWidthMax,
    columnSpacingWidthMin,
    cranes,
    cranesCapacity,
    cranesHookHeight,
    hasColdStorage,
    hasMezzanine,
    hasAtrium,
    owners,
    managementRepresentatives,
    territoryManagers,
    leasingRepresentatives,
    architects,
    development,
    landArea,
    usableLandArea,
    lotDepth,
    lotWidth,
    zoningCodes,
    parcels,
    generalPlan,
    assessments,
    entitlements,
    utilities,
    floodRisk,
    floodZoneCode,
    tenure,
    topography,
    isEnterpriseZone,
    isFreeTradeZone,
    createdAt,
    updatedAt,
    ourDealsId,
    ourListingId,
    coStarId,
    customId,
    legacyId,
    lightboxId
  } = property;
  const nav = (key, id = null) => () => navigate(key, id);
  const fieldNotes = property.fieldNotes || {};

  const basicInfo = [
    [
      { key: 'rentableBuildingArea', label: 'RBA', value: rentableBuildingArea, format: 'area' },
      { key: 'buildingHeightFloors', label: 'Building Floors', value: buildingHeightFloors, format: 'number' },
      { key: 'averageFloorArea', label: 'Average Floor Area', value: averageFloorArea, format: 'number' },
      { key: 'buildings', label: 'Number of Buildings', value: buildings, format: 'number' },
      { key: 'buildingHeightRise', label: 'Building Rise', value: buildingHeightRise, format: 'number' },
      { key: 'occupancyType', label: 'Occupancy Type', value: occupancyType, format: 'string' },
      { key: 'units', label: 'Number of Units', value: units, format: 'number' },
      { key: 'averageUnitArea', label: 'Average Size Per Unit', value: averageUnitArea, format: 'number' },
      { key: 'grossUp', label: 'Gross-Up', value: grossUp, format: 'currency' },
      { key: 'grossUpYear', label: 'Gross-Up Year', value: grossUpYear, format: 'string' },
      { key: 'rentAverage', label: 'Average Rental Rate', value: rentAverage, format: 'currency' },
      { key: 'occupancyRate', label: 'Percent Occupied', value: occupancyRate, format: 'ratio' }
    ],
    [
      { key: 'propertyTypes', label: 'Property Types', value: propertyTypes, format: 'array' },
      { key: 'propertyUses', label: 'Property Uses', value: propertyUses, format: 'array' },
      { key: 'buildingClass', label: 'Class', value: buildingClass, format: 'string' },
      { key: 'yearBuilt', label: 'Year Built', value: yearBuilt, format: 'string' },
      { key: 'yearRenovated', label: 'Year Renovated', value: yearRenovated, format: 'string' },
      { key: 'hasExpansionPotential', label: 'Expansion Potential', value: hasExpansionPotential, format: 'boolean' },
      { key: 'isFullBlock', label: 'Full Block', value: isFullBlock, format: 'boolean' },
      { key: 'energyCertification', label: 'LEED Cert.', value: energyCertification, format: 'string' },
      { key: 'isGreenGlobe', label: 'Green Globe Cert.', value: isGreenGlobe, format: 'boolean' },
      { key: 'buildingRoofType', label: 'Roof Type', value: buildingRoofType, format: 'string' },
      { key: 'constructionTypes', label: 'Constructions Type', value: constructionTypes, format: 'array' },
      { key: 'affordablePercentage', label: 'Percent Affordable', value: affordablePercentage, format: 'ratio' }
    ],
    [
      { key: 'market', label: 'Market', value: market, format: 'string' },
      { key: 'submarket', label: 'Submarket', value: submarket, format: 'string' },
      { key: 'micromarket', label: 'Micro-Market', value: micromarket, format: 'string' },
      { key: 'crossStreets', label: 'Cross Streets', value: crossStreets, format: 'string' },
      { key: 'county', label: 'County', value: county, format: 'string' },
      { key: 'suite', label: 'Suite', value: suite, format: 'string' },
      { key: 'complexName', label: 'Complex', value: complexName, format: 'string' },
      { key: 'sourcedBy', label: 'Source', value: sourcedBy, format: 'string' },
      { key: 'website', label: 'Website', value: website, format: 'uri' },
      { key: 'virtualTourUrl', label: 'Virtual Tour URL', value: virtualTourUrl, format: 'uri' }
    ]
  ];

  const additionalInfoTopRow = [
    [{ key: 'parkingRatio', label: 'Parking Ratio', value: parkingRatio, format: 'parkingRatio' }],
    [{ key: 'parkingDetails', label: 'Parking Details', value: parkingDetails, format: 'parkingDetails', customClass: classes.spanTwoColumns }]
  ];

  const additionalInfo = [
    [
      { key: 'buildingPassengerElevators', label: 'Passenger Elevators', value: buildingPassengerElevators, format: 'number' },
      { key: 'buildingFreightElevators', label: 'Freight Elevators', value: buildingFreightElevators, format: 'number' },
      { key: 'loadFactor', label: 'Load Factor', value: loadFactor, format: 'ratio' },
      { key: 'dockDoors', label: 'Dock Doors', value: dockDoors, format: 'number' },
      { key: 'driveIns', label: 'Drive-Ins', value: driveIns, format: 'number' },
      { key: 'amperage', label: 'Amperage (Low)', value: amperage, format: 'number' },
      { key: 'amperageHigh', label: 'Amperage (High)', value: amperageHigh, format: 'number' },
      { key: 'electricalPhase', label: 'Electrical Phase', value: electricalPhase, format: 'number' },
      { key: 'docks', label: 'Number of Docks', value: docks, format: 'number' }
    ],
    [
      { key: 'trailerSpaces', label: 'Trailer Spaces', value: trailerSpaces, format: 'number' },
      { key: 'clearHeightMin', label: 'Min Clear Height', value: clearHeightMin, format: 'number' },
      { key: 'clearHeightMax', label: 'Max Clear Height', value: clearHeightMax, format: 'number' },
      { key: 'voltage', label: 'Volts (Low)', value: voltage, format: 'number' },
      { key: 'voltageHigh', label: 'Volts (High)', value: voltageHigh, format: 'number' },
      { key: 'yardArea', label: 'Yard Area', value: yardArea, format: 'number' },
      { key: 'docksCross', label: 'Cross Docks', value: docksCross, format: 'number' },
      { key: 'docksInside', label: 'Interior Docks', value: docksInside, format: 'number' },
      { key: 'docksAtGrade', label: 'Docks at Grade', value: docksAtGrade, format: 'number' }
    ],
    [
      { key: 'sprinklers', label: 'Sprinklers', value: sprinklers, format: 'string' },
      { key: 'columnSpacingDepth', label: 'Col. Spacing (depth)', value: columnSpacingDepth, format: 'number' },
      { key: 'columnSpacingWidth', label: 'Col. Spacing (width)', value: columnSpacingWidth, format: 'number' },
      { key: 'columnSpacingWidthMin', label: 'Min Column Spacing', value: columnSpacingWidthMin, format: 'number' },
      { key: 'columnSpacingWidthMax', label: 'Max Column Spacing', value: columnSpacingWidthMax, format: 'number' },
      { key: 'cranes', label: 'Number of Cranes', value: cranes, format: 'number' },
      { key: 'cranesCapacity', label: 'Crane Capacity (tons)', value: cranesCapacity, format: 'number' },
      { key: 'cranesHookHeight', label: 'Crane Hook Height (ft)', value: cranesHookHeight, format: 'number' }
    ]
  ];

  const additionalInfoSpecialRow = [
    [
      { key: 'isVented', label: 'Vented', value: isVented, format: 'boolean' },
      { key: 'hasCreativeSpace', label: 'Creative Space', value: hasCreativeSpace, format: 'boolean' },
      { key: 'isEnergyStar', label: 'Energy Star Cert.', value: isEnergyStar, format: 'boolean' },
      { key: 'hasRailSpur', label: 'Rail Spur', value: hasRailSpur, format: 'boolean' },
      { key: 'spaceUses', label: 'Space Uses', value: spaceUses, format: 'spaceUses', customClass: classes.spanTwoColumns }
    ],
    [
      { key: 'hasClimateControl', label: 'Climate Controlled', value: hasClimateControl, format: 'boolean' },
      { key: 'hasFencedYard', label: 'Fenced Yard', value: hasFencedYard, format: 'boolean' },
      { key: 'hasGreenSpace', label: 'Green Spaces', value: hasGreenSpace, format: 'boolean' }
    ],
    [
      { key: 'hasColdStorage', label: 'Cold Storage', value: hasColdStorage, format: 'boolean' },
      { key: 'hasMezzanine', label: 'Mezzanine', value: hasMezzanine, format: 'boolean' },
      { key: 'hasAtrium', label: 'Atrium', value: hasAtrium, format: 'boolean' }
    ]
  ];
  
  const contactInfo = [
    [
      { key: 'owners', label: 'Owner', value: owners, format: 'signatories' },
      { key: 'managementRepresentatives', label: 'Management Rep.', value: managementRepresentatives, format: 'signatories' },
      { key: 'territoryManagers', label: 'Territory Manager', value: territoryManagers, format: 'signatories' },
    ],
    [
      { key: 'leasingRepresentatives', label: 'Leasing Rep.', value: leasingRepresentatives, format: 'signatories' },
      { key: 'architects', label: 'Architect', value: architects, format: 'signatories' },
      { key: 'developers', label: 'Developers', value: development, format: 'developers' }
    ]
  ];

  const parcelInfo = [
    [
      { key: 'landArea', value: landArea, label: 'Land Area', format: 'number' },
      { key: 'usableLandArea', value: usableLandArea, label: 'Usable Land Area', format: 'number' },
      { key: 'lotDepth', value: lotDepth, label: 'Lot Depth', format: 'number' },
      { key: 'lotWidth', value: lotWidth, label: 'Lot Width', format: 'number' },
      { key: 'zoningCodes', value: zoningCodes, label: 'Zoning Codes', format: 'array' },
      { key: 'parcels', value: parcels, label: 'Parcels', format: 'array' }
    ],
    [
      { key: 'floorAreaRatio', value: floorAreaRatio, label: 'Floor Area Ratio', format: 'number' },
      { key: 'generalPlan', value: generalPlan, label: 'General Plan', format: 'string' },
      { key: 'assessments', value: assessments, label: 'Assessments', format: 'string' },
      { key: 'entitlements', value: entitlements, label: 'Entitlements', format: 'string' },
      { key: 'utilities', value: utilities, label: 'Utilities', format: 'array' }
    ],
    [
      { key: 'floodRisk', value: floodRisk, label: 'Flood Risk', format: 'string' },
      { key: 'floodZoneCode', value: floodZoneCode, label: 'Flood Zone Code', format: 'string' },
      { key: 'tenure', value: tenure, label: 'Tenure', format: 'string' },
      { key: 'topography', value: topography, label: 'Topography', format: 'string' },
      { key: 'isEnterpriseZone', value: isEnterpriseZone, label: 'Enterprise Zone', format: 'boolean' },
      { key: 'isFreeTradeZone', value: isFreeTradeZone, label: 'Free Trade Zone', format: 'boolean' }
    ]
  ];

  const [recordInfo, setRecordInfo] = useState([
    [
      { key: 'createdAt', value: moment(createdAt).format('MMM D, YYYY') || '--', label: 'Created At', format: 'date' },
      { key: 'usableLandArea', value: moment(updatedAt).format('MMM D, YYYY') || '--', label: 'Last Modified', format: 'date' }
    ],
    [
      { key: 'relatedIds', value: ' ', label: 'Related IDs', format: 'string' },
      { key: 'ourDeals', value: ourDealsId, label: 'Our Deals', format: 'string' },
      { key: 'ourListings', value: ourListingId, label: 'Our Listings', format: 'string' },
      { key: 'coStar', value: coStarId, label: 'CoStar', format: 'string' },
      { key: 'legacy', value: legacyId, label: 'Legacy', format: 'string' },
      { key: 'lightBox', value: lightboxId, label: 'LightBox', format: 'string' }
    ],
    [
      { key: 'customIds', value: ' ', label: 'Custom IDs', format: 'string' }
    ]
  ]);
  
  useEffect(() => {
    if (customId && customId.length > 0) {
      setRecordInfo((prevRecordInfo) => {
        const newRecordInfo = [...prevRecordInfo];
        customId.forEach((item) => {
          const customItem = { key: `customIds_${item.name}`, value: item.id, label: item.name, format: 'string' };
          newRecordInfo[2].push(customItem);
        });
        return newRecordInfo;
      });
    }
  }, []);

  const { documents, features, images, note, _type: resource, __tenant: tenant, rescourId } = property;
  return (
    <div className={cx({ [classes.infoContainer]: true, [classes.infoContainerNested]: isNested })}>
      <PropertySubheader
        allNearbyItems={allNearbyItems}
        fetchAllDatatypeWithFilters={fetchAllDatatypeWithFilters}
        nav={nav}
        params={params}
        property={property}
        datatypeInfo={visibleDatatypes} />
      <Section
        title={'Basic Info'}
        sectionArray={basicInfo}
        fieldNotes={fieldNotes}
        specialHeaderEdit={true}
        tenant={tenant}
        rescourId={rescourId}
        resource={resource} />
      {documents && (
        <Documents documents={documents} />)}
      <section>
        <Paper elevation={1}>
          <div className={classes.headerGallery}>
            <StaticMap
              location={property.location}
              size="600x375"
              zoom={15} />
            <div className={classes.streetview}>
              <ReactStreetview
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                streetViewPanoramaOptions={{
                  position: { lat: property.location[1], lng: property.location[0] },
                  pov: { heading: 100, pitch: 20 },
                  zoom: 1,
                  motionTracking: false,
                  motionTrackingControl: false
                }} />
            </div>
          </div>
        </Paper>
      </section>
      <section>
        <Paper elevation={1} className={classes.sectionPaper}>
          <Typography className={classes.sectionHeader} variant="subtitle1">Neighborhood Info</Typography>
          <div className={classes.neighborhoodContainer}>
            <Walkscore
              className={classes.walkscore}
              location={property.location} />
          </div>
        </Paper>
      </section>
      <Section
        title={'Additional Info'}
        sectionArray={additionalInfo}
        fieldNotes={fieldNotes}
        specialTopRowArray={additionalInfoTopRow}
        specialRowArray={additionalInfoSpecialRow}
        item={property} />
      <Features
        features={features}
        fieldNote={fieldNotes.features ? fieldNotes.features : null} />
      <Section
        title={'Contacts'}
        sectionArray={contactInfo}
        fieldNotes={fieldNotes} />
      <Section
        title={'Parcels'} 
        sectionArray={parcelInfo}
        fieldNotes={fieldNotes} />
      {note && note.length > 0 &&  (
        <Notes
          note={note}
          fieldNote={fieldNotes.note ? fieldNotes.note : null} />)}
      {images && images.length > 0 &&  (
        <Images
          images={images}
          fieldNote={fieldNotes.images ? fieldNotes.images : null} />)}
      <Section
        title={'Record Info'}
        sectionArray={recordInfo}
        fieldNotes={fieldNotes} />
    </div>
  );
};

Info.propTypes = {
  allNearbyItems: PropTypes.object,
  classes: PropTypes.object,
  fetchAllDatatypeWithFilters: PropTypes.func.isRequired,
  isNested: PropTypes.bool,
  navigate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  property: PropTypes.object,
  visibleDatatypes: PropTypes.array.isRequired,
};

Info.defaultProps = {
  property: {},
};

export default withStyles(styles)(Info);

import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field as FormikField } from 'formik'; // TODO: changed redux-form to formik, but didn't do anything to file
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';

const TitleField = ({ field, label, form, ...custom }) => (
  <TextField
    fullWidth
    label={label}
    error={Boolean(form.errors[field.name])}
    {...field}
    {...custom} />
);

TitleField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

const GridViewCreator = ({ onClose, onSave, open }) => {
  const close = () => { return onClose(); };

  const handleSave = (data) => {
    onSave(data);
  };

  const initialValues = { title: '' };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={close}
      open={open}>
      <Formik onSubmit={handleSave} initialValues={initialValues}>
        {(formik) => (
          <Form style={{ display: 'flex', flexDirection: 'column' }}>
            <DialogTitle>Save Grid View</DialogTitle>
            <DialogContent>
              <FormikField
                name="title"
                component={TitleField}
                form={formik}
                label="Title" />
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary">Save</Button>
              <Button onClick={close}>Cancel</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

GridViewCreator.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default GridViewCreator;

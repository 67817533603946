import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import PortfolioIcon from '@material-ui/icons/Folder';
import SummaryFieldStats from '../common/SummaryFieldStats';
import Section from '../common/Section';
import SuiteInfo from '../common/SuiteInfo';
import Features from '../common/Features';
import Notes from '../common/Notes';
import Images from '../common/Images';
import Documents from '../common/Documents';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const CardContent = ({ item }) => {
  const classes = useStyles();
  const {
    area,
    transactionValue,
    pricePerSquareFoot,
    capRate,
    soldDate,
    saleType,
    buyers,
    sourcedBy
  } = item;
  const formattedBuyers = buyers && buyers[0] && buyers[0].name ? buyers[0].name : null;

  const saleStats = [
    { label: 'Date Sold', value: soldDate, format: 'date' },
    { label: 'RBA', value: area, format: 'number' },
    { label: 'Sale Price', value: transactionValue, format: 'wholeCurrency' },
    { label: 'Price / SF', value: pricePerSquareFoot, format: 'currency' },
    { label: 'Cap Rate', value: capRate, format: 'ratio' },
    { label: 'Sale Type', value: saleType, format: 'arrayZeroIndex' },
    { label: 'Buyer', value: formattedBuyers, format: 'string' },
    { label: 'Source', value: sourcedBy, format: 'string', customClass: classes.sourceSummaryEllipsis },
  ];

  return (
    <React.Fragment>
      <SummaryFieldStats stats={saleStats} />
    </React.Fragment>
  );
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
};

export const ExpandedCardContent = ({ item }) => {
  const classes = useStyles();
  const fieldNotes = item.fieldNotes || {};
  const {
    area,
    transactionValue,
    units,
    signedDate,
    soldDate,
    floors,
    virtualTourUrl,
    saleType,
    pricePerSquareFoot,
    averageUnitPrice,
    portfolioName,
    portfolioId,
    suite,
    monthsOnMarket,
    netOperatingIncome,
    capRate,
    sourcedBy
  } = item;

  const compInfo = [
    [
      { key: 'transactionValue', label: 'Sale Price', value: transactionValue, format: 'wholeCurrency' },
      { key: 'units', label: 'Number of Units', value: units, format: 'number' },
      { key: 'signedDate', label: 'Date Signed', value: signedDate, format: 'date' },
      { key: 'dateSold', label: 'Date Sold', value: soldDate, format: 'date' },
      { key: 'floors', label: 'Floors', value: floors, format: 'array' }
    ],
    [
      { key: 'pricePerSquareFoot', label: 'Price Per SF', value: pricePerSquareFoot, format: 'currency' },
      { key: 'averageUnitPrice', label: 'Average Price Per Unit', value: averageUnitPrice, format: 'currency' },
      { key: 'portfolioName', label: 'Portfolio Name', value: portfolioName, format: 'string' },
      { key: 'portfolioId', label: 'Portfolio ID', value: portfolioId, format: 'string' },
      { key: 'suite', label: 'Suite', value: suite, format: 'string' }
    ],
    [
      { key: 'netOperatingIncome', label: 'Net Operating Income', value: netOperatingIncome, format: 'wholeCurrency' },
      { key: 'capRate', label: 'Capitalization Rate', value: capRate, format: 'ratio' },
      { key: 'sourcedBy', label: 'Source', value: sourcedBy, format: 'string' },
      {
        key: 'virtualTourUrl',
        label: 'Virtual Tour URL',
        value: virtualTourUrl,
        format: 'uri',
        customClass: classes.spanTwoColumns
      }
    ]
  ];
  const compInfoSpecialTopRow = [
    [{ key: 'area', label: 'Area (SF)', value: area, format: 'number' }],
    [{ key: 'saleType', label: 'Sale Type', value: saleType, format: 'array' }],
    [{ key: 'monthsOnMarket', label: 'Months on Market', value: monthsOnMarket, format: 'number' }]
  ];

  const {
    buyers,
    buyerRepresentatives,
    buyerType,
    naicsCode,
    sicCode,
    sellers,
    sellerRepresentatives,
    sellerType,
    buyerPreviousLocation
  } = item;

  const contactInfoSpecialTopRow = [
    [
      { key: 'buyers', label: 'Buyers', value: buyers, format: 'signatories' },
      { key: 'buyerRepresentatives', label: 'Buyer Rep.', value: buyerRepresentatives, format: 'signatories' }
    ],
    [
      { key: 'sellers', label: 'Sellers', value: sellers, format: 'signatories' },
      { key: 'sellerRepresentatives', label: 'Seller Rep.', value: sellerRepresentatives, format: 'signatories' }
    ]
  ];
  const contactInfo = [
    [
      { key: 'buyerType', label: 'Buyer Type', value: buyerType, format: 'array' },
      { key: 'naicsCode', label: 'NAICS Code', value: naicsCode, format: 'string' },
      { key: 'sicCode', label: 'SIC Code', value: sicCode, format: 'string' }
    ],
    [
      { key: 'sellerType', label: 'Seller Type', value: sellerType, format: 'array' },
      { key: 'buyerPreviousLocation', label: 'Buyer Prior Location', value: buyerPreviousLocation, format: 'string' }
    ]
  ];

  const {
    isArmsLength,
    lenders,
    loanAmount,
    transactionFee,
    interestRateType,
    isPartialInterestSale,
    partialInterestRatio,
    partialInterestType,
    isDistressed,
    deedType,
    deedSubType
  } = item;

  const saleDetailsInfo = [
    [
      { key: 'isArmsLength', label: 'Arms Length Transaction', value: isArmsLength, format: 'boolean' },
      { key: 'lenders', label: 'Lenders', value: lenders, format: 'signatories' },
      { key: 'loanAmount', label: 'Loan Amount', value: loanAmount, format: 'currency' },
      { key: 'transactionFee', label: 'Transaction Fee', value: transactionFee, format: 'currency' },
      { key: 'interestRateType', label: 'Interest Rate Type', value: interestRateType, format: 'string' }
    ],
    [
      { key: 'isPartialInterestSale', label: 'Partial Interest Sale', value: isPartialInterestSale, format: 'boolean' },
      { key: 'partialInterestRatio', label: 'Partial Interest Ratio', value: partialInterestRatio, format: 'ratio' },
      { key: 'partialInterestType', label: 'Partial Interest Type', value: partialInterestType, format: 'string' }
    ],
    [
      { key: 'isDistressed', label: 'Distressed Property', value: isDistressed, format: 'boolean' },
      { key: 'deedType', label: 'Deed Type', value: deedType, format: 'string' },
      { key: 'deedSubType', label: 'Deed Sub-Type', value: deedSubType, format: 'string' }
    ]
  ];

  const {
    createdAt,
    updatedAt,
    ourDealsId,
    ourListingId,
    coStarId,
    customId,
    legacyId,
    lightboxId
  } = item;

  const [recordInfo, setRecordInfo] = useState([
    [
      { key: 'createdAt', value: moment(createdAt).format('MMM D, YYYY') || '--', label: 'Created At', format: 'date' },
      {
        key: 'usableLandArea',
        value: moment(updatedAt).format('MMM D, YYYY') || '--',
        label: 'Last Modified',
        format: 'date'
      }
    ],
    [
      { key: 'relatedIds', value: ' ', label: 'Related IDs', format: 'string' },
      { key: 'ourDeals', value: ourDealsId, label: 'Our Deals', format: 'string' },
      { key: 'ourListings', value: ourListingId, label: 'Our Listings', format: 'string' },
      { key: 'coStar', value: coStarId, label: 'CoStar', format: 'string' },
      { key: 'legacy', value: legacyId, label: 'Legacy', format: 'string' },
      { key: 'lightBox', value: lightboxId, label: 'LightBox', format: 'string' }
    ],
    [
      { key: 'customIds', value: ' ', label: 'Custom IDs', format: 'string' },
    ]
  ]);

  useEffect(() => {
    if (customId && customId.length > 0) {
      setRecordInfo((prevRecordInfo) => {
        const newRecordInfo = [...prevRecordInfo];
        customId.forEach((item) => {
          const customItem = { key: `customIds_${item.name}`, value: item.id, label: item.name, format: 'string' };
          newRecordInfo[2].push(customItem);
        });
        return newRecordInfo;
      });
    }
  }, []);

  const { documents, features, note, images } = item;
  return (
    <div className={classes.expandedView}>
      <Section
        title={'Comp Info'}
        sectionArray={compInfo}
        fieldNotes={fieldNotes}
        specialTopRowArray={compInfoSpecialTopRow} />
      {documents && documents.length > 0 && (
        <Documents documents={documents} />)}
      <Section
        title={'Contacts'}
        sectionArray={contactInfo}
        fieldNotes={fieldNotes}
        specialTopRowArray={contactInfoSpecialTopRow} />
      <Section
        title={'Sale Details'}
        fieldNotes={fieldNotes}
        sectionArray={saleDetailsInfo} />
      <SuiteInfo
        fieldNotes={fieldNotes}
        item={item} />
      <Features
        features={features}
        fieldNote={fieldNotes.features ? fieldNotes.features : null} />
      {note && note.length > 0 &&  (
        <Notes note={note} fieldNote={fieldNotes.note ? fieldNotes.note : null} />)}
      {images && images.length > 0 &&  (
        <Images images={images} fieldNote={fieldNotes.images ? fieldNotes.images : null} />)}
      <Section
        title={'Record Info'}
        sectionArray={recordInfo}
        fieldNotes={fieldNotes} />
    </div>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired
};

export const Subtitle = (item) => {
  return item.properties.length > 1 && (
    <div>
      <PortfolioIcon
        style={{
          height: '18px',
          marginRight: '3px',
          position: 'relative',
          top: '4px',
          width: '18px'
        }} />
      Portfolio Sale
    </div>
  );
};

Subtitle.propTypes = {
  item: PropTypes.object.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import datatypes from '../../common/datatypes';
import * as researchActions from '../actions';
import Searches from './Searches';
import * as actions from './actions';

export class SearchesContainer extends React.Component {
  static propTypes = {
    searches: PropTypes.object
  };

  render() {
    return <Searches {...this.props} />;
  }
}

function mapStateToProps({
  research: { 
    map, searches, selectedResults, showSelected, sourceFilter
  },
  auth
}) {
  return {
    currentUser: auth.currentUser,
    searchBounds: map?.bounds?.searchBounds,
    searches,
    selectedResults,
    showSelected,
    sourceFilter,
    datatype: datatypes[searches.activeFilter],
  };
}

export default withRouter(connect(mapStateToProps, { ...actions, ...researchActions })(SearchesContainer));

import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import PROPERTY_TYPES from '../constants/propertyTypes';

const PropertyTypesCheckboxGroup = ({ name, push, remove, form }) => {
  const value = form.values[name] || [];

  const onCheck = ({ currentTarget: { name } }) => {
    const indexOfValue = value.indexOf(name);
    if (indexOfValue > -1) {
      remove(indexOfValue);
    } else {
      push(name);
    }
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {PROPERTY_TYPES.map((type) => (
        <FormControlLabel
          key={type}
          control={
            <Checkbox
              color="primary"
              name={type}
              onChange={onCheck}
              checked={value.indexOf(type) > -1} />
          }
          label={type}
          style={{ flex: '1 0 50%', marginRight: 0 }} />
      ))}
    </div>
  );
};

PropertyTypesCheckboxGroup.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};

export default PropertyTypesCheckboxGroup;

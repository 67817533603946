import fileSaver from 'file-saver';
import {
  DISABLE_SELECT_MODE,
  REPORT_GENERATE_REQUEST,
  REPORT_GENERATE_INITIATED,
  REPORT_GENERATE_SUCCESS,
  REPORT_GENERATE_ERROR,
  REPORT_PREVIEW_REQUEST,
  REPORT_PREVIEW_SUCCESS,
  REPORT_PREVIEW_ERROR,
  DISMISS_REPORT_ERROR,
} from '../../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest,
  requestData,
  receiveData
} from '../../common/apiActions';
import api from '../../services/api';

export function disableSelectMode() {
  return {
    type: DISABLE_SELECT_MODE
  };
}

function mimeType({ fileExtension }) {
  /* Map for Different MIME types dependent on fileExtension; add filetype to map */
  const map = {
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    pdf: 'application/pdf'
  };
  return map[fileExtension];
}

export function fetchReportPreview({ options, reportType, reportId, ids, fileExtension }) {
  const currentMime = mimeType({ fileExtension });
  return (dispatch) => {
    dispatch(requestData(REPORT_PREVIEW_REQUEST, { reportType, ids }));
    return api.postBinary(`/report/${reportId}/build`, {
      type: reportType,
      ids: ids.join(','),
      options,
      preview: true
    })
      .then((data) => data.blob())
      .then((blob) => blob.slice(0, blob.size, currentMime))  // force MIME so doesn't download
      .then((blob) => window.URL.createObjectURL(blob))
      .then((dataUri) => {
        dispatch(receiveData(REPORT_PREVIEW_SUCCESS, { dataUri }));
        window.URL.revokeObjectURL(dataUri); // Prevents double download
      })
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return dispatch(handleError(REPORT_PREVIEW_ERROR, response));
      });
  };
}

export function fetchSingleReportPreview({ options, reportType, reportId, ids, fileExtension }) {
  const currentMime = mimeType({ fileExtension });
  return (dispatch) => {
    dispatch(requestData(REPORT_PREVIEW_REQUEST, { reportType, ids }));
    return api.postBinary(`/report/${reportId}/build`, {
      type: reportType,
      ids: [ids].join(','),
      options,
      preview: true
    })
      .then((data) => data.blob())
      .then((blob) => blob.slice(0, blob.size, currentMime))  // force MIME so doesn't download
      .then((blob) => window.URL.createObjectURL(blob))
      .then((dataUri) => {
        dispatch(receiveData(REPORT_PREVIEW_SUCCESS, { dataUri }));
        window.URL.revokeObjectURL(dataUri); // Prevents double download
      })
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return dispatch(handleError(REPORT_PREVIEW_ERROR, response));
      });
  };
}

function downloadFullReport(dispatch, id, title, fileExtension) {
  return api.readBinary(`/report/download/${id}/${fileExtension}`)
    .then((data) => {
      return data.blob();
    })
    .then((blob) => {
      fileSaver.saveAs(
        blob,
        `${title}.${fileExtension}`,
        { type: mimeType({ fileExtension }) }
      );
      return dispatch(receiveData(REPORT_GENERATE_SUCCESS, { id }));
    })
    .catch((response) => {
      if (response.status === 401) {
        return dispatch(unauthorizedRequest());
      }
      return dispatch(handleError(REPORT_GENERATE_ERROR, response));
    });
}

function watchFullReportStatus(dispatch, id, title, fileExtension) {
  return api.read(`/report/build/${id}/status/${fileExtension}`)
    .then((response) => {
      if (response.status === 'error') {
        return dispatch(handleError(REPORT_GENERATE_ERROR, response));
      } else if (response.status === 'ready') {
        return downloadFullReport(dispatch, id, title, fileExtension);
      }
      setTimeout(() => { watchFullReportStatus(dispatch, id, title, fileExtension); }, 3000);
    })
    .catch((response) => {
      if (response.status === 401) {
        return dispatch(unauthorizedRequest());
      }
      return dispatch(handleError(REPORT_GENERATE_ERROR, response));
    });
}

export function generateFullReport({ options, reportType, reportId, ids, title, fileExtension }) {
  return (dispatch) => {
    dispatch(requestData(REPORT_GENERATE_REQUEST, { reportType, ids }));
    return api.create(`/report/${reportId}/build`, {
      type: reportType,
      ids: ids.join(','),
      options,
      preview: false,
      fileExtension
    })
      .then((response) => {
        dispatch(receiveData(REPORT_GENERATE_INITIATED, response));
        watchFullReportStatus(dispatch, response.id, title, fileExtension);
      })
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }
        return dispatch(handleError(REPORT_GENERATE_ERROR, response));
      });
  };
}

export function dismissReportError() {
  return {
    type: DISMISS_REPORT_ERROR
  };
}

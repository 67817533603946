import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';
import ColumnsOrganizer from './ColumnsOrganizer';
import * as actions from './actions';

const Container = (props) => {
  const { datatype, fetchGridViews } = props;

  useEffect(() => {
    fetchGridViews(datatype);
  }, []);

  return <ColumnsOrganizer {...props} />;
};

Container.propTypes = {
  columns: PropTypes.array.isRequired,
  datatype: PropTypes.string.isRequired,
  fetchGridViews: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  previousPath: PropTypes.string.isRequired,
};

function mapStateToProps(
  { research: { searches, table }, routing },
  { location: { query: { currentListView } } }
) {
  const enabledSearches = pickBy(searches, (s) => s.isEnabled && s.filters);
  if (!enabledSearches[currentListView]) {
    currentListView = Object.keys(enabledSearches)[0];
  }
  const selectedGridView = table.columnsOrganizer.selectedGridViews ?
    table.columnsOrganizer.selectedGridViews[currentListView] :
    null;
  const urlRoutes = routing.locationBeforeTransitions;
  return {
    columns: table.columns[currentListView],
    datatype: currentListView,
    gridViews: table.columnsOrganizer.gridViews || [],
    previousPath: `/research/table/${urlRoutes.search}${urlRoutes.hash}`,
    selectedGridView
  };
}

export default connect(mapStateToProps, actions)(Container);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dimensions from 'react-dimensions';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import * as researchActions from '../actions';
import * as searchesActions from '../searches/actions';
import * as actions from './actions';
import Analytics from './Analytics';

class PropertyDetailContainer extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    selectedResults: PropTypes.array.isRequired,
    setSourceFilterSchema: PropTypes.func.isRequired
  };

  UNSAFE_componentWillMount() {
    this.props.setSourceFilterSchema('show');
  }

  render() {
    return <Analytics {...this.props} />;
  }
}

function mapStateToProps({ auth, research }, { location }) {
  const searches = pickBy(research.searches, (s) => s.isEnabled && s.filters);
  let { currentListView } = (location.query || 'lease');
  if (!searches[currentListView]) currentListView = Object.keys(searches)[0];
  const results = research.searches[currentListView].results || [];
  const selectedResults = research.selectedResults[currentListView] || [];  // (not using selected rows as a filter yet)

  return {
    isSearching: some(searches, 'isSearching'),
    currentUser: auth.currentUser,
    results,
    selectedResults,
    currentListView,
    isFetchingPdf: research.analytics.isFetchingPdf,
    pdfErrorMsg: research.analytics.pdfErrorMsg
  };
}

export default dimensions()(
  connect(
    mapStateToProps,
    { ...researchActions, ...searchesActions, ...actions }
  )(PropertyDetailContainer)
);

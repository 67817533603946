import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stats from '../../../common/stats/Stats';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const CardContent = ({ stats, item }) => {
  const classes = useStyles();
  const fieldNotes = item.fieldNotes || {};
  const filteredStats = stats.filter(({ key }) => {
    return (item[key] || fieldNotes[key]) && [
      '_type',
      'id',
      'canonicalName',
      'fieldNotes',
      'propertyId',
      'location',
      'rescourId',
      'createdAt',
      'property',
    ].indexOf(key) === -1;
  });

  return (
    <div>
      <section className={classes.stats}>
        <Stats schema={filteredStats} item={item} />
      </section>
    </div>
  );
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import styles from './Loading.css';

const Loading = ({ classes, message }) => {
  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <CircularProgress size={50} />
        <Typography variant="body2" style={{ marginTop: 16 }}>{message}</Typography>
      </div>
    </div>
  );
};

Loading.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.string
};

export default withStyles(styles)(Loading);

import {
  DETAIL_PROPERTY_FETCH_REQUEST,
  DETAIL_PROPERTY_FETCH_ERROR,
  DETAIL_PROPERTY_FETCH_SUCCESS,
  DETAIL_PROPERTY_CLEAR,
  DETAIL_ALL_NEARBY_FETCH_REQUEST,
  DETAIL_ALL_NEARBY_FETCH_ERROR,
  DETAIL_ALL_NEARBY_FETCH_SUCCESS,
  DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_REQUEST,
  DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_SUCCESS,
  DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_ERROR,
  DETAIL_DEMOGRAPHIC_REPORT_ENABLE_VIEW,
  DETAIL_DEMOGRAPHIC_REPORT_DISABLE_VIEW,
  DETAIL_DEMOGRAPHICS_REPORT_DOWNLOAD_REQUEST,
  DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_SUCCESS,
  DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_ERROR,
  DETAIL_COMMENT_SAVE_REQUEST,
  DETAIL_COMMENT_SAVE_SUCCESS,
  DETAIL_COMMENT_DELETE_SUCCESS,
  DETAIL_COMMENTS_FETCH_REQUEST,
  DETAIL_COMMENTS_FETCH_SUCCESS,
  FILTERED_QUERY_WITH_IDS_ERROR,
  FILTERED_QUERY_WITH_IDS_REQUEST,
  FILTERED_QUERY_WITH_IDS_SUCCESS,
  FILTERED_QUERY_WITH_IDS_QUERYING_COMPLETE,
  REPORT_SINGLE_GENERATE_REQUEST,
  REPORT_SINGLE_GENERATE_SUCCESS,
  REPORT_SINGLE_GENERATE_ERROR,
} from '../../constants/actionTypes';

const initialState = {
  allNearbyItems: {},
  comments: [],
  fetchError: null,
  files: [],
  filteredQueryItemsLease: [],
  filteredQueryItemsAvailability: [],
  filteredQueryItemsSale: [],
  filteredQueryItemsListing: [],
  filteredQueryItemsDevelopment: [],
  filteredQueryItemsCompany: [],
  filteredQueryItemsNews: [],
  hasFilteredQueryItemsLease: false,
  hasFilteredQueryItemsAvailability: false,
  hasFilteredQueryItemsSale: false,
  hasFilteredQueryItemsListing: false,
  hasFilteredQueryItemsDevelopment: false,
  hasFilteredQueryItemsCompany: false,
  hasFilteredQueryItemsNews: false,
  property: {},
  dataUri: null,
  isFetching: false,
  isFetchingContent: false,
  isFetchingFilteredQueryItems: false,
  isFetchingNearby: false,
  isFetchingDemographics: false,
  isViewReportDisabled: true,
  isFetchingPdf: false,
  isSavingComment: false,
  isFetchingComments: false,
  reportPdfError: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DETAIL_PROPERTY_FETCH_REQUEST:
      return {
        ...state,
        fetchError: null,
        property: {},
        dataUri: null,
        isFetching: true,
      };

    case DETAIL_PROPERTY_FETCH_ERROR:
      return {
        ...state,
        fetchError: action.error,
        isFetching: false,
      };

    case DETAIL_PROPERTY_FETCH_SUCCESS:
      return {
        ...state,
        fetchError: null,
        property: action.data,
        isFetching: false,
      };

    case DETAIL_PROPERTY_CLEAR:
      return {
        ...state,
        property: {},
        dataUri: null,
        allNearbyItems: {},
      };

    case DETAIL_ALL_NEARBY_FETCH_REQUEST:
      return {
        ...state,
        fetchError: null,
        allNearbyItems: {},
        isFetchingNearby: true,
        isFetchingContent: true
      };

    case DETAIL_ALL_NEARBY_FETCH_ERROR:
      return {
        ...state,
        fetchError: action.error,
        isFetchingNearby: false,
        isFetchingContent: state.isFetchingFilteredQueryItems
      };

    case DETAIL_ALL_NEARBY_FETCH_SUCCESS:
      const allAvailableNearbyItems = {};
      for (const item in action.data) {
        if (Array.isArray(action.data[item]) && action.data[item].length > 0) {
          allAvailableNearbyItems[item] = action.data[item];
        }
      }
      return {
        ...state,
        fetchError: null,
        isFetchingNearby: false,
        isFetchingContent: state.isFetchingFilteredQueryItems,
        allNearbyItems: allAvailableNearbyItems
      };

    case DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_REQUEST: 
      return {
        ...state,
        isFetchingDemographics: true,
        isViewReportDisabled: true
      };

    case DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_SUCCESS:
      return {
        ...state,
        isFetchingDemographics: false,
        isViewReportDisabled: true,
        dataUri: action.data.dataUri
      };

    case DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_ERROR:
      return {
        ...state,
        isFetchingDemographics: false,
        isViewReportDisabled: false
      };

    case DETAIL_DEMOGRAPHIC_REPORT_ENABLE_VIEW: {
      return {
        ...state,
        isViewReportDisabled: false
      };
    }

    case DETAIL_DEMOGRAPHIC_REPORT_DISABLE_VIEW: {
      return {
        ...state,
        isViewReportDisabled: true
      };
    }

    case DETAIL_DEMOGRAPHICS_REPORT_DOWNLOAD_REQUEST:
      return {
        ...state,
        isFetchingDemographics: true
      };

    case DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_SUCCESS: 
      return {
        ...state,
        isFetchingDemographics: false
      };

    case DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_ERROR:
      return {
        ...state,
        isFetchingDemographics: false
      };

    case DETAIL_COMMENT_SAVE_REQUEST:
      return {
        ...state,
        isSavingComment: true
      };

    case DETAIL_COMMENT_SAVE_SUCCESS:
      return {
        ...state,
        comments: state.comments.concat([action.data]),
        isSavingComment: false,
      };

    case DETAIL_COMMENT_DELETE_SUCCESS:
      return {
        ...state,
        files: state.comments.filter((comment) => comment.id !== action.data.id)
      };

    case DETAIL_COMMENTS_FETCH_REQUEST:
      return {
        ...state,
        comments: [],
        isFetchingComments: true,
      };

    case DETAIL_COMMENTS_FETCH_SUCCESS:
      return {
        ...state,
        comments: action.data,
        isFetchingComments: false,
      };

    case REPORT_SINGLE_GENERATE_REQUEST:
      return {
        ...state,
        isFetchingPdf: true,
        reportPdfError: false
      };

    case REPORT_SINGLE_GENERATE_SUCCESS:
      return {
        ...state,
        isFetchingPdf: false,
        reportPdfError: false
      };

    case REPORT_SINGLE_GENERATE_ERROR:
      return {
        ...state,
        isFetchingPdf: false,
        reportPdfError: true
      };
  
    case FILTERED_QUERY_WITH_IDS_REQUEST:
      return {
        ...state,
        filteredQueryItemsLease: [],
        filteredQueryItemsAvailability: [],
        filteredQueryItemsSale: [],
        filteredQueryItemsListing: [],
        filteredQueryItemsDevelopment: [],
        filteredQueryItemsCompany: [],
        filteredQueryItemsNews: [],
        hasFilteredQueryItemsLease: false,
        hasFilteredQueryItemsAvailability: false,
        hasFilteredQueryItemsSale: false,
        hasFilteredQueryItemsListing: false,
        hasFilteredQueryItemsDevelopment: false,
        hasFilteredQueryItemsCompany: false,
        hasFilteredQueryItemsNews: false,
        isFetchingFilteredQueryItems: true,
        isFetchingContent: true
      };

    case FILTERED_QUERY_WITH_IDS_SUCCESS:
      const datatype = action.datatype;
      const itemsReceived = action.data.hits.hits;
      const filteredQueryItemIds = itemsReceived.map((item) => item._id);
      const propertyDetailItems = state.property[datatype];
      const propertyDetailItemsFilterMatches = propertyDetailItems.filter((propertyDetailItem) =>
        filteredQueryItemIds.includes(propertyDetailItem.id)
      );
      let hasItems = false;
      if (itemsReceived.length) hasItems = true;
      return {
        ...state,
        [`hasFilteredQueryItems${datatype[0].toUpperCase()}${datatype.slice(1)}`]: hasItems,
        [`filteredQueryItems${datatype[0].toUpperCase()}${datatype.slice(1)}`]: propertyDetailItemsFilterMatches
      };

    case FILTERED_QUERY_WITH_IDS_QUERYING_COMPLETE:
      return {
        ...state,
        isFetchingFilteredQueryItems: false,
        isFetchingContent: state.isFetchingNearby
      };
      
    case FILTERED_QUERY_WITH_IDS_ERROR:
      return {
        ...state,
        fetchError: action.error,
        isFetchingFilteredQueryItems: false,
        isFetchingContent: state.isFetchingNearby
      };
  }

  return state;
}

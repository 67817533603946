import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import ClearIcon from '@material-ui/icons/Clear';
import IconArrowDown from '@material-ui/icons/KeyboardArrowDown';
import IconArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Stat from './stats/Stat';
import styles from './stats/module.css';

class Signatory extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    signatories: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]).isRequired,
  };

  constructor(props) {
    super(props);
    const dialogState = (props.signatories.length ? props.signatories : [props.signatories]).reduce((acc, sig) => {
      acc[sig.name] = false;
      return acc;
    }, {});
    this.state = {
      dialogState,
      expanded: false,
      popupOpen: false,
      popupPosition: { top: 0, left: 0 },
      linkVerticalOffset: 50,
      linkHorizontalOffset: 10
    };
  }

  toggleExpand = () => {
    return (this.state.expanded === true) ? this.setState({ expanded: false }) : this.setState({ expanded: true });
  };

  toggleDialog = (key) => (e) => {
    const linkPosition = e.target.getBoundingClientRect();
    this.setState({
      ...this.state,
      dialogState: {
        ...this.state.dialogState,
        [key]: !this.state.dialogState[key]
      },
      popupOpen: !this.state.popupOpen,
      popupPosition: {
        top: linkPosition.top - this.state.linkVerticalOffset,
        left: linkPosition.left + linkPosition.width + this.state.linkHorizontalOffset
      }
    });
  };

  formatSignatory({ email, name, phoneNumber, type }) {
    const { classes } = this.props;

    switch (type) {
      case 'Contact':
        return (
          <div key={name}>
            <Link onClick={this.toggleDialog(name)} underline="always" style={{ cursor: 'pointer' }}>
              {name}
            </Link>
            <Dialog
              onClose={this.toggleDialog(name)}
              open={this.state.dialogState[name]}
              PaperProps={{
                style: {
                  position: 'absolute',
                  left: `${this.state.popupPosition.left}px`,
                  top: `${this.state.popupPosition.top}px`,
                } }}>
              <DialogTitle>
                {name}
                <IconButton
                  onClick={this.toggleDialog(name)}
                  style={{ float: 'right', height: '32px', padding: 0, width: '32px' }}>
                  <ClearIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                {email && (
                  <Stat
                    className={classes.signatoryStat}
                    key={email}
                    label="Email"
                    value={<Link href={`mailto:${email}`} target={'_blank'}>{email}</Link>} />
                )}
                {phoneNumber && (
                  <Stat
                    className={classes.signatoryStat}
                    key={phoneNumber}
                    label="Phone Number"
                    value={<Link href={`tel:${phoneNumber}`} target={'_blank'}>{phoneNumber}</Link>} />
                )}
              </DialogContent>
            </Dialog>
          </div>
        );
      default:
        return <div key={name}>{name}</div>;
    }
  }
  render() {
    const { classes } = this.props;
    const signatories = this.props.signatories.length
      ? this.props.signatories
      : [this.props.signatories];
    return (
      <span>
        {signatories.slice(0, 4).map((sig) => this.formatSignatory(sig))}
        {signatories.length > 5 && this.state.expanded && (
          <span>{signatories.slice(5).map((sig) => this.formatSignatory(sig))}</span>
        )}
        {signatories.length > 5 && (
          <div className={classes.showAll}>
            <a onClick={this.toggleExpand}>
              {this.state.expanded ? 'Hide' : `Show All (${signatories.length})`}
              <IconButton>{this.state.expanded ? <IconArrowUp /> : <IconArrowDown />}</IconButton>
            </a>
          </div>
        )}
      </span>
    );
  }
}

export default withStyles(styles)(Signatory);

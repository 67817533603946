import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';

const format = function (key, value, objectFormat) {
  if (!objectFormat || !objectFormat[key] || !value) return value;
  if (objectFormat[key]) {
    switch (objectFormat[key]) {
      case 'currency':
        return numeral(value).format('$0,0.00[0]');
      default:
        return value;
    }
  }
};

const MinMax = ({ objectFormat, value: { min, max } }) => {
  const _min = format('min', min, objectFormat);
  const _max = format('max', max, objectFormat);
  if (min && max) return <div>{_min} - {_max}</div>;
  return <div>{_min || _max}</div>;
};

MinMax.propTypes = {
  objectFormat: PropTypes.object,
  value: PropTypes.any
};

export default MinMax;

import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import mammoth from 'mammoth/mammoth.browser';
import find from 'lodash/find';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import debounce from 'lodash/debounce';
import { history } from '../../../src/store';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SortAscIcon from '@material-ui/icons/ArrowDropUp';
import SortDescIcon from '@material-ui/icons/ArrowDropDown';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import ReportSettingsIcon from '@material-ui/icons/Settings';
import NavigationCloseIcon from '@material-ui/icons/Close';
import ReloadIcon from '@material-ui/icons/Refresh';
import SaveDocx from '@material-ui/icons/CloudDownload';
import SaveIcon from '@material-ui/icons/PictureAsPdf';
import SortIcon from '@material-ui/icons/Sort';
import { COLLIERS_YELLOW } from '../../styles/theme';
import NotificationMessage from '../../common/NotificationMessage';
import { MAX_REPORT_SIZE } from '../constants';
import styles from './module.css';

const warnColor = COLLIERS_YELLOW;

const SortOptions = ({ onClick, options, pinSelected, sortBy, sortDirection }) => {
  const menuItems = [];
  if (pinSelected) {
    const selected = options.find(({ key }) => key === sortBy);
    if (selected) {
      menuItems.push(
        <MenuItem button={false} key="_pinned">
          <ListItemText primary={selected.title} />
          <ListItemSecondaryAction>
            <ButtonGroup size="small" variant="contained">
              <Tooltip title="Sort ascending" placement="left">
                <Button
                  color={sortDirection === 'asc' ? 'primary' : 'default'}
                  onClick={onClick(sortBy, 'asc')}>
                  <SortAscIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="Sort descending" placement="right">
                <Button
                  color={sortDirection === 'desc' ? 'primary' : 'default'}
                  onClick={onClick(sortBy, 'desc')}>
                  <SortDescIcon fontSize="small"  />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </ListItemSecondaryAction>
        </MenuItem>
      );
      menuItems.push(<Divider key="_divider" />);
    }
  }

  return [
    ...menuItems,
    ...options
      .filter(({ key }) => !(pinSelected && sortBy === key))
      .map(({ key, title }) => (
        <MenuItem button={false} key={key}>
          <ListItemText primary={title} style={{ paddingRight: 50 }} />
          <ListItemSecondaryAction>
            <ButtonGroup
              disableElevation={sortBy !== key}
              size="small"
              variant={sortBy === key ? 'contained' : 'outlined'}>
              <Tooltip title="Sort ascending" placement="left">
                <Button
                  color={sortBy === key && sortDirection === 'asc' ? 'primary' : 'default'}
                  onClick={onClick(key, 'asc')}>
                  <SortAscIcon fontSize="small" />
                </Button>
              </Tooltip>
              <Tooltip title="Sort descending" placement="right">
                <Button
                  color={sortBy === key && sortDirection === 'desc' ? 'primary' : 'default'}
                  onClick={onClick(key, 'desc')}>
                  <SortDescIcon fontSize="small"  />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </ListItemSecondaryAction>
        </MenuItem>
      ))
  ];
};
SortOptions.propTypes = {
  onClick: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  pinSelected: PropTypes.bool,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.string
};

class Reports extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    columns: PropTypes.array.isRequired,
    containerHeight: PropTypes.number.isRequired,
    currentListView: PropTypes.string,
    currentUser: PropTypes.object.isRequired,
    disableSelectMode: PropTypes.func.isRequired,
    dismissReportError: PropTypes.func.isRequired,
    fetchReportPreview: PropTypes.func.isRequired,
    fetchSingleReportPreview: PropTypes.func.isRequired,
    generateFullReport: PropTypes.func.isRequired,
    isBuildingFullReport: PropTypes.bool.isRequired,
    isFetchingPreviewReport: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    previewReportDataUri: PropTypes.string,
    reportGenerationError: PropTypes.string,
    reportRecord: PropTypes.object,
    results: PropTypes.array.isRequired,
    router: PropTypes.object.isRequired,
    selectedReport: PropTypes.string,
    singlePrint: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.previewReportContainerRef = createRef();
    this.docxReportRef = createRef();
    this.state = {
      isDialogOpen: false,
      locationStack: [],
      needsRegeneration: false,
      sortKey: null,
      sortDirection: 'asc',
      sortMenuAnchor: null,
      reportOptions: this.getDefaultReportOptions(),
      reportOptionsForm: {},
      displayBrokers: true,
      fileExtension: this.getFileExtension()
    };
  }

  componentDidMount = () => {
    if (this.props.results.length > 0) this.generatePdf();
    const { location } = this.props;
    this.unlisten = history.listen((location) => {
      this.setState((prevState) => ({
        locationStack: [...prevState.locationStack, location],
      }));
    });
    this.setState({ locationStack: [location] });
  };

  componentDidUpdate = (prevProps) => {
    if (this.docxReportRef
      && this.previewReportContainerRef.current
      && this.previewReportContainerRef.current.contains(this.docxReportRef)) {
      this.docxReportRef.destroy();
    }
    // if report type changed, reset report sort order and regenerate
    if (prevProps.selectedReport !== this.props.selectedReport) {
      return this.setState({
        reportOptions: this.getDefaultReportOptions(),
        sortKey: null,
        sortDirection: 'asc',
        fileExtension: this.getFileExtension()
      }, () => this.generatePdf());
    }

    // if results changed, then mark as dirty but don't auto-reload
    if (prevProps.results.map((x) => x._id).join(',') !== this.props.results.map((x) => x._id).join(',') 
      && !this.props.singlePrint) {
      this.setState({ needsRegeneration: true });
    }
  };

  componentWillUnmount() {
    this.unlisten();
  }

  getFileExtension = () => { 
    const foundMatch = this.props.currentUser.reports.find((report) => {
      const matchedReport = report.id === this.props.selectedReport;
      return matchedReport;
    });
    return foundMatch.fileExtension;
  };

  handleReportOptionChange = (k) => (event) => {
    const value = Object.keys(event.currentTarget).includes('checked')
      ? event.currentTarget.checked
      : event.currentTarget.value;
    this.setState({
      reportOptionsForm: {
        ...this.state.reportOptionsForm,
        [k]: value
      },
      fileExtension: this.getFileExtension()
    });
  };

  handleReportOptionSortChange = (sortKey, sortDirection) => () => {
    this.setState({
      reportOptionsForm: {
        ...this.state.reportOptionsForm,
        sortKey,
        sortDirection
      },
      sortMenuAnchor: null
    });
  };

  handleDialogOpen = () => {
    this.setState({
      isDialogOpen: true,
      reportOptionsForm: {
        ...this.state.reportOptions,
        sortKey: this.state.sortKey,
        sortDirection: this.state.sortDirection,
      }
    });
  };

  handleDialogCancel = () => {
    this.setState({ isDialogOpen: false });
  };

  handleDialogSave = () => {
    this.setState({
      reportOptions: { ...this.state.reportOptionsForm },
      sortKey: this.state.reportOptionsForm.sortKey,
      sortDirection: this.state.reportOptionsForm.sortDirection,
      isDialogOpen: false
    }, () => this.generatePdf());
  };

  getDefaultReportOptions = () => {
    const currentReport = this.getCurrentReport(this.props.selectedReport);
    const form = {};
    if (currentReport.features) {
      Object.keys(currentReport.features).forEach((k) => {
        form[k] = currentReport.features[k].default || '';
      });
    }
    return form;
  };

  toggleDisplayBrokers = (e, value) => this.setState({ displayBrokers: value }, () => this.generatePdf());

  handleGoBack = debounce(() => {
    const { locationStack } = this.state;
    if (locationStack.length > 0) {
      history.go(-locationStack.length);
    }
  }, 100);

  close = () => {
    this.handleGoBack();
  };

  containsPortfolio = (items = []) => (!isNil(items.find(({ _source: { properties = [] } }) =>
    (properties.length > 1))));

  getType = () => this.props.currentListView;

  generatePdf = ({ preview = true } = {}) => {
    const { results, selectedReport, singlePrint } = this.props;
    const { sortKey, sortDirection, fileExtension } = this.state;
    if (!results || results.length === 0) return;
    this.setState({ needsRegeneration: false, fileExtension: this.getFileExtension() });
    
    const ids = results.map(({ _id }) => _id);
    const sortOptions = (sortKey) ? { sort: { [sortKey]: sortDirection } } : {};

    if (singlePrint) {
      this.props.fetchSingleReportPreview({
        ids,
        options: { ...this.state.reportOptions, ...sortOptions },
        reportType: this.getType(),
        reportId: selectedReport,
        fileExtension
      });
    } else if (preview) {
      this.props.fetchReportPreview({
        ids,
        options: { ...this.state.reportOptions, ...sortOptions },
        reportType: this.getType(),
        reportId: selectedReport,
        fileExtension
      });
    // else, send all ids and poll for "ready"
    } else {
      this.props.generateFullReport({
        ids,
        options: { ...this.state.reportOptions, ...sortOptions },
        preview,
        reportType: this.getType(),
        reportId: selectedReport,
        fileExtension,
        title: get(this.getCurrentReport(this.props.selectedReport), 'title', 'colliers-discover-report'),
        results
      });
    }
  };

  dismissReportError = () => {
    this.props.dismissReportError();
  };

  getCurrentReport = (reportId) => {
    if (!reportId) return '';
    return find(this.props.currentUser.reports, { id: reportId });
  };

  onSortChange = () => this.generatePdf();

  handleSelectSort = (sortKey, sortDirection) => () => {
    this.setState({
      sortKey,
      sortDirection,
      sortMenuAnchor: null
    }, () => this.onSortChange());
  };

  closeSortMenu = () => {
    this.setState({ sortMenuAnchor: null });
  };

  openSortMenu = (event) => {
    this.setState({ sortMenuAnchor: event.currentTarget });
    // when this is triggered by focus event, need to blur (else, will end up
    // in loop when close menu and focus is passed back to currentTarget)
    event.currentTarget.blur();
  };

  /* For PDF Report Previews */
  getReportIframe = (previewReportDataUri) => `
    <iframe
      src="${previewReportDataUri || 'about:blank'}#toolbar=0"
      type="application/pdf"
      width="100%" height="100%" style="overflow: auto;" />
  `;

  generateFullReport = () => {
    this.generatePdf({ preview: false });
  };

  /* FileViewer Component dependent on fileExtension of Report */
  previewType = (fileExtension, previewReportDataUri, classes, needsRegeneration) => {
    if (fileExtension === 'docx') {
      this.docxReportRef = createRef();
      const jsonFile = new XMLHttpRequest();
      jsonFile.open('GET', previewReportDataUri, true);
      jsonFile.send();
      jsonFile.responseType = 'arraybuffer';
      jsonFile.onreadystatechange = () => {
        if (jsonFile.readyState === 4 && jsonFile.status === 200) {
          mammoth.convertToHtml(
            { arrayBuffer: jsonFile.response },
            { includeDefaultStyleMap: false },
          )
            .then((result) => {
              const docEl = document.createElement('div');
              docEl.className = 'document-container';
              docEl.innerHTML = result.value;
              document.getElementById('docx').innerHTML = docEl.outerHTML;
            })
            .catch(() => {
              const docErrorEl = document.createElement('p');
              docErrorEl.className = 'document-error';
              docErrorEl.innerHTML = 'Something went wrong with the preview, please try downloading the report.';
              document.getElementById('docx').innerHTML = docErrorEl.outerHTML;
            })
            .done();
        }
      };
      return (
        <div className={classes.docxPageWrapper} id="docx" ref={this.docxReportRef} />
      );
    }
    return (
      <div
        className={cx({ [classes.embed]: 1, [classes.staleEmbed]: needsRegeneration })}
        dangerouslySetInnerHTML={{ __html: this.getReportIframe(previewReportDataUri) }} /> // eslint-disable-line
    );
  };
  render = () => {
    const {
      classes,
      columns,
      containerHeight,
      isBuildingFullReport,
      isFetchingPreviewReport,
      selectedReport,
      reportGenerationError,
      results,
      previewReportDataUri
    } = this.props;
    const { needsRegeneration, sortKey, sortDirection, fileExtension } = this.state;

    const currentReport = this.getCurrentReport(selectedReport);
    const reportFeatures = currentReport.features || {};
    const reportName = (currentReport || {}).title;
    const disableActions = needsRegeneration || isFetchingPreviewReport || isBuildingFullReport;
    const isPortfolio = this.containsPortfolio(results);
    const sortOptions = [
      { key: null, title: 'As Selected' },
      ...columns.filter((c) => c.sortable).map(({ sortKey, key, title }) => ({
        key: sortKey || key,
        title
      }))
    ];
    const curSortTitle = (sortOptions.find(({ key }) => key === this.state.reportOptionsForm.sortKey) || {}).title;
    return (
      <Paper elevation={1} className={classes.container} style={{ height: containerHeight }}>
        <AppBar elevation={0} position="static">
          <Toolbar style={{ paddingRight: 8 }}>
            <Typography style={{ flex: 1 }} variant="h5">
              Report Builder - {reportName}
            </Typography>
            <div style={{ color: 'white' }}>
              {results.length > 1 && (
                <span>
                  <Tooltip title="Sort by...">
                    <IconButton color="inherit" disabled={disableActions} onClick={this.openSortMenu}>
                      <SortIcon />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    anchorEl={this.state.sortMenuAnchor}
                    keepMounted
                    onClose={this.closeSortMenu}
                    open={this.state.sortMenuAnchor && !this.state.isDialogOpen}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    targetOrigin={{ horizontal: 'right', vertical: 'top' }}
                    MenuListProps={{ style: { maxHeight: 360, padding: 0 } }}>
                    <SortOptions
                      onClick={this.handleSelectSort}
                      options={sortOptions}
                      pinSelected={true}
                      sortBy={sortKey}
                      sortDirection={sortDirection} />
                  </Menu>
                </span>
              )}
              {Object.keys(reportFeatures).length > 0 && (
                <Tooltip title="Customize Options">
                  <IconButton color="inherit" disabled={disableActions} onClick={this.handleDialogOpen}>
                    <ReportSettingsIcon />
                  </IconButton>
                </Tooltip>
              )}
              {isBuildingFullReport && (
                <Tooltip title="Generating Full Report...">
                  <IconButton color="inherit">
                    <CircularProgress color="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              {!isBuildingFullReport && results.length > 0 && (
                <Tooltip title="Save Full Report">
                  <IconButton color="inherit" disabled={disableActions} onClick={this.generateFullReport}>
                    {fileExtension === 'docx' ? <SaveDocx /> : <SaveIcon />}
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Close">
                <IconButton color="inherit" disabled={isBuildingFullReport} onClick={this.close}>
                  <NavigationCloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        {results.length > 0 || this.props.singlePrint ? (
          <div className={classes.report} style={{ height: containerHeight - 64 }}>
            <Typography style={{ fontSize: '12px', lineHeight: 2, fontWeight: 'bold', textAlign: 'center' }}>
              Previews are limited to the first 10 pages. Download the {fileExtension} to view the entire report.
              {isPortfolio && (
                <span style={{ display: 'block' }}>
                  <ErrorIcon style={{ marginRight: 4, verticalAlign: 'top' }} htmlColor={warnColor} />
                  One or more selected items is a portfolio.
                </span>
              )}
            </Typography>
            {isFetchingPreviewReport && fileExtension ? (
              <CircularProgress color="primary" size={80} />
            ) : (
              this.previewType(fileExtension, previewReportDataUri, classes, needsRegeneration)
            )}
            {needsRegeneration && (
              <div className={classes.regenOverlay} onClick={this.generatePdf}>
                <Button
                  color="primary"
                  startIcon={<ReloadIcon />}
                  variant="contained">
                  Reload Preview
                </Button>
              </div>
            )}
          </div>
        ) : (
          <Typography className={classes.noResults}>
            Select up to {MAX_REPORT_SIZE} items in the list view to the right to generate a printable report.
            The report will be sorted in the same order as the list view.
            When you&apos;re finished click the &#34;Printer&#34; button at the top of the list to print or save as PDF.
          </Typography>
          
        )}

        <Dialog
          disableBackdropClick
          onClose={this.handleDialogCancel}
          open={this.state.isDialogOpen}>
          <DialogTitle>Customization Options</DialogTitle>
          <DialogContent style={{ paddingBottom: 24, paddingTop: 24, width: 400, maxWidth: '100%' }}>
            {Object.keys(reportFeatures).map((featureKey) => {
              const { type, title } = reportFeatures[featureKey];
              return (
                <div key={featureKey} className={classes.reportFeatureRow}>
                  {type === 'boolean' && (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          onChange={this.handleReportOptionChange(featureKey)}
                          checked={this.state.reportOptionsForm[featureKey]} />
                      )}
                      label={title} />
                  )}
                  {type === 'text' && (
                    <TextField
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      label={title}
                      onChange={this.handleReportOptionChange(featureKey)}
                      style={{ marginTop: -16 }}
                      value={this.state.reportOptionsForm[featureKey]} />
                  )}
                </div>
              );
            })}
            <div className={classes.reportFeatureRow}>
              <TextField
                fullWidth
                label="Sort By"
                onFocus={this.openSortMenu}
                value={`${curSortTitle} (${this.state.reportOptionsForm.sortDirection})`} />
              <Menu
                anchorEl={this.state.sortMenuAnchor}
                keepMounted
                onClose={this.closeSortMenu}
                open={this.state.sortMenuAnchor && this.state.isDialogOpen}
                MenuListProps={{ style: { maxHeight: 360, padding: 0 } }}>
                <SortOptions
                  onClick={this.handleReportOptionSortChange}
                  options={sortOptions}
                  pinSelected={true}
                  sortBy={this.state.reportOptionsForm.sortKey}
                  sortDirection={this.state.reportOptionsForm.sortDirection} />
              </Menu>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.handleDialogSave} variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          autoHideDuration={6000}
          message={<NotificationMessage text={reportGenerationError} type="error" />}
          onClose={this.dismissReportError}
          open={(reportGenerationError || '').length > 0} />
      </Paper>
    );
  };
}

export default withStyles(styles)(Reports);

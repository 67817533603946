import { grey } from '@material-ui/core/colors';

export default (theme) => ({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    ['& > button:not(:first-child)']: {
      marginLeft: 16,
    }
  },

  arrow: {
    'position': 'absolute',
    '&::before': { 
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: '8px solid #fff',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      position: 'absolute',
      zIndex: 100,
      top: '-12px',
      left: '50%',
      transform: 'translate(-50%, 50%)'
    },
  },

  contactsWrapper: {
    padding: theme.spacing(),
    marginBottom: theme.spacing(),
    ['& > div:not(:first-child)']: {
      borderTop: '1px solid #e0e0e0',
    },
  },

  curateEditIcon: {
    paddingRight: '12px',
    marginBottom: theme.spacing(1.0),
    color: '#919191',
    ['&:hover']: {
      color: '#787878'
    }
  },

  detailInfo: {
    flex: 1,
    marginLeft: theme.spacing(2.5),
    maxWidth: 400,
  },

  documentRow: {
    alignItems: 'center',
    display: 'flex',
    margin: `${theme.spacing()}px 0`
  },

  documentTitle: {
    flex: 1,
    fontSize: '16px',
    paddingLeft: 6
  },
  sourceSummaryEllipsisPopover: {
    lineHeight: 'normal',
    marginTop: -1,
    position: 'absolute !important',
    left: 0,
  },
  sourceSummaryEllipsisContent: {
    fontSize: 14,
    maxWidth: 400,
    padding: `${theme.spacing(2)}px`,
  },
  sourceSummaryEllipsis: {
    fontWeight: 300,
    fontSize: theme.spacing(2.75),
    color: '#254091',
    alignSelf: 'center',
    [theme.breakpoints.down(1280)]: {
      maxWidth: '70px',
    },
    [theme.breakpoints.down(1440)]: {
      maxWidth: '40px',
    },
    [theme.breakpoints.down(1500)]: {
      fontSize: '16px',
      maxWidth: '170px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up(1440)]: {
      maxwidth: '300px',
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: '350px'
    }
  },
  sourceSummaryEllipsisLease: {
    fontWeight: 300,
    fontSize: theme.spacing(2.75),
    color: '#254091',
    alignSelf: 'center',
    [theme.breakpoints.down(1280)]: {
      maxWidth: '30px',
    },
    [theme.breakpoints.down(1500)]: {
      fontSize: '16px'
    },
    [theme.breakpoints.down(1550)]: {
      maxWidth: '40px',
    },
    [theme.breakpoints.down(1700)]: {
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up(1700)]: {
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  },

  spanTwoColumns: {
    width: '300%',
    color: '#000000',
    font: 'Open Sans',
    fontSize: theme.spacing(2),
    fontWeight: 400,
    [theme.breakpoints.down(1500)]: {
      width: '100%'
    }
  },
  spanThreeColumns: {
    color: '#000000',
    font: 'Open Sans',
    fontSize: theme.spacing(2),
    fontWeight: 400,
    [theme.breakpoints.down(1500)]: {
      width: '100%'
    }
  },

  expandedView: {
    lineHeight: 2,

    ['& section']: {
      boxShadow: 'none !important',
      margin: '0 15px',
      padding: 15,

      ['&:last-child']: {
        border: 0,
        marginBottom: 50,
      },
      ['& thead']: {
        border: '0 !important',
      },
      ['& th']: {
        color: 'black !important',
        fontWeight: 'bold !important',
        height: 'auto !important',
        textTransform: 'uppercase',
      },

      ['& h3']: { // TODO: ...
        color: grey[400],
        margin: 0,
        textTransform: 'uppercase',
      }
    }
  },

  generalInfo: {
    flex: 1,
    ['& h2']: {
      color: grey[500],
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '18px',
      marginBottom: 30,
      textTransform: 'uppercase',
    }
  },

  ourDealsLogo: {
    width: '60px',
    marginRight: '10px',
  },

  genericWrapper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(),
    ['& header']: {
      color:grey[500],
      textTransform: 'uppercase',
      fontWeight: 500,
    }
  },

  imageGallery: {
    maxWidth: 560
  },

  leaseStat: {
    display: 'flex',
    margin: 0,
    padding: '0 5px',
    borderBottom: `1px solid ${grey[300]}`,

    ['&:last-child']: {
      border: 0
    },

    ['& strong']: {
      flex: '1 1 0%',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '35px',
      textTransform: 'uppercase',
    },

    ['& span']: {
      color: grey[800],
      flex: '3 1 0%',
      fontSize: '14px',
      lineHeight: '35px',
      textAlign: 'right',
    }
  },

  newsStat: {
    fontSize: '15px',
    flex: '1 1 0%',
    paddingLeft: theme.spacing(4),
    ['& span']: {
      lineHeight: '35px',
    }
  },

  newsStats: {
    display: 'flex',
    flex: '2 1 0%',
    flexDirection: 'column'
  },

  newsThumbnail: {
    flex: '1 1 0%',
    justifyXontent: 'center',
    position: 'relative',

    ['& img']: {
      height: 120,
      right: 10,
      position: 'absolute',
      top: -60,
      width: 120,
    }
  },

  printArrow: {
    'position': 'absolute',
    '&::before': { 
      content: '""',
      display: 'block',
      width: 0,
      height: 0,
      borderLeft: '7px solid transparent',
      borderRight: '7px solid transparent',
      borderBottom: '8px solid #fff',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      position: 'absolute',
      zIndex: 100,
      transform: 'translate(-50%, 50%)'
    },
  },

  quickPrint: {
    height: '12px',
    paddingBottom: '12px',
    color: '#919191',
    opacity: .66,
    ['&:hover']: {
      color: '#787878'
    },
    [theme.breakpoints.down(1500)]: {
      left: '30% !important',
    },
    [theme.breakpoints.down(1400)]: {
      left: '30% !important',
    },
    [theme.breakpoints.down(1200)]: {
      left: '30% !important',
    },
    [theme.breakpoints.down(1100)]: {
      left: '30% !important',
    },
    [theme.breakpoints.down(1020)]: {
      left: '30% !important',
    },
    [theme.breakpoints.down(1000)]: {
      top: '5% !important',
      left: '30% !important',
    },
    [theme.breakpoints.down(900)]: {
      top: '7%',
      left: '30% !important',
    },
  },

  reportButtonToolTipContainer: {
    'width': '300px',
    'overflow': 'visible',
    'backgroundColor': theme.palette.background.paper,
    'zIndex': 100,
    'borderRadius': '5px',
    'filter': 'drop-shadow(0 2px 2px rgba(0, 0, 0, .3))',
    '&[x-placement*="bottom"] $printArrow': {
      'top': 0,
      'left': '285px !important',
      'marginTop': '-0.375em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $printArrow': {
      'bottom': 0,
      'left': 0,
      'marginBottom': '-0.375em',
      '&::before': {
        top: '-12px',
        left: '-6px',
        borderBottom: 'unset',
        borderTop: '8px solid #fff',
      }
    }
  },

  reportMenuTitles: {
    '& div': {
      'marginLeft': '-9.5% !important'
    },
    '& div:nth-child(3)': {
      boxShadow: 
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 0px 2px rgba(0,0,0,0.12)',
      marginTop: '3em !important',
      [theme.breakpoints.down(1700)]: {
        marginLeft: '-9% !important',
      },
      [theme.breakpoints.down(1600)]: {
        marginLeft: '-11% !important',
      },
      [theme.breakpoints.down(1500)]: {
        marginLeft: '-12% !important',
      },
      [theme.breakpoints.down(1400)]: {
        marginLeft: '-12% !important',
      },
      [theme.breakpoints.down(1300)]: {
        marginLeft: '-13% !important',
      },
      [theme.breakpoints.down(1200)]: {
        marginLeft: '-14% !important',
      },
      [theme.breakpoints.down(1100)]: {
        marginLeft: '-16% !important',
      },
      [theme.breakpoints.down(1000)]: {
        marginLeft: '-17% !important',
      },
      [theme.breakpoints.down(900)]: {
        marginLeft: '-18% !important',
      },
      [theme.breakpoints.down(800)]: {
        marginLeft: '-1% !important',
      },
      [theme.breakpoints.down(700)]: {
        marginLeft: '-1% !important',
        height: '119.5',
        width: '206px'
      },
      [theme.breakpoints.down(600)]: {
        marginLeft: '2% !important',
        height: '119.5',
        width: '206px'
      },
      [theme.breakpoints.down(500)]: {
        marginLeft: '2% !important',
        height: '119.5',
        width: '206px'
      },
    }
  },

  reportMenuTitlesLarge: {
    '& div': {
      'marginLeft': '-12% !important'
    },
    '& div:nth-child(3)': {
      boxShadow: 
      '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 0px 2px rgba(0,0,0,0.12)',
      marginTop: '3em !important',
      [theme.breakpoints.down(1700)]: {
        marginLeft: '-13% !important',
      },
      [theme.breakpoints.down(1600)]: {
        marginLeft: '-14% !important',
      },
      [theme.breakpoints.down(1500)]: {
        marginLeft: '-15% !important',
      },
      [theme.breakpoints.down(1400)]: {
        marginLeft: '-16% !important',
      },
      [theme.breakpoints.down(1300)]: {
        marginLeft: '-17% !important',
      },
      [theme.breakpoints.down(1200)]: {
        marginLeft: '-18% !important',
      },
      [theme.breakpoints.down(1100)]: {
        marginLeft: '-20% !important',
      },
      [theme.breakpoints.down(1000)]: {
        marginLeft: '-22% !important',
      },
      [theme.breakpoints.down(900)]: {
        marginLeft: '-24% !important',
      },
      [theme.breakpoints.down(800)]: {
        marginLeft: '-1% !important',
      },
      [theme.breakpoints.down(700)]: {
        marginLeft: '-1% !important',
        height: '119.5',
        width: '206px'
      },
      [theme.breakpoints.down(600)]: {
        marginLeft: '2% !important',
        height: '119.5',
        width: '206px'
      },
      [theme.breakpoints.down(500)]: {
        marginLeft: '2% !important',
        height: '119.5',
        width: '206px'
      },
    }
  },
  
  reportProblemButton: {
    marginTop: -2,
    ['& > span:first-child']: {
      fontSize: 12,
      fontWeight: 'normal',
      textTransform: 'none',
    }
  },

  rentScheduleHeader: {
    color: '#707070',
    fontSize: 16,
    fontWeight: 400
  },

  sectionHeader: {
    color: '#253F8E',
    fontSize: theme.spacing(3),
    fontFace: 'Open Sans Semibold',
    lineHeight: 2,
    margin: 0,
    width: 'fit-content'
  },

  sectionPaper: {
    padding: theme.spacing(1, 1.5, 3, 1.5)
  },

  tripleColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down(1500)]: {
      gridTemplateColumns: '1fr',
      width: '100%',
    },
  },

  specialTitleWrapper: {
    marginLeft: theme.spacing(1)
  },

  specialTitleLabel: {
    fontWeight: 400,
    color: '#707070'
  },

  specialTitleValue: {
    fontWeight: 400,
    color: 'black'
  },

  title: {
    color: 'black',
    textDecoration: 'none',
  },

  ['@media (max-width: 600px)']: {
    detailInfo: {
      marginLeft: 0,
      marginTop: 20
    },
    expandedView: {
      flexDirection: 'column',
    },
    newsStat: {
      ['& svg']: {
        display: 'none !important',
      }
    },
    newsThumbnail: {
      display: 'none',
    }
  },
});

/* eslint-disable camelcase */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { List, Checkbox, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

export const FeatureMetaList = (props) => {
  const { featureMeta, fetchGISFeature, featureContent, removeFeature, leafletMap } = props;
  const [checked, setChecked] = React.useState([...featureContent.map((poi) => poi.id)]);

  const getBoundary = (value) => {
    if (featureContent.find((poi) => poi.id === value)) {
      return removeFeature(value);
    }
    const { _northEast, _southWest } = leafletMap.getBounds();
    const boundary = {
      xleft: _southWest.lng,
      xright: _northEast.lng,
      ybottom: _southWest.lat,
      ytop: _northEast.lat
    };
    const params = new URLSearchParams({ ...boundary });
    fetchGISFeature(`${value}?${params}`, value, 'POINT');
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked([...featureContent.map((poi) => poi.id)]);
    getBoundary(value);
  };

  useEffect(() => {
    setChecked([...featureContent.map((poi) => poi.id)]);
  }, [featureContent.length]);

  return (<List >
    {featureMeta &&
      featureMeta.length &&
      featureMeta.filter((feature) => feature.description.includes('points-of-interest')).map(({ id, name }) => {
        const labelId = `checkbox-list-label-${id}`;
        const label = name.split(' ').map((text) => text.charAt(0).toUpperCase() + text.slice(1)).join(' ');
        return (
          <ListItem
            key={id}
            role={undefined}
            dense
            button
            onClick={handleToggle(id)}>
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked.indexOf(id) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': labelId }} />
            </ListItemIcon>
            <ListItemText id={labelId} primary={`${label}`} />
          </ListItem>
        );
      })}
  </List>);
};

FeatureMetaList.propTypes = {
  featureContent: PropTypes.array,
  featureMeta: PropTypes.array,
  featureType: PropTypes.string.isRequired,
  fetchGISFeature: PropTypes.func.isRequired,
  leafletMap: PropTypes.object.isRequired,
  removeFeature: PropTypes.func.isRequired,
};
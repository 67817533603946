import React from 'react';
import PropTypes from 'prop-types';
import {
  SortableHandle as sortableHandle,
  SortableContainer as sortableContainer,
  SortableElement as sortableElement
} from 'react-sortable-hoc';
import { grey } from '@material-ui/core/colors';
import Switch from '@material-ui/core/Switch';
import ReorderIcon from '@material-ui/icons/Reorder';
import LockIcon from '@material-ui/icons/Lock';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { withStyles } from '@material-ui/core/styles';
import styles from './SortableList.css';

const DragHandle = sortableHandle(ReorderIcon);

const SortableItem = sortableElement(({ 
  classes, 
  datatype, 
  disabled, 
  toggleColumn, 
  value, 
}) => {
  const onToggle = (event, isEnabled) => {
    return toggleColumn(datatype, value.key, isEnabled);
  };

  if (disabled) {
    if (value.isEnabled) {
      return (
        <ListItem className={classes.column}>
          <ListItemIcon>
            <LockIcon htmlColor={grey[500]} />
          </ListItemIcon>
          <ListItemText primary={value.title} />
        </ListItem>);
    }
    return <span />;
  }

  return (
    <ListItem className={classes.column}>
      <ListItemIcon>
        <DragHandle htmlColor={grey[500]} style={{ cursor: 'move' }} />
      </ListItemIcon>
      <ListItemText primary={value.title} />
      <ListItemSecondaryAction>
        <Switch
          color="primary"
          edge="end"
          onChange={onToggle}
          checked={value.isEnabled !== false} />
      </ListItemSecondaryAction>
    </ListItem>);
});

SortableItem.propTypes = {
  classes: PropTypes.object,
  toggleColumn: PropTypes.func.isRequired,
};

export const SortableList = sortableContainer(({ 
  classes, 
  datatype, 
  items, 
  isSortingDisabled, 
  toggleColumn, 
  updateAllColumnsDeselected 
}) => (
  <List className={classes.columns}>
    {items.filter(({ key }) => key).map((item, i) => {
      return (
        <SortableItem
          updateAllColumnsDeselected={updateAllColumnsDeselected}
          datatype={datatype}
          classes={classes}
          toggleColumn={toggleColumn}
          key={`item-${i}`}
          disabled={isSortingDisabled}
          index={i}
          value={item} />
      );
    })}
  </List>
));

SortableList.propTypes = {
  classes: PropTypes.object,
  isSortingDisabled: PropTypes.bool,
  items: PropTypes.array.isRequired,
  toggleColumn: PropTypes.func.isRequired
};

export default withStyles(styles)(SortableList);

import React, { createRef } from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import PrintIcon from '@material-ui/icons/Print';
import fileSaver from 'file-saver';
import { FullStoryAPI as fullStoryAPI } from 'react-fullstory';
import NotificationMessage from '../../common/NotificationMessage';
import { EVENTS as eventNames } from '../../constants/fullstory.js';
import { readBinary } from '../../services/api';
import { history } from '../../store';
import { detailReports } from '../common/detailReportIds.js';

class ReportsMenu extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    currentListView: PropTypes.string,
    currentUser: PropTypes.object.isRequired,
    fetchSingleReportPreview: PropTypes.func,
    iconClass: PropTypes.string,
    isFetchingPreviewReport: PropTypes.bool,
    previewReportDataUri: PropTypes.string,
    property: PropTypes.object.isRequired,
    record: PropTypes.object,
    reports: PropTypes.object,
    singleReportsList: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.arrowRef = createRef();
    this.state = {
      isFetching: false,
      menuAnchor: null,
      notification: null,
      singleReportsList: false,
      reports: {
        demographics: props.currentUser.reports.filter(({ dataTypes }) => (dataTypes.includes('demographics'))),
        property: props.currentUser.reports.find(({ type, dataTypes }) =>
          (type === 'detail' && dataTypes.includes('property'))),
        singleReportsList: props.currentUser.reports.filter(({ id, dataTypes }) =>
          (detailReports.includes(id) && dataTypes.includes(this.props.currentListView)))
      }
    };
  }

  componentDidMount = () => {
    this.setState({ singleReportsList: this.props.singleReportsList });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.currentListView !== this.props.currentListView) {
      this.setState({
        ...this.state,
        reports: {
          demographics: this.props.currentUser.reports.filter(({ dataTypes }) => (dataTypes.includes('demographics'))),
          property: this.props.currentUser.reports.find(({ type, dataTypes }) =>
            (type === 'detail' && dataTypes.includes('property'))),
          singleReportsList: this.props.currentUser.reports.filter(({ id, dataTypes }) =>
            (detailReports.includes(id) && dataTypes.includes(this.props.currentListView)))
        }
      });
    }
  };

  handleNotificationClose = () => {
    this.setState({ notification: null });
  };

  handleSelect = (value) => () => {
    const { currentUser: { reports }, property: { address, hash, location: [lng, lat] } } = this.props;
    const report = reports.find(({ id }) => id === value);

    this.setState({ isFetching: true });
    this.closeMenu();

    return readBinary(`/report/${report.id}/build`, null, {
      address,
      hash,
      lat,
      lng,
      type: report.dataTypes[0]
    })
      .then((data) => data.blob())
      .then((blob) => {
        fileSaver.saveAs(blob, `${report.title}.pdf`, { type: 'application/pdf' });
        this.setState({ isFetching: false, notification: null });
        fullStoryAPI(eventNames.EVENT,
          eventNames.PDF_DOWNLOAD, { 'pdfDownload_str': eventNames.PDF_DOWNLOAD_CLICKED });
      })
      .catch((response) => {
        this.setState({
          isFetching: false,
          notification: <NotificationMessage text={'Error creating report'} type={'error'} />,
        });
        console.error(response.message);
      });
  };

  handleGenerateReport = (e, report) => {
    e.stopPropagation(); 
    const record = this.props.record;
    this.setState({ isFetching: true });
    this.closeMenu();
    
    history.push({
      ...location,
      pathname: '/research/map/reports',
      state: { record: [record] },
      query: 
            Object.assign(
              {}, location.query, { selectedReport: report.id, currentListView: report.dataTypes[0], singlePrint: true }
            )
    });
    this.setState({ isFetching: false });
  };

  openMenu = (event) => {
    event.stopPropagation();
    if (!this.state.isFetching) {
      this.setState({ menuAnchor: event.currentTarget });
    }
  };
  closeMenu = () => {
    this.setState({ menuAnchor: null });
  };

  render() {
    const { classes, currentListView, isFetchingPreviewReport } = this.props;
    const { isFetching, menuAnchor, notification, reports, singleReportsList } = this.state;
    const { current: arrow } = this.arrowRef;

    return (
      <div>
        { singleReportsList ? (
          <Tooltip title="Quick Print" placement="bottom">
            <IconButton
              className={classes.quickPrint} 
              disabled={isFetchingPreviewReport}
              size="small"
              onClick={this.openMenu}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton className={classes.rightButton} size="small" onClick={this.openMenu}>
            {isFetching ? <CircularProgress color="inherit" size={24} /> : <PrintIcon />}
          </IconButton>
        )} 
        <Popper
          open={Boolean(menuAnchor)}
          anchorEl={menuAnchor}
          className={classes.reportButtonToolTipContainer}
          placement="bottom-end"
          modifiers={{
            flip: {
              enabled: true,
            },
            arrow: {
              enabled: true,
              element: arrow
            }
          }}>
          <span ref={this.arrowRef} className={classes.printArrow} />
          <Menu
            anchorEl={menuAnchor}
            className={
              cx({ 
                [classes.reportMenuTitles]: singleReportsList, 
                [classes.reportMenuTitlesLarge]: singleReportsList && currentListView === 'sale' }
              )}
            style={{ marginTop: '2px' }}
            onClose={this.closeMenu}
            open={Boolean(menuAnchor)}>
            { singleReportsList ? (
              reports.singleReportsList && 
              Object.values(reports.singleReportsList).map((report) => (
                <MenuItem onClick={(e) => this.handleGenerateReport(e, report)} key={report.id}>
                  {report.title}
                </MenuItem>
              ))
            ) : (
              reports.property && (
                <MenuItem onClick={this.handleSelect(reports.property.id)}>
                  {reports.property.title}
                </MenuItem>
              )
            )}
          </Menu>
        </Popper>      
        {notification && (
          <Snackbar
            autoHideDuration={4000}
            message={notification}
            onRequestClose={this.handleNotificationClose}
            open={true} />
        )}
      </div>
    );
  }
}

export default ReportsMenu;

import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const Confirm = ({ confirmButtonText, denyButtonText, onConfirm, onDeny, open, text, title }) => {
  const handleConfirm = (event) => {
    onConfirm(event);
    event.stopPropagation();
  };

  const handleDeny = (event) => {
    onDeny(event);
    event.stopPropagation();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeny} color="primary">
          {denyButtonText}
        </Button>
        <Button onClick={handleConfirm} color="primary" autoFocus>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog> 
  );
};

Confirm.propTypes = {
  confirmButtonText: PropTypes.string.isRequired,
  denyButtonText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
  open: PropTypes.bool,
  text: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default Confirm;

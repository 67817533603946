/* eslint-disable max-len */
import { grey } from '@material-ui/core/colors';
import { COLLIERS_BLUE } from '../../styles/theme';

export default (theme) => ({
  ['@global']: {
    'div.document-container > table:nth-child(odd)': {
      width: '100%',
      backgroundColor: COLLIERS_BLUE,
      color: 'white',
      height: '10rem',
      ['& > tbody > tr:nth-child(1) > td:nth-child(1)']: {
        width: '25%',
        marginLeft: '1rem',
        ['& > p > img']: {
          width: '80%',
        }
      },
      ['& > tbody > tr:nth-child(1) > td:nth-child(2) > p']: {
        fontSize: '2rem',
        fontWeight: '700',
      },
    },
    'div.document-container > table:nth-child(even)': {
      ['& > tbody > tr:nth-child(2)']: {
        ['& > td:nth-child(1)']: {
          verticalAlign: 'top',
          width: '30%',
          marginLeft: '1rem',
          ['& > p']: {
            width: 'fit-content',
            ['& > img']: {
              width: '75%',
            },
          }
        },
        ['& > td:nth-child(2)']: {
          width: '15%',
        },
        ['& > td:nth-child(6)']: {
          width: '10%',
        },
      },
      ['& > tbody > tr > td:nth-child(1)']: {
        width: '15%',
      },
      ['& > tbody > tr:nth-child(10) > td:nth-child(2)']: {
        width: '15%',
      },
      ['& > tbody > tr:nth-child(10) > td:nth-child(4)']: {
        width: '10%',
      },
    },
  },
  docxPageWrapper: {
    overflowY: 'auto',
    marginTop: '-1rem',
    padding: '1rem 0.5rem',
    width: '100%',
  },

  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  embed: {
    height: '100%',
    width: '100%',
    ['& iframe']: {
      border: 0
    },
  },

  headerIcon: {
    lineHeight: '0.5em',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    ['&:before']: {
      borderBottom: `1px solid ${grey[300]}`,
      content: '""',
      display: 'block',
      height: 5,
      left: 0,
      position: 'absolute',
      right: 0,
      top: '50%',
      width: '100%',
      zIndex: 0
    }
  },

  headerIconButton: {
    border: `1px solid ${grey[300]}`,
    zIndex: 2,
  },

  noResults: {
    margin: '0 auto',
    padding: theme.spacing(6),
    width: '75%',
  },
  report: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  regenOverlay: {
    background: 'rgba(0, 0, 0, 0.6)',
    bottom: 0,
    left: 0,
    paddingTop: 150,
    position: 'absolute',
    right: 0,
    textAlign: 'center',
    top: 0,
  },
  reportFeatureRow: {
    ['&:not(:first-child)']: {
      marginTop: theme.spacing(2)
    }
  },
  staleEmbed: {
    filter: 'blur(6px) saturate(50%)',
  },
  title: {
    display: 'block',
    fontSize: '200%',
    fontWeight: 300,
    padding: theme.spacing(),
    textAlign: 'center',
  },
  
  ['@media print']: {
    ['div']: {
      position: 'static !important',
    },
    header: {
      backgroundColor: 'white'
    },
    report: {
      bottom: 0,
      left: 0,
      position: 'absolute !important',
      right: 0,
      top: 0,
    }
  }
});

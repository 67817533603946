import request from 'superagent';
import { getHeaders } from './api';

export default function walkscore(location) {
  const params = {
    latitude: location[1],
    longitude: location[0],
  };

  return request
    .get(`${process.env.REACT_APP_API_URL}/walkscore`)
    .query(params)
    .set(getHeaders())
    .accept('json')
    .then((result) => {
      return result.body;
    });
}

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TextTruncate from 'react-text-truncate';
import { makeStyles } from '@material-ui/core/styles';
import ExternalLink from '../../../common/ExternalLink';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const PrimaryText = ({ canonicalName }) => {
  const classes = useStyles();
  return (
    <div className={classes.primaryText}>
      <TextTruncate text={canonicalName || '\u2013'} />
    </div>
  );
};

PrimaryText.propTypes = {
  canonicalName: PropTypes.string
};

export const SecondaryText = ({ postedDate, source, sourceUrl }) => {
  const classes = useStyles();
  const date = postedDate ? moment(postedDate).format('MMM D, YYYY') : '\u2013';
  return (
    <div className={classes.secondaryText}>
      <p>{date}</p>
      {(source && sourceUrl) && <ExternalLink text={source} url={sourceUrl} />}
    </div>
  );
};

SecondaryText.propTypes = {
  postedDate: PropTypes.string,
  source: PropTypes.string,
  sourceUrl: PropTypes.string,
};

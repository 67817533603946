import React, { useState, useEffect } from 'react';
import { useTracker } from '@colliers-international/usage-tracker';
import PropTypes from 'prop-types';
import transform from 'lodash/transform';
import get from 'lodash/get';
import { CSVLink } from 'react-csv';
import Link from '@material-ui/core/Link';
import plur from 'plur';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ShareIcon from '@material-ui/icons/Share';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CloseIcon from '@material-ui/icons/Close';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ReportsIcon from '@material-ui/icons/Print';
import ShowAllIcon from '@material-ui/icons/ReplyAll';
import ShowFiltersIcon from '../../assets/filter-icon.svg';
import SavedSearch from '../map/saveSearchButton/SavedSearch';
import ShareSearch from '../shareSearch/ShareSearch';
import { MyListsMenu } from '../map/MyListsMenu';
import Can from '../../auth/ability';
import { read } from '../../services/api';
import datatypes from '../../common/datatypes';
import getReportMenuItems from '../../common/reports';
import { MAX_REPORT_SIZE } from '../constants';
import HeaderButton from './common/HeaderButton';
import { formatContent } from './Cell';
import styles from './module.css';

const Header = React.forwardRef(({
  classes,
  createListsWithEntities,
  currentListView,
  currentUser,
  router,
  location,
  columns,
  myLists,
  popoverRef,
  results,
  selectedResults,
  showSelected,
  toggleShowSelected,
  toggleShareDialog,
  updateMyList,
  ...props }, ref) => {
  const alternateTextColor = 'white'; // TODO: use theme?
  const { trackEvent } = useTracker();

  const [reportMenuAnchor, setReportMenuAnchor] = useState(null);
  const openReportMenu = (event) => {
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Reports' });
    setReportMenuAnchor(event.currentTarget);
  };
  const closeReportMenu = () => setReportMenuAnchor(null);

  const [sendMenuAnchor, setSendMenuAnchor] = useState(null);
  const openSendMenu = (event) => { 
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Send to' });
    setSendMenuAnchor(event.currentTarget);
  };
  const closeSendMenu = () => setSendMenuAnchor(null);

  const [datatypeMenuAnchor, setDatatypeMenuAnchor] = useState(null);
  const openDatatypeMenu = (event) => setDatatypeMenuAnchor(event.currentTarget);
  const closeDatatypeMenu = () => setDatatypeMenuAnchor(null);

  const [mounted, setMounted] = useState(false);
  const [recentSavedSearches, setRecentSavedSearches] = useState([]);

  const totalDisplayedResults = results.length - 1;

  useEffect(() => {
    setMounted(true);
  }, []);

  const selectedResultsIds = selectedResults.map((item) => item._id) || [];

  const openFilters = () => {
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Filter' }); 
    props.showFilters(currentListView);
  };
  
  const { color, isEnabled } = datatypes[currentListView];
  const updateCurrentListView = (key) => () => {
    if (!isEnabled) props.enableDatatype(key);
    closeDatatypeMenu();
    return router.push({ ...location, query: { currentListView: key } });
  };
  const itemCount = () => {
    return `${selectedResults.length} / ${MAX_REPORT_SIZE}`;
  };
  const csvCellContent = (source, { aggregation, key }) => {
    if (aggregation) {
      const [arrKey, objAttr] = key.split('.', 2);
      return get(source, arrKey, []).map((val) => val[objAttr]);
    }
    return get(source, key);
  };
  const columnsWhitelist = columns.filter((c) => (
    !['clientId', 'hash'].includes(c.key) && c.hideHeader !== true && !!c.title
  ));
  
  const csvFilename = `${currentListView}-${Date.now()}.csv`;
  const csvHeaders = columnsWhitelist.map((c) => c.title);
  const csvData = selectedResults.map(({ _source }) => {
    // use current results data since we are now in "show" sourceFilter mode
    // and it is possible for items to have been added in "list"
    const source = (results.find((item) => item._id === _source.id) || { _source })._source;
    return transform(columnsWhitelist, (acc, col) => {
      acc[col.title] = formatContent({
        column: col,
        content: csvCellContent(source, col),
        item: source,
        dateFormat: 'YYYY-MM-DD'
      });
    }, {});
  });

  const handleShare = () => {
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Share' });
    toggleShareDialog(true);
  };
  const handleShareClose = () => toggleShareDialog(false);

  const openColumnSettings = () => {
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Column Settings' });
    router.push({ ...location, pathname: '/research/table/columns' });
  };

  const openAnalytics = () => {
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Analytics' });
    router.push({ ...location, pathname: '/research/table/analytics' });
  };

  const openMapView = () => {
    router.push({ ...location, pathname: '/research/map' });
  };

  const openDownload = () => {
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Download' });
  };

  const showMyReports = () => {
    const listsHidden = ['tim', 'news', 'company', 'shape', 'development', 'property'];
    return !listsHidden.includes(currentListView) && !currentUser.isGuest;
  };

  const showMyLists = () => {
    const listsHidden = ['tim', 'news'];
    return !listsHidden.includes(currentListView);
  };

  const hideBoom = () => {
    const hidden = ['tim', 'company', 'news'];
    return hidden.includes(currentListView) || currentUser.isGuest;
  };

  const handleSendToBoom = (e) => {
    e.stopPropagation();
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Send to Boom' });
  };

  const boomUrl = () => {
    const selectedIds = new Set(selectedResults.map((element) => element._id));
    const idList = results.filter((element) => selectedIds.has(element._id)).map((element) => element._id);
    const boomParams = {
      source: 'discover',
      type: plur(currentListView),
      ids: idList,
    };

    // eslint-disable-next-line no-undef
    return `${process.env.REACT_APP_BOOM_APP_DOMAIN}/?uploadpoints=${JSON.stringify(boomParams)}`;
  };

  const reportMenuItems = getReportMenuItems({
    closeMenu: closeReportMenu,
    currentUser,
    currentListView
  });

  const getSavedSearches = (query) => {
    return read('savedSearches', null, query)
      .then((results) => {
        return results.map((result) => ({ ...result, _type: 'savedSearch' }));
      });
  };

  const loadRecentSavedSearches = () => {
    getSavedSearches({ limit: 5, sort: '-updatedAt' })
      .then((results) => {
        if (mounted) {
          setRecentSavedSearches(results.map((result) => ({ text: result.title, value: result })) || []);
        }
      });
    return recentSavedSearches;
  };

  const handleSavedSearchCreate = () => {
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Save Search' });
    loadRecentSavedSearches();
  };

  const handleSavedSearchDelete = () => {
    loadRecentSavedSearches();
  };

  const handleSavedSearchSave = () => loadRecentSavedSearches();

  return (
    <div ref={ref} style={{ alignItems: 'center', backgroundColor: color, color: alternateTextColor }}>
      <div className={classes.headerColumnContainer}>
        <div className={classes.headerDataFilterContainer}>
          <div className={classes.headerDataRightContainer}>
            <Button
              className={classes.headerDropdown}
              endIcon={(<DropDownIcon />)}
              onClick={openDatatypeMenu}
              size="large">
              {datatypes[currentListView.toLowerCase()].plural}
            </Button>
            <Menu
              anchorEl={datatypeMenuAnchor}
              onClose={closeDatatypeMenu}
              open={Boolean(datatypeMenuAnchor)}>
              {Object.keys(datatypes).map((search) => {
                const { key, plural } = datatypes[search];
                if (search === 'news' || search === 'company') {
                  return (
                    <Can key={key} do="read">
                      <MenuItem onClick={updateCurrentListView(key)}>{plural}</MenuItem>
                    </Can>
                  );
                }
                return (
                  <MenuItem key={key} onClick={updateCurrentListView(key)}>{plural}</MenuItem>
                );
              })}
            </Menu>

            {selectedResults.length === 0 && (
              <div className={classes.headerItemCount}>
                Displaying {totalDisplayedResults} results
              </div>
            )}
            {selectedResults.length > 0 && (
              <Tooltip title="Exports limited to 100 records.">
                <div className={classes.headerItemCount}>
                  Selected {itemCount()}
                </div>
              </Tooltip>
            )}
          </div>

          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <HeaderButton 
                  title={'Column Settings'} 
                  handler={openColumnSettings} 
                  headerIcon={<ViewColumnIcon color="inherit" />}
                  hide={currentUser.isGuest} />

                {(selectedResults.length > 0 || showSelected) ? (
                  <HeaderButton 
                    title={showSelected ? 'Show All Items' : 'View Selected'} 
                    handler={() => {
                      trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'View Selected' });
                      toggleShowSelected();
                    }} 
                    headerIcon={showSelected ?
                      (<ShowAllIcon color="inherit" />) :
                      (<VisibilityIcon color="inherit" />)
                    } />
                )
                  : (
                    <HeaderButton 
                      title={'Please select 1 or more records'} 
                      handler={null} 
                      headerIcon={<VisibilityIcon color="inherit" />} />
                  )}

                <HeaderButton 
                  title={'Filter Results'} 
                  handler={openFilters} 
                  headerIcon={<img src={ShowFiltersIcon} alt="Show Filters" />} />

                <Can do="create" on="exports">
                  <Tooltip title="Download CSV">
                    <CSVLink headers={csvHeaders} data={csvData} filename={csvFilename}>
                      <IconButton className={classes.headerButton} onClick={openDownload}>
                        <CloudDownloadIcon color="inherit" />
                      </IconButton>
                    </CSVLink>
                  </Tooltip>
                </Can>

                {!currentUser.isGuest && <SavedSearch
                  grid={true}
                  onCreate={handleSavedSearchCreate}
                  onDelete={handleSavedSearchDelete}
                  onSave={handleSavedSearchSave} />}

                {(
                  selectedResults.length > 0 && showMyLists() && myLists && !currentUser.isGuest ? (
                    <MyListsMenu 
                      currentUser={currentUser}
                      grid={true}
                      myLists={myLists}
                      itemId={selectedResultsIds}
                      createListsWithEntities={createListsWithEntities}
                      currentListView={currentListView}
                      updateMyList={updateMyList}
                      classes={classes}
                      isBulk={true}
                      popoverRef={popoverRef} />
                  ) : (
                    (showMyLists() && !currentUser.isGuest) && (
                      <HeaderButton 
                        title={'Please select 1 or more records'} 
                        handler={null} 
                        headerIcon={<PlaylistAddIcon color="inherit" />} />
                    )))}

                {!hideBoom() && (
                  selectedResults.length > 0 ? (
                    <React.Fragment>
                      <HeaderButton 
                        title={'Send To Boom'} 
                        handler={openSendMenu} 
                        headerIcon={<OpenInNewIcon color="inherit" />} />
                      <Menu
                        anchorEl={sendMenuAnchor}
                        onClose={closeSendMenu}
                        open={Boolean(sendMenuAnchor)}>
                        {<Link
                          className={classes.boomLink}
                          href={boomUrl()}
                          onClick={handleSendToBoom}
                          target={'_blank'}>
                          <MenuItem>Send to Boom</MenuItem>
                        </Link>}
                      </Menu>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <HeaderButton 
                        title={'Please select 1 or more records'} 
                        handler={null} 
                        headerIcon={<OpenInNewIcon color="inherit" />} />
                      <Menu
                        anchorEl={sendMenuAnchor}
                        onClose={closeSendMenu}
                        open={Boolean(sendMenuAnchor)}>
                        {<Link
                          className={classes.boomLink}
                          href={boomUrl()}
                          onClick={handleSendToBoom}
                          target={'_blank'}>
                          <MenuItem>Send to Boom</MenuItem>
                        </Link>}
                      </Menu>
                    </React.Fragment>
                  ))}
                {(
                  selectedResults.length > 0 ? (
                    <HeaderButton 
                      title={'Share Selection'} 
                      handler={handleShare} 
                      headerIcon={<ShareIcon color="inherit" />}
                      hide={currentUser.isGuest} />
                  ) : (
                    <HeaderButton 
                      title={'Please select 1 or more records'} 
                      handler={null} 
                      headerIcon={<ShareIcon color="inherit" />}
                      hide={currentUser.isGuest} />
                  ))}

                {(currentListView === 'lease' && !currentUser.isGuest) && (
                  selectedResults.length > 0 ? (
                    <HeaderButton
                      title={'Analytics'}
                      handler={openAnalytics}
                      headerIcon={<AssessmentIcon color="inherit" />} />
                  ) : (
                    <HeaderButton
                      title={'Please select 1 or more records'}
                      handler={null}
                      headerIcon={<AssessmentIcon color="inherit" />} />
                  )
                )}

                {showMyReports() && (
                  <Can do="read" on="reports">
                    <HeaderButton 
                      title={'Preview Report'} 
                      handler={openReportMenu} 
                      headerIcon={<ReportsIcon color="inherit" />} />
                    <Menu
                      anchorEl={reportMenuAnchor}
                      onClose={closeReportMenu}
                      open={Boolean(reportMenuAnchor)}>
                      {reportMenuItems}
                    </Menu>
                  </Can>
                )}
              
                <HeaderButton 
                  title={'Back to Map View'}
                  handler={openMapView} 
                  headerIcon={<CloseIcon color="inherit" />} />
              </div>
            </div>

            <Can do="create" on="interactiveLinks">
              <ShareSearch onClose={handleShareClose} />
            </Can>
          </div>
        </div>
      </div>
    </div>
  );
});

Header.displayName = 'Header';

Header.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.array.isRequired,
  createListsWithEntities: PropTypes.func.isRequired,
  currentListView: PropTypes.string.isRequired,
  currentUser: PropTypes.object.isRequired,
  disableSelectMode: PropTypes.func.isRequired,
  enableDatatype: PropTypes.func.isRequired,
  enableSelectMode: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  myLists: PropTypes.object.isRequired,
  popoverRef: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired,
  savedSearch: PropTypes.object.isRequired,
  searches: PropTypes.array.isRequired,
  selectAllResults: PropTypes.func.isRequired,
  selectedResults: PropTypes.array.isRequired,
  showFilters: PropTypes.func.isRequired,
  showSelected: PropTypes.bool.isRequired,
  toggleShareDialog: PropTypes.func.isRequired,
  toggleShowSelected: PropTypes.func.isRequired,
  updateMyList: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NavigationCloseIcon from '@material-ui/icons/Close';
import AnalyticsViewIcon from '@material-ui/icons/InsertChart';
import FiltersIcon from '@material-ui/icons/FilterList';
import PDFIcon from '@material-ui/icons/PictureAsPdf';
import { COLLIERS_BLUE, COLLIERS_CYAN, COLLIERS_RED, COLLIERS_YELLOW } from '../../styles/theme';
import { MAX_REPORT_SIZE } from '../constants';
import PrintableChart from './PrintableChart';
import styles from './module.css';
import { Tooltip } from '@material-ui/core';

class Analytics extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    containerHeight: PropTypes.number.isRequired,
    currentListView: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
    fetchAnalysisPdf: PropTypes.func.isRequired,
    isFetchingPdf: PropTypes.bool.isRequired,
    isSearching: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    pdfErrorMsg: PropTypes.string,
    router: PropTypes.object.isRequired,
    selectedResults: PropTypes.array.isRequired,
    showFilters: PropTypes.func.isRequired,
  };

  chartAnimationDuration = 1000;

  showFilters = () => this.props.showFilters('lease');

  close = () => {
    this.props.router.push({
      ...this.props.location,
      pathname: this.props.location.pathname.replace('/analytics', '')
    });
  };

  downloadPdf = () => {
    // as pre-processing, overwrite every analytics canvas with an image version of self
    // (image is generated within each component, just need to hide/show)
    const doc = (new DOMParser()).parseFromString(
      document.querySelector('html').outerHTML,
      'text/html'
    );

    // wait on canvas images to be in place
    // (can't seem to do this through state alone b/c updates to this state re-render
    // its children, which puts us in a weird rendering loop)
    if (!doc.querySelector('#lease-analytics-content img[data-canvas]')) {
      return setTimeout(this.downloadPdf, 50);
    }

    // in our version of the dom, replace all canvases with a picture of that canvas
    doc.querySelectorAll('#lease-analytics-content canvas').forEach((canvas) => {
      canvas.style.display = 'none';
    });
    doc.querySelectorAll('#lease-analytics-content img[data-canvas]').forEach((canvasImg) => {
      canvasImg.style.display = 'block';
    });
    doc.querySelectorAll('#lease-analytics-content [data-printclass]').forEach((el) => {
      el.dataset.printclass.split(' ').forEach((c) => {
        el.classList.add(c);
      });
    });

    const extraCss = '.row { display: block !important; padding: 10 0 10 0 }\
      .row:after { content: ""; display: table; clear: both;}\
      .row > div { float: left; flex: initial !important; }\
      .row.of-2>div {  width: 49%; }\
      .row.of-3>div { width: 33%; }\
      img { width: 100%; }';

    // fetch the resulting pdf
    this.props.fetchAnalysisPdf(
      doc.querySelector('html').outerHTML,
      (document.querySelector('base') || location).href,    // (if base tag is present, the getter will result in absolute value even if relative in tag)
      '#lease-analytics-content',
      extraCss
    );
  };

  render = () => {
    const {
      classes,
      containerHeight,
      currentUser,
      isFetchingPdf,
      isSearching
    } = this.props;

    const selectedResults = this.props.selectedResults || [];

    // format functions for gauge charts
    const formatRent = (v) => `$${v.toFixed(2)}`;
    const formatArea = (v) => `${(v / 1000).toFixed(1)}K`;
    const formatTerm = (v) => `${Math.round(v)}`;

    // standardized color scheme (IMPORTANT: use hex codes)
    const chartColors = [
      COLLIERS_CYAN,
      COLLIERS_BLUE,
      COLLIERS_YELLOW,
      COLLIERS_RED
    ];

    // standardized config for the legends on the page
    const legendConfig = {
      position: 'bottom',
      labels: {
        boxWidth: 12
      }
    };

    return (
      <Paper elevation={1} className={classes.container} style={{ height: containerHeight }}>
        <AppBar
          className={classes.header}
          elevation={0}
          position="static">
          <Toolbar className={classes.headerToolbar}>
            <AnalyticsViewIcon />
            <Typography className={classes.headerTitle} variant="h5">
              Lease Analytics
            </Typography>
            <div style={{ color: 'white' }}>
              {!(isSearching || isFetchingPdf) && (
                <React.Fragment>
                  <Tooltip title="Filter Leases">
                    <IconButton 
                      color="inherit" 
                      onClick={this.showFilters}>
                      <FiltersIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Save as PDF">
                    <IconButton color="inherit" onClick={this.downloadPdf}>
                      <PDFIcon />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              )}
              {isFetchingPdf && (
                <Tooltip title="Generating PDF...">
                  <IconButton color="inherit">
                    <CircularProgress color="inherit" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Close">
                <IconButton color="inherit" onClick={this.close}>
                  <NavigationCloseIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        {selectedResults.length === 0 && (
          <p className={classes.noResults}>
            Select up to {MAX_REPORT_SIZE} items in the list view to the right to view grouped analytics.
          </p>
        )}
        {selectedResults.length > 0 && (
          isSearching
            ? (
              <div className={classes.statsContainer} style={{ textAlign: 'center' }}>
                <CircularProgress size={100} />
              </div>
            ) : (
              <div id="lease-analytics-content" className={classes.statsContainer}>

                <div className={classes.divider} />

                <div className={classes.leaseCountLine}>
                  <div className={classes.leaseCount}>{selectedResults.length}</div>
                  Leases have been analyzed to create this report
                </div>

                <div className={classes.chartRow} style={{ marginTop:'-40px' }} data-printclass="row of-3">
                  <div className={classes.chartContainer}>
                    <PrintableChart
                      type="gauge"
                      classes={classes}
                      title="Average Lease Rate"
                      items={selectedResults}
                      analyzeKey="rentRate"
                      format={formatRent}
                      unit="/SF"
                      color={chartColors[0]}
                      animationDuration={this.chartAnimationDuration} />
                  </div>

                  <div className={classes.chartContainer}>
                    <PrintableChart
                      type="gauge"
                      classes={classes}
                      title="Average Term"
                      items={selectedResults}
                      analyzeKey="leaseTerm"
                      format={formatTerm}
                      unit=" mos"
                      color={chartColors[0]}
                      animationDuration={this.chartAnimationDuration} />
                  </div>

                  <div className={classes.chartContainer}>
                    <PrintableChart
                      type="gauge"
                      classes={classes}
                      title="Average Lease Size"
                      items={selectedResults}
                      analyzeKey="area"
                      format={formatArea}
                      unit=" SF"
                      color={chartColors[0]}
                      animationDuration={this.chartAnimationDuration} />
                  </div>
                </div>

                <div className={classes.divider} />

                {!currentUser.isGuest && (
                  <div>
                    <div className={classes.chartRow} data-printclass="row of-2">
                      <div className={[classes.chartContainer, classes.dividerRight].join(' ')}>
                        <h4 className={classes.chartTitle}>Average Lease Rate by Year</h4>
                        <PrintableChart
                          type="rateByYear"
                          classes={classes}
                          items={selectedResults}
                          legend={legendConfig}
                          color={chartColors[0]}
                          animationDuration={this.chartAnimationDuration} />
                      </div>
                      <div className={classes.chartContainer}>
                        <h4 className={classes.chartTitle}>Average Term vs Average Square Footage</h4>
                        <PrintableChart
                          type="termAndAreaByYear"
                          classes={classes}
                          items={selectedResults}
                          legend={legendConfig}
                          colors={[chartColors[0], chartColors[1]]}
                          animationDuration={this.chartAnimationDuration} />
                      </div>
                    </div>
                    <div className={classes.divider} />
                  </div>
                )}

                <div className={classes.chartRow} data-printclass="row of-2">

                  <div className={[classes.chartContainer, classes.dividerRight].join(' ')}>
                    <h4 className={classes.chartTitle}>Building Class</h4>
                    <PrintableChart
                      type="buildingClass"
                      classes={classes}
                      items={selectedResults}
                      legend={legendConfig}
                      colors={[chartColors[1], chartColors[2], chartColors[3], chartColors[0]]}
                      animationDuration={this.chartAnimationDuration} />
                  </div>

                  <div className={classes.chartContainer}>
                    <h4 className={classes.chartTitle}>Lease Expiration</h4>
                    <PrintableChart
                      type="expirations"
                      classes={classes}
                      items={selectedResults}
                      legend={legendConfig}
                      colors={[chartColors[1], chartColors[0]]}
                      animationDuration={this.chartAnimationDuration} />
                  </div>

                </div>

              </div>
            )
        )}

        <Snackbar
          message={this.props.pdfErrorMsg}
          onClose={this.handleNotificationClose}
          open={this.props.pdfErrorMsg.length > 0} />
      </Paper>
    );
  };
}

export default withStyles(styles)(Analytics);

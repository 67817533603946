import React, { memo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTracker } from '@colliers-international/usage-tracker';
import { browserHistory } from 'react-router';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styles from './module.css';

function getParcelPath(parcel, location) {
  if (parcel) {
    const view = get((location && location.pathname || '').split('/'), [2], 'map');
    return `/research/${view}/parcel/fips/${parcel.fips}/apn/${parcel.assessment.apn}`;
  }
}

const ParcelTabs = memo(({
  classes,
  isFetching,
  location,
  parcel,
  properties,
  propertyId,
  setCurrentProperty
}) => {
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const { trackEvent } = useTracker();

  const handleMenuClose = () => {
    setOpen(false);
  };

  const handleMenuToggle = () => {
    trackEvent({ category: 'Parcel Detail Page', action: 'Clicked', name: 'View MORE button' });
    setOpen((prevOpen) => !prevOpen);
  };

  const handleParcelClick = () => {
    if (propertyId && parcel) {
      trackEvent({ category: 'Parcel Pop-Up', action: 'Clicked', name: 'PUBLIC RECORD button' });
      browserHistory.push({
        ...location,
        pathname: `${getParcelPath(parcel.parcels[0], location)}/info/public/tax`
      });
    }
  };

  const handlePropertyClick = (p) => () => {
    const propPath = `${p.hash}|${p.rescourId}/property/${p.id}`;
    setCurrentProperty(p.id);
    handleMenuClose();
    browserHistory.push({
      ...location,
      pathname: `${getParcelPath(parcel.parcels[0], location)}/${propPath}`
    });
  };

  const allTabs = properties?.filter((p) => !!p.id);
  const shownTabs = allTabs?.slice(0,2) ?? [];
  const moreTabs = allTabs?.slice(2) ?? [];
  const moreTabsSelected = !!moreTabs.filter((p) => `${p.hash}|${p.rescourId}` === propertyId).length;

  return (
    <React.Fragment>
      <ButtonGroup className={classes.tabGroup} size="small">
        <Button
          className={classes.parcelButton}
          color="primary"
          onClick={handleParcelClick}
          variant={propertyId ? 'outlined' : 'contained'}>
          {'Public Record'}
        </Button>
        {!isFetching && shownTabs.map((p) => {
          const active = `${p.hash}|${p.rescourId}` === propertyId;
          return (
            <Button
              className={classes.propertyButton}
              color="primary"
              key={p.id}
              onClick={handlePropertyClick(p)}
              variant={active ? 'contained' : 'outlined'}>
              {p.address}
            </Button>
          );
        })};
        {!isFetching && (moreTabs.length > 0) && (
          <Button
            color="primary"
            onClick={handleMenuToggle}
            ref={menuRef}
            variant={open || moreTabsSelected ? 'contained' : 'outlined'}>
            {'More'}
            <ArrowDropDownIcon />
          </Button>
        )
        }
      </ButtonGroup>
      <Popper
        anchorEl={menuRef.current}
        disablePortal
        open={open}
        transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper className={classes.menuPaper}>
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList>
                  {moreTabs.map((p) => {
                    const selected = `${p.hash}|${p.rescourId}` === propertyId;
                    return (
                      <MenuItem
                        className={classes.propertyMenuItem}
                        key={p.id}
                        onClick={handlePropertyClick(p)}
                        selected={selected}>
                        {p.address}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}, isEqual);

ParcelTabs.displayName = 'ParcelTabs';

ParcelTabs.propTypes = {
  classes: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  location: PropTypes.object.isRequired,
  parcel: PropTypes.object,
  properties: PropTypes.array,
  propertyId: PropTypes.string,
  setCurrentProperty: PropTypes.func.isRequired,
};

export default withStyles(styles)(ParcelTabs);

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@mui/material/Drawer';
import datatypes from '../../common/datatypes';
import Filters from './Filters';
import ParcelSearch from './ParcelSearch';
import styles from './module.css';

const Searches = ({
  currentLocation,
  currentUser,
  disableListMode,
  hideFilters,
  orderColumn,
  polygons,
  receiveSearchResults,
  requestSearchResults,
  resetFilters,
  searches,
  searchBounds,
  selectedResults,
  selectFilter,
  showSelected,
  sourceFilter,
  updateParcelFilteredData,
}) => {
  const handleParcelSearchClose = useCallback(() => hideFilters('parcel'), []);

  return (
    <div>
      <ParcelSearch
        currentLocation={currentLocation}
        data={searches?.parcel?.unfilteredData || []}
        dataTime={searches?.parcel?.unfilteredDataTime}
        filterCount={searches?.parcel?.filterCount}
        onClose={handleParcelSearchClose}
        onFiltered={updateParcelFilteredData}
        open={searches?.parcel?.isOpen}
        parcelFilters={searches?.parcel?.filters}
        resetDate={searches?.parcel?.resetDate}
        resetFilters={resetFilters}
        searchBounds={searchBounds}
        selectFilter={selectFilter} />
      {Object.keys(searches).map((key) => {
        const onClose = () => hideFilters(key);
        const limitIds = (selectedResults && selectedResults[key] && selectedResults[key].length)
          ? selectedResults[key].map((x) => x._id)
          : [];
        return (
          <div key={key}>
            {searches[key].isEnabled && (
              <Drawer
                elevation={1}
                variant="persistent"
                anchor="left"
                open={searches[key].isOpen}
                sx={{
                  '& .MuiDrawer-paper': {
                    maxWidth: '100vw',
                    overflowY: 'hidden',
                    paddingBottom: 4,
                    width: 350,
                  }
                }}
                ModalProps={{ keepMounted: true }}>
                <div style={{ minHeight: 72 }} />
                <Filters
                  bounds={searchBounds}
                  currentUser={currentUser}
                  disableListMode={disableListMode}
                  limitIds={showSelected ? limitIds : null}
                  onQuery={requestSearchResults}
                  onResults={receiveSearchResults}
                  onClose={onClose}
                  orderBy={searches[key].orderBy}
                  orderColumn={orderColumn}
                  polygons={polygons}
                  datatype={datatypes[key]}
                  search={searches[key]}
                  sourceFilter={sourceFilter} />
              </Drawer>
            )}
          </div>
        );
      })}
    </div>
  );
};

Searches.propTypes = {
  currentLocation: PropTypes.string,
  currentUser: PropTypes.object,
  disableListMode: PropTypes.func.isRequired,
  hideFilters: PropTypes.func.isRequired,
  orderBy: PropTypes.object.isRequired,
  orderColumn: PropTypes.func.isRequired,
  polygons: PropTypes.array.isRequired,
  receiveSearchResults: PropTypes.func.isRequired,
  requestSearchResults: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  searchBounds: PropTypes.object.isRequired,
  searches: PropTypes.object.isRequired,
  selectFilter: PropTypes.func.isRequired,
  selectedResults: PropTypes.object.isRequired,
  showSelected: PropTypes.bool.isRequired,
  sourceFilter: PropTypes.string.isRequired,
  updateParcelFilteredData: PropTypes.func.isRequired,
};

Searches.defaultProps = {
  orderBy: {},
};

export default withStyles(styles)(Searches);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { history } from '../../store';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import formatStat from '../../common/stats/formatStat';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import DatatypeIcons from '../../account/common/DatatypeIcons';
import NotificationMessage from '../../common/NotificationMessage';
import api from '../../services/api';
import styles from './module.css';

const SavedSearchRow = ({ classes, ...props }) => {
  const { search: { title, timestamp, value, description, frequency },
    onDeleteSavedSearch,
    onUpdateSavedSearch,
    savedSearchDeleted,
    savedSearchUpdated,
    selectSavedSearch,
  } = props;

  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const toggleDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };
  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setNotificationMessage('');
    setNotificationOpen(false);
    setIsDeleting(false);
  };
  const handleDelete = () => {
    setIsDeleting(true);
    return api.destroy('/savedSearches', value.id)
      .then((result) => {
        setIsDeleting(false);
        setDeleteDialogOpen(false);
        savedSearchDeleted(value);
        onDeleteSavedSearch(value);
        return result;
      })
      .catch((error) => {
        setIsDeleting(false);
        setDeleteDialogOpen(false);
        setNotificationOpen(false);
        setNotificationMessage(
          <NotificationMessage text={`Error deleting search [${error.message}]`} type={'error'} />
        );
        setNotificationOpen(true);
      });
  };
  const handleNotificationClose = () => {
    setNotificationOpen(false);
    setNotificationOpen(false);
    setNotificationMessage('');
  };

  const [savedSearchTitle, setSavedSearchTitle] = useState(title);
  const [savedSearchDescription, setSavedSearchDescription] = useState(description);
  const [savedSearchFrequency, setSavedSearchFrequency] = useState(frequency);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const toggleEditDialog = () => {
    setEditDialogOpen(true);
  };
  const handleEditCancel = () => {
    setIsEditing(false);
    setNotificationMessage('');
    setNotificationOpen(false);
    setEditDialogOpen(false);
    setSavedSearchTitle(title);
    setSavedSearchDescription(description);
  };
  const handleEdit = () => {
    let resource = '/savedSearches';
    resource += `/${value.id}`;
    const saveMode = 'update';
    const data = {
      enabledDataTypes: value.enabledDataTypes,
      urlHash: value.urlHash,
      filters: value.filters,
      query: value.query,
      selectedItems: value.selectedItems,
      title: savedSearchTitle,
      description: savedSearchDescription,
      frequency: savedSearchFrequency
    };
    Object.assign(data, { title: savedSearchTitle, description: savedSearchDescription });
    setIsEditing(true);
    return api[saveMode](resource, data)
      .then((result) => {
        onUpdateSavedSearch(savedSearchTitle, savedSearchDescription, savedSearchFrequency, value);
        savedSearchUpdated(value);
        setIsEditing(false);
        setEditDialogOpen(false);
        setNotificationMessage(<NotificationMessage
          secondaryText={`Search ${saveMode}d`}
          text={savedSearchTitle}
          type={'success'} />);
        setNotificationOpen(true);
        setSavedSearchTitle(savedSearchTitle);
        setSavedSearchDescription(savedSearchDescription);
        setSavedSearchFrequency(savedSearchFrequency);
        return result;
      })
      .catch((error) => {
        setIsEditing(false);
        setNotificationOpen(false);
        setNotificationMessage(<NotificationMessage text={`Error saving search [${error.message}]`} type={'error'} />);
        setNotificationOpen(true);
        console.error('handleSave error: ', error);
      });
  };
  const handleTitleChange = (event) => {
    setSavedSearchTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setSavedSearchDescription(event.target.value);
  };

  const handleFrequencyChange = (event, value) => {
    event.preventDefault();
    setSavedSearchFrequency(value);
  };

  const loadSearch = () => {
    selectSavedSearch(value);
    window.location.hash = value.urlHash;
    history.replace({
      ...location,
      pathname: '/research/map',
      hash: value.urlHash
    });
  };
  
  return (
    <React.Fragment>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
        <TableCell className={classes.savedSearchTableCell}>
          <p
            className={classes.savedSearchColumnName}
            onClick={loadSearch}>{title}</p>
        </TableCell>
        <TableCell
          className={classes.savedSearchTableCell}>{description}</TableCell>
        <TableCell className={classes.savedSearchTableCell}>
          <div className={classes.savedSearchColumnModules}>
            <DatatypeIcons types={value.enabledDataTypes} />
          </div>
        </TableCell>
        <TableCell className={classes.savedSearchTableCell}>{moment(timestamp).format('M/D/YYYY h:mm A')}</TableCell>
        <TableCell className={classes.savedSearchTableCell}>
          {formatStat(frequency, { format: 'properCaseString' })}
        </TableCell>
        <TableCell className={classes.savedSearchTableCell}>
          <div className={classes.savedSearchColumnActions}>
            <Tooltip title="Edit Name or Description" placement="right-start">
              <IconButton
                onClick={toggleEditDialog}
                size="small">
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Search" placement="top">
              <IconButton
                onClick={toggleDeleteDialog}
                size="small">
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}>
        <DialogTitle>Delete Saved Search</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you wish to delete this alert?
            <span style={{ display: 'block', fontWeight: 'bold' }}>{title}</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isDeleting}
            onClick={handleDeleteCancel}>No</Button>
          <Button
            color="primary"
            disabled={isDeleting}
            onClick={handleDelete}
            variant="contained">Yes</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={editDialogOpen}
        onClose={handleEditCancel}>
        <DialogTitle>Manage Search</DialogTitle>
        <div className={classes.savedSearchModules}>
          <DatatypeIcons types={value.enabledDataTypes} />
        </div>
        <Close className={classes.close} onClick={handleEditCancel} />
        <DialogContent>
          <div>
            <TextField
              fullWidth
              label={'Title'}
              defaultValue={title} 
              onChange={handleTitleChange} />
          </div>
          <div>
            <TextField
              fullWidth
              label={'Description'}
              defaultValue={description}
              onChange={handleDescriptionChange} />
          </div>
          <div>
            <Typography className={classes.notifyText}>
              Notify me of new changes
            </Typography>
            <Typography className={classes.notifySubText}>
              Receive email alerts when new items match this saved search.
            </Typography>
          </div>
          <div>
            <ToggleButtonGroup
              value={savedSearchFrequency}
              exclusive
              onChange={handleFrequencyChange}
              name="frequency"
              aria-label="frequency"
              className={classes.toggleButtons}>
              <ToggleButton value="never">NEVER</ToggleButton>
              <ToggleButton value="daily">DAILY</ToggleButton>
              <ToggleButton value="weekly">WEEKLY</ToggleButton>
            </ToggleButtonGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Delete Search" placement="top">
            <Button
              onClick={toggleDeleteDialog}
              size="small"
              className={classes.deleteSearch}>
              Delete Search
            </Button>
          </Tooltip>
          <Button
            color="primary"
            disabled={isEditing}
            className={classes.cancelButton}
            variant="outlined"
            onClick={handleEditCancel}>Cancel</Button>
          <Button
            color="primary"
            disabled={isEditing}
            onClick={handleEdit}
            className={classes.updateButton}
            variant="contained">Update</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={notificationOpen}
        autoHideDuration={5000}
        onClose={handleNotificationClose}>
        <SnackbarContent
          style={{ minWidth: 'fit-content' }}
          message={notificationMessage} />
      </Snackbar>
    </React.Fragment>
  );
};

SavedSearchRow.propTypes = {
  classes: PropTypes.object.isRequired,
  onDeleteSavedSearch: PropTypes.func.isRequired,
  onUpdateSavedSearch:PropTypes.func.isRequired,
  savedSearchDeleted: PropTypes.func,
  savedSearchUpdated: PropTypes.func,
  search: PropTypes.object.isRequired,
  selectSavedSearch: PropTypes.func
};

export default withStyles(styles)(SavedSearchRow);

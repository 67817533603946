import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import SortIcon from '@material-ui/icons/Sort';

export default function SortIconButton({ columnId, sortColumn, handleSortClick }) {
  return (
    <IconButton onClick={handleSortClick(columnId)}>
      <SortIcon
        style={{
          transform: sortColumn.id === columnId && sortColumn.order === 'desc' ? 'scaleY(-1)' : 'scaleY(1)',
        }} />
    </IconButton>
  );
}
  
SortIconButton.propTypes = {
  columnId: PropTypes.string.isRequired,
  handleSortClick: PropTypes.func.isRequired,
  sortColumn: PropTypes.object.isRequired,
};

import {
  RESEARCH_GRID_GRID_VIEW_SUCCESS,
  RESEARCH_GRID_GRID_VIEW_SELECT,
  RESEARCH_GRID_GRID_VIEW_DELETE_SUCCESS,
  RESEARCH_GRID_GRID_VIEW_SAVE_SUCCESS,
  RESEARCH_GRID_GRID_VIEW_UPDATE_SUCCESS,
} from '../../../constants/actionTypes';

export default function (state = {}, action) {
  switch (action.type) {
    case RESEARCH_GRID_GRID_VIEW_SUCCESS:
      return {
        ...state,
        gridViews: action.items
      };

    case RESEARCH_GRID_GRID_VIEW_SELECT:
      return {
        ...state,
        selectedGridViews: {
          [action.datatype]: {
            id: action.id,
            title: action.title
          }
        }
      };

    case RESEARCH_GRID_GRID_VIEW_SAVE_SUCCESS:
      return {
        ...state,
        gridViews: [
          ...state.gridViews,
          action.response
        ]
      };

    case RESEARCH_GRID_GRID_VIEW_UPDATE_SUCCESS:
      return {
        ...state,
        gridViews: [
          ...state.gridViews.filter((gv) => gv.id !== action.response.id),
          action.response
        ]
      };

    case RESEARCH_GRID_GRID_VIEW_DELETE_SUCCESS:
      return {
        ...state,
        gridViews: state.gridViews.filter((gv) => gv.id !== action.id)
      };

    default:
      return state;
  }
}


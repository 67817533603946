import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTracker } from '@colliers-international/usage-tracker';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import DisableIcon from '@mui/icons-material/PowerSettingsNew';
import ParcelsIcon from '../../assets/parcel-icon.svg';
import ShowFiltersIcon from '../../assets/filter-icon.svg';
import { COLLIERS_TEAL } from '../../styles/theme';
import styles from './module.css';

const ParcelMenu = ({
  classes, 
  disable,
  enable,
  filtersActive,
  isEnabled,
  offColor,
  onFilterClick
}) => {
  const [datatypeIconIsHovered, setDatatypeIconIsHovered] = useState(true);
  const [filterIconIsHovered, setFilterIconIsHovered] = useState(false);
  const [turnOffIconIsHovered, setTurnOffIconIsHovered] = useState(false);
  const { trackEvent } = useTracker();

  const backgroundColor = isEnabled ? COLLIERS_TEAL : offColor;

  const disableParcelModules = () => {
    trackEvent({ category: 'Discover Menu', action: 'Clicked', name: 'Toggle Parcels Module Off', value: 0 });
    disable();
  };

  return (
    <div className={classes.datatype}>
      <div className={classes.datatypeButton}>
        <IconButton
          onClick={enable}
          onMouseEnter={() => setDatatypeIconIsHovered(true)}
          onMouseLeave={() => setDatatypeIconIsHovered(false)}
          style={{
            color: 'white',
            backgroundColor,
            height: '40px',
            padding: 0,
            width: '40px',
          }}>
          <Badge 
            badgeContent={(filtersActive && isEnabled) ?
              <img src={ShowFiltersIcon} style={{ width: '6px', height: '6px' }} alt="Filter Active" /> :
              0
            }
            color="error"                 
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            sx={{
              ['& .MuiBadge-badge']: {
                left: '12px',
                bottom: '26px',
                border: '1px solid white',
                minWidth: '15.4px',
                height: '15.4px',
                padding: '0 0px',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                fontSize: '0.6rem',
                fontWeight: 'bold',
              }
            }}>
            <img src={ParcelsIcon} alt="Filters" style={{ backgroundColor, color: 'white' }} />
          </Badge>
        </IconButton>
      </div>
      <div className={classes.disabledDatatypeTooltip}>
        {!isEnabled && datatypeIconIsHovered && 'Parcels'}
      </div>
      <div className={isEnabled && datatypeIconIsHovered ? classes.enabledDatatypeTooltip : ''}>
        {isEnabled && datatypeIconIsHovered && 'Parcels'}
      </div>
      <div className={isEnabled && filterIconIsHovered ? classes.filterIconTooltip : ''}>
        {isEnabled && filterIconIsHovered && 'Filters'}
      </div>
      <div className={isEnabled && turnOffIconIsHovered ? classes.turnOffIconTooltip : ''}>
        {isEnabled && turnOffIconIsHovered && 'Turn Off'}
      </div>
      <aside>
        {isEnabled && (
          <React.Fragment>
            <Fab
              onClick={onFilterClick('parcel')}
              onMouseEnter={() => setFilterIconIsHovered(true)}
              onMouseLeave={() => setFilterIconIsHovered(false)}
              className={classes.datatypeButton}
              size="small"
              style={{ backgroundColor, color: 'white' }}>
              <Badge 
                badgeContent={filtersActive}
                color="error"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                sx={{
                  ['& .MuiBadge-badge']: {
                    left: '12px',
                    bottom: '26px',
                    border: '1px solid white',
                    minWidth: '15.4px',
                    height: '15.4px',
                    padding: '0 0px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                    fontSize: '0.6rem',
                    fontWeight: 'bold',
                  }
                }}>
                <img src={ShowFiltersIcon} alt="Filters" />
              </Badge>
            </Fab>
            <Fab
              onClick={disableParcelModules}
              onMouseEnter={() => setTurnOffIconIsHovered(true)}
              onMouseLeave={() => setTurnOffIconIsHovered(false)}
              className={classes.datatypeButton}
              size="small"
              style={{ backgroundColor, color: 'white', left: 0 }}>
              <DisableIcon />
            </Fab>
          </React.Fragment>
        )}
      </aside>
    </div>
  );
};

ParcelMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  disable: PropTypes.func.isRequired,
  enable: PropTypes.func.isRequired,
  filtersActive: PropTypes.number.isRequired,
  isEnabled: PropTypes.bool.isRequired,
  location: PropTypes.string.isRequired,
  offColor: PropTypes.string.isRequired,
  onFilterClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(ParcelMenu);

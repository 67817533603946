import {
  DETAIL_SHOW_PROBLEM_REPORTER,
  DETAIL_HIDE_PROBLEM_REPORTER,
  DETAIL_REPORT_PROBLEM_REQUEST,
  DETAIL_REPORT_PROBLEM_SUCCESS,
  DETAIL_REPORT_PROBLEM_ERROR,
} from '../../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest
} from '../../common/apiActions';
import api from '../../services/api';

export function showProblemReporter(subitem) {
  return {
    type: DETAIL_SHOW_PROBLEM_REPORTER,
    subitem: subitem && subitem.id ? subitem : null
  };
}

export function hideProblemReporter() {
  return {
    type: DETAIL_HIDE_PROBLEM_REPORTER,
  };
}

function reportProblemRequest(problemItem, problem) {
  return {
    type: DETAIL_REPORT_PROBLEM_REQUEST,
    problemItem,
    problem
  };
}

function reportProblemSuccess(response) {
  return {
    type: DETAIL_REPORT_PROBLEM_SUCCESS,
    response,
  };
}

export function reportProblem(problemItem, problem) {
  return (dispatch) => {
    dispatch(reportProblemRequest(problemItem, problem));
    return api.create('/problem', {
      currentLink: window.location.href,
      reportedItemType: problemItem._type,
      reportedItemId: problemItem.id,
      report: problem
    })
      .then((data) => {
        return dispatch(reportProblemSuccess(data));
      })
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }
        return dispatch(handleError(DETAIL_REPORT_PROBLEM_ERROR, response));
      });
  };
}
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import calculateDistance from '../common/calculateDistance';
import calculatePolygonDistance from '../common/calculatePolygonDistance';
import layoutStyles from '../styles/layout.module.css';
import Filters from './Filters';
import FeedList from './FeedList';
import Locations from './Locations';
import QuickView from './QuickView';
import styles from './module.css';

const EMAIL_FREQUENCIES = [
  { label: 'Daily', value: ['Mo', 'Tu', 'We', 'Th', 'Fr'] },
  { label: 'Twice Weekly', value: ['Mo', 'Th'] },
  { label: 'Weekly', value: ['Mo'] },
  { label: 'None', value: [] },
];

const Feed = ({
  classes,
  currentUser,
  location,
  router,
  ...props
}) => {
  const [frequencyMenuAnchor, setFrequencyMenuAnchor] = useState();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

  const favorites = currentUser.tags.filter((tag) => tag.type === 'favorite');
  const updateFilters = (filter) => router.push({ ...location, query: { filter } });
  const updateEmailFrequency = (frequency) => props.updateCurrentUser({ frequency });
  const updateFollow = (updatedFollow) => {
    const follows = currentUser.follows.map((item) => (item.id === updatedFollow.id) ? updatedFollow : item);
    props.updateCurrentUser({ follows });
  };

  const handleMobileDrawerToggle = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  const handleSettingsDialogToggle = () => {
    setSettingsDialogOpen(!settingsDialogOpen);
  };

  const handleSettingsMenuClick = (event) => {
    setFrequencyMenuAnchor(event.currentTarget);
  };
  const handleSettingsMenuClose = () => {
    setFrequencyMenuAnchor(null);
  };
  const handleFrequencyChange = (value) => () => {
    updateEmailFrequency(value);
    handleSettingsMenuClose();
  };

  const items = props.items.map((item) => {
    let distances = [];

    // for tim, find the closest followed location / shape-centroid combo for each item
    if (item._type === 'tim') {
      item.searchShapes.coordinates.forEach((shapeHoleCombo) => {
        distances = distances.concat(
          currentUser.follows.map((follow) => {
            const [followLat, followLng] = follow.location;
            return {
              title: follow.title || follow.address,
              distance: calculatePolygonDistance({ lat: followLat, lng: followLng }, shapeHoleCombo[0])
            };
          })
        );
      });

    // for propertIES-based items, find closest followed location to item location
    } else if (item.properties) {
      distances = currentUser.follows.map((follow) => {
        const [followLat, followLng] = follow.location;
        return {
          title: follow.title || follow.address,
          distance: item.properties.reduce((acc, { location: [lat, lng] }) => Math.min(
            acc,
            calculateDistance({ lat, lng }, { lat: followLat, lng: followLng })
          ), Infinity)
        };
      });

    // for property-based items, find closest followed location to item location
    } else {
      const [lat, lng] = item.location;
      distances = currentUser.follows.map((follow) => {
        const [followLat, followLng] = follow.location;
        return {
          title: follow.title || follow.address,
          distance: calculateDistance({ lat, lng }, { lat: followLat, lng: followLng })
        };
      });
    }

    item.closest = distances.sort((a, b) => a.distance - b.distance)[0];
    return item;
  });

  const quickViewsContent = useMemo(() => (
    <React.Fragment>
      <Paper elevation={4} square style={{ flex: 1, position: 'relative' }}>
        <Hidden mdUp>
          <IconButton onClick={handleMobileDrawerToggle} style={{ position: 'absolute', top: -3, right: 0 }}>
            <CloseIcon />
          </IconButton>
        </Hidden>
        <QuickView
          showProblemReporter={props.showProblemReporter}
          source="user/feed/listings"
          title="My Listings"
          type="listing" />
      </Paper>
      <Hidden smDown>
        <div style={{ height: 12 }} />
      </Hidden>
      <Paper elevation={4} square style={{ flex: 1, position: 'relative' }}> 
        <QuickView
          showProblemReporter={props.showProblemReporter}
          source="user/feed/critical"
          title="My Lease Expirations"
          type="lease" />
      </Paper>
    </React.Fragment>
  ));

  return (
    <div className={cx(layoutStyles.container, layoutStyles.row)}>
      <div className={cx(layoutStyles.container, layoutStyles.column)} style={{ flexBasis: '100%', width: 'auto' }}>
        <Filters
          toggleMobileDrawer={handleMobileDrawerToggle}
          toggleSettingsMenu={handleSettingsDialogToggle}
          updateEmailFrequency={updateEmailFrequency}
          onFilterChange={updateFilters}
          filter={props.filter}
          visibleDatatypes={props.visibleDatatypes}
          datatypes={props.datatypes} />
        <FeedList
          scrollOverscan={5}
          hasCompleteList={props.hasCompleteList}
          isFetching={props.isFetching}
          favorites={favorites}
          items={items}
          createFavorite={props.createFavorite}
          removeFavorite={props.removeFavorite}
          selectedItem={props.selectedItem}
          shareItem={props.shareItem}
          sendShareItem={props.sendShareItem}
          hideShareItem={props.hideShareItem}
          showShareItem={props.showShareItem}
          onLoadMore={props.updateFeedListOffset}
          onFeedItemToggle={props.toggleFeedItem} />
      </div>

      <Hidden smDown>
        <div className={classes.quickViewsColumn}>
          <Toolbar className={classes.quickViewsFakeToolbar} />
          <div className={classes.quickViewsContainer}>
            {quickViewsContent}
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <Drawer
          anchor="right"
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}
          onClose={handleMobileDrawerToggle}
          open={mobileDrawerOpen}
          variant="temporary">
          {quickViewsContent}
        </Drawer>
      </Hidden>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={settingsDialogOpen}
        onClose={handleSettingsDialogToggle}
        ModalProps={{ keepMounted: true }}>
        <div style={{ minHeight: 300, maxHeight: '100%', overflowY: 'auto' }}>
          <Locations
            items={currentUser.follows}
            location={location}
            onLocationUpdate={updateFollow}
            onAddLocation={props.addLocation}
            onRemoveLocation={props.removeLocation}
            selectedLocationId={props.selectedLocationId}
            summary={props.summary} />
        </div>
        <DialogActions style={{ borderTop: '1px solid #e0e0e0' }}>
          <Button
            onClick={handleSettingsMenuClick}
            variant="outlined">
            Email Frequency
          </Button>
          <Menu
            anchorEl={frequencyMenuAnchor}
            keepMounted
            open={Boolean(frequencyMenuAnchor)}
            onClose={handleSettingsMenuClose}>
            {EMAIL_FREQUENCIES.map(({ label, value }) => (
              <MenuItem
                key={value}
                onClick={handleFrequencyChange(value)}>
                {value.join(',') === currentUser.frequency.join(',') && (
                  <ListItemIcon style={{ minWidth: 40 }}><CheckIcon /></ListItemIcon>
                )}
                {label}
              </MenuItem>
            ))}
          </Menu>
          <div style={{ flex: 1 }} />
          <Button
            color="primary"
            onClick={handleSettingsDialogToggle}
            variant="contained">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Feed.propTypes = {
  addLocation: PropTypes.func.isRequired,
  classes: PropTypes.object,
  createFavorite: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  datatypes: PropTypes.object.isRequired,
  filter: PropTypes.string,
  hasCompleteList: PropTypes.bool,
  hideShareItem: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  removeLocation: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  selectedItem: PropTypes.string,
  selectedLocationId: PropTypes.string,
  sendShareItem: PropTypes.func.isRequired,
  shareItem: PropTypes.string,
  showProblemReporter: PropTypes.func.isRequired,
  showShareItem: PropTypes.func.isRequired,
  summary: PropTypes.object,
  toggleFeedItem: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
  updateFeedListOffset: PropTypes.func.isRequired,
  visibleDatatypes: PropTypes.array.isRequired,
};

export default withStyles(styles)(Feed);

export default () => ({
  accountSettingsRow: {
    marginBottom: 20
  },
  ilOuter: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  ilWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center'
  },
});
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ResetIcon from '@material-ui/icons/Refresh';

const ResetFiltersButton = ({ datatypeKey, resetFilters, searchesFilters }) => {
  const [hasFilters, setHasFilters] = useState(false);

  const checkHasFilters = (filterObject) => {
    if (datatypeKey === 'availability') {
      const { areaAndMinDivisibleArea, ...remainingFilters } = filterObject;
      for (const key in areaAndMinDivisibleArea) {
        if (key === 'area' && Object.keys(areaAndMinDivisibleArea[key]).length > 0) {
          return true;
        }
        if (key === 'minDivisibleArea' && Object.keys(areaAndMinDivisibleArea[key]).length > 0) {
          return true;
        }
      }
      for (const value of Object.values(remainingFilters)) {
        if ((Array.isArray(value) && value.length > 0) ||
          (typeof value === 'string' && value.length > 0) ||
          (typeof value === 'object' && value !== null && Object.keys(value).length > 0)) {
          return true;
        }
      }
    } else {
      for (const value of Object.values(filterObject)) {
        if ((Array.isArray(value) && value.length > 0) ||
          (typeof value === 'string' && value.length > 0) ||
          (typeof value === 'object' && value !== null && Object.keys(value).length > 0)) {
          return true;
        }
      }
    } 
    return false;
  };

  useEffect(() => {
    if (searchesFilters) {
      setHasFilters(checkHasFilters(searchesFilters));
    }
  }, [searchesFilters]);

  const handleClick = () => {
    if (datatypeKey === 'availability') {
      const { areaAndMinDivisibleArea, ...restFilters } = searchesFilters;
      if (areaAndMinDivisibleArea) {
        searchesFilters.areaAndMinDivisibleArea.andOrChecked = 'AND';
        searchesFilters.areaAndMinDivisibleArea.area = {};
        searchesFilters.areaAndMinDivisibleArea.minDivisibleArea = {};
      } else {
        resetFilters(restFilters);
      }
    } resetFilters();
  };

  return hasFilters
    ? (
      <Button
        fullWidth  
        onClick={handleClick}
        startIcon={<ResetIcon />}
        style={{ position: 'relative', zIndex: 2 }}
        variant="contained">Reset All Filters</Button>
    )
    : (<span />);
};

ResetFiltersButton.propTypes = {
  datatypeKey: PropTypes.string.isRequired,
  hasFilters: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  searchesFilters: PropTypes.object.sRequired,
};

export default ResetFiltersButton;

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import FieldNote from '../../../common/stats/FieldNote';
import { withStyles } from '@material-ui/core';
import styles from '../module.css';

const Features = ({ classes, features, fieldNote }) => {
  const getInfoStatFieldNote = (fieldName, extraStyles) => {
    if (fieldNote) {
      return (
        <FieldNote content={fieldNote} style={extraStyles} />
      );
    }
    return null;
  };

  return (
    <section>
      <Paper elevation={1} className={classes.sectionPaper}>
        <div>
          <Typography className={classes.sectionHeader} variant="subtitle1">
            Features and Amenities {getInfoStatFieldNote('features')}
          </Typography>
        </div>
        <div className={classes.featuresWrapper}>
          <div className={classes.features}>
            {features && features.map((feature, index) => (
              <Chip key={index} label={feature} />))}
          </div>
        </div>
      </Paper>
    </section>
  );
};

Features.propTypes = {
  classes: PropTypes.object,
  features: PropTypes.array,
  fieldNote: PropTypes.string
};

export default withStyles(styles)(Features);

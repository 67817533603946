import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const RangeTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: 0
  }
});

const LotSizeRange = ({ onFilterChange, title, setFilter, getSelectedFilters, filterType }) => {
  const savedSelections = getSelectedFilters();
  const filter = savedSelections && savedSelections[title] ? savedSelections[title] : [];
  const min = filter ? filter[0] : undefined;
  const max = filter ? filter[1] : undefined;
  const [minVal, setMinVal] = useState(min || undefined);
  const [maxVal, setMaxVal] = useState(max || undefined);

  const updateFilter = (min, max) => {
    if (onFilterChange) {
      const filter = (value) => {
        const val = parseFloat(value);
        if ((min || max) && !val) {
          return false;
        }
        
        if (min && (val) < min) {
          return false;
        }

        if (max && (val) > max) {
          return false;
        }

        return true;
      };

      filter.isDirty = min || max;

      onFilterChange(filter);
      setFilter(title, [min, max], filterType);
    }
  };

  const handleMinBlur = (ev) => {
    const minValue = parseFloat(ev?.target?.value) || undefined;
    setMinVal(minValue);
    updateFilter(minValue, maxVal);
  };

  const handleMinChange = (ev) => {
    setMinVal(parseFloat(ev?.target?.value) || undefined);
  };

  const handleMaxBlur = (ev) => {
    const maxValue = parseFloat(ev?.target?.value) || undefined;
    setMaxVal(maxValue);
    updateFilter(minVal, maxValue);
  };

  const handleMaxChange = (ev) => {
    setMaxVal(parseFloat(ev?.target?.value) || undefined);
  };

  const handleKeyDown = (ev) => {
    if (['Enter'].includes(ev?.key)) {
      updateFilter(minVal, maxVal);
    }
  };

  return (
    <section>
      <div className="sk-panel" style={{ padding: '8px 24px' }}>
        <div className="sk-panel__header">
          {title}
        </div>
        <div className="sk-panel__content">
          <span style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
            <RangeTextField
              margin="dense"
              onBlur={handleMinBlur}
              onChange={handleMinChange}
              onKeyDown={handleKeyDown}
              placeholder="Min"
              size="small"
              type="number"
              value={minVal}
              variant="outlined" 
              inputProps={{ step: 0.01, min: 0.0 }} />
            <span style={{ padding: '0 10px' }}>&ndash;</span>
            <RangeTextField
              margin="dense"
              onBlur={handleMaxBlur}
              onChange={handleMaxChange}
              onKeyDown={handleKeyDown}
              placeholder="Max"
              size="small"
              type="number"
              value={maxVal}
              variant="outlined"
              inputProps={{ step: 0.01, min: 0.0 }} />
          </span>
        </div>
      </div>
    </section>
  );
};

LotSizeRange.propTypes = {
  filterType:  PropTypes.string,
  getSelectedFilters: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  setFilter: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default LotSizeRange;

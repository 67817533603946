import React from 'react';
import { renderToString } from 'react-dom/server';
import { diff } from 'deep-object-diff';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import plur from 'plur';
import translate from '../translate';

// Leaflet requires a string representation of the popup so this creates a string version of the svg icon
export function createMapIcon(OriginalIcon) {
  return renderToString(
    <OriginalIcon style={{ width: '15px', height: '15px', color: 'white' }} />
  );
}

export default function createDatatype(key, config = {}) {
  const schema = require(`./schemas/_${key}`);
  const singular = translate(config.singular) || translate(startCase(key));
  const plural = translate(config.plural) || translate(plur(singular));
  const resource = (config.resource) || (plur(key));

  const detailMap = new Map([
    ['availability', 'Available Suites'],
    ['listing', 'Sale Details'],
    ['lease', `${plural}`],
    ['sale', `${plural}`]
  ]);
  const nearbyMap = new Map([
    ['availability', 'Nearby Lease Availabilities'],
    ['listing', 'Nearby Listings']
  ]);                           
  const detailLabel = (key === 'availability') 
    || (key === 'listing')
    || (key === 'sale')
    || (key === 'lease')
    ?  detailMap.get(key)
    :  `Property ${plural}`;
  const nearbyLabel = (key === 'availability') || (key === 'listing')
    ?  nearbyMap.get(key)
    :  `Nearby ${plural}`;
  const getEnabledFilters = (filters) => {
    return Object.keys(filters).reduce((acc, f) => {
      if (!isEmpty(filters[f])) {
        acc[f] = filters[f];
      }
      return acc;
    }, {});
  };
  const getNonDefaultFilters = (filters) => {
    return diff(config.defaultFilters, getEnabledFilters(filters));
  };
  const hasOnlyDefaultFilters = (filters) => {
    return isEmpty(getNonDefaultFilters(filters));
  };
  return Object.assign({
    ...schema,
    key,
    singular,
    plural,
    resource,
    permissionKey: plural.toLowerCase(),
    permission: [`/${plural.toLowerCase()}`, 'get'],
    preferenceScope: ['Multi-Family', 'Office', 'Industrial', 'Retail', 'Hospitality', 'Medical'],
    isShownInFeed: false,
    mapIcon: createMapIcon(config.Icon),
    hasNearby: true,
    detailTab: detailLabel,
    nearbyTab: nearbyLabel,
    getEnabledFilters,
    getNonDefaultFilters,
    hasOnlyDefaultFilters,
  }, config);
}

import React from 'react';
import { InputFilter } from 'searchkit-react16';

// this filter does not use searchkit's internal onChange searching because
// the debouncing is buggy (and causes disappearing characters, weird typing
// issues on entry); instead, searchOnChange is disabled and we do our own
// debouncing using the search triggered by "onblur" (a little hacky, but 
// hopefully won't be needed after searchkit upgrade)
class CustomInputFilter extends InputFilter {
  throttle;

  onChange(e) {
    super.onChange(e);
    if (this.throttle) {
      clearTimeout(this.throttle);
      this.throttle = null;
    }
    this.throttle = setTimeout(() => {
      this.setFocusState(false);
      this.setFocusState(true);
    }, 250);
  }
}

const CustomInputFilterWithProps = (props) => (
  <CustomInputFilter
    {...props}
    searchOnChange={false} />
);

export default CustomInputFilterWithProps;

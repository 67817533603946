import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import store from '../store';
import {
  withStyles,
  FormControl,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Delete, 
  DragHandle as DragHandleIcon,
  GridOn as GridOnIcon,
  Map as MapIcon,
} from '@material-ui/icons';
import { getParcelPath } from '../common/getParcelPath';
import api from '../services/api';
import { enableLeaseExpirations } from '../research/searches/actions';
import styles from './module.css';
import SortIconButton from './common/SortIcon';

const INITIAL_LOAD_TALLY = 10;

function formatTenant(tenant) {
  return tenant ? tenant : '-';
}

function formatRentRate(rentRate) {
  return rentRate || rentRate === 0
    ? `$${parseFloat(rentRate).toFixed(2)}`
    : '-';
}

function formatArea(area) {
  return area || area === 0 ? `${area.toLocaleString()}` : '-';
}

function formatLeaseTerm(leaseTerm) {
  return leaseTerm || leaseTerm === 0 ? `${leaseTerm} mos` : '-';
}

function formatExpirationDate(expirationDate) {
  return expirationDate ? moment(expirationDate).format('MM/DD/YYYY') : '-';
}

const columns = [
  {
    id: 'tenant',
    label: 'Tenant',
    width: 150,
    valueWidth: '60%',
    align: 'left',
    sortType: 'string',
  },
  { 
    id: 'address', 
    label: 'Address',
    align: 'left',
    width: 350, 
    sortType: 'string' 
  },
  {
    id: 'rentRate',
    label: 'Rental Rate / SF',
    align: 'left',
    width: 200,
    valueWidth: '50%',
    sortType: 'number',
  },
  {
    id: 'area',
    label: 'Leased Area SF',
    align: 'left',
    width: 200,
    valueWidth: '100%',
    sortType: 'number',
  },
  {
    id: 'leaseTerm',
    label: 'Term',
    align: 'left',
    width: 150,
    sortType: 'number',
  },
  { id: 'expirationDate', 
    label: 'End Date', 
    align: 'left',
    width: 150, 
    sortType: 'date' 
  },
];

function createData(
  tenant = '',
  address = '',
  rentRate = '',
  area = '',
  leaseTerm = '',
  expirationDate = '',
  id = '',
  propertyHash = '',
  property = []
) {
  return {
    tenant: formatTenant(tenant),
    address,
    rentRate: formatRentRate(rentRate),
    area: formatArea(area),
    leaseTerm: formatLeaseTerm(leaseTerm),
    expirationDate: formatExpirationDate(expirationDate),
    id,
    propertyHash,
    property
  };
}

const LeaseExpirationsWidget = ({
  classes,
  enableDatatype,
  onRemove,
  onMapViewClick,
  onTableViewClick,
  router,
  title,
  widgetId
}) => {
  const [records, setRecords] = useState(localStorage.getItem(`records_${widgetId}`) || '3months');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState({
    id: 'tenant',
    order: 'asc',
  });
  const [showPagination, setShowPagination] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);

  const rows = data.map((row) => {
    return createData(
      row.tenant && row.tenant.name ? row.tenant.name : '',
      row.property && row.property.address ? row.property.address : '',
      row.rentRate ? row.rentRate : '',
      row.area ? row.area : '',
      row.leaseTerm ? row.leaseTerm : '',
      row.expirationDate ? row.expirationDate : '',
      row.id ? row.id : '',
      row.property && row.property.hash ? row.property.hash : '',
      row.property ? row.property : []
    );
  });

  const sortedRows = useMemo(() => {
    const sortableRows = [...rows].sort((a, b) => {
      const column = columns.find((col) => col.id === sortColumn.id);
      let valueA = a[column.id];
      let valueB = b[column.id];

      const extractNumber = (value) => {
        return parseFloat(value.replace(/[^\d.]/g, ''));
      };

      if (valueA === '-') return sortColumn.order === 'asc' ? 1 : -1;
      if (valueB === '-') return sortColumn.order === 'asc' ? -1 : 1;
      
      if (column.id === 'address') {
        valueA = valueA.split(' ').slice(1).join(' ');
        valueB = valueB.split(' ').slice(1).join(' ');
      }

      if (['rentRate', 'area', 'leaseTerm'].includes(column.id)) {
        valueA = extractNumber(valueA);
        valueB = extractNumber(valueB);
      }

      let compareResult;

      switch (column.sortType) {
        case 'number':
          compareResult = sortColumn.order === 'asc' ? valueA - valueB : valueB - valueA;
          break;
        case 'date':
          compareResult = sortColumn.order === 'asc'
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
          break;
        case 'string':
        default:
          compareResult = sortColumn.order === 'asc'
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
          break;
      }

      return compareResult;
    });

    return sortableRows;
  }, [rows, sortColumn]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortClick = (columnId) => () => {
    setSortColumn((prevSortColumn) => {
      if (prevSortColumn.id === columnId) {
        return { id: columnId, order: prevSortColumn.order === 'asc' ? 'desc' : 'asc' };
      }
      return { id: columnId, order: 'desc' };
    }
    );
  };

  const handleTableViewClick = () => {
    store.dispatch(enableLeaseExpirations('lease', data));
    onTableViewClick({ value: title, key: 'lease' });
  };

  const handleMapViewClick = () => {
    store.dispatch(enableLeaseExpirations('lease', data));
    onMapViewClick({ value: title, key: 'lease' });
  };

  const loadValue = async ({ value }) => {
    try {
      let record;

      if (rows && rows.length > 0) {
        record = rows.find((row) => (row.tenant === value));
      }
      const key = 'lease';
      const pathname = await getParcelPath({
        address: record.address || record.property.address,
        slug: `${record.propertyHash}|${record.property.rescourId}`,
        type: key
      });

      router.push({
        pathname,
        search: `?currentListView=${key}`,
        hash: `#18/${record.property.location[1]}/${record.property.location[0]}`,
        query: { currentListView: key }
      });
      store.dispatch(enableDatatype(key));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchMore = async (offset, limit) => {
      const params = { offset };
      if (limit) params.limit = limit;
      setHasLoaded(false);
      try {
        const data = await api.read('user/feed/critical', null, params);

        if (data && Array.isArray(data.items) && isMounted) {
          const currentDate = moment();
          let filteredData;

          switch (records) {
            case '3months':
              const after3Months = moment(currentDate).add(3, 'months');
              filteredData = data.items.filter((item) =>
                moment(item.expirationDate).isBetween(currentDate, after3Months)
              );
              break;
            case '6months':
              const after6Months = moment(currentDate).add(6, 'months');
              filteredData = data.items.filter((item) =>
                moment(item.expirationDate).isBetween(currentDate, after6Months)
              );
              break;
            case '12months':
              const after12Months = moment(currentDate).add(12, 'months');
              filteredData = data.items.filter((item) =>
                moment(item.expirationDate).isBetween(currentDate, after12Months)
              );
              break;
            case '18months':
              const after18Months = moment(currentDate).add(18, 'months');
              filteredData = data.items.filter((item) =>
                moment(item.expirationDate).isBetween(currentDate, after18Months)
              );
              break;
            default:
              filteredData = data.items;
          }
          setData(filteredData);
          setHasLoaded(true);
        }
      } catch (error) {
        console.error(error);
        setHasLoaded(true);
      }
    };

    fetchMore(0, INITIAL_LOAD_TALLY);
    setShowPagination(data.length > 0);
    return () => {
      isMounted = false;
    };
  }, [records, data.length]);

  const handleRecordChange = (event) => {
    setRecords(event.target.value);
    localStorage.setItem(`records_${widgetId}`, event.target.value.toString());
  };

  useEffect(() => {
    localStorage.setItem(`records_${widgetId}`, records.toString());
  }, [records]);

  const renderTableCellContent = (column, row, value) => {
    if (column.id === 'tenant') {
      return (
        <div style={{ maxWidth: '300px' }}>
          <Typography
            onClick={() => { loadValue({ value }); }}
            className={classes.loadValue}
            style={{ 
              overflow: 'hidden',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              height: '24px',
              whiteSpace: 'nowrap'
            }}>
            {value}
          </Typography>    
        </div>
      );
    }

    if (column.id === 'address') {
      return (
        <div style={{ maxWidth: '350px' }}>
          <Typography
            style={{ 
              overflow: 'hidden',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              height: '24px',
              whiteSpace: 'nowrap' 
            }}>
            {value}
          </Typography>    
        </div>
      );
    }

    return value;
  };

  const dragHandleButton =
    (
      <IconButton className={classes.dragHandle}>
        <DragHandleIcon className={'dashboard-widget-drag-handle'} />
      </IconButton>
    );

  const headerDropDown = (
    <div>
      <FormControl className={classes.expirationsDropdown}>
        <Select name="records" value={records} onChange={handleRecordChange}>
          <MenuItem value={'3months'}>Next 3 Months</MenuItem>
          <MenuItem value={'6months'}>Next 6 Months</MenuItem>
          <MenuItem value={'12months'}>Next 12 Months</MenuItem>
          <MenuItem value={'18months'}>Next 18 Months</MenuItem>
        </Select>
      </FormControl>
    </div>
  );

  const headerButtons = (
    <div className={classes.headerIcons}>
      <Tooltip title="GRID VIEW">
        <IconButton onClick={handleTableViewClick}>
          <GridOnIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="MAP VIEW">
        <IconButton onClick={handleMapViewClick}>
          <MapIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <Card className={classes.card} elevation={5}>
      <div className={classes.tableHeader}>
        <CardHeader
          action={dragHandleButton}
          title={title}
          className={classes.title} />
        {headerDropDown}
        {headerButtons}
      </div>

      {hasLoaded ? (<CardContent>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader className={classes.tableHeadRoot}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.sortType === 'number' ? 'right' : 'left'}
                      style={{ minWidth: column.width, borderBottom: '2px solid #25408F' }}>
                      {column.label}
                      <SortIconButton
                        columnId={column.id}
                        sortColumn={sortColumn}
                        handleSortClick={handleSortClick} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center', padding: '20px' }}>
                      <Typography variant="body1">
                        No data available for the selected time range.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow 
                          key={index}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell 
                                key={column.id} 
                                align={column.sortType === 'number' ? 'right' : 'left'} 
                                style={column.sortType === 'number' ?  { paddingRight: '64px' } : null}>
                                {renderTableCellContent(column, row, value)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={`${showPagination ? classes.tableFooter : classes.hidePagination}`}>
            {showPagination && (
              <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={sortedRows.length}
                labelRowsPerPage={'Rows per Page:'}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
            )}
            <Tooltip title="Remove Widget">
              <IconButton className={classes.removeButton} onClick={onRemove}> 
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
      </CardContent>) : (<div className={classes.spinner}><CircularProgress  /></div>)}
    </Card>
  );
};

LeaseExpirationsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  enableDatatype: PropTypes.func.isRequired,
  onMapViewClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTableViewClick: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  widgetId: PropTypes.number.isRequired
};

export default withStyles(styles)(LeaseExpirationsWidget);

import React from 'react';
import { Route } from 'react-router';
import AccountContainer from './Container';
import SavedSearchContainer from './SavedSearches/Container';
import MyListContainer from './MyLists/Container';
import MyAlertsContainer from './MyAlerts/Container';

export default (
  <Route path="account" components={{ main: AccountContainer }}>
    <Route path="savedsearches" component={SavedSearchContainer} />
    <Route path="mylists" component={MyListContainer} />
    <Route path="alerts" component={MyAlertsContainer} />
  </Route>
);

import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const ExternalLink = ({ children, text, url }) => {
  const onClick = (e) => {
    e.stopPropagation();
  };

  if (!text && !children) {
    text = url;
  }

  return (
    <Link
      href={url}
      onClick={onClick}
      target={'_blank'}
      underline="always">
      {children ? children : text}
    </Link>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  url: PropTypes.string.isRequired
};

export default ExternalLink;

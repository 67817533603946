/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import LayersIcon from '@material-ui/icons/Layers';
import RoomIcon from '@material-ui/icons/Room';
import { BaseMapsIcon, BasemapGallery } from './BaseLayers';
import { ControlContainer } from '@colliers-international/react-leaflet-components';
import { ButtonGroup, Button, Tooltip, Popper, ClickAwayListener } from '@material-ui/core';
import FeatureContainer from './FeatureContainer';
import { AuthFeatureButton } from './FeatureButton';
import SubmarketIcon from './../../../assets/sub-market.svg';

export const DemographicMenu = (props) => {
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const [placement, setPlacement] = useState();
  const { classes } = props;
  const closePopper = () => {
    setOpen(false);
    setValue(null);
  };

  const handleButtonSelect = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(value !== event.currentTarget.name);
    setPlacement(newPlacement);
    setValue(value !== event.currentTarget.name && event.currentTarget.name);
  };

  const selectedButton = (name) => {
    if (value === name) {
      return {
        backgroundColor: '#bcbcbc',
        color: 'white !important'
      };
    }
    return {};
  };
  return (
    <ControlContainer style={{ height: '40px', marginLeft: '10px' }}>
      <ButtonGroup
        size="small"
        variant="contained"
        aria-label="small button group"
        style={{ background: 'white' }}>
        <AuthFeatureButton
          className={classes.lightButton}
          name="Submarkets"
          title="Submarkets"
          onClick={handleButtonSelect('bottom-end')}
          style={selectedButton('Submarkets')}>
          <img src={SubmarketIcon} style={{ height: '20px' }} />
        </AuthFeatureButton>
        <AuthFeatureButton
          className={classes.lightButton}
          name="layers"
          title="Demographics"
          onClick={handleButtonSelect('bottom-end')}
          style={selectedButton('layers')}>
          <LayersIcon />
        </AuthFeatureButton>
        <AuthFeatureButton
          className={classes.lightButton}
          name="points-of-interest"
          title="Points of Interest"
          onClick={handleButtonSelect('bottom-end')}
          style={selectedButton('points-of-interest')}>
          <RoomIcon />
        </AuthFeatureButton>
        <Tooltip arrow title="Basemaps">
          <Button
            className={classes.lightButton}
            name="basemap"
            onClick={handleButtonSelect('bottom-end')}
            style={selectedButton('basemap')}><BaseMapsIcon />
          </Button>
        </Tooltip>
      </ButtonGroup >
      {open && <ClickAwayListener
        onClickAway={closePopper}>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          className={classes.toolTipContainer}
          modifiers={{
            arrow: {
              enabled: true,
              element: arrowRef
            }
          }}>
          {open && <span ref={setArrowRef} className={classes.arrow} />}
          <MenuContent
            selected={value}
            close={closePopper}
            {...props} />
        </Popper>
      </ClickAwayListener>}
    </ControlContainer>
  );
};

const MenuContent = ({ selected, close, ...rest }) => {
  const { setBasemap, basemapLayer } = rest;
  if (selected === 'basemap') {
    return <BasemapGallery setBasemap={setBasemap} close={close} basemapLayer={basemapLayer} />;
  }
  return <FeatureContainer close={close} {...rest} featureType={selected} />;
};


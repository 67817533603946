import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';
import AttachFile from '@material-ui/icons/AttachFile';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import striptags from 'striptags';
import { getParcelPath } from '../../common/getParcelPath';
import { notClickablePropertyDetail } from './Table';

export const formatContent = ({ column, item, dateFormat, ...props }) => {
  if (_.isNil(props.content)) return;
  const rawContent = props.content;
  let content;
  let overrideFormat;

  // string-like aggregation
  if (column.aggregation === 'concat') {
    content = [];

    // (array of arrays of already-formatted values)
    if (column.type === 'array' && column.format === 'array') {
      (rawContent || []).forEach((arr) => {
        (arr || []).forEach((val) => {
          if (val && (column.allowDuplicates || !content.includes(val))) {
            content.push(val);
          }
        });
      });

    // array of need-to-format values (i.e. aggregating signatories)
    } else {
      const otherColumnProps = { ...column };
      delete otherColumnProps.aggregation;
      delete otherColumnProps.allowDuplicates;
      (rawContent || []).forEach((val) => {
        const formattedVal = formatContent({
          column: otherColumnProps,
          content: val,
          item,
          dateFormat
        });
        if (formattedVal && (column.allowDuplicates || !content.includes(formattedVal))) {
          content.push(formattedVal);
        }
      });
      overrideFormat = 'aggArray';
    }

  // additive aggregation
  } else if (column.aggregation === 'add') {
    content = rawContent.reduce((acc, cur) => acc + (cur || 0), 0) || '';

  // nested objects in an array (custom ids)
  } else if (column.key === 'customId') {
    content = rawContent.map((item) => `${item.name}: ${item.id}`);
    if (content.length > 1) {
      content = content.join('; ');
    }

  // (no aggregation)
  } else {
    content = rawContent;
  }

  switch (overrideFormat || column.format) {
    case 'signatory':
      return content.name;

    case 'signatories':
      return Array.from(content)
        .map((value) => value.name)
        .join('; ');

    case 'aggArray':
      if (content.length === 0) return '';
      return Array.from(content).join('; ');

      // case 'tourDates':
      //   if (value.length === 0) return '';
      //   return value.map((v) => formatStat(v.date, { format: 'date' })).join(' / ');

    case 'object':
      if (column.key === 'property') {
        if (content.canonicalName) return content.canonicalName;
        return _(content).at(['name', 'address'])
          .compact()
          .join(' - ');
      }
      return content.toString();

    case 'currency':
      return numeral(content).format('$0,0.00[0]');

    case 'number':
      if (column.key === 'escalationRate' && item.escalationType === 'percent') {
        return numeral(content).format('0.[00000]%');
      }
      return content.toLocaleString();

    case 'parkingRatio':
      return `${content}/1000`;

    case 'ratio':
      return numeral(content).format('0.[00000]%');

    case 'date':
    case 'date-time':
      return moment(content).format(dateFormat ? dateFormat : 'MMM Do, YYYY');

    case 'company':
      return content.name;

    case 'boolean':
      return (content) ? 'Yes' : 'No';

    case 'spaceUses':
      if (content.length === 0) return '';
      return content.map(({ type }) => type).join('; ');

    case 'officeSqft':
      return ((content || []).find(({ type }) => /office/i.test(type)) || {}).area;

    case 'rentSchedule':
      return Array.from(content)
        .map((value) => `Start: ${value.start}, End: ${value.end}, Rate: ${value.rate}`)
        .join('; ');

    case 'phoneNumbers':
      if (content.length === 0) return '';
      return content.map((phone) => phone.number).join(', ');
  
    case 'parkingDetails':
      if (content.length === 0) return '';
      return content.map((parking) => parking.type).join(', ');

    case 'unitMixes':
      if (content.length === 0) return '';
      return content.map((unit) => `${unit.area}(sf) / ${unit.price}`).join('; ');
      
    default:
      return striptags(content.toString());
  }
};

const Cell = ({
  classes,
  column,
  content = null,
  currentListView,
  toggleSelectedItem,
  row,
  isTitleCell }) => {
  const showDetails = async () => {
    const id = row.id || '';
    const property = (currentListView === 'property') ? row : row.property || row.properties[0];
    const hash = property.hash || '';
    const rescourId = property.rescourId || row.id;
    const slug = `${hash}|${rescourId}`;

    const pathname = await getParcelPath({
      address: property.address || row.address,
      id,
      slug,
      type: currentListView,
      view: 'table',
    });

    browserHistory.push({
      ...location,
      pathname
    });
  };

  if (column.format === 'checkbox') {
    return (
      <Checkbox
        checked={content}
        color="default"
        onChange={toggleSelectedItem}
        style={{ marginLeft: -6 }} />
    );
  }

  if (column.format === 'documents') {
    return (
      <span className={classes.documentsTableCellWrapper}>
        <AttachFile fontSize="inherit" />
        <span className={classes.documentsTableCellContent}>{content ? content.length : '0'}</span>
      </span>
    );
  }

  if (isTitleCell && !(notClickablePropertyDetail(currentListView))) {
    if (_.isNil(content)) { return <span style={{ width: '100%', height: '100%' }} onClick={showDetails} />; }
    return (
      <span onClick={showDetails} style={{ width: '100%' }}>
        {formatContent({ column, content, item: row, dateFormat: 'MMM Do, YYYY' })}
      </span>
    );
  }

  // Virtual-Type in CURA-API using spaceUses (content reassigned to hit formatContent case)
  if (column.key === 'officeSqft') content = row.spaceUses;

  if (_.isNil(content)) { return <span />; }
  return <span>{formatContent({ column, content, item: row, dateFormat: 'MMM Do, YYYY' })}</span>;
};

Cell.propTypes = {
  classes: PropTypes.object,
  column: PropTypes.object.isRequired,
  columnIndex: PropTypes.number,
  content: PropTypes.any,
  currentListView: PropTypes.string.isRequired,
  isTitleCell: PropTypes.bool,
  row: PropTypes.object,
  rowIndex: PropTypes.number,
  toggleSelectedItem: PropTypes.func.isRequired,
};

export default Cell;

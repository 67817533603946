import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import TextTruncate from 'react-text-truncate';
import { makeStyles } from '@material-ui/core/styles';
import Stat from '../../../common/stats/Stat';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const PrimaryText = ({ name }) => {
  const classes = useStyles();
  return (
    <div className={classes.primaryText}>
      <TextTruncate text={name || '\u2013'} />
    </div>
  );
};

PrimaryText.propTypes = {
  name: PropTypes.string,
};

export const SecondaryText = ({ developer, property }) => {
  const classes = useStyles();
  const address = get(property, 'address', '\u2013');
  const developerDisplay = get(developer, 'name');
  return (
    <React.Fragment>
      <div className={classes.secondaryText}>
        <p>{address}</p>
      </div>
      <div>
        {developerDisplay && (
          <Stat
            className={classes.stat}
            label="Developer"
            marginValue="5px"
            value={developerDisplay} />
        )}
      </div>
    </React.Fragment>
  );
};

SecondaryText.propTypes = {
  area: PropTypes.number,
  deliveryDate: PropTypes.string,
  developer: PropTypes.object,
  developmentStatus: PropTypes.string,
  property: PropTypes.object,
  propertyTypes: PropTypes.array,
  units: PropTypes.number,
};

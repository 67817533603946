import fileSaver from 'file-saver';
import {
  ANALYSIS_REPORT_REQUEST,
  ANALYSIS_REPORT_SUCCESS,
  ANALYSIS_REPORT_ERROR,
  ANALYSIS_REPORT_ERROR_DISMISSED
} from '../../constants/actionTypes';
import {
  unauthorizedRequest,
  requestData,
  receiveData
} from '../../common/apiActions';
import api from '../../services/api';

export function fetchAnalysisPdf(fullHtml, baseHref, targetSelector, extraCss) {
  return (dispatch) => {
    dispatch(requestData(ANALYSIS_REPORT_REQUEST));
    return api.createBinary('/pdf', { fullHtml, baseHref, targetSelector, extraCss, cssDpi: 140 })
      .then((data) => {
        dispatch(receiveData(ANALYSIS_REPORT_SUCCESS, data));
        return data.blob();
      })
      .then((blob) => {
        fileSaver.saveAs(blob, `lease-analysis-${Date.now()}.pdf`, { type: 'application/pdf' });
      })
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }
        
        // error display
        dispatch({
          type: ANALYSIS_REPORT_ERROR,
          message: `PDF generation error: "${response.statusText}"`
        });

        // timeout error display
        setTimeout(() => {
          dispatch({ type: ANALYSIS_REPORT_ERROR_DISMISSED });
        }, 5000);
      });
  };
}

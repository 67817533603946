import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment';

const InputWrapper = styled('span')({
  alignItems: 'center',
  border: '1px solid #cacccd',
  display: 'flex',
  flexDirection: 'row',
  padding: '0 10px'
});

const DateTextField = styled(TextField)({
  ['& .MuiOutlinedInput-root']: {
    borderRadius: 0
  }
});

const DateRange = ({ onFilterChange, title, setFilter, getSelectedFilters, filterType }) => {
  const savedSelections = getSelectedFilters();
  const filter = savedSelections && savedSelections[title] ? savedSelections[title] : [];
  const min = filter ? filter[0] : undefined;
  const max = filter ? filter[1] : undefined;
  const [minVal, setMinVal] = useState(min || undefined);
  const [maxVal, setMaxVal] = useState(max || undefined);

  const handleMinChange = (date) => { 
    setMinVal(date);
  };

  const handleMaxChange = (date) => { 
    setMaxVal(date);
  };

  useEffect(() => {
    if (onFilterChange) {
      const filter = (value) => {
        if ((minVal || maxVal) && !value) {
          return false;
        }
        const startDate = moment(value);
        const endDate = moment(value).subtract(1, 'days');

        if (minVal && startDate.isBefore(minVal)) {
          return false;
        }
        if (maxVal && endDate.isAfter(maxVal)) {
          return false;
        }

        return true;
      };
      
      filter.isDirty = !!minVal || !!maxVal;

      onFilterChange(filter);
      setFilter(title, [minVal, maxVal], filterType);
    }
  }, [minVal, maxVal]);

  return (
    <section>
      <div className="sk-panel" style={{ padding: '8px 24px' }}>
        <div className="sk-panel__header">
          {title}
        </div>
        <div className="sk-panel__content">
          <InputWrapper>
            <DatePicker
              format="MM-DD-YYYY"
              onChange={handleMinChange}
              value={minVal}
              slots={{
                textField: DateTextField
              }}
              slotProps={{
                textField: {
                  variant: 'standard'
                }
              }} />
            <ArrowIcon style={{ width: '16px', height: '16px', margin: '16px 8px' }} />
            <DatePicker
              format="MM-DD-YYYY"
              onChange={handleMaxChange}
              value={maxVal}
              slots={{
                textField: DateTextField
              }}
              slotProps={{
                textField: {
                  variant: 'standard'
                }
              }} />
          </InputWrapper>
        </div>
      </div>
    </section>
  );
};

DateRange.propTypes = {
  filterType:  PropTypes.string,
  getSelectedFilters: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  setFilter: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default DateRange;

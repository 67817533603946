import {
  UPDATE_MAP_PINS,
  SELECT_POPUP,
  CLEAR_POPUP,
  CURATE_USER_FETCH_ERROR,
  CURATE_USER_FETCH_REQUEST,
  CURATE_USER_FETCH_SUCCESS,
  FETCH_POPUP_CONTENT_ERROR,
  FETCH_POPUP_CONTENT_REQUEST,
  FETCH_POPUP_CONTENT_SUCCESS,
  SET_LIST_MOUSEOVER,
  SET_MAP_POLYGONS,
  SITEADVISOR_SESSION_REQUEST,
  SITEADVISOR_SESSION_SUCCESS,
  SITEADVISOR_SESSION_ERROR,
  CLEAR_LIST_ERROR,
  CLEAR_MAP_POLYGONS,
  UPDATE_MAP_BOUNDS,
  FETCH_FEATURE_CONTENT_SUCCESS,
  FETCH_FEATURE_CONTENT_REQUEST,
  FETCH_FEATURE_META_SUCCESS,
  REMOVE_POI,
  FETCH_FEATURE_META_REQUEST,
  FETCH_FEATURE_CONTENT_ERROR,
  FETCH_DEMO_CONTENT_REQUEST,
  FETCH_DEMO_CONTENT_SUCCESS,
  SNACKBAR_MAP_MESSAGE,
  SNACKBAR_MAP_CLOSE,
  DISABLE_MAP_PARCELS,
  ENABLE_MAP_PARCELS,
  GET_PARCEL_PATH_START,
  GET_PARCEL_PATH_END,
  DETAIL_PARCEL_PROPERTIES_CLEAR,
  DETAIL_PARCEL_PROPERTIES_SELECT,
} from '../../constants/actionTypes';

const initialState = {
  areas: [],
  bounds: {},
  curateError: null,
  curateUser: null,
  demographic:{},
  featureContent: [],
  hasListError: false,
  isFeatureFetching: false,
  isFetchingCurateUser: false,
  isPopupFetching: false,
  listErrorMessage: '',
  listMouseoverId: null,
  parcelsEnabled: true,
  pins: {},
  polygons: [],
  popup: null,
  popupContent: {},
  hasPopupError: false,
  openSnackbar: { open: false, text: '', secondaryText: '', type: '' }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_MAP_PINS:
      const areas = [];
      const pins = {};
      Object.keys(action.searchResults).forEach((type) => {
        const { results } = action.searchResults[type];

        if (!results) {
          return;
        }

        results.forEach((result) => {
          if (type === 'tim') {
            if (result._source.searchShapes && result._source.searchShapes.coordinates) {
              areas.push({
                id: result._source.id,
                shapes: result._source.searchShapes.coordinates,
                type,
              });
            }
          } else if (type === 'listing' || type === 'sale') {
            result._source.properties.forEach(({ hash, location }) => {
              if (pins[hash] && pins[hash].type !== type) {
                pins[hash].type = 'combo';
              } else {
                pins[hash] = { ...result._source, location, type, pindex: hash };
              }
            });
          } else {
            const { location, hash, property } = result._source;
            const pindex = (type === 'property') ? hash : property.hash;
            if (pins[pindex] && pins[pindex].type !== type) {
              pins[pindex].type = 'combo';
            } else {
              pins[pindex] = { location, type, pindex, ...result._source };
            }
          }
        });
      });

      return {
        ...state,
        areas,
        pins
      };

    case SELECT_POPUP:
      return {
        ...state,
        popup: action.pindex,
      };

    case CLEAR_POPUP:
      return {
        ...state,
        popup: null,
      };

    case SET_MAP_POLYGONS:
      return {
        ...state,
        polygons: action.polygons,
      };

    case CLEAR_MAP_POLYGONS:
      return {
        ...state,
        polygons: [],
      };

    case FETCH_POPUP_CONTENT_REQUEST:
      return {
        ...state,
        popupContent: {},
        isPopupFetching: true,
        hasPopupError: false
      };

    case FETCH_POPUP_CONTENT_SUCCESS:
      return {
        ...state,
        popupContent: action.data,
        isPopupFetching: false,
        hasPopupError: false
      };

    case FETCH_POPUP_CONTENT_ERROR:
      return {
        ...state,
        isPopupFetching: false,
        hasPopupError: true
      };
      
    case FETCH_FEATURE_CONTENT_REQUEST:
      return {
        ...state,
        isFeatureFetching: true,
      };
    case FETCH_FEATURE_META_REQUEST:
      return {
        ...state,
        isFeatureMetaFetching: true,
      };
    case FETCH_DEMO_CONTENT_REQUEST:
      return {
        ...state,
        demographic: { ...state.demographic, ...action.data },
      };
    case FETCH_DEMO_CONTENT_SUCCESS:
      return {
        ...state,
        demographicData: action.data,
        isFeatureFetching: false,
        isFeatureMetaFetching: false,
      };
    case FETCH_FEATURE_CONTENT_SUCCESS:
      const cleaned = state.featureContent.filter(({ id }) => id !== action.data.id);
      return {
        ...state,
        featureContent: [...cleaned, action.data],
        isFeatureFetching: false,
        isFeatureMetaFetching: false,
      };
    case REMOVE_POI:
      return {
        ...state,
        featureContent: state.featureContent.filter(({ id }) => id !== action.id)
      };
    case FETCH_FEATURE_META_SUCCESS:
      return {
        ...state,
        featureMeta: action.data,
        isFeatureFetching: false,
        isFeatureMetaFetching: false,

      };
    case FETCH_FEATURE_CONTENT_ERROR:
      const cleanedData = state.featureContent.filter(({ id }) => id !== action.error.id);
      return {
        ...state,
        featureContent: [...cleanedData, action.error],
        isFeatureFetching: false,
        isFeatureMetaFetching: false,
      };
    case SET_LIST_MOUSEOVER:
      return {
        ...state,
        listMouseoverId: action.id
      };

    case UPDATE_MAP_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      };

    case SITEADVISOR_SESSION_REQUEST:
    case SITEADVISOR_SESSION_SUCCESS:
      return {
        ...state,
        hasListError: false,
        listErrorMessage: ''
      };

    case SITEADVISOR_SESSION_ERROR:
      return {
        ...state,
        hasListError: true,
        listErrorMessage: action.error.message
      };

    case CLEAR_LIST_ERROR:
      return {
        ...state,
        hasListError: false,
        listErrorMessage: ''
      };

    case CURATE_USER_FETCH_REQUEST:
      return {
        ...state,
        isFetchingCurateUser: true,
      };

    case CURATE_USER_FETCH_ERROR:
      return {
        ...state,
        isFetchingCurateUser: false,
        curateError: action.error || { message: 'Could not fetch user from Curate.' },
      };

    case CURATE_USER_FETCH_SUCCESS:
      return {
        ...state,
        curateUser: action.data,
        isFetchingCurateUser: false
      };

    case SNACKBAR_MAP_MESSAGE:
      return {
        ...state,
        openSnackbar: {
          open: true,
          text: action.text,
          secondaryText: action.secondaryText,
          textType: action.textType
        }
      };

    case SNACKBAR_MAP_CLOSE:
      return {
        ...state,
        openSnackbar: {
          open: false,
          text: '',
          secondaryText: '',
          textType: ''
        }
      };

    case DISABLE_MAP_PARCELS:
      return {
        ...state,
        parcelsEnabled: false
      };

    case ENABLE_MAP_PARCELS:
      return {
        ...state,
        parcelsEnabled: true
      };

    case GET_PARCEL_PATH_START:
      return {
        ...state,
        detailsLoading: true
      };

    case GET_PARCEL_PATH_END:
      return {
        ...state,
        detailsLoading: false
      };

    case DETAIL_PARCEL_PROPERTIES_SELECT:
      return {
        ...state,
        selectedProperty: action.id
      };

    case DETAIL_PARCEL_PROPERTIES_CLEAR:
      return {
        ...state,
        selectedProperty: null
      };
  }

  return state;
}

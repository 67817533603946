import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AreaIcon from '@material-ui/icons/BorderOuter';
import DateIcon from '@material-ui/icons/Today';
import DevelopmentIcon from '@material-ui/icons/Domain';
import StatusIcon from '@material-ui/icons/LocalOffer';
import UnitsIcon from '@material-ui/icons/Functions';
import Stats from '../../common/stats/Stats';
import SummaryStats from '../../common/stats/SummaryStats';
import styles from './module.css';

const getClasses = makeStyles(styles);

export const CardContent = ({ stats, item }) => {
  const firstStat = item.area ? 'area' : 'units';
  const firstIcon = item.area ? AreaIcon : UnitsIcon;
  const summaryIcons = {
    [firstStat]: firstIcon,
    deliveryDate: DateIcon,
    developmentType: DevelopmentIcon,
    developmentStatus: StatusIcon,
  };

  const orderedStats = [firstStat, 'deliveryDate', 'developmentType', 'developmentStatus'];
  const filteredStats = stats.filter(({ key }) => {
    return orderedStats.indexOf(key) > -1;
  });
  filteredStats.sort((a, b) => {
    return orderedStats.indexOf(a.key) < orderedStats.indexOf(b.key) ? -1 : 1;
  });

  return <SummaryStats schema={filteredStats} icons={summaryIcons} item={item} />;
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array,
};

export const ExpandedCardContent = ({ stats, item }) => {
  const classes = getClasses();
  const fieldNotes = item.fieldNotes || {};
  const filteredStats = stats.filter(({ key }) => {
    return (item[key] || fieldNotes[key]) && [
      'developer',
      'description',
      'constructionStartDate',
      'constructionCost',
      item.area ? 'units' : '', // (if units not shown in summary)
      'preleasePercentage',
      'anticipatedTenants',
      'note',
    ].indexOf(key) !== -1;
  });

  return (
    <section className={classes.stats}>
      <Stats schema={filteredStats} item={item} />
    </section>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

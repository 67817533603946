import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DescIcon from '@material-ui/icons/ArrowDropDown';
import AscIcon from '@material-ui/icons/ArrowDropUp';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SortIcon from '@material-ui/icons/Sort';
import cx from 'clsx';
import find from 'lodash/find';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import {
  Hits, InitialLoader, Panel, ResetFilters, SearchkitManager, SearchkitProvider, SourceFilterAccessor
} from 'searchkit-react16';
import NotificationMessage from '../../common/NotificationMessage';
import { getHeaders } from '../../services/api';
import '../../styles/searchkit.css';
import ListMode from '../map/ListMode';
import Loader from '../Loader';
import Filter from './Filter';
import styles from './module.css';
import ResetFiltersButton from './ResetFiltersButton';

class Filters extends React.Component {
  static propTypes = {
    bounds: PropTypes.object.isRequired,
    classes: PropTypes.object,
    currentUser: PropTypes.object,
    datatype: PropTypes.object.isRequired,
    disableListMode: PropTypes.func.isRequired,
    limitIds: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    onQuery: PropTypes.func.isRequired,
    onResults: PropTypes.func.isRequired,
    orderBy: PropTypes.object,
    orderColumn: PropTypes.func,
    polygons: PropTypes.array.isRequired,
    search: PropTypes.object,
    sourceFilter: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.searchkit = new SearchkitManager(process.env.REACT_APP_API_URL, {
      useHistory: false,
      searchOnLoad: false,
      httpHeaders: Object.assign({}, getHeaders(), { 'Elasticsearch-Query-Index': this.props.datatype.key }),
      timeout: 15000
    });
    const { currentUser: { tenants }, datatype: { filters } } = props;
    const tenantFilter = find(filters, { field: '__tenant' });
    if (tenantFilter && tenants) {
      Object.assign(tenantFilter.translations, tenants.reduce(((acc, { id, name }) => {
        acc[id] = name;
        return acc;
      }), { }));
    }

    this.state = {
      notificationMessage: '',
      notificationOpen: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.setQueryProcessor(this.props);

    if (this.resultsListener) this.resultsListener();

    this.resultsListener = this.searchkit.addResultsListener((results) => {
      this.props.onResults(this.props.datatype.key, results);
    });

    this.searchkit.emitter.addListener(() => {
      if (!this.searchkit.loading && this.searchkit.error) {
        if (this.searchkit.error && this.searchkit.error.toString().indexOf('timeout') > -1) {
          this.setState({
            notificationMessage: <NotificationMessage text={'Error! Please try again.'} type={'error'} />,
            notificationOpen: true
          });
        }
      }
    });
  }

  componentDidMount() {
    this.searchkit.accessors.setState(this.props.search.filters);
    this.searchkit.reloadSearch();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleNotificationClose();

    if (this.props.search.listMode !== nextProps.search.listMode) {
      this.setQueryProcessor(nextProps);
    }

    const nextFilterJson = JSON.stringify(nextProps.search.filters);
    const lastFilterJson = JSON.stringify(this.searchkit.accessors.getState() || this.props.search.filters);
    if (nextFilterJson !== lastFilterJson) {
      this.searchkit.accessors.setState(nextProps.search.filters);
      this.setQueryProcessor(nextProps);
      this.searchkit.reloadSearch();
      return;
    }

    if (this.props.sourceFilter !== nextProps.sourceFilter) {
      const sourceFilterAccessor = this.searchkit.getAccessorByType(SourceFilterAccessor);
      sourceFilterAccessor.source = nextProps.datatype.sourceFilters[nextProps.sourceFilter];
      this.searchkit.reloadSearch();
      return;
    }

    const reloadSearch =
      (this.props.polygons.length === 0 && JSON.stringify(this.props.bounds) !== JSON.stringify(nextProps.bounds))
      || (JSON.stringify(this.props.polygons) !== JSON.stringify(nextProps.polygons))
      || (JSON.stringify(this.props.limitIds) !== JSON.stringify(nextProps.limitIds))
      || (this.props.orderBy !== nextProps.orderBy);
    if (reloadSearch) {
      this.setQueryProcessor(nextProps);
      this.searchkit.reloadSearch();
      return;
    }
  }

  setQueryProcessor(props) {
    this.searchkit.setQueryProcessor((fullQuery) => {
      const { search } = props;
      const { query: _query, ...etc } = fullQuery;
      const filters = this.searchkit.accessors.getState();
      const query = { query: { bool: { must: [] } }, ...etc, sort: [] };
      if (_query) query.query.bool.must.push(_query);

      let geoQuery;

      // [Search Area] User-Generated Polygons
      if (props.polygons.length) {
        // TIM
        if (props.datatype.key === 'tim') {
          geoQuery = {
            'geo_shape': {
              searchShapes: {
                shape: {
                  type: 'multipolygon',
                  coordinates: props.polygons.map((polygon) => [polygon.map((point) => [point.lng, point.lat])])
                }
              }
            }
          };

        // all property-based types
        } else {
          geoQuery = {
            bool: {
              'should': props.polygons.map((polygon) => ({
                'geo_polygon': {
                  location: { points: polygon.map((point) => [point.lng, point.lat]) }
                }
              })),
              'minimum_should_match': 1
            }
          };
        }

      // [Search Area] Screen Boundary
      // TIM
      } else if (props.datatype.key === 'tim') {
        geoQuery = {
          'geo_shape': {
            searchShapes: {
              shape: {
                type: 'envelope',
                coordinates: [
                  [props.bounds.bottom_left[0],props.bounds.top_right[1]], // top-left
                  [props.bounds.top_right[0],props.bounds.bottom_left[1]], // bottom-right
                ]
              }
            }
          }
        };

      // [Search Area] Screen Boundary
      // all property-based types
      } else {
        geoQuery = { 'geo_bounding_box': { location: props.bounds } };
      }

      // In list mode, ignore geoQuery for this datatype
      if (!search.listMode) {
        query.query.bool.must.push(geoQuery);
      }

      if (props.limitIds) query.query.bool.must.push({ ids: { values: props.limitIds } });

      // if a sort is selected, it will be the first criteria
      const sortDirection = _get(props, 'orderBy.direction') || 'desc';
      if (_get(props, 'orderBy.key', null)) {
        query.sort.push({ [props.orderBy.sortKey]: { order: sortDirection, ['unmapped_type']: 'long' } });
      }

      // add default sort
      query.sort.push({ sortDate: { order: sortDirection, ['unmapped_type']: 'date' } });

      props.onQuery(props.datatype.key, query, filters);
      return query;
    });
  }

  sortColumn = (key) => () => {
    return this.props.orderColumn(this.props.datatype.key, key);
  };

  renderOrderIndicator(column) {
    if (column && column.sortable) {
      let icon = <SortIcon />;
      const classNames = [this.props.classes.sortIcon, 'hint--left'];

      if (_get(this.props, 'orderBy.key') === column.sortKey) {
        icon = this.props.orderBy.direction === 'desc' ? <DescIcon /> : <AscIcon />;
        classNames.push(this.props.classes.sorted);
      }

      return (
        <div aria-label="Sort" className={cx(classNames)} onClick={this.sortColumn(column.sortKey)}>
          {icon}
        </div>
      );
    }
    return null;
  }

  handleNotificationClose = () => {
    this.setState ({
      notificationMessage: '',
      notificationOpen: false,
    });
  };

  handleDisable = () => {
    const { disableListMode, datatype: { key } } = this.props;
    disableListMode(key);
  };

  render() {
    const {
      classes,
      currentUser: { tenants },
      datatype: { columns, filters, Icon, color, plural, key },
      search,
      search: { listMode, filters: searchesFilters }
    } = this.props;
    const tenantFilter = find(filters, { field: '__tenant' });
    if (tenantFilter) {
      Object.assign(tenantFilter.translations, (tenants || []).reduce(((acc, { id, name }) => {
        acc[id] = name;
        return acc;
      }), { }));
    }

    const sortables = [];
    columns.forEach((c) => {
      // if column sortable...
      if (c.sortable && !['clientId', 'hash', '__tenant'].includes(c.key) && !c.hideHeader) {
        // check for exact match in filters (so can put sort button there)
        let filter = filters.find((f) => f.field === c.key);
        if (!filter) {
          // if no exact match, look for related filter (i.e. filter on spaceUses.type <--> order by spaceUses)
          const regex = new RegExp(`^${c.key}.`);
          filter = filters.find((f) => regex.test(f.field));
        }
        if (filter) {
          filter.sortColumn = c;
        } else {
          sortables.push(c);
        }
      }
    });
    sortables.sort((a, b) => a.title < b.title ? -1 : 1);

    return (
      <SearchkitProvider searchkit={this.searchkit}>
        <div className={classes.filtersContainer}>
          <AppBar color="transparent" elevation={0} position="static">
            <Toolbar style={{ borderBottom: '1px solid #e0e0e0' }}>
              <Icon style={{ color, marginRight: 16 }} />
              <Typography className={classes.filterHeader} variant="h5">{plural}</Typography>
              <IconButton edge="end" onClick={this.props.onClose}><CloseIcon /></IconButton>
            </Toolbar>
            {listMode &&
              <ListMode
                count={search.listCount}
                name={search.listTitle}
                onDisable={this.handleDisable} />}
          </AppBar>

          <InitialLoader component={Loader} />
          <React.Fragment>
            <ResetFilters
              component={(props) => (
                <ResetFiltersButton
                  {...props}
                  datatypeKey={key}
                  searchesFilters={searchesFilters} />
              )} />
            <div className={classes.filters}>
              {filters.map((filter, i) => (
                <section key={i} className={classes.filter}>
                  {this.renderOrderIndicator(filter.sortColumn)}
                  <Filter
                    datatype={this.props.datatype.key}
                    {...filter} />
                </section>
              ))}
              {/* Dropdown - if user would like to view additional sort features */}
              {sortables && (
                <Container classes={{ root: classes.accordionContainer }}>
                  <Accordion classes={{ root: classes.accordionFilter }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="additional sort">
                      <Typography>ADDITIONAL SORT FEATURES</Typography>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.accordionDetails }}>
                      <Container classes={{ root: classes.sortContainer }}>
                        {sortables.map((sortable, i) => (
                          <section key={i} className={cx(classes.filter, classes.sortFilter)} >
                            {this.renderOrderIndicator(sortable)}
                            <Panel title={sortable.title} />
                          </section>
                        ))}
                      </Container>
                    </AccordionDetails>
                  </Accordion>
                </Container>
              )}
            </div>
          </React.Fragment>
          {/*
            Wrapping Hits in a hidden div prevents the Hits component from displaying anything
            because its not being used for display only to define the query.
            Results are dispatched by the results listener above to redux.
          */}
          <div style={{ display: 'none' }}>
            <Hits hitsPerPage={200} sourceFilter={this.props.datatype.sourceFilters[this.props.sourceFilter]} />
          </div>
          <Snackbar
            autoHideDuration={30000}
            message={this.state.notificationMessage}
            onClose={this.handleNotificationClose}
            open={this.state.notificationOpen} />
        </div>
      </SearchkitProvider>
    );
  }
}

export default withStyles(styles)(Filters);

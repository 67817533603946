import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const PrimaryText = ({ address, name }) => {
  const classes = useStyles();
  return (
    <div className={classes.primaryText}>
      {name || (address && address.split(',')[0]) || '\u2013'}
    </div>
  );
};

PrimaryText.propTypes = {
  address: PropTypes.string,
  name: PropTypes.string,
};

export const SecondaryText = ({ address }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.secondaryText}>
        <p>{address || '\u2013'}</p>
      </div>
    </React.Fragment>
  );
};

SecondaryText.propTypes = {
  address: PropTypes.string,
  rentableBuildingArea: PropTypes.number,
};

SecondaryText.defaultProps = {
  propertyTypes: [],
};

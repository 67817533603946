import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import plur from 'plur';
import CurateCan from '../../../auth/CurateUserAbility';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Create from '@material-ui/icons/Create';
import styles from '../module.css';
import { withStyles } from '@material-ui/core';
import ColumnFieldStat from './ColumnFieldStat';

/**
 * @param {object} item
 *    - Passed for PropertyLinks & escalationRate formats that need the Item object in Listing (For Sale)
 *    - Must pass item as prop to corresponding section or property
 * @param {boolean} specialHeaderEdit 
 *    - For Property - Basic Info Edit Capability
 * @param {function(){ return <JSX /> }} specialColumn
 *    - For special column renders that return the JSX payload (rentSchedule of Lease)
 * @param {array[]} specialRowArray
 *    - For special row renders (when stats span across multiple columns)
 *    - Bottom Special Rows such as spaceUses spanning three columns
 * @param {array[]} specialTopRowArray
 *    - For special row renders (when stats span across multiple columns)
 *    - parkingRatio (1 column) & parkingDetails (2 columns)
 */

function handleCurateClick(event) {
  event.stopPropagation();
}

const Section = ({
  classes,
  title,
  sectionArray,
  fieldNotes,
  item,
  specialColumn,
  specialRowArray,
  specialTopRowArray,
  specialHeaderEdit,
  tenant,
  rescourId,
  resource }) => {
  return (
    <section>
      <Paper elevation={1} className={classes.sectionPaper}>
        <div className={specialHeaderEdit ? classes.sectionHeaderWrapper : null}>
          <Typography className={classes.sectionHeader} variant="subtitle1">{title}</Typography>
          {specialHeaderEdit && (
            <CurateCan
              do="read"
              on={plur(resource)}
              tenant={tenant}>
              <Tooltip title="Edit this Record" placement="right">
                <IconButton
                  className={classes.propertyCurateEditIcon}
                  target="_blank"
                  onClick={handleCurateClick}
                  href={`${process.env.REACT_APP_CURATE_APP_DOMAIN}/manager/property/edit/property/${rescourId}`}>
                  <Create />
                </IconButton>
              </Tooltip>
            </CurateCan>)}
        </div>
        {specialTopRowArray && 
          <ColumnFieldStat 
            sectionArray={specialTopRowArray} 
            fieldNotes={fieldNotes}
            item={item} />}
        <ColumnFieldStat
          sectionArray={sectionArray}
          fieldNotes={fieldNotes}
          specialColumn={specialColumn ? specialColumn : null}
          item={item} />
        {specialRowArray && 
          <ColumnFieldStat 
            sectionArray={specialRowArray} 
            fieldNotes={fieldNotes}
            item={item} />}
      </Paper>
    </section>
  );
};

Section.propTypes = {
  classes: PropTypes.object,
  fieldNotes: PropTypes.object.isRequired,
  item: PropTypes.object,
  rescourId: PropTypes.string,
  resource: PropTypes.string,
  sectionArray: PropTypes.array.isRequired,
  specialColumn: PropTypes.any,
  specialHeaderEdit: PropTypes.bool,
  specialRowArray: PropTypes.array,
  specialTopRowArray: PropTypes.array,
  tenant: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default withStyles(styles)(Section);

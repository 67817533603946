import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FieldNote from '../../../common/stats/FieldNote';
import { withStyles } from '@material-ui/core';
import styles from '../module.css';
import Stat from '../../../common/stats/Stat';

const Notes = ({ classes, note, fieldNote }) => {
  const getInfoStatFieldNote = (fieldName, extraStyles) => {
    if (fieldNote) {
      return (
        <FieldNote content={fieldNote} style={extraStyles} />
      );
    }
    return null;
  };

  return (
    <section>
      <Paper elevation={1} className={classes.sectionPaper}>
        <div>
          <Typography className={classes.sectionHeader} variant="subtitle1">
            Notes {getInfoStatFieldNote('note')}
          </Typography>
        </div>
        {(note || fieldNote) && (
          <div className={classes.note}>
            <Stat
              value={note || fieldNote}
              className={classes.noteValue}
              label="Notes" />
          </div>)}
      </Paper>
    </section>
  );
};

Notes.propTypes = {
  classes: PropTypes.object,
  fieldNote: PropTypes.string,
  note: PropTypes.string
};

export default withStyles(styles)(Notes);

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import PropertySubheader from '../propertySubheader/PropertySubheader';
import * as actions from './actions';
import Demographics from './Demographics';
import styles from './module.css';

const DemographicsContainer = ({
  allNearbyItems,
  classes,
  currentUser: { reports },
  dataUri,
  disableViewReport,
  downloadDemographicsReport,
  enableViewReport,
  fetchAllDatatypeWithFilters,
  fetchDemographicsReportPreview,
  isFetching,
  isViewReportDisabled,
  navigate,
  params,
  property,
  visibleDatatypes,
}) => {
  useEffect(() => {
    if (!dataUri) {
      fetchDemographicsReportPreview({ reportId: process.env.REACT_APP_DEMOGRAPHICS_REPORT_ID_DEFAULT, ids: property.id, property }); // eslint-disable-line
    }
  }, []);

  const nav = (key, id = null) => () => navigate(key, id);

  const demographicReports = reports.filter(({ dataTypes }) => (dataTypes.includes('demographics')));

  return (
    <div className={classes.demographicsContainer}>
      <PropertySubheader
        allNearbyItems={allNearbyItems}
        fetchAllDatatypeWithFilters={fetchAllDatatypeWithFilters}
        nav={nav}
        params={params}
        property={property}
        datatypeInfo={visibleDatatypes} />
      <Demographics
        fetchReportPreview={fetchDemographicsReportPreview}
        disableViewReport={disableViewReport}
        downloadDemographicsReport={downloadDemographicsReport}
        enableViewReport={enableViewReport}
        isFetching={isFetching}
        isViewReportDisabled={isViewReportDisabled}
        property={property} 
        dataUri={dataUri}
        reports={demographicReports} />
    </div>
  );
};

DemographicsContainer.propTypes = {
  allNearbyItems: PropTypes.object,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  dataUri: PropTypes.string,
  disableViewReport: PropTypes.func.isRequired,
  downloadDemographicsReport: PropTypes.func.isRequired,
  enableViewReport: PropTypes.func.isRequired,
  fetchAllDatatypeWithFilters: PropTypes.func.isRequired,
  fetchDemographicsReportPreview: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isViewReportDisabled: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
  visibleDatatypes: PropTypes.array.isRequired
};

function mapStateToProps({ research }) {
  const { property, dataUri, demographics, isFetchingDemographics, isViewReportDisabled } = research.propertyDetail;

  return {
    isFetching: isFetchingDemographics,
    isViewReportDisabled,
    property,
    dataUri,
    demographics,
  };
}

export default connect(mapStateToProps, actions)(withStyles(styles)(DemographicsContainer));

import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import { point, popup } from 'leaflet';
import { render, unmountComponentAtNode } from 'react-dom';
import theme from '../../styles/theme';
import PopupContent from './popupContent/Container';
import { getPopupOffset } from './utils';

const defaultTheme = createMuiTheme(theme);

const styles = () => ({
  popup: {
    '& .leaflet-popup-tip-container': {
      'display': 'none'
    },
    '& .leaflet-popup-content-wrapper': {
      borderRadius: 0,
    },
    '& .leaflet-popup-content': {
      minWidth: 261
    }
  }
});

class Popup extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    marker: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired
  };

  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  static defaultProps = {
    marker: {},
  };

  shouldComponentUpdate(nextProps) {
    return (this.props.marker._leaflet_id !== nextProps.marker._leaflet_id);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.marker && nextProps.marker._leaflet_id) {
      this.renderPopup(nextProps.marker, nextProps.trackEvent);
    }
  }

  renderPopup = (marker, trackEvent) => {
    const offset = getPopupOffset('marker', marker._map, marker._latlng);
    const markerPopup = popup({
      autoPan: false,
      closeButton: false,
      className: this.props.classes.popup,
    });
    marker.bindPopup(markerPopup, { offset: point(offset) }).openPopup();
    marker._map.once('popupclose', () => {
      if (markerPopup._contentNode) {
        unmountComponentAtNode(markerPopup._contentNode);
        marker.unbindPopup(markerPopup);
      }
      this.props.onClose();
    });

    const closePopup = () => {
      marker.closePopup();
    };

    render((
      <ThemeProvider theme={defaultTheme}>
        <PopupContent
          closePopup={closePopup}
          store={this.context.store}
          pindex={marker.options.pindex}
          trackEvent={trackEvent} />
      </ThemeProvider>
    ), markerPopup._contentNode);
  };

  render() {
    return null;
  }
}
export default withStyles(styles)(Popup);

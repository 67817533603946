import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store, { history } from '../store';
import { selectPopup } from '../research/map/actions';
import Spotlight from './Spotlight';

export class SpotlightContainer extends React.Component {
  static propTypes = {
    defaultLocation: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      searchText: null
    };
  }

  onChange = (text) => this.setState({ searchText: text });

  onSelect = (item) => {
    if (!item) return;

    let pathname = history.getCurrentLocation().pathname;
    const { _type, hash, location } = item;
    let zoom = 7;

    if (_type === 'geocode') {
      pathname = '/research/map';
      zoom = 18;
    }

    // TODO: for whatever reason changing hash with the router doesn't trigger onhashchange
    window.location.hash = `#${zoom}/${location.reverse().join('/')}`;
    if (hash) store.dispatch(selectPopup(hash));
    history.push({ ...history.getCurrentLocation(), pathname });
  };

  render() {
    let location = null;
    // use map's current location if available
    const [, lat, lng] = window.location.hash
      .replace('#', '')
      .split('/')
      .map(parseFloat);
    if (lat && lng) {
      location = [lng, lat];

    // else, use user's default location
    } else if (this.props.defaultLocation && this.props.defaultLocation.location) {
      location = [...this.props.defaultLocation.location];
    }
    return (
      <Spotlight
        location={location}
        onChange={this.onChange}
        onSelect={this.onSelect}
        value={this.state.searchText} />
    );
  }
}

function mapStateToProps({
  auth: { currentUser: { defaultLocation } }
}) {
  return {
    defaultLocation
  };
}

export default connect(mapStateToProps, null)(SpotlightContainer);

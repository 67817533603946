import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Stat from '../../../common/stats/Stat';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const PrimaryText = ({ tenant }) => {
  const classes = useStyles();
  const text = get(tenant, 'name', 'Unknown Tenant');
  return (
    <div className={classes.primaryText}>{text}</div>
  );
};

PrimaryText.propTypes = {
  tenant: PropTypes.object,
};

export const SecondaryText = ({ targetArea, spaceUses, submarkets }) => {
  const classes = useStyles();
  const { min, max } = targetArea || {};
  return (
    <React.Fragment>
      <div className={classes.secondaryText}>
        <p>{(submarkets || []).join(', ')}</p>
      </div>
      <div>
        {(min || max) && (
          <Stat
            className={classes.stat}
            label="Area"
            marginValue="5px"
            value={
              min && max
                ? `${min.toLocaleString()} - ${max.toLocaleString()} sq. ft`
                : `${(min || max).toLocaleString()} sq. ft`
            } />
        )}
        {spaceUses && spaceUses.length && (
          <Stat
            className={classes.stat}
            label="Space Uses"
            marginValue="5px"
            value={spaceUses.map((su) => su.type).join(', ')} />
        )}
      </div>
    </React.Fragment>
  );
};

SecondaryText.propTypes = {
  spaceUses: PropTypes.array,
  submarkets: PropTypes.array,
  targetArea: PropTypes.object,
};

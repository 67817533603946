import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import Paper from '@material-ui/core/Paper';
import PublicSalesRecordsSummaryAddl from './PublicSalesRecordsSummaryAddl';
import PublicSalesRecordsDetailsAddl from './PublicSalesRecordsDetailsAddl';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css';

const PublicSalesRecordsTab = ({ classes, propertySize, additionalMarketSales }) => {
  const [addlCardsExpanded, setAddlCardsExpanded] = useState(additionalMarketSales.map(() => false));

  const toggleAddlCardsExpand = (i) => () => {
    const updatedCardExpanded = [...addlCardsExpanded];
    updatedCardExpanded[i] = !updatedCardExpanded[i];
    setAddlCardsExpanded(updatedCardExpanded);
  };

  return (
    <div>
      {additionalMarketSales.map((val, i) => (
        <Card key={val.id} className={classes.card}>
          <CardContent>
            <PublicSalesRecordsSummaryAddl 
              i={i} 
              propertySize={propertySize} 
              additionalMarketSales={additionalMarketSales} />
          </CardContent>
          <Divider />
          <Paper elevation={4} square style={{ flex: 1, position: 'relative' }}>
            {addlCardsExpanded[i] && (
              <div className={classes.expandedView}>
                <PublicSalesRecordsDetailsAddl
                  i={i} 
                  propertySize={propertySize} 
                  additionalMarketSales={additionalMarketSales} />
              </div>
            )}
          </Paper>
          <CardActions className={classes.actions}>
            <IconButton onClick={toggleAddlCardsExpand(i)}>
              {addlCardsExpanded[i] ? <CollapseIcon /> : <ExpandIcon />}
            </IconButton>
          </CardActions>
        </Card>
      ))}
    </div>
  );
};

PublicSalesRecordsTab.propTypes = {
  additionalMarketSales: PropTypes.array.isRequired,
  classes: PropTypes.object,
  propertySize: PropTypes.number,
};

export default withStyles(styles)(PublicSalesRecordsTab);

export default (theme) => ({
  embed: {
    height: '100%',
    width: '100%',
    ['& iframe']: {
      border: 0
    },
  },
  staleEmbed: {
    filter: 'blur(6px) saturate(50%)',
  },
  demographicsContainer: {
    marginRight: '15px',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column'
  },
  demographicsInfoContainer: {
    height: 'calc(100vh - 387px)',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column'
  },
  demographicsLoadingWrapper: {
    width: '80px',
    margin: 'auto',
    height: '100%',
    marginTop: '24vh' 
  },
  demographicsHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: '0 16px',
    alignItems: 'center',
    margin: theme.spacing(2, 0)
  },
  demographicsHeaderTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: '#505050',
    lineHeight: 1.25,
    margin: 0,
    overflow: 'hidden',
    padding: theme.spacing(0, 0, 0, 2),
    textOverflow: 'ellipsis',
    width: '30%',
    [theme.breakpoints.down(1560)]: {
      width: '50%'
    },
    [theme.breakpoints.down(1280)]: {
      width: '30%',
    },
    [theme.breakpoints.down(1020)]: {
      width: '100%',
    },
  },
  demographicsHeaderSelector: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(1020)]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginRight: 'auto',
      marginLeft: 'unset'
    },
  },
  demographicsHeaderSelectorHelper: {
    [theme.breakpoints.down(1020)]: {
      padding: theme.spacing(1, 0, 0, 2),
    }
  },
  demographicsHeaderSelectorButton: {
    width: '345px',
    [theme.breakpoints.down(1020)]: {
      marginLeft: theme.spacing(2)
    }
  },
  demographicButtonGroup: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '8px',
    [theme.breakpoints.down(1560)]: {
      marginLeft: 'auto'
    },
  },
  demographicButton: {
    color: 'white',
    fontWeight: '500',
    backgroundColor: '#304085',
    borderRadius: '16px',
    minWidth: 'fit-content',
    border: '0.5px solid white',
    margin: theme.spacing(0, 0, 0, 1),
    ['&:hover']: {
      backgroundColor: 'white',
      color: '#304085',
      border: '0.5px solid #304085',
    }
  },
});

import { history } from '../store';

export function handleError(type, error) {
  return {
    type,
    error
  };
}

export function unauthorizedRequest() {
  history.push('/logout');
}

export function requestData(type, args) {
  return {
    type,
    ...args
  };
}

export function receiveData(type, data, args) {
  return {
    type,
    data,
    receivedAt: Date.now(),
    ...args
  };
}

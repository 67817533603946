import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import range from 'lodash/range';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropRightIcon from '@material-ui/icons/ArrowRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import getLocationTitles from '../common/getLocationTitles';
import AddLocation from './AddLocation';
import styles from './module.css';

const Locations = ({
  classes,
  items = [],
  selectedLocationId,
  location,
  onAddLocation,
  onRemoveLocation,
  onLocationUpdate,
  summary,
}, {
  router
}) => {
  const [anchorEl, setAnchorEl] = useState();
  const [menuItem, setMenuItem] = useState();
  const [radiusAnchorEl, setRadiusAnchorEl] = useState();

  const toggleLocation = (id) => () => {
    let pathname = '/feed';
    if (id && id !== selectedLocationId) pathname = `${pathname}/${id}`;
    const { query = {} } = location;
    router.push({ pathname, query: { filter: query.filter } });
  };

  const newItemCount = (item) => {
    if (!summary || !item) return 0;
    if (item.showTotalCount) return summary._total || 0;
    return parseInt(get(summary, [item.id, 'count'], 0)) || 0;
  };

  const formatRadius = (radius) => {
    if (radius === 0.25) return '¼ ';
    if (radius === 0.5) return '½ ';
    return radius;
  };

  const openRadiusMenu = (event) => {
    setRadiusAnchorEl(event.currentTarget);
  };
  const closeRadiusMenu = () => {
    setRadiusAnchorEl();
  };

  const openLocationMenu = (item) => (event) => {
    setMenuItem(item);
    setAnchorEl(event.currentTarget);
  };
  const closeLocationMenu = () => {
    closeRadiusMenu();
    setMenuItem();
    setAnchorEl();
  };

  const toggleDigest = () => {
    onLocationUpdate({ ...menuItem, digest: !menuItem.digest });
    closeLocationMenu();
  };

  const updateRadius = (radius) => () => {
    onLocationUpdate({ ...menuItem, radius });
    closeLocationMenu();
  };

  const unfollow = () => {
    if (menuItem.id === selectedLocationId) toggleLocation()();
    onRemoveLocation(menuItem);
    closeLocationMenu();
  };

  const onLocationChange = (newLocation) => {
    if (newLocation) onAddLocation(newLocation);
  };

  return (
    <React.Fragment>
      <AddLocation onChange={onLocationChange} />
      <List style={{ padding: 0 }}>
        {[{ name: 'All My Locations', showTotalCount: true }, ...items].map((item) => {
          const { primary, secondary } = getLocationTitles(item);
          const isSelected = (selectedLocationId === item.id || !selectedLocationId);
          return (
            <ListItem
              key={item.id || 'undefined'}
              button
              className={classes.location}
              onClick={toggleLocation(item.id)}>
              <ListItemIcon>
                <Badge badgeContent={newItemCount(item)} color="error">
                  <CheckCircleIcon className={isSelected ? classes.selectedLocation : classes.unselectedLocation} />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={primary} secondary={secondary} />
              {item.id && (
                <ListItemSecondaryAction>
                  <IconButton onClick={openLocationMenu(item)}>
                    <MoreVertIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          );
        })}
      </List>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeLocationMenu}>
        <MenuItem onClick={toggleDigest}>
          {menuItem && menuItem.digest && (
            <ListItemIcon>
              <CheckIcon fontSize="small" />
            </ListItemIcon>
          )}
          <ListItemText primary="Include in email digest" />
          <ListItemSecondaryAction />
        </MenuItem>
        <MenuItem onClick={openRadiusMenu}>
          <ListItemText primary="Search Radius" />
          <ListItemSecondaryAction><ArrowDropRightIcon /></ListItemSecondaryAction>
        </MenuItem>
        <Divider />
        <MenuItem onClick={unfollow}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Unfollow" />
          <ListItemSecondaryAction />
        </MenuItem>
      </Menu>

      <Menu
        anchorEl={radiusAnchorEl}
        onClose={closeLocationMenu}
        open={Boolean(radiusAnchorEl)}>
        {[0.25,0.5].concat(range(1, 11)).map((num) => (
          <MenuItem key={num} onClick={updateRadius(num)}>
            {menuItem && menuItem.radius === num && (
              <ListItemIcon>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
            )}
            <ListItemText primary={`${formatRadius(num)} mi`} />
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

Locations.contextTypes = {
  router: PropTypes.object.isRequired
};

Locations.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.array,
  location: PropTypes.object.isRequired,
  onAddLocation: PropTypes.func.isRequired,
  onLocationUpdate: PropTypes.func.isRequired,
  onRemoveLocation: PropTypes.func.isRequired,
  selectedLocationId: PropTypes.string,
  summary: PropTypes.object,
};

Location.defaultProps = {
  items: []
};

export default withStyles(styles)(Locations);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import get from 'lodash/get';
import styles from './module.css';

const formatString = (str) => {
  return str ? str : '\u2013';
};

const HeaderPanel = ({ classes, type, source }) => {
  let label = '';
  let value = '';
  switch (type) {
    case 'lease':
      label = 'Tenant:';
      value = formatString(get(source, 'tenant.name', '\u2013'));
      break;
    case 'availability':
      label = 'Listing Co.:';
      value = formatString(get(source, 'leasingRepresentatives[0].name', '\u2013'));
      break;
    case 'sale':
      label = 'Buyer:';
      value = formatString(get(source, 'buyers[0].name', '\u2013'));
      break;
    case 'listing':
      label = 'Seller:';
      value = formatString(get(source, 'sellers[0].name', '\u2013'));
      break;  
  }
  return (
    <div className={classes.headerPanelRowItem}>
      <span className={classes.headerPanelLabel}>{label}</span>
      <strong 
        className={classes.headerPanelValue} 
        style={{ paddingTop: '4px', marginLeft: type === 'availability' ? '10px' : null }}>
        {formatString(value)}
      </strong>
    </div>
  );
};

HeaderPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default withStyles(styles)(HeaderPanel);

import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FieldNote from '../../../common/stats/FieldNote';
import Gallery from '../../../common/Gallery';
import { withStyles } from '@material-ui/core';
import styles from '../module.css';

const Images = ({ classes, images, fieldNote }) => {
  const getInfoStatFieldNote = (fieldName, extraStyles) => {
    if (fieldNote) {
      return (
        <FieldNote content={fieldNote} style={extraStyles} />
      );
    }
    return null;
  };

  return (
    <section>
      <Paper elevation={1} className={classes.sectionPaper}>
        <Typography className={classes.sectionHeader} style={{ flex: 1 }} variant="subtitle1">
          Images {getInfoStatFieldNote('images')}
        </Typography>
        <div className={classes.images}>
          <Gallery
            additionalClass={classes.imageGallery}
            images={images} />
        </div>
      </Paper>
    </section>
  );
};

Images.propTypes = {
  classes: PropTypes.object,
  fieldNote: PropTypes.string,
  images: PropTypes.array
};

export default withStyles(styles)(Images);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Widget from './common/Widget';
import styles from './module.css';

const AddWidgets = ({ availableWidgets, classes, onAdd, small }) => {
  return (
    <Widget small={small} title="ADD WIDGET">
      <Table className={classes.availableWidgetsWrapper}>
        <TableBody>
          {availableWidgets.map((widget) => {
            const handleAdd = () => { onAdd(widget); };
            return (
              <TableRow key={widget}>
                <TableCell>
                  <div className={classes.addWidgetLink} onClick={handleAdd}>ADD</div>
                </TableCell>
                <TableCell>
                  {widget}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Widget>
  );
};

AddWidgets.propTypes = {
  availableWidgets: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  small: PropTypes.bool
};

export default withStyles(styles)(AddWidgets);

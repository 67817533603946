import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pickBy from 'lodash/pickBy';
import datatypes from '../common/datatypes';
import { ability } from '../auth/ability';
import { updateCurrentUser } from '../auth/actions';
import Loading from '../common/Loading';
import { showProblemReporter } from '../research/problemReporter/actions';
import NotFound from '../NotFound';
import Feed from './Feed';
import * as actions from './actions';
import { getFeedQueryParams } from './selectors';

export class FeedContainer extends React.Component {
  static propTypes = {
    currentUser: PropTypes.object,
    feedListOffset: PropTypes.number.isRequired,
    feedListParams: PropTypes.object.isRequired,
    fetchFavoritesList: PropTypes.func.isRequired,
    fetchFeedList: PropTypes.func.isRequired,
    fetchSummaryList: PropTypes.func.isRequired,
    hydrateFeedItem: PropTypes.func.isRequired,
    selectedLocationId: PropTypes.string,
    showProblemReporter: PropTypes.func.isRequired,
    toggleFeedItem: PropTypes.func.isRequired,
    updateCurrentUser: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.props.fetchSummaryList();
    this.props.fetchFeedList(this.props.feedListParams, this.props.selectedLocationId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectedLocationId !== this.props.selectedLocationId ||
      JSON.stringify(nextProps.feedListParams) !== JSON.stringify(this.props.feedListParams)) {
      nextProps.fetchFeedList(nextProps.feedListParams, nextProps.selectedLocationId);
    } else if (nextProps.feedListOffset > this.props.feedListOffset) {
      nextProps.fetchFeedList(
        { ...nextProps.feedListParams, offset: nextProps.feedListOffset },
        nextProps.selectedLocationId,
        false
      );
    }
  }

  toggleAndHydrateFeedItem = (item, isExpanded) => {
    this.props.toggleFeedItem(item, isExpanded);
    if (isExpanded && !item.isHydrated) {
      this.props.hydrateFeedItem(datatypes[item._type].resource, item.id);
    }
  };

  render() {
    if (ability.cannot('read', 'feed', ability.defaultTenantId)) return <NotFound />;
    if (!this.props.currentUser.id) return <Loading />;
    return (
      <Feed
        {...this.props}
        selectedItem={this.props.feedListParams.itemId}
        toggleFeedItem={this.toggleAndHydrateFeedItem} />
    );
  }
}

function mapStateToProps(state, { params, location: { query } }) {
  const { visibleDatatypes, auth: { currentUser } } = state;
  const feedDatatypes = pickBy(datatypes, (d) => d.isShownInFeed);
  const visibleFeedDatatypes = Object.keys(feedDatatypes).filter((key) => visibleDatatypes.indexOf(key) > -1);
  return {
    ...state.feed,
    currentUser,
    visibleDatatypes,
    selectedLocationId: params.selectedLocationId,
    feedListParams: getFeedQueryParams(query, visibleFeedDatatypes),
    feedListOffset: state.feed.offset,
    filter: query.filter,
    datatypes: feedDatatypes
  };
}

export default connect(mapStateToProps, { ...actions, showProblemReporter, updateCurrentUser })(FeedContainer);

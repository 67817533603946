
export const EVENTS = { EVENT: 'event',   
  LOCATION: 'Location', LOCATION_CLICKED: 'Location clicked',
  E_MAIL: 'E-mail',EMAIL_CLICKED:'Email clicked', 
  LINKEDIN: 'LinkedIn', LINKEDIN_CLICKED: 'LinkedIn clicked', 
  MYLISTLOADED: 'MyListLoaded', MYLISTLOADED_CLICKED: 'MyListLoaded clicked',
  TWITTER: 'Twitter', TWITTER_CLICKED: 'Twitter clicked',
  GROUP_ANALYTICS: 'Group Analytics', GROUP_ANALYTICS_CLICKED: 'Group Analytics clicked',
  PROBLEM_REPORTED: 'Problem Reported', PROBLEM_REPORTED_CLICKED: 'Problem Reported clicked',
  PDF_DOWNLOAD: 'Pdf Download', PDF_DOWNLOAD_CLICKED: 'Pdf download clicked',
  TABLE_VIEW: 'Table View', TABLE_VIEW_CLICKED: 'Table View Clicked'
};
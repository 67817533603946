import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import store, { history } from '../store';
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  Delete,
  DragHandle as DragHandleIcon,
  GridOn as GridOnIcon,
  Map as MapIcon,
} from '@material-ui/icons';
import { getParcelPath } from '../common/getParcelPath';
import api from '../services/api';
import { enableListings } from '../research/searches/actions';
import styles from './module.css';
import SortIconButton from './common/SortIcon';
import MyListingsButtonGroup from './common/MyListingsButtonGroup';

const INITIAL_LOAD_TALLY = 10; 

const getPropertyInfo = (row, tabValue) => {
  const propertyInfo = {};
    
  if (tabValue === 0) {
    propertyInfo.address = row.property ? row.property.address : '-';
    propertyInfo.hash = row.property ? row.property.hash : '-';
  } else if (tabValue === 1) {
    propertyInfo.rentableBuildingArea = row.properties && row.properties[0] ? 
      row.properties[0].rentableBuildingArea : '-';
    propertyInfo.address = row.properties && row.properties[0] ? row.properties[0].address : '-';
    propertyInfo.hash = row.properties && row.properties[0] ? row.properties[0].hash : '-';
  }
  
  return propertyInfo;
};

function formatDate(date) {
  const momentDate = moment(date);
  return momentDate.isValid() ? momentDate.format('MM/DD/YYYY') : '-';
}

function formatDollar(value) {
  return (value || value === 0) ? `$${value.toLocaleString()}` : '-';
}

function formatSuite(suite) {
  return suite ? String(suite).toUpperCase() : '-';
}

function formatAskingRate(askingRate) {
  return askingRate || askingRate === 0
    ? `$${parseFloat(askingRate).toFixed(2)} NNN`
    : '-';
}

function formatArea(area) {
  return area || area === 0 ? `${area.toLocaleString()}` : '-';
}

function formatPricePerSquareFoot(pricePerSquareFoot) {
  return pricePerSquareFoot || pricePerSquareFoot === 0
    ? `$${parseFloat(pricePerSquareFoot).toFixed(2)}`
    : '-';
}

const MyListingsWidget = ({
  classes,
  onRemove,
  onMapViewClick,
  onTableViewClick,
  title,
  widgetId
}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState({
    id: 'address',
    order: 'asc',
  });
  const [tabValue, setTabValue] = useState(parseInt(localStorage.getItem(`tabValue_${widgetId}`)) || 0);
  const [showPagination, setShowPagination] = useState(true);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [key, setKey] = useState('availability');

  const columns = [
    { 
      id: 'address', 
      label: 'Address',
      align: 'left',
      width: 300, 
      sortType: 'string' 
    },
    {
      id: tabValue === 0 ? 'suite' : 'area',
      label: tabValue === 0 ? 'Suite' : 'Area',
      width: 150,
      align: 'left',
      sortType: tabValue === 0 ? 'string' : 'number',
    },
    {
      id: tabValue === 0 ? 'askingRateMin' : 'price',
      label: tabValue === 0 ? 'Rate Min.' : 'Asking Price',
      align: 'left',
      width: 200,
      sortType: 'number',
    },
    {
      id: tabValue === 0 ? 'askingRateMax' : 'pricePerSquareFoot',
      label: tabValue === 0 ? 'Rate Max.' : 'Asking Price / SF',
      align: 'left',
      width: 200,
      sortType: 'number',
    },  
    { 
      id: tabValue === 0 ? 'area' : 'saleType', 
      label: tabValue === 0 ? 'Available SF' : 'Listing Type', 
      align: 'left',
      width: 200, 
      sortType: tabValue === 0 ? 'number' : 'string',
    },
    {
      id: tabValue === 0 ? 'minDivisibleArea' : 'availableDate',
      label: tabValue === 0 ? 'Min. Divisible SF' : 'Date Available',
      align: 'left',
      width: 200,
      sortType: tabValue === 0 ? 'number' : 'date'
    },
    {
      id: tabValue === 0 ? 'availableDate' : 'status',
      label: tabValue === 0 ? 'Date Available' : 'Listing Status',
      align: 'left',
      width: 200,
      sortType: tabValue === 0 ? 'date' : 'string'
    }
  ];
  
  function createData(tabValue, row, propertyInfo) {
    if (tabValue === 0) {
      const {
        suite = '',
        askingRate = {},
        area = '',
        minDivisibleArea = '',
        availableDate = '-',
        id = ''
      } = row;
      
      return {
        address: propertyInfo.address,
        suite: formatSuite(suite),
        askingRateMin: formatAskingRate(askingRate.min || ''),
        askingRateMax: formatAskingRate(askingRate.max || ''),
        area: formatArea(area),
        minDivisibleArea: formatArea(minDivisibleArea),
        availableDate: formatDate(availableDate),
        propertyHash: propertyInfo.hash,
        id
      };
    } else if (tabValue === 1) {
      const {
        price = '',
        pricePerSquareFoot = '',
        saleType = [],
        availableDate = '-',
        status = '',
        id = ''
      } = row;
  
      return {
        address: propertyInfo.address,
        area: formatArea(propertyInfo.rentableBuildingArea),
        price: formatDollar(price),
        pricePerSquareFoot: formatPricePerSquareFoot(pricePerSquareFoot),
        saleType: saleType.length > 0 ? saleType[0] : '-',
        availableDate: formatDate(availableDate),
        status: status || '-',
        propertyHash: propertyInfo.hash,
        id
      };
    }
  }
  
  const rows = data.map((row) => {
    const propertyInfo = getPropertyInfo(row, tabValue);
    return createData(
      tabValue,
      row,
      propertyInfo
    );
  });  

  const sortedRows = useMemo(() => {
    const sortableRows = [...rows].sort((a, b) => {
      const column = columns.find((col) => col.id === sortColumn.id);
      if (!column) {
        return 0;
      }
      let valueA = a[column.id] || '';
      let valueB = b[column.id] || '';
  
      if (valueA === '-') return sortColumn.order === 'asc' ? 1 : -1;
      if (valueB === '-') return sortColumn.order === 'asc' ? -1 : 1;
  
      if (column.id === 'address') {
        valueA = valueA.split(' ').slice(1).join(' ');
        valueB = valueB.split(' ').slice(1).join(' ');
      }
  
      const extractNumber = (value) => {
        return parseFloat(value.replace(/[^\d.]/g, ''));
      };
  
      if (
        ['askingRateMin','askingRateMax', 'pricePerSquareFoot', 'area', 'minDivisibleArea', 'price']
          .includes(column.id)) {
        valueA = extractNumber(valueA);
        valueB = extractNumber(valueB);
      }
  
      let compareResult;
  
      switch (column.sortType) {
        case 'number':
          compareResult = sortColumn.order === 'asc' ? valueA - valueB : valueB - valueA;
          break;
        case 'date':
          compareResult = sortColumn.order === 'asc'
            ? new Date(valueA) - new Date(valueB)
            : new Date(valueB) - new Date(valueA);
          break;
        case 'string':
        default:
          compareResult = sortColumn.order === 'asc'
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
          break;
      }
  
      return compareResult;
    });
  
    return sortableRows;
  }, [rows, sortColumn]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortClick = (columnId) => () => {
    setSortColumn((prevSortColumn) => {
      return prevSortColumn.id === columnId 
        ? { id: columnId, order: prevSortColumn.order === 'asc' ? 'desc' : 'asc' } 
        : { id: columnId, order: 'desc' };
    });
  };  

  const handleTableViewClick = () => {
    store.dispatch(enableListings(key, data));
    onTableViewClick({ value: title, key });
  };
  
  const handleMapViewClick = () => {
    store.dispatch(enableListings(key, data));
    onMapViewClick({ value: title, key });
  };

  const handleRowClick = (row) => async (_event) => {
    const pathname = await getParcelPath({
      address: row.address,
      id: row.id,
      slug: row.propertyHash,
      type: key
    });

    history.push(pathname);
  };

  const handleTabChange = (newTabValue) => {
    setTabValue(newTabValue);
    localStorage.setItem(`tabValue_${widgetId}`, newTabValue.toString());
    localStorage.setItem(
      `key_${widgetId}`,
      newTabValue === 0 ? 'availability' : 'listing'
    );

    const newKey = newTabValue === 0 ? 'availability' : 'listing';
    setKey(newKey);
  };

  useEffect(() => {
    let isMounted = true;

    const tabValueFromStorage = localStorage.getItem(`tabValue_${widgetId}`);
    const keyValueFromStorage = localStorage.getItem(`key_${widgetId}`);
    if (tabValueFromStorage) {
      setTabValue(parseInt(tabValueFromStorage));
    }

    if (keyValueFromStorage) {
      setKey(keyValueFromStorage);
    }

    const fetchMore = async (offset, limit) => {
      const params = { offset };
      if (limit) params.limit = limit;
      setHasLoaded(false);
      try {
        let data = await api.read('user/feed/listings', null, params);
        if (tabValue === 0) {
          data = data.items.filter((item) => item._type === 'availability');
        }
        if (tabValue === 1) {
          data = data.items.filter((item) => item._type === 'listing');
        }

        if (data && isMounted) {
          if (isMounted) {
            setData(data);
            setShowPagination(data.length > 0);
            setHasLoaded(true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchMore(0, INITIAL_LOAD_TALLY);
    return () => {
      isMounted = false;
    };
  }, [tabValue]); 

  const renderTableCellContent = (column, row, value) => {
    if (column.id === 'address') {
      return (
        <div style={{ maxWidth: '300px' }}>
          <Typography
            onClick={handleRowClick(row)}
            className={classes.loadValue}
            style={{ 
              overflow: 'hidden',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              height: '24px',
              whiteSpace: 'nowrap' 
            }}>
            {value}
          </Typography>    
        </div>
      );
    }

    return value;
  };

  const dragHandleButton =
    (
      <IconButton className={classes.dragHandle}>
        <DragHandleIcon className={'dashboard-widget-drag-handle'} />
      </IconButton>
    );

  const headerButtons = (
    <div className={classes.headerIcons}>
      <Tooltip title="GRID VIEW">
        <IconButton onClick={handleTableViewClick}>
          <GridOnIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="MAP VIEW">
        <IconButton onClick={handleMapViewClick}>
          <MapIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <Card className={classes.card} elevation={5}>
      <div className={classes.tableHeader}>
        <CardHeader
          action={dragHandleButton}
          title={title}
          className={classes.title} />

        <MyListingsButtonGroup
          classes={classes}
          onTabChange={handleTabChange}
          tabValue={tabValue} />

        {headerButtons}
      </div>

      {hasLoaded ? (<CardContent>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader className={classes.tableHeadRoot}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.sortType === 'number' ? 'right' : 'left'}
                      style={{ minWidth: column.width, borderBottom: '2px solid #25408F' }}>
                      {column.label}
                      <SortIconButton
                        columnId={column.id}
                        sortColumn={sortColumn}
                        handleSortClick={handleSortClick} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center', padding: '20px' }}>
                      <Typography variant="body1">
                        No data available for the selected time range.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow 
                          key={index}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell 
                                key={column.id} 
                                align={column.sortType === 'number' ? 'right' : 'left'} 
                                style={column.sortType === 'number' ?  { paddingRight: '64px' } : null}>
                                {renderTableCellContent(column, row, value)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={`${showPagination ? classes.tableFooter : classes.hidePagination}`}>
            {showPagination && (
              <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={sortedRows.length}
                labelRowsPerPage={'Rows per Page:'}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
            )}
            <Tooltip title="Remove Widget">
              <IconButton className={classes.removeButton} onClick={onRemove}> 
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
      </CardContent>) : (<div className={classes.spinner}><CircularProgress  /></div>)}
    </Card>
  );
};

MyListingsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  onMapViewClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTableViewClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  widgetId: PropTypes.number.isRequired,
};

export default withStyles(styles)(MyListingsWidget);

import {
  FilterBucket,
  BoolMust,
  RangeAccessor
} from 'searchkit-react16';

const safeVal = (input, fallback) => {
  if (input) {
    const clean = parseInt(input);
    if (!isNaN(clean)) {
      return clean;
    }
  }
  return fallback;
};

export default class PropertySizeRangeAccessor extends RangeAccessor {
  constructor(id, options, propertyKey) {
    super(id, options);
    this.propertyKey = propertyKey || 'property';
  }

  buildOwnQuery(query) {
    const otherFilters = query.getFiltersWithoutKeys(this.uuid);
    const stats = {
      [this.key]: {
        cardinality: {
          script: {
            lang: 'painless',
            source: `
              if (!doc['${this.propertyKey}.rentableBuildingArea'].empty) {
                return doc['${this.propertyKey}.rentableBuildingArea'].value;
              } if (!doc['${this.propertyKey}.area'].empty) { return doc['${this.propertyKey}.area'].value; }
              return false;
            `
          }
        }
      }
    };
    const fb = new FilterBucket(this.uuid, otherFilters, ...this.fieldContext.wrapAggregations(stats));
    return query.setAggs(fb);
  }

  buildSharedQuery(query) {
    if (!this.state.hasValue()) return query;
    const value = this.state.getValue();
    const selectedFilter = {
      name: this.translate(this.options.title),
      value: `${value.min} - ${value.max}`,
      id: this.options.id,
      remove: () => { this.state = this.state.clear(); }
    };
    const filter = {
      script: {
        script: {
          lang: 'painless',
          inline: `
            if (!doc['${this.propertyKey}.rentableBuildingArea'].empty) {
              return doc['${this.propertyKey}.rentableBuildingArea'].value >= params.min &&
                doc['${this.propertyKey}.rentableBuildingArea'].value <= params.max
            } else if (!doc['${this.propertyKey}.area'].empty) {
              return doc['${this.propertyKey}.area'].value >= params.min &&
                doc['${this.propertyKey}.area'].value <= params.max
            }
            return false
          `,
          params: {
            min: safeVal(value.min, this.options.min),
            max: safeVal(value.max, this.options.max)
          }
        }
      }
    };

    return query
      .addFilter(this.key, new BoolMust([filter]))
      .addSelectedFilter(selectedFilter);
  }
}

import React, { useState } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import CopyrightIcon from '@material-ui/icons/Copyright';
import SearchIcon from '@material-ui/icons/Search';
import HomeIcon from '@material-ui/icons/Home';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import MapIcon from '@material-ui/icons/Map';
import MenuIcon from '@material-ui/icons/MoreVert';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import Popper from '@material-ui/core/Popper';
import Can from '../auth/ability';
import logo from '../assets/colliers-logo.png';
import AccountSettings from '../auth/AccountSettings';
import SpotlightContainer from '../spotlight/Container';
import { history } from '../store';
import Disclaimer from './Disclaimer';
import styles from './module.css';

export const Header = ({
  classes,
  router
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [disclaimerOpen, setDisclaimerOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [arrowRef, setArrowRef] = React.useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const toggleDisclaimer = (which) => () => {
    setDisclaimerOpen(which);
    closeMenu();
  };

  const toggleSettings = (which) => () => {
    setSettingsOpen(which);
    closeMenu();
  };

  const toggleMyLists = () => {
    router.push('/account/mylists');
    closeMenu();
  };

  const toggleSavedSearches = () => {
    router.push('/account/savedsearches');
    closeMenu();
  };

  const isPath = (path) => {
    return router.getCurrentLocation().pathname.indexOf(path) === 0;
  };

  const onLogout = () => {
    history.push('/logout');
  };

  return (
    <AppBar className={classes.headerBar} elevation={0} position="static">
      <Toolbar className={classes.toolbar}>
        <div className={classes.header}>
          <Button
            disableRipple
            className={classes.headerLogoButton}
            component={Link}
            to="/dashboard">
            <div className={classes.logo}>
              <img className={classes.logoImg} src={logo} alt="Colliers Discover logo" />
            </div>
          </Button>
          <div className={classes.spotlightContainer}>
            <SpotlightContainer />
          </div>

          <nav className={classes.navContainer}>
            <Can do="read" on="widgets">
              <Button
                classes={{ startIcon: classes.headerButtonIcon }}
                className={clsx([
                  classes.headerButton,
                  classes.headerButtonSquare,
                  { [classes.selected]: isPath('/dashboard') }
                ])}
                component={Link}
                startIcon={<HomeIcon />}
                to="/dashboard">
                <span className={classes.headerButtonLabel}>Home</span>
              </Button>
            </Can>

            <Button
              classes={{ startIcon: classes.headerButtonIcon }}
              className={clsx([
                classes.headerButton,
                classes.headerButtonSquare,
                { [classes.selected]: isPath('/research') }
              ])}
              component={Link}
              startIcon={<MapIcon />}
              to="/research">
              <span className={classes.headerButtonLabel}>Map</span>
            </Button>

            <IconButton
              className={classes.headerButton}
              color="primary"
              onClick={openMenu}>
              <MenuIcon />
            </IconButton>
            {Boolean(anchorEl) && <React.Fragment>
              <ClickAwayListener
                onClickAway={closeMenu}>
                <Popper
                  className={classes.headerPopper}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={closeMenu}
                  placement="bottom-end"
                  disablePortal={true}
                  modifiers={{
                    flip: {
                      enabled: false,
                    },
                    preventOverflow: {
                      enabled: true,
                      boundariesElement: 'window',
                    },
                    arrow: {
                      enabled: true,
                      element: arrowRef,
                    },
                  }}>
                  {Boolean(anchorEl) && <span ref={setArrowRef} className={classes.arrow} />}
                  <Can do="use" on="_app">
                    <MenuItem onClick={toggleSettings(true)}>
                      <ListItemIcon><PersonIcon /></ListItemIcon>
                      <ListItemText primary="Account" className={classes.listItemText} />
                    </MenuItem>
                  </Can>

                  <Can do="use" on="_app">
                    <MenuItem onClick={toggleSavedSearches}>
                      <ListItemIcon><SearchIcon /></ListItemIcon>
                      <ListItemText primary="My Searches" className={classes.listItemText} />
                    </MenuItem>
                  </Can>

                  <Can do="use" on="_app">
                    <MenuItem onClick={toggleMyLists}>
                      <ListItemIcon><PlaylistAdd /></ListItemIcon>
                      <ListItemText primary="My Lists" className={classes.listItemText} />
                    </MenuItem>
                  </Can>

                  <MenuItem onClick={toggleDisclaimer(true)}>
                    <ListItemIcon><CopyrightIcon /></ListItemIcon>
                    <ListItemText primary="Disclaimer" className={classes.listItemText} />
                  </MenuItem>

                  <MenuItem onClick={onLogout}>
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                    <ListItemText primary="Log Out" className={classes.listItemText} />
                  </MenuItem>
                </Popper>
              </ClickAwayListener>
            </React.Fragment>}

            <Disclaimer isVisible={disclaimerOpen} hide={toggleDisclaimer(false)} />
            <AccountSettings isVisible={settingsOpen} hide={toggleSettings(false)} router={router} />
          </nav>
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);
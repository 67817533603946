import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import datatypes from '../../../common/datatypes';
import listItemContent from '../listItemContent';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import styles from './module.css';

const PopupContent = ({ 
  hideAllFilters,
  classes,
  closePopup,
  parcelPopup,
  property, 
  searches,
  isFetching
}) => {
  if (isFetching) {
    return (
      <div className={classes.loadingPopup}>
        <LinearProgress style={{ width: '100%' }} />
      </div>
    );
  }

  const showDetails = ({ id = null }, type = 'property') => () => {
    hideAllFilters();
    browserHistory.push({
      ...location,
      pathname: `/research/map/${property.hash}|${property.rescourId}/${type}/${id}`
    });
  };

  return (
    parcelPopup === true ? null : (
      <div className={classes.popup}>
        <header className={classes.popupHeader} onClick={showDetails(property)}>
          <h4>{property.name || (property.address && property.address.split(',')[0])}</h4>
          <h5>{property.address}</h5>
        </header>
        <Button className={classes.popupContentCloseButton} onClick={closePopup}>
          <CloseIcon className={classes.poupContentCloseIcon} />
        </Button>
        <article>
          {searches.map((key) => {
            if (property[key] && property[key].length > 0) {
              const { plural, color, Icon } = datatypes[key];
              const { PrimaryText, SecondaryText } = listItemContent[key];

              return (
                <List key={key} style={{ padding: 0 }}>
                  <ListSubheader style={{ backgroundColor: color, color: 'white', zIndex: 250 }}>
                    {plural}
                  </ListSubheader>
                  {property[key].map((item) => (
                    <ListItem
                      button
                      onClick={showDetails(item, key)}
                      key={item.id}
                      secondaryTextLines={2}>
                      <ListItemAvatar>
                        <Avatar
                          icon={<Icon style={{ color: 'white' }} />}
                          src={item.thumbnailUrl}
                          backgroundColor={color} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={<PrimaryText {...item} customClass={classes.ourDealsLogo} />}
                        secondary={<SecondaryText {...item} />}
                        secondaryTypographyProps={{ style: { color: 'black' } }} />
                    </ListItem>
                  ))}
                </List>
              );
            }

            return null;
          })}
        </article>
      </div>
    )
  );
};

PopupContent.propTypes = {
  classes: PropTypes.object,
  closePopup: PropTypes.func.isRequired,
  hideAllFilters: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  parcelPopup: PropTypes.bool.isRequired,
  pindex: PropTypes.string,
  property: PropTypes.object.isRequired,
  searches: PropTypes.array.isRequired
};

PopupContent.defaultProps = {
  searches: [],
};

export default withStyles(styles)(PopupContent);

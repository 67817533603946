import React from 'react';

const NotFound = () => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
      }}>
      <h2>Not Found</h2>
      <p>The page you attempted to access was not found or you are not authorized to access it.</p>
    </div>
  );
};

export default NotFound;

import React from 'react';
import PropTypes from 'prop-types';

const ParkingDetails = ({ value }) => {
  return (
    <div>
      {value.map((parkingDetail, index) => {
        return (
          <div key={index}>
            {parkingDetail.type && <span>{parkingDetail.type}</span> }
            {parkingDetail.count && <span>, {parkingDetail.count.toLocaleString()} spaces</span>}
            {parkingDetail.price && <span>, ${parkingDetail.price} per month</span>}
          </div>
        );
      })}
    </div>
  );
};

ParkingDetails.propTypes = {
  value: PropTypes.any
};

export default ParkingDetails;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import OpenHydraIcon from '@material-ui/icons/Launch';
import DownloadIcon from '@material-ui/icons/PictureAsPdf';
import styles from './module.css';

const Demographics = ({
  classes,
  dataUri,
  disableViewReport,
  downloadDemographicsReport,
  enableViewReport,
  fetchReportPreview,
  isFetching,
  isViewReportDisabled,
  property,
  reports
}) => {
  const [report, setReport] = useState(process.env.REACT_APP_DEMOGRAPHICS_REPORT_ID_DEFAULT || '');
  const [reportViewer, setReportViewer] = useState(report);

  useEffect(() => {
    if (report === reportViewer) {
      disableViewReport();
    }
  }, [report]);

  const handleChange = (event) => {
    setReport(event.target.value);
    enableViewReport();
  };

  const getReportIframe = (previewReportDataUri) => `
  <iframe
    src="${previewReportDataUri || 'about:blank'}#toolbar=0"
    type="application/pdf"
    width="100%" height="100%" style="overflow: auto;" />
`;

  const previewType = (previewReportDataUri, classes, needsRegeneration) => {
    return (
      <div
        className={cx({ [classes.embed]: 1, [classes.staleEmbed]: needsRegeneration })}
        dangerouslySetInnerHTML={{ __html: getReportIframe(previewReportDataUri) }} /> // eslint-disable-line
    );
  };

  const { address, hash, location: [lng, lat] = [] } = property || {};

  const handleDownload = () => {
    const reportObj = reports ? (reports.filter((demo) => demo.id === report))[0] : null;
    downloadDemographicsReport(address, hash, lng,lat, reportObj);
  };
  const handleViewReport = () => {
    fetchReportPreview({ reportId: report, ids: property.id, property });
    setReportViewer(report);
  };

  const hydraUrl = () => {
    const reportTitle = (reports.filter((demo) => demo.id === reportViewer))[0].title;
    const latLngQuery = `?latitude=${lat}&longitude=${lng}`;
    const ringsQuery = '&rings=1,2,3'; 
    const reportQuery = `&title=${address}&description=${reportTitle}&location_name=`;
    return `${process.env.REACT_APP_HYDRA_APP_DOMAIN}${latLngQuery}${ringsQuery}${reportQuery}`;
  };

  const handleSendToHydra = (e) => {
    e.stopPropagation();
  };

  return (
    <section className={classes.demographicsInfoContainer}>
      <div className={classes.demographicsHeader}>
        <Typography className={classes.demographicsHeaderTitle} variant="h6">
          AREA DEMOGRAPHICS
        </Typography>
        <FormControl className={classes.demographicsHeaderSelector}>
          <FormHelperText className={classes.demographicsHeaderSelectorHelper}>
            Select Report Template
          </FormHelperText>
          <Select
            className={classes.demographicsHeaderSelectorButton}
            defaultValue={process.env.REACT_APP_DEMOGRAPHICS_REPORT_ID_DEFAULT || ''} // eslint-disable-line
            value={report}
            label="Report"
            onChange={handleChange}>
            {reports.map((report, index) => (
              <MenuItem key={index} value={report.id}>{report.title}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.demographicButtonGroup}>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disableRipple
            onClick={handleViewReport}
            disabled={isViewReportDisabled}
            disableFocusRipple
            className={classes.demographicButton}>
            View Report
          </Button>
          <Button
            color="primary"
            variant="contained"
            disableElevation
            disableRipple
            disabled={isFetching}
            onClick={handleDownload}
            disableFocusRipple
            endIcon={<DownloadIcon />}
            className={classes.demographicButton}>
            Download
          </Button>
          <Link
            href={hydraUrl()}
            onClick={handleSendToHydra}
            target={'_blank'}>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              disableRipple
              disabled={isFetching}
              disableFocusRipple
              endIcon={<OpenHydraIcon />}
              className={classes.demographicButton}>
              Open in HYDRA
            </Button>
          </Link>
        </div>
      </div>
      {!dataUri && (
        <div className={classes.demographicsLoadingWrapper}>
          <CircularProgress color="primary" size={80} />
        </div>)}
      {dataUri && previewType(dataUri, classes, isFetching)}
    </section>
  );
};

Demographics.propTypes = {
  classes: PropTypes.object.isRequired,
  dataUri: PropTypes.string,
  disableViewReport: PropTypes.func.isRequired,
  downloadDemographicsReport: PropTypes.func.isRequired,
  enableViewReport: PropTypes.func.isRequired,
  fetchReportPreview: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isViewReportDisabled: PropTypes.bool.isRequired,
  property: PropTypes.object.isRequired,
  reports: PropTypes.array.isRequired,
};

export default withStyles(styles)(Demographics);

import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

const RateByYearChart = (props) => {
  const {
    classes,
    items,
    legend,
    color,
    chartImg,
    animationDuration,
    onAnimationComplete
  } = props;

  // analyze the items (selectedResults from the map view)
  const signedRates = [];
  if (items && items.length > 0) {
    const signedRateStats = {};
    for (const item of items) {
      if (item._source && item._source.signedDate) {
        const signedYear = item._source.signedDate.split('-').shift();
        if (signedYear && parseInt(signedYear) > 0 && item._source.rentRate > 0) {
          if (Object.keys(signedRateStats).indexOf(signedYear) === -1) {
            signedRateStats[signedYear] = { sum: 0, tally: 0 };
          }
          signedRateStats[signedYear].sum += item._source.rentRate;
          signedRateStats[signedYear].tally++;
        }
      }
    }
    for (const year in signedRateStats) {
      signedRates.push({ x: year, y: signedRateStats[year].sum / signedRateStats[year].tally });
    }
  }

  // get maxes of each scale to help set the y-axis
  let max = 0;
  for (const dataPoint of signedRates) {
    max = (dataPoint.y > max) ? dataPoint.y : max;
  }

  // init chartRef
  let chartRef = null;
  
  // return the element
  return (
    <div>
      <Line
        ref={(chart) => { chartRef = chart; }}
        data={{
          datasets: [{
            label: '$/SF',
            data: signedRates,
            lineTension: 0,
            pointRadius: 3,
            borderColor: color,
            borderWidth: 3,
            backgroundColor: `${color}20` // (translucent of same color)
          }]
        }}
        options={{
          scales: {
            xAxes: [{
              type: 'linear',
              ticks: { stepSize: 1 }
            }],
            yAxes: [{
              type: 'linear',
              scaleLabel: { display: true, labelString: '$/SF', fontStyle: 'bold' },
              ticks: {
                beginAtZero: true,
                suggestedMax: Math.ceil(1.05 * max) // 5% more than the highest value in data
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem) => `$${tooltipItem.yLabel.toFixed(2)} / SF`
            }
          },
          animation: {
            duration: animationDuration || 1000,
            onComplete: () => {
              if (onAnimationComplete) {
                onAnimationComplete(chartRef.chartInstance);
              }
            }
          },
          legend: legend ? legend : {}
        }} />
      {chartImg && <img src={chartImg} className={classes.canvasImg} data-canvas />}
    </div>
  );
};

RateByYearChart.propTypes = {
  animationDuration: PropTypes.number,
  chartImg: PropTypes.string,
  classes: PropTypes.object,
  color: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  legend: PropTypes.object,
  onAnimationComplete: PropTypes.func
};
  
export default RateByYearChart;
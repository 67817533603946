import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useCollapsable } from './useCollapsable';

const RefinementList = ({ onFilterChange, options = [], title, setFilter, getSelectedFilters, filterType }) => {
  const savedSelections = getSelectedFilters();
  const filters = savedSelections && savedSelections[title] ? savedSelections[title] : '';
  const [selected, setSelected] = useState(new Set(filters ? filters : []));
  const [collapsed, toggleCollapsed] = useCollapsable();
  const [filterOptions, setFilterOptions] = useState(options);

  useEffect(() => {
    const setFilters = () => {
      const values = new Set(options.map((o) => o.value));
      if (filters.length > 0) {
        const isFiltersSelected = filters.every((f) => values.has(f));
        if (!isFiltersSelected) {
          const results = [];
          for (const filter in filters) {
            if (values.has(filters[filter])) return; 
            results.push({ value: filters[filter] });
          }

          return setFilterOptions([...options, ...results]);
        }

        return setFilterOptions(options);
      }
      
      return setFilterOptions(options);
    };
    setFilters();
  }, [filters, options]);

  const handleToggleCheck = (value) => () => {
    const newSelected = new Set(selected);
    if (selected.has(value)) {
      newSelected.delete(value);
    } else {
      newSelected.add(value);
    }

    setSelected(newSelected);
    setFilter(title, newSelected, filterType);
  };

  useEffect(() => {
    if (onFilterChange) {
      const filter = (val) => {
        if (!selected || !selected.size) {
          return true;
        }
        // If this expands to more cases, change to a more complex translation function
        if (val === undefined) {
          val = 'NOT CATEGORIZED';
        }

        if (selected.has(val)) {
          return true;
        }

        return false;
      };

      filter.isDirty = selected?.size > 0;

      onFilterChange(filter);
    }
  }, [selected]);

  return (
    <section>
      <div className="sk-panel" style={{ padding: '8px 24px' }}>
        <div
          className={`sk-panel__header is-collapsable ${collapsed ? 'is-collapsed' : ''}`}
          onClick={() => toggleCollapsed()}>
          {title}
        </div>
        <div className={`sk-panel__content ${collapsed ? 'is-collapsed' : ''}`}>
          <div className="sk-item-list">
            {filterOptions.map(({ value, count }) => (
              <div
                className="sk-item-list-option sk-item-list__item"
                style={{ display: 'flex', alignItems: 'flex-start' }}
                key={value}
                onClick={handleToggleCheck(value)}>
                <input 
                  checked={selected.has(value)} 
                  style={{ marginRight: 5, marginTop: 5 }} 
                  type="checkbox" 
                  readOnly />
                <div className="sk-item-list-option__text">
                  {value}
                </div>
                <div className="sk-item-list-option__count">
                  {count}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

RefinementList.propTypes = {
  filterType:  PropTypes.string,
  getSelectedFilters: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  setFilter: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default RefinementList;

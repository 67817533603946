import {
  FEED_LIST_REQUEST,
  FEED_LIST_SUCCESS,
  FEED_LIST_ERROR,
  FEED_ITEM_EXPAND_TOGGLE,
  FEED_ITEM_HYDRATE_SUCCESS,
  FEED_LIST_UPDATE_OFFSET,
  FEED_ITEM_SHARE_SHOW,
  FEED_ITEM_SHARE_HIDE,
  FEED_ITEM_SHARE_SEND_REQUEST,
  FEED_ITEM_SHARE_SEND_SUCCESS,
  FEED_SUMMARY_LIST_SUCCESS,
} from '../constants/actionTypes';

function updateItem(items, updatedItem) {
  return items.map((item) => {
    if (item.id === updatedItem.id) {
      return updatedItem;
    }

    return item;
  });
}

const initialState = {
  isFetching: false,
  items: [],
  offset: 0,
  shareItem: null,
  isShareditemSending: false,
  isShareditemSent: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FEED_LIST_REQUEST:
      return {
        ...state,
        offset: action.refresh ? 0 : state.offset,
        items: action.refresh ? [] : state.items,
        isFetching: true
      };

    case FEED_LIST_SUCCESS:
      return {
        ...state,
        hasCompleteList: action.data.length === 0,
        isFetching: false,
        updatedAt: Date.now(),
        items: state.items.concat(action.data),
      };

    case FEED_LIST_ERROR:
      return {
        ...state,
        isFetching: false
      };

    case FEED_LIST_UPDATE_OFFSET:
      return {
        ...state,
        offset: action.offset,
      };

    case FEED_ITEM_HYDRATE_SUCCESS:
      const item = {
        ...action.data,
        isHydrated: true,
        isExpanded: true
      };

      return {
        ...state,
        items: updateItem(state.items, item)
      };

    case FEED_ITEM_EXPAND_TOGGLE:
      return {
        ...state,
        items: updateItem(state.items, action.item)
      };

    case FEED_ITEM_SHARE_SHOW:
      return {
        ...state,
        shareItem: action.item.id
      };

    case FEED_ITEM_SHARE_HIDE:
      return {
        ...state,
        shareItem: null,
        isShareditemSending: false,
        isShareditemSent: false,
      };

    case FEED_ITEM_SHARE_SEND_REQUEST:
      return {
        ...state,
        isShareditemSending: true,
        isShareditemSent: false,
      };

    case FEED_ITEM_SHARE_SEND_SUCCESS:
      return {
        ...state,
        isShareditemSending: false,
        isShareditemSent: true,
      };

    case FEED_SUMMARY_LIST_SUCCESS:
      return {
        ...state,
        summary: action.data
      };
  }

  return state;
}

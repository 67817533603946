export const FALLBACK_LOCATION = '#6/33.7550/-84.3900';
export const TABLE_ROW_HEIGHT = 45;
export const TABLE_COL_WIDTH_DEFAULT = 200;
export const LIST_WIDTH = 405;
export const LIST_ITEM_HEIGHT = 180;
export const GUTTER_SIZE = 10;
export const MENU_WIDTH = 60;
export const MAX_REPORT_SIZE = 100;
export const ACRES_PER_SQ_M = 0.000247105;
export const SQ_FT_PER_SQ_M = 10.7639;
export const SQ_FT_PER_ACRE = 43560;

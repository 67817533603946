/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DatatypeIcons from '../account/common/DatatypeIcons';
import { read } from '../services/api';
import { enableListMode } from '../research/actions';
import { selectMyList } from '../account/actions';
import { history } from '../store';
import Widget from './common/Widget';
import WidgetTable from './common/WidgetTable';
import styles from './module.css';

const TitleRow = ({ classes, enableListMode, list, selectMyList }) => {
  const handleClick = () => {
    selectMyList(list);
    enableListMode(list.datatype);
  };

  return <span className={classes.clickableTitle} onClick={handleClick}>{list.title}</span>;
};

TitleRow.propTypes = {
  classes: PropTypes.object.isRequired,
  enableListMode: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  selectMyList: PropTypes.func.isRequired
};

const ConnectedTitleRow = connect(null, { enableListMode, selectMyList })(withStyles(styles)(TitleRow));

const cellRenderer = (row, column) => {
  const value = row[column.id];
  const content = column.format && typeof column.format === 'function' ? column.format(value, row) : value;

  switch (column.id) {
    case 'title':
      return <ConnectedTitleRow list={row}>{content}</ConnectedTitleRow>;
    case 'datatype':
      return <DatatypeIcons layout="row" types={[content]} />;
    default:
      return <span>{content}</span>;
  }
};

const columns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 100,
  },
  {
    id: 'datatype',
    label: 'Module',
    minWidth: 100
  },
  {
    format: (d) => moment(d).format('MM/DD/YYYY'),
    id: 'updatedAt',
    label: 'Last Viewed',
    minWidth: 100
  },
  {
    format: (items) => items.length,
    id: 'items',
    label: 'Count',
    minWidth: 100,
    sortType: 'array'
  }
];

const handleManage = () => {
  history.push('/account/mylists');
};

const MySavedListsWidget = ({ classes, onRemove }) => {
  const [lists, setLists] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let abortController = new window.AbortController();
    async function fetchSavedLists() {
      try {
        setFetching(true);
        const savedLists = await read(
          'myLists',
          null,
          { limit: 200, sort: '-updatedAt' },
          { signal: abortController.signal }
        );
        setLists(savedLists);
        setFetching(false);
        abortController = null;
      } catch (error) {
        if (!abortController.signal.aborted) {
          setError(error);
          setFetching(false);
        }
      }
    }
    fetchSavedLists();
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, []);

  return (
    <Widget
      onManage={handleManage}
      onRemove={onRemove}
      small
      title="MY SAVED LISTS">
      {fetching && (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      )}
      {!fetching && (
        <React.Fragment>
          {error && (
            <div className={classes.errorWrapper}>
              <Typography variant="h6">{'An error occurred while loading your Saved Lists'}</Typography>
            </div>
          )}
          {!error && (
            <React.Fragment>
              {lists.length > 0 && (
                <React.Fragment>
                  <WidgetTable
                    columns={columns}
                    cellRenderer={cellRenderer}
                    defaultSortColumn="updatedAt"
                    emptyMessage={'You don’t have any saved lists. Go to the map or grid view and add records to a list.'}
                    onRemove={onRemove}
                    rows={lists} />
                </React.Fragment>
              )}
              {lists.length < 1 && (
                <React.Fragment>
                  <WidgetTable
                    columns={columns}
                    cellRenderer={cellRenderer}
                    defaultSortColumn="updatedAt"
                    emptyMessage={'You don’t have any saved lists. Go to the map or grid view and add records to a list.'}
                    onRemove={onRemove}
                    rows={lists} />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Widget>
  );
};

MySavedListsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withStyles(styles)(MySavedListsWidget);

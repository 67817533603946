import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const FormField = ({ field, form, ...custom }) => {
  const touched = !!form.touched[field.name];
  const error = form.errors[field.name];
  return (
    <TextField
      error={touched && Boolean(error)}
      fullWidth
      helperText={touched && error}
      {...field}
      {...custom} />
  );
};

FormField.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired
};

export default FormField;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ShareIcon from '@material-ui/icons/Share';
import { FullStoryAPI as fullStoryAPI } from 'react-fullstory';
import { EVENTS as eventNames } from '../constants/fullstory.js';

const Share = ({ btnLabelClass, item, shareEmail }) => {
  const [anchorEl, setAnchorEl] = useState();

  const onShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onShareClose = () => {
    setAnchorEl();
  };

  const shareViaEmail = () => {
    shareEmail(item);
    fullStoryAPI(eventNames.EVENT, eventNames.E_MAIL, { 'shareEmail_str': eventNames.EMAIL_CLICKED });
    onShareClose();
  };

  const shareViaLinkedIn = () => {
    const sourceUrl = item.sourceUrl;
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(sourceUrl)}&source=ColliersDiscover`;
    fullStoryAPI(eventNames.EVENT, eventNames.LINKEDIN, { 'shareLinkedIn_str': eventNames.LINKEDIN_CLICKED });
    window.open(url);
    onShareClose();
  };

  const shareViaTwitter = () => {
    const { sourceUrl, title } = item;
    const url = `https://twitter.com/share?url=${encodeURI(sourceUrl)}&text=${encodeURI(title)}&via=ColliersDiscover`;
    fullStoryAPI(eventNames.EVENT, eventNames.TWITTER, { 'shareTwitter_str': eventNames.TWITTER_CLICKED });
    window.open(url);
    onShareClose();
  };

  return (
    <React.Fragment>
      <Button
        onClick={onShareClick}
        startIcon={<ShareIcon />}>
        <span className={btnLabelClass}>Share</span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        getContentAnchorEl={null}
        onClose={onShareClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}>
        <MenuItem onClick={shareViaEmail}>E-mail</MenuItem>
        {item._type === 'news' && (<MenuItem onClick={shareViaLinkedIn}>LinkedIn</MenuItem>)}
        {item._type === 'news' && (<MenuItem onClick={shareViaTwitter}>Twitter</MenuItem>)}
      </Menu>
    </React.Fragment>
  );
};

Share.propTypes = {
  btnLabelClass: PropTypes.string,
  item: PropTypes.object.isRequired,
  shareEmail: PropTypes.func.isRequired,
};

export default Share;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import FlagIcon from '@material-ui/icons/Flag';
import CommentsIcon from '@material-ui/icons/Message';
import FieldNote from '../../common/stats/FieldNote';
import { previousPath } from '../../common/getLocationTitles';
import Can from '../../auth/ability';
import ReportsMenu from './Reports';
import styles from './module.css';

const Header = ({ classes, currentUser, isNested, property, navigate, ...props }) => {
  const nav = (key, id = null) => () => navigate(key, id);
  const close = () => {
    props.clearProperty();
    props.router.push({ ...props.location, pathname: `/research/${previousPath()}` });
  };

  const reportProperty = () => {
    props.showProblemReporter(property);
  };

  const fieldNotes = property.fieldNotes || {};
  const getHeaderStatFieldNote = (fieldName, extraStyles) => {
    if (fieldNotes[fieldName]) {
      return (
        <FieldNote color="#FFFFFF" content={fieldNotes[fieldName]} style={extraStyles} />
      );
    }
    return null;
  };

  return (
    <div className={classes.header}>
      <header>
        <div className={classes.metaNav}>
          <Can do="read" on="reports">
            <ReportsMenu
              currentUser={currentUser}
              classes={classes}
              property={property}
              singleReportsList={false} />
          </Can>
          {!currentUser.isGuest && (
            <IconButton className={classes.rightButton} size="small" onClick={reportProperty}>
              <FlagIcon />
            </IconButton>
          )}
          <Can do="read" on="comments">
            <IconButton className={classes.rightButton} size="small" onClick={nav('comments')}>
              <CommentsIcon />
            </IconButton>
          </Can>
          {!isNested &&
            <IconButton className={classes.rightButton} size="small" onClick={close}>
              <CloseIcon />
            </IconButton>
          }
        </div>
        <div className={classes.headerTitle}>
          <Typography className={classes.headerBanner} variant="h5">
            {property.name || (property.address && property.address.split(',')[0])}
            {getHeaderStatFieldNote('name', { top: 2 })}
          </Typography>
          <Typography className={classes.headerSubbanner} variant="h6">
            {property.address} {getHeaderStatFieldNote('address')}
          </Typography>
        </div>
      </header>
    </div>
  );
};

Header.propTypes = {
  classes: PropTypes.object,
  clearProperty: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  isNested: PropTypes.bool,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  showProblemReporter: PropTypes.func.isRequired,
  visibleDatatypes: PropTypes.array.isRequired,
};

export default withStyles(styles)(Header);

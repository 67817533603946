import React from 'react';
import PropTypes from 'prop-types';
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import { createCanBoundTo } from '@casl/react';

export const curateAbility = new Ability([]);

export const registerCuratePermissions = (userInfo) => {
  curateAbility.update(unpackRules(userInfo.permissions));
};

const CurateBoundCan = createCanBoundTo(curateAbility);

const CurateCan = (props) => {
  const { tenant, ...cleanProps } = props;
  return <CurateBoundCan {...cleanProps} field={tenant} />;
};
CurateCan.propTypes = {
  tenant: PropTypes.string,
};

export default CurateCan;

import React from 'react';
import { RangeFilter } from 'searchkit-react16';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircleOutlineIcon from '@material-ui/icons/RadioButtonUnchecked';
import CircleFilledIcon from '@material-ui/icons/FiberManualRecord';
import AndOrRangeAccessor from './accessors/AndOrRangeAccessor';
import styles from './module.css';

class AndOrRange extends RangeFilter {
  static propTypes = {
    classes: PropTypes.object,
    defaultChecked: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      andOrChecked: props.defaultChecked,
      area: { min: props.min, max: props.max },
      minDivisibleArea: { min: props.min, max: props.max }
    };
    this.stepSize = 1;
    this.previousStateRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    this.previousStateRef.current = prevState;
  }

  updateStateWithAccessorValues = (values) => {
    //updates ui state with accessor values after reset is hit to reset TextField values
    //due to nested filters for this component, resetFilters searchkit function doesnt reset values and inputs similar to the other filters
    this.setState({
      andOrChecked: values.andOrChecked,
      area: values.area,
      minDivisibleArea: values.minDivisibleArea
    });
  };

  defineAccessor() {
    const { andOrChecked, area, minDivisibleArea } = this.state;
    return new AndOrRangeAccessor(this.props.field, {
      andOrChecked,
      area,
      minDivisibleArea,
      updateStateWithAccessorValues: this.updateStateWithAccessorValues
    });
  }

  onChange = (fieldInput, minOrMax) => (event) => {
    const value = event.target.value;
    this.setState((prevState) => ({
      [fieldInput]: {
        ...prevState[fieldInput],
        [minOrMax]: value
      }
    }));
  };

  onBlur = (fieldInput, minOrMax) => (event) => {
    const value = event.target.value;
    if (value === '' &&  this.previousStateRef.current?.[fieldInput]?.[minOrMax] === '') return;
    this.setState((prevState) => ({
      [fieldInput]: {
        ...prevState[fieldInput],
        [minOrMax]: value
      }
    }), () => {
      const currentAccessorValue = this.accessor.state.getValue();
      const updateAccessorValue = currentAccessorValue
        ? {
          ...currentAccessorValue,
          [fieldInput]: {
            ...currentAccessorValue[fieldInput],
            [minOrMax]: value
          }
        }
        : {
          [fieldInput]: {
            [minOrMax]: value
          }
        };
      if (value === '') { delete updateAccessorValue[fieldInput][minOrMax]; }
      this.accessor.state = this.accessor.state.setValue(updateAccessorValue);
      this.searchkit.reloadSearch();
    });
  };

  onKeyPress = (fieldInput, minOrMax) => (event) => {
    if (event.key === 'Enter') {
      this.onBlur(fieldInput, minOrMax)(event);
      event.target.blur();
    }
  };

  handleCheckboxChange = (newSelection) => {
    this.setState({ andOrChecked: newSelection }, () => {
      this.accessor.state = this.accessor.state.setValue({
        ...this.accessor.state.getValue(),
        andOrChecked: newSelection
      });
      this.defineAccessor();
      this.searchkit.reloadSearch();
    });
  };

  render() {
    const { andOrChecked, area, minDivisibleArea } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.andOrContainer}>
        <label className={classes.andOrHeaders}>AVAILABLE AREA SF</label>
        <div className={classes.rangeContainer}>
          <TextField
            id="outlined-basic"
            inputProps={{ step: this.stepSize }}
            InputProps={{ style: { borderRadius: 0 } }} 
            label="Min"
            name="min"
            onBlur={this.onBlur('area', 'min')}
            onChange={this.onChange('area', 'min')}
            onKeyPress={this.onKeyPress('area', 'min')}
            size="small"
            type="number"
            value={(area && area.min !== undefined) && (area.min !== this.props.min) ? area.min : ''}
            variant="outlined" />
          <div style={{ padding: '0 10px' }}>-</div>
          <TextField
            id="outlined-basic"
            inputProps={{ step: this.stepSize }}
            InputProps={{ style: { borderRadius: 0 } }} 
            label="Max"
            name="max"
            onBlur={this.onBlur('area', 'max')}
            onChange={this.onChange('area', 'max')}
            onKeyPress={this.onKeyPress('area', 'max')}
            size="small"
            type="number"
            value={(area && area.max !== undefined) && (area.max !== this.props.max) ? area.max : ''}
            variant="outlined" />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <FormControlLabel
            control={
              <Checkbox
                icon={<CircleOutlineIcon />}
                checkedIcon={<CircleFilledIcon />}
                name="andCheckbox"
                checked={andOrChecked === 'AND'}
                onChange={() => this.handleCheckboxChange('AND')} />
            }
            label="AND" />
          <FormControlLabel
            control={
              <Checkbox
                icon={<CircleOutlineIcon />}
                checkedIcon={<CircleFilledIcon />}
                name="orCheckbox"
                checked={andOrChecked === 'OR'}
                onChange={() => this.handleCheckboxChange('OR')} />
            }
            label="OR" />
        </div>
        <label className={classes.andOrHeaders}>MIN DIVISIBLE AREA</label>
        <div className={classes.rangeContainer}>
          <TextField
            id="outlined-basic"
            inputProps={{ step: this.stepSize }}
            InputProps={{ style: { borderRadius: 0 } }} 
            label="Min"
            name="min"
            onBlur={this.onBlur('minDivisibleArea', 'min')}
            onChange={this.onChange('minDivisibleArea', 'min')}
            onKeyPress={this.onKeyPress('minDivisibleArea', 'min')}
            size="small"
            type="number"
            value={(minDivisibleArea && minDivisibleArea.min !== undefined) && (minDivisibleArea.min !== this.props.min)
              ? minDivisibleArea.min : ''}
            variant="outlined" />
          <div style={{ padding: '0 10px' }}>-</div>
          <TextField
            id="outlined-basic"
            inputProps={{ step: this.stepSize }}
            InputProps={{ style: { borderRadius: 0 } }} 
            label="Max"
            name="max"
            onBlur={this.onBlur('minDivisibleArea', 'max')}
            onChange={this.onChange('minDivisibleArea', 'max')}
            onKeyPress={this.onKeyPress('minDivisibleArea', 'max')}
            size="small"
            type="number"
            value={(minDivisibleArea && minDivisibleArea.max !== undefined) && (minDivisibleArea.max !== this.props.max)
              ? minDivisibleArea.max : ''}
            variant="outlined" />
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AndOrRange);

import { createStore, applyMiddleware, compose } from 'redux';
import { syncHistoryWithStore } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import { browserHistory } from 'react-router';
import persistState, { mergePersistedState } from 'redux-localstorage';
import filter from 'redux-localstorage-filter';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import merge from 'lodash/merge';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';

const defaultState = {};

const reducer = compose(
  mergePersistedState((initial, persisted) => merge({}, initial, persisted))
)(rootReducer);

const storage = compose(
  filter([
    'auth.currentUser',
    'research.menu',
    'research.searches.availability',
    'research.searches.company',
    'research.searches.development',
    'research.searches.lease',
    'research.searches.listing',
    'research.searches.news',
    'research.searches.property',
    'research.searches.sale',
    'research.searches.tim',
    'research.map.parcelsEnabled',
    'spotlight.selectedSavedSearch',
    'visibleDatatypes'
  ])
)(adapter(window.localStorage));

const enableReduxTrace = ['staging','development'].includes(process.env.NODE_ENV);
const enhancers = composeWithDevTools({ trace: enableReduxTrace , traceLimit: 25 })(
  persistState(storage, 'app')
);

const createStoreWithMiddleware = applyMiddleware(thunkMiddleware)(createStore);
const store = createStoreWithMiddleware(reducer, defaultState, enhancers);

export const history = syncHistoryWithStore(browserHistory, store);

export default store;

import moment from 'moment';
import numeral from 'numeral';

export function numeric(value) {
  return numeral(value).format('0,0');
}

export function commalessNumeric(value) {
  return numeral(value).format('0');
}

export function bigNumeric(value) {
  return numeral(value).format('0.[0]a').toUpperCase();
}

export function currency(value) {
  return numeral(value).format('$0,0.[00]');
}

export function bigCurrency(value) {
  return numeral(value).format('$0.[0]a').toUpperCase();
}

export function days(value) {
  return moment().diff(value, 'days');
}

export function date(value) {
  return moment(value).format('MM/D/YYYY');
}

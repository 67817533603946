import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dimensions from 'react-dimensions';
import pickBy from 'lodash/pickBy';
import some from 'lodash/some';
import { HIDDEN_GUEST_COLUMNS } from '../../common/datatypes/constants';
import * as researchActions from '../../research/actions';
import * as searchesActions from '../searches/actions';
import TableDetail from './TableDetail';
import * as actions from './actions';
import Table from './Table';

class TableContainer extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    columns: PropTypes.array,
    containerHeight: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired,
    selectAllResults: PropTypes.func.isRequired,
    selectionAnchor: PropTypes.object,
    setSelectionAnchor: PropTypes.func.isRequired,
    setSourceFilterSchema: PropTypes.func.isRequired,
    showSelected: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      columnWidthOverrides: {}
    };
  }

  UNSAFE_componentWillMount() {
    this.props.setSourceFilterSchema('show');
  }

  overrideColumnWidth = (columnIndex, newWidth) => {
    this.setState({
      columnWidthOverrides: {
        ...this.state.columnWidthOverrides,
        [columnIndex]: newWidth
      }
    });
  };

  render() {
    const { children, containerWidth } = this.props;
    const childrenWithProps = React.Children.map(this.props.children, (child) => React.cloneElement(child, this.props));

    // deepish-copy datatypes, then apply width overrides
    const sizedColumns = this.props.columns
      .filter((cObj) => cObj.key !== '__tenant')
      .map((cObj) => { return { ...cObj }; });

    // add the checkbox array
    sizedColumns.unshift({
      format: 'checkbox',
      hideHeader: false,
      key: 'select',
      title: '',
      type: 'checkbox',
      width: 45
    });

    // apply the draggable width-overrides
    sizedColumns.forEach((c, index) => c.width = this.state.columnWidthOverrides[index] || c.width);

    // return
    return (
      <div>
        {childrenWithProps && <TableDetail containerWidth={containerWidth}>{children}</TableDetail>}
        <Table
          {...this.props}
          columns={sizedColumns}
          onColumnResize={this.overrideColumnWidth}
          onSelect={this.onSelect} />
      </div>
    );
  }
}

function mapStateToProps({ auth, research }, { location }) {
  const searches = pickBy(research.searches, (s) => s.isEnabled && s.filters);
  let { currentListView } = location.query;
  if (!searches[currentListView]) currentListView = Object.keys(searches)[0];
  const selectedResults = research.selectedResults[currentListView] || [];
  const { myLists: { myLists } } = research;

  return {
    isSearching: some(searches, 'isSearching'),
    results: searches[currentListView].results || [],
    columns: (research.table && research.table.columns[currentListView] || []).filter(({ isEnabled, key }) => {
      return !(auth.currentUser.isGuest && HIDDEN_GUEST_COLUMNS.includes(key)) && isEnabled;
    }),
    currentUser: auth.currentUser,
    myLists,
    searches,
    selectedResults,
    selectionAnchor: research.selectionAnchor,
    showSelected: !!research.showSelected,
    currentListView,
    orderBy: research.searches[currentListView].orderBy || { key: null, direction: null },
  };
}

const TableContainerWithDimensions = dimensions()(TableContainer);
export default connect(mapStateToProps, { 
  ...actions, ...searchesActions, ...researchActions })(TableContainerWithDimensions);

import {
  GUEST_LOGIN_REQUEST,
  GUEST_LOGIN_ERROR,
  CURRENT_USER_UPDATE_SUCCESS,
  CURRENT_USER_FETCH_ERROR,
  CURRENT_USER_FETCH_REQUEST,
  CURRENT_USER_FETCH_SUCCESS,
  FEED_ITEM_FAVORITE_CREATE_SUCCESS,
  FEED_ITEM_FAVORITE_REMOVE_SUCCESS,
  FEED_ADD_LOCATION_SUCCESS,
  FEED_REMOVE_LOCATION_SUCCESS,
  SET_AUTH_TOKEN
} from '../constants/actionTypes';

const initialState = {
  currentUser: null,
  isFetchingUser: false,
  isFetchingCurateUser: false,
  loginError: null,
  token: null
};

export default function (state = {
  ...initialState,
  token: window.localStorage.getItem('token')
}, action) {
  switch (action.type) {
    case FEED_REMOVE_LOCATION_SUCCESS:
      return {
        ...state,
        currentUser: action.currentUser
      };

    case FEED_ADD_LOCATION_SUCCESS:
      return {
        ...state,
        currentUser: action.currentUser
      };

    case FEED_ITEM_FAVORITE_CREATE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...(state.currentUser || {}),
          tags: [...state.currentUser.tags, action.data]
        }
      };

    case FEED_ITEM_FAVORITE_REMOVE_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...(state.currentUser || {}),
          tags: state.currentUser.tags.filter(({ id }) => id !== action.data)
        }
      };

    case SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.data,
        // clear user if token is null
        ...(action.data ? {} : { currentUser: null }),
      };

    case CURRENT_USER_FETCH_REQUEST:
      return {
        ...state,
        isFetchingUser: true,
        loginError: null
      };

    case CURRENT_USER_FETCH_ERROR:
      return {
        ...state,
        currentUser: null,
        isFetchingUser: false,
        loginError: `${action.error['error_description']}` || 'Could not fetch user.',
      };

    case CURRENT_USER_FETCH_SUCCESS:
    case CURRENT_USER_UPDATE_SUCCESS:
      return {
        ...state,
        currentUser: action.data,
        isFetchingUser: false
      };

    case GUEST_LOGIN_REQUEST:
      return {
        ...state,
        loginError: null
      };

    case GUEST_LOGIN_ERROR:
      return {
        ...state,
        loginError: `${action.error['error_description']}` || 'Could not login.',
      };

    default:
      return state;
  }
}

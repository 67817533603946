import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import ShareIcon from '@material-ui/icons/Share';
import api from '../../services/api';

const copyToClipBoard = function () {
  const copyText = document.querySelector('#linkUrl');
  copyText.select();
  document.execCommand('copy');
};

export class SendShareDialog extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func,
    onComplete: PropTypes.func,
    onError: PropTypes.func,
    shareableLink: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      emails: null,
      copyMe: false,
      isSharing: false,
      message: null
    };
  }

  onEmailChange = (event) => this.setState({ emails: event.currentTarget.value });

  onMessageChange = (event) => this.setState({ message: event.currentTarget.value });

  onShare = () => {
    const { onComplete, onError, shareableLink } = this.props;
    const { copyMe } = this.state;
    this.setState({ isSharing: true });
    api.create(`/il/${shareableLink.id}/share`, {
      copySender: copyMe,
      emails: this.state.emails,
      message: this.state.message,
      url: this.linkUrl()
    })
      .then(() => {
        this.setState({ isSharing: false });
        onComplete();
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isSharing: false });
        if (onError) onError(error);
      });
  };

  handleCancel = () => {
    this.setState({ emails: null, isSharing: false });
    return this.props.onCancel();
  };

  linkUrl = () => {
    return (this.props.shareableLink) ? `${window.location.origin}/il/${this.props.shareableLink.id}` : '';
  };

  toggleCopyMe = (event, isInputChecked) => {
    this.setState({ copyMe: isInputChecked });
  };

  validate = () => {
    if (!this.state.emails) return { valid: 0, invalid: 0 };
    const emails = this.state.emails.split(',');
    const result = emails.reduce((acc, value) => {
      const email = (value || '').trim();
      if (!email) return acc;
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) acc.valid++;
      if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))) acc.invalid++;
      return acc;
    }, { valid: 0, invalid: 0 });
    return result;
  };

  render() {
    const { shareableLink } = this.props;
    const { copyMe, isSharing } = this.state;
    const isDisabled = isSharing || this.validate().invalid > 0 || this.validate().valid === 0;
    return (
      <Dialog
        disableBackdropClick
        open={!!shareableLink}>
        <DialogTitle>Share</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            inputProps={{ id: 'linkUrl' }}
            margin="dense"
            value={this.linkUrl()}
            variant="outlined"
            InputProps={{
              readOnly: true,
              startAdornment: (
                <ShareIcon style={{ marginRight: 6 }} />
              ),
              endAdornment: (
                <Button
                  onClick={copyToClipBoard}
                  style={{
                    marginRight: -12,
                    paddingLeft: 16,
                    paddingRight: 16,
                    whiteSpace: 'nowrap'
                  }}
                  variant="contained">COPY LINK</Button>
              )
            }} />

          <Divider style={{ margin: '16px 0px' }} />

          <div style={{ padding: '0 16px 16px', border: '1px solid #e0e0e0' }}>
            <TextField
              autoFocus
              fullWidth
              label="Email (separate multiple addresses with a comma)"
              multiline
              onChange={this.onEmailChange}
              style={{ marginTop: 16 }}
              InputLabelProps={{ shrink: true }} />

            <TextField
              fullWidth
              label="Message"
              multiline
              onChange={this.onMessageChange}
              style={{ marginTop: 16 }}
              InputLabelProps={{ shrink: true }} />

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  onChange={this.toggleCopyMe}
                  checked={copyMe} />
              }
              label="Send me a copy" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSharing}
            onClick={this.handleCancel}>Cancel</Button>
          <Button
            color="primary"
            disabled={isDisabled}
            onClick={this.onShare}>Send via Email</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default SendShareDialog;

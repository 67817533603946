import grey from '@material-ui/core/colors/grey';

export const COLLIERS_BLUE = '#25408f';
export const COLLIERS_CYAN = '#0c9ed9';
export const COLLIERS_RED = '#ed1a35';
export const COLLIERS_YELLOW = '#ffd400';
export const COLLIERS_TEAL = '#0B90A8';

export default {
  typography: {
    color: grey[700],
    fontFamily: '"Open Sans", Arial, sans-serif',
    lineHeight: 2,
    useNextVariants: true,
    suppressWarning: true,
  },
  palette: {
    primary: {
      main: COLLIERS_BLUE
    },
    secondary: {
      main: '#666666'
    },
    error: {
      main: COLLIERS_RED
    },
    warning: {
      main: COLLIERS_YELLOW
    },
    colliersBlue: COLLIERS_BLUE,
    colliersCyan: COLLIERS_CYAN,
    colliersRed: COLLIERS_RED,
    colliersYellow: COLLIERS_YELLOW,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'html': {
          fontSize: '15px',
        },
        'html, body': {
          backgroundColor: grey[100],
          boxSizing: 'border-box',
          color: grey[700],
          height: '100%',
          overflow: 'hidden'
        },
        '.root, .root-inner': {
          height: '100%'
        },
        '.MuiSnackbar-anchorOriginTopCenter': {
          top: '128px !important'
        }
      }
    }
  }
};

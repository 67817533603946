import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DeleteIcon from '@material-ui/icons/Delete';
import CommentForm from './CommentForm';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'scroll'
  },
  comments: {
    margin: `${theme.spacing()}px auto`,
    maxWidth: 800,
    width: '100%',
  },
  buttons: {
    'textAlign': 'right',
    'marginTop': theme.spacing(),
    '& > button': {
      marginRight: theme.spacing(),
    }
  }
}));

const Comments = ({ currentUser, comments, onCommentSave, deleteComment }) => {
  const classes = useStyles();
  const onDelete = (id) => () => deleteComment(id);

  return (
    <div className={classes.container}>
      <div className={classes.comments}>
        {comments.map(({ id, user, body, createdAt }) => {
          return (
            <Card elevation={1} style={{ margin: '5px' }} key={id}>
              <CardHeader
                title={user.email}
                titleTypographyProps={{ style: { fontWeight: 'bold' }, variant: 'subtitle1' }}
                subheader={moment(createdAt).format('MM/D/YYYY')}
                subheaderTypographyProps={{ variant: 'subtitle2' }}
                action={(currentUser.id === user.id) && (
                  <Tooltip title="Delete Comment" placement="left">
                    <IconButton onClick={onDelete(id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )} />
              <CardContent>
                {body}
              </CardContent>
            </Card>
          );
        })}
        <Card elevation={0}>
          <CardContent>
            <CommentForm classes={classes} saveComment={onCommentSave} />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

Comments.propTypes = {
  comments: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
  onCommentSave: PropTypes.func.isRequired,
};

export default Comments;

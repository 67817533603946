import {
  MenuFilter,
} from 'searchkit-react16';
import BooleanAccessor from './accessors/BooleanAccessor';

export default class BooleanFilter extends MenuFilter {
  defineAccessor() {
    return new BooleanAccessor(this.props.field, this.getAccessorOptions());
  }
}

/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Features from '../common/Features';
import Notes from '../common/Notes';
import Documents from '../common/Documents';
import Images from '../common/Images';
import SummaryFieldStats from '../common/SummaryFieldStats';
import Section from '../common/Section';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const CardContent = ({ item }) => {
  const classes = useStyles();
  const {
    askingRate,
    area,
    availabilityTypes,
    dealStatus,
    floors,
    leasingRepresentatives,
    maxBlockArea,
    minDivisibleArea,
    occupancyType,
    rateType,
    suite,
  } = item;

  const availabilityStats = [
    { label: 'Floor', value: floors, format: 'arrayZeroIndex' },
    { label: 'Suite', value: suite, format: 'number' },
    { label: 'Avail Type', value: availabilityTypes, format: 'arrayZeroIndex' },
    { label: 'Available Area (SF)', value: area, format: 'number' },
    { label: 'Min Div (SF)', value: minDivisibleArea, format: 'number' },
    { label: 'Max Contig (SF)', value: maxBlockArea, format: 'number' },
    { label: 'Rate Min', value: askingRate, format: 'objectMinCurrency' },
    { label: 'Rate Max', value: askingRate, format: 'objectMaxCurrency' },
    { label: 'Rate Type', value: rateType, format: 'string' },
    { label: 'Occupancy', value: occupancyType, format: 'string' },
    { label: 'Listing Company', value: leasingRepresentatives, format: 'listingCompany', customClass: classes.sourceSummaryEllipsis },
    { label: 'Status', value: dealStatus, format: 'string' }
  ];

  return (
    <React.Fragment>
      <SummaryFieldStats stats={availabilityStats} />
    </React.Fragment>
  );
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
};

export const ExpandedCardContent = ({ item }) => {
  const classes = useStyles();
  const fieldNotes = item.fieldNotes || {};
  const {
    amperage,
    amperageHigh,
    askingRate,
    area,
    availableDate,
    availabilityTypes,
    clearHeightMax,
    clearHeightMin,
    columnSpacingDepth,
    columnSpacingWidth,
    columnSpacingWidthMax,
    columnSpacingWidthMin,
    description,
    dockDoors,
    driveIns,
    electricalPhase,
    expenses,
    expirationDate,
    flatRent,
    floors,
    frontage,
    fronting,
    grossUp,
    hasColdStorage,
    hasFencedYard,
    hasMezzanine,
    hasRailSpur,
    improvementType,
    isEntireBuilding,
    isGroupAvailability,
    isVented,
    landlords,
    leasingRepresentatives,
    listedDate,
    dealStatus,
    maxBlockArea,
    minDivisibleArea,
    occupancyDate,
    offMarketDate,
    onMarketDate,
    parkingRatio,
    parkingDetails,
    previousLeaseExpiration,
    previousLeaseTenant,
    rateType,
    recentRateChange,
    recentRateChangeFrom,
    recentRateChangeTo,
    askingRateFrequency,
    sizeClassification,
    sourcedBy,
    spaceUses,
    sprinklers, 
    subLease,
    sublessor,
    suite,
    tenantImprovement,
    vacant,
    virtualTourUrl,
    voltage,
    voltageHigh,
    createdAt,
    updatedAt,
    ourDealsId,
    ourListingId,
    coStarId,
    customId,
    legacyId
  } = item;

  const listingInfo = [
    [
      { key: 'floors', label: 'Floor', value: floors, format: 'array' },
      { key: 'suite', label: 'Suite', value: suite, format: 'string' },
      { key: 'availableArea', label: 'Avail Area (SF)', value: area, format: 'area' },
      { key: 'minDivisibleArea', label: 'Min Divisible (SF)', value: minDivisibleArea, format: 'area' }, 
      { key: 'maxBlockArea', label: 'Max Contig (SF)', value: maxBlockArea, format: 'area' },
      { key: 'vacant', label: 'Vacant', value: vacant, format: 'boolean' },
      { key: 'isEntireBuilding', label: 'Entire Building', value: isEntireBuilding, format: 'boolean' },
      { key: 'virtualTourUrl', label: 'Virtual Tour URL', value: virtualTourUrl, format: 'uri' },
      { key: 'description', label: 'Description', value: description, format: 'string', customClass: classes.spanTwoColumns },
    ],
    [
      { key: 'availabilityTypes', label: 'Availability Type', value: availabilityTypes, format: 'array' },
      { key: 'subLease', label: 'Sublease', value: subLease, format: 'boolean' },
      { key: 'dealStatus', label: 'Status', value: dealStatus, format: 'string' },
      { key: 'availableDate', label: 'Date Available', value: availableDate, format: 'date' }, 
      { key: 'isGroupAvailability', label: 'Group Availability', value: isGroupAvailability, format: 'boolean' },
      { key: 'tenantImprovement', label: 'TI Allowance / SF', value: tenantImprovement, format: 'currency' },
      { key: 'improvementType', label: 'Improvement Type', value: improvementType, format: 'string', },
    ],
    [
      { key: 'askingRate.min', label: 'Asking Rate (Min)', value: askingRate, format: 'objectMinCurrency' },
      { key: 'askingRate.max', label: 'Asking Rate (Max)', value: askingRate, format: 'objectMaxCurrency' },
      { key: 'rateType', label: 'Rate Type', value: rateType, format: 'string' },
      { key: 'askingRateFrequency', label: 'Frequency', value: askingRateFrequency, format: 'string' }, 
      { key: 'expenses', label: 'Expenses', value: expenses, format: 'currency' },
      { key: 'flatRent', label: 'Flat Rent', value: flatRent, format: 'currency' },
      { key: 'grossUp', label: 'Gross-Up', value: grossUp, format: 'currency' },
      { key: 'sourcedBy', label: 'Source', value: sourcedBy, format: 'string' }
    ],
  ];
  const dateInfo = [
    [
      { key: 'listedDate', label: 'Date Listed', value: listedDate, format: 'date' },
      { key: 'expirationDate', label: 'Expires On', value: expirationDate, format: 'date' },
    ],
    [
      { key: 'onMarketDate', label: 'On-Market Date', value: onMarketDate, format: 'date' },
      { key: 'occupancyDate', label: 'Occupancy Date', value: occupancyDate, format: 'date' }
    ],
    [
      { key: 'offMarketDate', label: 'Off-Market Date', value: offMarketDate, format: 'date' },
    ]
  ];

  const contactInfo = [
    [
      { key: 'leasingRepresentatives', label: 'Leasing Rep.', value: leasingRepresentatives, format: 'signatories' },
    ],
    [
      { key: 'landlords', label: 'Landlord', value: landlords, format: 'signatories' },
      { key: 'sublessor', label: 'Sublessor', value: sublessor, format: 'signatories' },
    ]
  ];
  const contactSpecialRow = [
    [
      { key: 'previousLeaseTenant', label: 'Previous Tenant', value: previousLeaseTenant, format: 'company' },
      { key: 'recentRateChangeFrom', label: 'Previous Rate', value: recentRateChangeFrom, format: 'currency' },
    ],
    [
      { key: 'recentRateChangeTo', label: 'New Rate', value: recentRateChangeTo, format: 'currency' },
      { key: 'previousLeaseExpiration', label: 'Prev. Lease Exp.', value: previousLeaseExpiration, format: 'date' },
    ],
    [
      { key: 'recentRateChange', label: 'Recent Rate Change', value: recentRateChange, format: 'currency' },
      { key: 'sizeClassification', label: 'Size Classification', value: sizeClassification, format: 'string' },
    ]
  ];

  const propertyInfo = [
    [
      { key: 'dockDoors', label: 'Dock Doors', value: dockDoors, format: 'number' },
      { key: 'driveIns', label: 'Drive-Ins', value: driveIns, format: 'number' },
      { key: 'amperage', label: 'Amperage (Low)', value: amperage, format: 'number' },
      { key: 'amperageHigh', label: 'Amperage (High)', value: amperageHigh, format: 'number' },
      { key: 'electricalPhase', label: 'Electrical Phase', value: electricalPhase, format: 'number' },
    ],
    [
      { key: 'clearHeightMin', label: 'Min Clear Height', value: clearHeightMin, format: 'number' },
      { key: 'clearHeightMax', label: 'Max Clear Height', value: clearHeightMax, format: 'number' },
      { key: 'voltage', label: 'Volts (Low)', value: voltage, format: 'number' },
      { key: 'voltageHigh', label: 'Volts (High)', value: voltageHigh, format: 'number' },
      { key: 'sprinklers', label: 'Sprinklers', value: sprinklers, format: 'string' },
    ],
    [
      { key: 'columnSpacingDepth', label: 'Col. Spacing (depth)', value: columnSpacingDepth, format: 'number' },
      { key: 'columnSpacingWidth', label: 'Col. Spacing (width)', value: columnSpacingWidth, format: 'number' },
      { key: 'columnSpacingWidthMin', label: 'Min Column Spacing', value: columnSpacingWidthMin, format: 'number' },
      { key: 'columnSpacingWidthMax', label: 'Max Column Spacing', value: columnSpacingWidthMax, format: 'number' },
      { key: 'frontage', label: 'Frontage', value: frontage, format: 'number' },
      { key: 'fronting', label: 'Fronting', value: fronting, format: 'string' },
    ]
  ];

  const propertyInfoSpecialTopRow = [
    [
      { key: 'parkingRatio', label: 'Parking Ratio', value: parkingRatio, format: 'parkingRatio' }
    ],
    [
      { key: 'parkingDetails', label: 'Parking Details', value: parkingDetails, format: 'parkingDetails', customClass: classes.spanTwoColumns }
    ]
  ];

  const propertyInfoSpecialRow = [
    [
      { key: 'hasRailSpur', label: 'Rail Spur', value: hasRailSpur, format: 'boolean' },
      { key: 'hasColdStorage', label: 'Cold Storage', value: hasColdStorage, format: 'boolean' },
      {
        key: 'spaceUses',
        label: 'Space Uses',
        value: spaceUses,
        format: 'spaceUses',
        customClass: classes.spanTwoColumns
      },
    ],
    [
      { key: 'hasMezzanine', label: 'Mezzanine', value: hasMezzanine, format: 'boolean' },
      { key: 'hasFencedYard', label: 'Fenced Yard', value: hasFencedYard, format: 'boolean' },
    ],
    [
      { key: 'isVented', label: 'Vented', value: isVented, format: 'boolean' }
    ]
  ];

  const [recordInfo, setRecordInfo] = useState([
    [
      { key: 'createdAt', value: moment(createdAt).format('MMM D, YYYY') || '--', label: 'Created At', format: 'date' },
      { key: 'usableLandArea', value: moment(updatedAt).format('MMM D, YYYY') || '--', label: 'Last Modified', format: 'date' }
    ],
    [
      { key: 'relatedIds', value: ' ', label: 'Related IDs', format: 'string' },
      { key: 'ourDeals', value: ourDealsId, label: 'Our Deals', format: 'string' },
      { key: 'ourListings', value: ourListingId, label: 'Our Listings', format: 'string' },
      { key: 'coStar', value: coStarId, label: 'CoStar', format: 'string' },
      { key: 'legacy', value: legacyId, label: 'Legacy', format: 'string' }
    ],
    [
      { key: 'customIds', value: ' ', label: 'Custom IDs', format: 'string' },
    ]
  ]);

  useEffect(() => {
    if (customId && customId.length > 0) {
      setRecordInfo((prevRecordInfo) => {
        const newRecordInfo = [...prevRecordInfo];
        customId.forEach((item) => {
          const customItem = { key: `customIds_${item.name}`, value: item.id, label: item.name, format: 'string' };
          newRecordInfo[2].push(customItem);
        });
        return newRecordInfo;
      });
    }
  }, []);

  const { documents, features, images, note } = item;
  return (
    <div className={classes.expandedView}>
      <Section
        title={'Lease Listing Info'}
        sectionArray={listingInfo}
        fieldNotes={fieldNotes} />
      {documents && documents.length > 0 && (
        <Documents documents={documents} />)}
      <Section
        title={'Dates'}
        sectionArray={dateInfo}
        fieldNotes={fieldNotes} />
      <Section
        title={'Contacts'}
        sectionArray={contactInfo}
        fieldNotes={fieldNotes}
        specialRowArray={contactSpecialRow} />
      <Section
        title={'Suite Info'}
        sectionArray={propertyInfo}
        fieldNotes={fieldNotes}
        specialTopRowArray={propertyInfoSpecialTopRow}
        specialRowArray={propertyInfoSpecialRow}
        item={item} />
      <Features
        features={features}
        fieldNote={fieldNotes.features ? fieldNotes.features : null} />
      {note && note.length > 0 && (
        <Notes
          note={note}
          fieldNote={fieldNotes.note ? fieldNotes.note : null} />)}
      {images && images.length > 0 && (
        <Images
          images={images}
          fieldNote={fieldNotes.images ? fieldNotes.images : null} />)}
      <Section
        title={'Record Info'}
        sectionArray={recordInfo}
        fieldNotes={fieldNotes} />
    </div>
  );
};
ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired
};

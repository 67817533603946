import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { browserHistory, Link } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import FlagIcon from '@material-ui/icons/Flag';
import MenuIcon from '@material-ui/icons/MoreVert';
import LoadAllIcon from '@material-ui/icons/SystemUpdateAlt';
import datatypes from '../common/datatypes';
import listItemContent from '../research/map/listItemContent';
import api from '../services/api';
import styles from './module.css';

// TODO: future enhancement -- load more on scroll and paginate rather
//        than load initial / load all
const INITIAL_LOAD_TALLY = 10;
export const QuickView = ({ classes, showProblemReporter, source, title, type }) => {
  const [anchorEl, setAnchorEl] = useState();
  const [loadedAll, setLoadedAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menuItem, setMenuItem] = useState();
  const [items, setItems] = useState([]);
  const [tally, setTally] = useState(0);

  const fetchMore = useCallback((offset, limit) => {
    setLoading(true);
    const params = { offset };
    if (limit) params.limit = limit;
    api.read(source, null, params).then((data) => {
      if (data) {
        setItems([
          ...items,
          ...(data.items || []),
        ]);
        setTally(data.total);
      }
    })
      .finally(() => {
        setLoading(false);
        if (offset !== 0) {
          setLoadedAll(true);
        }
      });
  }, [items, source]);

  useEffect(() => {
    fetchMore(0, INITIAL_LOAD_TALLY);
  }, []);

  const loadAll = () => {
    fetchMore(INITIAL_LOAD_TALLY);
  };

  const getItemLink = (item) => {
    if (type === 'tim') {
      return `/research/map/tim/${item.id}`;
    } else if (item.properties && item.properties[0] && item.properties[0].hash) {
      return `/research/map/${item.properties[0].hash}/${type}/${item.id}`;
    } else if (item.property && item.property.hash) {
      return `/research/map/${item.property.hash}/${type}/${item.id}`;
    }
    return '';
  };

  const openMenu = (item) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    setAnchorEl(e.currentTarget);
    setMenuItem(item);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setMenuItem(null);
  };

  const reportProblem = () => {
    if (menuItem) {
      showProblemReporter(menuItem);
      browserHistory.push(getItemLink(menuItem));
      closeMenu();
    }
  };
 
  const { color, Icon } = datatypes[type];
  const { PrimaryText, SecondaryText } = listItemContent[type];
  return (
    <div className={classes.quickViewContainer}>
      {tally > 0 && (
        <div style={{ position: 'absolute', right: 8, height: 38, display: 'flex', alignItems: 'center' }}>
          <Chip label={tally.toLocaleString()} size="small" />
        </div>
      )}
      <Typography className={classes.quickViewTitle} variant="h6">{title}</Typography>
      {loading ? <LinearProgress /> : <div className={classes.quickViewNotLoading} />}
      {items.length > 0 && (
        <div className={classes.quickViewListContainer}>
          <List style={{ padding: 0 }}>
            {items.map((item) => (
              <ListItem
                key={item.id}
                button
                component={Link}
                to={getItemLink(item)}
                style={{ borderBottom: '1px solid #e0e0e0', alignItems: 'center' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                  <ListItemAvatar>
                    <Avatar
                      src={item.thumbnailUrl}
                      style={{ backgroundColor: color, marginTop: 6 }}>
                      <Icon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={(
                      <PrimaryText
                        {...item}
                        name={item.name || item.canonicalName} />
                    )}
                    secondary={(
                      <SecondaryText
                        {...item}
                        isQuickView={true} />
                    )}
                    secondaryTypographyProps={{ component: 'span', style: { color: '#444', fontSize: '1rem' } }} />
                  <div style={{ marginRight: -12 }}>
                    <IconButton onClick={openMenu(item)}>
                      <MenuIcon />
                    </IconButton>
                  </div>
                </div>
              </ListItem>
            ))}
            <Menu
              anchorEl={anchorEl}
              onClose={closeMenu}
              open={Boolean(anchorEl)}
              MenuListProps={{ onMouseLeave: closeMenu }}>
              <MenuItem onClick={reportProblem}>
                <ListItemIcon><FlagIcon /></ListItemIcon>
                <ListItemText>Report a Problem</ListItemText>
              </MenuItem>
            </Menu>
          </List>
          {!loading && !loadedAll && items.length === INITIAL_LOAD_TALLY && (
            <div style={{ padding: 5, textAlign: 'center' }}>
              <Button 
                color="primary"
                onClick={loadAll}
                startIcon={<LoadAllIcon />}>
                Fetch All
              </Button>
            </div>
          )}
        </div>
      )}
      {!loading && items.length === 0 && (
        <div className={classes.emptyQuickView}>
          <Typography
            align="center"
            color="textSecondary"
            style={{ margin: 0 }}
            variant="subtitle1">
            No records found
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            variant="caption">
            If you think you should see something here, please contact your research team and
            make sure your email address is associated with your contact record in Curate.
          </Typography>
        </div>
      )}
    </div>
  );
};

QuickView.propTypes = {
  classes: PropTypes.object,
  showProblemReporter: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default withStyles(styles)(QuickView);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DatatypeIcons from '../account/common/DatatypeIcons';
import { read } from '../services/api';
import { selectSavedSearch } from '../spotlight/actions';
import { history } from '../store';
import Widget from './common/Widget';
import WidgetTable from './common/WidgetTable';
import styles from './module.css';

const TitleRow = ({ classes, savedSearch, selectSavedSearch }) => {
  const handleClick = () => {
    selectSavedSearch(savedSearch);
    history.push({
      pathname: '/research/map',
      hash: savedSearch.urlHash
    });
  };

  return <span className={classes.clickableTitle} onClick={handleClick}>{savedSearch.title}</span>;
};

TitleRow.propTypes = {
  classes: PropTypes.object.isRequired,
  savedSearch: PropTypes.object.isRequired,
  selectSavedSearch: PropTypes.func.isRequired
};

const ConnectedTitleRow = connect(null, { selectSavedSearch })(withStyles(styles)(TitleRow));

const cellRenderer = (row, column) => {
  const value = row[column.id];
  const content = column.format && typeof column.format === 'function' ? column.format(value, row) : value;

  switch (column.id) {
    case 'title':
      return <ConnectedTitleRow savedSearch={row}>{content}</ConnectedTitleRow>;
    case 'enabledDataTypes':
      return <DatatypeIcons layout="row" types={content} />;
    default:
      return <span>{content}</span>;
  }
};

const columns = [
  {
    id: 'title',
    label: 'Title',
    minWidth: 100,
  },
  {
    id: 'enabledDataTypes',
    label: 'Module',
    minWidth: 100
  },
  {
    format: (d) => moment(d).format('MM/DD/YYYY'),
    id: 'updatedAt',
    label: 'Last Viewed',
    minWidth: 100
  }
];

const handleManage = () => {
  history.push('/account/savedsearches');
};

const MySavedSearchWidget = ({ classes, onRemove }) => {
  const [searches, setSearches] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let abortController = new window.AbortController();
    async function fetchSavedSearches() {
      try {
        setFetching(true);
        const savedSearches = await read(
          'savedSearches',
          null,
          { limit: 200, sort: '-updatedAt' },
          { signal: abortController.signal }
        );
        setSearches(savedSearches);
        setFetching(false);
        abortController = null;
      } catch (error) {
        if (!abortController.signal.aborted) {
          setError(error);
          setFetching(false);
        }
      }
    }
    fetchSavedSearches();
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, []);

  return (
    <Widget
      onManage={handleManage}
      onRemove={onRemove}
      small
      title="MY SAVED SEARCHES">
      {fetching && (
        <div className={classes.loaderWrapper}>
          <CircularProgress />
        </div>
      )}
      {!fetching && (
        <React.Fragment>
          {error && (
            <div className={classes.errorWrapper}>
              <Typography variant="h6">{'An error occurred while loading your Saved Searches'}</Typography>
            </div>
          )}
          {!error && (
            <React.Fragment>
              {searches.length > 0 && (
                <WidgetTable
                  columns={columns}
                  cellRenderer={cellRenderer}
                  defaultSortColumn="title"
                  emptyMessage={'You don’t have any saved searches. Go to the map or grid view and save a search.'}
                  onRemove={onRemove}
                  rows={searches} />
              )}
              {searches.length < 1 && (
                <WidgetTable
                  columns={columns}
                  cellRenderer={cellRenderer}
                  defaultSortColumn="title"
                  emptyMessage={'You don’t have any saved searches. Go to the map or grid view and save a search.'}
                  onRemove={onRemove}
                  rows={searches} />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Widget>
  );
};

MySavedSearchWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default withStyles(styles)(MySavedSearchWidget);

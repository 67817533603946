import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Drawer,
  Icon ,
  IconButton,
  Toolbar ,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Leaflet from 'leaflet';
import { countBy, get } from 'lodash';
import ParcelsIcon from '../../assets/parcel-icon.svg';
import DateRange from './parcels/DateRange';
import Range from './parcels/Range';
import LotSizeRange from './parcels/LotSizeRange';
import RefinementList from './parcels/RefinementList';
import Text from './parcels/Text';
import ResetFiltersButton from './ResetFiltersButton';

const PARCEL_MIN_ZOOM = 15;

const FiltersContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const Filters = styled('div')((props) => ({
  height: `calc(100vh - (140px + ${props.isDirty() ? 36 : 0}px))`,
  overflowX: 'hidden',
  overflowY: 'scroll',
}));

const defaultFilter = () => true;

const defaultFilters = {
  ['use_code_std_desc_lps']: defaultFilter,
  ['building_sqft']: defaultFilter,
  acreage: defaultFilter,
  ['last_market_sale_date_transfer']: defaultFilter,
  ['owner_name']: defaultFilter,
  ['yr_blt']: defaultFilter,
  zoning: defaultFilter
};

function isPointBetween(min, max) {
  return (point) => {
    return (point > min) && (point < max);
  };
}

function applyFilters(filters, data = []) {
  const newData = data.filter((item) => {
    for (const [field, filter] of Object.entries(filters)) {
      if (!filter(item[field])) {
        return false;
      }
    }

    return true;
  });

  return newData;
}

function getGeoFilteredData(bounds, data) {
  if (bounds) {
    const latMin = get(bounds, 'bottom_left.1');
    const latMax = get(bounds, 'top_right.1');
    const lonMin = get(bounds, 'bottom_left.0');
    const lonMax = get(bounds, 'top_right.0');

    const geoFilters = {
      latitude: isPointBetween(latMin, latMax),
      longitude: isPointBetween(lonMin, lonMax)
    };

    const geoFilteredData = applyFilters(geoFilters, data);
    return geoFilteredData;
  }
  return data;
}

const ParcelSearch = ({
  currentLocation,
  data,
  dataTime,
  filterCount,
  onClose,
  onFiltered,
  open,
  parcelFilters,
  resetDate,
  resetFilters,
  selectFilter,
  searchBounds,
}) => {
  const [filters, setFilters] = useState(defaultFilters);
  const [unfilteredData, setUnfilteredData] = useState(data);
  const [filteredData, setFilteredData] = useState(unfilteredData);
  const [filteredDataWithoutMultiSelect, setFilteredDataWithoutMultiSelect] = useState(data);
  const [lastResetDate, setLastResetDate] = useState(resetDate);

  const { zoom } = Leaflet.Hash.parseHash(currentLocation);

  function getFacetOptions(field, data, filteredDataWithoutMultiSelect, filters) {
    const total = countBy(data, field);
    
    let countData;
    if (field === 'use_code_std_desc_lps') {
      countData = applyFilters({
        ['zoning']: filters.zoning,
      }, filteredDataWithoutMultiSelect);
    } else {
      countData = applyFilters({
        ['use_code_std_desc_lps']: filters['use_code_std_desc_lps'],
      }, filteredDataWithoutMultiSelect);
    }

    const filtered = countBy(countData, field);

    const results = [];
    for (const val in total) {
      if (filtered[val]) {
        results.push({ 
          value: (val === 'undefined') ? 'NOT CATEGORIZED' : val, count: filtered[val] });
      }
    }

    return results.sort((a, b) => b.count - a.count);
  }

  useEffect(() => {
    const geoFilteredData = getGeoFilteredData(searchBounds, data);
    setUnfilteredData(geoFilteredData);
    
    const multiSelectFilter = ['use_code_std_desc_lps', 'zoning'];
    let newData = applyFilters({ 
      ...filters, 
      [multiSelectFilter[0]]: defaultFilter, 
      [multiSelectFilter[1]]: defaultFilter 
    }, geoFilteredData);
    setFilteredDataWithoutMultiSelect(newData);
    
    newData = applyFilters({ 
      [multiSelectFilter[0]]: filters[multiSelectFilter[0]], 
      [multiSelectFilter[1]]: filters[multiSelectFilter[1]] 
    }, newData);
    setFilteredData(newData);
  }, [dataTime, filters, searchBounds]);

  const getDirtyCount = () => {
    let count = 0;
    const multiSelectFilter = ['zoning', 'use_code_std_desc_lps'];
    const multiSelectFilterKeys = {
      ['use_code_std_desc_lps']: 'Land Use',
      ['zoning']: 'Zoning'
    };
    for (const filter in filters) {
      if (filters[filter]?.isDirty) {
        count++;
        if (multiSelectFilter.includes(filter)) {
          const multiSelectCount = parcelFilters?.[multiSelectFilterKeys[filter]]?.length || 0;
          count += multiSelectCount - 1;
        }
      }
    }
    
    return count;
  };

  useEffect(() => {
    const isSelectionNulled = parcelFilters?.Zoning?.length > 0 
    &&  parcelFilters?.Zoning?.every((filter) => filter === 'NOT CATEGORIZED') 
    || parcelFilters && parcelFilters['Land Use']?.length > 0
    && parcelFilters && parcelFilters['Land Use']?.every((filter) => filter === 'NOT CATEGORIZED');

    if (filteredData.length === unfilteredData.length && !isSelectionNulled) {
      onFiltered([], getDirtyCount());
    } else {
      onFiltered(filteredData, getDirtyCount());
    }
  }, [filteredData]);

  useEffect(() => {
    setFilters(defaultFilters);
    setLastResetDate(resetDate);
  }, [resetDate]);

  const handleFilterChange = (field) => (updatedFilterFunction) => {
    setFilters({
      ...filters,
      [field]: updatedFilterFunction
    });
  };
  
  const handleResetFilters = () => {
    setFilters(defaultFilters);
    setLastResetDate(Date.now());
    resetFilters();
  };

  useEffect(() => {
    if (filterCount === 0) {
      handleResetFilters();
    }
  }, [filterCount]);

  const isDirty = () => {
    return getDirtyCount() > 0;
  };

  const setFilter = (title, value, type) => {
    let filterValue;
    if (type !== 'Refinement') {
      filterValue = value instanceof Set ? value : new Set([value]);
      selectFilter({ [title]: filterValue.keys().next().value });
      return title;
    } 
    filterValue = [...value];
    selectFilter({ [title]: filterValue });
    return title;
  };

  const getSelectedFilters = () => {
    if (isDirty() && (zoom >= PARCEL_MIN_ZOOM)) {
      return parcelFilters;
    }
  };

  return (
    <Drawer
      elevation={1}
      variant="persistent"
      anchor="left"
      open={!!open}
      sx={{
        '& .MuiDrawer-paper': {
          maxWidth: '100vw',
          overflowY: 'hidden',
          paddingBottom: 4,
          width: 350,
        }
      }}
      ModalProps={{ keepMounted: true }}>
      <div style={{ minHeight: 72 }} />
      <FiltersContainer>
        <AppBar color="transparent" elevation={0} position="static">
          <Toolbar style={{ borderBottom: '1px solid #e0e0e0' }}>
            <Icon
              sx={{
                alignItems: 'center',
                bgcolor: 'colliersTeal',
                display: 'flex',
                justifyContent: 'center',
                mr: 2
              }}>
              <img src={ParcelsIcon} />
            </Icon>
            <Typography sx={{ color: 'var(--grey500)', flex: 1 }} variant="h5">{'Parcels'}</Typography>
            <IconButton edge="end" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {(zoom < PARCEL_MIN_ZOOM) && (
          <Box sx={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center' }}>
            <Typography variant="body">
              Zoom in to view parcel filters
            </Typography>
          </Box>
        )}
        {(zoom >= PARCEL_MIN_ZOOM) && (
          <React.Fragment>
            <ResetFiltersButton hasFilters={isDirty()} resetFilters={handleResetFilters} />
            <Filters isDirty={isDirty}>
              <section>
                <RefinementList
                  filterType="Refinement"
                  key={`landUse-${lastResetDate}`}
                  onFilterChange={handleFilterChange('use_code_std_desc_lps')}
                  options={getFacetOptions(
                    'use_code_std_desc_lps', 
                    unfilteredData, 
                    filteredDataWithoutMultiSelect, 
                    filters
                  )}
                  title="Land Use"
                  setFilter={setFilter}
                  getSelectedFilters={getSelectedFilters} />
              </section>
              <section>
                <Range
                  filterType="Range"
                  key={`propertySize-${lastResetDate}`}
                  onFilterChange={handleFilterChange('building_sqft')}
                  title="Building Size" 
                  setFilter={setFilter}
                  getSelectedFilters={getSelectedFilters} />
              </section>
              <section>
                <LotSizeRange
                  filterType="Range"
                  key={`propertySize-${lastResetDate}`}
                  onFilterChange={handleFilterChange('acreage')} 
                  title="Lot Size" 
                  setFilter={setFilter}
                  getSelectedFilters={getSelectedFilters} />
              </section>
              <section>
                <DateRange
                  filterType="DateRange"
                  key={`propertySize-${lastResetDate}`}
                  onFilterChange={handleFilterChange('last_market_sale_date_transfer')}
                  title="Last Market Sale" 
                  setFilter={setFilter}
                  getSelectedFilters={getSelectedFilters} />
              </section>
              <section>
                <Text
                  filterType="Text"
                  key={`propertySize-${lastResetDate}`}
                  onFilterChange={handleFilterChange('owner_name')} title="Owner Name" 
                  setFilter={setFilter}
                  getSelectedFilters={getSelectedFilters} />
              </section>
              <section>
                <Range
                  filterType="Range"
                  key={`propertySize-${lastResetDate}`}
                  onFilterChange={handleFilterChange('yr_blt')} title="Year Built" 
                  setFilter={setFilter}
                  getSelectedFilters={getSelectedFilters} />
              </section>
              <section>
                <RefinementList
                  filterType="Refinement"
                  key={`propertySize-${lastResetDate}`}
                  onFilterChange={handleFilterChange('zoning')}
                  options={getFacetOptions(
                    'zoning', 
                    unfilteredData, 
                    filteredDataWithoutMultiSelect, 
                    filters
                  )}
                  title="Zoning"
                  setFilter={setFilter}
                  getSelectedFilters={getSelectedFilters} />
              </section>
            </Filters>
          </React.Fragment>
        )}
      </FiltersContainer>
    </Drawer>
  );
};

ParcelSearch.propTypes = {
  currentLocation: PropTypes.string,
  data: PropTypes.array,
  dataTime: PropTypes.string,
  filterCount: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onFiltered: PropTypes.func.isRequired,
  open: PropTypes.bool,
  parcelFilters: PropTypes.object,
  resetDate: PropTypes.number,
  resetFilters: PropTypes.func.isRequired,
  searchBounds: PropTypes.object,
  selectFilter: PropTypes.func.isRequired,
};

export default ParcelSearch;


import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import ChipInput from '../../common/ControlledChipInput';
import NotificationMessage from '../../common/NotificationMessage';
import styles from './module.css';

const ENTER_KEY = 13;
const TAB_KEY = 9;
const SPACE_KEY = 32;
const COMMA_KEY = 188;

const ShareItem = ({
  children,
  classes,
  currentUser,
  hide,
  isShareditemSending,
  isShareditemSent,
  isVisible,
  item,
  send,
}) => {
  const defaultMessage = 'I came across this insight on Colliers Discover '
    + 'and thought you would find it interesting.\n\n'
    + `- ${currentUser.name || currentUser.email}`;
  const [emails, setEmails] = useState([]);
  const [message, setMessage] = useState(defaultMessage);
  const [notification, setNotification] = useState({});

  useEffect(() => {
    if (isShareditemSent) {
      setMessage(defaultMessage);
      setNotification({ msg: 'You have successfully shared this record.', type: 'success', timeout: 5000 });
      hide();
    }
  }, [isShareditemSent]);

  const handleError = (error) => {
    setNotification({ msg: error, type: 'error' });
  };

  const handleNotificationClose = () => {
    setNotification({});
  };

  const save = () => {
    if (emails.length === 0) {
      handleError('Email address is required.');
    } else if (emails.find((email) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email))) {
      handleError('Email address is invalid.');
    } else if (message.length === 0) {
      handleError('A message is required.');
    } else {
      send({
        id: item.id,
        type: item._type,
        email: emails,
        message
      });
    }
  };

  const onChange = (which) => (valueOrEvent) => {
    if (which === 'emails') setEmails(valueOrEvent);
    else if (which === 'message') setMessage(valueOrEvent.target.value);
  };

  return (
    <React.Fragment>
      <Dialog
        classes={{ paper: classes.shareDialogPaper }}
        disableBackdropClick
        maxWidth="md"
        open={!!isVisible}
        onClose={hide}>
        <DialogTitle>Share</DialogTitle>
        <DialogContent>
          {children}
          <ChipInput
            addOnBlur
            className={classes.formField}
            fullWidth
            label="Email Address(es)"
            onChange={onChange('emails')}
            newChipKeyCodes={[ENTER_KEY, TAB_KEY, SPACE_KEY, COMMA_KEY]}
            placeholder="Enter email addresses..."
            InputLabelProps={{ shrink: true }}
            value={emails} />
          <TextField
            className={classes.formField}
            fullWidth
            multiline
            rowsMax={8}
            label="Your Message"
            onChange={onChange('message')}
            placeholder="Enter your message..."
            InputLabelProps={{ shrink: true }}
            value={message} />
        </DialogContent>
        <DialogActions>
          <Button onClick={hide}>Close</Button>
          <Button
            color="primary"
            disabled={isShareditemSending}
            onClick={save}
            variant="contained">Send</Button>
        </DialogActions>
        {isShareditemSending && <LinearProgress />}
      </Dialog>
      <Snackbar
        autoHideDuration={notification.timeout || 10000}
        message={(
          <NotificationMessage text={notification.msg} type={notification.type} />
        )}
        onClose={handleNotificationClose}
        open={Boolean(notification.msg)} />
    </React.Fragment>
  );
};

ShareItem.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  currentUser: PropTypes.object,
  formData: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  isShareditemSending: PropTypes.bool.isRequired,
  isShareditemSent: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  send: PropTypes.func.isRequired,
  shareItem: PropTypes.string,
};

function mapStateToProps({ auth: { currentUser }, feed: { isShareditemSent, isShareditemSending } }) {
  return { currentUser, isShareditemSending, isShareditemSent };
}

export default connect(mapStateToProps)(withStyles(styles)(ShareItem));

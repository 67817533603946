/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import SummaryFieldStats from '../common/SummaryFieldStats';
import Section from '../common/Section';
import Documents from '../common/Documents';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const CardContent = ({ item }) => {
  const {
    developmentType,
    developmentStatus,
    area,
    constructionStartDate,
    deliveryDate,
    developer,
  } = item;

  const developmentStats = [
    { label: 'Development Type', value: developmentType, format: 'string' },
    { label: 'Development Status', value: developmentStatus, format: 'string' },
    { label: 'Area', value: area, format: 'number' },
    { label: 'Construction Start Date', value: constructionStartDate, format: 'date' },
    { label: 'Delivery Date', value: deliveryDate, format: 'date' },
    { label: 'Developer', value: developer, format: 'company' },
  ];

  return (
    <React.Fragment>
      <SummaryFieldStats stats={developmentStats} />
    </React.Fragment>
  );
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
};

export const ExpandedCardContent = ({ item }) => {
  const classes = useStyles();
  const documents = item.documents || [];
  const fieldNotes = item.fieldNotes || {};
  const {
    property: {
      rentableBuildingArea,
      address
    },
    units,
    preleasePercentage,
    developmentStatus,
    developer,
    constructionStartDate,
    deliveryDate,
    constructionCost,
    plannedUses,
    anticipatedTenants,
    createdAt,
    updatedAt
  } = item;

  const developmentInfo = [
    [
      { key: 'property.rentableBuildingArea', label: 'Property Area', value: rentableBuildingArea, format: 'number' },
      { key: 'units', label: 'Number of Units', value: units, format: 'number' },
      { key: 'preleasePercentage', label: 'Prelease Percentage', value: preleasePercentage, format: 'ratio' },
      { key: 'developmentStatus', label: 'Development Status', value: developmentStatus, format: 'string' },
      { key: 'developer', label: 'Developer', value: developer, format: 'company' },
    ],
    [
      { key: 'constructionStartDate', label: 'Construction Start Date', value: constructionStartDate, format: 'date' },
      { key: 'deliveryDate', label: 'Delivery Date', value: deliveryDate, format: 'date' },
      { key: 'constructionCost', label: 'Construction Cost', value: constructionCost, format: 'currency' },
    ],
    [
      { key: 'plannedUses', label: 'Planned Uses', value: plannedUses, format: 'array' },
      { key: 'anticipatedTenants', label: 'Anticipated Tenants', value: anticipatedTenants, format: 'signatories' },
    ]
  ];

  const developmentSpecialTopRow = [
    [{ key: 'property.address', label: 'Property', value: address, format: 'string', customClass: classes.spanThreeColumns }]
  ];

  const recordInfo = [
    [
      { key: 'createdAt', value: moment(createdAt).format('MMM D, YYYY') || '--', label: 'Created At', format: 'date' },
      { key: 'usableLandArea', value: moment(updatedAt).format('MMM D, YYYY') || '--', label: 'Last Modified', format: 'date' }
    ]
  ];

  return (
    <div className={classes.expandedView}>
      <Section
        title={'Development Info'}
        sectionArray={developmentInfo}
        specialTopRow={developmentSpecialTopRow}
        fieldNotes={fieldNotes} />
      {documents && documents.length > 0 && (
        <Documents documents={documents} />)}
      <Section
        title={'Record Info'}
        sectionArray={recordInfo}
        fieldNotes={fieldNotes} />
    </div>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AreaIcon from '@material-ui/icons/BorderOuter';
import DateIcon from '@material-ui/icons/Today';
import PortfolioIcon from '@material-ui/icons/Folder';
import PriceIcon from '@material-ui/icons/AttachMoney';
import StatusIcon from '@material-ui/icons/LocalOffer';
import UnitsIcon from '@material-ui/icons/Functions';
import Stats from '../../common/stats/Stats';
import SummaryStats from '../../common/stats/SummaryStats';
import Gallery from '../../common/Gallery';
import styles from './module.css';

const getClasses = makeStyles(styles);

export const CardContent = ({ item, stats }) => {
  const _item = { ...item };
  
  let areaStat = 'units';
  let areaIcon = UnitsIcon;

  const totalRba = item.properties.reduce((acc, { rentableBuildingArea }) => {
    if (acc === null || !rentableBuildingArea) return null;
    return acc + rentableBuildingArea;
  }, 0);
  if (!item.units || totalRba) {
    _item.rentableBuildingArea = totalRba;
    areaStat = 'rentableBuildingArea';
    areaIcon = AreaIcon;
  }

  const summaryIcons = {
    [areaStat]: areaIcon,
    postedDate: DateIcon,
    price: PriceIcon,
    status: StatusIcon,
  };
  const orderedStats = ['postedDate', areaStat, 'price', 'status'];
  const filteredStats = [
    ...stats,
    { key: 'rentableBuildingArea', label: 'Total RBA (sq. ft)', format: 'number' }
  ].filter(({ key }) => orderedStats.indexOf(key) > -1);

  filteredStats.sort((a, b) => {
    return orderedStats.indexOf(a.key) < orderedStats.indexOf(b.key) ? -1 : 1;
  });

  return <SummaryStats schema={filteredStats} icons={summaryIcons} item={_item} />;
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

export const ExpandedCardContent = ({ stats, item }) => {
  const classes = getClasses();
  const orderedStats = [
    'description',
    'type',
    item.properties.find(({ rentableBuildingArea }) => !rentableBuildingArea) ? '' : 'units', // (if units not shown in summary)
    item.properties.length > 1 ? 'properties' : '',
    'constructions',
    'landArea',
    'tourDates',
    'callForOffersDate',
    'note'
  ];
  const fieldNotes = item.fieldNotes || {};
  const filteredStats = stats.filter(({ key }) => {
    return (item[key] || fieldNotes[key]) && orderedStats.indexOf(key) !== -1;
  });
  filteredStats.sort((a, b) => {
    return orderedStats.indexOf(a.key) < orderedStats.indexOf(b.key) ? -1 : 1;
  });

  return (
    <div>
      <section className={classes.stats}>
        <Stats schema={filteredStats} item={item} />
      </section>
      {/*item.unitMixes && item.unitMixes.length > 0 && (
        <section>
          <h4>Unit Mix</h4>
          <Table>
            <TableHeader displaySelectAll={false}>
              <TableRow selectable={false}>
                <TableHeaderColumn>Bedrooms</TableHeaderColumn>
                <TableHeaderColumn>Bathrooms</TableHeaderColumn>
                <TableHeaderColumn>Units</TableHeaderColumn>
                <TableHeaderColumn>Sq. Ft.</TableHeaderColumn>
                <TableHeaderColumn>Rent</TableHeaderColumn>
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {item.unitMixes.map((unit, i) => (
                <TableRow key={i}>
                  <TableRowColumn>{unit.bed}</TableRowColumn>
                  <TableRowColumn>{unit.bath}</TableRowColumn>
                  <TableRowColumn>{unit.units}</TableRowColumn>
                  <TableRowColumn>{unit.area}</TableRowColumn>
                  <TableRowColumn>{unit.price && `$${unit.price.toLocaleString()}`}</TableRowColumn>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </section>
      )*/}
      {item.images && item.images.length > 0 && (
        <section>
          <div className={classes.gallery}>
            <Gallery images={item.images} />
          </div>
        </section>
      )}
    </div>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

export const Subtitle = (item) => {
  return (item.properties || []).length > 1 && (
    <div>
      <PortfolioIcon
        style={{
          height: '18px',
          marginRight: '3px',
          position: 'relative',
          top: '4px',
          width: '18px'
        }} />
      Portfolio Sale
    </div>
  );
};

Subtitle.propTypes = {
  item: PropTypes.object.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Launch from '@material-ui/icons/Launch';
import Link from '@material-ui/core/Link';
import plur from 'plur';
import styles from './module.css.js';
import { withStyles } from '@material-ui/core/styles';

const BoomMenuItem = ({ currentListView, selectedResults, classes }) => {
  const boomUrl = () => {
    const idList = [];
    selectedResults.map((element) => idList.push(element._id));
    const boomParams = {
      source: 'discover',
      type: plur(currentListView),
      ids: idList,
    };
    
    // eslint-disable-next-line no-undef
    return `${process.env.REACT_APP_BOOM_APP_DOMAIN}/?uploadpoints=${JSON.stringify(boomParams)}`;
  };
  
  const handleSendToBoom = (e) => {
    e.stopPropagation();
  };

  return (
    <Link
      className={classes.boomLink}
      href={boomUrl()}
      onClick={handleSendToBoom}
      target={'_blank'}>
      <MenuItem>
        <ListItemIcon>
          <Launch />
        </ListItemIcon>
        <ListItemText primary="Send to Boom" />
      </MenuItem>
    </Link>
  );
};

BoomMenuItem.propTypes = {
  classes: PropTypes.object.isRequired,
  currentListView: PropTypes.string,
  selectedResults: PropTypes.object,
};

export default withStyles(styles)(BoomMenuItem);

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css.js';
import Sort from '@material-ui/icons/Sort';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import MyAlertsRow from './MyAlertsRow';
import { Typography } from '@material-ui/core';

const MyAlertsTable = ({ classes, alerts, fetchMyAlert }) => {
  const [myAlerts, setMyAlerts] = useState(alerts); // Shallow Copy to Enable Updates & Deletes
  const [sortedAlerts, setSortedAlerts] = useState(alerts); // Used for Sorting (Timestamp & Alphabetical titles)
  const [filteredAlerts, setFilteredAlerts] = useState(alerts); // Used for Module Filtering
  const [sortTimeIcon, setSortTimeIcon] = useState(false);
  const [sortNameIcon, setSortNameIcon] = useState(false);
  const [sortRecordIcon, setSortRecordIcon] = useState(false);
  const [page, setPage] = useState(0);

  const sortField = (field) => {
    if (field === 'timestamp') {
      setSortNameIcon(false);
      setSortTimeIcon(sortTimeIcon === false);
      const chronological = [...filteredAlerts].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      return setSortedAlerts((sortTimeIcon === true) ? filteredAlerts : chronological);
    } else if (field === 'name') {
      setSortNameIcon(sortNameIcon === false);
      setSortTimeIcon(false);
      const alphabetical = [...filteredAlerts].sort((a, b) => a.title.localeCompare(b.title));
      return setSortedAlerts((sortNameIcon === true) ? filteredAlerts : alphabetical);
    } else if (field === 'recordNumber') {
      setSortNameIcon(false);
      setSortTimeIcon(false);
      setSortRecordIcon(sortRecordIcon === false);
      const asc = [...filteredAlerts].sort((a, b) => a.value.items.length - b.value.items.length);
      const desc = [...filteredAlerts].sort((a, b) => b.value.items.length - a.value.items.length);
      return setSortedAlerts((sortRecordIcon === true) ? asc : desc);
    }
  };
  const sortName = () => sortField('name');
  const sortTimestamp = () => sortField('timestamp');
  const sortRecordNumber = () => sortField('recordNumber');

  const onUpdateAlerts = useMemo(() => (title, description, value) => {
    const updatedSortedAlerts = sortedAlerts.filter((alert) => alert.value.id !== value.id);
    updatedSortedAlerts.unshift({
      title,
      description,
      datatype: value.datatype,
      timestamp: new Date().toISOString(),
      value
    });
    const updatedOriginalAlerts = myAlerts.filter((alert) => alert.value.id !== value.id);
    updatedOriginalAlerts.unshift({
      title,
      description,
      datatype: value.datatype,
      timestamp: new Date().toISOString(),
      value
    });
    setMyAlerts(updatedOriginalAlerts);
    setFilteredAlerts(updatedSortedAlerts);
    setSortedAlerts(updatedSortedAlerts);
  });

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
  };

  return (
    <React.Fragment>
      <div className={classes.alertHeader}><Typography className={classes.alertText}>MY ALERTS LOG</Typography></div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.alertTableHeaderCell}>
                <span className={classes.alertTableHeaderActionWrapper}>
                  <p className={classes.alertTableHeaderCellBodyAction}>Name</p>
                  <IconButton
                    onClick={sortName}
                    className={classes.alertTableHeaderCellSort}
                    size="small">
                    {sortNameIcon ? <Sort style={{ transform: 'scaleY(-1)' }} /> : <Sort />}
                  </IconButton>
                </span>
              </TableCell>
              <TableCell className={classes.alertTableHeaderCellDescription}>
                <p className={classes.alertTableHeaderCellBody}>Description</p>
              </TableCell>
              <TableCell className={classes.alertTableHeaderCell}>
                <span className={classes.alertTableHeaderActionWrapper}>
                  <p className={classes.alertTableHeaderCellBodyAction}># of Records</p>
                  <IconButton
                    onClick={sortRecordNumber}
                    className={classes.alertTableHeaderCellSort}
                    size="small">
                    {sortRecordIcon ? <Sort style={{ transform: 'scaleY(-1)' }} /> : <Sort />}
                  </IconButton>
                </span>
              </TableCell>
              <TableCell className={classes.alertTableHeaderCell}>
                <span className={classes.alertTableHeaderActionWrapper}>
                  <p className={classes.alertTableHeaderCellBody}>Module</p>
                </span>
              </TableCell>
              <TableCell className={classes.alertTableHeaderCell}>
                <span className={classes.alertTableHeaderActionWrapper}>
                  <p className={classes.alertTableHeaderCellBodyAction}>Alert Date</p>
                  <IconButton
                    className={classes.alertTableHeaderCellSort}
                    onClick={sortTimestamp}
                    size="small">
                    {sortTimeIcon ? <Sort className={classes.sortIcon} /> : <Sort />}
                  </IconButton>
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(sortedAlerts.slice(page * 20, page * 20 + 20).map((alert) => (
              <MyAlertsRow
                key={alert.value.id}
                fetchMyAlert={fetchMyAlert}
                onUpdateAlerts={onUpdateAlerts}
                alert={alert} />
            )))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rows={sortedAlerts}
                rowsPerPageOptions={[20]}
                colSpan={3}
                count={sortedAlerts.length}
                rowsPerPage={20}
                page={page}
                onChangePage={handleChangePage} />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

MyAlertsTable.propTypes = {
  alerts: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  fetchMyAlert: PropTypes.func.isRequired,
};

export default withStyles(styles)(MyAlertsTable);

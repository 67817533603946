import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import GridOnIcon from '@material-ui/icons/GridOn';
import MapIcon from '@material-ui/icons/Map';
import Tooltip from '@material-ui/core/Tooltip';
import WidgetTable from './WidgetTable';
import styles from './module.css';

const Widget = ({
  children,
  classes,
  onManage,
  onRemove,
  onMapViewClick,
  onTableViewClick,
  small,
  title
}) => {
  const [records, setRecords] = useState(10);

  const handleRecordChange = (event) => {
    setRecords(event.target.value);
  };

  const dragHandleButton = (
    <IconButton className={cx(classes.dragHandle, 'dashboard-widget-drag-handle')}>
      <DragHandleIcon />
    </IconButton>
  );

  const manageButton = 
  (title !== 'ADD WIDGET' && <button className={classes.manageButton} onClick={onManage}>MANAGE</button>);

  const headerDropDown = (
    <div>
      <FormControl className={classes.dropDown}> 
        <Select
          name="records"
          value={records}
          onChange={handleRecordChange}>
          <MenuItem value={10}>Last 10 Days</MenuItem> 
          <MenuItem value={20}>Last 20 Days</MenuItem>
          <MenuItem value={30}>Last 30 Days</MenuItem>
        </Select> 
      </FormControl>
    </div>
  );

  const headerButtons = (
    <div className={classes.headerIcons}>
      <Tooltip title="GRID VIEW">
        <IconButton onClick={onTableViewClick}>
          <GridOnIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="MAP VIEW">
        <IconButton onClick={onMapViewClick}>
          <MapIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <Card className={classes.card} elevation={5}>
      <div className={classes.tableHeader}>
        <CardHeader
          action={dragHandleButton}
          title={title} 
          className={classes.title} />

        {!small && title !== 'ADD WIDGET' && title !== 'ADD WIDGETS' && headerDropDown}
      
        {small ?
          manageButton : (
            (title !== 'ADD WIDGET' && title !== 'ADD WIDGETS' && headerButtons)
          )}
      </div>

      <CardContent>
        {children || <WidgetTable defaultSortColumn="name" onRemove={onRemove} />}
      </CardContent>
    </Card>
  );
};

Widget.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  onManage: PropTypes.func,
  onMapViewClick: PropTypes.func,
  onRemove: PropTypes.func,
  onTableViewClick: PropTypes.func,
  small: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default withStyles(styles)(Widget);

// TODO: combine this with search.js
import request from 'superagent';
import _get from 'lodash/get';
import { getHeaders } from './api';

let requestCache = {};

function get(key, url) {
  if (requestCache[key]) {
    requestCache[key].abort();
  }

  requestCache[key] = request('GET', `${process.env.REACT_APP_API_URL}/${url}`)
    .set(getHeaders())
    .accept('json');
  return requestCache[key];
}

export default function search(text = '', location = null, size = 10) {
  const geoParams = [`address=${text}`, `size=${size}`];

  if (location && typeof location === 'string') {
    geoParams.push(`location=${encodeURI(location)}`);
  }

  return Promise.all([
    get('geo', `geocode?${geoParams.join('&')}`),
  ])
    .then((results) => {
      requestCache = {};
      const [geo] = results.map((r) => r.body);
      if (geo.address) {
        geo._type = 'geocode';
        return [geo];
      }

      return geo;
    });
}

function parseLocation(geo) {
  if (!geo) {
    return {};
  }

  const longitude = _get(geo, 'location.0') || _get(geo, 'attributes.X');
  const latitude = _get(geo, 'location.1') || _get(geo, 'attributes.Y');

  return {
    address: geo.address,
    latitude,
    longitude
  };
}

export function geocodeSearch(text = '') {
  return get('geo', `geocode?address=${text}`)
    .then((results) => {
      requestCache = {};
      const geo = parseLocation(results.body);
      if (geo.address) {
        geo.location = [geo.longitude, geo.latitude];
        geo._type = 'geocode';
        return [geo];
      }
      return [];
    });
}

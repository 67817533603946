export default (theme) => ({
  accountPageMain: {
    width: '100%'
  },
  accountPageWrapper: {
    margin: theme.spacing(4),
    paddingBottom: '32px'
  },
  accountSubheader: {
    marginBottom: theme.spacing(3)
  },
  accountSubheaderWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
    border: '2px solid #9d9d9d', 
    width: 'fit-content',
    minHeight: 'none',
  },
  accountSubheaderTitle: {
    fontWeight: 'bold'
  },
  accountSubheaderDivider: {
    width: '100%',
    height: theme.spacing(0.50),
    backgroundColor: '#2F438C'
  }
});

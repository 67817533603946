export const detailReports = [
  '618428061be5fca1af443894',
  '5f7c8287a146cb640edd6841', 
  '5f6244c19ebe303eac8b0bfb', 
  '5af0eded95ffe5aa54ede78a', 
  '618449211be5fca1af6c1911', 
  '616d940a7c9d334e93ce76a5',
  '5c865739b3e967130b2c31ce',
  '618449211be5fca1af6c1911',
  '5c87ead4b3e967130b2c321f',
  '5c87e86bb3e967130b2c321d',
  '5c87c533b3e967130b2c3218',
  '5c87eaa9b3e967130b2c321e',
  '6185849c1be5fca1af122281', 
  '5c87c0dab3e967130b2c3211', 
  '618c5c9a1be5fca1af3b306d',
  '618c5cea1be5fca1af3b85ef',
  '618c63c31be5fca1af4338a6',
  '618c640d1be5fca1af4389da'
];

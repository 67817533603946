import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FeaturePoint, FeaturePolygon } from './FeatureShape';

export const SubmarketFeature = ({ featureContent, map, fetchGISFeature, bounds }) => {
  const [featureLayer, setFeatureLayer] = useState(null);

  const getData = async () => {
    if (featureLayer) {
      await featureLayer.fetchData(bounds, fetchGISFeature);
    }
  };

  useEffect(() => {
    const lyr = new FeaturePolygon({ featureContent, map, fetchGISFeature });
    setFeatureLayer(lyr);
    return () => lyr.clearData();
  }, []);

  useEffect(() => {
    getData();
  }, [bounds]);

  useEffect(() => {
    if (featureLayer) {
      featureLayer.addData(featureContent.data);
    }
  }, [featureContent]);

  return null;
};

SubmarketFeature.propTypes = {
  bounds: PropTypes.object.isRequired,
  featureContent: PropTypes.object,
  fetchGISFeature: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired
};

export const POILayers = ({ featureContent, map, fetchGISFeature, bounds }) => {
  const [layers, setLayers] = useState([]);
  useEffect(() => {
    const results = layers.filter((lyr) => !featureContent.some((feature) => feature.id === lyr.id));
    if (!featureContent.length && layers.length) {
      layers.map((lyr) => map.removeLayer(lyr.feature));
      setLayers([]);
    } else if (results.length) {
      setLayers([...layers.filter((lyr) => lyr.id !== results[0].id)]);
      map.removeLayer(results[0].feature);
    } else {
      // makes sure that a layer is only being created for the new feature.
      const featureResult = featureContent.filter((feature) => !layers.some((lyr) => feature.id === lyr.id));
      featureResult.forEach((feature) => {
        setLayers([...layers, new FeaturePoint({ featureContent: feature, map, fetchGISFeature })]);
      });
    }
  }, [featureContent.length]);

  useEffect(() => {
    if (layers.length) {
      layers.forEach((lyr) => {
        lyr.fetchData(bounds, fetchGISFeature);
      });
    }
  }, [bounds]);

  useEffect(() => {
    if (layers.length) {
      layers.forEach((lyr) => {
        const data = featureContent.find((feature) => feature.id === lyr.id);

        if (data && !data.data.length) {
          lyr.addData(data.data);
        }
      });
    }
  }, [featureContent]);

  return null;
};

POILayers.propTypes = {
  bounds: PropTypes.object.isRequired,
  featureContent: PropTypes.object,
  fetchGISFeature: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DateIcon from '@material-ui/icons/Today';
import PortfolioIcon from '@material-ui/icons/Folder';
import PriceIcon from '@material-ui/icons/AttachMoney';
import SignatureIcon from '@material-ui/icons/Edit';
import TypeIcon from '@material-ui/icons/LocalOffer';
import Stats from '../../common/stats/Stats';
import SummaryStats from '../../common/stats/SummaryStats';
import styles from './module.css';

const getClasses = makeStyles(styles);

export const CardContent = ({ stats, item }) => {
  const summaryIcons = {
    saleType: TypeIcon,
    signedDate: SignatureIcon,
    soldDate: DateIcon,
    transactionValue: PriceIcon,
  };
  const orderedStats = ['soldDate', 'signedDate', 'transactionValue', 'saleType'];
  const filteredStats = stats.filter(({ key }) => {
    return orderedStats.indexOf(key) > -1;
  });

  filteredStats.sort((a, b) => {
    return orderedStats.indexOf(a.key) < orderedStats.indexOf(b.key) ? -1 : 1;
  });

  return <SummaryStats schema={filteredStats} icons={summaryIcons} item={item} />;
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array,
};

export const ExpandedCardContent = ({ stats, item }) => {
  const classes = getClasses();
  const fieldNotes = item.fieldNotes || {};
  const filteredStats = stats.filter(({ key }) => {
    return (item[key] || fieldNotes[key]) && [
      '__tenant',
      '_type',
      'canonicalName',
      'id',
      'fieldNotes',
      'location',
      'isExpanded',
      'isHydrated',
      'rescourId',
      'createdAt',
      item.properties.length === 1 ? 'properties' : '',
      'closest',
    ].indexOf(key) === -1;
  });

  return (
    <section className={classes.stats}>
      <Stats schema={filteredStats} item={item} />
    </section>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array.isRequired,
};

export const Subtitle = (item) => {
  return (item.properties || []).length > 1 && (
    <div>
      <PortfolioIcon
        style={{
          height: '18px',
          marginRight: '3px',
          position: 'relative',
          top: '4px',
          width: '18px'
        }} />
      Portfolio Sale
    </div>
  );
};

Subtitle.propTypes = {
  item: PropTypes.object.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import datatypes from '../../common/datatypes';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';

const DatatypeIcons = ({ layout, types }) => {
  if (!Array.isArray(types)) return null;
  return (
    <div style={{ display: 'flex', flexDirection: layout || 'row' }}>
      {types.map((result, index) => {
        if (result === 'news') return <span key={result} />;
        const { Icon, color } = datatypes[result];
        const capitalizedDatatype = result.charAt(0).toUpperCase() + result.slice(1);
        return (
          <Tooltip key={index} title={capitalizedDatatype}>
            <Avatar
              key={index}
              style={{ backgroundColor: color, height: 20, width: 20, margin: '2px' }}>
              <Icon style={{ height: 12, width: 12 }} color="inherit" />
            </Avatar>
          </Tooltip>
        );
      })}
    </div>
  );
};

DatatypeIcons.propTypes = {
  layout: PropTypes.string,
  types: PropTypes.array.isRequired
};

export default DatatypeIcons;

import {
  REPORT_PREVIEW_REQUEST,
  REPORT_PREVIEW_SUCCESS,
  REPORT_PREVIEW_ERROR,
  REPORT_GENERATE_REQUEST,
  REPORT_GENERATE_SUCCESS,
  REPORT_GENERATE_ERROR,
  DISMISS_REPORT_ERROR,
} from '../../constants/actionTypes';

export default function (state = {
  isBuildingFullReport: false,
  isFetchingPreviewReport: false,
  previewReportDataUri: null,
  reportGenerationError: null,
}, action) {
  switch (action.type) {
    case REPORT_PREVIEW_REQUEST:
      return {
        ...state,
        isFetchingPreviewReport: true,
      };

    case REPORT_PREVIEW_SUCCESS:
      return {
        ...state,
        isFetchingPreviewReport: false,
        previewReportDataUri: action.data.dataUri
      };

    case REPORT_PREVIEW_ERROR:
      return {
        ...state,
        isFetchingPreviewReport: false,
      };
    
    case REPORT_GENERATE_REQUEST:
      return {
        ...state,
        isBuildingFullReport: true,
        reportGenerationError: null,
      };

    case REPORT_GENERATE_SUCCESS:
      return {
        ...state,
        isBuildingFullReport: false,
      };

    case REPORT_GENERATE_ERROR:
      return {
        ...state,
        isBuildingFullReport: false,
        reportGenerationError: 'There was a problem generating your report. Please try again.'
      };

    case DISMISS_REPORT_ERROR:
      return {
        ...state,
        reportGenerationError: null
      };
  }

  return state;
}

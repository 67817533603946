import React from 'react';
import PropTypes from 'prop-types';
import ChipInput from 'material-ui-chip-input';

class ControlledChipInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chips: []
    };
  }

  onBlur = (event) => {
    if (this.props.addOnBlur && event.target.value) return this.handleRequestAdd(event.target.value);
  };

  handleRequestAdd = (chip) => {
    const chips = [...this.state.chips, chip];
    this.setState({ chips });
    if (this.props.onChange) this.props.onChange(chips);
  };

  handleRequestDelete = (deletedChip) => {
    const chips = this.state.chips.filter((c) => c !== deletedChip);
    this.setState({ chips });
    if (this.props.onChange) this.props.onChange(chips);
  };

  render() {
    return (
      <ChipInput
        {...this.props}
        value={this.state.chips}
        onAdd={this.handleRequestAdd}
        onDelete={this.handleRequestDelete}
        onBlur={this.onBlur}
        fullWidth />
    );
  }
}

ControlledChipInput.propTypes = {
  addOnBlur: PropTypes.bool,
  onChange: PropTypes.func
};

export default ControlledChipInput;

import React, { useState } from 'react';
import { useTracker } from '@colliers-international/usage-tracker';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import Popper from '@material-ui/core/Popper';
import { FileIcon, defaultStyles } from 'react-file-icon';

export const DocumentsMenu = ({ classes, documents }) => {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);
  const { trackEvent } = useTracker();

  const onButtonMouseEnter = () => {
    setOpenTooltip(true);
  };

  const onButtonMouseLeave = () => {
    setOpenTooltip(false);
  };

  const onOpenMenu = (event) => {
    event.stopPropagation();
    const trackedEvent = { category: 'Discover Map Interface', action: 'Clicked', name:'Show Documents' };
    trackEvent(trackedEvent);
    setOpenMenu(!openMenu);
    setAnchorEl(event.currentTarget);
    setOpenTooltip(false);
  };

  const onCloseMenu = (event) => {
    event.stopPropagation();
    setOpenMenu(false);
    setAnchorEl(null);
  };

  const handleLinkClick = (event) => {
    event.stopPropagation();
    setOpenTooltip(false);
  };

  const renderDocumentDetails = (document) => {
    const documentDetails = [
      { label: 'Name:', value: document.fileName || '-' },
      { label: 'Date Created:', value: document.createdAt ? moment(document.createdAt).format('MM/DD/YYYY') : '-' },
      { label: 'Category:', value: document.attachmentType || '-' },
    ];

    return documentDetails.map((detail) => (
      <div key={detail.createdAt} style={{ display: 'flex', alignItems: 'center' }}>
        <strong className={classes.documentDetailLabel}>{detail.label}</strong>
        <p className={classes.documentDetailValue}>{detail.value}</p>
      </div>
    ));
  };

  return (
    <React.Fragment>
      <Tooltip 
        title={openMenu ? '' : 'Show Documents'}
        placement="top"
        open={openTooltip}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow>
        <IconButton
          className={classes.listItemIcons}
          onClick={onOpenMenu}
          onMouseEnter={onButtonMouseEnter}
          onMouseLeave={onButtonMouseLeave}>
          <AttachFileIcon />
        </IconButton>
      </Tooltip>
      {openMenu && (
        <ClickAwayListener onClickAway={onCloseMenu}>
          <Popper
            open={openMenu}
            anchorEl={anchorEl}
            placement="bottom-end"
            leftoffset={50}
            className={classes.myListsToolTipContainer}
            transition
            modifiers={{
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: true,
                boundariesElement: 'viewPort',
              },
              arrow: {
                enabled: true,
                element: arrowRef,
              },
            }}>
            <div
              className={classes.paper}
              style={{
                width: '100%',
                margin: 0,
                padding: 0,
                border: 'none',
                overflowY: 'auto',
              }}>
              <div className={classes.arrow} ref={setArrowRef} />
              <List
                classes={{ root: classes.documentsListWrapper }}>
                {documents.map((document, index) => {
                  const extension = (document.url || '').split('.').pop() || 'document';
                  const isImage = (document.mimeType || '').indexOf('image/') === 0;
                  return (
                    <React.Fragment key={index}>
                      {index > 0 && <Divider />}
                      <ListItem
                        button
                        component="a"
                        href={document.url}
                        target="_blank"
                        onClick={handleLinkClick}
                        classes={{
                          container: classes.documentsListItemContainer,
                          root: classes.documentsListItemRoot,
                        }}>
                        <ListItemAvatar>
                          {isImage ? (
                            <Avatar src={document.url} variant="rounded" />
                          ) : (
                            <div className={classes.documentsFileIcon}>
                              <FileIcon
                                extension={extension.toUpperCase()}
                                {...defaultStyles[extension]} />
                            </div>
                          )}
                        </ListItemAvatar>
                        <div className={classes.documentDataWrapper}>
                          {renderDocumentDetails(document)}
                        </div>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </List>
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </React.Fragment>
  );
};

DocumentsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired,
};

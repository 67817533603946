import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import startCase from 'lodash/startCase';
import isUndefined from 'lodash/isUndefined';
import { ACRES_PER_SQ_M, SQ_FT_PER_SQ_M } from '../../research/constants.js';
import ExternalLink from '../../common/ExternalLink';
import Signatory from '../../common/Signatory';
import AreaChange from './AreaChange';
import ArrayStat from './ArrayStat';
import TourDates from './TourDates';
import Documents from './Documents';
import UnitMixes from './UnitMixes';
import ParkingDetails from './ParkingDetails';
import PropertyLinks from './PropertyLinks';
import MinMax from './MinMax';
import SpaceUses from './SpaceUses';
import PhoneNumbers from './PhoneNumbers';

export default function formatStat(value, { arrayFormat, format, objectFormat }, item) {
  if (value == null) return value;

  try {
    switch (format) {
      case 'parkingRatio':
        return `${value} per 1000 sqft`;

      case 'phoneNumbers':
        return <PhoneNumbers value={value} />;

      case 'properCaseString':
        return startCase(value);

      case 'documents':
        return <Documents value={value} />;

      case 'signatory':
      case 'signatories':
        return <Signatory signatories={value} />;

      case 'array':
        if (!Array.isArray(value)) return '';
        if (value.length === 0) return '';
        return <ArrayStat value={value} arrayFormat={arrayFormat} />;

      case 'arrayZeroIndex':
        return value[0];

      case 'area':
        return `${numeral(value).format('0,0')} SF`;

      case 'boolean':
        return value ? 'Y' : 'N';

      case 'tourDates':
        if (value.length === 0) return '';
        return <TourDates value={value} />;

      case 'currency':
        return numeral(value).format('$0,0.00');

      case 'wholeCurrency':
        return numeral(value).format('$0,0');

      case 'months':
        return `${value} months`;

      case 'number':
        return value.toLocaleString();

      case 'wholeNumber':
        return numeral(value).format('0,0');

      case 'ratio':
        return `${numeral(100 * value).format('0.[00000]')}%`;

      case 'date-time':
      case 'date':
        return moment(value).format('M/D/YYYY');

      case 'unitMixes':
        return <UnitMixes value={value} />;

      case 'uri':
        return <ExternalLink text="View" url={value} />;

      case 'parkingDetails':
        return <ParkingDetails value={value} />;

      case 'property':
        return value.canonicalName || value.name || value.address;

      case 'propertyLinks':
        return <PropertyLinks properties={value} item={item} />;

      case 'propertyTypesHeader':
        if (value && value.length > 1) return 'Mixed Use';
        return value[0];

      case 'spaceUses':
        return <SpaceUses value={value} item={item} />;

      case 'escalationRate':
        if (item.escalationType === 'percent') return numeral(value).format('0.[00000]%');
        return numeral(value).format('$0,0.00[0]');

      case 'company':
        if (isUndefined(value.name)) return '-';
        return value.name;

      case 'developers':
        return value.map((el, key) => {
          if (el && el.developer && el.developer.name) {
            return <div key={`developer-${el.developer.name}`}>{el.developer.name}</div>;
          }
          return <div key={`developer-${key}`}>-</div>;
        });

      case 'listingCompany':
        return value.filter((el) => el.type === 'Company').map((el) => el.name).join(', ');

      case 'minMax':
        return <MinMax value={value} objectFormat={objectFormat} />;

      case 'areaChange':
        return <AreaChange value={value} />;

      case 'objectMinCurrency':
        return numeral(value.min).format('$0,0.00');

      case 'objectMaxCurrency':
        return numeral(value.max).format('$0,0.00');

      case 'objectType':
        return value.type;

      case 'objectValue':
        return (value.value || '').toLocaleString();

      case 'sq-m-to-acres':
        const acres = value * ACRES_PER_SQ_M;
        const formattedAcres = acres < 100 ? numeral(acres).format('0.00') : numeral(acres).format('0');
        return `${formattedAcres} (Acres)`;

      case 'sq-m-to-sq-f':
        return formatStat(value * SQ_FT_PER_SQ_M, { format: 'wholeNumber' });

      default:
        return value.toString();
    }
  } catch (error) {
    console.error('Invalid value for', value);
    return '';
  }
}

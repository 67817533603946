import React from 'react';
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link';

const PropertyLinks = ({ item, properties }) => (
  <div>
    {properties.map(({ canonicalName, name, address, hash, location, rescourId }) => {
      const useHash = window.location.hash || `#11/${location[1]}/${location[0]}`;
      return (
        <Link
          key={hash}
          style={{ display: 'block' }}
          to={`/research/map/${hash}|${rescourId}/${item._type}/${item.id}${useHash}`}>
          {canonicalName || name || address}
        </Link>
      );
    })}
  </div>
);

PropertyLinks.propTypes = {
  item: PropTypes.object.isRequired,
  properties: PropTypes.array.isRequired
};

export default PropertyLinks;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { GUTTER_SIZE, LIST_WIDTH } from '../constants';
import styles from './module.css';

const Detail = ({ children, classes, containerWidth }) => {
  const rightPosition = (containerWidth < 900) ? GUTTER_SIZE : LIST_WIDTH + (GUTTER_SIZE * 2);
  return (
    <div
      className={classes.detailsContainer}
      style={{
        bottom: GUTTER_SIZE,
        top: GUTTER_SIZE,
        left: GUTTER_SIZE,
        right: rightPosition,
      }}>
      {children}
    </div>
  );
};

Detail.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  containerWidth: PropTypes.number.isRequired,
};

export default withStyles(styles)(Detail);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { savedSearchDeleted, selectSavedSearch, savedSearchUpdated } from '../../spotlight/actions';
import { read } from '../../services/api';
import Can, { ability } from '../../auth/ability';
import AccountDefault from '../common/AccountDefault';
import AccountLoading from '../common/AccountLoading';
import SavedSearchTable from './SavedSearchTable';
import styles from './module.css';

const SavedSearchContainer = ({
  router,
  savedSearchDeleted,
  savedSearchUpdated,
  selectSavedSearch 
}) => {
  const [hasSearchesLoaded, setHasSearchesLoaded] = useState(false);
  const [savedSearches, setSavedSearches] = useState([]);

  const getSavedSearches = async (query) => {
    if (ability.cannot('use', '_app')) return new Promise((resolve) => resolve([]));
    const results = await read('savedSearches', null, query);
    return results.map((result) => ({ ...result, _type: 'savedSearch' }));
  };

  const loadSavedSearches = () => {
    getSavedSearches({ limit: 100, sort: '-updatedAt' })
      .then((results) => {
        if (results.length === 0) return setHasSearchesLoaded(true);
        setSavedSearches(results.map((result) => ({
          title: result.title,
          description: result.description,
          frequency: result.frequency,
          timestamp: result.updatedAt,
          value: result
        })));
      });
  };

  useEffect(() => {
    loadSavedSearches();
  }, []);

  return (
    <Can do="use" on="_app">
      {(hasSearchesLoaded) && (savedSearches.length === 0) && (
        <AccountDefault 
          accountType={'Searches'}
          buttonText={'Search'}
          router={router} />
      )}
      {(!hasSearchesLoaded) && (savedSearches.length === 0) && (
        <AccountLoading 
          accountType={'Searches'} />
      )}
      {(!hasSearchesLoaded) && (savedSearches.length !== 0) && (
        <SavedSearchTable
          savedSearchDeleted={savedSearchDeleted}
          savedSearchUpdated={savedSearchUpdated}
          searches={savedSearches}
          selectSavedSearch={selectSavedSearch} />
      )}
    </Can>
  );
};

SavedSearchContainer.propTypes = {
  router: PropTypes.object.isRequired,
  savedSearchDeleted: PropTypes.func,
  savedSearchUpdated: PropTypes.func,
  selectSavedSearch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  searches: state.searches,
});

export default connect(
  mapStateToProps,
  { savedSearchDeleted, savedSearchUpdated, selectSavedSearch }
)(withStyles(styles)(SavedSearchContainer));

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  SortableContainer as sortableContainer,
  SortableElement as sortableElement,
} from 'react-sortable-hoc';
import List from '@material-ui/core/List';
import { create, read } from '../services/api';
import store from '../store';
import { SNACKBAR_MAP_MESSAGE } from '../constants/actionTypes';
import LeaseExpirationsWidget from './LeaseExpirationsWidget';
import SaleOurDealsWidget from './SaleOurDealsWidget';
import LeaseOurDealsWidget from './LeaseOurDealsWidget';
import MyAlertsWidget from './MyAlertsWidget';
import MyListingsWidget from './MyListingsWidget';
import MySavedSearchWidget from './MySavedSearchesWidget';
import MySavedListsWidget from './MySavedListsWidget';
import { withStyles } from '@material-ui/core/styles';
import AddWidgets from './AddWidgets';
import MyBookmarksWidget from './MyBookmarksWidget';
import styles from './module.css';

const allWidgets = {
  lg: [
    'RECENT LEASES',
    'RECENT SALES',
    'UPCOMING EXPIRATIONS',
    'MY LISTINGS',
    'MY BOOKMARKED APPS',
  ],
  sm: [
    'MY SAVED SEARCHES',
    'MY SAVED LISTS',
    'MY ALERTS',
  ]
};

const Dashboard = ({ classes, currentUser, router, enableDatatype }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [layout, setLayout] = useState({
    lg: [...allWidgets.lg, 'ADD WIDGET LG'],
    sm: [...allWidgets.sm, 'ADD WIDGET SM']
  });

  const saveLayout = async (newLayout) => {
    setLayout(newLayout);
    await create('/widgets', newLayout);
  };

  useEffect(() => {
    (async () => {
      const savedLayout = await read('/widgets');
      if (savedLayout) {
        setLayout(savedLayout);
      }
      setHasLoaded(true);
    })();
  }, []);

  function arrayMove(array, fromIndex, toIndex) {
    const result = [...array];
    const [removed] = result.splice(fromIndex, 1);
    result.splice(toIndex, 0, removed);
    return result;
  }

  const handleSortLarge = ({ oldIndex, newIndex }) => {
    const updatedLarge = arrayMove(layout.lg, oldIndex, newIndex);
    saveLayout({ lg: updatedLarge, sm: layout.sm });
  };

  const handleSortSmall = ({ oldIndex, newIndex }) => {
    const updatedSmall = arrayMove(layout.sm, oldIndex, newIndex);
    saveLayout({ lg: layout.lg, sm: updatedSmall });
  };

  const handleAdd = (column) => (value) => {
    const newLayout = {
      ...layout,
      [column]: [value, ...layout[column]]
    };

    saveLayout(newLayout);
  };

  const handleRemove = (value) => () => {
    saveLayout({
      lg: layout.lg.filter((item) => item !== value),
      sm: layout.sm.filter((item) => item !== value),
    });
  };
  
  const getKey = (value, listingKey) => {
    switch (value) {
      case 'RECENT LEASES':
      case 'UPCOMING EXPIRATIONS':
        return 'lease';
      case 'RECENT SALES':
        return 'sale';
      case 'MY LISTINGS':
        return listingKey ? listingKey : 'availability';
      default:
        return value;
    }
  };  

  const handleTableViewClick = (({ value, key, data = {} }) => {
    const typeKey = getKey(value, key);
    router.push({
      pathname: '/research/table',
      hash: '#5/32.306/-93.400',
      search: `?currentListView=${typeKey}`,
      query: { currentListView: typeKey, ...data }
    });
    store.dispatch(enableDatatype(typeKey));
  });
  
  const handleMapViewClick = (({ value, key }) => {
    const typeKey = getKey(value, key);
    router.push({ 
      pathname: '/research', 
      hash: '#5/32.306/-93.400', 
      search: `?currentListView=${typeKey}`, 
      query: { currentListView: typeKey } 
    });
    store.dispatch(enableDatatype(typeKey));
    store.dispatch({
      type: SNACKBAR_MAP_MESSAGE,
      text: value, secondaryText: 'Search Loaded', textType: 'success' });
  });

  const shouldCancelStart = (event) => {
    return !event.target.closest('.dashboard-widget-drag-handle');
  };

  const availableWidgets = {
    lg: allWidgets.lg.filter((w) => !layout.lg.includes(w)),
    sm: allWidgets.sm.filter((w) => !layout.sm.includes(w)),
  };

  const renderWidget = (value) => {
    switch (value) {
      case 'MY ALERTS':
        return <MyAlertsWidget onRemove={handleRemove(value)} />;

      case 'MY SAVED SEARCHES':
        return <MySavedSearchWidget onRemove={handleRemove(value)} />;

      case 'MY SAVED LISTS':
        return <MySavedListsWidget onRemove={handleRemove(value)} />;

      case 'MY BOOKMARKED APPS':
        return (
          <MyBookmarksWidget
            enableDatatype={enableDatatype}
            title={value}
            onRemove={handleRemove(value)}
            onMapViewClick={() => { handleMapViewClick({ value }); }}
            onTableViewClick={() => { handleTableViewClick({ value }); }}
            router={router} />
        );

      case 'RECENT LEASES':
        return (
          <LeaseOurDealsWidget
            currentUser={currentUser}
            enableDatatype={enableDatatype}
            title={value}
            datatype={'lease'}
            onRemove={handleRemove(value)}
            onMapViewClick={handleMapViewClick}
            onTableViewClick={handleTableViewClick}
            router={router}
            widgetId={1} />
        );
      case 'RECENT SALES':
        return (
          <SaleOurDealsWidget
            currentUser={currentUser}
            enableDatatype={enableDatatype}
            title={value}
            datatype={'sale'}
            onRemove={handleRemove(value)}
            onMapViewClick={handleMapViewClick}
            onTableViewClick={handleTableViewClick}
            router={router}
            widgetId={0} />
        );

      case 'ADD WIDGET LG':
        if (availableWidgets.lg.length === 0) {
          return null;
        }

        return <AddWidgets availableWidgets={availableWidgets.lg} onAdd={handleAdd('lg')} />;

      case 'ADD WIDGET SM':
        if (availableWidgets.sm.length === 0) {
          return null;
        }

        return <AddWidgets availableWidgets={availableWidgets.sm} onAdd={handleAdd('sm')} small />;

      case 'UPCOMING EXPIRATIONS':
        return (
          <LeaseExpirationsWidget
            enableDatatype={enableDatatype}
            title={value}
            onRemove={handleRemove(value)}
            onMapViewClick={() => { handleMapViewClick({ value }); }}
            onTableViewClick={() => { handleTableViewClick({ value }); }}
            router={router}
            widgetId={2} />
        );
      case 'MY LISTINGS':
        return (
          <MyListingsWidget
            enableDatatype={enableDatatype}
            title={value}
            onRemove={handleRemove(value)}
            onMapViewClick={handleMapViewClick}
            onTableViewClick={handleTableViewClick}
            router={router}
            widgetId={3} />
        );
      default:
        return null;
    }
  };

  const SortableItem = sortableElement(({ value }) => (
    <div>
      {renderWidget(value)}
    </div>
  ));

  const SortableList = sortableContainer(({ items }) => {
    if (!items) {
      return null;
    }
    return (
      <List>
        {items.map((value, index) => {
          return <SortableItem key={`item-${value}`} index={index} value={value} />;
        })}
      </List>
    );
  });

  return (
    <div className={classes.wrapper}>
      {hasLoaded && (
        <div className={classes.draggableWrapper}>
          <div className={classes.largeColumn}>
            <SortableList
              items={layout.lg}
              onSortEnd={handleSortLarge}
              lockAxis="y"
              shouldCancelStart={shouldCancelStart} />
          </div>
          <div className={classes.smallColumn}>
            <SortableList
              items={layout.sm}
              onSortEnd={handleSortSmall}
              lockAxis="y"
              shouldCancelStart={shouldCancelStart} />
          </div>
        </div>
      )}
    </div>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  enableDatatype: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired
};

export default withStyles(styles)(Dashboard);

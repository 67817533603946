import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import AllIcon from '@material-ui/icons/Public';
import BrokerIcon from '@material-ui/icons/ViewList';
import DropDownIcon from '@material-ui/icons/ArrowDropDown';
import FavoriteIcon from '@material-ui/icons/Star';
import SettingsIcon from '@material-ui/icons/Settings';

const Filters = ({
  datatypes,
  filter,
  onFilterChange,
  toggleMobileDrawer,
  toggleSettingsMenu,
  visibleDatatypes,
}) => {
  const [filterMenuAnchor, setFilterMenuAnchor] = useState();

  const handleFilterMenuClick = (event) => {
    setFilterMenuAnchor(event.currentTarget);
  };
  const handleFilterMenuClose = () => {
    setFilterMenuAnchor(null);
  };
  const handleFilterChange = (value) => () => {
    onFilterChange(value);
    handleFilterMenuClose();
  };

  const filterOptions = [
    { Icon: AllIcon, label: 'All', value: undefined },
    { Icon: FavoriteIcon, label: 'Favorites', value: 'favorite' },
  ];
  Object.keys(datatypes)
    .filter((key) => visibleDatatypes.indexOf(key) > -1)
    .forEach((key) => {
      const { Icon, plural } = datatypes[key];
      filterOptions.push({ Icon, label: plural, value: key });
    });

  const selectedFilterOption = filterOptions.find(({ value }) => value === filter) || {};
  return (
    <Toolbar style={{ backgroundColor: '#e8e8e8', paddingRight: 8 }}>
      <Button
        onClick={handleFilterMenuClick}
        size="large"
        startIcon={selectedFilterOption.Icon ? <selectedFilterOption.Icon /> : null}
        endIcon={<DropDownIcon style={{ marginLeft: 16 }} />}>
        {selectedFilterOption.label || 'Filter'}
      </Button>
      <Menu
        anchorEl={filterMenuAnchor}
        keepMounted
        open={Boolean(filterMenuAnchor)}
        onClose={handleFilterMenuClose}>
        {filterOptions.map(({ Icon, label, value }) => (
          <MenuItem key={label} onClick={handleFilterChange(value)}>
            <ListItemIcon style={{ minWidth: 40 }}><Icon /></ListItemIcon> {label}
          </MenuItem>
        ))}
      </Menu>

      <div style={{ flex: 1 }} />
    
      <IconButton onClick={toggleSettingsMenu}>
        <SettingsIcon />
      </IconButton>
      <Hidden mdUp>
        <IconButton onClick={toggleMobileDrawer}>
          <BrokerIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

Filters.propTypes = {
  datatypes: PropTypes.object.isRequired,
  filter: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  toggleMobileDrawer: PropTypes.func.isRequired,
  toggleSettingsMenu: PropTypes.func.isRequired,
  visibleDatatypes: PropTypes.array
};

export default Filters;

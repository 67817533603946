import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field, FieldArray } from 'formik';
import { Link as RouterLink } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import FormField from '../common/FormField';
import getLocationTitles from '../common/getLocationTitles';
import AddLocation from '../feed/AddLocation';
import loginsplash from '../assets/loginsplash.jpg';
import PropertyTypesCheckboxGroup from '../common/PropertyTypesCheckboxGroup';
import styles from './module.css';

const Onboarding = ({
  addLocation,
  classes,
  completeOnboarding,
  currentStep,
  currentUser,
  initialValues,
  isComplete,
  nextStep,
  previousStep,
  updateCurrentUser
}) => {
  const handleNext = () => nextStep();
  const handlePrev = () => {
    if (currentStep > 0) previousStep();
  };

  const onSubmit = (values, { setSubmitting }) => {
    const { inferDefaultLocation, ...submitValues } = values;
    
    // if inferring the default location from the follows, set defaultLocation
    if (inferDefaultLocation && currentUser.follows.length) {
      submitValues.defaultLocation = {
        address: currentUser.follows[0].address,
        location: currentUser.follows[0].location
      };
    }

    // submit the form
    setSubmitting(true);
    return updateCurrentUser(submitValues).then(() => {
      return completeOnboarding();
    });
  };

  const defaultLocationIsEmpty = !(currentUser.defaultLocation && currentUser.defaultLocation.location.length);
  const followingTally = (currentUser.follows || []).length;

  const steps = [
    {
      title: 'Welcome to Colliers Discover',
      content: (
        <p className={classes.stepText}>
          Colliers Discover provides a tailored experience based on your unique role and preferences.
          We have just a few questions for you before you get started.
        </p>
      )
    },
    {
      title: 'Introduce Yourself',
      content: (
        <React.Fragment>
          <p className={classes.stepText}>
            {`
              Colliers Discover allows you to share interesting articles,
              new listings or the latest developments with your colleagues.
              We'll include your name to make sure they know who's keeping them informed.
            `}
          </p>
          <Field
            autoFocus
            label="Name"
            component={FormField}
            name="name"
            placeholder="Enter your name..." />
        </React.Fragment>
      ),
      isValid: (formik) => !!formik.values.name,
    },
    {
      title: 'Select your Property Types',
      content: (
        <React.Fragment>
          <p className={classes.stepText}>
            {`
              Whether you're a tenant rep, or an investment sales analyst,
              Colliers Discover cuts down on the noise by providing only
              the data you care about.
            `}
          </p>
          <div className={classes.propertyTypes}>
            <FieldArray name="propertyTypes" component={PropertyTypesCheckboxGroup} />
          </div>
        </React.Fragment>
      ),
      isValid: (formik) => formik.values.propertyTypes && formik.values.propertyTypes.length > 0,
    },
    {
      title: 'Follow your Market',
      content: (
        <React.Fragment>
          <p className={classes.stepText}>
            {`
              Almost there! To get started with Insights, let's start by following your office.
              Start typing and select the correct address from the list.
            `}
          </p>
          <div style={{ margin: '0 -8px', paddingBottom: '5px' }}>
            <AddLocation onChange={addLocation} />
          </div>
          {followingTally > 0 &&
            <List style={{ maxHeight: '200px', overflowY: 'scroll', paddingTop: 0 }}>
              {currentUser.follows.map((follow) => {
                const { primary, secondary } = getLocationTitles(follow);
                return (
                  <ListItem
                    key={follow.id || follow.address}
                    style={{ border: '1px solid #EEE' }}>
                    <ListItemText primary={primary} secondary={secondary} />
                  </ListItem>
                );
              })}
            </List>
          }
          {followingTally > 0 && defaultLocationIsEmpty &&
            <div>
              <label className={classes.defaultLocationLine}>
                <Field name="inferDefaultLocation">
                  {({ field: { name, onChange, value } }) => (
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={value}
                          color="primary"
                          onChange={onChange}
                          name={name} />
                      )}
                      label={`Also set ${getLocationTitles(currentUser.follows[0]).primary} as default map location`} />
                  )}
                </Field>
              </label>
            </div>
          }
        </React.Fragment>
      ),
      isValid: () => followingTally > 0,
    },
    {
      title: 'Pick your Home Page',
      content: (
        <React.Fragment>
          <p className={classes.stepText} style={{ marginTop: 0 }}>
            Last one! When you login, which page would you like to see first?
          </p>
          <Field
            name="defaultLanding"
            component={({ field: { name, onChange, value } }) => {
              return (
                <RadioGroup
                  name={name}
                  onChange={onChange}
                  style={{ marginBottom: 20 }}
                  value={value}>
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="A dashboard of recent deals and insights from your market"
                    value="dashboard" />
                  <FormControlLabel
                    control={<Radio color="primary" />}
                    label="An interactive map of your market"
                    value="research" />
                </RadioGroup>
              );
            }} />
        </React.Fragment>
      ),
      isValid: (formik) => !!formik.values.defaultLanding,
    }
  ];

  return (
    <div className={classes.container} style={{ backgroundImage: `url(${loginsplash})` }}>
      <Paper elevation={3} className={classes.onboarding}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {(formik) => (
            <Form>
              {isComplete && (
                <div className={classes.complete}>
                  <Typography variant="h6">You’re all set to use Discover. Enjoy!</Typography>
                  <Typography variant="body2" className={classes.stepText}>
                    If you have any questions, contact the support desk by{' '}
                    <Link href="https://colliers.service-now.com/colliersportal" target={'_blank'}>clicking here</Link>.
                  </Typography>
                  <Button
                    color="primary"
                    component={RouterLink}
                    style={{ marginTop: 20 }}
                    to="/"
                    variant="contained">
                    Get Started
                  </Button>
                </div>
              )}

              {!isComplete && (
                <Stepper
                  activeStep={currentStep}
                  connector={<StepConnector classes={{ lineVertical: classes.connector }} />}
                  orientation="vertical">
                  {steps.map((step, i) => {
                    const isFinalStep = i === steps.length - 1;
                    return (
                      <Step key={i}>
                        <StepLabel classes={{ label: classes.stepTitle }}>{step.title}</StepLabel>
                        <StepContent>
                          {step.content}
                          <div style={{ margin: '10px 0' }}>
                            <Button
                              color="primary"
                              disabled={step.isValid ? !step.isValid(formik) : false}
                              onClick={isFinalStep ? formik.submitForm : handleNext}
                              size="large"
                              style={{ marginRight: 10 }}
                              variant="contained">
                              {isFinalStep ? 'Finish' : 'Next'}
                            </Button>
                            {i > 0 && (
                              <Button
                                onClick={handlePrev}
                                size="large">
                                Back
                              </Button>
                            )}
                          </div>
                        </StepContent>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
            </Form>
          )}
        </Formik>
      </Paper>
    </div>
  );
};
Onboarding.propTypes = {
  addLocation: PropTypes.func.isRequired,
  classes: PropTypes.object,
  completeOnboarding: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  isComplete: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  previousStep: PropTypes.func.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
};

export default withStyles(styles)(Onboarding);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import AuthenticatedImage from '../../common/AuthenticatedImage';
import ProblemReporter from '../problemReporter/ProblemReporter';
import Loader from '../Loader';
import Header from './Header';

function getStreetview(property) {
  const [lng, lat] = property.location;
  const params = {
    size: '640x250',
    location: `${lat},${lng}`,
    heading: '151.78',
    pitch: '-0.76',
  };
  const query = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
  return `${process.env.REACT_APP_API_URL}/maps/api/streetview?${query}`;
}

export class PropertyDetail extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    currentUser: PropTypes.object,
    fetchError: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    isNested: PropTypes.bool,
    navigate: PropTypes.func.isRequired,
    navigateFetchError: PropTypes.func.isRequired,
    problemItem: PropTypes.object,
    property: PropTypes.object,
    showProblemReporter: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { notificationMessage: '', notificationOpen: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchError } = nextProps;
    if (!isEmpty(fetchError)) {
      this.props.navigateFetchError();
    }
  }

  handleNotificationClose = () => {
    this.setState({ notificationOpen: false, notificationMessage: '' });
  };

  render() {
    const {
      children,
      currentUser,
      isFetching,
      problemItem,
      property } = this.props;

    if (isFetching) return <Loader />;

    if (isEmpty(property)) return <div />;

    return (
      <Paper
        elevation={1}
        style={{
          alignItems: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}>
        <AuthenticatedImage url={getStreetview(property)}>
          <Header property={property} {...this.props} />
        </AuthenticatedImage>
        {!currentUser.isGuest && (<ProblemReporter problemItem={problemItem} />)}
        {React.cloneElement(children, { property, ...this.props })}
      </Paper>
    );
  }
}

function mapStateToProps({ research: { propertyDetail } }) {
  return { fetchError: propertyDetail.fetchError };
}
export default connect(mapStateToProps)(PropertyDetail);

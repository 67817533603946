import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import DateField from './DateField';

class DateRangeInput extends React.Component {
  static propTypes = {
    max: PropTypes.number,
    maxValue: PropTypes.number,
    min: PropTypes.number,
    minValue: PropTypes.number,
    onFinished: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      endDate: null,
      startDate: null,
      focusedInput: null,
      touched: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if was not in "reset" state and has been reset...
    if ((this.props.min !== this.props.minValue || this.props.max !== this.props.maxValue)
      && (nextProps.min === nextProps.minValue && nextProps.max === nextProps.maxValue)) {
      this.setState({
        startDate: null,
        endDate: null,
        touched: false
      });

    // else if was in "reset" state and now receiving new props, must be initial props (unless has been touched)
    } else if ((this.props.min === this.props.minValue && this.props.max === this.props.maxValue)
      && (nextProps.min !== nextProps.minValue || nextProps.max !== nextProps.maxValue)
      && !this.state.touched) {
      this.setState({
        startDate: nextProps.minValue,
        endDate: nextProps.maxValue,
        touched: !(nextProps.minValue === nextProps.min && nextProps.maxValue === nextProps.max),
      });
    }
  }

  updateSearch = () => {
    const { startDate, endDate } = this.state;
    const { min, max } = this.props;
  
    // if neither value set, use default props values
    const start = startDate ? moment(startDate).valueOf() : min;
    const end = endDate ? moment(endDate).valueOf() : max;
  
    this.props.onFinished({
      min: start,
      max: end
    });
  };

  // on date change, if new date is not null and other bound date is null, set other bound date to default (if there is one)
  onStartDateChange = (startDate) => {
    if (startDate !== this.state.startDate || !this.state.touched) { 
      this.setState({
        startDate,
        endDate: this.state.endDate || (startDate && this.props.max) || null,
        touched: true
      }, this.updateSearch);
    }
  };

  onEndDateChange = (endDate) => {
    if (endDate !== this.state.endDate || !this.state.touched) {
      this.setState({
        startDate: this.state.startDate || (endDate && this.props.min) || null,
        endDate,
        touched: true
      }, this.updateSearch);
    }
  };

  render() {
    const enforceBoundProps = {};
    /*
    // TODO: uncomment this if actually want to enforce bounds (previously min and max
    // were just used for initial values, so that enforceBounds is not true by default)
    if (this.props.enforceBounds) {
      if (this.props.min) {
        enforceBoundProps.minDate = this.props.min;
      }
      if (this.props.max) {
        enforceBoundProps.maxDate = this.props.max;
      }
    }
    */
    return (
      <span
        style={{
          alignItems: 'center',
          display: 'flex',
          backgroundColor: '#fff',
          border: '1px solid #cacccd',
          padding: '0 10px',
        }}>
        <DateField
          name="startDate"
          initialFocusedDate={this.props.min}
          onChange={this.onStartDateChange}
          value={this.state.startDate}
          {...enforceBoundProps} />
        <ArrowIcon style={{ width: '16px', height: '16px', margin: '16px 8px' }} />
        <DateField
          name="endDate"
          initialFocusedDate={this.props.max}
          onChange={this.onEndDateChange}
          value={this.state.endDate}
          {...enforceBoundProps} />
      </span>
    );
  }
}

export default DateRangeInput;

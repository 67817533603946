import React from 'react';
import { Route, IndexRoute } from 'react-router';
import TimDetailContainer from './Container';
import Info from './Info';

export default (
  <Route path="tim(/:timId)" component={TimDetailContainer}>
    <IndexRoute component={Info} />
  </Route>
);

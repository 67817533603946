import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import NotificationMessage from '../../common/NotificationMessage';
import ProblemReporter from '../problemReporter/ProblemReporter';
import Loader from '../Loader';
import Header from './Header';

export class TimDetail extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    fetchError: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    problemItem: PropTypes.object,
    showProblemReporter: PropTypes.func.isRequired,
    tim: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { notificationMessage: '', notificationOpen: false };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { fetchError } = nextProps;
    if (isEmpty(fetchError)) {
      this.setState({ notificationMessage: '', notificationOpen: false });
    } else {
      this.setState({
        notificationMessage: <NotificationMessage text={fetchError.message} type={'error'} />,
        notificationOpen: true });
    }
  }

  handleNotificationClose = () => {
    this.setState({ notificationOpen: false, notificationMessage: '' });
  };

  render() {
    const {
      children,
      isFetching,
      problemItem,
      tim } = this.props;

    if (this.state.notificationOpen) {
      return (
        <Snackbar
          autoHideDuration={4000}
          message={this.state.notificationMessage}
          onRequestClose={this.handleNotificationClose}
          open={this.state.notificationOpen} />
      );
    }

    if (isFetching) return <Loader />;
    if (this.state.notificationOpen) {
      return (
        <Snackbar
          autoHideDuration={4000}
          message={this.state.notificationMessage}
          onRequestClose={this.handleNotificationClose}
          open={this.state.notificationOpen} />
      );
    }

    if (isEmpty(tim)) return <div />;
    return (
      <Paper
        elevation={1}
        style={{
          alignItems: 'stretch',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}>
        <Header tim={tim} {...this.props} /> 
        <ProblemReporter problemItem={problemItem} />
        {React.cloneElement(children, { tim, ...this.props })}
      </Paper>
    );
  }
}

function mapStateToProps({ auth: { currentUser }, research: { timDetail: { fetchError } } }) {
  return {
    currentUser,
    fetchError
  };
}
export default connect(mapStateToProps)(TimDetail);

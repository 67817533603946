import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const InputFilter = styled('div')({
  borderRadius: 0,
  display: 'flex'
});

const Text = ({ onFilterChange, title, setFilter, getSelectedFilters, filterType }) => {
  const savedSelections = getSelectedFilters();
  const filter = savedSelections && savedSelections[title] ? savedSelections[title] : [];
  const [value, setValue] = useState(filter);

  const handleChange = (ev) => {
    setValue(ev?.target?.value);
  };

  const updateFilter = () => {
    if (onFilterChange) {
      const filter = (val) => {
        const valueTransformed = value?.toString().toLowerCase();
        if (!value) {
          return true;
        }

        if (val?.toLowerCase()?.includes(valueTransformed)) {
          return true;
        }

        return false;
      };

      filter.isDirty = !!value;

      onFilterChange(filter);
      setFilter(title, value, filterType);
    }
  };

  const handleBlur = () => {
    updateFilter(value);
  };

  const handleKeyDown = (ev) => {
    if (ev?.key === 'Enter') {
      updateFilter(value);
    }
  };

  return (
    <section>
      <div className="sk-panel" style={{ padding: '8px 24px' }}>
        <div className="sk-panel__header">
          {title}
        </div>
        <div className="sk-panel__content">
          <InputFilter className="sk-input-filter">
            <div className="sk-input-filter__icon" /> 
            <input
              className="sk-input-filter__text"
              onBlur={handleBlur}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              placeholder="Search"
              value={value}
              type="text" />
          </InputFilter>
        </div>
      </div>
    </section>
  );
};

Text.propTypes = {
  filterType:  PropTypes.string,
  getSelectedFilters: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  setFilter: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default Text;

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import styles from './module.css';

const MyListingsButtonGroup = ({ classes, onTabChange, tabValue }) => {
  const handleTabChange = (newTabValue) => {
    onTabChange(newTabValue);
  };

  return (
    <div className={classes.ourDealsFilter}>
      <Tabs 
        className={classes.ourDealsTabs} 
        value={tabValue} 
        TabIndicatorProps={{ hidden: true }}>
        <Tab 
          label={<Typography className={classes.ourDealsTabTitle}>For Lease</Typography>}
          onClick={() => { handleTabChange(0); }}
          color={tabValue === 0 ? 'white' : '#25408f'}
          style={
            tabValue === 0
              ? { backgroundColor: '#25408f', color:'white' } : { backgroundColor: 'white', color: 'black' }} />
        <Tab 
          label={<Typography className={classes.accountSubheaderTitle}>For Sale</Typography>} 
          onClick={() => { handleTabChange(1); }}
          color={tabValue === 1 ? 'white' : 'black'}
          style={
            tabValue === 1 
              ? { backgroundColor: '#25408f', color:'white' } : { backgroundColor: 'white', color: 'black' }} />
      </Tabs>
    </div>
  );
};

MyListingsButtonGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  onTabChange: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired
};

export default withStyles(styles)(MyListingsButtonGroup); 

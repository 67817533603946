import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import GridOnIcon from '@material-ui/icons/GridOn';
import MapIcon from '@material-ui/icons/Map';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import SortIcon from '@material-ui/icons/Sort';
import { getParcelPath } from '../common/getParcelPath';
import { read } from '../services/api';
import store from '../store';
import { enableLeases } from '../research/searches/actions';
import OurDealsFilter from './common/OurDealsFilter';
import styles from './module.css';

const columns = [
  { 
    id: 'tenant', 
    label: 'Tenant', 
    align: 'left',
    sortType: 'string'
  },
  { 
    id: 'leasedArea', 
    label: 'Leased Area SF', 
    minWidth: 100,
    align: 'left',
    sortType: 'number'
  },
  {
    id: 'startRate',
    label: 'Start Rate',
    minWidth: 170,
    align: 'left',
    sortType: 'number'
  },
  {
    id: 'dateSigned',
    label: 'Date Signed',
    minWidth: 170,
    align: 'left',
    sortType: 'date'
  },
  {
    id: 'endDate',
    label: 'End Date',
    minWidth: 170,
    align: 'left',
    sortType: 'date'
  },
  {
    id: 'leaseType',
    label: 'Lease Type',
    minWidth: 170,
    align: 'left',
    sortType: 'string'
  },
  {
    id: 'created',
    label: 'Created',
    minWidth: 170,
    align: 'left',
    sortType: 'date'
  },
  {
    id: 'source',
    label: 'Source',
    minWidth: 170,
    align: 'left',
    sortType: 'string'
  },
];
    
function createData(
  tenant = '', 
  leasedArea = '', 
  startRate = '', 
  dateSigned = '',
  endDate = '', 
  leaseType = '', 
  created = '', 
  source = '',
  id = '',
  property = []
) {
  const formatDate = (date) => moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : '-';
  const formatDollar = (value) => value ? `$${parseFloat(value).toFixed(2)}` : '-';
  const formatLeasedArea = (value) => value ? `${value.toLocaleString()}` : '-';

  return { 
    tenant, 
    leasedArea: formatLeasedArea(leasedArea), 
    startRate: formatDollar(startRate), 
    dateSigned: formatDate(dateSigned), 
    endDate: formatDate(endDate), 
    leaseType, 
    created: formatDate(created), 
    source,
    id,
    property
  };
}

const LeaseOurDealsWidget = ({ 
  classes, 
  datatype,
  enableDatatype,
  onRemove, 
  onMapViewClick, 
  onTableViewClick, 
  router,
  title,
  widgetId
}) => {
  const [records, setRecords] = useState(parseInt(localStorage.getItem(`records_${widgetId}`)) || 10);
  const [rows, setRows] = useState([]);
  const [tabValue, setTabValue] = useState(() => {
    const storedTabValue = parseInt(localStorage.getItem(`tabValue_${widgetId}`), 10);
    return isNaN(storedTabValue) ? 2 : storedTabValue;
  });
  const [hasLoaded, setHasLoaded] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortColumn, setSortColumn] = useState({ id: 'created', order: 'desc' });
  const [showPagination, setShowPagination] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setHasLoaded(false);
      let results = [];

      if (tabValue === 0) {
        results = await read(`/leases?createdBy=${process.env.REACT_APP_OURDEALS_USER_ID}&filterDate=${records}`);
      } else if (tabValue === 1) {
        results = await read(`/leases?createdBy=null&filterDate=${records}`);
      } else {
        const ourDealsResults = await read(
          `/leases?createdBy=${process.env.REACT_APP_OURDEALS_USER_ID}&filterDate=${records}`
        );
        const nonOurDealsResults = await read(`/leases?createdBy=null&filterDate=${records}`);
        results = [...ourDealsResults, ...nonOurDealsResults];
      }

      const transformedResults = results.map((row) =>
        createData(
          row.tenant && row.tenant.name ? row.tenant.name : '-',
          row.area ? row.area : '',
          row.rentRate ? row.rentRate : '', 
          row.signedDate ? row.signedDate : '-', 
          row.expirationDate ? row.expirationDate : '-',
          row.leaseType ? row.leaseType : '-', 
          row.createdAt ? row.createdAt : '',
          row.sourcedBy ? row.sourcedBy : '-',
          row.id ? row.id : '',
          row.property ? row.property : []
        )
      );

      setRows(transformedResults);
      setHasLoaded(true);
      setShowPagination(transformedResults.length > 0);
    };

    fetchData();
  }, [tabValue, records]);

  const handleRecordChange = (event) => {
    setRecords(event.target.value);
    localStorage.setItem(`records_${widgetId}`, event.target.value.toString());
  };

  useEffect(() => {
    localStorage.setItem(`records_${widgetId}`, records.toString());
  }, [records]);
  
  useEffect(() => {
    const tabValueFromStorage = localStorage.getItem(`tabValue_${widgetId}`);
    if (tabValueFromStorage) {
      setTabValue(parseInt(tabValueFromStorage));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(`tabValue_${widgetId}`, tabValue.toString());
  }, [tabValue]);
    
  const sortedRows = [...rows].sort((a, b) => {
    const column = columns.find((col) => col.id === sortColumn.id);
    let valueA = a[column.id];
    let valueB = b[column.id];

    const extractNumber = (value) => {
      return parseFloat(value.replace(/[^\d.]/g, ''));
    };

    if (
      ['leasedArea', 'startRate']
        .includes(column.id)) {
      valueA = extractNumber(valueA);
      valueB = extractNumber(valueB);
    }

    let compareResult;

    switch (column.sortType) {
      case 'number':
        compareResult = sortColumn.order === 'asc' ? valueA - valueB : valueB - valueA;
        break;
      case 'date':
        compareResult = sortColumn.order === 'asc'
          ? new Date(valueA) - new Date(valueB)
          : new Date(valueB) - new Date(valueA);
        break;
      case 'string':
      default:
        compareResult = sortColumn.order === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
        break;
    }

    return compareResult;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortClick = (columnId) => {
    if (sortColumn.id === columnId) {
      const order = sortColumn.order === 'asc' ? 'desc' : 'asc';
      setSortColumn({ id: columnId, order });
    } else {
      setSortColumn({ id: columnId, order: 'asc' });
    }
  };
  const handleTabChange = (newTabValue) => {
    setTabValue(newTabValue);
    localStorage.setItem(`tabValue_${widgetId}`, newTabValue.toString());
  };

  const handleTableViewClick = () => {
    store.dispatch(enableLeases(datatype, rows));
    onTableViewClick({ value: title, key: datatype });
  };
  
  const handleMapViewClick = () => {
    store.dispatch(enableLeases(datatype, rows));
    onMapViewClick({ value: title, key: datatype });
  };

  const loadValue = async ({ value }) => {
    try {
      let record;

      if (rows && rows.length > 0) {
        record = rows.find((row) => (row.tenant === value));
      }
      const key = datatype;
      const pathname = await getParcelPath({
        address: record.property.address,
        slug: `${record.property.hash}|${record.property.rescourId}`,
        type: key
      });

      router.push({
        pathname,
        search: `?currentListView=${key}`,
        hash: `#18/${record.property.location[1]}/${record.property.location[0]}`,
        query: { currentListView: key }
      });
      store.dispatch(enableDatatype(key));
    } catch (error) {
      console.error(error);
    }
  };

  const renderTableCellContent = (column, value) => {
    if (column.id === 'tenant') {
      return (
        <Typography
          onClick={() => { loadValue({ value }); }}
          className={classes.loadValue}
          style={{ overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', height: '24px', width: '100%' }}>
          {value}
        </Typography>
      );
    }

    return column.format && typeof value === 'number'
      ? column.format(value)
      : value;
  };

  const dragHandleButton = 
    (
      <IconButton className={classes.dragHandle}>
        <DragHandleIcon className={'dashboard-widget-drag-handle'} />
      </IconButton>
    );

  const headerDropDown = (
    <div>
      <FormControl className={classes.dropDown}> 
        <Select
          name="records"
          value={records}
          onChange={handleRecordChange}>
          <MenuItem value={10}>Last 10 Days</MenuItem> 
          <MenuItem value={20}>Last 20 Days</MenuItem>
          <MenuItem value={30}>Last 30 Days</MenuItem>
        </Select> 
      </FormControl>
    </div>
  );

  const headerButtons = (
    <div className={classes.headerIcons}>
      <Tooltip title="GRID VIEW">
        <IconButton onClick={handleTableViewClick}>
          <GridOnIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="MAP VIEW">
        <IconButton onClick={handleMapViewClick}>
          <MapIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  return (
    <Card className={classes.card} elevation={5}>
      <div className={classes.tableHeader}>
        <CardHeader 
          action={dragHandleButton}
          title={title} 
          className={classes.title} />

        <OurDealsFilter
          onTabChange={handleTabChange}
          tabValue={tabValue} />

        {headerDropDown}
      
        {headerButtons}
      </div>

      {hasLoaded ? (<CardContent>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader className={classes.tableHeadRoot}>
              <TableHead className={classes.tableHead}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.sortType === 'number' ? 'right' : 'left'}
                      style={{ minWidth: 200, borderBottom: '2px solid #25408F' }}>
                      {column.label}
                      <IconButton onClick={() => { handleSortClick(column.id); }}>
                        <SortIcon 
                          style={{ transform: 
                        column.id === sortColumn.id  && sortColumn.order === 'asc' ? 'scaleY(-1)' : 'scaleY(1)' }} />
                      </IconButton>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: 'center', padding: '20px' }}>
                      <Typography variant="body1">
                        No data available for the selected time range.
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  sortedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow 
                          key={index}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell 
                                key={column.id} 
                                align={column.sortType === 'number' ? 'right' : 'left'} 
                                style={column.sortType === 'number' ?  { paddingRight: '64px' } : null}>
                                {renderTableCellContent(column, value)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={`${showPagination ? classes.tableFooter : classes.hidePagination}`}>
            {showPagination && (
              <TablePagination
                className={classes.tablePagination}
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={sortedRows.length}
                labelRowsPerPage={'Rows per Page:'}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage} />
            )}
            <Tooltip title="Remove Widget">
              <IconButton className={classes.removeButton} onClick={onRemove}> 
                <Delete />
              </IconButton>
            </Tooltip>
          </div>
        </Paper>
      </CardContent>) : (<div className={classes.spinner}><CircularProgress  /></div>)}
    </Card>
  );
};

LeaseOurDealsWidget.propTypes = {
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  datatype: PropTypes.string.isRequired,
  enableDatatype: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool,
  onMapViewClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onTableViewClick: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  widgetId: PropTypes.number.isRequired
};

export default withStyles(styles)(LeaseOurDealsWidget);

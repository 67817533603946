import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import DescriptionIcon from '@material-ui/icons/Description';
import FeedItem from './FeedItem';
import styles from './module.css';

export class FeedList extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    createFavorite: PropTypes.func.isRequired,
    favorites: PropTypes.array,
    hasCompleteList: PropTypes.bool,
    hideShareItem: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    onFeedItemToggle: PropTypes.func.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    removeFavorite: PropTypes.func.isRequired,
    scrollOverscan: PropTypes.number.isRequired,
    selectedItem: PropTypes.string,
    sendShareItem: PropTypes.func.isRequired,
    shareItem: PropTypes.string,
    showShareItem: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.container.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.container.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const { hasCompleteList, isFetching, onLoadMore, scrollOverscan } = this.props;
    const { scrollHeight, offsetHeight, scrollTop } = this.container;

    if (hasCompleteList) return;

    if (!isFetching && scrollHeight >= offsetHeight && scrollTop + offsetHeight >= scrollHeight - scrollOverscan) {
      onLoadMore();
    }
  };

  bindContainer = (el) => this.container = el;

  render() {
    const { classes, hasCompleteList, onFeedItemToggle, isFetching, items, selectedItem } = this.props;
    return (
      <div className={classes.list} ref={this.bindContainer}>
        {items.map((item) => (
          <FeedItem
            key={item.id}
            item={item}
            createFavorite={this.props.createFavorite}
            favorites={this.props.favorites}
            removeFavorite={this.props.removeFavorite}
            shareItem={this.props.shareItem}
            sendShareItem={this.props.sendShareItem}
            showShareItem={this.props.showShareItem}
            hideShareItem={this.props.hideShareItem}
            onToggle={onFeedItemToggle} />
        ))}
        {selectedItem && (
          <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 24 }}>
            <Button
              href="/feed"
              startIcon={<DescriptionIcon />}
              variant="contained">
              Show full feed
            </Button>
          </div>
        )}
        {!selectedItem && hasCompleteList && (
          <div style={{ textAlign: 'center' }}>No more items to load.</div>
        )}
        {isFetching && (
          <div
            className={classes.loading}
            style={{ height: items.length === 0 ? '100%' : 'auto' }}>
            <CircularProgress />
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(FeedList);

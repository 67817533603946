import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import CloseButton from '../../../common/CloseButton';
import datatypes from '../../../common/datatypes';
import SortableList from './SortableList';
import GridViews from './GridViews';
import styles from './ColumnsOrganizer.css';

function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

function arrayMoveImmutable(array, fromIndex, toIndex) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);
  return newArray;
}

export const ColumnsOrganizer = ({
  classes,
  columns,
  datatype,
  deleteGridView,
  gridViews,
  hideAllColumnsToggle,
  reorderColumns,
  selectGridView,
  selectedGridView,
  saveGridView,
  showAllColumnsToggle,
  toggleColumn,
  updateGridView,
  previousPath,
  router,
}) => {
  const enabledColumns = (columns || []).filter((c) => c.isEnabled);

  const close = () =>  {
    if (enabledColumns.length === 0) {
      return false;
    }

    return router.push(previousPath);
  };

  const handleSortEnd = (p) =>  {
    reorderColumns(datatype, arrayMoveImmutable(columns, p.oldIndex, p.newIndex));
  };

  const onSelect = (data) => { return selectGridView({ ...data, datatype }); };

  const onClickHideAll = () => {
    return hideAllColumnsToggle(columns, datatype);
  };

  const onClickShowAll = () => {
    return showAllColumnsToggle(columns, datatype);
  };

  return (
    <Dialog
      fullWidth={true}
      onClose={close}
      open={true}>
      <DialogTitle>
        <div className={classes.title}>
          <h3>Organize Columns</h3>
          <div className={classes.subHeader}>
            <GridViews
              columns={columns}
              customColumns={datatypes[datatype].columns}
              datatype={datatype}
              deleteGridView={deleteGridView}
              history={history}
              location={location}
              onSave={saveGridView}
              onSelect={onSelect}
              options={gridViews}
              saveGridView={saveGridView}
              selectedGridView={selectedGridView}
              updateGridView={updateGridView} />
          </div>
          <div className={classes.closeColumnsOrganizer}>
            <CloseButton disabled={enabledColumns.length === 0} onClick={close} />
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.sortableListColumns}>
          <SortableList
            datatype={datatype}
            items={columns}
            lockToContainerEdges={true}
            lockAxis="y"
            onSortEnd={handleSortEnd}
            toggleColumn={toggleColumn}
            useDragHandle={true} />
        </div>
        <div className={classes.hideAndShowButtons}>
          <Button className={classes.hideAllButtons} onClick={onClickHideAll}>Hide All</Button>
          <Button className={classes.showAllButtons} onClick={onClickShowAll}>Show All</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ColumnsOrganizer.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.array.isRequired,
  datatype: PropTypes.string.isRequired,
  deleteGridView: PropTypes.func.isRequired,
  gridViews: PropTypes.array.isRequired,
  hideAllColumnsToggle: PropTypes.func.isRequired,
  previousPath: PropTypes.string.isRequired,
  reorderColumns: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  saveGridView: PropTypes.func.isRequired,
  selectGridView: PropTypes.func.isRequired,
  selectedGridView: PropTypes.object,
  showAllColumnsToggle: PropTypes.func.isRequired,
  toggleColumn: PropTypes.func.isRequired,
  updateGridView: PropTypes.func.isRequired,
};

export default withStyles(styles)(ColumnsOrganizer);

import {
  FacetAccessor,
  TermQuery
} from 'searchkit-react16';
import map from 'lodash/map';

class BooleanAccessor extends FacetAccessor {
  getRawBuckets() {
    return super.getRawBuckets().map((bucket) => ({ ...bucket, label: (bucket.key === 0) ? 'No' : 'Yes' }));
  }

  getBuckets() {
    const buckets = super.getBuckets();
    const value = this.state.getValue();
    return map(buckets, (bucket) => {
      if (value.includes(bucket.key)) bucket.selected = true;
      return bucket;
    });
  }

  buildSharedQuery(query) {
    const boolBuilder = this.getBoolBuilder();
    const filters = this.state.getValue();
    const filterTerms = filters.map((filter) => this.fieldContext.wrapFilter(
      new TermQuery(this.key, (filter === '1')))
    );
    const selectedFilters = filters.map((filter) => {
      return {
        name: this.options.title || this.translate(this.key),
        value: this.translate(filter),
        id: this.options.id,
        remove: () => { return this.state = this.state.remove(filter); }
      };
    });
    if (filterTerms.length > 0) {
      query = query.addFilter(this.uuid, boolBuilder(filterTerms)).addSelectedFilters(selectedFilters);
    }
    return query;
  }
}

export default BooleanAccessor;
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import { HIDDEN_GUEST_COLUMNS } from '../../common/datatypes/constants';
import datatypes from '../../common/datatypes';
import Actions from './listItem/Actions';
import * as listContent from './listItem/content';
import getTitles from './listItem/getTitles';
import styles from './module.css';

const useStyles = makeStyles(styles);
const ListItem = ({
  active,
  currentListView,
  currentResults,
  currentUser,
  item,
  nearbyDistance,
  property,
  selectedResults,
  showProblemReporter,
  toggleSelectedItem,
  ...props
}) => {
  const classes = useStyles();
  const ref = useRef();

  useEffect(() => {
    if (active) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [active, item, ref]);
  const { key, stats } = datatypes[item._type];
  const Info = listContent[key];
  const isExpandable = !!Info.ExpandedCardContent; item.nearbyDistance = nearbyDistance;
  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);
  const selectedComp = Object.assign(
    {}, { _type: currentListView, _id: item.id, _source: item }
  );
  const result = currentResults && currentResults.find((result) => result._id === item.id) || selectedComp;
  const onToggle = () => setExpanded(isExpandable ? !expanded : false);
  const stopPropagation = (e) => e.stopPropagation();
  const selectedIds = selectedResults && new Set(selectedResults.map((r) => r._id));
  const isSelected = (result) => {
    if (!result || !selectedIds) return false;
    return selectedIds.has(result._id);
  };

  // filter stats for guests
  const filteredStats = stats.filter(({ key }) => {
    return !(currentUser.isGuest && HIDDEN_GUEST_COLUMNS.includes(key));
  });
  
  const handleToggleSelect = () => {
    if (result) {
      const newSelectedState = !isSelected(result);
      toggleSelectedItem(result, newSelectedState, result._type);
    } else {
      setChecked(!checked);
    }
  };

  return (
    <Card className={classes.card} ref={ref}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ListItemIcon
          className={classes.checkBox}
          style={{ marginTop: 12 }}>
          <Checkbox
            edge="start"
            checked={result ? isSelected(result) : checked}
            color="default"
            onChange={handleToggleSelect}
            onClick={stopPropagation} />
        </ListItemIcon>
        <CardHeader
          {...getTitles(
            item, 
            property, 
            currentUser, 
            currentListView,
            result,
            props
          )}
          className={classes.cardHeader}
          classes={{
            title: classes.cardTitle,
            subheader: classes.cardSubtitle,
          }}
          onClick={onToggle} />
      </div>
      <CardContent className={classes.cardContentListItem}>
        <div onClick={onToggle}>
          <Info.CardContent stats={filteredStats} item={item} />
        </div>
        {isExpandable && (
          <Collapse in={expanded} style={{ marginTop: 16 }}>
            {expanded && <Info.ExpandedCardContent stats={filteredStats} item={item} />}
          </Collapse>
        )}
      </CardContent>
      <Divider />
      <CardActions className={classes.cardActions}>
        <Actions
          expand={onToggle}
          expanded={expanded}
          isExpandable={isExpandable}
          item={item}
          showProblemReporter={showProblemReporter} />
      </CardActions>
    </Card>
  );
};

ListItem.propTypes = {
  active: PropTypes.bool,
  currentListView: PropTypes.string.isRequired,
  currentResults: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  nearbyDistance: PropTypes.string,
  property: PropTypes.object.isRequired,
  selectedResults: PropTypes.array.isRequired,
  showProblemReporter: PropTypes.func.isRequired,
  toggleSelectedItem: PropTypes.func.isRequired,
};

export default ListItem;

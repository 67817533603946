import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import TextTruncate from 'react-text-truncate';
import { makeStyles } from '@material-ui/core/styles';
import Stat from '../../../common/stats/Stat';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const PrimaryText = ({ property }) => {
  const classes = useStyles();
  const text = get(property, 'name', get(property, 'address', '\u2013'));
  return (
    <div className={classes.primaryText}>
      <TextTruncate
        text={text}
        truncateText="…" />
    </div>
  );
};

PrimaryText.propTypes = {
  property: PropTypes.object
};

export const SecondaryText = ({ leasingRepresentatives, property }) => {
  const classes = useStyles();
  const text = get(property, 'address', '\u2013');
  const leasingRep = get(find(leasingRepresentatives, { 'type': 'Company' }), 'name', undefined)
    ? get(find(leasingRepresentatives, { 'type': 'Company' }), 'name', undefined)
    : get(find(leasingRepresentatives, { 'type': 'Contact' }), 'name', '\u2013');
  return (
    <React.Fragment>
      <div className={classes.secondaryText}>
        <p>
          {text}
        </p>
      </div>
      <div>
        <Stat
          className={classes.stat}
          label="Listing Co."
          paddingValue="9px"
          value={leasingRep} />
      </div>
    </React.Fragment>
  );
};

SecondaryText.propTypes = {
  leasingRepresentatives: PropTypes.array,
  property: PropTypes.object
};

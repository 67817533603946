import React from 'react';
import PropTypes from 'prop-types';
import colliersLogo from '../assets/colliers-logo.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css';

export const renderOurDeals = ({ datatype, item }) => {
  const ourDealsDisplay = ['lease', 'sale'];
  if (ourDealsDisplay.includes(datatype)) {
    return item.createdBy && item.createdBy.id && item.createdBy.id === process.env.REACT_APP_OURDEALS_USER_ID;
  } 
  return false;
};

const OurDealsLogo = ({ classes, customClass }) => {
  return (
    <div className={customClass ? customClass : classes.ourDealsLogoDefault}>
      <Tooltip title="Our Deals" placement="bottom">
        <img width="100%" src={colliersLogo} alt="Colliers - OurDeals" />
      </Tooltip>
    </div>
  );
};

OurDealsLogo.propTypes = {
  classes: PropTypes.object.isRequired,
  customClass: PropTypes.string,
};

export default withStyles(styles)(OurDealsLogo);

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css.js';
import Sort from '@material-ui/icons/Sort';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableHead from '@material-ui/core/TableHead';
import MyListRow from './MyListRow';
import Typography from '@material-ui/core/Typography';

const MyListTable = ({ classes, enableListMode, listDeleted, listUpdated, lists, selectList }) => {
  const [myLists, setMyLists] = useState(lists); // Shallow Copy to Enable Updates & Deletes
  const [sortedLists, setSortedLists] = useState(lists); // Used for Sorting (Timestamp & Alphabetical titles)
  const [filteredList, setFilteredList] = useState(lists); // Used for Module Filtering
  const [sortTimeIcon, setSortTimeIcon] = useState(false);
  const [sortNameIcon, setSortNameIcon] = useState(false);
  const [checked, setChecked] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const datatypes = [
    { label: 'Property', key: 'property' },
    { label: 'Lease Comps', key: 'lease' },
    { label: 'Lease Listings', key: 'availability' },
    { label: 'Sale Comps', key: 'sale' },
    { label: 'Sale Listings', key: 'listing' },
    { label: 'Companies', key: 'company' },
    { label: 'Developments', key: 'development' }
  ];

  const handleChange = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (newChecked.length === 0) {
      setChecked([]);
      setFilteredList(myLists);
      return setSortedLists([...myLists]);
    }
    
    const selected = myLists.filter((list) => newChecked.includes(list.datatype));

    setChecked(newChecked);
    setFilteredList(selected);
    return setSortedLists(selected);
  };

  const onClose = (event) => {
    event.stopPropagation();
    setOpen(false);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onOpen = (event) => {
    event.stopPropagation();
    setOpen(!open);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const moduleFilter = () => {
    return (
      <ClickAwayListener 
        onClickAway={onClose}>
        <Popper 
          onClose={onClose} 
          aria-labelledby="simple-dialog-title"
          open={open}
          anchorEl={anchorEl}
          className={classes.filterPopper}>
          <Typography 
            className={classes.clear} 
            onClick={() => { 
              setChecked([]);
              setSortedLists([...myLists]); 
            }}>
            {'Clear'}
          </Typography>
          <FormGroup className={classes.formGroup}>
            {datatypes.map((option, index) => (
              <FormControlLabel
                key={index}
                control={(
                  <Checkbox
                    checked={checked.includes(option.key)}
                    onChange={handleChange(option.key)}
                    color="primary" />
                )}
                label={option.label} />
            ))}
          </FormGroup>
        </Popper>
      </ClickAwayListener>
    );
  };

  const sortField = (field) => {
    if (field === 'timestamp') {
      setSortNameIcon(false);
      setSortTimeIcon(sortTimeIcon === false);
      const chronological = [...filteredList].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
      return setSortedLists((sortTimeIcon === true) ? filteredList : chronological);
    } else if (field === 'name') {
      setSortNameIcon(sortNameIcon === false);
      setSortTimeIcon(false);
      const alphabetical = [...filteredList].sort((a, b) => a.title.localeCompare(b.title));
      return setSortedLists((sortNameIcon === true) ? filteredList : alphabetical);
    }
  };
  const sortName = () => sortField('name');
  const sortTimestamp = () => sortField('timestamp');

  /* useMemo updates affected list deletes & updates to not cause full re-render*/
  const onDeleteList = useMemo(() => (removedList) => {
    const updatedSortedLists = sortedLists.filter((list) => list.value.id !== removedList.id);
    const updatedOriginalList = myLists.filter((list) => list.value.id !== removedList.id);

    setMyLists(updatedOriginalList);
    setFilteredList(updatedSortedLists);
    setSortedLists(updatedSortedLists);
  });
  const onUpdateList = useMemo(() => (title, description, value) => {
    const updatedSortedLists = sortedLists.filter((list) => list.value.id !== value.id);
    updatedSortedLists.unshift({
      title,
      description,
      datatype: value.datatype,
      timestamp: new Date().toISOString(),
      value
    });
    const updatedOriginalList = myLists.filter((list) => list.value.id !== value.id);
    updatedOriginalList.unshift({
      title,
      description,
      datatype: value.datatype,
      timestamp: new Date().toISOString(),
      value
    });
    setMyLists(updatedOriginalList);
    setFilteredList(updatedSortedLists);
    setSortedLists(updatedSortedLists);
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.listTableHeaderCell}>
              <span className={classes.listTableHeaderActionWrapper}>
                <p className={classes.listTableHeaderCellBodyAction}>Name</p>
                <IconButton
                  onClick={sortName}
                  className={classes.listTableHeaderCellSort}
                  size="small">
                  {sortNameIcon ? <Sort style={{ transform: 'scaleY(-1)' }} /> : <Sort />}
                </IconButton>
              </span>
            </TableCell>
            <TableCell className={classes.listTableHeaderCellDescription}>
              <p className={classes.listTableHeaderCellBody}>Description</p>
            </TableCell>
            <TableCell className={classes.listTableHeaderCell}>
              <p className={classes.listTableHeaderCellBody}> # of Records</p>
            </TableCell>
            <TableCell className={classes.listTableHeaderCell}>
              <span className={classes.listTableHeaderActionWrapper}>
                <p className={classes.listTableHeaderCellBodyAction}>Module</p>
                <IconButton
                  onClick={onOpen}
                  className={classes.listTableHeaderCellSort}
                  size="small">
                  {<ArrowDropDown />}
                </IconButton>
                {open && moduleFilter()}
              </span>
            </TableCell>
            <TableCell className={classes.listTableHeaderCell}>
              <span className={classes.listTableHeaderActionWrapper}>
                <p className={classes.listTableHeaderCellBodyAction}>Last Updated At</p>
                <IconButton
                  className={classes.listTableHeaderCellSort}
                  onClick={sortTimestamp}
                  size="small">
                  {sortTimeIcon ? <Sort className={classes.sortIcon} /> : <Sort />}
                </IconButton>
              </span>
            </TableCell>
            <TableCell className={classes.listTableHeaderCell}>
              <p className={classes.listTableHeaderCellBody}>Actions</p>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(sortedLists.map((list) => (
            <MyListRow
              key={list.value.id}
              enableListMode={enableListMode}
              listDeleted={listDeleted}
              listUpdated={listUpdated}
              selectList={selectList}
              onUpdateList={onUpdateList}
              onDeleteList={onDeleteList}
              list={list} />
          )))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

MyListTable.propTypes = {
  classes: PropTypes.object.isRequired,
  enableListMode: PropTypes.func,
  listDeleted: PropTypes.func,
  listUpdated: PropTypes.func,
  lists: PropTypes.array.isRequired,
  selectList: PropTypes.func,
};

export default withStyles(styles)(MyListTable);

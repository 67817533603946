import React from 'react';
import { connect } from 'react-redux';
import { addLocation } from '../feed/actions';
import { fetchUser, updateCurrentUser } from '../auth/actions';
import * as actions from './actions';
import Onboarding from './Onboarding';

export class OnboardingContainer extends React.Component {
  render() {
    return (
      <Onboarding {...this.props} />
    );
  }
}

function mapStateToProps({
  onboarding: { currentStep, isComplete },
  auth: { currentUser },
}) {
  return {
    currentStep,
    currentUser,
    initialValues: {
      name: currentUser.name || '',
      defaultLanding: currentUser.defaultLanding || 'feed',
      inferDefaultLocation: !(currentUser.defaultLocation && currentUser.defaultLocation.location.length > 0),
      onboarded: true,
      propertyTypes: currentUser.propertyTypes || [],
    },
    isComplete,
  };
}

export default connect(mapStateToProps, {
  updateCurrentUser,
  fetchUser,
  addLocation,
  ...actions,
})(OnboardingContainer);

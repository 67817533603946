import formatStat from '../common/stats/formatStat';

export const COLOR_RANGE = [
  '#FED976',
  '#FEB24C',
  '#FD8D3C',
  '#FC4E2A',
  '#E31A1C',
  '#BD0026',
  '#800026',
];

export const GEOGRAPHIES = [
  {
    title: 'Census Tract',
    key: 'tract',
    uri: 'census_tracts',
    zoomThreshold: 11,
  },
  {
    title: 'County Subdivision',
    key: 'cousub',
    uri: 'county_subdivisions',
    zoomThreshold: 9,
  },
  {
    title: 'County',
    key: 'county',
    uri: 'counties',
    zoomThreshold: 0,
  },
];

export const SERIES = {
  medianIncome: {
    name: 'Median Income',
    key: 'cy_median_household_income',
    format: (value) => formatStat(value || 0, { format: 'wholeCurrency' })
  },
  medianRent: {
    name: 'Median Rent',
    key: 'acsy_median_rent',
    format: (value) => formatStat(value || 0, { format: 'wholeCurrency' })
  },
  medianHomeValue: {
    name: 'Median Home Value',
    key: 'cy_median_home_value',
    format: (value) => formatStat(value || 0, { format: 'wholeCurrency' }) || '$0'
  },
  totalHouseholds: {
    name: 'Total Households',
    key: 'cy_households',
    format: (value) => `${formatStat(value || 0, { format: 'wholeNumber' })} households`
  },
  totalPopulation: {
    name: 'Total Population',
    key: 'cy_population',
    format: (value) => `${formatStat(value || 0, { format: 'wholeNumber' })} people`
  },
  totalHousingUnits: {
    name: 'Total Housing Units',
    key: 'cy_housing_units',
    format: (value) => `${formatStat(value || 0, { format: 'wholeNumber' })} housing units`
  },
  affordability: {
    name: 'Housing Affordability',
    key: 'cy_housing_affordability_index',
    format: (value) => formatStat(value || 0, { format: 'wholeNumber' })
  },
};

import get from 'lodash/get';
import {
  UPDATE_MAP_PINS,
  SELECT_POPUP,
  CLEAR_POPUP,
  FETCH_POPUP_CONTENT_REQUEST,
  FETCH_POPUP_CONTENT_SUCCESS,
  FETCH_POPUP_CONTENT_ERROR,
  SET_LIST_MOUSEOVER,
  SET_MAP_POLYGONS,
  SELECT_ALERT_RECORD,
  SNACKBAR_MAP_MESSAGE,
  SNACKBAR_MAP_CLOSE,
  CLEAR_MAP_POLYGONS,
  SITEADVISOR_SESSION_REQUEST,
  SITEADVISOR_SESSION_SUCCESS,
  SITEADVISOR_SESSION_ERROR,
  CLEAR_LIST_ERROR,
  FETCH_DEMO_CONTENT_SUCCESS,
  FETCH_FEATURE_CONTENT_REQUEST,
  FETCH_FEATURE_CONTENT_SUCCESS,
  FETCH_FEATURE_CONTENT_ERROR,
  FETCH_FEATURE_META_SUCCESS,
  REMOVE_POI,
  FETCH_FEATURE_META_REQUEST,
  FETCH_DEMO_CONTENT_REQUEST,
  UPDATE_MYALERTS_FAILURE,
  UPDATE_MYALERTS_REQUEST,
  UPDATE_MYALERTS_SUCCESS,
  ENABLE_MAP_PARCELS,
  DISABLE_MAP_PARCELS,
  PARCEL_DATA_LOADED,
} from '../../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest,
  requestData,
  receiveData
} from '../../common/apiActions';
import api, { getHeaders } from '../../services/api';
import { selectSavedSearch } from '../../spotlight/actions';
import { history } from '../../store';

export function updatePins(searchResults) {
  return {
    type: UPDATE_MAP_PINS,
    searchResults,
  };
}

export function selectPopup(pindex) {
  return {
    type: SELECT_POPUP,
    pindex,
  };
}

export function clearPopup() {
  return {
    type: CLEAR_POPUP
  };
}
export function setDemographic(demographic) {
  const { key, name, format } = demographic;
  return (dispatch) => {
    if (!key) {
      dispatch(requestData(FETCH_DEMO_CONTENT_SUCCESS, { data: null }));
    }
    dispatch(requestData(FETCH_DEMO_CONTENT_REQUEST, { data:{ name, key, format } }));
  };
}

export function fetchDemographic(params = '') {
  return (dispatch) => {
    dispatch(requestData(FETCH_FEATURE_CONTENT_REQUEST));
    return api.read(`/gis/features/${params}`)
      .then((data) => {
        return dispatch(receiveData(FETCH_DEMO_CONTENT_SUCCESS, data));
      })
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }
        return dispatch(handleError(FETCH_FEATURE_CONTENT_ERROR, {
          data: { type: 'FeatureCollection', features: [] }
        }));
      });
  };
}

export function fetchGISFeature(params = '', id, type, demographic) {
  return (dispatch) => {
    if (id) {
      dispatch(requestData(FETCH_FEATURE_CONTENT_REQUEST));
    } else {
      dispatch(requestData(FETCH_FEATURE_META_REQUEST));
    }
    return api.read(`/gis/features/${params}`)
      .then((data) => {
        if (id) {
          return dispatch(receiveData(FETCH_FEATURE_CONTENT_SUCCESS, { id, type, data, demographic }));
        }
        return dispatch(receiveData(FETCH_FEATURE_META_SUCCESS, data));
      })
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }
        return dispatch(handleError(FETCH_FEATURE_CONTENT_ERROR, {
          id,
          type,
          data: { type: 'FeatureCollection', features: [] }
        }));
      });
  };
}

export function enableMapParcels() {
  return {
    type: ENABLE_MAP_PARCELS
  };
}

export function disableMapParcels() {
  return {
    type: DISABLE_MAP_PARCELS
  };
}

export function parcelDataLoaded(data) {
  return {
    type: PARCEL_DATA_LOADED,
    data
  };
}

export function fetchPopupContent(id) {
  return (dispatch) => {
    dispatch(requestData(FETCH_POPUP_CONTENT_REQUEST));
    return api.read(`/properties/${id}`)
      .then((data) => dispatch(receiveData(FETCH_POPUP_CONTENT_SUCCESS, data)))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(FETCH_POPUP_CONTENT_ERROR, response));
      });
  };
}

export function createSiteAdvisorSession(data) {
  return (dispatch) => {
    dispatch(requestData(SITEADVISOR_SESSION_REQUEST));
    return fetch(process.env.REACT_APP_SITEADVISOR_API, {
      body: JSON.stringify(data),
      headers: {
        ...getHeaders()
      },
      method: 'POST',
      mode: 'cors'
    })
      .then(async (res) => {
        const url = await res.text();
        window.open(url, '_blank').focus();
        return dispatch(receiveData(SITEADVISOR_SESSION_SUCCESS, data));
      })
      .catch((response) => {
        return dispatch(handleError(
          SITEADVISOR_SESSION_ERROR,
          {
            message: 'An error occurred while generating a new SiteAdvisor session',
            response
          }
        ));
      });
  };
}

export function removeFeature(id) {
  return {
    type: REMOVE_POI,
    id
  };
}

export function clearListError() {
  return {
    type: CLEAR_LIST_ERROR
  };
}

export function setPolygons(polygons) {
  return {
    type: SET_MAP_POLYGONS,
    polygons
  };
}

export function clearPolygons() {
  return {
    type: CLEAR_MAP_POLYGONS
  };
}

export function setListMouseover(id) {
  return {
    type: SET_LIST_MOUSEOVER,
    id
  };
}

export function closeMapNotifications() {
  return {
    type: SNACKBAR_MAP_CLOSE
  };
}

export function updateAlertRead(alertId, alert) {
  alert.isRead = true;
  return (dispatch) => {
    dispatch({ type: UPDATE_MYALERTS_REQUEST });
    return api.update(`alerts/${alertId}`, alert)
      .then(() => {
        dispatch({ type: UPDATE_MYALERTS_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_MYALERTS_FAILURE,
          err: { ...error },
        });
      });
  };
}

export function fetchAlertRecord(alertId, savedSearchId) {
  return async (dispatch) => {
    const savedSearch = await api.read('savedSearches', savedSearchId)
      .then((res) => res)
      .catch(() => false);

    window.location.hash = savedSearch.urlHash;
    history.replace({
      ...location,
      pathname: '/research/map',
      hash: savedSearch.urlHash,
      search: ''
    });

    api.read('alerts', alertId)
      .then((alert) => {
        const polygonExists = get(
          savedSearch,
          `query.${savedSearch.enabledDataTypes[0]}.query.bool.must[0].bool.should`,
          false);
        if (polygonExists) {
          const polygons = polygonExists.map((geoPolygon) => (
            geoPolygon.geo_polygon.location.points.map((geoPoint) => (
              { lat: geoPoint[1], lng: geoPoint[0] }
            ))
          ));
          dispatch({ type: SET_MAP_POLYGONS, polygons });
        } else {
          dispatch({ type: CLEAR_MAP_POLYGONS });
        }
        dispatch({
          type: SNACKBAR_MAP_MESSAGE,
          text: savedSearch.title, secondaryText: 'Alert Loaded', textType: 'success' });
        dispatch({ type: SELECT_ALERT_RECORD, alert, savedSearch });
        return dispatch(updateAlertRead(alertId, alert));
      })
      .catch(() => {
        if (!savedSearch) {
          dispatch({ type: SNACKBAR_MAP_MESSAGE, text: 'No Search Found', textType: 'error' });
          return history.replace({ ...location, pathname: '/research/map' });
        }
        return dispatch(selectSavedSearch(savedSearch));
      });
  };
}


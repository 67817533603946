import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Can from '../../auth/ability';
import AccountDefault from '../common/AccountDefault';
import AccountLoading from '../common/AccountLoading';
import { fetchAlertRecord } from '../../research/map/actions';
import MyAlertsTable from './MyAlertsTable';
import styles from './module.css.js';

const MyAlertsContainer = ({
  fetchAlertRecord,
  router,
  recentMyAlerts
}) => {
  const [hasAlertsLoaded, setHasAlertsLoaded] = useState(false);
  const [alerts, setAlerts] = useState([]);

  useEffect(() => {
    if (recentMyAlerts) {
      const filteredAlerts = recentMyAlerts.filter((alert) => alert.frequency !== 'never');
      setAlerts(filteredAlerts.map((result) => ({
        title: result.title,
        description: result.description,
        frequency: result.frequency,
        datatype: result.datatype,
        timestamp: result.updatedAt,
        value: result
      })));

      setHasAlertsLoaded(true);
    }
  }, [recentMyAlerts]);

  return (
    <Can do="use" on="_app">
      {(hasAlertsLoaded) && (alerts.length === 0) && (
        <AccountDefault 
          accountType={'alerts'}
          alerts={alerts}
          buttonText={'Alert'}
          router={router} />
      )}
      {(!hasAlertsLoaded) && (alerts.length === 0) && (
        <AccountLoading 
          accountType={'Alerts'} />
      )}
      {(hasAlertsLoaded) && (alerts.length !== 0) && (
        <MyAlertsTable
          alerts={alerts}
          fetchMyAlert={fetchAlertRecord} />
      )}
    </Can>
  );
};

MyAlertsContainer.propTypes = {
  fetchAlertRecord: PropTypes.func.isRequired,
  recentMyAlerts: PropTypes.array,
  router: PropTypes.object.isRequired,
};

function mapStateToProps({ research }) {
  const { alerts: { recentMyAlerts } } = research;
  return { recentMyAlerts };
}

export default connect(
  mapStateToProps,
  { fetchAlertRecord }
)(withStyles(styles)(MyAlertsContainer));

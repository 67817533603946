export default () => ({
  container: {
    display: 'flex',
    height: '100%',
    width: '100%'
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
  }
});

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTracker } from '@colliers-international/usage-tracker';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DemographicsIcon from '@material-ui/icons/InsertChart';
import { COLLIERS_TEAL } from '../../styles/theme';
import styles from './module.css';

const getRouteTab = (router) => {
  const routes = get(router, 'routes', []);
  const tab = get(routes, [routes.length - 1, 'path']);
  return tab ? tab : 'public/tax';
};

const ParcelTiles = ({ classes, location, params, router }) => {
  const [currentTab, setCurrentTab] = useState(getRouteTab(router));
  const { trackEvent } = useTracker();

  const navigate = async (key) => {
    const basePath = `/research/map/parcel/fips/${params.fips}/apn/${params.apn}`;
    const newPath = `${basePath}/info/${key}`;
    router.push({ ...location, pathname: newPath });
  };

  const minWidth = useMediaQuery('(min-width:1500px)');
  
  const showDatatypeLabels = (label, tooltip) => {
    if (!minWidth && !tooltip) return '';
    return label;
  };

  const nav = (key) => {
    return () => {
      if (key === 'public/sales') {
        trackEvent({ category: 'Parcel Detail Page', action: 'Clicked', name: 'PUBLIC SALE RECORDS tab' });
      }
      navigate(key);
    };
  };

  const handleChange = async (_event, value) => {
    setCurrentTab(value);
  };

  const contentSections = [
    {
      key: 'public/tax',
      label: 'PARCEL/TAX',
      icon: AccountBalanceIcon
    },
    {
      key: 'public/sales',
      label: 'PUBLIC SALE RECORDS',
      icon: AttachMoneyIcon
    },
    {
      key: 'demographics',
      label: 'DEMOGRAPHICS',
      icon: DemographicsIcon
    }
  ];
  return (
    <nav className={classes.datatypeMenuNav}>
      <Tabs 
        value={currentTab} 
        className={classes.datatypeMenuTabs} 
        TabIndicatorProps={{
          style: { background: `${COLLIERS_TEAL}` },
          className: classes.datatypeMenuTabIndicatorProps }}
        onChange={handleChange}>
        {contentSections.map(({ key, label, icon: Icon }) => (
          <Tab
            style={
              currentTab === key
                ? { backgroundColor: COLLIERS_TEAL }
                : { backgroundColor: '#6E6E70' }
            }
            onClick={nav(key)}
            value={key}
            key={key}
            label={`${showDatatypeLabels(label)}`}
            disableRipple
            className={
              currentTab === key
                ? classes.datatypeMenuTab
                : classes.datatypeMenuTabInactive
            }
            icon={
              minWidth ? (
                <Avatar
                  className={classes.datatypesMenuTabAvatar}
                  style={
                    currentTab === key
                      ? { backgroundColor: COLLIERS_TEAL }
                      : { backgroundColor: '#6E6E70' }
                  }>
                  <Icon className={classes.datatypesMenuTabIcon} />
                </Avatar>
              ) : (
                <Tooltip title={showDatatypeLabels(label, true)}placement="bottom">
                  <Avatar
                    className={classes.datatypesMenuTabAvatar}
                    style={
                      currentTab && currentTab === key
                        ? { backgroundColor: COLLIERS_TEAL }
                        : { backgroundColor: '#6E6E70' }
                    }>
                    <Icon className={classes.datatypesMenuTabIcon} />
                  </Avatar>
                </Tooltip>
              )
            } />
        ))}
      </Tabs>
    </nav>
  );
};

ParcelTiles.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(ParcelTiles);

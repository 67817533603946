import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/Check';
import { COLLIERS_YELLOW } from '../styles/theme.js';
import styles from './module.css';

const NotificationMessage = ({ classes = {}, secondaryText, type, text = '' }) => {
  return (
    <div className={classes.notificationMessage}>
      {(type === 'progress') && (<CircularProgress size={30} color="inherit" />)}
      {(type === 'success') && (<SuccessIcon style={{ color: green[500] }} />)}
      {(type === 'error') && (<ErrorIcon color="error" />)}
      {(type === 'warn') && (<ErrorIcon style={{ color: COLLIERS_YELLOW }} />)}
      {secondaryText 
        ? <span className={classes.notificationMessageSecondaryText}><strong>{text}</strong> {secondaryText}</span> 
        : <span>{text}</span>}
    </div>
  );
};

NotificationMessage.propTypes = {
  classes: PropTypes.object,
  savedSearch: PropTypes.bool,
  secondaryText: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string
};

export default withStyles(styles)(NotificationMessage);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory as router } from 'react-router';
import { read } from '../../../services/api';
import Can from '../../../auth/ability';
import { getParcelPath } from '../../../common/getParcelPath';
import { COLLIERS_TEAL } from '../../../styles/theme';
import { hideAllFilters } from '../../searches/actions';
import RightPanelDetails from '../listItemContent/RightPanelDetails';
import ParcelsIcon from '../../../assets/parcel-icon.svg';
import { DemographicsPopupCard } from './DemographicsPopupCard';
import PropertiesPopupCard from './PropertiesPopupCard';
import RecordedSalesPopupCard from './RecordedSalesPopupCard';
import PopupContent from './PopupContent';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import styles from './module.css';

const formatParcelAddress = (city, state, zip) => {
  if (city && state && zip) {
    return `${city}, ${state} ${zip}`;
  }
  return '\u2013';
};

const formatAddress = (streetAddress, city, state, zip) => {
  if (streetAddress && city && state && zip) {
    return `${streetAddress}, ${city}, ${state} ${zip}`;
  }
  return '\u2013';
};

const formatOwnerType = (ownerType) => {
  return ownerType === null ? '\u2013' : ownerType;
};

const formatString = (str) => {
  return str ? str : '\u2013';
};

const PopUp = ({ 
  bounds, 
  classes, 
  closePopup, 
  parcelData, 
  isFetching, 
  property, 
  searches, 
  showProgress, 
  params, 
  parcelPopup,
  setParcelProperties,
  trackEvent
}) => {
  const [assessment, setAssessment] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const apn = get(parcelData, 'assessment.apn', 'parcelApn');

  useEffect(() => {
    async function fetchAssessmentData() {
      if (parcelData && parcelData.id) {
        try {
          const assessmentRes = await read(`/parcels/${parcelData.id}/assessments`);
          setAssessment(assessmentRes.assessments[0]);
          setIsLoading(false);
        } catch (error) {
          setAssessment({});
          setIsLoading(false);
          console.error('Error fetching assessment data:', error);
        }
      }
    }

    if (Object.keys(parcelData).length > 0) {
      fetchAssessmentData();
    }
  }, [parcelData]);

  const navigate = async () => {
    const pathname = await getParcelPath({
      apn: parcelData.assessment.apn,
      fips: parcelData.fips,
    });

    router.push({
      ...location,
      pathname: `${pathname}info`
    });
  };

  const hasAssessment = (parcelPopup || parcelPopup === undefined) 
    && Object.keys(assessment).length > 0
    && !showProgress && !isFetching && !isLoading;

  const hasParcelPopup = (parcelPopup || parcelPopup === undefined) 
    && Object.keys(parcelData).length > 0
    && !showProgress && !isFetching && !isLoading;

  const parcelAddressClick = () => {
    trackEvent({ category: 'Parcel Pop-Up', action: 'Clicked', name: 'Parcel Address from Pop-up' });
    return navigate();
  };

  return (
    <div>
      {hasParcelPopup && hasAssessment && !isLoading && (
        <Can do="read" on="parcels">
          <div className={classes.parcelPopup}>
            <React.Fragment>
              <header
                className={classes.parcelPopupHeader}
                onClick={parcelAddressClick}
                style={{ backgroundColor: COLLIERS_TEAL, color: 'white', zIndex: 250 }}>
                <div style={{ display: 'flex' }}>
                  <img
                    src={ParcelsIcon}
                    style={{ height: '26px', width: 'auto', margin: '6px 10px 0px 0px' }}
                    alt="Filters" />
                  <div>
                    <h4>{get(parcelData, 'location.streetAddress', '\u2013')}</h4>
                    <h4>
                      {formatParcelAddress(
                        get(parcelData, 'location.locality'),
                        get(parcelData, 'location.regionCode'),
                        get(parcelData, 'location.postalCode'))
                      }
                    </h4>
                  </div>
                </div>
              </header>
              <Button className={classes.closeButton} onClick={closePopup}>
                <CloseIcon className={classes.closeIcon} />
              </Button>
              <div className={classes.popupContent}>
                <div className={classes.popupContentRow}>
                  <div className={classes.popupContentRowItem}>
                    <span className={classes.parcelLabel}>Situs Address:</span>
                    <strong className={classes.situsAddress}>
                      {formatAddress(
                        get(parcelData, 'location.streetAddress'),
                        get(parcelData, 'location.locality'),
                        get(parcelData, 'location.regionCode'),
                        get(parcelData, 'location.postalCode'))}
                    </strong>
                  </div>
                  <div className={classes.popupContentRowItem}>
                    <span className={classes.parcelLabel}>APN:</span>
                    <strong className={classes.parcelValue}>
                      {formatString(apn)}
                    </strong>
                  </div>
                </div>
                <RightPanelDetails
                  assessment={assessment}
                  classes={classes}
                  type={'parcel'}
                  _source={parcelData || {}} />
                <div className={classes.popupContentRowItem}>
                  <span className={classes.parcelLabel}>Recorded Owner:</span>
                  <strong className={classes.parcelValue}>
                    {get(parcelData, 'owner.names[0].fullName', '\u2013')}
                  </strong>
                </div>
                <div className={classes.popupContentRowItem}>
                  <span className={classes.parcelLabel}>Mailing Address:</span>
                  <strong className={classes.parcelValue}>
                    {formatAddress(
                      get(parcelData, 'owner.streetAddress'),
                      get(parcelData, 'owner.locality'),
                      get(parcelData, 'owner.regionCode'),
                      get(parcelData, 'owner.postalCode'))}
                  </strong>
                </div>
                <div className={classes.popupContentRowItem}>
                  <span className={classes.parcelLabel}>Owner Type:</span>
                  <strong className={classes.parcelValue}>
                    {formatOwnerType(get(parcelData, 'owner.ownershipStatus.description', '\u2013'))}
                  </strong>
                </div>
              </div>
              <div>
                <RecordedSalesPopupCard
                  classes={classes}
                  isFetching={isFetching}
                  parcel={parcelData}
                  trackEvent={trackEvent} />
                { parcelData && parcelData.properties && parcelData.properties.length > 0 && (
                  <PropertiesPopupCard
                    classes={classes}
                    params={params}
                    parcel={parcelData}
                    property={property}
                    setParcelProperties={setParcelProperties} />
                )}
                <DemographicsPopupCard
                  parcel={parcelData} 
                  bounds={bounds}
                  trackEvent={trackEvent} />
              </div>
            </React.Fragment>
          </div>
        </Can>)}
      {hasParcelPopup && !hasAssessment && !isLoading && (
        <Can do="read" on="parcels">
          <div className={classes.parcelPopup}>
            <React.Fragment>
              <header
                className={classes.parcelPopupHeader}
                onClick={parcelAddressClick}
                style={{ backgroundColor: COLLIERS_TEAL, color: 'white', zIndex: 250 }}>
                <div style={{ display: 'flex' }}>
                  <img
                    src={ParcelsIcon}
                    style={{ height: '26px', width: 'auto', margin: '6px 10px 0px 0px' }}
                    alt="Filters" />
                  <div>
                    <h4>{get(parcelData, 'location.streetAddress', '\u2013')}</h4>
                    <h4>
                      {formatParcelAddress(
                        get(parcelData, 'location.locality'),
                        get(parcelData, 'location.regionCode'),
                        get(parcelData, 'location.postalCode'))
                      }
                    </h4>
                  </div>
                </div>
              </header>
              <Button className={classes.closeButton} onClick={closePopup}>
                <CloseIcon className={classes.closeIcon} />
              </Button>
              <div className={classes.popupContent}>
                <div className={classes.popupContentRow}>
                  <div className={classes.popupContentRowItem}>
                    <span className={classes.parcelLabel}>Situs Address:</span>
                    <strong className={classes.situsAddress}>
                      {formatAddress(
                        get(parcelData, 'location.streetAddress'),
                        get(parcelData, 'location.locality'),
                        get(parcelData, 'location.regionCode'),
                        get(parcelData, 'location.postalCode'))}
                    </strong>
                  </div>
                  <div className={classes.popupContentRowItem}>
                    <span className={classes.parcelLabel}>APN:</span>
                    <strong className={classes.parcelValue}>
                      {formatString(apn)}
                    </strong>
                  </div>
                </div>
                <RightPanelDetails
                  assessment={assessment}
                  classes={classes}
                  type={'parcel'}
                  _source={parcelData || {}} />
                <div className={classes.popupContentRowItem}>
                  <span className={classes.parcelLabel}>Recorded Owner:</span>
                  <strong className={classes.parcelValue}>
                    {get(parcelData, 'owner.names[0].fullName', '\u2013')}
                  </strong>
                </div>
                <div className={classes.popupContentRowItem}>
                  <span className={classes.parcelLabel}>Mailing Address:</span>
                  <strong className={classes.parcelValue}>
                    {formatAddress(
                      get(parcelData, 'owner.streetAddress'),
                      get(parcelData, 'owner.locality'),
                      get(parcelData, 'owner.regionCode'),
                      get(parcelData, 'owner.postalCode'))}
                  </strong>
                </div>
                <div className={classes.popupContentRowItem}>
                  <span className={classes.parcelLabel}>Owner Type:</span>
                  <strong className={classes.parcelValue}>
                    {formatOwnerType(get(parcelData, 'owner.ownershipStatus.description', '\u2013'))}
                  </strong>
                </div>
              </div>
              <div>
                { parcelData && parcelData.properties && parcelData.properties.length > 0 && (
                  <PropertiesPopupCard
                    classes={classes}
                    params={params}
                    parcel={parcelData}
                    property={property}
                    setParcelProperties={setParcelProperties} />
                )}
                <DemographicsPopupCard
                  parcel={parcelData} 
                  bounds={bounds}
                  trackEvent={trackEvent} />
              </div>
            </React.Fragment>
          </div>
        </Can>)} 
      {!hasParcelPopup && !hasAssessment && property && !isLoading && (
        <PopupContent
          assessment={assessment}
          className={classes.legacyPopup}
          closePopup={closePopup}
          hideAllFilters={hideAllFilters}
          property={property}
          searches={searches}
          isFetching={isFetching}
          parcelPopup={hasParcelPopup} />
      )}
    </div>
  );
};

PopUp.propTypes = {
  bounds: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
  hideAllFilters: PropTypes.func,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.object.isRequired,
  parcelData: PropTypes.object.isRequired,
  parcelPopup: PropTypes.bool.isRequired,
  property: PropTypes.object.isRequired,
  searches: PropTypes.array.isRequired,
  setParcelProperties: PropTypes.func.isRequired,
  showProgress: PropTypes.bool.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

PopUp.defaultProps = {
  searches: [],
};

export default withStyles(styles)(PopUp);

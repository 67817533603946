import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import translate from '../../../common/translate';
import datatypes from '../../../common/datatypes';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css';

const PropertyTiles = ({ 
  classes, 
  count,
  contentSections, 
  datatypeColor, 
  availableDatatypes, 
  currentDatatype, 
  handleChange, 
  showIcons = true
}) => {
  const minWidth = useMediaQuery('(min-width:1500px)');

  const showDatatypeLabels = (key, visiblePlural) => {
    return key === 'property' ? 'PROPERTY' : visiblePlural.toUpperCase();
  };

  return (
    <nav className={classes.datatypeMenuNav}>
      <Tabs
        className={classes.datatypeMenuTabs}
        value={currentDatatype}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { background: `${datatypeColor}`, width: '25%' },
          className: classes.datatypeMenuTabIndicatorProps }}
        variant="fullWidth">
        {Object.keys(contentSections).map((key) => {
          if (Object.keys(contentSections[key]).length === 0) return null;
          const { color = '#b0bec5', Icon } = contentSections[key];
          const visiblePlural = datatypes[key] ? datatypes[key].plural : translate(key);
          const datatypeCount = showIcons ?  '' : ` (${count[key]})`;

          if (!availableDatatypes.includes(key) && (key === 'news' || key === 'company')) return null;

          return (
            <Tab
              key={key}
              className={
                availableDatatypes.includes(key)
                  ? classes.datatypeMenuTab
                  : classes.datatypeMenuTabInactive
              }
              icon={
                minWidth ? (
                  <Avatar
                    className={classes.datatypesMenuTabAvatar}
                    style={
                      currentDatatype && currentDatatype === key
                        ? { backgroundColor: color }
                        : { backgroundColor: '#6E6E70' }
                    }>
                    {showIcons && <Icon className={classes.datatypesMenuTabIcon} />}
                  </Avatar>
                ) : (
                  <Tooltip title={showDatatypeLabels(key, visiblePlural)} placement="bottom">
                    <Avatar
                      className={classes.datatypesMenuTabAvatar}
                      style={
                        currentDatatype && currentDatatype === key
                          ? { backgroundColor: color }
                          : { backgroundColor: '#6E6E70' }
                      }>
                      {showIcons && <Icon className={classes.datatypesMenuTabIcon} />}
                    </Avatar>
                  </Tooltip>
                )
              }
              disableRipple
              disabled={!availableDatatypes.includes(key)}
              value={key}
              label={`${showDatatypeLabels(key, visiblePlural)}${datatypeCount}`}
              style={
                currentDatatype && currentDatatype === key
                  ? { backgroundColor: color }
                  : { backgroundColor: '#6E6E70' }
              } />
          );
        })}
      </Tabs>
    </nav>
  );
};

PropertyTiles.propTypes = {
  availableDatatypes: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  contentSections: PropTypes.object.isRequired,
  count: PropTypes.object,
  currentDatatype: PropTypes.string.isRequired,
  datatypeColor: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  showIcons: PropTypes.bool,
};

export default withStyles(styles)(PropertyTiles);

function url(resource, id, params) {
  let _params;
  if (resource.indexOf('/') !== 0) {
    resource = `/${resource}`;
  }
  if (params) {
    _params = Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&');
  }

  return `${process.env.REACT_APP_API_URL}${resource}${id ? `/${id}` : ''}${params ? `?${_params}` : ''}`;
}

function checkStatus(response) {
  return (response.ok) ? Promise.resolve(response) : Promise.reject(response);
}

export function getHeaders(headers = {}) {
  const token = window.localStorage.getItem('token') || null;
  const guestHeaders = window.localStorage.getItem('isGuest') ? { 'X-Guest-User': 1 } : {};
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    ...guestHeaders,
    ...headers
  };
}

function parseJson(response) {
  if (response.status === 204) {
    return response.text();
  }

  return response.json().then((items) => {
    const total = response.headers.get('X-Total-Items');
    if (total) {
      items.total = parseInt(total);
    }

    return items;
  });
}

export function create(resource, data, options = {}) {
  return fetch(url(resource), {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
    ...options
  })
    .then(checkStatus)
    .then(parseJson);
}

export function createBinary(resource, data) {
  return fetch(url(resource), {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data)
  })
    .then(checkStatus);
}

export async function read(resource, id, params, fetchOptions = {}) {
  const { timeout, ...options } = fetchOptions;
  const controller = new window.AbortController();

  const timeoutId = setTimeout(() => controller.abort(), timeout || 30000);

  const response = await fetch(url(resource, id, params), {
    method: 'GET',
    headers: getHeaders(),
    signal: controller.signal,
    ...options
  })
    .then(checkStatus)
    .then(parseJson);

  clearTimeout(timeoutId);

  return response;
}

export function readBinary(resource, id, params) {
  return fetch(url(resource, id, params), {
    method: 'GET',
    headers: getHeaders()
  })
    .then(checkStatus);
}

export function postBinary(resource, data) {
  return fetch(url(resource), {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data)
  })
    .then(checkStatus);
}

export function update(resource, data, fullUpdate = false) {
  return fetch(url(resource), {
    method: (fullUpdate) ? 'PUT' : 'PATCH',
    headers: getHeaders(),
    body: JSON.stringify(data),
  })
    .then(checkStatus)
    .then(parseJson);
}

export function destroy(resource, id) {
  return fetch(url(resource, id), {
    method: 'DELETE',
    headers: getHeaders()
  })
    .then(checkStatus);
}

export function serializeParams(obj, shouldEncodeValues = true) {
  return Object.keys(obj)
    .map((key) => `${key}=${shouldEncodeValues ? encodeURIComponent(obj[key]) : obj[key]}`)
    .join('&');
}

export function fcContact(email) {
  return fetch(url(`/fc/contact?${serializeParams({ email }, true)}`), {
    method: 'GET',
    headers: getHeaders(),
  })
    .then(checkStatus)
    .then(parseJson);
}

export default {
  create,
  createBinary,
  read,
  readBinary,
  postBinary,
  update,
  destroy
};

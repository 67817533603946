import {
  BoolMustNot,
  BoolShould,
  ExistsQuery,
  FacetAccessor,
  FilterBucket,
} from 'searchkit-react16';
import map from 'lodash/map';
  
class PortfolioAccessor extends FacetAccessor {
  getRawBuckets() {
    return Object.entries(super.getRawBuckets()).map(([key, bucket]) => ({ ...bucket, label: key, key }));
  }
  
  getBuckets() {
    const buckets = super.getBuckets();
    const value = this.state.getValue();
    return map(buckets, (bucket) => {
      if (value.includes(bucket.key)) bucket.selected = true;
      return bucket;
    });
  }

  buildOwnQuery(query) {
    const otherFilters = query.getFiltersWithoutKeys(this.uuid);
    const portfolio = {
      filters: {
        ['other_bucket_key']: 'isNotPortfolio',
        filters: {
          isPortfolio: {
            bool: {
              should: [
                {
                  exists: {
                    field: 'portfolioName.untouched'
                  }
                },
                {
                  exists: {
                    field: 'portfolioId.untouched'
                  }
                }
              ],
              ['minimum_should_match']: 1
            }
          }
        }
      }
    };
    const fb = new FilterBucket(
      this.uuid,
      otherFilters,
      ...this.fieldContext.wrapAggregations({ portfolio })
    );
    return query.setAggs(fb);
  }
  
  buildSharedQuery(query) {
    if (!this.state.getValue().length) return query;
    const value = this.state.getValue();
    const selectedFilter = {
      name: this.translate(this.options.title),
      value,
      id: this.options.id,
      remove: () => { this.state = this.state.clear(); }
    };
    const existsQuery = [new ExistsQuery('portfolioName.untouched'), new ExistsQuery('portfolioId.untouched')];

    let filter; 
    if (value.includes('isPortfolio')) {
      filter = new BoolShould(existsQuery);
      filter.bool['minimum_should_match'] = 1;
    } else if (value.includes('isNotPortfolio')) {
      filter = new BoolMustNot(existsQuery);
    }
    return query
      .addFilter(this.uuid, filter)
      .addSelectedFilter(selectedFilter);
  }
}
  
export default PortfolioAccessor;

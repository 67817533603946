import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import * as demographicsActions from '../propertyDetail/demographics/actions';
import Demographics from '../propertyDetail/demographics/Demographics';
import * as reportsActions from '../reports/actions';
import * as actions from './actions';

const ParcelDemographicsContainer = (props) => {
  const { dataUri, fetchDemographicsReportPreview, property } = props;

  useEffect(() => {
    if (!dataUri && property) {
      fetchDemographicsReportPreview({
        reportId:process.env.REACT_APP_DEMOGRAPHICS_REPORT_ID_DEFAULT, ids: property.id, property
      });
    }
  }, [property.address]);

  return (
    <div style={{ height: 'calc(100vh - 392px)' }}>
      <Demographics {...props} />
    </div>
  );
};

ParcelDemographicsContainer.propTypes = {
  dataUri: PropTypes.string,
  fetchDemographicsReportPreview: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object,
  property: PropTypes.object,
  router: PropTypes.object.isRequired,
};

function mapStateToProps({ auth, research }) {
  const {
    dataUri,
    isViewReportDisabled,
    parcel,
  } = research.parcelDetail;
  const { property: mapProperty } = research.propertyDetail;
  const { currentUser } = auth;
  const { reports } = currentUser;
  const parcelData = get(parcel, 'parcels[0]', {});
  const { latitude, longitude } = get(parcelData, 'location.representativePoint', {});
  const address = get(parcelData, 'location.streetAddress', '');
  const demographicProperty = { address, id: [], location: [longitude, latitude] };
  const demographicReports = reports.filter(({ dataTypes }) => (dataTypes.includes('demographics')));

  return {
    currentUser,
    dataUri,
    isViewReportDisabled,
    mapProperty,
    parcel,
    property: demographicProperty,
    reports: demographicReports,
  };
}

export default connect(
  mapStateToProps,
  {
    ...reportsActions,
    ...demographicsActions,
    fetchReportPreview: demographicsActions.fetchDemographicsReportPreview,
    ...actions
  }
)(ParcelDemographicsContainer);


import { grey } from '@material-ui/core/colors';

export default (theme) => ({
  card: {
    position: 'relative'
  },
  cardAvatar: {
    height: 50,
    left: 0,
    position: 'absolute',
    top: 0,
    width: 50,
  },
  cardBody: {
    borderBottom: `1px solid ${grey[300]}`,
    paddingTop: '5px !important',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    '& span': {
      color: `${grey[500]} !important`
    }
  },
  contactDetails: {
    paddingTop: 5,

  },
  contactDetail: {
    fontSize: '14px',
    color: grey[700],
    fontWeight: 500,
    lineHeight: '18px',
    marginLeft: 70
  },
  contactHeader: {
    height: 50,
    position: 'relative',
  },
  contactTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '50px',
    marginLeft: 70
  },
  social: {
    height: 50,
    position: 'absolute',
    right: 0,
    top: 0,
    ['& a']: {
      display: 'inline-block',
      height: 50,
      marginLeft: theme.spacing(),
      padding: `${theme.spacing()}px 0`,
      width: 30,
      ['&:first-child']: {
        marginLeft: 0,
      }
    },
    ['& img']: {
      backgroundColor: 'transparent !important',
      height: 30,
      width: 30,
    }
  },
  stat: {
    margin: `${theme.spacing()}px 0`,
    position: 'relative',
  },
  statIcon: {
    left: 30,
    height: '18px',
    position: 'absolute',
    top: 0,
    width: '18px',
  }
});

import React from 'react';
import { Route, IndexRoute } from 'react-router';
import ResearchContainer from './Container';
import MapContainer from './map/Container';
import TableContainer from './table/Container';
import AnalyticsContainer from './analytics/Container';
import ReportsContainer from './reports/Container';
import detailRoutes from './propertyDetail/routes';
import parcelRoutes from './parcelDetail/routes';
import timDetailRoutes from './timDetail/routes';
import ColumnsOrganizerContainer from './table/columnsOrganizer/Container';

export default (
  <Route path="research" components={{ main: ResearchContainer }}>
    <IndexRoute component={MapContainer} />
    <Route path="map" component={MapContainer}>
      <Route path="analytics" component={AnalyticsContainer} />
      <Route path="reports" component={ReportsContainer} />
      {timDetailRoutes}
      {parcelRoutes}
      {detailRoutes}
    </Route>
    <Route path="table" component={TableContainer}>
      <Route path="columns" component={ColumnsOrganizerContainer} />
      <Route path="analytics" component={AnalyticsContainer} />
      <Route path="reports" component={ReportsContainer} />
      {parcelRoutes}
      {detailRoutes}
    </Route>
  </Route>
);

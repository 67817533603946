import React, { useState } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import formatStat from '../../common/stats/formatStat';
import styles from './module.css';

const formatDate = (date) => {
  return date ? moment(date).format('MM/DD/YYYY') : '\u2013';
};

const formatNumber = (number) => {
  return number ? numeral(number).format('0,0') : '\u2013';
};

function formatString(str) {
  const formattedStr = str ? str : '\u2013';
  const words = formattedStr
    .toLowerCase()
    .split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
}

const formatDollarNumber = (number) => {
  return number ? `$${number.toLocaleString('en-US')}` : '\u2013';
};

const PublicSalesRecordsSummaryLastSale = ({ classes, propertySize, sortedLastMarketSale }) => {
  const { buyer, dateFiling, salePrice } = sortedLastMarketSale;
  const [anchorEl, setAnchorEl] = useState(null);
  const [hoverElId, setHoverElId] = useState(null);

  const calculatedPricePerSF = salePrice.value && propertySize
    ? `$${(salePrice.value / propertySize).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    : '\u2013'; 

  const propertySaleRecordsSummary = [
    { 
      key: 'dateSold', 
      value: formatDate(dateFiling), 
      label: 'Date Sold', 
      format: 'string' 
    },
    { 
      key: 'salePrice', 
      value: formatDollarNumber(salePrice.value), 
      label: 'Sale Price',
      format: 'number' 
    },
    { 
      key: 'propertySize', 
      value: formatNumber(propertySize), 
      label: 'Property Size (SF)', 
      format: 'number' 
    },
    { 
      key: 'pricePerSF', 
      value: calculatedPricePerSF, 
      label: 'Price Per SF', 
      format: 'number' 
    },
    { 
      key: 'saleType', 
      value: formatString(salePrice.description),
      label: 'Sale Type', 
      format: 'string',
      customClass: classes.sourceSummaryEllipsisSalesRecords
    },
    { 
      key: 'buyer', 
      value: formatString(buyer.name), 
      label: 'Buyer', 
      format: 'string',
      customClass: classes.sourceSummaryEllipsisSalesRecords
    },
  ];

  const onMouseEnter = (id) => (event) => {
    setAnchorEl(event.currentTarget);
    setHoverElId(id);
  };
  const onMouseLeave = () => {
    setAnchorEl(null);
    setHoverElId(null);
  };

  return (
    <div>
      <div className={classes.salesRecordsSummaryWrapper}>
        {propertySaleRecordsSummary.map((stat) => {
          return (
            <div key={stat.label} className={classes.summaryFieldStatsStat}>
              {stat.customClass && (
                <React.Fragment>
                  <Typography
                    className={stat.customClass}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={onMouseEnter(stat.label)}
                    onMouseLeave={onMouseLeave}>
                    {formatStat(stat.value, { format: stat.format }) || '-'}
                  </Typography>
                  <Typography className={classes.summaryFieldStatsLabel}>{stat.label}</Typography>
                  <Popover
                    key={stat.label}
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    className={classes.sourceSummaryEllipsisPopover}
                    classes={{ paper: classes.sourceSummaryEllipsisContent }}
                    disableRestoreFocus
                    open={hoverElId === stat.label}
                    style={{ pointerEvents: 'none' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                    {formatStat(stat.value, { format: stat.format }) || '-'}
                  </Popover>
                </React.Fragment>
              )}
              {!stat.customClass && (
                <React.Fragment>
                  <Typography
                    className={classes.summaryFieldStatsData}>
                    {formatStat(stat.value, { format: stat.format }) || '-'}
                  </Typography>
                  <Typography className={classes.summaryFieldStatsLabel}>{stat.label}</Typography>
                </React.Fragment>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

PublicSalesRecordsSummaryLastSale.propTypes = {
  classes: PropTypes.object,
  propertySize: PropTypes.number,
  sortedLastMarketSale: PropTypes.object.isRequired,
};

export default withStyles(styles)(PublicSalesRecordsSummaryLastSale);

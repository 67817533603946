import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Stat from '../../../common/stats/Stat';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const PrimaryText = ({ name, address }) => {
  const classes = useStyles();
  return (
    <div className={classes.primaryText}>
      {name || address && address.split(',')[0] || '\u2013'}
    </div>
  );
};

PrimaryText.propTypes = {
  address: PropTypes.string,
  name: PropTypes.string
};

export const SecondaryText = ({ flyerUrl, marketingUrl, properties = [], sellers, isQuickView }) => {
  const classes = useStyles();
  const mergedTypes = new Set();
  const sellerName = get(sellers, '0.name');
  const ownerName = get(properties, '0.owners.0.name', '\u2013');
  const propertyAddress = get(properties, '0.address', '\u2013');
  properties.forEach(({ propertyTypes }) => {
    (propertyTypes || []).forEach((pt) => mergedTypes.add(pt));
  });

  return (
    <React.Fragment>
      <div className={classes.secondaryText}>
        <p>
          {propertyAddress}
        </p>
      </div>
      <div>
        <Stat
          className={classes.stat}
          label="Seller"
          paddingValue="28.5px"
          value={sellerName || ownerName} />
        {isQuickView && (
          <React.Fragment>
            {flyerUrl && (
              <Stat
                className={classes.stat}
                label="Flyer"
                linkValue={true}
                value={flyerUrl} />
            )}
            {!flyerUrl && marketingUrl && (
              <Stat
                className={classes.stat}
                label="Marketing"
                linkValue={true}
                value={marketingUrl} />
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

SecondaryText.propTypes = {
  flyerUrl: PropTypes.string,
  isQuickView: PropTypes.bool,
  marketingUrl: PropTypes.string,
  price: PropTypes.number,
  properties: PropTypes.array,
  sellers: PropTypes.array,
  status: PropTypes.string,
  units: PropTypes.number
};

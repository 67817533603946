import { BoolMust, QueryAccessor } from 'searchkit-react16';

class ZipCodeAccessor extends QueryAccessor {
  constructor(field, options = {}, datatype = '') {
    super(field, options);
    this.datatype = datatype;
  }

  buildSharedQuery(query) {
    const zipCodeIds = this.state.getValue();
    if (!zipCodeIds || zipCodeIds.length === 0) return query;

    let filter;

    if (this.datatype === 'property') {
      filter = new BoolMust({ terms: { zipCode: zipCodeIds } });
    }

    if (this.datatype === 'availability' || this.datatype === 'lease') {
      filter = new BoolMust({ terms: { 'property.zipCode': zipCodeIds } });
    }

    if (this.datatype === 'sale' || this.datatype === 'listing') {
      filter = new BoolMust({ terms: { 'properties.zipCode': zipCodeIds } });
    }

    const selectedFilter = {
      name: this.translate(this.options.title),
      zipCodeIds,
      id: this.options.id,
      remove: () => { this.state = this.state.clear(); }
    };
    return query
      .addQuery(filter)
      .addSelectedFilter(selectedFilter);
  }
}

export default ZipCodeAccessor;

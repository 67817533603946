import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import {
  CURRENT_USER_FETCH_SUCCESS,
  CURRENT_USER_UPDATE_SUCCESS,
  RESET_APP_STATE
} from './constants/actionTypes';
import datatypes from './common/datatypes';
import account from './account/reducer';
import auth from './auth/reducer';
import { ability } from './auth/ability';
import feed from './feed/reducer';
import onboarding from './onboarding/reducer';
import research from './research/reducer';
import spotlight from './spotlight/reducer';

function visibleDatatypes(state = [], action) {
  switch (action.type) {
    case CURRENT_USER_UPDATE_SUCCESS:
    case CURRENT_USER_FETCH_SUCCESS:
      let result = Object.keys(datatypes);
      // limit visible datatypes based on permissions
      if (ability.defaultTenantId) {
        result = Object.keys(datatypes).reduce((acc, key) => {
          if (ability.can('read', datatypes[key].resource)) acc.push(key);
          return acc;
        }, []);
      }
      return result;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  visibleDatatypes,
  account,
  auth,
  feed,
  research,
  onboarding,
  routing: routerReducer,
  spotlight
});

export default (state, action) => {
  if (action.type === RESET_APP_STATE) {
    window.localStorage.removeItem('app');
    state = undefined;
  }
  return rootReducer(state, action);
};

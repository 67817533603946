import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import styles from './module.css.js';
import { withStyles } from '@material-ui/core/styles';

const CreateListsMenu = ({
  classes,
  createListsWithEntities,
  currentListView,
  selectedEntities,
  setListView,
  validateNewList
}) => {
  const [value, setValue] = useState({ title: '', description: '' });
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleSubmit = (event) => {
    event.stopPropagation();
    if (value && value.title && value.title.trim()) {
      if (!validateNewList(value)) {
        setHasError(true);
        setErrorMessage('A list with that name already exists');
        return;
      }

      createListsWithEntities(value, currentListView, selectedEntities);
      setValue({ title: '', description: '' });
      setListView(event);
      return;
    }

    setHasError(true);
    setErrorMessage('Title is a required field');
  };

  const handleChange = (event) => {
    event.stopPropagation();
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
    setHasError(false);
    setErrorMessage('');
  };

  return (
    <div className={classes.createListMenu}>
      <TextField
        autoComplete="off"
        error={hasError}
        helperText={errorMessage}
        id="standard-size-normal"
        label="LIST NAME"
        name="title"
        onChange={handleChange}
        onClick={stopPropagation}
        className={classes.createListMenuTextField} />
      <TextField
        autoComplete="off"
        className={classes.createListMenuTextField}
        id="standard-size"
        label="DESCRIPTION"
        name="description"
        onChange={handleChange} 
        onClick={stopPropagation} />
      <div className={classes.createListMenuChipDiv}>
        <Chip
          onClick={setListView}
          className={classes.createListMenuChip}
          label="CANCEL"
          variant="outlined" color="primary" />
        <Chip label="SAVE" color="primary" onClick={handleSubmit} />
      </div>  
    </div>
  );
};

CreateListsMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  createListsWithEntities: PropTypes.func.isRequired,
  currentListView: PropTypes.string.isRequired,
  selectedEntities: PropTypes.any,
  setListView: PropTypes.func.isRequired,
  validateNewList: PropTypes.func.isRequired
};

export default withStyles(styles)(CreateListsMenu);

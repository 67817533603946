import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const CommentForm = ({ classes, saveComment }) => {
  const onSubmit = (data, { resetForm }) => saveComment(data).then(resetForm);

  return (
    <Formik initialValues={{ body: '' }} onSubmit={onSubmit}>
      {({ isSubmitting, dirty, handleReset }) => (
        <Form>
          <div>
            <Field name="body">
              {({ field, meta }) => (
                <TextField
                  autoFocus
                  fullWidth
                  label="Add a Comment"
                  multiline
                  rows={3}
                  error={meta.touched && Boolean(meta.error)}
                  helperText={meta.touched && meta.error}
                  name={field.name}
                  onChange={field.onChange}
                  value={field.value} />
              )}
            </Field>
          </div>
          <footer className={classes.buttons}>
            <Button
              disabled={!dirty || isSubmitting}
              onClick={handleReset}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={!dirty || isSubmitting}
              type="submit"
              variant="contained">
              Add Comment
            </Button>
          </footer>
        </Form>
      )}
    </Formik>
  );
};

CommentForm.propTypes = {
  classes: PropTypes.object.isRequired,
  saveComment: PropTypes.func.isRequired,
};

export default CommentForm;

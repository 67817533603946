import moment from 'moment';

export const TODAY = Date.parse(moment().format());
export const ONE_DAY_INTERVAL = 86400000;
export const THIRTY_DAYS_AGO = Date.parse(moment().subtract(30, 'days').format());
export const TWO_YEARS_AGO = Date.parse(moment().subtract(2, 'years').format());
export const FIVE_YEARS_AGO = Date.parse(moment().subtract(5, 'years').format());
export const FIVE_YEARS_FROM_NOW = Date.parse(moment().add(5, 'years').format());
export const FIVE_HUNDRED_DAYS_AGO = Date.parse(moment().subtract(500, 'days').format());

export const HIDDEN_GUEST_COLUMNS = ['tags','sourcedBy','rescourId'];

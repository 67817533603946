import calculateDistance from './calculateDistance';

function distanceBetweenPoints([p1x, p1y], [p2x, p2y]) {
  return calculateDistance(
    { lng: p1x, lat: p1y },
    { lng: p2x, lat: p2y }
  );
}

function distanceToLine([x, y], [[l1x, l1y], [l2x, l2y]]) {
  const xD = l2x - l1x;
  const yD = l2y - l1y;

  const u = (((x - l1x) * xD) + ((y - l1y) * yD)) / ((xD * xD) + (yD * yD));

  let closestLinePoint;
  if (u < 0) {
    closestLinePoint = [l1x, l1y];
  } else if (u > 1) {
    closestLinePoint = [l2x, l2y];
  } else {
    closestLinePoint = [l1x + (u * xD), l1y + (u * yD)];
  }

  return distanceBetweenPoints([x, y], closestLinePoint);
}

function distanceToPolygon([x, y], vertices) {
  const comp = vertices.reduce(({ prevPoint, dist }, currPoint) => {
    const currDist = distanceToLine([x, y], [prevPoint, currPoint]);
    const ret = {
      prevPoint: currPoint,
      dist,
    };
    if (currDist < dist) {
      ret.dist = currDist;
    }
    return ret;
  }, { prevPoint: vertices[vertices.length - 1], dist: Infinity });
  return comp.dist;
}

function isInsidePolygon([x, y], vertices) {
  let inside = false;
  for (let i = 0, j = vertices.length - 1; i < vertices.length; j = i++) {
    const xi = vertices[i][0];
    const yi = vertices[i][1];
    const xj = vertices[j][0];
    const yj = vertices[j][1];

    const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    if (intersect) inside = !inside;
  }
  return inside;
}

export default function geoDistanceToPolygon({ lat, lng }, shape) {
  const xyShape = shape.map((latlng) => [latlng[1], latlng[0]]);
  return isInsidePolygon([lng, lat], xyShape) ? 0 : distanceToPolygon([lng, lat], xyShape);
}
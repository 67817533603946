export default function getLocationTitles({ name, street, address }) {
  const primary = name || street || address.split(',')[0];
  const secondary = address;
  return { primary, secondary };
}

export const previousPath = () => {
  const path = ((window.location.pathname).split('/')[2]);
  return path || 'map';
}; // Replaces dynamic route on either table or map for path URL

import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import PublicSalesRecordsDetailsLastSale from './PublicSalesRecordsDetailsLastSale';
import PublicSalesRecordsSummaryLastSale from './PublicSalesRecordsSummaryLastSale';
import PublicSalesRecordsTab from './PublicSalesRecordsTab';

const PublicSalesRecordsWrapper = (props) => {
  const { isFetchingAssessments, isFetchingTransactions, parcel, transactions } = props;
  if (!parcel || !transactions) return null;
  const isFetching = isFetchingAssessments || isFetchingTransactions;

  const parcelId = get(parcel, 'parcels.0.id', null);
  const propertySize = get(parcel, 'parcels.0.assessment.lot.size', null);
  const dateSold = get(parcel, 'parcels.0.transaction.lastMarketSale.filingDate', null);
  const salePriceValue = get(parcel, 'parcels.0.transaction.lastMarketSale.value', null);
  const saleType = get(parcel, 'parcels.0.transaction.lastMarketSale.sale.description', null);
  const buyer = get(parcel, 'parcels.0.transaction.lastMarketSale.buyer', null);
  const saleDate = get(parcel, 'parcels.0.transaction.lastMarketSale.transferDate', null);
  const titleCompany = get(parcel, 'parcels.0.transaction.lastMarketSale.titleCompany', null);
  const saleDocNo = get(parcel, 'parcels.0.transaction.lastMarketSale.documentNumber', null);
  const firstMtgType = get(parcel, 'parcels.0.transaction.lastMarketSale.loan.description', null);
  const firstMtgAmt = get(parcel, 'parcels.0.transaction.lastMarketSale.loan.first', null);
  const transferDocNo = get(parcel, 'parcels.0.transaction.lastMarketSale.tdDocumentNumber', null);
  const transferDocDesc = get(parcel, 'parcels.0.transaction.lastMarketSale.documentTypeDescription', null);
  const sellerName = get(parcel, 'parcels.0.transaction.lastMarketSale.seller', null);
  const lender = get(parcel, 'parcels.0.transaction.lastMarketSale.lender', null);
  const multipleAPNFlag = get(parcel, 'parcels.0.transaction.multipleApnFlag', null);

  function translateParcelData() {
    return {
      id: parcelId,
      isMarketSale: true,
      isConstructionLoan: null,
      dateFiling: dateSold,
      salePrice: {
        description: saleType,
        value: salePriceValue
      },
      buyer: {
        name: buyer
      },
      dateTransfer: saleDate,
      titleCompanyName: titleCompany,
      loan: {
        currentSale: {
          documentNumber: saleDocNo
        },
        type: {
          description: firstMtgType
        },
        value: {
          first: firstMtgAmt
        }
      },
      document: {
        number: transferDocNo,
        typeDescription: transferDocDesc
      },
      seller: {
        name: sellerName
      },
      lender: {
        name: lender
      },
      multipleApnFlag: {
        description: multipleAPNFlag
      }
    };
  }

  const updatedParcelData = translateParcelData(parcel);
  const combinedParcelandTransactions = [...transactions, updatedParcelData];
  const marketSaleTrueData = combinedParcelandTransactions.filter((item) => item.isMarketSale);
  const marketSaleFalseData = combinedParcelandTransactions.filter((item) => !item.isMarketSale);
  marketSaleTrueData.sort((a, b) => new Date(b.dateFiling) - new Date(a.dateFiling));
  marketSaleFalseData.sort((a, b) => new Date(b.dateFiling) - new Date(a.dateFiling));
  const sortedTransactions = [...marketSaleTrueData, ...marketSaleFalseData];

  const sortedLastMarketSale = sortedTransactions[0];
  const additionalMarketSales = sortedTransactions.slice(1);

  return (
    <div>
      {!isFetching && (
        <div style={{ padding: 32, overflow: 'scroll' }}>
          <PublicSalesRecordsSummaryLastSale 
            {...props}
            propertySize={propertySize}
            sortedLastMarketSale={sortedLastMarketSale} />
          <PublicSalesRecordsDetailsLastSale 
            {...props}
            propertySize={propertySize}
            sortedLastMarketSale={sortedLastMarketSale} />
          <PublicSalesRecordsTab 
            {...props}
            propertySize={propertySize}
            additionalMarketSales={additionalMarketSales} />
        </div>
      )}
    </div>
  );
};

PublicSalesRecordsWrapper.propTypes = {
  isFetchingAssessments: PropTypes.bool.isRequired,
  isFetchingTransactions: PropTypes.bool.isRequired,
  parcel: PropTypes.object.isRequired,
  transactions: PropTypes.array.isRequired,
};

export default PublicSalesRecordsWrapper;

/* eslint-disable react/prop-types */
import { Paper } from '@material-ui/core';
import React from 'react';

export const LegendControl = ({ featureLayer, limits, classes }) => {
  const { demographic: { format, name }, clrs } = featureLayer;
  return limits.length ? (
    <Paper elevation={2} className={classes.overlayLegend}>
      <h3>{name}</h3>
      <ul className={classes.overlayLegendList}>
        {limits.map((val, i) => {
          const value = val === 0 ? '0' : val;
          const next = i + 1;
          let formatted;
          if (limits[next]) {
            formatted = <span>{format(value)} &mdash; {format(limits[next] - 1)}</span>;
          } else {
            formatted = <span>{format(value)}+</span>;
          }

          return (
            <li key={i}>
              <div
                style={{ backgroundColor: clrs[i] }}
                className={classes.overlayLegendItemColor} />
              {formatted}
            </li>
          );
        })}
      </ul>
    </Paper>) : null;
};

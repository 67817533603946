import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik, Form, Field, FieldArray } from 'formik';
import compact from 'lodash/compact';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import VirtualSelect from '../common/VirtualSelect';
import PropertyTypesCheckboxGroup from '../common/PropertyTypesCheckboxGroup';
import ProblemSubscriptionsCheckboxGroup from '../common/ProblemSubscriptionsCheckboxGroup';
import { CURRENT_USER_UPDATE_SUCCESS } from '../constants/actionTypes';
import { geocodeSearch } from '../services/location';
import { ability } from './ability';
import { updateCurrentUser } from './actions';
import styles from './module.css';

const AccountSettings = ({
  classes,
  currentUser,
  isVisible,
  hide,
  updateCurrentUser,
  router
}) => {
  const [defaultLocation, setDefaultLocation] = useState(currentUser.defaultLocation || {});
  const initialValues = {
    address: defaultLocation.address || '',
    defaultLanding: currentUser.defaultLanding || 'feed',
    flagSubscriptions: currentUser.flagSubscriptions || [],
    latitude: defaultLocation.latitude,
    longitude: defaultLocation.longitude,
    name: currentUser.name || '',
    propertyTypes: currentUser.propertyTypes || [],
  };

  const onSubmit = (values, { setSubmitting }) => {
    const originalDefaultAddress = initialValues.address;

    // if there is address and latlng set, then just changed defaultLocation
    let newDefaultLocation = {};
    if (values.address && values.longitude && values.latitude) {
      newDefaultLocation = {
        address: values.address,
        location: compact([values.longitude, values.latitude])
      };
    // else, if address is set, reuse current defaultLocation
    } else if (values.address) {
      newDefaultLocation = currentUser.defaultLocation;
    }
    // (else, will be cleared)

    setSubmitting(true);
    return updateCurrentUser({
      name: values.name,
      propertyTypes: values.propertyTypes,
      flagSubscriptions: values.flagSubscriptions,
      defaultLanding: values.defaultLanding,
      defaultLocation: newDefaultLocation
    }).then((dispatchedAction) => {
      // if successful save...
      if (dispatchedAction.type === CURRENT_USER_UPDATE_SUCCESS) {
        // if change defaultLocation, clear the hash on the research page (so will jump to new default location)
        const newDefaultLocation = (dispatchedAction.data.defaultLocation || {});
        if (newDefaultLocation.location && newDefaultLocation.address !== originalDefaultAddress) {
          const location = router.getCurrentLocation();
          if (location.pathname === '/research') {
            router.push({ ...location, hash: '' });
          }
        }

        // hide this dialog
        hide();
      }
    });
  };

  const canFlagPermissions = ability.can('read', 'flagNotifications', ability.defaultTenantId);
  const canSubscribeToFlags = canFlagPermissions && currentUser.tenants.length > 0;

  const onLocationChange = (formik) => (changedLocation) => {
    const newLocation = changedLocation || {};

    setDefaultLocation(newLocation);
    formik.setFieldValue('address', newLocation.address || '');
    formik.setFieldValue('latitude', newLocation.latitude);
    formik.setFieldValue('longitude', newLocation.longitude);
  };

  const loadLocationOptions = (searchText) => {
    if (!searchText) return Promise.resolve({ options: [] });
    return geocodeSearch(searchText).then((options) => {
      return { options };
    });
  };

  // build fails is pass `hide` directly to button
  const onHide = () => {
    hide();
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={isVisible}
      onClose={hide}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(formik) => (
          <Form>
            <DialogContent>
              <div className={classes.accountSettingsRow}>
                <label>Default Location</label>
                <VirtualSelect
                  labelKey="address"
                  onChange={onLocationChange(formik)}
                  onSearch={loadLocationOptions}
                  value={defaultLocation}
                  placeholder="Select a location..." />
              </div>

              <div className={classes.accountSettingsRow}>
                <label>Property Types</label>
                <FieldArray name="propertyTypes" component={PropertyTypesCheckboxGroup} />
              </div>
              <div className={classes.accountSettingsRow}>
                <label>Home Page</label>
                <Field
                  name="defaultLanding"
                  component={({ field: { name, onChange, value } }) => {
                    return (
                      <RadioGroup
                        name={name}
                        onChange={onChange}
                        style={{ flexDirection: 'row' }}
                        value={value}>
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Home"
                          style={{ flex: 1 }}
                          value="dashboard" />
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Map"
                          style={{ flex: 1 }}
                          value="research" />
                      </RadioGroup>
                    );
                  }} />
              </div>

              {canSubscribeToFlags && (
                <div className={classes.accountSettingsRow}>
                  <label>Subscribe to Problem Reports</label>
                  <FieldArray
                    name="flagSubscriptions"
                    component={(arrayHelpers) => {
                      return (
                        <ProblemSubscriptionsCheckboxGroup
                          {...arrayHelpers}
                          tenants={currentUser.tenants || []} />
                      );
                    }} />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={onHide}>Cancel</Button>
              <Button
                color="primary"
                disabled={formik.isSubmitting}
                type="submit"
                variant="contained">Save Changes</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

AccountSettings.propTypes = {
  classes: PropTypes.object,
  currentUser: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  updateCurrentUser: PropTypes.func.isRequired,
};

function mapStateToProps({
  auth: {
    currentUser,
    hasResetPassword,
    isResettingPassword,
  },
}) {
  return {
    currentUser,
    hasResetPassword,
    isResettingPassword,
  };
}

export default connect(mapStateToProps, { updateCurrentUser })(
  withStyles(styles)(AccountSettings)
);

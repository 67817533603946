import { red } from '@material-ui/core/colors';

export default () => ({
  columnsContainer: {
    width: '100%'
  },
  layoutList: {
    width: '200px',
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  warningAlert: {
    color: red[500],
    fontSize: '14px',
    marginTop: '10px'
  },
});

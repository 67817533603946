import { COLLIERS_CYAN } from '../styles/theme';

export default (theme) => ({
  accountDefault: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    minHeight: 300,
    width: '100%'
  },
  addWidgetLink: {
    color: COLLIERS_CYAN,
    cursor: 'pointer',
  },
  availableWidgetsWrapper: {
    width: '100%'
  },
  clickableTitle: {
    'cursor': 'pointer',
    'overflowX': 'hidden',
    'maxWidth': 150,
    'textOverflow': 'ellipsis',
    'textWrap': 'nowrap',
    'color': theme.palette.colliersBlue,
    'fontWeight': 'bold',
    '&:hover': {
      color: COLLIERS_CYAN,
      textDecoration: 'underline'
    }
  },
  card: {
    overflow: 'auto',
    marginBottom: 16,
  },
  disabled: {
    'opacity': 0.5,
    'pointerEvents': 'none',
  },
  dragHandle: {
    color: theme.palette.colliersBlue,
    cursor: 'grab',
    justifySelf: 'flex-start',
    alignSelf: 'flex-end',
  },
  draggableWrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-around',
    width: '100%',
  },
  dropDown: {
    'width': '160px',
    '& > div > div': {
      textAlign: 'center'
    }
  },
  expirationsDropdown: {
    'width': '160px',
    'marginLeft': '280px',
    '& > div > div': {
      textAlign: 'center'
    },
    [theme.breakpoints.down(1570)]: {
      marginLeft: '0px',
    },
    
  },
  errorWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 200,
    textAlign: 'center'
  },
  headerIcons: {
    display: 'flex',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  hidePagination: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'flexDirection': 'row-reverse', 
    [theme.breakpoints.down(1570)]: {
      'flexWrap': 'nowrap',
    },
  },
  largeColumn: {
    'width': '66%',
    'height': '100%',
    'padding': 8,
  },
  link: {
    'cursor': 'pointer',
    'color': theme.palette.colliersBlue,
    'fontWeight': 'bold',
    'textDecoration': 'none',
    '&:hover': {
      color: COLLIERS_CYAN,
      textDecoration: 'underline'
    }
  },
  loaderWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    minHeight: 400
  },
  loadValue: {
    'cursor': 'pointer',
    'color': theme.palette.colliersBlue,
    'fontWeight': 'bold',
    '&:hover': {
      color: COLLIERS_CYAN,
      textDecoration: 'underline'
    }
  },
  removeButton: {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  rowTotal: {
    fontWeight: 'bold',
    paddingLeft: '8px',
  },
  smallColumn: {
    width: '33%',
    height: '100%',
    padding: 8,
  },
  tableFooter: {
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'flexWrap': 'wrap',
    [theme.breakpoints.down(1570)]: {
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      padding: '0px',
      flexWrap: 'nowrap'
    },
  },
  tableHead: {
    '& > tr > th': {
      fontWeight: 'bold',
    },
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(1570)]: {
      'flexDirection': 'column',
      'alignContent': 'space-evenly',
      'flexWrap': 'wrap',
      'alignItems': 'center',
      'gap': '24px',
      '& > .MuiCardHeader-root': {
        'marginLeft': '-24px',
        'width': '100%',
        '& > div': {
          position: 'relative',
          left: '8px',
        },
      },
    },
  },
  tableHeadRoot: {
    '& > thead > tr > th': {
      backgroundColor: '#FFFFFF'
    }
  },
  tablePagination: {
    'margin': '0px',
    'overflow': 'auto',
    '& > div': {
      padding: '0px',
    },
    '& > div > p:nth-of-type(1)': {
      paddingLeft: '12px',
      fontWeight: 'bold',
    },
    '& > div > p:nth-of-type(2)': {
      paddingLeft: '360px',
      fontWeight: 'bold',
    },
    '& > div > div:nth-child(3)': {
      marginLeft: '0px',
      marginRight: '0px',
    },
    '& > div > div > div': {
      fontWeight: 'bold',
    },
    [theme.breakpoints.down(1570)]: {
      '& > div > p:nth-of-type(1)': {
        'padding': '0px 8px',
      },
      '& > div > p:nth-of-type(2)': {
        'padding': '0px 8px 0px 160px',
      },
      '& > div > div:nth-child(3)': {
        'padding': '0px 8px',
      },
    },
  },
  title: {
    'width': '55%',
    'display': 'flex',
    'flexDirection': 'row-reverse',
    'color': theme.palette.colliersBlue,
    '& > div': {
      paddingRight: '8px',
      marginTop: '-8px'
    },
  },
  wrapper: {
    height: '100%',
    width: '100%',
  },
  myBookmarksTableHead: {
    display: 'flex',
    alignItems: 'center',
  },
  myBookmarksTitle: {
    'cursor': 'pointer',
    'color': theme.palette.colliersBlue,
    'fontWeight': 'bold',
    '&:hover': {
      color: COLLIERS_CYAN,
      textDecoration: 'underline'
    }
  },
  myBookmarksDescription: {
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    width: '575px'
  },
  myBookmarksThumbnail: {
    maxWidth: '160px',
    maxHeight: '40px'
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '100px 50px 100px 50px'
  }
});

import grey from '@material-ui/core/colors/grey';
import { COLLIERS_RED } from '../../styles/theme';

export default (theme) => ({
  ['.gm-iv-address']: {
    display: 'none !important'
  },

  contactsWrapper: {
    marginTop: 30,
    marginBottom: 10,
    maxWidth: 400,
    padding: 10,
  },

  header: {
    backgroundColor: grey[900],
    ['& > header']: {
      color: 'white',
      paddingLeft: 10,
      position: 'relative',
    }
  },

  headerBanner: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 2,
    margin: 0,
    overflow: 'hidden',
    padding: '10px 100px 0 0',
    textOverflow: 'ellipsis',
  },

  headerGallery: {
    display: 'flex',
    //height: 'calc((100vw - 370px)/3.2)',
    alignItems: 'stretch',
    width: '100%',

    ['& > img']: {
      flex: '1 1 auto',
      height: '100% !important',
      width: '50% !important',
    },
  },

  headerInfo: {
    display: 'flex',
  },

  headerStats: {
    padding: '0 12px',
  },

  headerStat: {
    border: '1px solid white',
    display: 'inline-block',
    margin: '0 12px',
    padding: '2px 25px',
  },

  headerSubbanner: {
    flex: 1,
    fontSize: 15,
    fontWeight: 100,
    lineHeight: 2,
    margin: 0,
    overflow: 'hidden',
    padding: 0,
    textOverflow: 'ellipsis',
  },

  headerTitle: {
    paddingLeft: 10,
    paddingRight: 35,
    paddingBottom: 15,
    width: '100%',
  },

  infoContainer: {
    lineHeight: 2,
    overflowX: 'scroll',

    ['& section']: {
      borderBottom: `1px solid ${grey[300]}`,
      boxShadow: 'none !important',
      margin: '0 15px',
      padding: 15,

      ['&:last-child']: {
        border: 0,
        marginBottom: 50,
      },
      ['& thead']: {
        border: '0 !important',
      },
      ['& th']: {
        color: 'black !important',
        fontWeight: 'bold !important',
        height: 'auto !important',
        textTransform: 'uppercase',
      }
    }
  },
  
  mapContainer: {
    ['& img']: {
      height: 'auto !important',
      width: '100% !important',
    },
  },

  metaNav: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    right: 0,
    top: 0,

    button: {
      flex: '1 1 auto',
      height: 'auto !important',
      paddingBottom: '0 !important',
      path: {
        fill: `${grey[500]} !important`,
      },
      ['&:hover']: {
        path: {
          fill: 'white !important',
        }
      }
    },
  },

  multirow: {
    ['& > span']: {
      display: 'inline-block',
      lineHeight: '1em',
      paddingTop: '14px',
      whiteSpace: 'normal',
    },
  },

  rightButton: {
    color: 'white',
    margin: '8px 8px 0 0',
    opacity: 0.7,
    ['&:hover']: {
      opacity: 1
    }
  },

  section: {
    ['& > h6']: {
      color: grey[400],
      fontWeight: 'bold',
      lineHeight: 2,
      margin: '2rem 0 0.5rem',
      textTransform: 'uppercase',
    },
    ['&:first-child']: {
      ['& h6']: {
        marginTop: 0
      },
    }
  },

  stat: {
    display: 'flex',
    margin: 0,
    padding: '0 5px',
    borderBottom: `1px solid ${grey[300]}`,
    ['&:last-child']: {
      borderBottom: 0,
    },
    ['& strong']: {
      flex: 1,
      fontSize: '13px',
      fontWeight: 700,
      lineHeight: '35px',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
    ['& span']: {
      color: grey[800],
      flex: '3 1 0%',
      fontSize: '15px',
      lineHeight: '35px',
      textAlign: 'right',
    }
  },

  statBigContainer: {
    flex: 1,
    minWidth: 360,
    maxWidth: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center'
  },

  statBig: {
    flex: 1,
    lineHeight: 2,
    marginTop: 15,
    textAlign: 'center',
    whiteSpace: 'nowrap',

    ['& div']: {
      textTransform: 'uppercase',
      fontWeight: 'bold',
      ['& > svg']: {
        display: 'inline-block',
        marginRight: theme.spacing(),
        height: '24px !important',
        width: '24px !important',
        verticalAlign: 'middle',
      },
    },
    ['& > span']: {
      color: COLLIERS_RED,
      fontSize: '28px',
    },
  },

  statBigRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },

  statDivider: {
    height: '100%',
    background: '#DDD',
    width: 1,
  },

  ['@media (max-width: 600px)']: {
    headerGallery: {
      height: 'calc(100vw / 3.2)',
    },
    headerStats: {
      display: 'none',
    },
    infoContainer: {
      ['& section']: {
        borderBottom: `1px solid ${grey[300]}`,
        padding: '15px 10px',
        margin: 0,
      }
    },
    multirow: {
      ['& span']: {
        paddingTop: theme.spacing()
      }
    },
    statBig: {
      width: '50% !important',
      ['& span']: {
        fontSize: '18px',
      }
    },
  },

  ['@media (max-width: 750px), (min-width: 900px) and (max-width: 1150px)']: {
    headerGallery: {
      flexDirection: 'column',
      height: 'auto',
      ['& > *']: {
        width: '100%',
      },
    },
    statBigContainer: {
      minWidth: 'auto',
      borderBottom: `1px solid ${grey[300]}`,
    },
    statBigRow: {
      paddingBottom: '1em',
    },
  },
});
import {
  RangeAccessor,
  RangeQuery
} from 'searchkit-react16';

export default class PercentageRangeAccessor extends RangeAccessor {
  /* (this is from when extended DynamicRangeAccessor instead)
  buildOwnQuery(query) {
    const otherFilters = query.getFiltersWithoutKeys(this.key);
    const allFilters = new BoolMust([
      otherFilters,
      this.fieldContext.wrapFilter(new RangeQuery(this.options.field, {
        gte: this.options.min / 100,
        lte: this.options.max / 100,
      }))
    ]);

    let metric;
    if (this.options.loadHistogram) {
      metric = query.HistogramBucket(this.key, this.options.field, {
        'interval': this.options.interval,
        'min_doc_count': 0,
        'extended_bounds': {
          'min': this.options.min,
          'max': this.options.max
        }
      });
    } else {
      metric = new CardinalityMetric(this.key, this.options.field);
    }

    const fb = new FilterBucket(
      this.key,
      allFilters,
      ...this.fieldContext.wrapAggregations(metric)
    );
    return query.setAggs(fb);
  }
  */

  buildSharedQuery(query) {
    const _this = this;
    if (this.state.hasValue()) {
      const val = this.state.getValue();
      const rangeFilter = this.fieldContext.wrapFilter(new RangeQuery(this.options.field, {
        gte: val.min / 100,
        lte: val.max / 100
      }));
      const selectedFilter = {
        name: this.translate(this.options.title),
        value: `${val.min} - ${val.max}`,
        id: this.options.id,
        remove: () => {
          _this.state = _this.state.clear();
        }
      };
      return query
        .addFilter(this.key, rangeFilter)
        .addSelectedFilter(selectedFilter);
    }
    return query;
  }
}

import {
  ONBOARDING_PREVIOUS_STEP,
  ONBOARDING_NEXT_STEP,
  ONBOARDING_COMPLETE,
} from '../constants/actionTypes';

const defaultState = {
  currentStep: 0,
  isComplete: false
};

export default function (state = { ...defaultState }, action) {
  switch (action.type) {
    case ONBOARDING_PREVIOUS_STEP:
      return { ...state, currentStep: (state.currentStep - 1) };

    case ONBOARDING_NEXT_STEP:
      return { ...state, currentStep: (state.currentStep + 1) };

    case ONBOARDING_COMPLETE:
      return { ...state, isComplete: true };

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropertyIcon from '@material-ui/icons/LocationOn';
import LocationIcon from '@material-ui/icons/NearMe';
import VirtualSelect from '../common/VirtualSelect';
import locationApi from '../services/location';
import getLocationTitles from '../common/getLocationTitles';
import styles from './module.css';

const AddLocation = ({ classes, onChange }) => {
  const loadOptions = (searchText) => {
    if (!searchText) return Promise.resolve({ options: [] });
    return locationApi(searchText).then((options) => {
      options = options.map((option) => {
        const { primary, secondary } = getLocationTitles(option);
        return ({
          ...option,
          primary,
          secondary,
          Icon: (option._type === 'geocode') ? LocationIcon : PropertyIcon
        });
      });
      return { options };
    });
  };

  const renderOption = (option) => {
    const { Icon, primary, secondary } = option;
    return (
      <React.Fragment>
        <ListItemIcon className={classes.locationOptionIcon}><Icon /></ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
      </React.Fragment>
    );
  };

  return (
    <Paper className={classes.addLocationContainer} elevation={1}>
      <Typography variant="button" className={classes.addLocationTitle}>Add Location</Typography>
      <VirtualSelect
        className={classes.addLocationInput}
        labelKey="primary"
        margin="dense"
        onChange={onChange}
        onSearch={loadOptions}
        noOptionsText="Type to search"
        placeholder="Search for city, zip, address, or property name…"
        renderOption={renderOption}
        variant="outlined" />
    </Paper>
  );
};

AddLocation.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(AddLocation);

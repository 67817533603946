import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import numeral from 'numeral';
import formatStat from './formatStat';
import styles from './module.css';

const SpaceUses = ({ classes, value, item }) => {
  if (!item) { // for TIM Detail Stat
    return (
      <div>
        {value.map((spaceUse, index) => {
          return (
            <div key={index}>
              {spaceUse.type && <strong>{spaceUse.type}</strong>}
              {spaceUse.area && <span> / {spaceUse.area.toLocaleString()} SF</span>}
              {spaceUse.rate && <span> / ${numeral(spaceUse.rate).format('0,0.00')} (p/sf)</span>}
            </div>
          );
        })}
      </div>
    );
  }
  const { _type } = item;

  const showPriceTotalPsf = (datatype) => {
    const noDisplay =  ['property'];
    return !noDisplay.includes(datatype);
  };

  const showPriceTotal = (datatype) => {
    const noDisplay =  ['lease', 'availability', 'property'];
    return !noDisplay.includes(datatype);
  };

  return (
    <div>
      {value && (
        <Table size="small" className={classes.statTableSpaceUses}>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Area (SF)</TableCell>
              {showPriceTotalPsf(_type) &&
              <TableCell>Price (psf)</TableCell>}
              {showPriceTotal(_type) &&
              <TableCell>Price (Total)</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {value.map((space, key) => {
              return (
                <TableRow key={key} className={classes.statTableRow}>
                  <TableCell>{space.type || '-'}</TableCell>
                  <TableCell>{formatStat(space.area, { format: 'number' }) || '-'}</TableCell>
                  {showPriceTotalPsf(_type) &&
                  <TableCell>{formatStat(space.rate, { format: 'currency' }) || '-'}</TableCell>}
                  {showPriceTotal(_type) && 
                  <TableCell>{formatStat(space.totalPrice, { format: 'currency' }) || '-'}</TableCell>}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>)}
    </div>
  );
};

SpaceUses.propTypes = {
  classes: PropTypes.object,
  item: PropTypes.object,
  value: PropTypes.array
};

export default withStyles(styles)(SpaceUses);

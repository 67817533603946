import { QueryAccessor, ObjectState } from 'searchkit-react16';

export default class AndOrRangeAccessor extends QueryAccessor {
  constructor(field, options = {}) {
    super(field, options);
    this.options = options;
    this.state = new ObjectState({
      andOrChecked: options.andOrChecked || 'AND',
      area: options.area || {},
      minDivisibleArea: options.minDivisibleArea || {},
    });
  }

  buildSharedQuery(query) {
    if (!this.state.hasValue()) return query;
    const value = this.state.getValue();

    if (!value.andOrChecked) {
      this.state = this.state.setValue({
        ...value,
        andOrChecked: 'AND'
      });
    }

    this.options.updateStateWithAccessorValues({
      andOrChecked: value.andOrChecked,
      area: value.area,
      minDivisibleArea: value.minDivisibleArea
    });

    const filters = [];

    if (value.area?.min || value.area?.max) {
      filters.push({
        range: {
          area: {
            ...(value.area.min ? { gte: value.area.min } : {}),
            ...(value.area.max ? { lte: value.area.max } : {})
          }
        }
      });
    }

    if (value.minDivisibleArea?.min || value.minDivisibleArea?.max) {
      filters.push({
        range: {
          minDivisibleArea: {
            ...(value.minDivisibleArea.min ? { gte: value.minDivisibleArea.min } : {}),
            ...(value.minDivisibleArea.max ? { lte: value.minDivisibleArea.max } : {})
          }
        }
      });
    }

    if (filters.length > 0) {
      if (value.andOrChecked === 'AND') {
        return query.addFilter(this.key, { bool: { must: filters } });
      } return query.addFilter(this.key, { bool: { should: filters, ['minimum_should_match']: 1 } });
    }

    return query;
  }
}

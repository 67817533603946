/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import PortfolioIcon from '@material-ui/icons/Folder';
import SummaryFieldStats from '../common/SummaryFieldStats';
import Section from '../common/Section';
import SuiteInfo from '../common/SuiteInfo';
import Features from '../common/Features';
import Notes from '../common/Notes';
import Images from '../common/Images';
import Documents from '../common/Documents';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const CardContent = ({ item }) => {
  const classes = useStyles();
  const {
    saleType,
    rentableBuildingArea,
    price,
    pricePerSquareFoot,
    capRate,
    netOperatingIncome,
    sellerRepresentatives,
    status
  } = item;

  const listingStats = [
    { label: 'Sale Type', value: saleType, format: 'arrayZeroIndex' },
    { label: 'Property Size', value: rentableBuildingArea, format: 'number' },
    { label: 'Asking Price', value: price, format: 'wholeCurrency' },
    { label: 'Asking Price / SF', value: pricePerSquareFoot, format: 'currency' },
    { label: 'Cap Rate', value: capRate, format: 'ratio' },
    { label: 'Net Operating', value: netOperatingIncome, format: 'wholeCurrency' },
    { label: 'Listing Company', value: sellerRepresentatives, format: 'listingCompany', customClass: classes.sourceSummaryEllipsis },
    { label: 'Status', value: status, format: 'string' }
  ];

  return (
    <React.Fragment>
      <SummaryFieldStats stats={listingStats} />
    </React.Fragment>
  );
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired
};

export const ExpandedCardContent = ({ item }) => {
  const classes = useStyles();
  const fieldNotes = item.fieldNotes || {};

  const {
    canonicalName,
    properties,
    portfolioName,
    saleType,
    status,
    sourcedBy,
    sourceUrl,
    description,
    saleTerms,
    rentableBuildingArea,
    floors,
    suite,
    vacant,
    landAreaMin,
    landAreaMax,
    price,
    pricePerSquareFoot,
    capRate,
    netOperatingIncome,
    grossIncome,
    improvementType
  } = item;
  const formattedPropertyTypes = properties && properties[0].propertyTypes ? properties[0].propertyTypes : null;
  const specialTopRowListingInfo = [
    [
      { key: 'canonicalName', label: 'Name', value: canonicalName, format: 'string', customClass: classes.spanThreeColumns },
      { key: 'properties', label: 'Properties', value: properties, format: 'propertyLinks', customClass: classes.spanThreeColumns },
      { key: 'portfolioName', label: 'Portfolio Name', value: portfolioName, format: 'string', customClass: classes.spanThreeColumns }
    ]
  ];

  const listingInfo = [
    [
      { key: 'formattedPropertyTypes', label: 'Property Types', value: formattedPropertyTypes, format: 'array' },
      { key: 'saleType', label: 'Sale Type', value: saleType, format: 'array' },
      { key: 'status', label: 'Status', value: status, format: 'string' },
      { key: 'sourcedBy', label: 'Source', value: sourcedBy, format: 'string' },
      { key: 'sourceUrl', label: 'Source URL', value: sourceUrl, format: 'uri' },
      {
        key: 'description',
        label: 'Description',
        value: description,
        format: 'string',
        customClass: classes.spanThreeColumns
      },
      {
        key: 'saleTerms',
        label: 'Sale Terms',
        value: saleTerms,
        format: 'string',
        customClass: classes.spanThreeColumns
      }
    ],
    [
      { key: 'rentableBuildingArea', label: 'Property Area', value: rentableBuildingArea, format: 'number' },
      { key: 'floors', label: 'Floors', value: floors, format: 'array' },
      { key: 'suite', label: 'Suite', value: suite, format: 'string' },
      { key: 'vacant', label: 'Vacant', value: vacant, format: 'boolean' },
      { key: 'landAreaMin', label: 'Land Area (Min)', value: landAreaMin, format: 'number' },
      { key: 'landAreaMax', label: 'Land Area (Max)', value: landAreaMax, format: 'number' }
    ],
    [
      { key: 'price', label: 'Asking Price', value: price, format: 'currency' },
      { key: 'pricePerSquareFoot', label: 'Asking Price / sq ft', value: pricePerSquareFoot, format: 'currency' },
      { key: 'capRate', label: 'Capitalization Rate', value: capRate, format: 'ratio' },
      { key: 'netOperatingIncome', label: 'Net Operating Income', value: netOperatingIncome, format: 'wholeCurrency' },
      { key: 'grossIncome', label: 'Gross Income', value: grossIncome, format: 'wholeCurrency' },
      { key: 'improvementType', label: 'Improvement Type', value: improvementType, format: 'string' }
    ]
  ];

  const {
    postedDate,
    expirationDate,
    availableDate,
    offMarketDate,
    tourDates
  } = item;
  const datesInfo = [
    [
      { label: 'Date Listed', format: 'date' },
      { key: 'expirationDate', label: 'Expires On', value: expirationDate, format: 'date' }
    ],
    [
      { key: 'postedDate', label: 'On-Market Date', value: postedDate, format: 'date' },
      { key: 'availableDate', label: 'Date Available', value: availableDate, format: 'date' }
    ],
    [
      { key: 'offMarketDate', label: 'Off-Market Date', value: offMarketDate, format: 'date' },
      { key: 'tourDates', label: 'Tour Dates', value: tourDates, format: 'tourDates' }
    ]
  ];

  const { sellers = [], sellerRepresentatives = [] } = item;
  const contactInfo = [
    [{ key: 'sellerRepresentatives', label: 'Seller Reps', value: sellerRepresentatives, format: 'signatories' }],
    [{ key: 'sellers', label: 'Sellers', value: sellers, format: 'signatories' }]
  ];

  const {
    createdAt,
    updatedAt,
    ourDealsId,
    ourListingId,
    coStarId,
    customId,
    legacyId
  } = item;
  const [recordInfo, setRecordInfo] = useState([
    [
      { key: 'createdAt', value: moment(createdAt).format('MMM D, YYYY') || '--', label: 'Created At', format: 'date' },
      { key: 'usableLandArea', value: moment(updatedAt).format('MMM D, YYYY') || '--', label: 'Last Modified', format: 'date' }
    ],
    [
      { key: 'relatedIds', value: ' ', label: 'Related IDs', format: 'string' },
      { key: 'ourDeals', value: ourDealsId, label: 'Our Deals', format: 'string' },
      { key: 'ourListings', value: ourListingId, label: 'Our Listings', format: 'string' },
      { key: 'coStar', value: coStarId, label: 'CoStar', format: 'string' },
      { key: 'legacy', value: legacyId, label: 'Legacy', format: 'string' },
    ],
    [
      { key: 'customIds', value: ' ', label: 'Custom IDs', format: 'string' },
    ]
  ]);

  useEffect(() => {
    if (customId && customId.length > 0) {
      setRecordInfo((prevRecordInfo) => {
        const newRecordInfo = [...prevRecordInfo];
        customId.forEach((item) => {
          const customItem = { key: `customIds_${item.name}`, value: item.id, label: item.name, format: 'string' };
          newRecordInfo[2].push(customItem);
        });
        return newRecordInfo;
      });
    }
  }, []);

  const documents = item.documents || [];
  if (item.flyerUrl) documents.unshift({ title: 'Flyer', url: item.flyerUrl });
  if (item.marketingUrl) documents.unshift({ title: 'Marketing', url: item.marketingUrl });
  const { features, note, images } = item;

  return (
    <div className={classes.expandedView}>
      <Section
        title={'For Sale Info'}
        sectionArray={listingInfo}
        fieldNotes={fieldNotes}
        item={item}
        specialTopRowArray={specialTopRowListingInfo} />
      {documents && documents.length > 0 && (
        <Documents documents={documents} />)}
      <Section
        title={'Dates'}
        sectionArray={datesInfo}
        fieldNotes={fieldNotes} />
      <Section
        title={'Contacts'}
        sectionArray={contactInfo}
        fieldNotes={fieldNotes} />
      <SuiteInfo
        fieldNotes={fieldNotes}
        item={item} />
      <Features
        features={features}
        fieldNote={fieldNotes.features ? fieldNotes.features : null} />
      {note && note.length > 0 &&  (
        <Notes
          note={note}
          fieldNote={fieldNotes.note ? fieldNotes.note : null} />)}
      {images && images.length > 0 &&  (
        <Images
          images={images}
          fieldNote={fieldNotes.images ? fieldNotes.images : null} />)}
      <Section
        title={'Record Info'}
        sectionArray={recordInfo}
        fieldNotes={fieldNotes} />
    </div>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired
};

export const Subtitle = (item) => {
  return item.properties.length > 1 && (
    <div>
      <PortfolioIcon
        style={{
          height: '18px',
          marginRight: '3px',
          position: 'relative',
          top: '4px',
          width: '18px'
        }} />
      Portfolio Sale
    </div>
  );
};

Subtitle.propTypes = {
  item: PropTypes.object.isRequired
};

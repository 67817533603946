import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import isNil from 'lodash/isNil';
import TextField from '@material-ui/core/TextField';

export default class RangeInput extends React.Component {
  static propTypes = {
    max: PropTypes.number,
    maxValue: PropTypes.number,
    min: PropTypes.number,
    minValue: PropTypes.number,
    onFinished: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      max: props.maxValue,
      min: props.minValue,
      touched: false,
    };

    this.stepSize = 1;
    this.roundToDecimals = 0;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // if was not in "reset" state and has been reset...
    if ((this.props.min !== this.props.minValue || this.props.max !== this.props.maxValue)
      && (nextProps.min === nextProps.minValue && nextProps.max === nextProps.maxValue)) {
      this.setState({
        min: nextProps.min,
        max: nextProps.max,
        touched: false
      });

    // else if was in "reset" state and now receiving new props, must be initial props (unless has been touched)
    } else if ((this.props.min === this.props.minValue && this.props.max === this.props.maxValue)
      && (nextProps.min !== nextProps.minValue || nextProps.max !== nextProps.maxValue)
      && !this.state.touched) {
      this.setState({
        min: nextProps.minValue,
        max: nextProps.maxValue,
        touched: !(nextProps.minValue === nextProps.min && nextProps.maxValue === nextProps.max),
      });
    }
  }

  onMinChange = (event) => {
    const value = event.target.value;
    if (value !== this.state.min || !this.state.touched) {
      this.setState({ min: value, touched: true });
    }
  };

  onMaxChange = (event) => {
    const value = event.target.value;
    if (value !== this.state.max || !this.state.touched) {
      this.setState({ max: value, touched: true });
    }
  };

  cleansedValue = (val) => {
    if (isNil(val) || !numeral(val).value()) return null;
    if (this.roundToDecimals) return parseFloat(parseFloat(val).toFixed(this.roundToDecimals));
    return parseInt(val);
  };

  onBlur = () => {
    if (this.state.touched) {
      let min = this.cleansedValue(this.state.min);
      if (min === null || min < this.props.min) {
        min = this.props.min;
      }
      let max = this.cleansedValue(this.state.max);
      if (max === null || max > this.props.max) {
        max = this.props.max;
      }
      this.props.onFinished({ min, max });
      this.setState({ min, max, touched: false });
    }
  };

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.onBlur();
    }
  };

  render() {
    const { min, max } = this.state;
    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          onBlur={this.onBlur}
          onKeyPress={this.onKeyPress}
          name="min"
          onChange={this.onMinChange}
          placeholder="Min"
          size="small"
          type="number"
          value={min === this.props.min ? '' : min}
          variant="outlined"
          inputProps={{ step: this.stepSize }}
          InputProps={{ style: { borderRadius: 0 } }} />
        <span style={{ padding: '0 10px' }}>-</span>
        <TextField
          onBlur={this.onBlur}
          onKeyPress={this.onKeyPress}
          name="max"
          onChange={this.onMaxChange}
          placeholder="Max"
          size="small"
          type="number"
          value={max === this.props.max ? '' : max}
          variant="outlined"
          inputProps={{ step: this.stepSize }}
          InputProps={{ style: { borderRadius: 0 } }} />
      </span>
    );
  }
}

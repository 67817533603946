import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

const TermAndAreaByYearChart = (props) => {
  const {
    classes,
    items,
    legend,
    colors,
    chartImg,
    animationDuration,
    onAnimationComplete
  } = props;

  // analyze the items (selectedResults from the map view)
  const signedAreas = [];
  const signedTerms = [];
  if (items && items.length > 0) {
    // rate by year
    const signedAreaStats = {};
    const signedTermStats = {};
    for (const item of items) {
      if (item._source && item._source.signedDate) {
        const signedYear = item._source.signedDate.split('-').shift();
        if (signedYear && parseInt(signedYear) > 0) {
          if (item._source.area > 0) {
            if (Object.keys(signedAreaStats).indexOf(signedYear) === -1) {
              signedAreaStats[signedYear] = { sum: 0, tally: 0 };
            }
            signedAreaStats[signedYear].sum += item._source.area;
            signedAreaStats[signedYear].tally++;
          }
          if (item._source.leaseTerm > 0) {
            if (Object.keys(signedTermStats).indexOf(signedYear) === -1) {
              signedTermStats[signedYear] = { sum: 0, tally: 0 };
            }
            signedTermStats[signedYear].sum += item._source.leaseTerm;
            signedTermStats[signedYear].tally++;
          }
        }
      }
    }
    for (const year in signedAreaStats) {
      signedAreas.push({ x: year, y: (signedAreaStats[year].sum / 1000) / signedAreaStats[year].tally });
    }

    // NOTE: there is a bias based on last-signed-date against shorter terms (because will disappear)
    for (const year in signedTermStats) {
      signedTerms.push({ x: year, y: signedTermStats[year].sum / signedTermStats[year].tally });
    }
  }

  // get maxes of each scale to help set the y-axis
  let maxArea = 0;
  for (const dataPoint of signedAreas) {
    maxArea = (dataPoint.y > maxArea) ? dataPoint.y : maxArea;
  }

  let maxTerm = 0;
  for (const dataPoint of signedTerms) {
    maxTerm = (dataPoint.y > maxTerm) ? dataPoint.y : maxTerm;
  }

  // init chartRef
  let chartRef = null;

  // return the element
  return (
    <div>
      <Line
        ref={(chart) => { chartRef = chart; }}
        data={{
          datasets: [{
            label: 'Avg Term',
            yAxisID: 'term',
            data: signedTerms,
            lineTension: 0,
            pointRadius: 3,
            borderColor: colors[0],
            borderWidth: 3,
            backgroundColor: 'transparent'
          }, {
            label: 'Avg Sq Footage',
            yAxisID: 'area',
            data: signedAreas,
            lineTension: 0,
            pointRadius: 3,
            borderColor: colors[1],
            borderWidth: 3,
            backgroundColor: 'transparent'
          }]
        }}
        options={{
          scales: {
            xAxes: [{
              type: 'linear',
              ticks: { stepSize: 1 }
            }],
            yAxes: [{
              id: 'term',
              scaleLabel: { display: true, labelString: 'Mo', fontStyle: 'bold' },
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                suggestedMax: Math.ceil(1.05 * maxTerm) // 5% more than the highest value in data
              }
            },{
              id: 'area',
              scaleLabel: { display: true, labelString: '(k) SF', fontStyle: 'bold' },
              type: 'linear',
              position: 'right',
              ticks: {
                beginAtZero: true,
                suggestedMax: Math.ceil(1.05 * maxArea) // 5% more than the highest value in data
              },
              gridLines: {
                display: false
              }
            }]
          },
          tooltips: {
            callbacks: {
              label: (tooltipItem, data) => {
                // term
                if (tooltipItem.datasetIndex === 0) {
                  return `${data.datasets[tooltipItem.datasetIndex].label}: `
                          + `${Math.round(100 * tooltipItem.yLabel) / 100} mo`;
                }

                // area
                return `${data.datasets[tooltipItem.datasetIndex].label}: `
                        + `${(Math.round(100 * tooltipItem.yLabel) * 10).toLocaleString()} SF`;
              }
            }
          },
          animation: {
            duration: animationDuration || 1000,
            onComplete: () => {
              if (onAnimationComplete) {
                onAnimationComplete(chartRef.chartInstance);
              }
            }
          },
          legend: legend ? legend : {}
        }} />
      {chartImg && <img src={chartImg} className={classes.canvasImg} data-canvas />}
    </div>
  );
};

TermAndAreaByYearChart.propTypes = {
  animationDuration: PropTypes.number,
  chartImg: PropTypes.string,
  classes: PropTypes.object,
  colors: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  legend: PropTypes.object,
  onAnimationComplete: PropTypes.func
};
  
export default TermAndAreaByYearChart;
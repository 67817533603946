import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Link from '@material-ui/core/Link';
import { RegisterUser } from '@colliers-international/usage-tracker';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import { jwtDecode } from 'jwt-decode';
import Loading from '../common/Loading';
import { history } from '../store';
import { ability } from './ability';
import { fetchUser, processGuestLink, setToken } from './actions';

function isTokenValid(token) {
  if (token) {
    try {
      const { exp = 1 } = jwtDecode(token, { json: true });
      return (exp * 1000) > Date.now();
    } catch (error) {
      console.warn(error);
    }
  }
  return false;
}

function Authentication({
  children,
  currentUser,
  fetchUser,
  loginError,
  processGuestLink,
  setToken,
  token
}) {
  const auth0 = useAuth0();

  useEffect(() => {
    if (!token) {
      // if auth0 library is loaded...
      if (!auth0.isLoading && !auth0.error) {
        // if freshly authed, set token
        if (auth0.isAuthenticated) {
          auth0.getIdTokenClaims().then((newToken) => {
            const updatedToken = (newToken || {}).__raw;
            if (updatedToken !== token) {
              setToken(updatedToken);
            }
          });

          // else, login
        } else {
          auth0.loginWithRedirect();
        }
      }
    }
  }, [auth0.isAuthenticated, auth0.isLoading, auth0.error, token]);

  useEffect(() => {
    if (token) {
      if (isTokenValid(token)) {
        fetchUser();
      } else {
        setToken(null);
      }
    }
  }, [token]);

  const safeUser = (currentUser || {});
  useEffect(() => {
    if (safeUser.id) {
      if (safeUser.isGuest && Boolean(safeUser.guestLink)) {
        const savedSearch = get(safeUser, 'guestLink.savedSearch');
        if (savedSearch) {
          processGuestLink(savedSearch);
          if (savedSearch.urlHash) {
            history.push({ pathname: '/research', hash: savedSearch.urlHash });
          }
        }
      }
    }
  }, [
    safeUser.id,
    safeUser.isGuest,
    Boolean(safeUser.guestLink)
  ]);

  const handleTryAgain = () => {
    window.localStorage.clear();
    auth0.logout({ returnTo: window.location.origin });
  };

  const shouldRenderChildren = currentUser && token && ability.defaultTenantId;

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {shouldRenderChildren && (
        <RegisterUser
          enableTracking={process.env.REACT_APP_TRACKING}
          matomoSiteId={process.env.REACT_APP_MATOMO_SITE_ID}
          fullstoryOrgId={process.env.REACT_APP_FULLSTORY_ORG}
          user={{
            userId: currentUser.providerId,
            displayName: currentUser.name,
            email: currentUser.email,
            userVars: currentUser }}>
          {children}    
        </RegisterUser>)}
      {!shouldRenderChildren && (
        <React.Fragment>
          {(loginError || auth0.error)
            ? (
              <div style={{ textAlign: 'center', paddingTop: 50 }}>
                <Typography variant="h6">Authentication Error</Typography>
                <Typography>
                  <Link onClick={handleTryAgain}>Click here to try again</Link> or report the problem if it persists.
                </Typography>
                <Typography
                  color="error"
                  display="block"
                  variant="caption"
                  style={{ fontWeight: 'bold', marginTop: 6 }}>
                  {loginError || 'Auth0 was unable to load.'}
                </Typography>
              </div>
            )
            : <Loading message="Please wait, application loading..." />
          }
        </React.Fragment>
      )}
    </div>
  );
}

Authentication.propTypes = {
  children: PropTypes.node,
  currentUser: PropTypes.object,
  fetchUser: PropTypes.func.isRequired,
  loginError: PropTypes.string,
  processGuestLink: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,
  token: PropTypes.string
};

export default connect(
  (state) => ({
    currentUser: state.auth.currentUser || null,
    loginError: state.auth.loginError,
    token: state.auth.token,
  }),
  { fetchUser, processGuestLink, setToken }
)(Authentication);

import { AbilityBuilder, Ability } from '@casl/ability';
import { packRules } from '@casl/ability/extra';
import { getHeaders } from '../services/api';

const getCuratePermissions = async () => {
  try {
    return fetch(`${process.env.REACT_APP_API_URL}/curate`, {
      headers: {
        ...getHeaders()
      }
    });
  } catch (error) {
    return { error };
  } 
};

export const getCurateAbilities = async () => {
  const tenants = [];
  const ruleSet = new Set();
  const response = await getCuratePermissions();
  (await response.json()).forEach(({ tenant, permissions }) => {
    tenants.push(tenant);
    permissions.forEach(({ action, resource }) => { 
      ruleSet.add([action, resource, tenant.id].join(':'));     
    });
  });

  if (ruleSet.size === 0) {
    return {};
  }
  const { rules, can } = new AbilityBuilder();
  const permissions = Array.from(ruleSet).map((packedRule) => (packedRule.split(':')));
  permissions.forEach((unpackedRule) => {
    const [action, resource, tenantId] = unpackedRule;
    can(action,resource,tenantId);
  });
  const ability = new Ability(rules);
  
  return {
    ability,
    getAbilityTenants: (action, object) => tenants
      .filter(({ id }) => ability.can(action, object, id))
      .map(({ id }) => id),
    permissions: packRules(ability.rules),
    tenants
  };
};

import grey from '@material-ui/core/colors/grey';
import { COLLIERS_TEAL } from '../../../styles/theme';

export default (theme) => ({
  headerHeroImageBackground: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'blur(10px)',
    zIndex: 1,
    opacity: 0.40,
    height: '100%',
    width: '100%',
    position: 'static',
    margin: '0 auto'
  },
  headerHeroImage: {
    display: 'flex',
    justifyContent: 'center',
    height: 550,
    margin: '0 auto',
    backgroundColor: 'white',
    position: 'relative',
    top: -565,

    ['& > img']: {
      objectFit: 'contain',
      zIndex: 2,
      opacity: 1,

    },
  },
  
  datatypeMenuStickyWrapper: {
    top: '0',
    zIndex: 'flex-start',
    backgroundColor: 'white',
    boxShadow: 'unset'
  },

  datatypeMenuNav: {
    width: '100%',
    marginLeft: 'unset !important',
    position: 'relative',
    padding: '12px 0px 0px 0px !important',
    overflow: 'hidden',
  },
  datatypeMenuTabIndicatorProps: {
    bottom: 'unset',
    left: '0',
    top: '0',
    height: '12px',
    transition: 'unset',
  },

  datatypeMenuBox: {
    color: 'white'
  },

  datatypeMenuTabs: {
    backgroundColor: '#6E6E70',
    width: '100%',
    ['& .MuiTabs-scroller']: {
      position: 'unset'
    },
  },

  datatypeMenuTab: {
    opacity: 'unset',
    fontWeight: '600',
    fontSize: '16px',
    padding: '6px 8px',
    ['& .MuiTab-wrapper']: {
      opacity: 'unset',
    },
    [theme.breakpoints.down(1800)]: {
      fontSize: theme.spacing(1.5),
      minWidth: 'fit-content',
    },
    [theme.breakpoints.down(1500)]: {
      padding: '6px 24px'
    }
  },

  datatypeMenuTabInactive: {
    fontWeight: '600',
    fontSize: theme.spacing(2),
    opacity: 'unset !important',
    padding: '6px 8px',
    ['& .MuiTab-wrapper']: {
      opacity: '0.36',
    },
    [theme.breakpoints.down(1800)]: {
      fontSize: theme.spacing(1.5),
      minWidth: 'fit-content',
    },
    [theme.breakpoints.down(1500)]: {
      padding: '16px 24px'
    }
  },

  datatypesMenuTabAvatar: {
    height: 28,
    width: 28,
    margin: '0 !important',
    padding: '4px',
    [theme.breakpoints.down(1500)]: {
      height: 32,
      width: 32
    }
  },

  datatypesMenuTabIcon: {
    height: 28,
    width: 28,
    padding: '4px',
    [theme.breakpoints.down(1500)]: {
      height: 32,
      width: 32
    }
  },

  headerPropertStatDataMarkets: {
    fontWeight: 300,
    fontSize: theme.spacing(2.75),
    color: '#254091',
    alignSelf: 'center',
    [theme.breakpoints.down(1280)]: {
      maxWidth: '70px',
    },
    [theme.breakpoints.up(1280)]: {
      maxWidth: '140px'
    },
    [theme.breakpoints.down(1500)]: {
      maxWidth: '140px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: '16px'
    },
    [theme.breakpoints.up(1440)]: {
      maxWidth: '300px'
    },
    [theme.breakpoints.up(1550)]: {
      maxWidth: 'fit-content'
    },
  },
  parcelLabel: {
    marginRight: '5px',
    flexShrink: 0,
    width: '25%'
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  detailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.4rem',
    width: '100%'
  },
  label: {
    fontSize: '12px',
    marginRight: '5px',
    flexShrink: 0
  },

  labelContainer: {
    display: 'flex'
  },
  value: {
    fontSize: '12px',
    overflow: 'hidden',
    textAlign: 'right',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '100%'
  },
  popupContainer: {
    width: '440px',
  },
  recordedSalesSummary: {
    backgroundColor: COLLIERS_TEAL,
    color: 'white',
    fontWeight: 'bold',
  },
  loadingPopup: {
    height: '50px',
    padding: theme.spacing(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  ourDealsLogo: {
    width: '35px',
    position: 'absolute',
    top: '12px',
    right: '8px'
  },
  ownerValue: {
    marginLeft: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '50%'
  },
  parcelPopup: {
    width: '440px',
    maxHeight: '650px',
    backgroundColor: 'white',
    ['& header']: {
      padding: theme.spacing(),
      height: '56px',
    },
    ['& h4']: {
      color: 'white !important',
      textDecoration: 'underline !important',
      fontSize: '120% ',
      fontWeight: 'bold !important',
      margin: 0,
    },
    ['& h5']: {
      color: 'white',
      textDecoration: 'underline',
      fontWeight: 'normal',
      margin: 0,
    },
    ['& article']: {
      fontSize: '50%',
      maxHeight: '180px',
      overflowY: 'scroll'
    },
    ['& .MuiAccordion-root.Mui-expanded']: {
      margin: 0
    }
  },
  parcelPopupHeader: {
    'cursor': 'pointer',
    'color': 'white'
  },
  popup: {
    ['& header']: {
      padding: theme.spacing(),
    },
    ['& h4']: {
      color: grey[800],
      fontSize: '120%',
      fontWeight: 'normal',
      margin: 0,
    },
    ['& h5']: {
      color: grey[500],
      fontWeight: 'normal',
      margin: 0,
    },
    ['& article']: {
      fontSize: '50%',
      maxHeight: '180px',
      overflowY: 'scroll'
    },
  },
  popupContent: {
    padding: theme.spacing(),
    ['.MuiAccordionDetails-root']: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
      maxHeight: '400px',
    }
  },
  popupContentRow: {
    paddingBottom: '8px',
  },
  popupContentRowItem: {
    display: 'flex',
    alignItems: 'center'
  },
  popupHeader: {
    cursor: 'pointer',
  },
  popupPanelRow: {
    width: 'inherit',
    paddingLeft: '8px',
    paddingBottom: '12px',
  },
  propertyColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  propertyContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    lineHeight: '1.4rem',
    gap: '92px',
  },
  propertyLabel: {
    marginRight: '16px',
  },
  heading: {
    fontSize: '.5rem'
  },
  recordedSalesTableHeader: {
    fontSize: '12px', fontFamily: 'Helvetica Neue, Arial, Helvetica, sans-serif',
    fontWeight: '400',
    lineHeight: '1.4px',
    paddingBottom: '8px',
    borderBottom: '0'
  },
  recordedSalesTableCell: {
    fontSize: '12px', fontFamily: 'Helvetica Neue, Arial, Helvetica, sans-serif',
    lineHeight: '1.4px',
    borderBottom: '0',
    fontWeight: 'bold'
  },
  situsAddress: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '65%'
  },
  parcelValue: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '65%'
  }
});

import { FullStoryAPI as fullStoryAPI } from 'react-fullstory';
import fileSaver from 'file-saver';
import { EVENTS as eventNames } from '../../../constants/fullstory.js';
import {
  DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_REQUEST,
  DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_SUCCESS,
  DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_ERROR,
  DETAIL_DEMOGRAPHICS_REPORT_DOWNLOAD_REQUEST,
  DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_SUCCESS,
  DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_ERROR,
  DETAIL_DEMOGRAPHIC_REPORT_ENABLE_VIEW,
  DETAIL_DEMOGRAPHIC_REPORT_DISABLE_VIEW
} from '../../../constants/actionTypes';
import {
  handleError,
  unauthorizedRequest,
  requestData,
  receiveData
} from '../../../common/apiActions';
import api from '../../../services/api';

export function fetchDemographicsReportPreview({ reportId, ids, property }) {
  const { address, hash, location } = property;
  const lat = location[1];
  const lng = location[0];
  return (dispatch) => {
    dispatch(requestData(DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_REQUEST, { ids }));
    return api.readBinary(`/report/${reportId}/build`, null, {
      address,
      hash,
      lat,
      lng,
      type: 'demographics'
    })
      .then((data) => data.blob())
      .then((blob) => blob.slice(0, blob.size, 'application/pdf'))
      .then((blob) => window.URL.createObjectURL(blob))
      .then((dataUri) => {
        dispatch(receiveData(DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_SUCCESS, { dataUri }));
      })
      .catch((response) => {
        if (response.status === 401) return dispatch(unauthorizedRequest());
        return dispatch(handleError(DETAIL_DEMOGRAPHIC_REPORT_PREVIEW_ERROR, response));
      });
  };
}

export function enableViewReport() {
  return {
    type: DETAIL_DEMOGRAPHIC_REPORT_ENABLE_VIEW
  };
}

export function disableViewReport() {
  return {
    type: DETAIL_DEMOGRAPHIC_REPORT_DISABLE_VIEW
  };
}

export function downloadDemographicsReport(address, hash, lng, lat, report) {
  return (dispatch) => {
    dispatch(requestData(DETAIL_DEMOGRAPHICS_REPORT_DOWNLOAD_REQUEST, { report }));
    return api.readBinary(`/report/${report.id}/build`, null, {
      address,
      hash,
      lat,
      lng,
      type: report.dataTypes[0]
    })
      .then((data) => data.blob())
      .then((blob) => {
        fileSaver.saveAs(blob, `${report.title}.pdf`, { type: 'application/pdf' });
        fullStoryAPI(eventNames.EVENT,
          eventNames.PDF_DOWNLOAD, { 'pdfDownload_str': eventNames.PDF_DOWNLOAD_CLICKED });
      })
      .then(() => dispatch({ type: DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_SUCCESS }))
      .catch((response) => {
        if (response.status === 401) {
          return dispatch(unauthorizedRequest());
        }

        return dispatch(handleError(DETAIL_DEMOGRAPHIC_REPORT_DOWNLOAD_ERROR));
      });
  };
}

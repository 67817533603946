import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DemographicsIcon from '@material-ui/icons/InsertChart';
import { ability } from '../../../auth/ability';
import translate from '../../../common/translate';
import datatypes from '../../../common/datatypes';
import Avatar from '@material-ui/core/Avatar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '@material-ui/core/AppBar';
import PropertyStats from './PropertyStats';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css';

const PropertySubheader = ({
  allNearbyItems,
  classes,
  params, property,
  nav,
  datatypeInfo
}) => {
  const accent3Color = '#b0bec5';
  // Sets Order for Menu
  const contentSections = { 
    property: {},
    lease: {},
    availability: {},
    sale: {},
    listing: {},
    development: {},
  };
  if (ability.can('read', 'demographics', ability.defaultTenantId)) {
    contentSections.demographics = {
      Icon: DemographicsIcon,
      label: 'Demographics',
      color: '#AD745B',
    };
  }

  const visibleDatatypes = datatypeInfo.reduce((memo, d) => {
    if (d === 'tim') return memo;

    const { Icon, color, plural } = datatypes[d];
    memo[d] = { Icon, color, label: plural, nearby: true };
    return memo;
  }, {});
  Object.keys(visibleDatatypes).forEach((key) => {
    contentSections[key] = { ...visibleDatatypes[key] };
  });

  // Datatypes that are not disabled, demographics added to end of array.
  const availableDatatypes = Object.keys(contentSections)
    .filter((key) => (
      (key === 'property') ? true : (property[key] || []).length > 0) 
      || (allNearbyItems && key in allNearbyItems))
    .map((key) => datatypes[key].key);
  availableDatatypes.push('demographics');

  const [currentDatatype, setCurrentDatatype] = useState(params.datatype || window.location.pathname
    .split('/')
    .find((element) => contentSections[element])
    || 'property');
  const [datatypeColor, setDatatypeColor] = useState(contentSections[currentDatatype].color);

  useEffect(() => {
    const initialDatatype = params.datatype || window.location.pathname
      .split('/')
      .find((element) => contentSections[element])
    || 'property';
    setCurrentDatatype(initialDatatype);
    if (contentSections[params.datatype]) {
      setDatatypeColor(contentSections[params.datatype].color);
    }
  }, [params.datatype, contentSections]);
  
  const handleChange = (event, newDatatype) => {
    setCurrentDatatype(newDatatype);
    setDatatypeColor(contentSections[newDatatype].color);
  };

  const minWidth = useMediaQuery('(min-width:1500px)'); // Used for Tooltip on Nav Menu Icons & Tab Labels
  const showDatatypeLabels = (key, visiblePlural, tooltip) => {
    if (!minWidth && !tooltip) return null;
    return key === 'property' ? 'PROPERTY' : visiblePlural.toUpperCase();
  };

  const { images } = property;
  return (
    <React.Fragment>
      {currentDatatype === 'property' && images && images.length > 0 &&
      <div style={{ position: 'static', width: '100%', height: 625, padding: '20px 0', marginBottom: '10px' }}>
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
          <div className={classes.headerHeroImageBackground} style={{ backgroundImage: `url(${images[0]})` }} />
        </div>
        <div className={classes.headerHeroImage}>
          <img src={images[0]} />
        </div>
      </div>}
      <PropertyStats
        property={property} />
      <AppBar
        position="sticky"
        className={classes.datatypeMenuStickyWrapper}>
        <nav
          className={classes.datatypeMenuNav}
          style={{ borderBottom: `${datatypeColor} 12px solid` }}>
          <Box className={classes.datatypeMenuBox}>
            <Tabs
              className={classes.datatypeMenuTabs}
              value={currentDatatype}
              onChange={handleChange}
              TabIndicatorProps={{
                style: { background: `${datatypeColor}` },
                className: classes.datatypeMenuTabIndicatorProps }}
              variant="fullWidth">
              {Object.keys(contentSections)
                .map((key) => {
                  if (Object.keys(contentSections[key]).length === 0) return null;
                  const { color = accent3Color, Icon } = contentSections[key];
                  const visiblePlural = datatypes[key]
                    ? datatypes[key].plural
                    : translate(key);
                  if (!availableDatatypes.includes(key) && (key === 'news' || key === 'company')) return null;
                  return (
                    <Tab
                      key={key}
                      className={(availableDatatypes.includes(key))
                        ? classes.datatypeMenuTab
                        : classes.datatypeMenuTabInactive}
                      icon={(
                        minWidth
                          ? (
                            <Avatar
                              className={classes.datatypesMenuTabAvatar}
                              style={currentDatatype && currentDatatype === key
                                ? { backgroundColor: color }
                                : { backgroundColor: '#6E6E70',  }}>
                              <Icon className={classes.datatypesMenuTabIcon} />
                            </Avatar>
                          ) : (
                            <Tooltip
                              title={showDatatypeLabels(key, visiblePlural, true)}
                              placement="bottom">
                              <Avatar
                                className={classes.datatypesMenuTabAvatar}
                                style={currentDatatype && currentDatatype === key
                                  ? { backgroundColor: color }
                                  : { backgroundColor: '#6E6E70',  }}>
                                <Icon className={classes.datatypesMenuTabIcon} />
                              </Avatar>
                            </Tooltip>
                          )
                      )}
                      onClick={nav(key)}
                      disableRipple
                      disabled={!(availableDatatypes.includes(key))}
                      value={key}
                      label={showDatatypeLabels(key, visiblePlural)}
                      style={currentDatatype && currentDatatype === key
                        ? { backgroundColor: color }
                        : { backgroundColor: '#6E6E70' }} />);
                })}
            </Tabs>
          </Box>
        </nav>
      </AppBar>
    </React.Fragment>
  );
};

PropertySubheader.propTypes = {
  allNearbyItems: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  datatypeInfo: PropTypes.array.isRequired,
  nav: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  property: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertySubheader);

import React from 'react';
import PropTypes from 'prop-types';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateIoMoment from '@date-io/moment';
import moment from 'moment';

export default class DateField extends React.Component {
  static propTypes = {
    autoFocus: PropTypes.bool,
    initialFocusedDate: PropTypes.number,
    label: PropTypes.node,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      date: new Date(props.value)
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ date: newProps.value ? new Date(newProps.value) : null });
  }

  onChange = (date) => {
    if (date === null || (date && date.isValid())) {
      this.setState({ date });
      this.props.onChange(date);
    }
  };

  onBlur = (event) => {
    // only update value onBlur if needs to be nullified (i.e. not valid)
    // if value is valid, will have been updated onChange already
    if (event.target.value.includes('_') || !moment(event.target.value).isValid()) {
      this.setState({ date: null });
      this.props.onChange(null);
    }
  };

  render() {
    const { initialFocusedDate } = this.props;
    return (
      <div style={{ alignItems: 'baseline', display: 'flex', flex: 1 }}>
        <MuiPickersUtilsProvider utils={DateIoMoment}>
          <KeyboardDatePicker
            autoFocus={Boolean(this.props.autoFocus)}
            clearable
            emptyLabel="MM-DD-YYYY"
            format="MM-DD-YYYY"
            fullWidth
            initialFocusedDate={initialFocusedDate}
            inputProps={{ style: { color: '#9e9e9e', fontSize: '90%' } }}
            label={this.props.label}
            onBlur={this.onBlur}
            onChange={this.onChange}
            name={this.props.name}
            value={this.state.date}
            views={['year', 'month', 'date']}
            KeyboardButtonProps={{ size: 'small' }} />
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default (theme) => ({
  column: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: -1,
    userSelect: 'none'
  },
  columns: {
    padding: 0,
    height: '66vh',
    overflow: 'auto'
  }
});

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { List as VirtualizedList } from 'react-virtualized';
import { GUTTER_SIZE, LIST_ITEM_HEIGHT, LIST_WIDTH } from '../constants';
import mediaQuery from '../../common/mediaQuery';
import ListHeader from './ListHeader';
import ListItem from './ListItem';
import ListMode from './ListMode';
import styles from './module.css';
import datatypes from '../../common/datatypes';
import { blueGrey } from '@material-ui/core/colors';
import ShowSelectedIcon from '@material-ui/icons/PlaylistAddCheck';

class MapList extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    containerHeight: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired,
    currentListView: PropTypes.string,
    disableListMode: PropTypes.func.isRequired,
    enabledSearches: PropTypes.object.isRequired,
    hasListError: PropTypes.bool,
    hideAllFilters: PropTypes.func.isRequired,
    isSearching: PropTypes.bool.isRequired,
    listErrorMessage: PropTypes.string,
    popup: PropTypes.string,
    results: PropTypes.array.isRequired,
    searches: PropTypes.object.isRequired,
    selectedProperty: PropTypes.string,
    selectedResults: PropTypes.object.isRequired,
    selectionAnchor: PropTypes.object,
    setSelectionAnchor: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    toggleSelectedItem: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.containerHeight = props.containerHeight;
    this.popoverRef = React.createRef();
    mediaQuery('(max-width: 600px)', (match) => {
      this.setState({ collapsed: !!match });
    });
  }

  state = {};

  collapseList = () => {
    this.setState({ collapsed: true });
  };

  expandList = () => {
    this.setState({ collapsed: false });
  };
 
  onSelect = (item, select, isShiftKey) => {
    const {
      currentListView,
      results,
      selectedResults,
      selectionAnchor,
      setSelectionAnchor,
      toggleSelectedItem } = this.props;

    // if selecting a range (and has an anchor of the same type)
    if (isShiftKey && selectionAnchor && selectionAnchor._type === item._type) {
      // and range is non-zero in length
      if (selectionAnchor._id !== item._id) {
        // select all unselected in the range...
        let inRange = false;
        const alreadySelectedIds = (selectedResults[currentListView] || []).map((x) => x._id);
        results.forEach((result) => {
          const isEdgeItem = (result._id === selectionAnchor._id || result._id === item._id);
          if (isEdgeItem) {
            inRange = !inRange;
          }
          if (inRange || isEdgeItem) {
            if (!alreadySelectedIds.includes(result._id)) {
              toggleSelectedItem(result, true, result._type);
            }
          }
        });
        return;
      }
    }

    // update state
    toggleSelectedItem(item, select, item._type);

    // if selecting and not holding shift w/ existing anchor, set anchor
    if (select && !(selectionAnchor && isShiftKey)) {
      setSelectionAnchor(item);

      // if unselecting the selection anchor, unset anchor
    } else if (selectionAnchor && !select && item._id === selectionAnchor._id) {
      setSelectionAnchor(null);
    }
  };

  render() {
    const props = this.props;
    const { color, Icon, plural } = datatypes[props.currentListView] || {
      color: blueGrey[500],
      Icon: ShowSelectedIcon,
    };
    const {
      classes,
      currentListView,
      disableListMode,
      enabledSearches,
      hasListError,
      isSearching,
      listErrorMessage,
      theme,
      results,
      popup,
      selectedProperty,
    } = props;

    let scrollIndex = 0;
    const selectedResults = props.selectedResults[currentListView] || [];
    let containerHeight = this.containerHeight - 20;
    mediaQuery('(max-width: 600px)', (match) => match && (containerHeight += 20));
    const listHeight = Math.min(
      results.length * (
        currentListView === 'news' ||
          currentListView === 'tim' ||
          currentListView === 'company' ?
          140 : LIST_ITEM_HEIGHT
      ),
      containerHeight - theme.mixins.toolbar.minHeight - 100
    );

    const isSelected = (result) => selectedResults.map((r) => r._id).indexOf(result._id) > -1;
    const onSelectRow = this.onSelect;

    if (selectedProperty) {
      scrollIndex = results.findIndex((item) => {
        const property = item._source.property || item._source.id || item._source.properties[0] || {};
        const propertyId = property.id || property || '';
        return propertyId === selectedProperty;
      });
    } else if (popup) {
      scrollIndex = results.findIndex((item) => {
        const property = item._source.property || item._source.hash || item._source.properties[0] || {};
        const propertyHash = property.hash || property || '';
        return propertyHash === popup;
      });
    }

    const handleDisable = () => {
      disableListMode(currentListView);
    };

    const listData = props.searches[currentListView] || {};

    return (
      <Paper
        ref={this.popoverRef}
        className={this.state.collapsed ? classes.listContainerCollapsed : classes.listContainer}
        style={{
          right: 0,
          maxHeight: `${containerHeight - (GUTTER_SIZE * 2)}px`,
          width: `${LIST_WIDTH}px`,
          position: 'absolute',
        }}
        elevation={1}>
        <ListHeader
          popoverRef={this.popoverRef}
          collapseList={this.collapseList}
          enabledSearches={enabledSearches}
          expandList={this.expandList}
          hasListError={hasListError}
          isSearching={isSearching}
          currentListView
          listErrorMessage={listErrorMessage}
          {...{ color, Icon, plural }}
          {...this.props} />
        {props.searches[currentListView].listMode &&
          <ListMode
            count={listData.listCount}
            name={listData.listTitle}
            onDisable={handleDisable} />
        }
        <VirtualizedList
          className={classes.list}
          width={LIST_WIDTH}
          rowHeight={
            (currentListView === 'news' || currentListView === 'tim' || currentListView === 'company')
              ? 140 : LIST_ITEM_HEIGHT}
          height={listHeight}
          overscanRowCount={0}
          rowCount={results.length}
          scrollToIndex={scrollIndex}
          // scrollToRow={scrollIndex}
          rowRenderer={(rowProps) => {
            return (
              <ListItem
                {...rowProps}
                {...props}
                color={color}
                isSearching={isSearching}
                isSelected={isSelected}
                onSelect={onSelectRow}
                result={results[rowProps.index]} 
                selectedProperty={selectedProperty || null} />
            );
          }} />
      </Paper>
    );
  }
}

export default withStyles(styles)(withTheme(MapList));

import React from 'react';
import PropTypes from 'prop-types';
import BikeIcon from '@material-ui/icons/DirectionsBike';
import TransitIcon from '@material-ui/icons/DirectionsTransit';
import WalkIcon from '@material-ui/icons/DirectionsWalk';
import walkscore from '../services/walkscore';
import styles from './walkscore.module.css';

class Walkscore extends React.Component {
  constructor(props) {
    super(props);
    this.state = { walkscore: null };
  }

  componentDidMount() {
    this._isFetching = true;
    walkscore(this.props.location).then((result) => {
      if (this._isFetching) {
        this._isFetching = false;
        this.setState({ walkscore: result });
        return;
      }
    });
  }

  componentWillUnmount() {
    this._isFetching = false;
  }

  render() {
    const walkscoreData = this.state.walkscore;
    const containerStyles = [styles.walkscoreContainer];

    if (this.props.className) {
      containerStyles.push(this.props.className);
    }

    return (
      <div className={containerStyles.join(' ')}>
        {walkscoreData && walkscoreData.walkscore && (
          <div className={[styles.c100, styles[`p${walkscoreData.walkscore}`], styles.walk].join(' ')}>
            <span>
              <WalkIcon className={styles.icon} />
              <h4>Walk Score&reg;</h4>
              <div className={styles.score}>{walkscoreData.walkscore}</div>
            </span>
            <div className={styles.slice}>
              <div className={styles.bar} />
              <div className={styles.fill} />
            </div>
          </div>
        )}
        {(walkscoreData && walkscoreData.transit && walkscoreData.transit.score) && (
          <div className={[styles.c100, styles[`p${walkscoreData.transit.score}`], styles.transit].join(' ')}>
            <span>
              <TransitIcon className={styles.icon} />
              <h4>Transit Score&reg;</h4>
              <div className={styles.score}>{walkscoreData.transit.score}</div>
            </span>
            <div className={styles.slice}>
              <div className={styles.bar} />
              <div className={styles.fill} />
            </div>
          </div>
        )}
        {(walkscoreData && walkscoreData.bike && walkscoreData.bike.score) && (
          <div className={[styles.c100, styles[`p${walkscoreData.bike.score}`], styles.bike].join(' ')}>
            <span>
              <BikeIcon className={styles.icon} />
              <h4>Bike Score&reg;</h4>
              <div className={styles.score}>{walkscoreData.bike.score}</div>
            </span>
            <div className={styles.slice}>
              <div className={styles.bar} />
              <div className={styles.fill} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

Walkscore.propTypes = {
  className: PropTypes.string,
  location: PropTypes.array.isRequired,
};

export default Walkscore;

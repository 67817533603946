import { grey } from '@material-ui/core/colors';

export default (theme) => ({

  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '48%',
  },

  detailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    lineHeight: '1.2rem'
  },

  label: {
    marginRight: '5px',
    flexShrink: 0,
    textWrap: 'nowrap',
    overflow: 'hidden'
  },

  labelContainer: {
    display: 'flex'
  },

  value: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '100%'
  },

  popupContentRowItem: {
    display: 'flex',
    alignItems: 'center'
  },

  headerPanelRowItem: {
    display: 'flex',
    alignItems: 'center'
  },

  headerPanelLabel: {
  },

  headerPanelValue: {
    marginTop: '-5px',
    marginLeft: '31px',
    overflow: 'visible',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    flexBasis: '50%'
  },

  primaryText: {
    color: grey[700],
    fontWeight: 500,
    lineHeight: '18px',
    width: '100%',
    paddingBottom: '3px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '110%'
  },

  primaryTextOurDeals: {
    color: grey[700],
    fontWeight: 500,
    width: '80%',
    lineHeight: '18px',
    paddingBottom: '3px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '110%'
  },
  
  secondaryText: {
    lineHeight: '1.3em',
    ['& > p']: {
      color: grey[500],
      fontSize: '75%',
      margin: 0,
      overflow: 'hidden',
      padding: 0,
      textOverflow: 'ellipsis',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
    },
  
    ['& > span']: {
      borderRight: `1px solid ${grey[500]}`,
      fontSize: '90%',
      margin: 0,
      padding: `0 ${theme.spacing()}px`,
  
      ['&:first-child']: {
        paddingLeft: 0,
      },
  
      ['&:last-child']: {
        borderRight: 'none',
        paddingRight: 0,
      },
    }
  },
  
  stats: {
    fontSize: '80%',
    lineHeight: 'initial'
  },
  
  stat: {
    width: '100%',
    fontSize: '75%',
    display: 'flex',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    alignItems: 'baseline',
  },

  headerStat: {
    fontSize: '80%',
    display: 'flex',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    ['& > strong']: {
      paddingRight: theme.spacing(),
      ['&:after']: {
        content: '":"',
      },
    }
  }
  
});

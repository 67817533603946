import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Snackbar from '@material-ui/core/Snackbar';
import NotificationMessage from '../common/NotificationMessage';
import Loader from './Loader';
import Searches from './searches/Container';

const Research = ({ children, ...props }) => {
  const onNotificationClose = () => {
    props.notificationsClose();
  };

  useEffect(() => {
    const { fetchSavedLists } = props;
    fetchSavedLists();
  }, []);

  if (isEmpty(props.currentUser) || !props.currentLocation) {
    return <Loader />;
  }
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'relative',
        zIndex: 2,
      }}>
      <Searches {...props} />
      {React.cloneElement(children, props)}
      <Snackbar
        autoHideDuration={4000}
        message={<NotificationMessage text={props.notifications.message} type={props.notifications.type} />}
        onClose={onNotificationClose}
        open={props.notifications.open} />
    </div>
  );
};

Research.propTypes = {
  children: PropTypes.node.isRequired,
  currentLocation: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  fetchSavedLists: PropTypes.func,
  location: PropTypes.object.isRequired,
  notifications: PropTypes.shape({
    open: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.string
  }),
  notificationsClose: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  sourceFilter: PropTypes.string.isRequired,
};

export default Research;

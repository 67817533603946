import { lru } from 'tiny-lru';
import { ability } from '../auth/ability';
import { GET_PARCEL_PATH_END, GET_PARCEL_PATH_START } from '../constants/actionTypes';
import { read } from '../services/api';
import store from '../store';

const valueCache = lru(500, (12 * 60 * 60 * 1000), true);
const promiseCache = lru(10, (30 * 1000), true);

export async function getParcelPath({
  address,
  apn,
  fips,
  id,
  slug,
  type,
  view = 'map'
}) {
  store.dispatch({ type: GET_PARCEL_PATH_START });
  const prefix = `/research/${view}/`;
  const detailPath = `${slug}/${type}/${id ? id : ''}`;
  const defaultPath = `${prefix}${detailPath}`;

  if (ability.can('read', 'parcels')) {
    let parcelPath = '';
    if (apn && fips) {
      parcelPath = `parcel/fips/${fips}/apn/${apn}/`;
    } else if (address) {
      const cached = valueCache.get(address);

      if (cached) {
        parcelPath = `parcel/fips/${cached.fips}/apn/${cached.apn}/`;
      } else {
        try {
          let promise = promiseCache.get(address);

          if (!promise) {
            promise = read(`/parcels?address=${encodeURIComponent(address)}`, null, null, { timeout: 5000 });
            promiseCache.set(address, promise);
          }

          const response = await promise;
          if (response.parcels && response.parcels[0]) {
            const { assessment: { apn }, fips } = response.parcels[0];
            parcelPath = `parcel/fips/${fips}/apn/${apn}/`;
            valueCache.set(address, { fips, apn });
          }
        } catch (error) {
          store.dispatch({ type: GET_PARCEL_PATH_END });
          return defaultPath;
        }
      }
    }

    store.dispatch({ type: GET_PARCEL_PATH_END });
    
    if (!slug) { return `${prefix}${parcelPath}`; }

    return `${prefix}${parcelPath}${detailPath}`;
  }

  store.dispatch({ type: GET_PARCEL_PATH_END });
  return defaultPath;
}

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Loading from '../common/Loading';
import { history } from '../store';
import { guestLogin, softLogout } from './actions';
import styles from './module.css';

function InteractiveLinkAuth({
  classes,
  currentUser,
  guestLogin,
  loginError,
  params,
  softLogout,
  token
}) {
  const isRealUser = currentUser && currentUser.id && !currentUser.isGuest;
  const guestLinkId = get(currentUser, 'guestLink.id');
  useEffect(() => {
    // only proceed if not a real user logged in (they have to logout to use guest link)
    if (!isRealUser) {
      // if logged in as guest, but click a new shared link, need to logout and reauth
      if (guestLinkId && guestLinkId !== params.id) {
        softLogout();
      // else, if has logged in as guest, send along
      } else if (token) {
        history.push('/');
      // else, need to login as guest
      } else {
        guestLogin(params.id);
      }
    }
  }, [token, params.id, isRealUser, guestLinkId]);
  
  // render loading or error or "soft logout" prompt
  return (
    <div className={classes.ilOuter}>
      {loginError && <p>Login error</p>}
      {!loginError && (
        <div className={classes.ilWrapper}>
          <Loading message="Please wait, logging in..." />
          <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={Boolean(currentUser && !currentUser.isGuest)}>
            <DialogTitle>Already Logged In</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {`
                  If you would like to preview this interactive client view, please copy and
                  paste the link into a private browser window, or click "Logout & Preview"
                  below to continue as a guest.
                `}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                onClick={softLogout}>
                Logout & Preview
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </div>
  );
}

InteractiveLinkAuth.propTypes = {
  classes: PropTypes.object,
  currentUser: PropTypes.object,
  guestLogin: PropTypes.func.isRequired,
  loginError: PropTypes.string,
  params: PropTypes.object.isRequired,
  softLogout: PropTypes.func.isRequired,
  token: PropTypes.string,
};

export default connect((state) => ({
  currentUser: state.auth.currentUser,
  loginError: state.auth.loginError,
  token: state.auth.token,
}), { guestLogin, softLogout })(
  withStyles(styles)(InteractiveLinkAuth)
);

import { get, isEmpty, pick } from 'lodash';

const types = {
  map: {
    right: -340,
    left: 70,
    top: 320,
    bottom: 0
  },
  marker: {
    right: -310,
    left: 180,
    top: 360,
    bottom: 0
  }
}; 

export const parcelFilterFields = [
  'acreage',
  'address',
  'building_sqft',
  'id',
  'last_market_sale_date_transfer',
  'latitude',
  'longitude',
  'owner_name',
  'use_code_std_desc_lps',
  'yr_blt',
  'zoning'
];

export function getFeaturesFromVectorTiles(tiles) {
  const features = new Map();

  for (const t in tiles) {
    const tileFeatures = tiles[t]._layers;
    for (const f in tileFeatures) {
      const featureObj = get(tileFeatures, [f, 'properties']);
      if (!isEmpty(featureObj) && !features.has(featureObj.id)) {
        features.set(featureObj.id, pick(featureObj, parcelFilterFields));
      }
    }
  }

  return Array.from(features.values());
}

export function getPopupOffset(type, map, latlng) {
  const LIST_WIDTH = 415;

  const { x: clickX, y: clickY } = map.latLngToContainerPoint(latlng);
  const { x: screenX, y: screenY } = map.getSize();
  const horizontalMeridian = (screenX - LIST_WIDTH) / 2;
  const verticalMeridian = screenY / 2;

  let offsetX = 0;
  let offsetY = 0;

  const values = types[type];

  if (clickX > verticalMeridian) {
    offsetX += values.right;
  } else {
    offsetX += values.left;
  }

  if (clickY < horizontalMeridian) {
    offsetY += values.top;
  } else {
    offsetY += values.bottom;
  }

  return [offsetX, offsetY];
}

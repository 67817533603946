import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import FieldNote from './FieldNote';
import styles from './module.css';

function stopPropagation(event) {
  event.stopPropagation();
}

const Stat = ({ 
  classes, 
  fieldNote, 
  fieldNoteStyle, 
  label, 
  linkValue, 
  showEmpty, 
  truncate, 
  value, 
  marginValue, 
  paddingValue, 
  ...props }) => {
  if (!(value || fieldNote) && !showEmpty) {
    return <div />;
  }

  return (
    <div {...props}>
      <div className={classes.statLabel} style={{ paddingRight: paddingValue, marginRight: marginValue || '' }}>
        {label}
        {fieldNote && <FieldNote content={fieldNote} style={fieldNoteStyle} />}
        :&nbsp;
      </div>
      {linkValue && value
        ? (
          <Link
            href={value}
            onClick={stopPropagation}
            rel="noreferrer"
            target="_blank">{value}</Link>
        )
        : (
          <span 
            className={(truncate) ? classes.statValueTruncate : ''} 
            style={{ color: '#25408f', fontSize: '.9rem', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {value || '-'}
          </span>
        )}
    </div>
  );
};

Stat.propTypes = {
  classes: PropTypes.object,
  fieldNote: PropTypes.string,
  fieldNoteStyle: PropTypes.object,
  label: PropTypes.string.isRequired,
  linkValue: PropTypes.bool,
  marginValue: PropTypes.string,
  paddingValue: PropTypes.string,
  showEmpty: PropTypes.bool,
  truncate: PropTypes.bool,
  value: PropTypes.any
};

Stat.defaultProps = {
  classes: {},
  linkValue: false,
  showEmpty: false,
  truncate: false
};

export default withStyles(styles)(Stat);

import React from 'react';
import { Route, IndexRedirect, IndexRoute } from 'react-router';
import propertyDetailRoutes from '../propertyDetail/routes';
import ParcelDemographicsContainer from './ParcelDemographicsContainer';
import ParcelDetailContainer from './Container';
import Info from './Info';
import ParcelTax from './ParcelTax';
import PublicSalesRecordsWrapper from './PublicSalesRecordsWrapper';

export default (
  <Route path="parcel/fips/:fips/apn/:apn" component={ParcelDetailContainer}>
    <IndexRedirect to="info" />
    <Route path="info" component={Info}>
      <IndexRoute component={ParcelTax} />
      <Route path="public/tax" component={ParcelTax} />
      <Route path="public/sales" component={PublicSalesRecordsWrapper} />
      <Route path="demographics" component={ParcelDemographicsContainer} />
    </Route>
    {propertyDetailRoutes}
  </Route>
);

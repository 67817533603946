import { BoolMust, QueryAccessor } from 'searchkit-react16';

class CityAccessor extends QueryAccessor {
  constructor(field, options = {}, datatype = '') {
    super(field, options);
    this.datatype = datatype;
  }

  buildSharedQuery(query) {
    const cities = this.state.getValue();
    if (!cities || cities.length === 0) return query;
    
    let filter;
    
    if (this.datatype === 'property') {
      filter = new BoolMust({ terms: { 'city.untouched': cities } });
    }
    
    if (this.datatype === 'availability' || this.datatype === 'lease') {
      filter = new BoolMust({ terms: { 'property.city.untouched': cities } });
    }
    
    if (this.datatype === 'sale' || this.datatype === 'listing') {
      filter = new BoolMust({ terms: { 'properties.city.untouched': cities } });
    }
    const selectedFilter = {
      name: this.translate(this.options.title),
      cities,
      id: this.options.id,
      remove: () => { this.state = this.state.clear(); }
    };
    return query
      .addQuery(filter)
      .addSelectedFilter(selectedFilter);
  }
}

export default CityAccessor;

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import NotFavoritedIcon from '@material-ui/icons/StarBorder';
import FavoritedIcon from '@material-ui/icons/Star';
import SourceIcon from '@material-ui/icons/Link';
import MapIcon from '@material-ui/icons/Map';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import CollapseIcon from '@material-ui/icons/ExpandLess';
import Share from '../../common/Share';
import styles from './module.css';

const useStyles = makeStyles(styles);

const Actions = ({ favorite, item, expand, router, share, unfavorite }) => {
  const classes = useStyles(useTheme());
  const { properties = [], _type: type, hash, id, rescourId, sourceUrl } = item;
  const property = item.property || properties[0] || {};
  const location = property.location;
  const toggleFavorite = () => (item.favorite) ? unfavorite(item.favorite) : favorite(item);
  const showSource = () => window.open(sourceUrl);
  const _rescourId = ((type === 'property') ? rescourId : property.rescourId) || '';
  const showOnMap = () => {
    let routerPath = '/research/map';
    let routerHash = '';
    if (type === 'tim') {
      routerPath += `/tim/${id}`;
      if (get(item, 'searchShapes.coordinates[0][0]') && item.searchShapes.coordinates[0][0].length > 0) {
        const fuzzyCenter = item.searchShapes.coordinates[0][0]
          .reduce((acc, cur) => [acc[0] + cur[0], acc[1] + cur[1]], [0, 0])
          .map((loc) => loc / item.searchShapes.coordinates[0][0].length);
        routerHash = `#10/${fuzzyCenter[1]}/${fuzzyCenter[0]}`;
      }
    } else {
      routerPath += `/${property.hash || hash}|${_rescourId}/${type}/${id}`;
      routerHash = `#7/${location[1]}/${location[0]}`;
    }
    router.push({
      pathname: routerPath,
      hash: routerHash
    });
  };

  return (
    <div className={classes.actions}>
      <Button
        onClick={toggleFavorite}
        startIcon={item.favorite ? <FavoritedIcon /> : <NotFavoritedIcon />}>
        <span className={classes.hideOnSmall}>Favorite</span>
      </Button>
      <Share
        btnLabelClass={classes.hideOnSmall}
        item={item}
        shareEmail={share} />
      {item.sourceUrl && (
        <Button
          onClick={showSource}
          startIcon={<SourceIcon />}>
          <span className={classes.hideOnSmall}>Source</span>
        </Button>
      )}
      <Button
        className={classes.hideOnSmall}
        onClick={showOnMap}
        startIcon={<MapIcon />}>
        View On Map
      </Button>

      <div onClick={expand} style={{ flex: 1 }} />
      
      <IconButton onClick={expand}>
        {item.isExpanded ? <CollapseIcon /> : <ExpandIcon />}
      </IconButton>
    </div>
  );
};

Actions.propTypes = {
  expand: PropTypes.func.isRequired,
  favorite: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  share: PropTypes.func.isRequired,
  unfavorite: PropTypes.func.isRequired,
};

export default withRouter(Actions);

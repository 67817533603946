import React from 'react';
import PropTypes from 'prop-types';
import { Auth0Provider } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core/styles';
import Authentication from './auth/Authentication';
import Header from './layout/Header';
import styles from './App.css';
import VersionCheck from './common/VersionCheck';

const App = ({ classes, location, main, noHeader, router }) => {
  const enableVersionCheck = ['staging','production'].includes(process.env.NODE_ENV);
  return (
    <div className={classes.appContainer}>
      {enableVersionCheck && <VersionCheck checkInterval={process.env.REACT_APP_VERSION_CHECK_DELAY} />}
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_CLIENT_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}>
        <Authentication location={location}>
          {!noHeader && <Header router={router} location={location} />}
          <div className={classes.mainContainer}>
            {main}
          </div>
        </Authentication>
      </Auth0Provider>
    </div>
  );
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.node,
  location: PropTypes.object.isRequired,
  main: PropTypes.node,
  noHeader: PropTypes.bool,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);

import React from 'react';
import PropTypes from 'prop-types';
import Pluralize from 'pluralize';
import datatypes from '../../../common/datatypes';
import { withStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from '../module.css.js';

const StyledTabs = withStyles({
  root: {
    'backgroundColor': 'white',
    'color': 'black',
    'minHeight': '32px'
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ hidden: true }} />);

const StyledTab = withStyles(() => ({
  root: {
    'display': 'flex',
    'justifyContent': 'flex-end',
    'backgroundColor': 'white',
    'minHeight': '32px',

  },
}))((props) => <Tab disableRipple {...props} />);

const MenuTabs = ({ 
  color,
  filteredQueryItems,
  handleTabChange, 
  hasFilteredQueryItems,
  hasNearby, 
  items, 
  nearbyItemsTab,
  params,
  tabIndex
}) => {
  const currentDatatype = () => {
    for (const datatype in datatypes) {
      if (datatype === params.datatype) return Pluralize.singular(datatypes[datatype].plural);
    }
  };
  const datatypeLabel = currentDatatype();

  return (
    <React.Fragment>
      <div
        style={!items.length && tabIndex === 0 ? { display: 'flex' } : {}}>
        {!items.length && tabIndex === 0 && (
          <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            There are no {datatypeLabel} records for this property. However, we did find nearby {datatypeLabel} records.
          </div>
        )}

        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '8px 8px' }}>
          <StyledTabs
            value={tabIndex}
            onChange={handleTabChange}
            style={{
              borderRadius: '8px',
              width: 'fit-content',
              minHeight: 'none' }}>
            {hasFilteredQueryItems && (
              <StyledTab
                label={`SHOW FILTERED (${filteredQueryItems.length})`}
                color={color}
                style={params.itemId === 'filter'
                  ? { backgroundColor: color, color: 'white', opacity: 1 }
                  : {
                    backgroundColor: 'white',
                    color: '#6E6E70',
                    borderRadius: '8px 0 0 8px',
                    border: '2px solid #9d9d9d',
                    borderRight: 'none',
                    opacity: 1 }} />
            )}
            {hasFilteredQueryItems && (
              <StyledTab
                label={`SHOW ALL (${items.length})`}
                color={color}
                style={params.itemId === 'nearby' || params.itemId === 'filter'
                  ? {
                    backgroundColor: 'white',
                    color: '#6E6E70',
                    border: '2px solid #9d9d9d',
                    opacity: 1,
                    fontWeight: 'bold' }
                  : { backgroundColor: color, color: 'white', opacity: 1 }} />
            )}
            {!hasFilteredQueryItems && (
              <StyledTab
                label={`SHOW ALL (${items.length})`}
                color={color}
                style={params.itemId === 'nearby' || params.itemId === 'filter'
                  ? {
                    backgroundColor: 'white',
                    color: '#6E6E70',
                    borderRadius: '8px 0 0 8px',
                    border: '2px solid #9d9d9d',
                    borderRight: 'none',
                    opacity: 1,
                    fontWeight: 'bold' }
                  : { backgroundColor: color, color: 'white', opacity: 1 }} />
            )}
            {hasNearby && (
              <StyledTab
                label={`NEARBY (${nearbyItemsTab})`}
                color={color}
                style={params.itemId === 'nearby'
                  ? { backgroundColor: color, color: 'white', opacity: 1 }
                  : {
                    backgroundColor: 'white',
                    color: '#6E6E70',
                    borderRadius: '0 8px 8px 0',
                    border: '2px solid #9d9d9d',
                    borderLeft: 'none',
                    opacity: 1 }} />
            )}
          </StyledTabs>
        </div>
      </div>
    </React.Fragment>
  );
};

MenuTabs.propTypes = {
  color: PropTypes.string,
  filteredQueryItems: PropTypes.array.isRequired,
  handleTabChange: PropTypes.func,
  hasFilteredQueryItems: PropTypes.bool.isRequired,
  hasNearby: PropTypes.bool,
  items: PropTypes.array,
  nearbyItemsTab: PropTypes.number,
  params: PropTypes.object,
  tabIndex: PropTypes.number
};

export default withStyles(styles)(MenuTabs);

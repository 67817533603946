import {
  CLEAR_SELECTED_MYALERT,
  CLEAR_SELECTED_MYLIST,
  CREATED_MYLIST,
  DELETED_MYLIST,
  DISABLE_ALL_FILTER_DATATYPES,
  SELECT_MYLIST,
  UPDATED_MYALERT,
  UPDATED_MYLIST,
  LOAD_RECENT_MYALERTS_REQUEST,
  LOAD_RECENT_MYALERTS_SUCCESS,
  LOAD_RECENT_MYALERTS_FAILURE,
  LOAD_RECENT_MYLISTS_REQUEST,
  LOAD_RECENT_MYLISTS_SUCCESS,
  LOAD_RECENT_MYLISTS_FAILURE,
  CLEAR_MAP_POLYGONS,
  SNACKBAR_MAP_MESSAGE,
} from '../constants/actionTypes';
import { ability } from '../auth/ability';
import api, { read } from '../services/api';
import { history } from '../store';
import { enableDatatype } from '../research/searches/actions';

export function selectMyList(myList) {
  return (dispatch, getState) => {
    const { research: { lists } } = getState();
    const { datatype } = myList;
    dispatch({ type: CLEAR_MAP_POLYGONS });

    history.push('/research/map');
    dispatch({ type: DISABLE_ALL_FILTER_DATATYPES }); // Disable all Filters
    dispatch(enableDatatype(datatype)); // Enable List Datatype Filter
    dispatch({ type: SELECT_MYLIST, myList, lists });
    dispatch({ type: SNACKBAR_MAP_MESSAGE, text: myList.title, secondaryText: 'List Loaded', textType: 'success' });
  };
}

export function loadRecentMyAlerts() {
  return (dispatch) => {
    dispatch({ type: LOAD_RECENT_MYALERTS_REQUEST });
    const query = { limit: 100, sort: '-updatedAt' };
    if (ability.cannot('use', '_app')) return new Promise((resolve) => resolve([]));
    return read('/alerts', null, query)
      .then((data) => {
        dispatch({ type: LOAD_RECENT_MYALERTS_SUCCESS, data });
      })
      .catch((error) => {
        dispatch({ type: LOAD_RECENT_MYALERTS_FAILURE, error });
      });
  };
}

export function loadRecentMyLists() {
  return (dispatch) => {
    dispatch({ type: LOAD_RECENT_MYLISTS_REQUEST });
    const query = { limit: 100, sort: '-updatedAt' };
    if (ability.cannot('use', '_app')) return new Promise((resolve) => resolve([]));
    return read('myLists', null, query)
      .then((data) => {
        dispatch({ type: LOAD_RECENT_MYLISTS_SUCCESS, data });
      })
      .catch((error) => {
        dispatch({ type: LOAD_RECENT_MYLISTS_FAILURE, error });
      });
  };
}

export function clearSelectedMyAlert() {
  return {
    type: CLEAR_SELECTED_MYALERT
  };
}

export function clearSelectedMyList() {
  return {
    type: CLEAR_SELECTED_MYLIST
  };
}

export function myListCreated(myList) {
  return {
    type: CREATED_MYLIST,
    myList
  };
}

export function myAlertUpdated(savedSearch) {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const myAlerts = state.research.account.alerts.recentMyAlerts;
      const myAlert = myAlerts.find((alert) => alert.savedSearch === savedSearch.id);

      if (!myAlert) return;
      myAlert.frequency = savedSearch.frequency;
      myAlert.title = savedSearch.title;
      const updatedMyAlert = await api.update(`/alerts/${myAlert.id}`, myAlert);
      dispatch({
        type: UPDATED_MYALERT,
        data: updatedMyAlert
      });
    } catch (error) {
      console.error('error', error);
    }
  };
}

export function myListUpdated(myList, myListId) {
  return (dispatch) => {
    dispatch({ type: UPDATED_MYLIST, myList });
    return api.update(`/myLists/${myListId}`, myList);
  };
}

export function myListDeleted(myListId) {
  return (dispatch) => {
    dispatch({ type: DELETED_MYLIST });
    return api.destroy(`/myLists/${myListId}`);
  };
}

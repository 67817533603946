/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useTracker } from '@colliers-international/usage-tracker';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Snackbar from '@material-ui/core/Snackbar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import EyeIcon from '@material-ui/icons/Visibility';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import ShareIcon from '@material-ui/icons/Share';
import TableViewIcon from '@material-ui/icons/GridOn';
import ShowAllIcon from '@material-ui/icons/ReplyAll';
import ShowSelectedIcon from '@material-ui/icons/PlaylistAddCheck';
import PrintIcon from '@material-ui/icons/Print';
import { FullStoryAPI as fullStoryAPI } from 'react-fullstory';
import AnalyticsLogo from '../../assets/analytics-icon.svg';
import datatypes from '../../common/datatypes';
import getReportMenuItems from '../../common/reports';
import Can from '../../auth/ability';
import ShareSearch from '../shareSearch/ShareSearch';
import { MAX_REPORT_SIZE } from '../constants';
import { EVENTS as eventNames } from '../../constants/fullstory.js';
import SiteAdvisorMenuItem from './SiteAdvisorMenuItem';
import styles from './module.css';
import { Icon as AnalyticsButton } from '@material-ui/core';
import BoomMenuItem from './BoomMenuItem';
import { MyListsMenu } from './MyListsMenu';

const ListHeader = ({
  popoverRef,
  classes,
  clearListError,
  createListsWithEntities,
  currentListView,
  currentUser,
  enabledSearches,
  fitListModePins,
  hideAllFilters,
  isSearching,
  results,
  router,
  color,
  Icon,
  plural,
  showSelected,
  toggleShowSelected,
  toggleShareDialog,
  myLists,
  updateMyList,
  ...props
}) => {
  const selectedResults = props.selectedResults[currentListView] || [];
  const selectedResultsIds = selectedResults.map((item) => item._id) || [];

  const MainIcon = Icon;
  const [menuAnchor, setMenuAnchor] = useState({});

  const { trackEvent } = useTracker();

  const openMenu = (menu) => (event) => {
    setMenuAnchor({
      el: event.currentTarget,
      menu
    });
  };
  const closeMenu = () => setMenuAnchor({});

  const closeFiltersOpenMenu = (menu) => (event) => {
    hideAllFilters();
    openMenu(menu)(event);
  };

  const hideBoom = () => {
    const hidden = ['tim', 'company', 'news'];
    return hidden.includes(currentListView) || currentUser.isGuest;
  };

  const showMyLists = () => {
    const listsHidden = ['tim', 'news'];
    return !listsHidden.includes(currentListView);
  };

  const clearSelectedResults = () => {
    props.clearSelectedResults(currentListView);
  };
  const selectAllResults = () => props.selectAllResults(results.slice(0, MAX_REPORT_SIZE), currentListView);
  const handleGroupAnalyticsClick = () => {
    fullStoryAPI(
      eventNames.EVENT,
      eventNames.GROUP_ANALYTICS,
      { 'groupAnalytics_str': eventNames.GROUP_ANALYTICS_CLICKED }
    );
    router.push({ ...location, pathname: '/research/map/analytics' });
  };
  const handleTableViewClick = () => {
    hideAllFilters();
    trackEvent({ category: 'Discover Grid Interface', action: 'Clicked', name:'Grid Icon' });
    router.push({ ...location, pathname: '/research/table' });
  };
  const handleDatatypeSwitch = (datatype) => () => {
    router.push({ ...location, query: { currentListView: datatype } });
    closeMenu();
  };

  const handleShare = () => toggleShareDialog(true);
  const handleShareClose = () => toggleShareDialog(false);

  const handleErrorClose = () => clearListError();

  const reportMenuItems = getReportMenuItems({
    currentUser,
    currentListView,
    closeMenu
  });

  const handleEyeClick = () => {
    fitListModePins(true);
  };

  return (
    <AppBar
      color="transparent"
      elevation={0}
      position="static"
      className={classes.headerAppBar}
      style={{ backgroundColor: color }}>
      <Toolbar className={classes.headerToolbar}>
        <IconButton className={classes.headerButton}>
          <MainIcon />
        </IconButton>
        <div className={classes.headerWrapper}>
          <div className={classes.headerTitleWrapper}>
            <h3>{plural.toUpperCase()}</h3>
            <IconButton className={classes.appBarIconButton} onClick={openMenu('datatypes')}>
              <ArrowDropDown />
            </IconButton>
          </div>
          <Typography className={classes.headerCount} noWrap variant="subtitle2">
            {`${selectedResults.length} / ${MAX_REPORT_SIZE} SELECTED`}
            {selectedResults && selectedResults.length > 0 && (
              <IconButton
                className={classes.appBarIconButton}
                style={{ marginLeft: 5, marginBottom: 2 }}
                onClick={toggleShowSelected}>
                {showSelected ? 
                  <Tooltip title="Show All Items">
                    <ShowAllIcon />
                  </Tooltip>
                  :
                  <Tooltip  title="Show Selected">
                    <EyeIcon onClick={handleEyeClick} />
                  </Tooltip>
                }
              </IconButton>
            )}
          </Typography>
        </div>
        {selectedResults.length >= 0 && (
          <div className={classes.listHeaderActions}>
            <Tooltip title="More Actions...">
              <IconButton style={{ padding: 7 }} className={classes.headerButton} onClick={openMenu('actions')}>
                <MoreVertIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Toolbar>
      <Toolbar style={{ minHeight: 48 }} className={classes.headerToolbar}>
        <React.Fragment>
          {isSearching && !props.searches[currentListView].listMode ? (
            <div className={classes.circularProgressContainer}>
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <React.Fragment>
              <Menu
                anchorEl={menuAnchor.el}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={closeMenu}
                open={menuAnchor.menu === 'datatypes'}>
                {Object.keys(enabledSearches).map((key) => {
                  const { plural, Icon } = datatypes[key];
                  return (
                    <MenuItem key={key} button onClick={handleDatatypeSwitch(key)}>
                      <ListItemIcon><Icon /></ListItemIcon>
                      <ListItemText primary={plural} />
                    </MenuItem>
                  );
                })}
              </Menu>
            </React.Fragment>
          )}
        </React.Fragment>
        <React.Fragment>
          <Tooltip
            title={`Select ${results.length > MAX_REPORT_SIZE ? `First ${MAX_REPORT_SIZE} Results` : 'All'}`}>
            <IconButton className={classes.headerButton} onClick={selectAllResults}>
              <SelectAllIcon />
            </IconButton>
          </Tooltip>
          {selectedResults.length > 0 && showMyLists() && (currentUser.isGuest === false) && (
            <MyListsMenu
              currentUser={currentUser}
              myLists={myLists}
              itemId={selectedResultsIds}
              createListsWithEntities={createListsWithEntities}
              currentListView={currentListView}
              updateMyList={updateMyList}
              classes={classes}
              isBulk={true}
              popoverRef={popoverRef} />)}
          <div className={classes.listHeaderActions}>
            {selectedResults.length >= 0 && (
              <React.Fragment>
                <Menu
                  anchorEl={menuAnchor.el}
                  onClose={closeMenu}
                  open={menuAnchor.menu === 'actions'}>
                  <MenuItem onClick={toggleShowSelected}>
                    <ListItemIcon>{showSelected ? <ShowAllIcon /> : <ShowSelectedIcon />}</ListItemIcon>
                    <ListItemText primary={`Show ${showSelected ? 'All' : 'Selected'} Items`} />
                  </MenuItem>
                  {!hideBoom() && (<BoomMenuItem 
                    classes={classes}
                    selectedResults={selectedResults}
                    currentListView={currentListView} />)}
                  {(currentListView === 'property') && (
                    <Can do="create" on="siteAdvisorAnalysis">
                      <SiteAdvisorMenuItem currentUser={currentUser} selected={selectedResults} {...props} />
                    </Can>
                  )}
                  <Can do="create" on="interactiveLinks">
                    <MenuItem onClick={handleShare}>
                      <ListItemIcon><ShareIcon /></ListItemIcon>
                      <ListItemText primary="Share Selection" />
                    </MenuItem>
                  </Can>
                </Menu>
              </React.Fragment>
            )}

            <div style={{ flex: 1 }} />

            {selectedResults.length > 0 && (
              <Tooltip title="Clear Selection">
                <IconButton className={classes.headerButton} onClick={clearSelectedResults}>
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            )}

            {currentListView === 'lease' &&
              <Tooltip title="Group Analytics">
                <IconButton
                  className={classes.headerButton}
                  onClick={handleGroupAnalyticsClick}>
                  <AnalyticsButton>
                    <img src={AnalyticsLogo} />
                  </AnalyticsButton>
                </IconButton>
              </Tooltip>
            }

            {selectedResults.length > 0 && reportMenuItems.length > 0 && (
              <Can do="read" on="reports">
                <Tooltip title="Preview Report">
                  <IconButton className={classes.headerButton} onClick={closeFiltersOpenMenu('reports')}>
                    <PrintIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={menuAnchor.el}
                  onClose={closeMenu}
                  open={menuAnchor.menu === 'reports'}>{reportMenuItems}
                </Menu>
              </Can>
            )}

            <Tooltip title="Table View">
              <IconButton className={classes.headerButton} onClick={handleTableViewClick}>
                <TableViewIcon />
              </IconButton>
            </Tooltip>
          </div>
        </React.Fragment>

        <div className={classes.listExpand}>
          <Button
            className={classes.collapseToggleButton}
            onClick={props.expandList}>List</Button>
        </div>
        <div className={classes.listCollapse}>
          <Button
            className={classes.collapseToggleButton}
            onClick={props.collapseList}>Map</Button>
        </div>

        <Can do="create" on="interactiveLinks">
          <ShareSearch onClose={handleShareClose} />
        </Can>
      </Toolbar>
      <Snackbar
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        autoHideDuration={10000}
        message={props.listErrorMessage}
        onClose={handleErrorClose}
        open={props.hasListError} />
    </AppBar>
  );
};

ListHeader.propTypes = {
  classes: PropTypes.object,
  clearListError: PropTypes.func.isRequired,
  clearSelectedResults: PropTypes.func.isRequired,
  collapseList: PropTypes.func.isRequired,
  createListsWithEntities: PropTypes.func.isRequired,
  currentListView: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  disableSelectMode: PropTypes.func.isRequired,
  enableSelectMode: PropTypes.func.isRequired,
  enabledSearches: PropTypes.object.isRequired,
  expandList: PropTypes.func.isRequired,
  hasListError: PropTypes.bool.isRequired,
  hideAllFilters: PropTypes.func.isRequired,
  isSearching: PropTypes.bool.isRequired,
  listErrorMessage: PropTypes.string,
  results: PropTypes.array.isRequired,
  router: PropTypes.object.isRequired,
  selectAllResults: PropTypes.func.isRequired,
  selectedResults: PropTypes.object.isRequired,
  showSelected: PropTypes.bool.isRequired,
  toggleShareDialog: PropTypes.func.isRequired,
  toggleShowSelected: PropTypes.func.isRequired,
  updateMyList: PropTypes.func.isRequired
};

export default withStyles(styles)(ListHeader);

import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const Documents = ({ value }) => {
  return (
    <div>
      {value.map((document, index) => {
        const title = document.title || document.fileName;
        return (
          <div key={index}>
            {(title && document.url) && <Link href={document.url} target={'_blank'}>{title}</Link>}
          </div>
        );
      })}
    </div>
  );
};

Documents.propTypes = {
  value: PropTypes.any
};

export default Documents;

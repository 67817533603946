/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FieldNote from '../../../common/stats/FieldNote';
import Features from '../common/Features';
import Images from '../common/Images';
import Documents from '../common/Documents';
import SummaryFieldStats from '../common/SummaryFieldStats';
import Section from '../common/Section';
import styles from './module.css';

const useStyles = makeStyles(styles);

export const CardContent = ({ item }) => {
  const classes = useStyles();
  const {
    area,
    rateType,
    rentRate,
    effectiveRent,
    leaseTerm,
    signedDate,
    abatement,
    tenantImprovement,
    commencementDate,
    expirationDate,
    leaseType,
    sourcedBy
  } = item;
  let formatedStartRate;
  if (rentRate && rateType) formatedStartRate = `$${rentRate} ${rateType}`; 
  if (rentRate && !rateType) formatedStartRate = `$${rentRate}`;
  if (rateType && !rentRate) formatedStartRate = rateType;

  const leaseStats = [
    { label: 'Leased Area', value: area, format: 'number' },
    { label: 'Start Rate', value: formatedStartRate, format: 'string' },
    { label: 'Effective Rate', value: effectiveRent, format: 'currency' },
    { label: 'Lease Term', value: leaseTerm, format: 'string' },
    { label: 'Date Signed', value: signedDate, format: 'date' },
    { label: 'Abatement', value: abatement, format: 'number' },
    { label: 'TI Allowance', value: tenantImprovement, format: 'currency' },
    { label: 'Start Date', value: commencementDate, format: 'date' },
    { label: 'End Date', value: expirationDate, format: 'date' },
    { label: 'Lease Type', value: leaseType, format: 'string' },
    { label: 'Source', value: sourcedBy, format: 'string', customClass: classes.sourceSummaryEllipsisLease }
  ];

  return (
    <React.Fragment>
      <SummaryFieldStats stats={leaseStats} />
    </React.Fragment>
  );
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
};

export const ExpandedCardContent = ({ item }) => {
  const classes = useStyles();
  const fieldNotes = item.fieldNotes || {};
  const {
    floors,
    suite,
    area,
    leaseType,
    subLease,
    areaChange,
    tenantImprovementNotes,
    note,
    leaseTerm,
    abatement,
    tenantImprovement,
    improvementType,
    signedDate,
    commencementDate,
    occupancyDate,
    expirationDate,
    terminationOptionDate,
    renewalOptionDate,
    monthsOnMarket,
    sourcedBy
  } = item;

  const compInfo = [
    [
      { key: 'floors', label: 'Floors', value: floors, format: 'array' },
      { key: 'suite', label: 'Suite', value: suite, format: 'string' },
      { key: 'area', label: 'Leased Area', value: area, format: 'number' },
      { key: 'leaseType', label: 'Lease Type', value: leaseType, format: 'string' },
      { key: 'subLease', label: 'Sublease', value: subLease, format: 'boolean' },
      { key: 'areaChange.type', label: 'Area Change Type', value: areaChange, format: 'objectType' },
      { key: 'areaChange.value', label: 'Area Change (SF)', value: areaChange, format: 'objectValue' },
      { key: 'tenantImprovementNotes', label: 'TI Notes', value: tenantImprovementNotes, format: 'string', customClass: classes.spanTwoColumns },
      { key: 'notes', label: 'Notes', value: note, format: 'string', customClass: classes.spanTwoColumns }
    ],
    [
      { key: 'leaseTerm', label: 'Lease Term (mos)', value: leaseTerm, format: 'number' },
      { key: 'abatement', label: 'Abatement (mos)', value: abatement, format: 'number' },
      { key: 'tenantImprovement', label: 'TI Allowance / SF', value: tenantImprovement, format: 'currency' },
      { key: 'improvementType', label: 'Improvement Type', value: improvementType, format: 'string' }
    ],
    [
      { key: 'signedDate', label: 'Signed Date', value: signedDate, format: 'date' },
      { key: 'commencementDate', label: 'Commencement Date', value: commencementDate, format: 'date' },
      { key: 'occupancyDate', label: 'Occupancy Date', value: occupancyDate, format: 'date' },
      { key: 'expirationDate', label: 'Expiration Date', value: expirationDate, format: 'date' },
      { key: 'terminationOptionDate', label: 'Termination Option Date', value: terminationOptionDate, format: 'date' },
      { key: 'renewalOptionDate', label: 'Renewal Option Date', value: renewalOptionDate, format: 'date' },
      { key: 'monthsOnMarket', label: 'Months on Market', value: monthsOnMarket, format: 'number' },
      { key: 'sourcedBy', label: 'Source', value: sourcedBy, format: 'string' }
    ]
  ];

  const {
    rentRate,
    rateType,
    rentRateFrequency,
    effectiveRent,
    expenses,
    commonAreaMaintenance,
    escalationType,
    escalationRate,
    grossLease,
    askingRate,
  } = item;

  const rateInfo = [
    [
      { key: 'rentRate', label: 'Start Rate (psf)', value: rentRate, format: 'currency' },
      { key: 'rateType', label: 'Rate Type', value: rateType, format: 'string' },
      { key: 'rentRateFrequency', label: 'Frequency', value: rentRateFrequency, format: 'string' },
      { key: 'effectiveRent', label: 'Effective Rent', value: effectiveRent, format: 'currency' },
      { key: 'askingRate.min', label: 'Asking Rate (Min)', value: askingRate, format: 'objectMinCurrency' },
      { key: 'askingRate.max', label: 'Asking Rate (Max)', value: askingRate, format: 'objectMaxCurrency' }
    ],
    [
      { key: 'escalationType', label: 'Escalation Type', value: escalationType, format: 'properCaseString' },
      { key: 'escalationRate', label: 'Annual Escalation Rate', value: escalationRate, format: 'escalationRate' },
      { key: 'expenses', label: 'Expenses', value: expenses, format: 'currency' },
      { key: 'commonAreaMaintenance', label: 'CAM', value: commonAreaMaintenance, format: 'currency' },
      { key: 'grossLease', label: 'Gross Lease', value: grossLease, format: 'number' }
    ]
  ];

  const {
    tenant,
    tenantRepresentatives,
    sublessor,
    landlords,
    landlordRepresentatives,
    sublessee
  } = item;
  const contactInfo = [
    [
      { key: 'tenant', label: 'Tenant', value: tenant, format: 'signatories' },
      { key: 'tenantRepresentatives', label: 'Tenant Rep.', value: tenantRepresentatives, format: 'signatories' }
    ],
    [
      { key: 'landlords', label: 'Landlord', value: landlords, format: 'signatories' },
      { key: 'landlordRepresentatives', label: 'Landlord Rep.', value: landlordRepresentatives, format: 'signatories' }
    ]
  ];
  const contactInfoSpecialRow = [
    [{ key: 'sublessee', label: 'Sublessee', value: sublessee, format: 'signatories' }],
    [{ key: 'sublessor', label: 'Sublessor', value: sublessor, format: 'signatories' }]
  ];

  const {
    buildingPassengerElevators,
    buildingFreightElevators,
    loadFactor,
    dockDoors,
    driveIns,
    amperage,
    amperageHigh,
    hasRailSpur,
    hasColdStorage,
    parkingRatio,
    parkingDetails,
    trailerSpaces,
    fronting,
    frontage,
    clearHeightMax,
    clearHeightMin,
    voltage,
    voltageHigh,
    hasFencedYard,
    hasMezzanine,
    spaceUses,
    sprinklers,
    columnSpacingDepth,
    columnSpacingWidth,
    electricalPhase,
    isVented
  } = item;

  const propertyInfo = [
    [
      { key: 'buildingPassengerElevators', label: 'Passenger Elevators', value: buildingPassengerElevators, format: 'number' },
      { key: 'buildingFreightElevators', label: 'Freight Elevators', value: buildingFreightElevators, format: 'number' },
      { key: 'loadFactor', label: 'Load Factor', value: loadFactor, format: 'ratio' },
      { key: 'dockDoors', label: 'Dock Doors', value: dockDoors, format: 'number' },
      { key: 'driveIns', label: 'Drive-Ins', value: driveIns, format: 'number' },
      { key: 'amperage', label: 'Amperage (Low)', value: amperage, format: 'number' },
      { key: 'amperageHigh', label: 'Amperage (High)', value: amperageHigh, format: 'number' }
    ],
    [
      { key: 'frontage', label: 'Frontage', value: frontage, format: 'number' },
      { key: 'fronting', label: 'Fronting', value: fronting, format: 'number' },
      { key: 'clearHeightMin', label: 'Min Clear Height', value: clearHeightMin, format: 'number' },
      { key: 'clearHeightMax', label: 'Max Clear Height', value: clearHeightMax, format: 'number' },
      { key: 'voltage', label: 'Volts (Low)', value: voltage, format: 'number' },
      { key: 'voltageHigh', label: 'Volts (High)', value: voltageHigh, format: 'number' }
    ],
    [
      { key: 'trailerSpaces', label: 'Trailer Spaces', value: trailerSpaces, format: 'number' },
      { key: 'sprinklers', label: 'Sprinklers', value: sprinklers, format: 'string' },
      { key: 'columnSpacingDepth', label: 'Col. Spacing (depth)', value: columnSpacingDepth, format: 'number' },
      { key: 'columnSpacingWidth', label: 'Col. Spacing (width)', value: columnSpacingWidth, format: 'number' },
      { key: 'electricalPhase', label: 'Electrical Phase', value: electricalPhase, format: 'number' }
    ]
  ];
  const propertyInfoSpecialTopRow = [
    [{ key: 'parkingRatio', label: 'Parking Ratio', value: parkingRatio, format: 'parkingRatio' }],
    [{ key: 'parkingDetails', label: 'Parking Details', value: parkingDetails, format: 'parkingDetails', customClass: classes.spanTwoColumns }]
  ];
  const propertyInfoSpecialRow = [
    [
      { key: 'hasRailSpur', label: 'Rail Spur', value: hasRailSpur, format: 'boolean' },
      { key: 'hasColdStorage', label: 'Cold Storage', value: hasColdStorage, format: 'boolean' },
      {
        key: 'spaceUses',
        label: 'Space Uses',
        value: spaceUses,
        format: 'spaceUses',
        customClass: classes.spanTwoColumns
      }
    ],
    [
      { key: 'hasMezzanine', label: 'Mezzanine', value: hasMezzanine, format: 'boolean' },
      { key: 'hasFencedYard', label: 'Fenced Yard', value: hasFencedYard, format: 'boolean' }
    ],
    [
      { key: 'isVented', label: 'Vented', value: isVented, format: 'boolean' }
    ]
  ];

  const {
    createdAt,
    updatedAt,
    ourDealsId,
    ourListingId,
    coStarId,
    customId,
    legacyId
  } = item;
  const [recordInfo, setRecordInfo] = useState([
    [
      { key: 'createdAt', value: moment(createdAt).format('MMM D, YYYY') || '--', label: 'Created At', format: 'date' },
      { key: 'usableLandArea', value: moment(updatedAt).format('MMM D, YYYY') || '--', label: 'Last Modified', format: 'date' }
    ],
    [
      { key: 'relatedIds', value: ' ', label: 'Related IDs', format: 'string' },
      { key: 'ourDeals', value: ourDealsId, label: 'Our Deals', format: 'string' },
      { key: 'ourListings', value: ourListingId, label: 'Our Listings', format: 'string' },
      { key: 'coStar', value: coStarId, label: 'CoStar', format: 'string' },
      { key: 'legacy', value: legacyId, label: 'Legacy', format: 'string' }
    ],
    [
      { key: 'customIds', value: ' ', label: 'Custom IDs', format: 'string' },
    ]
  ]);
  
  useEffect(() => {
    if (customId && customId.length > 0) {
      setRecordInfo((prevRecordInfo) => {
        const newRecordInfo = [...prevRecordInfo];
        customId.forEach((item) => {
          const customItem = { key: `customIds_${item.name}`, value: item.id, label: item.name, format: 'string' };
          newRecordInfo[2].push(customItem);
        });
        return newRecordInfo;
      });
    }
  }, []);

  const specialColumn = () => {
    return (
      <div>
        <header className={classes.rentScheduleHeader}>
          Rent Schedule
          {fieldNotes.rentSchedule && <FieldNote content={fieldNotes.rentSchedule} />}
        </header>
        {item.rentSchedule && (
          <Table size="small" style={{ marginTop: 16 }}>
            <TableHead>
              <TableRow>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell>Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {item.rentSchedule.map((schedule, key) => {
                return (
                  <TableRow key={key} style={{ height: 36 }}>
                    <TableCell>{schedule.start}</TableCell>
                    <TableCell>{schedule.end}</TableCell>
                    <TableCell>{numeral(schedule.rate).format('$0,0.00[0]')}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>)}
      </div>
    );
  };

  const { documents, features, images } = item;
  return (
    <div className={classes.expandedView}>
      <Section
        title={'Comp Info'}
        sectionArray={compInfo}
        fieldNotes={fieldNotes} />
      {documents && documents.length > 0 && (
        <Documents documents={documents} />)}
      <Section
        title={'Rate Info'}
        sectionArray={rateInfo}
        fieldNotes={fieldNotes}
        item={item}
        specialColumn={specialColumn} />
      <Section
        title={'Contacts'}
        sectionArray={contactInfo}
        fieldNotes={fieldNotes}
        specialRowArray={contactInfoSpecialRow} />
      <Section
        title={'Suite Info'}
        sectionArray={propertyInfo}
        fieldNotes={fieldNotes}
        specialTopRowArray={propertyInfoSpecialTopRow}
        specialRowArray={propertyInfoSpecialRow}
        item={item} />
      <Features
        features={features}
        fieldNote={fieldNotes.features ? fieldNotes.features : null} />
      {images && images.length > 0 &&  (
        <Images
          images={images}
          fieldNote={fieldNotes.images ? fieldNotes.images : null} />)}
      <Section
        title={'Record Info'}
        sectionArray={recordInfo}
        fieldNotes={fieldNotes} />
    </div>
  );
};

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired
};

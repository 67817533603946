import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import { read } from '../../../services/api';
import PopUp from './PopUp';
import styles from './module.css';

const  ParcelPopUp = ({ 
  bounds,
  classes,
  closePopup,
  parcelPopup,
  pin,
  property, 
  isFetching,
  searches,
  trackEvent,
  setParcelProperties
}) => {
  const [parcelData, setParcelData] = useState({});
  const [showProgress, setShowProgress] = useState(true);
  const [propertyData, setPropertyData] = useState(property);
  const params = {
    propertyId: `${property.hash}|${property.rescourId}`, 
    datatype: searches[0] || 'property'
  };
  
  useEffect(() => {
    let isMounted = true;
    async function fetchData() {
      if (!isFetching) {
        try {
          let propertyValue;
          const type = pin.type;
           
          if (type === 'property') {
            propertyValue = pin;
          } else if (type === 'lease' || type === 'availability') {
            propertyValue = get(pin, 'property');
          } else {
            propertyValue = get(pin, 'properties')[0];
          }

          if (!propertyValue) {
            throw new Error('No property available');
          }

          setPropertyData(propertyValue);

          const address = get(propertyValue, 'address');
          const location = get(propertyValue, 'location', {});
          let res;

          if (address) {
            res = await read(`/parcels?address=${address}`);
          } else if (res.parcels.length === 0 || !res.parcels[0].streetAddress) {
            res = await read(`/parcels?lat=${location[1]}&lng=${location[0]}`);
          }
          
          const count = (res.parcels || []).length;

          if (count === 0) {
            setParcelData({ properties: [propertyData] });
            setShowProgress(false);
            return;
          }
          const parcel = res && res.parcels[0];

          if (isMounted) {
            setParcelData(parcel);
            try {
              const wkt = get(parcel, 'location.geometry.wkt');
              if (!wkt) {
                throw new Error('No geometry');
              }

              const res = await read(`/properties?geometry=${wkt}`, null, null, { timeout: 2000 });
              if (!res.find((p) => p.rescourId === property.rescourId)) {
                res.push(propertyData);
              }
              setParcelData({ ...parcel, properties: res });
            } catch (error) {
              setParcelData({ ...parcel, properties: [propertyData] });
            }
            setShowProgress(false);
          }
        } catch (error) {
          if (isMounted) {
            setParcelData({ properties: [] });
            setShowProgress(false);
          }
        }
      }
    }
  
    fetchData();
  
    return () => {
      isMounted = false;
    };
  }, [isFetching, pin, propertyData]);

  return (
    <PopUp 
      closePopup={closePopup}
      bounds={bounds}
      classes={classes}
      parcelData={parcelData}
      isFetching={isFetching}
      property={propertyData}
      parcelPopup={parcelPopup}
      searches={searches}
      showProgress={showProgress}
      params={params}
      setParcelProperties={setParcelProperties}
      trackEvent={trackEvent} />
  );
};

ParcelPopUp.propTypes = {
  bounds: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  closePopup: PropTypes.func.isRequired,
  currentListView: PropTypes.string,
  hideAllFilters: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  parcelPopup: PropTypes.bool.isRequired,
  pin: PropTypes.array.isRequired,
  property: PropTypes.object.isRequired,
  searches: PropTypes.array.isRequired,
  setParcelProperties: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired
};

export default withStyles(styles)(ParcelPopUp);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ParcelHeader from './ParcelHeader';
import ParcelTiles from './ParcelTiles';
import styles from './module.css';
import AppBar from '@material-ui/core/AppBar';

const Info = ({ assessments, children, classes, parcel, ...props }) => {
  return (
    <div style={{ overflowY: 'scroll' }}>
      <ParcelHeader assessment={assessments && assessments[0]} parcel={parcel} />
      <AppBar position="sticky" className={classes.datatypeMenuStickyWrapper}>
        <ParcelTiles
          parcel={parcel}
          {...props} />
      </AppBar>
      {React.isValidElement(children) ?
        React.cloneElement(children, {
          assessments,
          isNested: true,
          parcel,
          ...props
        })
        : null
      }
    </div>
  );
};

Info.propTypes = {
  assessments: PropTypes.array.isRequired,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  parcel: PropTypes.object
};

export default withStyles(styles)(Info);

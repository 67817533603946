import get from 'lodash/get';
import {
  CLEAR_SELECTED_SAVEDSEARCH,
  CREATED_SAVEDSEARCH,
  DELETED_SAVEDSEARCH,
  SELECT_SAVEDSEARCH,
  SET_MAP_POLYGONS,
  UPDATED_SAVEDSEARCH,
  LOAD_RECENT_SAVEDSEARCHES_REQUEST,
  LOAD_RECENT_SAVEDSEARCHES_SUCCESS,
  LOAD_RECENT_SAVEDSEARCHES_FAILURE,
  CLEAR_MAP_POLYGONS,
  SNACKBAR_MAP_MESSAGE
} from '../constants/actionTypes';
import { ability } from '../auth/ability';
import { read } from '../services/api';
import { myAlertUpdated } from '../account/actions';

export function selectSavedSearch(savedSearch) {
  const polygonExists = get(
    savedSearch,
    `query.${savedSearch.enabledDataTypes[0]}.query.bool.must[0].bool.should`,
    false);
  return (dispatch, getState) => {
    if (polygonExists) {
      const polygons = polygonExists.map((geoPolygon) => (
        geoPolygon.geo_polygon.location.points.map((geoPoint) => (
          { lat: geoPoint[1], lng: geoPoint[0] }
        ))
      ));
      dispatch({ type: SET_MAP_POLYGONS, polygons });
    } else {
      dispatch({ type: CLEAR_MAP_POLYGONS });
    }

    const { research: { searches } } = getState();
    dispatch({ type: SELECT_SAVEDSEARCH, savedSearch, searches });

    dispatch({
      type: SNACKBAR_MAP_MESSAGE,
      text: savedSearch.title, secondaryText: 'Search Loaded', textType: 'success' });
  };
}

export function loadRecentSavedSearches() {
  return (dispatch) => {
    dispatch({ type: LOAD_RECENT_SAVEDSEARCHES_REQUEST });
    const query = { limit: 5, sort: '-updatedAt' };
    if (ability.cannot('use', '_app')) return new Promise((resolve) => resolve([]));
    return read('savedSearches', null, query)
      .then((results) => {
        return results.map((result) => ({ ...result, _type: 'savedSearch' }));
      })
      .then((results) => {
        const data = results.map((result) => ({ text: result.title, value: result }));
        dispatch({ type: LOAD_RECENT_SAVEDSEARCHES_SUCCESS, data });
      })
      .catch((error) => {
        dispatch({ type: LOAD_RECENT_SAVEDSEARCHES_FAILURE, error });
      });
  };
}

export function clearSelectedSavedSearch() {
  return {
    type: CLEAR_SELECTED_SAVEDSEARCH
  };
}

export function savedSearchCreated(savedSearch) {
  return {
    type: CREATED_SAVEDSEARCH,
    savedSearch
  };
}

export function savedSearchUpdated(savedSearch) {
  return (dispatch) => {
    dispatch(myAlertUpdated(savedSearch));
    dispatch({
      type: UPDATED_SAVEDSEARCH,
      savedSearch
    });
  };
}

export function savedSearchDeleted(savedSearch) {
  return {
    type: DELETED_SAVEDSEARCH,
    savedSearch
  };
}

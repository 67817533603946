export default (theme) => ({
  adornment: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: `calc(50% - ${theme.spacing(2.25)}px)`
  },
  input: {
    background: 'white'
  },
  loading: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing()
  },
  optIcon: {
    marginRight: theme.spacing(1.5),
    opacity: 0.6
  },
  optPrimary: {},
  optSecondary: {
    fontSize: '85%',
    fontStyle: 'italic'
  },
  optSep: {
    whiteSpace: 'pre-wrap'
  },
});

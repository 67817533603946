import React from 'react';
import PropTypes from 'prop-types';
import Leaflet from 'leaflet';
import { difference, intersection } from 'lodash';
import datatypes from '../../common/datatypes';

export default class Areas extends React.Component {
  static propTypes = {
    areas: PropTypes.array.isRequired,
    highlightAreaId: PropTypes.string,
    map: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { areas, map } = this.props;
    this.getTallies(this.props);
    map.on('load', () => areas.map((area) => this.addArea(area, map)));
  }

  shouldComponentUpdate(nextProps) {
    return this.props.areas !== nextProps.areas || this.props.highlightAreaId !== nextProps.highlightAreaId;
  }

  UNSAFE_componentWillUpdate(nextProps) {
    this.getTallies(nextProps);

    // update all areas
    const oldAreaIds = this.props.areas.map((area) => area.id);
    const newAreaIds = nextProps.areas.map((area) => area.id);
    difference(oldAreaIds, newAreaIds).forEach((id) => {
      this.removeArea(
        this.props.areas.find((area) => area.id === id),
        nextProps.map
      );
    });
    difference(newAreaIds, oldAreaIds).forEach((id) => {
      this.addArea(
        nextProps.areas.find((area) => area.id === id),
        nextProps.map,
        nextProps.highlightAreaId === id
      );
    });
    intersection(newAreaIds, oldAreaIds).forEach((id) => {
      this.updateArea(
        nextProps.areas.find((area) => area.id === id),
        nextProps.map,
        nextProps.highlightAreaId === id
      );
    });
  }

  getTallies = (props) => {
    // get tallies of how many of each type so can scale opacity appropriately
    this.tallies = props.areas.reduce((acc, cur) => {
      acc[cur.type] = (acc[cur.type] || 0) + 1;
      return acc;
    }, {});
  };

  getStyle = (type, highlight) => {
    const opacityMult = 1 - Math.min(1, (this.tallies[type] - 1) / 10);
    const style = {
      color: datatypes[type].color,
      fillOpacity: 0.1 + 0.5 * opacityMult,    // 0.1 - 0.6
      weight: 1,
      opacity: 0.25 + 0.5 * opacityMult        // 0.25 - 0.75
    };
    if (highlight) {
      style.weight = 4;
      style.opacity = 1;
    }
    return style;
  };

  addArea = ({ id, shapes, type }, map, highlight) => {
    // shapes look like [[[lngLats fill], [lngLats hole]], [[lngLats fill], [lngLats hole]]], but we don't use holes
    // need to switch to latLngs for mapping (lngLats are for elasticsearch)
    const polygons = shapes.map((s) => [s.map((cs) => cs.map((c) => c.reverse()))]);
    Leaflet
      .polygon(
        polygons,
        {
          ...this.getStyle(type, highlight),
          id,
          type,
        }
      )
      .addTo(map);
  };

  updateArea({ id, type }, map, highlight) {
    const leafletId = Object.keys(map._layers).find((x) => map._layers[x].options.id === id);
    if (leafletId) {
      if (highlight) {
        map._layers[leafletId].bringToFront();
      }
      map._layers[leafletId].setStyle(this.getStyle(type, highlight));
    }
  }

  removeArea({ id, type }, map) {
    Object.keys(map._layers)
      .filter((layerId) => {
        const { options } = map._layers[layerId];
        return (options.type === type && options.id === id);
      })
      .forEach((layerId) => map._layers[layerId].remove());
  }

  render() {
    return null;
  }
}

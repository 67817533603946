import { CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { SERIES } from '../../../constants/demographicsOptions';

// eslint-disable-next-line no-unused-vars
const demos = Object.entries(SERIES).map(([name,obj]) => obj);

export const Demographics = (props) => {
  const { isFeatureFetching, demographic } = props;
  const [value, setValue] = React.useState(demographic.name);
  const getBoundary = (name) => {
    const demo = demos.find((d) => d.key === name);
    if (demo) {
      return props.setDemographic({ ...demo });
    }
    props.setDemographic({ name: null, value: null, format: null });
  };

  const handleChange = (event) => {
    const { name, value } = (event.target);
    getBoundary(name);
    setValue(value);
  };

  return (
    <FormControl>
      <RadioGroup
        value={value}
        onChange={handleChange}>
        <FormControlLabel
          value="None"
          name={null}
          control={<Radio />}
          label="None" />
        {demos.map(({ key, name }) => {
          return (<FormControlLabel
            key={key}
            name={key}
            value={name}
            control={
              isFeatureFetching && value === name ?
                <CircularProgress value={0} style={{ height: '19px', width: '19px', margin: '10px' }} /> : <Radio />
            }
            label={name} />);
        })}
      </RadioGroup>
    </FormControl>
  );
};

Demographics.propTypes = {
  demographic:PropTypes.object,
  featureContent: PropTypes.array,
  featureMeta: PropTypes.array,
  featureType: PropTypes.string.isRequired,
  fetchGISFeature: PropTypes.func.isRequired,
  isFeatureFetching: PropTypes.bool,
  leafletMap: PropTypes.object.isRequired,
  removeFeature: PropTypes.func.isRequired,
  setDemographic: PropTypes.func.isRequired
};

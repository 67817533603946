/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import { previousPath } from '../../common/getLocationTitles';
import { read } from '../../services/api';
import ParcelTabs from './ParcelTabs';
import styles from './module.css';

const ParcelDetail = ({
  assessments = [],
  children,
  classes,
  fetchParcel,
  fetchParcelByAddress,
  location,
  mapProperty,
  params,
  parcel,
  properties,
  router,
  clearCurrentProperty,
  setCurrentProperty,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState(true);
  const { apn, fips } = params;
  
  const allProperties = properties;

  if (mapProperty && properties && !properties.find((p) => p.id === mapProperty.id)) {
    allProperties.push(mapProperty);
  }

  useEffect(() => {
    async function fetchData() {
      setIsFetching(true);
      try {
        const result = await fetchParcel({ fips, apn });
        let parcel = get(result, 'data.parcel.parcels.0');

        if (!parcel && params.propertyId) {
          const { address } = await read(`/properties/${params.propertyId}`).catch(() => null);
          if (address) {
            const { data } = await fetchParcelByAddress({ address });
            parcel = data;
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    }
    fetchData();
  }, [apn, fips]);

  const handleClose = () => {
    clearCurrentProperty();
    router.push({ ...location, pathname: `/research/${previousPath()}` });
  };

  return (
    <Paper
      className={classes.parcelWrapper}
      elevation={1}
      style={{
        alignItems: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        width: '100%',
      }}>
      <div className={classes.parcelWrapperHeader}>
        <ParcelTabs
          isFetching={isFetching}
          key={`${apn}-${fips}`}
          location={location}
          parcel={parcel}
          params={params}
          properties={allProperties}
          propertyId={params.propertyId}
          setCurrentProperty={setCurrentProperty} />
        <div className={classes.closeButtonWrapper}>
          <IconButton className={classes.closeButton} onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      {React.isValidElement(children) ?
        React.cloneElement(children, {
          assessments,
          isNested: true,
          parcel,
          ...props
        })
        : null
      }
    </Paper>
  );
};

ParcelDetail.propTypes = {
  assessments: PropTypes.array,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  clearCurrentProperty: PropTypes.func.isRequired,
  dataUri: PropTypes.string,
  demographics: PropTypes.object,
  fetchParcel: PropTypes.func.isRequired,
  fetchParcelByAddress: PropTypes.func.isRequired,
  fetchParcelProperties: PropTypes.func.isRequired,
  isFetchingParcelDetails: PropTypes.bool.isRequired,
  isFetchingProperties: PropTypes.bool.isRequired,
  isViewReportDisabled: PropTypes.bool,
  location: PropTypes.object.isRequired,
  mapProperty: PropTypes.object,
  params: PropTypes.object,
  parcel: PropTypes.object,
  properties: PropTypes.array,
  property: PropTypes.object,
  reports: PropTypes.array,
  router: PropTypes.object.isRequired,
  setCurrentProperty: PropTypes.func.isRequired,
  transactions: PropTypes.array
};

export default withStyles(styles)(withRouter(ParcelDetail));

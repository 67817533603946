import {
  DETAIL_TIM_FETCH_REQUEST,
  DETAIL_TIM_FETCH_ERROR,
  DETAIL_TIM_FETCH_SUCCESS,
  DETAIL_TIM_CLEAR,
  DETAIL_COMMENT_SAVE_REQUEST,
  DETAIL_COMMENT_SAVE_SUCCESS,
  DETAIL_COMMENT_DELETE_SUCCESS,
  DETAIL_COMMENTS_FETCH_REQUEST,
  DETAIL_COMMENTS_FETCH_SUCCESS,
} from '../../constants/actionTypes';

const initialState = {
  comments: [],
  fetchError: null,
  files: [],
  tim: {},
  isFetching: false,
  isSavingComment: false,
  isFetchingComments: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DETAIL_TIM_FETCH_REQUEST:
      return {
        ...state,
        fetchError: null,
        tim: {},
        isFetching: true,
      };

    case DETAIL_TIM_FETCH_ERROR:
      return {
        ...state,
        fetchError: action.error,
        isFetching: false,
      };

    case DETAIL_TIM_FETCH_SUCCESS:
      return {
        ...state,
        fetchError: null,
        tim: action.data,
        isFetching: false,
      };

    case DETAIL_TIM_CLEAR:
      return {
        ...state,
        tim: {},
      };

    case DETAIL_COMMENT_SAVE_REQUEST:
      return { ...state, isSavingComment: true };

    case DETAIL_COMMENT_SAVE_SUCCESS:
      return {
        ...state,
        comments: state.comments.concat([action.data]),
        isSavingComment: false,
      };

    case DETAIL_COMMENT_DELETE_SUCCESS:
      return {
        ...state,
        files: state.comments.filter((comment) => comment.id !== action.data.id)
      };

    case DETAIL_COMMENTS_FETCH_REQUEST:
      return {
        ...state,
        comments: [],
        isFetchingComments: true,
      };

    case DETAIL_COMMENTS_FETCH_SUCCESS:
      return {
        ...state,
        comments: action.data,
        isFetchingComments: false,
      };
  }
  return state;
}

import React from 'react';
import sortBy from 'lodash/sortBy';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { history } from '../store';
import { previousPath } from './getLocationTitles';

const getReports = function ({ reports, dataType, type, filter }) {
  const _reports = reports.filter((report) => {
    return (report.type === type && (report.dataTypes.includes('*') || report.dataTypes.includes(dataType)));
  });
  return (filter) ? _reports.filter(filter) : _reports;
};

const openReportView = (report, dataType, closeMenu) => () => {
  history.push({
    ...location,
    pathname: `/research/${previousPath()}/reports`,
    query: Object.assign({}, location.query, { selectedReport: report.id, currentListView: dataType })
  });
  if (closeMenu) closeMenu();
};

const toMenuItem = (dataType, onAfterOpen) => (report) => (  // eslint-disable-line react/display-name
  <MenuItem key={report.id} onClick={openReportView(report, dataType, onAfterOpen)}>
    {report.title}
  </MenuItem>
);

export default ({ currentListView: dataType, currentUser, closeMenu }) => {
  const reports = sortBy(currentUser.reports, 'title');

  const publicReports = getReports({
    reports,
    dataType,
    type: 'list',
    filter(r) { return r.isPublic; }
  }).map(toMenuItem(dataType, closeMenu));

  const customReports = getReports({
    reports,
    dataType,
    type: 'list',
    filter(r) { return !r.isPublic; }
  }).map(toMenuItem(dataType, closeMenu));
  
  if (customReports.length > 0 && publicReports.length > 0) {
    return [
      ...customReports,
      <Divider key="divider" />,
      ...publicReports
    ];
  }
  return [
    ...customReports,
    ...publicReports
  ];

  /*
  return (
    <IconMenu
      onChange={handleSelectReport(dataType)}
      iconButtonElement={<IconButton tooltip="Preview PDF"><VisibilityIcon color={iconColor} /></IconButton>}
      anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
      targetOrigin={{ horizontal: 'left', vertical: 'top' }}>
      {customReports.map((report) => (<MenuItem key={report.id} value={report} primaryText={report.title} />))}
      {customReports.length > 0 && publicReports.length > 0 && <Divider />}
      {publicReports.map((report) => (<MenuItem key={report.id} value={report} primaryText={report.title} />))}
    </IconMenu>
  );
  */
};

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { GUTTER_SIZE } from '../constants';
import styles from '../map/module.css';
import { previousPath } from '../../common/getLocationTitles';

const TableDetail = ({ children, classes, containerWidth }) => {
  const sidePosition = (containerWidth < 1280) ? GUTTER_SIZE : (GUTTER_SIZE * 14);
  const wrapperRef = useRef(null);
  const outerRef = useRef(null);
  const handleClickOutside = (event) => {
    if (outerRef.current && outerRef.current.contains(event.target) && !wrapperRef.current.contains(event.target)) {
      children.props.router.push({ ...children.props.location, pathname: `/research/${previousPath()}` });
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  }, [handleClickOutside]);

  return (
    <div
      ref={outerRef}
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.7)',
        position: 'absolute',
        zIndex: 1000,
      }}>
      <div
        className={classes.detailsContainer}
        ref={wrapperRef}
        style={{
          bottom: GUTTER_SIZE,
          top: GUTTER_SIZE,
          left: sidePosition,
          right: sidePosition,
          opacity: 1
        }}>
        {children}
      </div>
    </div>
  );
};

TableDetail.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  containerWidth: PropTypes.number,
};

export default withStyles(styles)(TableDetail);

import {
  property,
  lease,
  availability,
  sale,
  listing,
  development,
  company,
  news,
  tim
} from './datatypes';

export default {
  property,
  lease,
  availability,
  sale,
  listing,
  development,
  company,
  news,
  tim
};

import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgComponent = (props) => (
  <SvgIcon
    viewBox="0 0 36 36" role="img" aria-labelledby="title"
    {...props}>
    <g
      id="Field-Notes" stroke="none" strokeWidth="1"
      fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(7.000000, 5.000000)">
        <rect
          id="Rectangle" stroke={props.color || '#000000'} strokeWidth="2.25"
          x="0" y="0.75" width="22.5"
          height="25.5" rx="1.5" />
        <rect
          id="Rectangle" fill={props.color || '#000000'} x="4.5"
          y="6.75" width="13.5" height="3" />
        <rect
          id="Rectangle" fill={props.color || '#000000'} x="4.5"
          y="12.75" width="13.5" height="3" />
        <rect
          id="Rectangle" fill={props.color || '#000000'} x="4.5"
          y="18.75" width="7.5" height="3" />
      </g>
    </g>
  </SvgIcon>
);
SvgComponent.propTypes = {
  color: PropTypes.string
};

export default SvgComponent;

import {
  ANALYSIS_REPORT_REQUEST,
  ANALYSIS_REPORT_SUCCESS,
  ANALYSIS_REPORT_ERROR,
  ANALYSIS_REPORT_ERROR_DISMISSED
} from '../../constants/actionTypes';

export default function (state = {
  isFetchingPdf: false,
  pdfErrorMsg: ''
}, action) {
  switch (action.type) {
    case ANALYSIS_REPORT_REQUEST:
      return {
        ...state,
        isFetchingPdf: true,
      };

    case ANALYSIS_REPORT_SUCCESS:
      return {
        ...state,
        isFetchingPdf: false,
      };

    case ANALYSIS_REPORT_ERROR:
      return {
        ...state,
        isFetchingPdf: false,
        pdfErrorMsg: action.message
      };

    case ANALYSIS_REPORT_ERROR_DISMISSED:
      return {
        ...state,
        pdfErrorMsg: ''
      };
  }

  return state;
}
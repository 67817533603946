import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css.js';

const AccountLoadingWrapper = ({ classes, accountType }) => {
  return (
    <div className={classes.accountLoadingWrapper}>
      <CircularProgress color="primary" className={classes.accountLoadingCircle} />
      <Typography
        className={classes.accountLoadingDescription}
        variant="body2">Please Wait, {accountType} Loading...</Typography>
    </div>
  );
};

AccountLoadingWrapper.propTypes = {
  accountType: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(AccountLoadingWrapper));

import React from 'react';
import PropTypes from 'prop-types';
import formatStat from './formatStat';

const TourDates = ({ value }) => {
  return (
    <div>
      {value.map((tourDate, index) => {
        return (
          <div key={index}>{formatStat(tourDate, { format: 'date' })}</div>
        );
      })}
    </div>
  );
};

TourDates.propTypes = {
  value: PropTypes.any
};

export default TourDates;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Delete from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { read } from '../services/api';
import styles from './module.css';

const columns = [
  { id: 'thumbnail', label: '', minWidth: 100, align: 'left' },
  {
    id: 'title',
    label: 'Application',
    minWidth: 100,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'description',
    label: 'Description',
    minWidth: 170,
    align: 'left',
    format: (value) => value.toLocaleString('en-US'),
  }
];

function createData(description, thumbnail, title, url) {
  return { description, thumbnail, title, url, code: url };
}

const MyBookmarksWidgetTable = ({ classes, onRemove }) => {
  const [myBookmarksData, setMyBookmarksData] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [nullState, setNullState] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    let abortController = new window.AbortController();
    const fetchData = async () => {
      try {
        const results = await read('/pinnedApps', null, null, { signal: abortController.signal });
        if (results.data.length === 0) setNullState(true);
        setMyBookmarksData(results.data);
        setHasLoaded(true);
        abortController = null;
      } catch (error) {
        if (abortController && !abortController.signal.aborted) {
          setHasError(true);
        }
      }
    };
    fetchData();
    return () => {
      if (abortController) {
        abortController.abort(); 
      }
    };
  }, []);

  const rows = myBookmarksData.map((row) => {
    return createData(
      row.description,
      row.thumbnail,
      row.title, 
      row.url, 
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const loadApp = (url) => {
    if (url === undefined || url === '') return;
    return window.open(url);
  };

  const renderColumnValue = (column, value, url) => {
    if (column.id === 'title' || column.id === 'url') {
      const targetUrl = column.id === 'url' ? value : url;
      const isDisabled = !value || url === undefined || url === '';
      
      return (
        <div 
          className={`${classes.myBookmarksTitle} ${isDisabled ? classes.disabled : ''}`} 
          onClick={() => { loadApp(targetUrl); }}>
          {value}
        </div>
      );
    }
    if (column.id === 'description') {
      const textValueOnly = value.replace(/[#*]/g, '');
      return (
        <div className={classes.myBookmarksDescription}> {textValueOnly} </div>
      );
    }
    if (column.id === 'thumbnail') {
      return <img className={classes.myBookmarksThumbnail} src={value} alt="Thumbnail" />;
    }
    return value;
  };

  if (hasError) {
    return (
      <div className={classes.errorWrapper}>
        <Typography variant="h6">{'An error occurred while loading your Bookmarked Apps'}</Typography>
      </div>
    );
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      { hasLoaded ? (<TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader className={classes.tableHeadRoot}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: 200, borderBottom: '2px solid #25408F' }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!nullState && rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow 
                    key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {renderColumnValue(column, value, row.url)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {nullState && 
        <div>
          <Typography style={{ display: 'block', textAlign: 'center', marginBottom: '10px', marginTop: '20px' }}>
            You haven’t bookmarked any apps yet. 
          </Typography>
          <Typography style={{ display: 'block', textAlign: 'center' }}>
            Go to <a href="https://my.colliers.com/launcher" className={classes.link}> MyColliers </a> and save your favorite apps to show them here.
          </Typography>
        </div>}
      </TableContainer>) : (<div className={classes.spinner}><CircularProgress  /></div>)}

      <div className={`${nullState ? classes.hidePagination : classes.tableFooter}`}>
        {!nullState && (
          <TablePagination
            className={classes.tablePagination}
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage} />
        )}
        <Tooltip title="Remove Widget">
          <IconButton className={classes.removeButton} onClick={onRemove}> 
            <Delete />
          </IconButton>
        </Tooltip>
      </div>
    </Paper>
  );
};

MyBookmarksWidgetTable.propTypes = {
  classes: PropTypes.object.isRequired,
  hasLoaded: PropTypes.bool,
  nullState: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
};

export default withStyles(styles)(MyBookmarksWidgetTable);

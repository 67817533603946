import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from './actions';
import { withStyles } from '@material-ui/core/styles';
import AccountSubheader from './AccountSubheader';
import styles from './module.css';

const Container = ({ children, classes, loadRecentMyAlerts, loadRecentMyLists, location }) => {
  useEffect(() => {
    loadRecentMyLists();
    loadRecentMyAlerts();
  }, []);
  return (
    <main className={classes.accountPageMain}>
      <div className={classes.accountPageWrapper}>
        <AccountSubheader location={location} />
        {children}
      </div>
    </main>
  );
};

Container.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  loadRecentMyAlerts: PropTypes.func.isRequired,
  loadRecentMyLists: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default connect(
  null,
  { ...actions }
)(withStyles(styles)(Container));

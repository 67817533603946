import {
  CLEAR_SELECTED_SAVEDSEARCH,
  CREATED_SAVEDSEARCH,
  DELETED_SAVEDSEARCH,
  SELECT_SAVEDSEARCH,
  UPDATED_SAVEDSEARCH,
  LOAD_RECENT_SAVEDSEARCHES_REQUEST,
  LOAD_RECENT_SAVEDSEARCHES_FAILURE,
  LOAD_RECENT_SAVEDSEARCHES_SUCCESS
} from '../constants/actionTypes';

const initialState = {
  selectedSavedSearch: {},
  recentSavedSearches: [],
  savedSearchFailure: {}
};

export default function spotlight(state = initialState, action) {
  switch (action.type) {
    case CREATED_SAVEDSEARCH:
    case UPDATED_SAVEDSEARCH:
    case SELECT_SAVEDSEARCH:
      return {
        ...state,
        selectedSavedSearch: action.savedSearch
      };
    case LOAD_RECENT_SAVEDSEARCHES_REQUEST:
      return {
        ...state,
        recentSavedSearches: []
      };
    case LOAD_RECENT_SAVEDSEARCHES_SUCCESS: 
      return {
        ...state, 
        recentSavedSearches: action.data
      };
    
    case LOAD_RECENT_SAVEDSEARCHES_FAILURE:
      return {
        ...state,
        savedSearchFailure: action.data
      };
    
    case CLEAR_SELECTED_SAVEDSEARCH:
    case DELETED_SAVEDSEARCH:
      return {
        ...state,
        selectedSavedSearch: {}
      };

    default:
      return state;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import GaugeChart from './charts/GaugeChart';
import BuildingClassChart from './charts/BuildingClassChart';
import ExpirationsChart from './charts/ExpirationsChart';
import RateByYearChart from './charts/RateByYearChart';
import TermAndAreaByYearChart from './charts/TermAndAreaByYearChart';

class PrintableChart extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      chartImg: null
    };
  }

  onAnimationComplete = (chartjsInstance) => {
    if (!this.state.chartImg) {
      this.setState({ chartImg: chartjsInstance.toBase64Image() });
    }
  };

  render() {
    const { type } = this.props;
    const subProps = {
      ...this.props,
      ...this.state,
      onAnimationComplete: this.onAnimationComplete
    };
    return (
      <div>
        {type === 'buildingClass' && <BuildingClassChart {...subProps} />}
        {type === 'expirations' && <ExpirationsChart {...subProps} />}
        {type === 'gauge' && <GaugeChart {...subProps} />}
        {type === 'rateByYear' && <RateByYearChart {...subProps} />}
        {type === 'termAndAreaByYear' && <TermAndAreaByYearChart {...subProps} />}
      </div>
    );
  }
}
    
export default PrintableChart;
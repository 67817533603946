export default (theme) => ({
  complete: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(1.5)}px`,
    textAlign: 'center',
  },
  connector: {
    minHeight: theme.spacing(1.5),
  },
  container: {
    alignItems: 'center',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
  },
  defaultLocationLine: {
    alignContent: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  onboarding: {
    maxHeight: 'calc(100% - 10px)',
    maxWidth: 500,
    overflowX: 'hidden',
    overflowY: 'auto',
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  stepText: {
    lineHeight: 2,
  },
  stepTitle: {
    fontSize: '140%',
  },
});
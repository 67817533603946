import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { showProblemReporter, hideProblemReporter } from '../problemReporter/actions';
import * as actions from './actions';
import TimDetail from './TimDetail';

class TimDetailContainer extends React.Component {
  static propTypes = {
    clearTim: PropTypes.func.isRequired,
    fetchTim: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    tim: PropTypes.object,
    timId: PropTypes.string.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.props.fetchTim(this.props.timId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.timId !== this.props.timId) {
      nextProps.fetchTim(nextProps.timId);
    }
  }

  componentWillUnmount() {
    this.props.clearTim();
  }

  render() {
    return (<TimDetail {...this.props} />);
  }
}

function mapStateToProps({ research: { timDetail, problemReporter } }, { params }) {
  const { timId } = params;
  const {
    isFetching,
    tim
  } = timDetail;
  const {
    isProblemReporterVisible,
    problemItem
  } = problemReporter;

  return {
    timId,
    tim,
    isFetching,
    isProblemReporterVisible,
    problemItem,
  };
}

export default connect(
  mapStateToProps,
  { ...actions, hideProblemReporter, showProblemReporter }
)(TimDetailContainer);

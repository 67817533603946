import { grey } from '@material-ui/core/colors';

export default (theme) => ({
  canvasImg: {
    display: 'none',
    width: '100%',
  },
  chartContainer: {
    padding: '0 20px 20px',
    flex: '1 1 0',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  chartRow: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'nowrap',
  },
  chartTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: grey[700],
    whiteSpace: 'nowrap',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  divider: {
    height: 0,
    padding: `${theme.spacing(4)}px 0`,
    width: '100%',
    ['&::after']: {
      content: '""',
      borderTop: '1px solid #DDD',
      display: 'block',
    }
  },
  dividerRight: {
    borderRight: '1px solid #DDD',
  },
  gaugeEnds: {
    color: '#CCC',
    lineHeight: 'normal',
    position: 'relative',
    fontSize: '15px',
    fontWeight: 'bold',
    margin: '-4px 7% 0', // donut rendering leaves about x% on each side
  },
  gaugeEndLeft: {
    position: 'absolute',
    width: 100,
    textAlign: 'center',
    left: -50,
  },
  gaugeEndRight: {
    position: 'absolute',
    width: 100,
    textAlign: 'center',
    right: -50,
  },
  gaugeHeader: {
    //color: '#13d360',
    fontSize: '40px',
  },
  gaugeLabels: {
    textAlign: 'center',
    marginTop: -62,
    lineHeight: '35px',
  },
  gaugeSubheader: {
    textTransform: 'uppercase',
    color: '#555',
    fontSize: '13px',
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: grey[900],
  },
  headerTitle: {
    flex: 1,
    marginLeft: theme.spacing(2.5),
  },
  headerToolbar: {
    marginRight: 60,
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing()
  },
  leaseCount: {
    backgroundColor: grey[200],
    display: 'inline-block',
    padding: '0 20px',
    borderRadius: 20,
    fontSize: '24px',
    marginRight: 10,
    verticalAlign: 'middle'
  },
  leaseCountLine: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: grey[700],
    whiteSpace: 'nowrap',
    lineHeight: '36px',
    background: 'white',
    display: 'inline-block',
    paddingRight: 20,
    position: 'relative',
    top: -50,
  },
  noResults: {
    margin: '0 auto',
    padding: theme.spacing(6),
    textAlign: 'center',
    width: '75%',
  },
  statsContainer: {
    maxHeight: '100%',
    overflowY: 'auto',
    padding: '10px 20px 40px',
    textAlign: 'left',
    width: '100%',
  },
});


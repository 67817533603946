import ListingIcon from '@material-ui/icons/LocationCity';
import NewsIcon from '@material-ui/icons/RssFeed';
import LeaseIcon from '@material-ui/icons/Domain';
import AvailabilityIcon from '@material-ui/icons/AccountBalance';
import SaleIcon from '@material-ui/icons/AttachMoney';
import PropertyIcon from '@material-ui/icons/Place';
import DevelopmentIcon from '@material-ui/icons/Build';
import CompanyIcon from '@material-ui/icons/Work';
import TimIcon from '@material-ui/icons/LocationSearching';
import {
  red,
  blue,
  green,
  lightGreen,
  pink,
  orange,
  blueGrey,
  indigo,
} from '@material-ui/core/colors';
import createDatatype from './createDatatype';
import createFilter from './createFilter';
import {
  ONE_DAY_INTERVAL,
} from './constants';

const dataOwnerFilter = () => {
  const translations = {};
  return createFilter('__tenant', 'refinement', {
    isCollapsed: true,
    title: 'Data Source',
    translations
  });
};

export const property = createDatatype('property', {
  Icon: PropertyIcon,
  color: '#25408F',
  permission: ['/properties/:id/info', 'get'],
  isShownInFeed: true,
  filters: [
    dataOwnerFilter(),
    createFilter('name', 'text', { title: 'Property Name', wildcard: false }),
    createFilter('address', 'text', { title: 'Property Address', wildcard: false }),
    createFilter('propertyTypes', 'refinement', { title: 'Property Type' }),
    createFilter('propertyUses', 'refinement', { title: 'Property Uses' }),
    createFilter('buildingClass', 'refinement', { title: 'Building Class' }),
    createFilter('market', 'refinement', { title: 'Markets' }),
    createFilter('submarket', 'refinement', { title: 'Submarkets' }),
    createFilter('micromarket', 'refinement', { title: 'Micro-Markets' }),
    createFilter('county', 'refinement', { title: 'County' }),
    createFilter('city', 'cityArray', { title: 'City' }),
    createFilter('zipCode', 'zipCodeArray', { title: 'Zip Code' }),
    createFilter('rentableBuildingArea', 'simpleRange', { title: 'Property Size' }),
    createFilter('currentDevelopment.developmentStatus.untouched', 'refinement', { title: 'Development Status' }),
    createFilter('averageFloorArea', 'simpleRange', { title: 'Average Floor Area' }),
    createFilter('units', 'simpleRange', { title: 'Number of Units' }),
    createFilter('landArea', 'simpleDecimalRange', { title: 'Land Area' }),
    createFilter('occupancyType.untouched', 'refinement', { title: 'Occupancy Type' }),
    createFilter('yearBuilt', 'simpleRange', { min: 0, max: 2500 , title: 'Year Built' }),
    createFilter('yearRenovated', 'simpleRange', { min: 0, max: 2500 , title: 'Year Renovated' }),
    createFilter('assessmentYear', 'simpleRange', { min: 0, max: 2500 , title: 'Year Assessed' }),
    createFilter('owners.name', 'text', { title: 'Owners' }),
    createFilter('parkingRatio', 'simpleRange', { title: 'Parking Ratio' }),
    createFilter('clearHeightMin', 'simpleRange', { title: 'Clear Height' }),
    createFilter('dockDoors', 'simpleRange', { title: 'Dock Doors' }),
    createFilter('driveIns', 'simpleRange', { title: 'Drive-ins' }),
    createFilter('columnSpacingWidth', 'simpleRange', { title: 'Column Spacing (Width)' }),
    createFilter('amperage', 'simpleRange', { title: 'Amperage' }),
    createFilter('voltage', 'simpleRange', { title: 'Voltage' }),
    createFilter('electricalPhase', 'simpleRange', { title: 'Electrical Phase' }),
    createFilter('sprinklers', 'text', { title: 'Sprinklers' }),
    createFilter('tags', 'textArray', { title: 'Tags' }),
    createFilter('zoningCodes', 'text', { title: 'Zoning Codes' }),
    createFilter('isFreeTradeZone', 'boolean', { 
      title: 'Free Trade Zone', 
    }),
    createFilter('isEnterpriseZone', 'boolean', { 
      title: 'Enterprise Zone'
    }),
    createFilter('createdAt', 'dateRange', {
      title: 'Created At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('updatedAt', 'dateRange', {
      title: 'Last Updated At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('relatedAndCustomIdsArray', 'relatedAndCustomIdsArray', { title: 'RELATED IDS / CUSTOM IDS' }),
    createFilter('_id', 'idArray', { title: 'DISCOVER RECORD IDS' })
  ],
  sourceFilters: require('./schemas/_property').sourceFilters,
  defaultFilters: {}
});

export const lease = createDatatype('lease', {
  Icon: LeaseIcon,
  color: red[500],
  isShownInFeed: true,
  preferenceScope: ['Office', 'Industrial', 'Retail', 'Hospitality', 'Medical'],
  filters: [
    dataOwnerFilter(),
    createFilter('property.address', 'text', { title: 'Property Address', wildcard: false  }),
    createFilter('property.name', 'text', { title: 'Property Name', wildcard: false  }),
    createFilter('property.propertyTypes', 'refinement', { title: 'Property Types' }),
    createFilter('property.propertyUses', 'refinement', {
      title: 'Property Uses',
      isCollapsed: true
    }),
    createFilter('property.buildingClass', 'refinement', { title: 'Property Class' }),
    createFilter('property.market', 'refinement', { title: 'Markets' }),
    createFilter('property.submarket', 'refinement', { title: 'Submarkets' }),
    createFilter('property.micromarket', 'refinement', { title: 'Micro-markets' }),
    createFilter('property.county', 'refinement', { title: 'County' }),
    createFilter('property.city', 'cityArray', { title: 'City' }),
    createFilter('property.zipCode', 'zipCodeArray', { title: 'Zip Code' }),
    createFilter('area', 'simpleRange', { title: 'Leased Area SF' }),
    createFilter('property.landArea', 'simpleDecimalRange', { min: 0, title: 'Land Area' }),
    createFilter('leaseType', 'refinement', { title: 'Lease Type' }),
    createFilter('subLease', 'boolean', { title: 'Sublease' }),
    createFilter('leaseTerm', 'simpleRange', { title: 'Lease Term' }),
    createFilter('abatement', 'simpleRange', { title: 'Abatement' }),
    createFilter('tenantImprovement', 'simpleRange', { title: 'TI Allowance', isCollapsed: true }),
    createFilter('signedDate', 'dateRange', {
      title: 'Sign/Execution Date',
      interval: ONE_DAY_INTERVAL,
      showHistogram: true,
    }),
    createFilter('commencementDate', 'dateRange', {
      title: 'Commencement Date',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('occupancyDate', 'dateRange', {
      title: 'Occupancy Date',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('expirationDate', 'dateRange', {
      title: 'Expiration Date',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('rentRate', 'simpleRange', {
      subtype: 'currency',
      title: 'Rent Per Square Foot',
      min: 0,
      //max: 150,
      //showHistogram: true,
    }),
    createFilter('rateType', 'refinement', { title: 'Rate Type', isCollapsed: true }),
    createFilter('tenant.name', 'text', { title: 'Tenant' }),
    createFilter('tenant.industries', 'refinement', { title: 'Tenant Industry', isCollapsed: true }),
    createFilter('property.owners.name', 'text', { title: 'Landlord/Owners' }),
    createFilter('property.rentableBuildingArea', 'simpleRange', {
      title: 'Property Size',
      propertyKey: 'property',
    }),
    createFilter('property.currentDevelopment.developmentStatus.untouched', 'refinement', {
      title: 'Development Status'
    }),
    createFilter('property.yearBuilt', 'simpleRange', { min: 0, max: 2500 , title: 'Year Built' }),
    createFilter('clearHeightMin', 'simpleRange', { title: 'Clear Height' }),
    createFilter('dockDoors', 'simpleRange', { title: 'Dock Doors' }),
    createFilter('driveIns', 'simpleRange', { title: 'Drive Ins' }),
    createFilter('columnSpacingWidth', 'simpleRange', { title: 'Column Spacing' }),
    createFilter('amperage', 'simpleRange', { title: 'Amperage' }),
    createFilter('voltage', 'simpleRange', { title: 'Voltage' }),
    createFilter('sprinklers', 'text', { title: 'sprinklers' }),
    createFilter('landlordRepresentatives.name', 'text', { title: 'Landlord Representatives' }),
    createFilter('tenantRepresentatives.name', 'text', { title: 'Tenant Representatives' }),
    createFilter('tags', 'textArray', { title: 'Tags' }),
    createFilter('createdAt', 'dateRange', {
      title: 'Created At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('updatedAt', 'dateRange', {
      title: 'Last Updated At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('relatedAndCustomIdsArray', 'relatedAndCustomIdsArray', { title: 'RELATED IDS / CUSTOM IDS' }),
    createFilter('_id', 'idArray', { title: 'DISCOVER RECORD IDS' })
  ],
  sourceFilters: require('./schemas/_lease').sourceFilters,
  defaultFilters: {}
});

export const availability = createDatatype('availability', {
  Icon: AvailabilityIcon,
  color: lightGreen[500],
  isShownInFeed: true,
  preferenceScope: ['Office', 'Industrial', 'Retail', 'Hospitality', 'Medical'],
  filters: [
    dataOwnerFilter(),
    createFilter('property.name', 'text', { title: 'Property Name', wildcard: false  }),
    createFilter('property.address', 'text', { title: 'Property Address', wildcard: false  }),
    createFilter('property.propertyTypes', 'refinement', { title: 'Property Types',
      isCollapsed: true }),
    createFilter('property.propertyUses', 'refinement', {
      title: 'Property Use',
      isCollapsed: true
    }),
    createFilter('property.buildingClass', 'refinement', { title: 'Property Class',
      isCollapsed: true }),
    createFilter('property.market', 'refinement', { title: 'Markets',
      isCollapsed: true }),
    createFilter('property.submarket', 'refinement', { title: 'SubMarkets',
      isCollapsed: true }),
    createFilter('property.micromarket', 'refinement', { title: 'Micro-Markets',
      isCollapsed: true }),
    createFilter('property.county', 'refinement', { title: 'County' }),
    createFilter('property.city', 'cityArray', { title: 'City' }),
    createFilter('property.zipCode', 'zipCodeArray', { title: 'Zip Code' }),
    createFilter('areaAndMinDivisibleArea', 'andOrRange', { title: 'Available Area AND/OR Min Divisible Area' }),
    createFilter('subLease', 'boolean', { title: 'Sublease' }),
    createFilter('property.rentableBuildingArea', 'simpleRange', {
      title: 'Property Size',
      propertyKey: 'property',
    }),
    createFilter('property.landArea', 'simpleDecimalRange', { min: 0, title: 'Land Area' }),
    createFilter('property.currentDevelopment.developmentStatus.untouched', 'refinement', {
      title: 'Development Status'
    }),
    createFilter('property.yearBuilt', 'simpleRange', { min: 0, max: 2500 , title: 'Year Built' }),
    createFilter('askingRate.min', 'simpleRange', {
      title: 'Asking Rate Min',
      subtype: 'currency',
    }),
    createFilter('askingRate.max', 'simpleRange', {
      title: 'Asking Rate Max',
      subtype: 'currency',
    }),
    createFilter('rateType', 'refinement', { title: 'Rate Type', isCollapsed: true }),
    createFilter('dealStatus', 'refinement', { isCollapsed: true }),
    createFilter('availableDate', 'dateRange', {
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('occupancyDate', 'dateRange', {
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('onMarketDate', 'dateRange', { title: 'On-Market Date' }, {
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('spaceUses.type', 'refinement', {
      title: 'Space Uses',
      isCollapsed: true
    }),
    createFilter('clearHeightMin', 'simpleRange', { title: 'Clear Height' }),
    createFilter('dockDoors', 'simpleRange', { title: 'Dock Doors' }),
    createFilter('driveIns', 'simpleRange', { title: 'Drive Ins' }),
    createFilter('columnSpacingWidth', 'simpleRange', { title: 'Column Spacing' }),
    createFilter('amperage', 'simpleRange', { title: 'Amperage' }),
    createFilter('voltage', 'simpleRange', { title: 'Voltage' }),
    createFilter('electricalPhase', 'simpleRange', { title: 'Electrical Phase' }),
    createFilter('sprinklers', 'text', { title: 'sprinklers' }),
    createFilter('parkingRatio', 'simpleRange', { title: 'Parking Ratio' }),
    createFilter('property.zoningCodes', 'text', { title: 'Zoning Codes' }),
    createFilter('property.isFreeTradeZone', 'boolean', { 
      title: 'Free Trade Zone', 
    }),
    createFilter('property.isEnterpriseZone', 'boolean', { 
      title: 'Enterprise Zone'
    }),
    createFilter('leasingRepresentatives.name', 'text', {
      title: 'Leasing Representatives',
    }),
    createFilter('landlords.name', 'text', {
      title: 'Landlords',
    }),
    createFilter('expirationDate', 'dateRange', {
      title: 'Expiration Date',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('createdAt', 'dateRange', {
      title: 'Created At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('updatedAt', 'dateRange', {
      title: 'Last Updated At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('tags', 'textArray', { title: 'Tags' }),
    createFilter('relatedAndCustomIdsArray', 'relatedAndCustomIdsArray', { title: 'RELATED IDS / CUSTOM IDS' }),
    createFilter('_id', 'idArray', { title: 'DISCOVER RECORD IDS' })
  ],
  sourceFilters: require('./schemas/_availability').sourceFilters,
  defaultFilters: {
    dealStatus: ['Available'],
    areaAndMinDivisibleArea: { andOrChecked: 'AND', area: {}, minDivisibleArea: {} }
  }
});

export const sale = createDatatype('sale', {
  Icon: SaleIcon,
  color: pink[500],
  isShownInFeed: true,
  filters: [
    dataOwnerFilter(),
    createFilter('properties.address', 'text', { title: 'Property Address', wildcard: false  }),
    createFilter('properties.name', 'text', { title: 'Property Name', wildcard: false  }),
    createFilter('properties.propertyTypes', 'refinement', { title: 'Property Types', isCollapsed: true }),
    createFilter('properties.propertyUses', 'refinement', { title: 'Property Uses', isCollapsed: true }),
    createFilter('properties.buildingClass', 'refinement', { title: 'Building Class', isCollapsed: true }),
    createFilter('properties.market', 'refinement', { title: 'Markets', isCollapsed: true }),
    createFilter('properties.submarket', 'refinement', { title: 'Submarkets', isCollapsed: true }),
    createFilter('properties.micromarket', 'refinement', { title: 'Micro-markets', isCollapsed: true }),
    createFilter('properties.county', 'refinement', { title: 'County' }),
    createFilter('property.city', 'cityArray', { title: 'City' }),
    createFilter('properties.zipCode', 'zipCodeArray', { title: 'Zip Code' }),
    createFilter('transactionValue', 'simpleRange', { title: 'Price' }),
    createFilter('pricePerSquareFoot', 'simpleRange', {
      min: 0,
      max: 1000,
      subtype: 'currency',
      title: 'Price per Square Foot'
    }),
    createFilter('averageUnitPrice', 'simpleRange', {
      min: 0,
      subtype: 'currency',
      title: 'Price per Unit'
    }),
    createFilter('soldDate', 'dateRange', {
      title: 'Date Sold',
      interval: ONE_DAY_INTERVAL * 10,
      showHistogram: true,
    }),
    
    createFilter('signedDate', 'dateRange', {
      title: 'Date Signed',
      interval: ONE_DAY_INTERVAL,
      showHistogram: true,
    }),
    
    createFilter('rentableBuildingArea', 'propertySizeRange', {
      title: 'Property Size',
      propertyKey: 'properties'
    }),
    createFilter('units', 'simpleRange', { title: 'Number of Units' }),
    createFilter('properties.landArea', 'simpleDecimalRange', { min: 0, title: 'Land Area' }),
    createFilter('properties.currentDevelopment.developmentStatus.untouched', 'refinement', {
      title: 'Development Status'
    }),
    createFilter('properties.yearBuilt', 'simpleRange', { min: 0, max: 2500 , title: 'Year Built' }),
    createFilter('saleType', 'refinement', { title: 'Sale Type', isCollapsed: true }),
    createFilter('buyers.name', 'text', { title: 'Buyers' }),
    createFilter('sellers.name', 'text', { title: 'Sellers' }),
    createFilter('capRate', 'percentageRange', { title: 'Cap Rate' }),
    createFilter('netOperatingIncome', 'simpleRange', { title: 'Net Operating Income' }),
    createFilter('occupancyRate', 'percentageRange', { title: 'Percent Occupied' }),
    createFilter('clearHeightMin', 'simpleRange', { title: 'Clear Height' }),
    createFilter('dockDoors', 'simpleRange', { title: 'Dock Doors' }),
    createFilter('driveIns', 'simpleRange', { title: 'Drive-ins' }),
    createFilter('columnSpacingWidth', 'simpleRange', { title: 'Column Spacing' }),
    createFilter('amperage', 'simpleRange', { title: 'Amperage' }),
    createFilter('voltage', 'simpleRange', { title: 'Voltage' }),
    createFilter('sprinklers', 'text', { title: 'Sprinklers' }),
    createFilter('buyerRepresentatives.name', 'text', { title: 'Buyer Representative' }),
    createFilter('sellerRepresentatives.name', 'text', { title: 'Seller Representative' }),
    createFilter('tags', 'textArray', { title: 'Tags' }),
    createFilter('portfolio', 'portfolioFilter', { title: 'Portfolio' }), 
    createFilter('createdAt', 'dateRange', {
      title: 'Created At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('updatedAt', 'dateRange', {
      title: 'Last Updated At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('relatedAndCustomIdsArray', 'relatedAndCustomIdsArray', { title: 'RELATED IDS / CUSTOM IDS' }),
    createFilter('_id', 'idArray', { title: 'DISCOVER RECORD IDS' })
  ],
  sourceFilters: require('./schemas/_sale').sourceFilters,
  defaultFilters: {}
});

export const listing = createDatatype('listing', {
  Icon: ListingIcon,
  color: green[500],
  isShownInFeed: true,
  preferenceScope: ['Multi-Family'],
  filters: [
    dataOwnerFilter(),
    createFilter('properties.name', 'text', { title: 'Property Name', wildcard: false }),
    createFilter('properties.address', 'text', { title: 'Property Address', wildcard: false }),
    createFilter('properties.propertyTypes', 'refinement', { title: 'Property Types' }),
    createFilter('properties.propertyUses', 'refinement', { title: 'Property Uses' }),
    createFilter('properties.buildingClass', 'refinement', { title: 'Building Class' }),
    createFilter('properties.market', 'refinement', { title: 'Markets' }),
    createFilter('properties.submarket', 'refinement', { title: 'Submarkets' }),
    createFilter('properties.micromarket', 'refinement', { title: 'Micro-Markets' }),
    createFilter('properties.county', 'refinement', { title: 'County' }),
    createFilter('properties.city', 'cityArray', { title: 'City' }),
    createFilter('properties.zipCode', 'zipCodeArray', { title: 'Zip Code' }),
    createFilter('rentableBuildingArea', 'simpleRange', { title: 'Property Size' }),
    createFilter('units', 'simpleRange', { title: 'Number Of Units' }),
    createFilter('properties.currentDevelopment.developmentStatus.untouched', 'refinement', {
      title: 'Development Status'
    }),
    createFilter('landAreaMin', 'simpleDecimalRange', { min: 0, title: 'Land Area' }),
    createFilter('properties.yearBuilt', 'simpleRange', { min: 0, max: 2500 , title: 'Year Built' }),
    createFilter('price', 'simpleRange', { title: 'Price' }),
    createFilter('pricePerSquareFoot', 'simpleRange', {
      subtype: 'currency',
      title: 'Asking Price per SF'
    }),
    createFilter('capRate', 'percentageRange', { title: 'Capitalization Rate' }),
    createFilter('saleType', 'refinement', { title: 'Sale Type' }),
    createFilter('status', 'refinement', { title: 'Deal Status' }),
    createFilter('availableDate', 'dateRange', {
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('postedDate', 'dateRange', { title: 'On-Market Date' }, {
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('spaceUses.type', 'refinement', {
      title: 'Space Uses',
      isCollapsed: true
    }),
    createFilter('clearHeightMin', 'simpleRange', { title: 'Clear Height' }),
    createFilter('dockDoors', 'simpleRange', { title: 'Dock Doors' }),
    createFilter('driveIns', 'simpleRange', { title: 'Drive-ins' }),
    createFilter('columnSpacingWidth', 'simpleRange', { title: 'Column Spacing' }),
    createFilter('amperage', 'simpleRange', { title: 'Amperage' }),
    createFilter('voltage', 'simpleRange', { title: 'Voltage' }),
    createFilter('electricalPhase', 'simpleRange', { title: 'Electrical Phase' }),
    createFilter('sprinklers', 'text', { title: 'Sprinklers' }),
    createFilter('parkingRatio', 'simpleRange', { title: 'Parking Ratio' }),
    createFilter('properties.zoningCodes', 'text', { title: 'Zoning Codes' }),
    createFilter('properties.isFreeTradeZone', 'boolean', { 
      title: 'Free Trade Zone', 
    }),
    createFilter('properties.isEnterpriseZone', 'boolean', { 
      title: 'Enterprise Zone'
    }),
    createFilter('sellerRepresentatives.name', 'text', { title: 'Seller Representatives' }),
    createFilter('sellers.name', 'text', { title: 'Sellers' }),
    createFilter('expirationDate', 'dateRange', {
      title: 'Expires On',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('createdAt', 'dateRange', {
      title: 'Created At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('updatedAt', 'dateRange', {
      title: 'Last Updated At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('tags', 'textArray', { title: 'Tags' }),
    createFilter('portfolio', 'portfolioFilter', { title: 'Portfolio' }),
    createFilter('relatedAndCustomIdsArray', 'relatedAndCustomIdsArray', { title: 'RELATED IDS / CUSTOM IDS' }),
    createFilter('_id', 'idArray', { title: 'DISCOVER RECORD IDS' })
  ],
  sourceFilters: require('./schemas/_listing').sourceFilters,
  defaultFilters: {}
});

export const development = createDatatype('development', {
  Icon: DevelopmentIcon,
  color: orange[500],
  isShownInFeed: true,
  filters: [
    dataOwnerFilter(),
    createFilter('property.name', 'text', { title: 'Property Name', wildcard: false  }),
    createFilter('property.address', 'text', { title: 'Property Address', wildcard: false  }),
    createFilter('property.propertyTypes', 'refinement', { title: 'Property Type' }),
    createFilter('developmentType', 'refinement'),
    createFilter('deliveryDate', 'dateRange', {
      title: 'Delivery Date',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('area', 'simpleRange'),
    createFilter('units', 'simpleRange'),
    createFilter('property.submarket', 'refinement', { title: 'Submarket' }),
    createFilter('property.micromarket', 'refinement', { title: 'Micro-Market' }),
    createFilter('developer.name', 'text', { title: 'Developer' }),
    createFilter('developmentStatus', 'refinement'),
    createFilter('preleasePercentage', 'percentageRange', { title: 'Prelease Percentage (%)' }),
    createFilter('constructionStartDate', 'dateRange', {
      title: 'Construction Start Date',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false
    }),
    createFilter('constructionCost', 'simpleRange', { subtype: 'currency' }),
    createFilter('createdAt', 'dateRange', {
      title: 'Created At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('updatedAt', 'dateRange', {
      title: 'Last Updated At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('_id', 'idArray', { title: 'IDs' })
  ],
  sourceFilters: require('./schemas/_development').sourceFilters,
  defaultFilters: {}
});

export const company = createDatatype('company', {
  Icon: CompanyIcon,
  color: blueGrey[500],
  filters: [
    createFilter('renting', 'toggle', {
      translations: {
        '1': 'Rents',
        '0': 'Owns'
      }
    }),
    createFilter('locationEmployees', 'simpleRange'),
    createFilter('revenue', 'simpleRange', {}),
    createFilter('facilitySize', 'simpleRange', {}),
    createFilter('industry', 'refinement', { title: 'Industries' }),
    createFilter('_id', 'idArray', { title: 'IDs' })
  ],
  sourceFilters: require('./schemas/_company').sourceFilters,
  defaultFilters: {}
});

export const news = createDatatype('news', {
  Icon: NewsIcon,
  color: blue[500],
  plural: 'News',
  isShownInFeed: true,
  filters: [
    createFilter('postedDate', 'dateRange', {
      interval: ONE_DAY_INTERVAL * 10,
      showHistogram: true,
    }),
    createFilter('categories', 'refinementNews'),
    createFilter('source', 'refinementNews', { title: 'Sources' }),
    createFilter('_id', 'idArray', { title: 'IDs' })
  ],
  sourceFilters: require('./schemas/_news').sourceFilters,
  defaultFilters: {}
});

export const tim = createDatatype('tim', {
  Icon: TimIcon,
  color: indigo[500],
  isShownInFeed: true,
  plural: 'Tenants in Market',
  filters: [
    dataOwnerFilter(),
    createFilter('spaceUses.type', 'refinement', {
      title: 'Space Uses',
      isCollapsed: true
    }),
    createFilter('targetArea.min', 'simpleRange'),
    createFilter('targetArea.max', 'simpleRange'),
    createFilter('occupancyDate', 'dateRange', {
      title: 'Occupancy Date',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('inMarketAt', 'dateRange', {
      interval: ONE_DAY_INTERVAL,
      showHistogram: true,
      title: 'In-Market Date'
    }),
    createFilter('currentLeaseEndDate', 'dateRange', {
      interval: ONE_DAY_INTERVAL,
      showHistogram: true,
    }),
    createFilter('tenant.industries', 'refinement', { title: 'Industries' }),
    createFilter('markets', 'refinement', { title: 'Markets' }),
    createFilter('submarkets', 'refinement', { title: 'Submarkets' }),
    createFilter('micromarkets', 'refinement', { title: 'Micro-Markets' }),
    createFilter('tenant.name', 'text', { title: 'Tenant' }),
    createFilter('tenantRepresentatives.name', 'text', {
      title: 'Tenant Representatives',
    }),
    createFilter('currentProperty.name', 'text', {
      queryFields: ['currentProperty.name', 'currentProperty.address'],
      title: 'Current Property',
    }),
    createFilter('status', 'refinement', { title: 'Status' }),
    createFilter('tags', 'textArray', { title: 'Tags' }),
    createFilter('rescourId', 'text', { title: 'Colliers Id' }),
    createFilter('createdAt', 'dateRange', {
      title: 'Created At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('updatedAt', 'dateRange', {
      title: 'Last Updated At',
      interval: ONE_DAY_INTERVAL,
      showHistogram: false,
    }),
    createFilter('_id', 'idArray', { title: 'IDs' })
  ],
  sourceFilters: require('./schemas/_tim').sourceFilters,
  defaultFilters: { status: ['Active'] }
});

import {
  DETAIL_SHOW_PROBLEM_REPORTER,
  DETAIL_HIDE_PROBLEM_REPORTER,
} from '../../constants/actionTypes';

const initialState = {
  isProblemReporterVisible: false,
  problemItem: {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DETAIL_SHOW_PROBLEM_REPORTER:
      return {
        ...state,
        isProblemReporterVisible: true,
        problemItem: action.subitem
      };

    case DETAIL_HIDE_PROBLEM_REPORTER:
      return {
        ...state,
        isProblemReporterVisible: false,
        problemItem: {}
      };
  }

  return state;
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FullStoryAPI as fullStoryAPI } from 'react-fullstory';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import * as actions from './actions';
import { EVENTS as eventNames } from './../../constants/fullstory.js';

const ProblemReporter = ({
  hideProblemReporter,
  isProblemReporterVisible,
  problemItem,
  ...props
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [problemBody, setProblemBody] = useState('');

  const handleSubmit = () => {
    setSubmitting(true);
    props.reportProblem(problemItem, problemBody).then(() => {
      fullStoryAPI(eventNames.EVENT, eventNames.PROBLEM_REPORTED, {
        'problemReported_str': eventNames.PROBLEM_REPORTED_CLICKED
      });
      
      setSubmitting(false);
      setProblemBody('');
      hideProblemReporter();
    });
  };

  const onChange = (event) => {
    setProblemBody(event.target.value || '');
  };

  return (
    <Dialog
      open={isProblemReporterVisible}
      onClose={hideProblemReporter}>
      <DialogContent>
        <Typography variant="h6" style={{ lineHeight: '1em' }}>
          {problemItem.canonicalName}
        </Typography>
        <Typography variant="subtitle" color="textSecondary">
          Please provide a detailed description of the problem(s) you would like to
          report on this {problemItem._type} record.
        </Typography>
        <TextField
          autoFocus
          fullWidth
          label="Detailed Problem Description"
          multiline
          onChange={onChange}
          rows={3}
          style={{ marginTop: 16 }}
          value={problemBody}
          variant="outlined" />
      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={hideProblemReporter}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={!problemBody || submitting}
          onClick={handleSubmit}
          variant="contained">
          Report Problem
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProblemReporter.propTypes = {
  hideProblemReporter: PropTypes.func.isRequired,
  isProblemReporterVisible: PropTypes.bool.isRequired,
  problemItem: PropTypes.object.isRequired,
  reportProblem: PropTypes.func.isRequired,
};

function mapStateToProps({ research: { problemReporter } }) {
  return problemReporter;
}
export default connect(mapStateToProps, actions)(ProblemReporter);

export function getFeedQueryParams({ filter, itemId }, datatypes) {
  const params = {
    type: datatypes.join(',')
  };

  if (itemId) params.itemId = itemId;

  if (filter === 'favorite') {
    params.tags = 'favorite';
  } else if (filter) {
    params.type = filter;
  }

  return params;
}

const swapMap = new Map([
  ['listing', 'For Sale'],
  ['listings', 'For Sale'],
  ['for sales', 'For Sale'],
  ['lease', 'Lease Comp'],
  ['leases', 'Lease Comps'],
  ['lease comps', 'Lease Comps'],
  ['sale', 'Sale Comp'],
  ['sales', 'Sale Comps'],
  ['sale comps', 'Sale Comps'],
  ['availability', 'For Lease'],
  ['availabilities', 'For Lease'],
  ['for leases', 'For Lease']
]);

module.exports = function translate(str) {
  if (typeof str !== 'string' || !swapMap.has(str.toLowerCase())) {
    return str;
  }
  
  return swapMap.get(str.toLowerCase());
};

import { blue } from '@material-ui/core/colors';

export default (theme) => ({
  closeColumnsOrganizer: {
    position: 'absolute',
    right: 10,
    top: 32
  },
  column: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    marginBottom: -1,
    userSelect: 'none'
  },
  columns: {
    height: '66vh',
    overflow: 'auto'
  },
  sortableListColumns: {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  hideAndShowButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '12px',
    marginTop: '12px'
  },
  hideAllButtons: {
    color: blue[500]
  },
  showAllButtons: {
    color: blue[500],
  },
  subHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

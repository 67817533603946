import * as news from './News';
import * as listing from './Listing';
import * as property from './Property';
import * as lease from './Lease';
import * as availability from './Availability';
import * as sale from './Sale';
import * as development from './Development';
import * as company from './Company';
import * as tim from './Tim';

export default {
  news,
  listing,
  property,
  lease,
  availability,
  sale,
  development,
  company,
  tim,
};

export const COLLIERS_BLUE = '#25408f';
export const COLLIERS_CYAN = '#0c9ed9';
export const COLLIERS_RED = '#ed1a35';
export const COLLIERS_YELLOW = '#ffd400';
export const COLLIERS_TEAL = '#0B90A8';

export default {
  palette: {
    primary: {
      main: COLLIERS_BLUE
    },
    secondary: {
      main: '#666666'
    },
    error: {
      main: COLLIERS_RED
    },
    warning: {
      main: COLLIERS_YELLOW
    },
    colliersBlue: COLLIERS_BLUE,
    colliersCyan: COLLIERS_CYAN,
    colliersRed: COLLIERS_RED,
    colliersTeal: COLLIERS_TEAL,
    colliersYellow: COLLIERS_YELLOW,
  },
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: COLLIERS_BLUE,
          color: 'white',
          marginBottom: 16
        },
        viewTransitionContainer: {
          backgroundColor: 'white'
        }
      }
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchViewIcon: {
          color: 'white'
        }
      }
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          color: 'white'
        }
      }
    },
    MuiYearCalendar: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: 'black'
        }
      }
    }
  }
};

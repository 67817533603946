import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dimensions from 'react-dimensions';
import some from 'lodash/some';
import pickBy from 'lodash/pickBy';
import { ability } from '../../auth/ability';
import * as authActions from '../../auth/actions';
import * as reportActions from '../../research/reports/actions';
import * as researchActions from '../actions';
import * as searchesActions from '../searches/actions';
import * as actions from './actions';
import { setParcelProperties } from '../parcelDetail/actions';
import Map from './Map';
import Menu from './Menu';
import Detail from './Detail';
import Loader from '../Loader';
import NotificationMessage from '../../common/NotificationMessage';
import Paper from '@material-ui/core/Paper';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import 'leaflet/dist/leaflet.css';

class MapContainer extends React.Component {
  static propTypes = {
    anySearchesEnabled: PropTypes.bool.isRequired,
    bounds: PropTypes.object.isRequired,
    children: PropTypes.node,
    clearPolygons: PropTypes.func.isRequired,
    clearSelectedResults: PropTypes.func.isRequired,
    closeMapNotifications: PropTypes.func.isRequired,
    containerWidth: PropTypes.number.isRequired,
    currentListView: PropTypes.string,
    demographic: PropTypes.object.isRequired,
    detailLoading: PropTypes.bool,
    enabledSearches: PropTypes.object.isRequired,
    fetchAlertRecord: PropTypes.func.isRequired,
    fetchMapOverlayData: PropTypes.func.isRequired,
    isSearching: PropTypes.bool.isRequired,
    location: PropTypes.object.isRequired,
    openSnackbar: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    searches: PropTypes.object.isRequired,
    selectAllResults: PropTypes.func.isRequired,
    setPolygons: PropTypes.func.isRequired,
    setSourceFilterSchema: PropTypes.func.isRequired,
    toggleShareDialog: PropTypes.func.isRequired,
    updatePins: PropTypes.func.isRequired,
  };

  UNSAFE_componentWillMount() {
    this.props.setSourceFilterSchema('list');
  }

  componentDidMount() {
    const { location } = this.props;
    const query = location.search.split('=');

    if ((query) && (query[0] === '?savedSearch')) {
      const savedSearchId = location.search.match(/savedSearch=([a-zA-Z0-9]+)/)[1];
      const alertId = location.search.match(/alert=([a-zA-Z0-9]+)/)[1];
      if (alertId && savedSearchId) {
        this.props.fetchAlertRecord(alertId, savedSearchId);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Update pins only when all searches are complete or if a search was disabled
    if ((Object.keys(nextProps.enabledSearches).length < Object.keys(this.props.enabledSearches).length) ||
      (this.props.isSearching && !nextProps.isSearching)) {
      nextProps.updatePins(nextProps.searches);
    }
  }

  render() {
    const { children, openSnackbar, closeMapNotifications } = this.props;
    return (
      <div>
        <Map {...this.props} />
        {children && <Detail containerWidth={this.props.containerWidth}>{children}</Detail>}
        {this.props.detailLoading && <Detail containerWidth={this.props.containerWidth}>
          <Paper style={{ height: '100%', width: '100%' }}>
            <Loader />
          </Paper>
        </Detail>}
        <Menu {...this.props} />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={closeMapNotifications}
          open={openSnackbar.open}>
          <SnackbarContent
            style={{ minWidth: 'fit-content' }}
            message={<NotificationMessage
              secondaryText={openSnackbar.secondaryText || ''}
              text={openSnackbar.text || ''}
              type={openSnackbar.textType || ''} />} />
        </Snackbar>
      </div>
    );
  }
}

function mapStateToProps({ research, visibleDatatypes }, { location }) {
  const {
    searches,
    selectedResults,
    selectionAnchor,
    showSelected,
    map: { 
      areas,
      hasListError,
      isPopupFetching,
      listErrorMessage,
      listMouseoverId,
      openSnackbar,
      setDemographic,
      pins, 
      demographic, 
      demographicData, 
      featureContent, 
      parcelsEnabled,
      polygons,
      popup,
      popupContent,
      selectedProperty
    },
    myLists: { myLists },
  } = research;
  const {
    isBuildingFullReport,
    isFetchingPreviewReport,
    previewReportDataUri,
    reportGenerationError,
  } = research.reports;
  const enabledSearches = pickBy(searches, (s) => s.isEnabled && s.filters);

  let { currentListView } = location.query;
  if (!enabledSearches[location.query.currentListView]) currentListView = Object.keys(enabledSearches)[0];

  const currentResults = enabledSearches[currentListView] ? enabledSearches[currentListView].results : [];

  return {
    anySearchesEnabled: Object.keys(enabledSearches).length > 0,
    areas,
    hasListError,
    isFetching: isPopupFetching,
    isBuildingFullReport,
    isFetchingPreviewReport,
    previewReportDataUri,
    reportGenerationError,
    isSearching: some(enabledSearches, 'isSearching'),
    currentLocation: location.hash,
    results: currentResults,
    listErrorMessage,
    listMouseoverId,
    setDemographic,
    myLists,
    openSnackbar,
    searches,
    pins,
    demographic,
    demographicData,
    featureContent,
    parcelsEnabled: (ability.can('read', 'parcels', ability.defaultTenantId) && parcelsEnabled) || false,
    polygons,
    popup,
    popupSearches: Object.keys(searches).filter((key) => searches[key].isEnabled),
    property: popupContent,
    selectedProperty,
    selectedResults,
    selectionAnchor,
    enabledSearches,
    currentListView,
    visibleDatatypes,
    showSelected: showSelected || false
  };
}

const MapContainerWithDimensions = dimensions()(MapContainer);
export default connect(mapStateToProps, {
  ...actions, ...authActions, ...searchesActions, setParcelProperties, ...reportActions, ...researchActions
})(MapContainerWithDimensions);

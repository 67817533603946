import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RESET_APP_STATE } from '../constants/actionTypes';
import store from '../store';

const Logout = () => {
  const auth0 = useAuth0();

  useEffect(() => {
    window.localStorage.clear();
    store.dispatch({ type: RESET_APP_STATE });
    auth0.logout({ returnTo: window.location.origin });
  }, []);

  return null;
};

export default Logout;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import striptags from 'striptags';
import { AllHtmlEntities as entities } from 'html-entities';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CategoryIcon from '@material-ui/icons/LocalOffer';
import DateIcon from '@material-ui/icons/Today';
import SourceIcon from '@material-ui/icons/Link';
import SummaryStat from '../../common/stats/SummaryStat';
import formatStat from '../../common/stats/formatStat';
import statStyles from '../../common/stats/module.css';
import styles from './module.css';

const useStyles = makeStyles((theme) => ({
  ...statStyles(theme),
  ...styles(theme),
}));

const clean = (data) => entities.decode(striptags(data));

export const CardContent = ({ stats, item }) => {
  const classes = useStyles();
  const schema = {};
  stats.forEach((stat) => {
    if (['postedDate', 'categories', 'source'].indexOf(stat.key) > -1) {
      schema[stat.key] = stat;
    }
  });

  return (
    <div className={classes.summaryStats}>
      <SummaryStat
        className={classes.summaryStat}
        key={schema.postedDate.key}
        Icon={DateIcon}
        label={schema.postedDate.label}
        value={formatStat(item[schema.postedDate.key], schema.postedDate)} />
      <div className={classes.newsStats}>
        <div className={classes.newsStat}>
          <CategoryIcon className={clsx([classes.newsStatIcon, classes.hideOnSmall])} />
          <span>
            <strong>{schema.categories.label}: </strong>
            {formatStat(item[schema.categories.key], schema.categories)}
          </span>
        </div>
        <div className={classes.newsStat}>
          <SourceIcon className={clsx([classes.newsStatIcon, classes.hideOnSmall])} />
          <span>
            <strong>{schema.source.label}: </strong>
            {formatStat(item[schema.source.key], schema.source)}
          </span>
        </div>
      </div>
      <div className={clsx([classes.newsThumbnail, classes.hideOnSmall])}>
        {item.thumbnailUrl && (
          <img className={classes.newsThumbnail} src={item.thumbnailUrl} />
        )}
      </div>
    </div>
  );
};

CardContent.propTypes = {
  item: PropTypes.object.isRequired,
  stats: PropTypes.array,
};

export const ExpandedCardContent = ({ item }) => (
  <Typography variant="body2" style={{ lineHeight: 2 }}>
    {clean(item.body)}
  </Typography>
);

ExpandedCardContent.propTypes = {
  item: PropTypes.object.isRequired
};

import auth0 from 'auth0-js';
import React, { Component } from 'react';

class CrossOrigin extends Component {
  constructor(props) {
    super(props);

    const auth0Client = new auth0.WebAuth({
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      domain: process.env.REACT_APP_AUTH0_CLIENT_DOMAIN
    });

    auth0Client.crossOriginVerification();
  }
  render() {
    return (<div />);
  }
}

export default CrossOrigin;

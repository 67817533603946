import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css.js';

const AccountDefault = ({ classes, accountType }) => {
  return (
    <div className={classes.accountLoadingWrapper}>
      <Typography
        variant="h5"
        color="primary"
        className={classes.noAccountBody}>{`You don't have any recent ${accountType}`}</Typography>
    </div>
  );
};

AccountDefault.propTypes = {
  accountType: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

export default (withStyles(styles)(AccountDefault));

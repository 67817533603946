import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { debounce, get, isEqual } from 'lodash';
import Leaflet, { LatLng, LatLngBounds, popup } from 'leaflet';
import FreeDraw, { CREATE, EDIT, NONE } from 'leaflet-freedraw';
import 'leaflet-hash';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-active-area';
import 'leaflet.vectorgrid';
import { ThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import polylabel from 'polylabel';
import mediaQuery from '../../common/mediaQuery';
import { read } from '../../services/api';
import theme from '../../styles/theme';
import SavedSearch from './saveSearchButton/SavedSearch';
import { DrawControlContainer } from './controls/DrawTool';
import { MapControl } from '@colliers-international/react-leaflet-components';
import { useTracker } from '@colliers-international/usage-tracker';
import Areas from './Areas';
import Pins from './Pins';
import styles from './module.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { baseLayers } from './controls/BaseLayers';
import { ResultList } from './controls/ResultList';
import { DemographicMenu } from './controls/DemographicMenu';
import './map.css';
import { POILayers, SubmarketFeature } from './controls/Features';
import { DemographicFeature } from './controls/FeatureShape';
import Popup from './popupContent/PopUp';
import { getFeaturesFromVectorTiles, getPopupOffset } from './utils';

const PARCEL_MIN_ZOOM = 15;
const PARCEL_MAX_ZOOM = 21;

const defaultTheme = createMuiTheme(theme);

function getListModeDatatypes(searches) {
  return Object.entries(searches).reduce((acc, [datatype, options]) => {
    if (options.listMode) {
      acc.push(datatype);
    }

    return acc;
  }, []);
}

class Map extends React.Component {
  static propTypes = {
    areas: PropTypes.array.isRequired,
    bounds: PropTypes.object.isRequired,
    classes: PropTypes.object,
    clearPolygons: PropTypes.func.isRequired,
    clearPopup: PropTypes.func.isRequired,
    containerHeight: PropTypes.number.isRequired,
    containerWidth: PropTypes.number.isRequired,
    currentListView: PropTypes.string,
    currentLocation: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
    demographic: PropTypes.object.isRequired,
    demographicData: PropTypes.object,
    featureContent: PropTypes.array.isRequired,
    fetchDemographic: PropTypes.func.isRequired,
    fetchGISFeature: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    listMouseoverId: PropTypes.string,
    location: PropTypes.object.isRequired,
    parcelDataLoaded: PropTypes.func.isRequired,
    parcelsEnabled: PropTypes.bool,
    pins: PropTypes.object.isRequired,
    polygons: PropTypes.array.isRequired,
    popup: PropTypes.string,
    popupSearches: PropTypes.array.isRequired,
    property: PropTypes.object.isRequired,
    searches: PropTypes.object.isRequired,
    selectPopup: PropTypes.func.isRequired,
    selectedResults: PropTypes.array,
    setParcelProperties: PropTypes.func.isRequired,
    setPolygons: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    value: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.layersPopup = [];

    this.state = {
      basemapLayer: baseLayers[Map.DEFAULT_BASEMAP_INDEX],
      defaultParcelStyle: { weight: 0, fill: false },
      deleteDialogOpen: false,
      drawFreeEl: null,
      drawCircleEl: null,
      drawCircleRadius: 3,
      demographic: null,
      filteredParcelStyle: { weight: 3, color: '#FF00FF', fill: false },
      isDeleting: false,
      mounted: false,
      parcelData: {},
      recentSavedSearches: [],
      selectedPolygon: null,
      shouldFitListModePins: false,
      showDeleteMode: false,
      showProgress: true,
    };
    
    this.color = this.getBasemapColor(this.state.basemapLayer);

    this.parcelBaseLayer = Leaflet.tileLayer(
      `${process.env.REACT_APP_API_URL}/parcels/tiles/{z}/{x}/{y}?color=${this.color.baseLayer}`,
      { maxZoom: PARCEL_MAX_ZOOM, minZoom: PARCEL_MIN_ZOOM }
    );

    this.parcelStyles = (properties) => {
      const { defaultParcelStyle, filteredParcelStyle } = this.state;
      const filteredData = this.props?.searches?.parcel?.filteredData ?? [];

      const match = filteredData.find((i) => {
        return i.id === properties.id;
      });
      
      if (match) {
        return filteredParcelStyle;
      }

      return defaultParcelStyle;
    };

    this.debouncedDataLoaded = debounce((tiles) => {
      const features = getFeaturesFromVectorTiles(tiles);
      this.props.parcelDataLoaded(features);
    }, 250);

    this.parcelLayer = Leaflet.vectorGrid.protobuf(
      `${process.env.REACT_APP_API_URL}/parcels/tiles/{z}/{x}/{y}?color=${this.color.filterLayer}&variant=vector`,
      {
        getFeatureId(f) {
          return f.properties;
        },
        vectorTileLayerStyles: {
          [process.env.REACT_APP_PARCEL_TILE_LAYER]: (properties) => {
            return this.parcelStyles(properties);
          }
        },
        maxZoom: PARCEL_MAX_ZOOM,
        minZoom: PARCEL_MIN_ZOOM,
        rendererFactory: Leaflet.canvas.tile
      }
    ).on('load', () => {
      const { zoom } = Leaflet.Hash.parseHash(this.props.currentLocation);

      if (this.props.parcelsEnabled && zoom >= PARCEL_MIN_ZOOM) {
        const tiles = get(this.parcelLayer, '_vectorTiles', {});
        this.debouncedDataLoaded(tiles);
      }
    });

    this.exitDeleteMode = this.exitDeleteMode.bind(this);
    this.setDrawType = this.setDrawType.bind(this);
    this.setBasemap = this.setBasemap.bind(this);
    this.setDemographic = this.setDemographic.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteMode = this.handleDeleteMode.bind(this);
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
    this.resetDeleteDialog = this.resetDeleteDialog.bind(this);
    this.handleBindButton = this.handleBindButton.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
    this.loadRecentSavedSearches();
    const freedraw = new FreeDraw({
      leaveModeAfterCreate: true,
      mode: NONE,
      layerID: 'test'
    });

    freedraw.on('markers', (markers) => {
      this.props.setPolygons(markers.latLngs);
    });

    let controlPosition = 'bottomleft';
    mediaQuery('(max-width: 600px)', (match) => match && (controlPosition = 'topright'));
    const map = Leaflet
      .map(this.container, {
        minZoom: 4,
        maxZoom: 20
      })
      .addLayer(baseLayers[Map.DEFAULT_BASEMAP_INDEX].layer)
      .addLayer(freedraw);
    if (JSON.parse(window.localStorage.getItem('disableMouseZoom')) === true) {
      map.scrollWheelZoom.disable();
    }

    if (this.props.parcelsEnabled) {
      map.addLayer(this.parcelBaseLayer);
      map.addLayer(this.parcelLayer);
    }

    map.zoomControl.setPosition(controlPosition);
    Leaflet.hash(map);
    this.freedraw = freedraw;
    this.map = map;

    // If polygons were already drawn and still in state, re-draw them
    map.on('layeradd', (layer) => {
      if (layer.layer === freedraw && this.props.polygons.length > 0) {
        this.props.polygons.forEach((p, i) => {
          freedraw.create(p);
          this.handlePolygonDelete(p, i);
          this.exitDeleteMode();
        });
      }
    });

    // on click, if in "draw circle" mode, then draw a circle
    map.on('click', (e) => {
      if (this.state.drawCircleEl) {
        // calculate points to approximate circle with specified radius around clicked point
        const pointTally = 36;
        const radiusLat = this.state.drawCircleRadius / 69;
        const radiusLng = this.state.drawCircleRadius / Math.abs(Math.cos(e.latlng.lat * Math.PI / 180) * 69.127);
        const radiansBetweenPoints = 2 * Math.PI / pointTally;
        const points = [];
        for (let i = 0; i < pointTally; i++) {
          points.push(
            new LatLng(
              e.latlng.lat + radiusLat * Math.cos(radiansBetweenPoints * i),
              e.latlng.lng + radiusLng * Math.sin(radiansBetweenPoints * i)
            )
          );
        }

        // draw points to approximate circle at specified radius around clicked point
        freedraw.create(points);
        this.exitDeleteMode();

        // disable click circle
        this.setState({ drawCircleEl: null });
      } else if (this.state.drawFreeEl) {
        this.setState({ drawFreeEl: null });
      } else if (this.props.parcelsEnabled && e.latlng && this.map.getZoom() >= PARCEL_MIN_ZOOM) {
        read(`/parcels?lat=${e.latlng.lat}&lng=${e.latlng.lng}`)
          .then((res) => {
            const count = (res.parcel.parcels || []).length;

            if (count === 0) {
              this.setState({ parcelData: {} });
              this.setState({ showProgress: false });
              return;
            }

            const parcel = res.parcel.parcels[0];
            this.setState({ parcelData: parcel });

            return read(`/properties?geometry=${parcel.location.geometry.wkt}`).then((properties) => {
              this.setState({ parcelData: { ...parcel, properties } });
              this.setState({ showProgress: false });
            }).then(() => {
              const params = {
                propertyId: `${this.props.property.hash}|${this.props.property.rescourId}`, 
                datatype: this.props.currentListView || 'lease'
              };

              const popupOffset = getPopupOffset('map', map, e.latlng);
              
              const pop = popup({
                autoPan: false,
                closeButton: false,
                className: this.props.classes.popup,
                offset: popupOffset
              })
                .setLatLng(e.latlng)
                .openOn(this.map);

              pop._map.once('popupclose', () => {
                if (pop._contentNode) {
                  unmountComponentAtNode(pop._contentNode);
                }
              });

              const closePopup = () => {
                pop._map.closePopup();
              };

              render((
                <ThemeProvider theme={defaultTheme}>
                  <Popup 
                    closePopup={closePopup}
                    bounds={this.props.bounds} 
                    isFetching={this.props.isFetching}
                    params={params}
                    parcelData={this.state.parcelData}
                    property={{}} 
                    searches={this.props.popupSearches}
                    setParcelProperties={this.props.setParcelProperties}
                    showProgress={this.state.showProgress}
                    trackEvent={this.props.trackEvent} />
                </ThemeProvider>
              ), pop._contentNode);
            });
          })
          .catch(() => {
            this.setState({ parcelData: {} });
            this.setState({ showProgress: false });
          });
      }
    });

    const listModeDatatypes = getListModeDatatypes(this.props.searches);
    if (listModeDatatypes.length > 0) {
      this.setState({ shouldFitListModePins: true });
    }

    this.forceUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    const previousListMode = getListModeDatatypes(prevProps.searches);
    const currentListMode = getListModeDatatypes(this.props.searches);

    // If the a datatype's listMode status has changed, refit the pins.
    if (!isEqual(previousListMode, currentListMode)) {
      this.setState({ shouldFitListModePins: true });
    }

    if (!isEqual(this.props.polygons, prevProps.polygons)) {
      if (prevProps.polygons.length > 0) {
        this.freedraw.clear();
      }
      this.layersPopup.forEach((layer) => this.map.removeLayer(layer));
      this.layersPopup = [];
      this.props.polygons.forEach((p, i) => {
        this.freedraw.create(p);
        this.handlePolygonDelete(p, i);
        this.handleDeleteMode();
      });
    }

    if (prevState.basemapLayer !== this.state.basemapLayer) {
      const color = this.getBasemapColor(this.state.basemapLayer);
      this.parcelBaseLayer.setUrl(
        `${process.env.REACT_APP_API_URL}/parcels/tiles/{z}/{x}/{y}?color=${color.baseLayer}`,
        { maxZoom: PARCEL_MAX_ZOOM, minZoom: PARCEL_MIN_ZOOM }
      );
      const { filteredParcelStyle } = this.state;
      const updatedFilteredParcelStyle = {
        ...filteredParcelStyle,
        color: `#${color.filterLayer}`
      };
      this.setState({ filteredParcelStyle: updatedFilteredParcelStyle });
      this.parcelBaseLayer.redraw();
      this.parcelLayer.redraw();
      if (this.props.parcelsEnabled) {
        this.parcelBaseLayer.bringToFront();
        this.parcelLayer.bringToFront();
      }
    }

    if (this.props.parcelsEnabled !== prevProps.parcelsEnabled) {
      if (this.props.parcelsEnabled) {
        this.map.addLayer(this.parcelBaseLayer);
        this.map.addLayer(this.parcelLayer);
      } else {
        this.map.removeLayer(this.parcelBaseLayer);
        this.map.removeLayer(this.parcelLayer);
      }
    }

    if (this.props.parcelsEnabled && (
      this.props.searches?.parcel?.filteredDataHash !== prevProps.searches?.parcel?.filteredDataHash 
    )) {
      this.parcelLayer.redraw();
    }
  }

  componentWillUnmount() {
    this.map.removeLayer(this.freedraw);
    this.map.removeLayer(this.parcelBaseLayer);
    this.map.removeLayer(this.parcelLayer);
    this.map.remove();
    this.setState({ mounted: false });
  }

  static DEFAULT_BASEMAP_INDEX = 0;
  static MARKER_SWITCH_ZOOM_LEVEL = 11;

  getBasemapColor(basemapLayer) {
    const parcelBaseLayerColor = basemapLayer.name === 'Aerial' || basemapLayer.name === 'Hybrid' ? 'FFFFFF' : '0b90a8';
    const parcelLayerColor = basemapLayer.name === 'Aerial' || basemapLayer.name === 'Hybrid' ? 'FFE67E' : 'FF00FF';
    return {
      baseLayer: parcelBaseLayerColor,
      filterLayer: parcelLayerColor
    };
  }

  getParcelBaseLayer() {
    const color = this.getBasemapColor(this.state.basemapLayer).baseLayer;
    return Leaflet.tileLayer(
      `${process.env.REACT_APP_API_URL}/parcels/tiles/{z}/{x}/{y}?color=${color}`,
      { maxZoom: PARCEL_MAX_ZOOM, minZoom: PARCEL_MIN_ZOOM }
    );
  }

  handlePolygonDelete(polygon, index) {
    const ll = polygon.map((latLng) => [latLng.lat, latLng.lng]);
    const layer = Leaflet.polygon(ll, {
      color: 'rgba(0,0,0,0)'
    }).on('click', (e) => {
      if (!this.state.showDeleteMode) {
        e.originalEvent.stopPropagation();
        this.layersPopup.forEach((lyr) => {
          lyr.off('click');
        }
        );
      }
    });

    const p = polylabel(layer._latlngs.map((latLngArr) => latLngArr.map((latLng) => [latLng.lat, latLng.lng])), 1.0);
    const pop = Leaflet.popup({ autoClose: false, closeOnClick: true, className: 'delete-button-popup' })
      .setContent(this.handleBindButton(index))
      .setLatLng([...p]);

    this.layersPopup.push(pop);
  }

  handleDeleteCancel() {
    this.setState({ deleteDialogOpen: false, isDeleting: false });
    this.resetDeleteDialog();
  }

  handleDeleteMode() {
    return this.layersPopup && this.layersPopup.forEach((pop) => {
      pop.openOn(this.map);
    });
  }

  exitDeleteMode() {
    this.layersPopup.forEach((pop) => {
      pop.remove();
    });

    this.setState({ showDeleteMode: false });
  }

  handleDelete() {
    this.setState({ isDeleting: true, showDeleteMode: true });
    const foundLayer = this.layersPopup[this.state.selectedPolygon];
    const newPolygons = this.props.polygons.filter((poly, i) => i !== this.state.selectedPolygon);
    this.map.removeLayer(foundLayer);
    this.layersPopup.splice(this.state.selectedPolygon, 1);
    this.props.setPolygons(newPolygons);
    this.resetDeleteDialog();
  }

  resetDeleteDialog() {
    this.setState({ deleteDialogOpen: false, isDeleting: false, selectedPolygon: null });
  }

  handlePinsUpdated = () => {
    if (this.state.shouldFitListModePins) {
      const listModeDatatypes = getListModeDatatypes(this.props.searches);
      this.fitListModePins(listModeDatatypes);
      this.setState({ shouldFitListModePins: false });
    }
  };

  loadRecentSavedSearches = () => {
    this.getSavedSearches({ limit: 5, sort: '-updatedAt' })
      .then((results) => {
        if (this.state.mounted) {
          this.setState({ recentSavedSearches: results.map((result) => ({ text: result.title, value: result })) });
        }
      });
  };

  getSavedSearches = (query) => {
    return read('savedSearches', null, query)
      .then((results) => {
        return results.map((result) => ({ ...result, _type: 'savedSearch' }));
      });
  };

  fitMapToBounds = (locations) => {
    if (locations.length > 0) {
      const latLngBounds = new LatLngBounds(locations);
      // Max zoom will keep the map from zooming too far into a single point.
      // The padding is to keep the pins from rendering under the filter and result list overlays. These #s can be tweaked.
      this.map.fitBounds(latLngBounds, { maxZoom: 14, paddingTopLeft: [100, 20], paddingBottomRight: [400, 20] });
    }
  };

  fitListModePins = (zoomSelected, listModeDatatypes) => {
    const selectedLocations = zoomSelected && this.props.selectedResults
      ? Object.values(this.props.selectedResults).flatMap((array) => 
        array.map((item) => item._source && item._source.location ?
          [item._source.location[1], item._source.location[0]]
          : [])
      ).filter((loc) => loc.length === 2)
      : [];

    this.fitMapToBounds(selectedLocations);

    const pinLocations = Object.entries(this.props.pins).reduce((acc, [_id, { location, type }]) => {
      if (Array.isArray(listModeDatatypes) && listModeDatatypes.includes(type)) {
        acc.push([location[1], location[0]]);
      }
      return acc;
    }, selectedLocations);

    this.fitMapToBounds(pinLocations);
  };

  handleSavedSearchCreate = () => this.loadRecentSavedSearches();

  handleSavedSearchDelete = () => {
    this.loadRecentSavedSearches();
  };

  handleSavedSearchSave = () => this.loadRecentSavedSearches();

  bindContainer = (container) => {
    this.container = container;
  };

  selectRadius = (radius) => {
    this.setState({ drawCircleRadius: radius });
  };

  setDrawType(e) {
    const { name } = e.currentTarget;
    if (name === 'cancelEl') {
      this.setState({ drawCircleEl: null, drawFreeEl: null });
      return this.freedraw.mode(NONE);
    } else if (name === 'drawCircleEl') {
      return this.setState({ drawCircleEl: e.currentTarget });
    } else if (name === 'drawFreeEl') {
      this.setState({ drawFreeEl: e.currentTarget });
      return this.freedraw.mode(CREATE | EDIT);
    } else if (name === 'clearEl') {
      this.setState({ drawCircleEl: null, drawFreeEl: null });
      return this.freedraw.mode(NONE);
    }
  }

  setBasemap(basemap) {
    this.map.removeLayer(this.state.basemapLayer.layer);
    this.setState({ basemapLayer: basemap });
    this.map.addLayer(basemap.layer);
    this.parcelLayer.bringToFront();
  }

  setDemographic(demographic) {
    this.setState({ demographic });
  }

  handleBindButton = (index) => {
    const deleteButton = document.createElement('button');
    deleteButton.className = 'delete-button';
    deleteButton.innerHTML = ReactDOMServer.renderToString(<DeleteForeverIcon fontSize="large" />);
    deleteButton.addEventListener('click', () => {
      this.setState({ deleteDialogOpen: true, selectedPolygon: index });
    });
    return deleteButton;
  };

  render() {
    const { classes, currentUser } = this.props;
    const { zoom } = Leaflet.Hash.parseHash(this.props.currentLocation);
    const activeDraw = this.state.drawCircleEl || this.state.drawFreeEl !== null;
    const pinClassName = clsx({
      [classes.pin]: true,
      [classes.zoomedInPin]: (Map.MARKER_SWITCH_ZOOM_LEVEL <= zoom),
      [classes.zoomedOutPin]: (Map.MARKER_SWITCH_ZOOM_LEVEL > zoom)
    });
    const polygonFeature = this.props.featureContent.find((feature) => feature.type === 'POLYGON');
    const poiFeatures = this.props.featureContent.filter((feature) => feature.type === 'POINT');
    // NOTE: data-circling is in lieu of changing class because will undesireably overwrite uncontrolled leaflet-related classes

    return (
      <div
        style={{
          height: this.props.containerHeight,
          width: this.props.containerWidth,
          position: 'relative',
          zIndex: 1,
          cursor: activeDraw ? 'crosshair' : 'inherit'
        }}
        data-circling={this.state.drawCircleEl ? '1' : '0'}
        ref={this.bindContainer}>
        {this.map && (
          <MapControl position={'topright'} {...this.props} leafletMap={this.map}>
            {(currentUser.isGuest === false) ? (<SavedSearch
              onCreate={this.handleSavedSearchCreate}
              onDelete={this.handleSavedSearchDelete}
              onSave={this.handleSavedSearchSave} />) : []}
            <DrawControlContainer
              handleDelete={this.handleDeleteMode}
              exitDelete={this.state.showDeleteMode}
              exitDeleteMode={this.exitDeleteMode}
              layers={this.layersPopup}
              map={this.map}
              radius={this.state.drawCircleRadius}
              activeDraw={activeDraw}
              setDrawType={this.setDrawType}
              setRadius={this.selectRadius} />
            <DemographicMenu
              setBasemap={this.setBasemap}
              basemapLayer={this.state.basemapLayer}
              setDemographic={this.setDemographic}
              demographic={this.state.demographic} />
            <ResultList fitListModePins={this.fitListModePins} />
          </MapControl>
        )}
        {
          this.map && poiFeatures && (
            <POILayers
              featureContent={poiFeatures}
              map={this.map}
              bounds={this.props.bounds}
              fetchGISFeature={this.props.fetchGISFeature} />
          )
        }
        {
          this.map && polygonFeature && (
            <SubmarketFeature
              featureContent={polygonFeature}
              map={this.map}
              bounds={this.props.bounds}
              fetchGISFeature={this.props.fetchGISFeature} />
          )
        }
        {
          this.map && this.props.demographic.name && (
            <DemographicFeature
              classes={this.props.classes}
              featureContent={this.props.demographicData}
              demographic={this.props.demographic}
              map={this.map}
              bounds={this.props.bounds}
              fetchDemographic={this.props.fetchDemographic} />
          )
        }
        {
          this.map && (
            <Pins
              highlightPinId={this.props.listMouseoverId}
              onClick={this.props.selectPopup}
              onPinsUpdated={this.handlePinsUpdated}
              onPopupClose={this.props.clearPopup}
              pins={this.props.pins}
              popup={this.props.popup}
              map={this.map}
              {...this.props}
              className={pinClassName} />
          )
        }
        {
          this.map && (
            <Areas
              areas={this.props.areas}
              highlightAreaId={this.props.listMouseoverId}
              map={this.map} />
          )
        }

        <Dialog
          open={this.state.deleteDialogOpen}
          onClose={this.handleDeleteCancel}>
          <DialogContent>
            <DialogContentText>
              Would you like to delete this shape?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={this.state.isDeleting}
              onClick={this.handleDeleteCancel}>NO</Button>
            <Button
              color="primary"
              disabled={this.state.isDeleting}
              onClick={this.handleDelete}>YES</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.props.parcelsEnabled && zoom < PARCEL_MIN_ZOOM}>
          <SnackbarContent
            message="Zoom in to see parcel boundaries"
            style={{ minWidth: 'fit-content' }} />
        </Snackbar>
      </div >
    );
  }
}

const TrackedMap = (props) => {
  const { trackEvent } = useTracker();

  return (
    <Map trackEvent={trackEvent} {...props} />
  );
};

export default withStyles(styles)(TrackedMap);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import get from 'lodash/get';
import api from '../../../services/api';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import styles from './module.css';

const widthLeft = 130;
const widthRight = 130;

const formatNumber = (number, label) => {
  const isNumber = typeof number === 'number';
  if (label === 'Median Income' || label === 'Median Rent' || label === 'Median Home Value') {
    return number && isNumber ? `$${numeral(number).format('0,0')}` : '\u2013';
  } 
  return number && isNumber ? numeral(number).format('0,0') : '\u2013'; 
};

export const DemographicsPopupCard = ({ parcel, bounds, trackEvent }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const [demographicsCensusData, setDemographicsCensusData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const xLocation = get(parcel, 'properties[0].location[0]')
        || get(parcel, 'location.representativePoint.longitude');
        
        const yLocation = get(parcel, 'properties[0].location[1]') 
        || get(parcel, 'location.representativePoint.latitude');

        const boundary = {
          xleft: xLocation - .000000000001,
          xright: xLocation,
          ybottom: yLocation - .000000000001,
          ytop: yLocation
        };
        const params = new URLSearchParams({ ...boundary });
        const censusData = process.env.REACT_APP_CENSUS_DATA;
        const data = await api.read(`/gis/features/${censusData.toString()}?${params}`);
        setDemographicsCensusData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [parcel, bounds]); 

  const demographicsDataDetailsLeft = [
    {
      label: 'Median Income', 
      value: get(demographicsCensusData, 'features[0].properties.data.cy_median_household_income', '\u2013')
    },
    {
      label: 'Median Rent',
      value: get(demographicsCensusData, 'features[0].properties.data.acsy_median_rent', '\u2013')
    },
    {
      label: 'Median Home Value',
      value: get(demographicsCensusData, 'features[0].properties.data.cy_median_home_value', '\u2013')
    }
  ];

  const demographicsDataDetailsRight = [
    {
      label: 'Total Households',
      value: get(demographicsCensusData, 'features[0].properties.data.cy_households', '\u2013')
    },
    {
      label: 'Total Population',
      value: get(demographicsCensusData, 'features[0].properties.data.cy_population', '\u2013')
    },
    {
      label: 'Total Housing Units',
      value: get(demographicsCensusData, 'features[0].properties.data.cy_housing_units', '\u2013')
    },
    {
      label: 'Total Affordability',
      value: get(demographicsCensusData, 'features[0].properties.data.cy_housing_affordability_index', '\u2013')
    }
  ];

  const expandAccordion = (e, isExpanded) => {
    if (isExpanded) {
      trackEvent({ category: 'Parcel Pop-Up', action: 'Clicked', name: 'DEMOGRAPHICS Accordion to Expand', value: 1 });
    }
  };

  return (
    <Accordion onChange={expandAccordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
        style={{ backgroundColor: '#ad735b' }}>
        <Typography component="div">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: 10, fontWeight: 'bold' }}>DEMOGRAPHICS</span>
            <span style={{ fontSize: '0.7rem', marginTop: '3px' }}>(Census Tract)</span>
          </div>
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ backgroundColor: 'white', justifyContent: 'center' }}>
        <div className={classes.detailContainer}>
          <div className={classes.columnContainer}>
            {demographicsDataDetailsLeft.map((detail) => (
              <div key={detail.label} className={classes.labelContainer}>
                <p className={classes.label} style={{ width: widthLeft }}>{detail.label}:</p>
                <strong className={classes.value}>
                  {formatNumber(detail.value, detail.label)}
                </strong>
              </div>
            ))}
          </div>
          <div className={classes.columnContainer}>
            {demographicsDataDetailsRight.map((detail) => (
              <div key={detail.label} className={classes.labelContainer}>
                <p className={classes.label} style={{ width: widthRight }} >{detail.label}:</p>
                <strong className={classes.value}>
                  {formatNumber(detail.value, detail.label)}
                </strong>
              </div>
            ))}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

DemographicsPopupCard.propTypes = {
  bounds: PropTypes.object,
  parcel: PropTypes.object,
  trackEvent: PropTypes.func.isRequired
};

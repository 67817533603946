import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

class Redirector extends React.Component {
  static propTypes = { // eslint-disable-line no-undef
    currentUser: PropTypes.object.isRequired
  };

  UNSAFE_componentWillMount() {
    const { currentUser } = this.props;
    let destination = 'onboarding';
    if (currentUser.onboarded) {
      destination = currentUser.defaultLanding;
      if (!(destination === 'dashboard' || destination === 'research')) {
        destination = 'dashboard';
      }
    }
    browserHistory.replace(destination);
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {
    currentUser: state.auth.currentUser
  };
}
export default connect(mapStateToProps)(Redirector);

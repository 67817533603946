import blue from '@material-ui/core/colors/blue';

export default (theme) => ({
  clear: {
    textDecoration: 'underline',
    color: blue[500],
    cursor: 'pointer',
    textAlign: 'right',
    position: 'relative',
    top: '24px',
    right: '16px'
  },
  dialogContentText: {
    display: 'block',
    fontWeight: 'bold'
  },
  filterPopper: {
    backgroundColor: 'white',
    position: 'absolute',
  },
  formGroup: {
    padding: '8px'
  },
  listColumnActions: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  listTableActionButton: {
    marginRight: theme.spacing(1),
  },
  listColumnModules: {
    display: 'flex',
  },
  listTableCell: {
    width: '16%',
    padding: '12px 16px',
  },
  listTableCellDescription: {
    width: '20%',
    padding: '12px 16px',
  },
  listTableHeaderActionWrapper: {
    display: 'flex',
  },
  listTableHeaderCellDescription: {
    width: '20%',
    borderBottom: '2px solid #25408F'
  },
  listTableHeaderCell: {
    width: '16%',
    borderBottom: '2px solid #25408F'
  },
  listTableHeaderCellBodyAction: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    margin: theme.spacing(0),
    alignSelf: 'center'
  },
  listTableHeaderCellBody: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    margin: theme.spacing(0),
  },
  listTableHeaderCellSort: {
    marginLeft: theme.spacing(0.5),
  },
  snackbarContent: {
    minWidth: 'fit-content',
  },
  sortIcon: {
    transform: 'scaleY(-1)'
  },
  tableRow: {
    '&:last-child td, &:last-child th': {
      border: 0
    }
  },
  listTitle: {
    margin: theme.spacing(0),
    color: blue[500],
    ['&:hover']: {
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  }
});

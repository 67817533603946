import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './module.css';
import Sort from '@material-ui/icons/Sort';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TableHead from '@material-ui/core/TableHead';
import SavedSearchRow from './SavedSearchRow';

const SavedSearchTable = ({ classes, ...props }) => {
  const { savedSearchDeleted, savedSearchUpdated, searches, selectSavedSearch } = props;
  const [savedSearches, setSavedSearches] = useState(searches); // Shallow Copy to Enable Updates & Deletes
  const [sortedSearches, setSortedSearches] = useState(searches); // Used for Sorting
  const [sortTimeIcon, setSortTimeIcon] = useState(false);
  const [sortNameIcon, setSortNameIcon] = useState(false);
  const [sortFrequencyIcon, setSortFrequencyIcon] = useState(false);

  const sortField = (field) => {
    if (field === 'timestamp') {
      setSortNameIcon(false);
      setSortTimeIcon(sortTimeIcon === false);
      const chronological = [...sortedSearches].reverse();
      return setSortedSearches((sortTimeIcon === true) ? savedSearches : chronological);
    } else if (field === 'name') {
      setSortTimeIcon(false);
      setSortNameIcon(sortNameIcon === false);
      const alphabetical = [...sortedSearches].sort((a, b) => a.title.localeCompare(b.title));
      return setSortedSearches((sortNameIcon === true) ? savedSearches : alphabetical);
    } else if (field === 'frequency') {
      setSortFrequencyIcon(sortFrequencyIcon === false);
      const frequency = [...sortedSearches].sort((a, b) => a.frequency.localeCompare(b.frequency));
      return setSortedSearches((sortFrequencyIcon === true) ? savedSearches : frequency);
    }
    return;
  };
  const sortName = () => sortField('name');
  const sortTimestamp = () => sortField('timestamp');
  const sortFrequency = () => sortField('frequency');

  /* useMemo updates affected savedSearch deletes & updates to not cause full re-render*/
  const onDeleteSavedSearch = useMemo(() => (removedSearch) => {
    const updatedSearches = sortedSearches.filter((search) => search.value.id !== removedSearch.id);
    setSavedSearches(updatedSearches);
    setSortedSearches(updatedSearches);
  });
  const onUpdateSavedSearch = useMemo(() => (title, description, frequency, value) => {
    const updatedSearches = sortedSearches.filter((search) => search.value.id !== value.id);
    updatedSearches.unshift({
      title,
      timestamp: new Date().toISOString(),
      description,
      frequency,
      value
    });
    setSavedSearches(updatedSearches);
    setSortedSearches(updatedSearches);
  });

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ borderBottom: '2px solid #25408F' }}>
              <span className={classes.savedSearchTableHeaderActionWrapper}>
                <p className={classes.savedSearchTableHeaderCellBodyAction}>Name</p>
                <IconButton
                  onClick={sortName}
                  className={classes.savedSearchTableHeaderCellSort}
                  size="small">
                  {sortNameIcon ? <Sort style={{ transform: 'scaleY(-1)' }} /> : <Sort />}
                </IconButton>
              </span>
            </TableCell>
            <TableCell style={{ borderBottom: '2px solid #25408F' }}>
              <p className={classes.savedSearchTableHeaderCellBody}>Description</p>
            </TableCell>
            <TableCell style={{ borderBottom: '2px solid #25408F' }}>
              <p className={classes.savedSearchTableHeaderCellBody}> Module(s)</p>
            </TableCell>
            <TableCell style={{ borderBottom: '2px solid #25408F' }}>
              <span className={classes.savedSearchTableHeaderActionWrapper}>
                <p className={classes.savedSearchTableHeaderCellBodyAction}>Last Updated At</p>
                <IconButton
                  className={classes.savedSearchTableHeaderCellSort}
                  onClick={sortTimestamp}
                  size="small">
                  {sortTimeIcon ? <Sort style={{ transform: 'scaleY(-1)' }} /> : <Sort />}
                </IconButton>
              </span>
            </TableCell>
            <TableCell style={{ borderBottom: '2px solid #25408F' }}>
              <span className={classes.savedSearchTableHeaderActionWrapper}>
                <p className={classes.savedSearchTableHeaderCellBodyAction}>Email Alert</p>
                <IconButton
                  className={classes.savedSearchTableHeaderCellSort}
                  onClick={sortFrequency}
                  size="small">
                  {sortFrequencyIcon ? <Sort style={{ transform: 'scaleY(-1)' }} /> : <Sort />}
                </IconButton>
              </span>
            </TableCell>
            <TableCell style={{ borderBottom: '2px solid #25408F' }}>
              <p className={classes.savedSearchTableHeaderCellBody}>Actions</p>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(sortedSearches.map((search) => (
            <SavedSearchRow
              key={search.value.id}
              savedSearchDeleted={savedSearchDeleted}
              savedSearchUpdated={savedSearchUpdated}
              selectSavedSearch={selectSavedSearch}
              onUpdateSavedSearch={onUpdateSavedSearch}
              onDeleteSavedSearch={onDeleteSavedSearch}
              search={search} />
          )))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

SavedSearchTable.propTypes = {
  classes: PropTypes.object.isRequired,
  savedSearchDeleted: PropTypes.func,
  savedSearchUpdated: PropTypes.func,
  searches: PropTypes.array.isRequired,
  selectSavedSearch: PropTypes.func,
};

export default withStyles(styles)(SavedSearchTable);

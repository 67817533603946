import { COLLIERS_TEAL } from '../../styles/theme';

export default (theme) => ({
  address: {
    color: '#505050',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.25,
    marginTop: '-6px',
    marginLeft: theme.spacing(2),
    textOverflow: 'ellipsis',
  },
  closeButtonWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(1)
  },
  tabGroup: {
    margin: theme.spacing(2)
  },
  sectionHeader: {
    color: '#253F8E',
    fontSize: '22px',
    FontFace: 'Open Sans Semibold',
    lineHeight: 2,
    margin: 0,
    width: 'fit-content'
  },
  tripleColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down(1500)]: {
      gridTemplateColumns: '1fr',
      width: '100%',
    },
  },
  columnContainer: {
    fontSize: '16px',
    marginTop: theme.spacing(1.875),
  },
  datatypeMenuNav: {
    borderBottom: `12px solid ${COLLIERS_TEAL}`,
    width: '100%',
    marginLeft: 'unset !important',
    position: 'relative',
    padding: '12px 0px 0px 0px !important',
    overflow: 'hidden',
  },
  datatypeMenuStickyWrapper: {
    top: '0',
    zIndex: 'flex-start',
    backgroundColor: 'white',
    boxShadow: 'unset'
  },
  datatypeMenuTab: {
    opacity: 'unset',
    fontWeight: '600',
    fontSize: '16px',
    padding: '6px 8px',
    ['& .MuiTab-wrapper']: {
      opacity: 'unset',
    },
    [theme.breakpoints.up(1000)] : {
      width: '33.33% !important'
    },
    [theme.breakpoints.down(1800)]: {
      fontSize: theme.spacing(1.5),
    },
    [theme.breakpoints.down(1500)]: {
      padding: '6px 24px'
    },
  },
  datatypeMenuTabs: {
    backgroundColor: '#6E6E70',
    width: '100%',
    ['& .MuiTabs-scroller']: {
      position: 'unset'
    },
  },
  datatypesMenuTabAvatar: {
    height: 28,
    width: 28,
    margin: '0 !important',
    padding: '4px',
    [theme.breakpoints.down(1500)]: {
      height: 32,
      width: 32
    }
  },
  datatypesMenuTabIcon: {
    height: 28,
    width: 28,
    padding: '4px',
    [theme.breakpoints.down(1500)]: {
      height: 32,
      width: 32
    }
  },
  datatypeMenuTabIndicatorProps: {
    left: '0',
    top: 0,
    bottom: 'auto',
    height: '12px',
    transition: 'unset',
  },
  datatypeMenuTabInactive: {
    fontWeight: '600',
    fontSize: theme.spacing(2),
    opacity: 'unset !important',
    padding: '6px 8px',
    ['& .MuiTab-wrapper']: {
      opacity: '1',
    },
    [theme.breakpoints.up(1000)] : {
      width: '33.33% !important'
    },
    [theme.breakpoints.down(1800)]: {
      fontSize: theme.spacing(1.5),
    },
    [theme.breakpoints.down(1500)]: {
      padding: '16px 24px'
    }
  },
  labelContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    font: 'Open Sans',
    fontSize: '20px',
    fontWeight: 400,
    marginTop: theme.spacing(1.875),
    width: '100%',
    ['&$wide']: {
      width: '50%',
    },
    ['& strong']: {
      flex: '0 1 auto',
      textTransform: 'uppercase',
      ['&:first-child']: {
        color: '#707070'
      },
    },
    ['& span']: {
      flex: '1 1 auto',
    }
  },
  label: {
    color: '#707070',
    font: 'Open Sans',
    fontSize: theme.spacing(2),
    fontWeight: 400,
    justifySelf: 'end',
    alignSelf: 'start',
    marginRight: theme.spacing(1.25),
  },
  value: {
    color: '#000000',
    font: 'Open Sans',
    fontSize: theme.spacing(2),
    fontWeight: 400,
  },

  salesRecordsSummaryWrapper: {
    display: 'flex',
    justifyContent: 'space-between', 
    padding: '0px 16px',
    [theme.breakpoints.down(1500)]: {
      flexWrap: 'wrap',
      padding: '0px 16px',
      justifyContent: 'space-evenly',
    },
  },
  summmaryPropertyStatsWrapper: {
    display: 'flex',
    justifyContent: 'space-between', 
    padding: '10px 16px',
    [theme.breakpoints.down(1500)]: {
      flexWrap: 'wrap',
      padding: '0px 16px',
      justifyContent: 'space-evenly',
    },
  },
  summaryFieldStatsStat: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down(1500)]: {
      padding: '10px 16px',
    },
  },
  summaryFieldStatsLabel: {
    color: '#505050',
    fontWeight: 700,
    fontSize: theme.spacing(1.5),
    alignSelf: 'center',
    [theme.breakpoints.down(1500)]: {
      fontSize: '12px'
    }
  },
  summaryFieldStatsData: {
    fontWeight: 300,
    fontSize: theme.spacing(2.75),
    color: '#254091',
    alignSelf: 'center',
    maxWidth: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(1500)]: {
      fontSize: '16px'
    }
  },
  sourceSummaryEllipsisPopover: {
    lineHeight: 'normal',
    marginTop: -1,
    position: 'absolute !important',
    left: 0,
  },
  sourceSummaryEllipsisContent: {
    fontSize: 14,
    maxWidth: 400,
    padding: `${theme.spacing(2)}px`,
  },
  sourceSummaryEllipsisSalesRecords: {
    fontWeight: 300,
    fontSize: theme.spacing(2.75),
    color: '#254091',
    alignSelf: 'center',
    [theme.breakpoints.down(1280)]: {
      maxWidth: '30px',
    },
    [theme.breakpoints.down(1500)]: {
      fontSize: '16px'
    },
    [theme.breakpoints.down(1550)]: {
      maxWidth: '40px',
    },
    [theme.breakpoints.down(1700)]: {
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up(1700)]: {
      maxWidth: '150px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  },
  
  card: {
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  actions: {
    alignSelf: 'flex-end',
    marginTop: 'auto',
    order: 2, 
  },
  sectionPaper: {
    padding: theme.spacing(1, 1.5, 3, 1.5)
  },
  expandedView: {
    lineHeight: 2,
    ['& section']: {
      boxShadow: 'none !important',
      margin: '0 15px',
      padding: 15,
    }
  },
  
  menuPaper: {
    backgroundColor: theme.palette.background.paper
  },
  parcelButton: {
    textWrap: 'nowrap',
  },
  propertyMenuItem: {
    ['&.Mui-selected']: {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',

      ['&:hover']: {
        color: 'inherit'
      }
    }
  },
  parcelWrapper: {
    overflowY: 'scroll'
  },
  parcelWrapperHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    zIndex: theme.zIndex.tooltip
  },
  propertyButton: {
    overflow: 'hidden',
    textWrap: 'nowrap',
    ['& .MuiButton-label']: {
      display: 'block',
      maxWidth: 'max-content',
      overflow: 'hidden',
      textAlign: 'start',
      textOverflow: 'ellipsis',
      width: '20vw',

    }
  },
  title: {
    color: '#505050',
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 2,
    marginLeft: theme.spacing(2)
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
}); 

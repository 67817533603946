export default (theme) => ({
  cancelButton: {
    width: '80px',
    fontWeight: '500',
    color:  '#0C9ED9',
    border: '0.5px solid black',
    backgroundColor: 'white',
    borderRadius: '20px'
  },
  close : {
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
  notifyText: {
    marginTop: '16px',
    marginBottom: '4px',
    fontWeight: 'bold',
  },
  notifySubText: {
    marginBottom: '16px',
    color: '#707274'
  },
  saveButton: {
    width: '80px',
    color: 'white',
    fontWeight: '500',
    backgroundColor: '#304085',
    borderRadius: '20px'
  },
  saveSearchDialog: {
    ['& .MuiDialog-paper']: {
      'bottom': '20%',
      'left': '-7%',
    },
    [theme.breakpoints.down('lg')]: {
      '& .MuiDialog-paper': {
        'left': '-9%',
      },
    },
    [theme.breakpoints.down('md')]: {
      '& .MuiDialog-paper': {
        'left': '-16%',
      },
    }
  },
  savedSearchColumnModules: {
    display: 'flex',
    position: 'absolute',
    top: '16px',
    right: '80px',
  },
  toggleButtons: {
    ['& .MuiToggleButton-root']: {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid #707274',
      borderRadius: 0,
      width: '80px'
    },
    ['& .MuiToggleButton-root.Mui-selected']: {
      backgroundColor: '#0C9ED9',
      color: 'white'
    },
  },
});

import React from 'react';
import DistanceIcon from '@material-ui/icons/CompareArrows';
import PlaceIcon from '@material-ui/icons/Place';
import SearchIcon from '@material-ui/icons/Layers';
import * as feedContent from './content';

const getPropertyName = ({ address, name }) => {
  return name || (address || '').split(',').shift();
};

export default function getTitles({ property, properties = [], ...item }) {
  const subheader = (
    <div>
      <div>
        {item._type !== 'tim' && (
          <span>
            <PlaceIcon
              style={{
                height: '18px',
                marginRight: '3px',
                position: 'relative',
                top: '4px',
                width: '18px'
              }} />
            {properties.length > 0 ? properties.map(getPropertyName).join(', ') : getPropertyName(property)}
          </span>
        )}
        {item._type === 'tim' && (
          <span>
            <SearchIcon
              style={{
                height: '18px',
                marginRight: '3px',
                position: 'relative',
                top: '4px',
                width: '18px'
              }} />
            {item.searchShapes.coordinates.length} Search Area{item.searchShapes.coordinates.length === 1 || 's'}
          </span>
        )}
        <DistanceIcon
          style={{
            height: '18px',
            margin: '0 4px',
            position: 'relative',
            top: '4px',
            width: '18px'
          }} />
        {item.closest.distance.toFixed(1)} miles from {item.closest.title}
      </div>
      {feedContent[item._type].Subtitle && feedContent[item._type].Subtitle(item) }
    </div>
  );
  const title = item.canonicalName;

  return {
    title,
    subheader
  };
}

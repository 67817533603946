import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SummaryStat from './SummaryStat';
import formatStat from './formatStat';
import styles from './module.css';

const getClasses = makeStyles(styles);

const SummaryStats = ({ icons, item, schema }) => {
  const classes = getClasses(useTheme());
  const fieldNotes = item.fieldNotes || {};
  return (
    <div className={classes.summaryStats}>
      {schema.map(({ key, label, ...stat }) => {
        return (
          <SummaryStat
            className={classes.summaryStat}
            fieldNote={fieldNotes[key]}
            key={key}
            Icon={icons[key]}
            label={label}
            value={formatStat(item[key], stat)} />
        );
      })}
    </div>
  );
};

SummaryStats.propTypes = {
  icons: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  schema: PropTypes.array.isRequired,
};

export default SummaryStats;

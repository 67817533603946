import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { myListDeleted, selectMyList,  myListUpdated } from '../actions';
import { enableListMode } from '../../research/actions';
import Can from '../../auth/ability';
import AccountDefault from '../common/AccountDefault';
import AccountLoading from '../common/AccountLoading';
import MyListTable from './MyListTable';
import styles from './module.css.js';

const MyListContainer = ({
  router,
  enableListMode,
  myListDeleted,
  myListUpdated,
  recentMyLists,
  selectMyList,
}) => {
  const [hasListsLoaded, setHasListsLoaded] = useState(false);
  const [lists, setLists] = useState([]);

  useEffect(() => {
    if (recentMyLists) {
      setLists(recentMyLists.map((result) => ({
        title: result.title,
        description: result.description,
        datatype: result.datatype,
        timestamp: result.updatedAt,
        value: result
      })));

      setHasListsLoaded(true);
    }
  }, [recentMyLists]);

  return (
    <Can do="use" on="_app">
      {(hasListsLoaded) && (lists.length === 0) && (
        <AccountDefault 
          accountType={'Lists'}
          buttonText={'Lists'}
          router={router} />
      )}
      {(!hasListsLoaded) && (lists.length === 0) && (
        <AccountLoading 
          accountType={'Lists'} />
      )}
      {(hasListsLoaded) && (lists.length !== 0) && (
        <MyListTable
          enableListMode={enableListMode}
          listDeleted={myListDeleted}
          listUpdated={myListUpdated}
          lists={lists}
          selectList={selectMyList} />
      )}
    </Can>
  );
};

MyListContainer.propTypes = {
  enableListMode: PropTypes.func,
  myListDeleted: PropTypes.func,
  myListUpdated: PropTypes.func,
  recentMyLists: PropTypes.array,
  router: PropTypes.object.isRequired,
  selectMyList: PropTypes.func,
};

function mapStateToProps({ research }) {
  const { lists: { recentMyLists } } = research;
  return { recentMyLists };
}

export default connect(
  mapStateToProps,
  { enableListMode, myListDeleted, myListUpdated, selectMyList }
)(withStyles(styles)(MyListContainer));
